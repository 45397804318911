import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Chip,
} from '@material-ui/core';
import DataTable from '../../../components/Table/DataTable';
import StackedColumn from '../../../components/Charts/StackedColumn';
import HorizontalBar from '../../../components/Charts/HorizontalBar';

const TargetSettingDisplay = ({
  categories,
  chartData,
  yAxisTitle,
  companies,
  isSelected,
  barChartData,
  currentSector,
  handleSectorChange,
  setSelected,
  tableData,
  targetFundCells,
  tableHeading,
}) => (
  <Box>
    {isSelected ? (
      <StackedColumn
        data={chartData}
        title="Funds' Target Setting"
        categories={categories}
        yAxisTitle={yAxisTitle}
      />
    ) : (
      <HorizontalBar
        categories={companies}
        data={barChartData}
        title="Funds' Target Setting"
        yAxisTitle={yAxisTitle}
        currentSector={currentSector}
        tooltipText={['Allowance', 'Contribution']}
      />
    )}
    <Grid container>
      <Grid item md={6} sm={12} xs={12}>
        <FormControl variant="outlined">
          <InputLabel>Select Sector</InputLabel>
          <Select
            label="Select Sector"
            value={currentSector}
            onChange={handleSectorChange}
            style={{ fontSize: 14, width: 300, marginBottom: 20 }}
          >
            {categories.length > 0
              && categories.map((sector) => (
                <MenuItem key={sector.name} value={sector.name}>
                  {sector.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        {isSelected ? (
          <Chip
            label="Change to sectoral view"
            variant="outlined"
            onClick={() => {
              setSelected(false);
            }}
            style={{ width: 220, height: 55 }}
          />
        ) : (
          <Chip
            label="Change to stacked view"
            onClick={() => {
              setSelected(true);
            }}
            style={{ width: 220, height: 55 }}
          />
        )}
      </Grid>
    </Grid>
    <DataTable
      data={tableData}
      columns={targetFundCells}
      tableHeading={tableHeading}
    />
  </Box>
);

TargetSettingDisplay.propTypes = {
  chartData: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  yAxisTitle: PropTypes.string.isRequired,
  companies: PropTypes.array.isRequired,
  isSelected: PropTypes.bool.isRequired,
  barChartData: PropTypes.array.isRequired,
  currentSector: PropTypes.string.isRequired,
  handleSectorChange: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  tableData: PropTypes.array.isRequired,
  targetFundCells: PropTypes.array.isRequired,
  chartKey: PropTypes.string.isRequired,
  tableHeading: PropTypes.string.isRequired,
};

export default TargetSettingDisplay;
