import React from 'react';
import useCurrentTheme from '../../hooks/useCurrentTheme';
import BaseChart from './BaseChart';
import ChartProps from './ChartProps';

function StackedColumn(props) {
  const { usedTheme } = useCurrentTheme(props.theme);
  const options = {
    yAxis: {
      min: 0,
      plotLines: props.yAxisPlotline || null,
    },
    legend: {
      reversed: true,
    },
    tooltip: {
      formatter() {
        return `<b> ${this.x} </b><br /> : ${this.y}`;
      },
    },
    plotOptions: {
      series: {
        borderWidth: 1,
        borderColor: usedTheme === 'dark' ? '#000000' : '#FFFFFF',
      },
    },
  };
  return <BaseChart {...props} chartType="column" options={options} />;
}

StackedColumn.propTypes = {
  ...ChartProps,
};
export default StackedColumn;
