import React from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import TabPanel from '../../components/TabPanel';
import { strandedAssetTabs } from '../../util/tabs-config';
import FossilFuel from './FossilFuel/FossilFuel';
import CoalPower from './CoalPower/CoalPower';
import useTabValue from '../../hooks/useTabValueHook';

function StrandedAssetsAnalysis() {
  const [tabValue, onTabChange] = useTabValue();

  return (
    <>
      <Paper position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={onTabChange}
          indicatorColor="secondary"
          fullWidth="true"
        >
          {strandedAssetTabs.map((e) => (
            <Tab key={e} label={e} style={{ fontSize: 11 }} />
          ))}
        </Tabs>
      </Paper>
      <TabPanel value={tabValue} index={0}>
        <FossilFuel />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <CoalPower />
      </TabPanel>
    </>
  );
}
export default StrandedAssetsAnalysis;
