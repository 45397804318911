import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { LoadingHOC } from '../../../util/LoadingHOC';
import AttributionDisplay from './AttributionDisplay';
import useGetPayload from '../../../hooks/useGetPayload';
import Model from '../../../models/tempScore/attribution';
import { useGetTempMetricAttributionQuery } from '../../../redux/apiHooks';

const AttributionDisplayWithLoading = LoadingHOC(AttributionDisplay);

const Attribution = () => {
  const [chartData, setChartData] = useState([]);
  const [categories, setCategories] = useState([]);

  const { filters, payload } = useGetPayload(Model);
  const {
    data: tempAttributionData,
    isFetching,
    error,
  } = useGetTempMetricAttributionQuery(payload);
  const { scoreType, emission } = filters;

  const getScoreType = () => {
    if (scoreType === 'shortTerm') {
      return 'short';
    }
    if (scoreType === 'longTerm') {
      return 'long';
    }
    return 'mid';
  };
  const getChartData = () => {
    const score = getScoreType();
    const data = tempAttributionData?.[score] || {};
    const cData = [];
    const cat = [];

    if (data && data.length > 0) {
      data.forEach((res) => {
        const values = [];
        if (res && res.points && res.points.length > 0) {
          res.points.forEach((value) => {
            const sector = value.x;
            if (!cat.includes(sector)) {
              cat.push(sector);
            }
            values.push(value.y[emission]);
          });
        }
        cData.push({
          name: res.name,
          data: values,
        });
      });
    }
    setChartData(cData);
    setCategories(cat);
  };

  useEffect(() => {
    if (!isEmpty(tempAttributionData)) getChartData();
  }, [tempAttributionData, scoreType, emission]);

  return (
    <React.Fragment>
      <AttributionDisplayWithLoading
        loading={isFetching}
        error={error}
        categories={categories}
        chartData={chartData}
        chartKey="TEMP_ATTRIBUTION"
      />
    </React.Fragment>
  );
};
export default Attribution;
