import BaseRequest from '../baseRequest';

export default class PortfolioEmissionPcaf extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem, userInfo } = props;
    this.metric_type = 'contribution';
    this.sector = filterItem.sector;
    this.Loan = {
      attribution: {
        private: userInfo.footprint_parameters.Loan.attribution.private,
        public: userInfo.footprint_parameters.Loan.attribution.public,
      },
      strategy: userInfo.footprint_parameters.Loan.strategy,
      emissions_type: userInfo.footprint_parameters.Loan.emissions_type,
      footprint:
        userInfo.footprint_parameters.Loan.strategy === 'default'
          ? filterItem.footprintMetric
          : 'TotalCarbEmis',
      security_type: userInfo.footprint_parameters.Loan.security_type,
    };
    this.Equity = {
      attribution: {
        private: userInfo.footprint_parameters.Equity.attribution.private,
        public: userInfo.footprint_parameters.Equity.attribution.public,
      },
      strategy: userInfo.footprint_parameters.Equity.strategy,
      emissions_type: userInfo.footprint_parameters.Equity.emissions_type,
      footprint:
        userInfo.footprint_parameters.Equity.strategy === 'default'
          ? filterItem.footprintMetric
          : 'TotalCarbEmis',
      security_type: userInfo.footprint_parameters.Equity.security_type,
    };
    this.Corporate_Bond = {
      attribution: {
        private:
          userInfo.footprint_parameters.Corporate_Bond.attribution.private,
        public: userInfo.footprint_parameters.Corporate_Bond.attribution.public,
      },
      strategy: userInfo.footprint_parameters.Corporate_Bond.strategy,
      emissions_type:
        userInfo.footprint_parameters.Corporate_Bond.emissions_type,
      footprint:
        userInfo.footprint_parameters.Corporate_Bond.strategy === 'default'
          ? filterItem.footprintMetric
          : 'TotalCarbEmis',
      security_type: userInfo.footprint_parameters.Corporate_Bond.security_type,
    };
  }
}
