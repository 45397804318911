import React from 'react';
import PropTypes from 'prop-types';
import HeatmapChart from '../../Charts/HeatmapChart';

function ReportHeatMapChart({ data, yAxisCategories, xAxisCategories }) {
  return (
    <HeatmapChart
      data={data}
      yAxisCategories={yAxisCategories}
      xAxisCategories={xAxisCategories}
      theme={'ICE light'}
      height={300}
      width={700}
      fontSize={8}
      isExportable={false}
    />
  );
}

ReportHeatMapChart.propTypes = {
  data: PropTypes.array,
  yAxisCategories: PropTypes.string,
  xAxisCategories: PropTypes.string,
};
export default ReportHeatMapChart;
