import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import categoryContent from '../CategoryContent';
import { LoadingHOC } from '../../../util/LoadingHOC';
import Scope3HeatmapDisplay from './Scope3HeatmapDisplay';
import { getCategoryKeyForScope3 } from '../../../helpers';
import { SCOPE_12_TOTAL } from '../../../components/FilterSection/constants';
import PortfolioScope3Heatmap from '../../../models/scope3/portfolioScope3Heatmap';
import useGetPayload from '../../../hooks/useGetPayload';
import { useGetScope3PortfolioHeatmapQuery } from '../../../redux/apiHooks';

const Scope3HeatmapWithLoading = LoadingHOC(Scope3HeatmapDisplay);

const Scope3Heatmap = () => {
  const [chartData, setChartData] = useState([]);
  const [yCategories, setYCategories] = useState([]);
  const [xCategories, setXCategories] = useState([]);
  const [dialog, setDialog] = useState(false);

  const { filters, payload } = useGetPayload(PortfolioScope3Heatmap);
  const {
    data: heatmapData,
    isFetching,
    error,
  } = useGetScope3PortfolioHeatmapQuery(payload);
  const { emission: filterEmission } = filters;

  const getChartData = () => {
    const emission = filterEmission === 'Sc12' ? 'Sc123' : filterEmission;
    let emissionChartData = [];
    const categories = [];
    let sectorList = [];

    for (let i = 0; i < 15; i += 1) {
      categories.push(`cat ${i + 1}`);
    }
    sectorList = heatmapData.sectors;
    const res = heatmapData.data;
    if (res) {
      emissionChartData = res.map((data) => {
        if (
          !categories.includes('total 1 + 2')
          && data.category === SCOPE_12_TOTAL
        ) {
          categories.unshift('total 1 + 2');
        }
        const sectorName = data.sector;
        const xValue = getCategoryKeyForScope3(data.category, emission);
        if (xValue === 'N/A') return false;
        const yValue = sectorList.indexOf(sectorName);
        const values = [
          xValue,
          yValue,
          parseFloat(data.contribution.toFixed(2)),
        ];
        return values;
      });
    }
    setChartData(emissionChartData.filter((data) => data !== false));
    setYCategories(sectorList);
    setXCategories(categories);
  };

  useEffect(() => {
    if (!isEmpty(heatmapData)) getChartData();
  }, [heatmapData, filterEmission]);

  const onDialogHandler = () => {
    setDialog(!dialog);
  };

  return (
    <Scope3HeatmapWithLoading
      error={error}
      loading={isFetching}
      chartKey="SCOPE3_HEATMAP"
      yAxisCategories={yCategories}
      data={chartData}
      xAxisCategories={xCategories}
      onDialogHandler={onDialogHandler}
      dialogOpen={dialog}
      fullWidth={true}
      dialogContent={categoryContent}
    />
  );
};

export default Scope3Heatmap;
