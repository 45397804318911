import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const usePickerStyle = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    color: theme.palette.primary.main,
    height: 35,
    display: 'flex',
    padding: '4px 10px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    gap: '10px',
  },
}));

const DownloadFileButton = ({ onClick, label = 'Download' }) => {
  const classes = usePickerStyle();
  return (
    <div className={classes.container} onClick={onClick}>
      <GetAppIcon /> {label}
    </div>
  );
};

DownloadFileButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default DownloadFileButton;
