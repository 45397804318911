import colorSchema from '../Themes/config';
import useGetTheme from './useGetTheme';

const useCurrentTheme = (theme) => {
  const { currentTheme } = useGetTheme();
  const usedTheme = theme || currentTheme;
  const usedStyles = colorSchema[usedTheme];
  return {
    usedTheme,
    usedStyles,
  };
};

export default useCurrentTheme;
