const strings = {
  emissions: {
    title:
      'Emissions footprint analysis enables financial institutions to use the latest available reported and estimated data to quantify the greenhouse gas emissions (GHG) embedded within a portfolio, presenting them as tons of carbon dioxide equivalents (tCO2e) apportioned to their exposure. To better compare companies or portfolios to each other, ICE normalizes emissions by an indicator of size (such as annual revenues or enterprise value including cash) to give a measure of carbon intensity.',
    title2:
      'The metrics below can be considered the first step towards understanding the climate related risks and opportunities in a portfolio, and as such may be used for internal or external reporting, as well as for risk management and corporate engagement.',
    emissionChartText:
      'The Portfolio Footprint (Carbon Intensity) chart displays the carbon intensity (or footprint) of the portfolio and the benchmark selected by [client name]  in terms of Scope 1+2, Scope 3, and Scope 1+2+3.',
    emissionChartSc12Text:
      'The Portfolio Footprint (Carbon Intensity) chart displays the carbon intensity (or footprint) of the portfolio and the benchmark selected by [client name]  in terms of Scope 1+2.',
    footPrintChartText:
      'The selected footprint metric is Weighted Average Intensity (Market Value), where the Enterprise Value including Cash (EVIC) represents the Market Value.',
    pcaf1:
      'The Portfolio Footprint Partnership for Carbon Accounting Financials (PCAF) chart displays the carbon intensity (or footprint) of the portfolio and benchmark, categorised by PCAF disclosure quality scores. PCAF disclosure quality range from 1 to 5 and are defined as above.',
    pcaf2:
      'The selected footprint metric is Financed Emissions, with EVIC selected as the attribution factor.',
    carbonAtt1:
      'The Carbon Attribution Analysis chart identifies specific areas of the portfolio and benchmark’s carbon intensity performance. The stock selection effect measures the impact of individual security selection on the carbon intensity against the benchmark within a respective sector. The sector allocation effect highlights the impact of the portfolio’s sector weighting on the carbon intensity against the benchmark. Lastly, the interaction effect measures the combined impact of the selection and allocation effects within a sector.',
    carbonAtt2:
      'The selected footprint metric is Weighted Average Intensity (Market Value), where the EVIC represents the Market Value. The carbon attribution analysis includes Scope 1, 2 and 3 emissions.',
    carbonAtt2_Sc12:
      'The selected footprint metric is Weighted Average Intensity (Market Value), where the EVIC represents the Market Value. The carbon attribution analysis includes Scope 1 + 2 emissions.',
    disclouser:
      'The Carbon Disclosure chart highlights the number of Scope 1+2 carbon emission disclosures that are made by companies within the portfolio and benchmark. Disclosure is categorised by ICE, from 1 to 5, as above.',
  },
  disclaimer: {
    limitations: 'LIMITATIONS:',
    text1:
      'This document contains information that is confidential and proprietary property and/or trade secret of Intercontinental Exchange, Inc. and/or its affiliates (the “ICE Group”), is not to be published, reproduced, copied, modified, disclosed or used without the express written consent of ICE Group.   ',
    text2:
      'This document is provided for informational purposes only. The information contained herein is subject to change without notice and does not constitute any form of warranty, representation, or undertaking. Nothing herein should in any way be deemed to alter the legal rights and obligations contained in agreements between ICE Group and its respective clients relating to any of the products or services described herein. Nothing herein is intended to constitute legal, tax, accounting, investment or other professional advice. Users of the report should consult with an attorney, tax, or accounting professional regarding any specific legal, tax, or accounting situation.',
    text3:
      'All feature values included in the report, including those that are reflections of data provided by other data providers as well as forecasts of expectations of change, are estimates based upon the currently available information, are provided as is, and should be treated as estimates and forecasts with substantial potential deviations from underlying values. ',
    text4:
      'There are many methodologies (including computer-based analytical modelling) available to calculate and determine information such as the information described herein. The climate related metrics contained herein may not generate results that correlate to actual outcomes, and/or actual behavior of the market. There may be errors or defects in the ICE Group software, databases, or methodologies that may cause resultant data to be inappropriate for use for certain purposes or use cases, and/or within certain applications. Certain historical data may be subject to periodic updates over time due to recalibration processes, including, without limitation enhancement of the models and increased coverage.  Although the ICE Group may elect to update the data it uses from time to time, it has no obligation to do so.',
    text5:
      'ICE Group are not registered as nationally registered statistical rating organizations, nor should this information be construed to constitute an assessment of the creditworthiness of any company or financial instrument.',
    text6:
      'GHG emissions information available is either compiled from publicly reported information or estimated, as indicated in the applicable product and services.',
    text7:
      'Trademarks of the ICE Group include: Intercontinental Exchange, ICE, ICE block design, NYSE, ICE Data Services, and New York Stock Exchange. Information regarding additional trademarks and intellectual property rights of Intercontinental Exchange, Inc. and/or its affiliates is located at https://www.theice.com/terms-of-use. Other products, services, or company names mentioned herein are the property of, and may be the service mark or trademark of, their respective owners.',
    text8: '© 2023 Intercontinental Exchange, Inc.',
  },
  fossilFuel: {
    text1:
      'Stranded assets are described as assets that may suffer from unanticipated or premature write downs, devaluations, or conversion to liabilities. The term is commonly applied to the fossil fuel reserves of companies that would not be usable if decarbonization targets to combat climate change are met in line with the Paris Agreement.',
    text2:
      'Part of the TCFD reporting guidelines for asset owners and managers involves reporting on exposure to ‘carbon related assets’. ICE assesses exposure to such assets by quantifying the potential increase in the emissions footprint of companies and portfolios, due to the extraction of proven fossil fuel reserves. Such analysis is broken down by different energy sources, such as coal, oil and gas, as they have different climate impact and to keep the mean temperature from increasing more than 1.5 or 2 degrees Celsius their usage in the future global energy mix will be different.',
    text3:
      'The Fossil-Fuel Reserve Footprint chart measures the portfolio and benchmark’s exposure to coal, oil and gas reserves. The footprint calculation is based on a list of 100 companies with the largest reserves of coal, oil, and gas.',
    text4:
      'The selected footprint metric is Weighted Average Intensity (Market Value), where the EVIC represents the Market Value.',
    text5:
      'The Country Contributions Breakdown chart displays the contribution of the country (based on the country of domicile) to the total portfolio footprint.',
    text6:
      'The selected footprint metric is Weighted Average Intensity (Market Value), where the EVIC represents the Market Value.',
  },
  avoidedEmission: {
    text1:
      'Globally, governments and regulators are implementing climate policy with an emphasis on companies setting their own Net Zero targets. While this encourages companies to reduce emissions across not only their own direct operations but also their supply and value chains (Scope 3 emissions), the need for companies to also develop new innovative low or zero emissions solutions is becoming increasingly recognised as essential for the global transition to a low carbon economy. The extent to which a company contributes to the broader global goal of Net Zero with decarbonising solutions is measured via its Potential Avoided Emissions, commonly referred to as “Avoided Emissions”[2]. The TCFD reporting guidelines encourage institutions to also consider any climate-related opportunities within their disclosures. ICE has utilised its extensive carbon emissions database to construct an Avoided Emissions methodological framework to help organizations estimate the potential Avoided Emissions in their portfolios and develop a better understanding of their overall climate position by generating climate opportunities.',
    text3:
      'The Avoided (Carbon) Emissions Footprint chart displays the estimated avoided emissions for the portfolio. Avoided emissions estimates are calculated using sector-level methodologies - specifically calculating the carbon savings produced by the constituent companies compared to their industry peers. This methodology looks at avoided emissions in specific sectors that have direct contributions towards carbon savings (e.g. Transportation and Electricity).',
    text4:
      'The selected footprint metric is Weighted Average Intensity (Market Value), where the Enterprise Value including Cash (EVIC) represents the Market Value.',
    text5:
      'The Avoided Emissions Breakdown chart expands on the total calculated portfolio avoided emissions, looking at the different sectors contributing to the avoided emissions in the portfolio. Examples include Transportation - where the methodology looks at avoided emissions from contributions to the manufacturing of electric vehicles and the supporting infrastructure enabling this shift to electric vehicles (e.g., charging stations).',
    text6:
      'The Reported Avoided Emissions chart provides the percentage of companies within the portfolio that publicly report or do not report avoided emissions.',
  },
  alignment: {
    text1:
      'The financial sector has a critical role to play in the net zero transition. In recognition of this fact, an increasing number of financial institutions have committed to aligning their lending or investing portfolios to the goals of the Paris Agreement, and in doing so, will reduce emissions to net-zero by mid-century. This is reflected, for example, by the launch of the Glasgow Financial Alliance for Net Zero (GFANZ) in April 2021. For financial institutions to achieve their climate ambitions and fulfil their critical role in the net-zero transition, however, they need a new set of forward-looking management tools to measure and evaluate the transition progress of their counterparties, and in doing so, identify the engagement activities they must conduct to steer their portfolios toward Paris alignment. Given the continued interest in forward-looking metrics indicated by TCFD signatories, the TCFD Secretariat has worked with the Portfolio Alignment Team (PAT), formed by the UN Special Envoy for Climate and Finance, Mark Carney, to highlight the different portfolio alignment metrics that financial institutions can use.',
    text2:
      'ICE’s TCFD Reporting Solution provides three broad categories of forward-looking portfolio alignment analytics:',
    text3:
      'Binary target measurements: measures the alignment of a portfolio with a given climate outcome based on the percent of companies in [client name] portfolio with declared net zero/Paris-alignment targets.',
    text4:
      'Benchmark divergence models[1]: assesses portfolio alignment by constructing normative benchmarks (emissions pathways that describe what must be done to achieve a given warming target) from forward-looking climate scenarios and comparing constituent company emissions against them. They can then be aggregated at the sector or portfolio level.',
    text5:
      'Implied Temperature Rise (ITR) scores: extends benchmark divergence models one step further, translating an assessment of alignment/ misalignment with a benchmark into a measure of the consequences of that alignment in the form of an ITR score that describes the most likely global warming outcome if the global economy was to exhibit same level of ambition as the counterparty in question.',
    text6:
      'The Binary Target Measurement chart displays the percentage of companies in the portfolio that have or have not disclosed emission reduction targets (covering Scope 1, 2, and 3). For those companies that have disclosed emission reduction targets, this is broken down to include whether they are Science Based Targets initiative (SBTi) validated or non-SBTi validated targets.',
    text6_sc12:
      'The Binary Target Measurement chart displays the percentage of companies in the portfolio that have or have not disclosed emission reduction targets (covering Scope 1 + 2). For those companies that have disclosed emission reduction targets, this is broken down to include whether they are Science Based Targets initiative (SBTi) validated or non-SBTi validated targets.',
    text7:
      'The Alignment chart shows: (i) the emissions trajectory of the portfolio’s financed emissions which is estimated based on constituent company targets and historical changes in the emissions intensities, and (ii) pathways that are aligned to the Network for Greening of the Financial System (NGFS) scenarios: Net Zero 2050, Delayed Transition and Current Policies. The Net Zero 2050 scenario is an orderly below 1.5C scenario, while the Delayed Transition is a disorderly below 2C scenario, and the Current Policies scenario results in global mean temperature risk of 3.2C. These serve as a benchmark transition or baseline pathway, from which it can be established how closely the portfolio is aligned to different temperature outcomes. The alignment chart effectively highlights how closely aligned the portfolio’s emissions trajectory is to the selected scenarios until 2050.',
    text8:
      'The selected market value metric is EVIC. Scope 1, 2 and 3 emissions are considered in the model.',
    text8_sc12:
      'The selected market value metric is EVIC. Scope 1 + 2 emissions are considered in the model.',
    text9:
      'The Cumulative Emissions chart displays the cumulative emissions corresponding to the estimated emissions trajectory and scenario aligned pathways (NGFS - Net Zero 2050, Delayed Transition, and Current Policies). The cumulative emissions are subsequently used for calculating the implied temperature risk score as well',
    text10:
      'The selected market value metric is EVIC. Scope 1, 2 and 3 emissions are considered in the model.',
    text10_sc12:
      'The selected market value metric is EVIC. Scope 1 + 2 emissions are considered in the model.',
    text11:
      'The Implied Temperature Rise Score chart is calculated by factoring in the cumulative emission pathways, associated temperatures derived from the NGFS scenario database and calculating the TCRE (Transient Climate Response to Cumulative Carbon Emissions) - the ratio of globally average surface temperature changes per unit of CO2 emitted. For the calculation of the ITR score, the TCRE is portfolio-specific, based on the industries, financed by the portfolio.',
    text12:
      'The selected market value metric is Enterprise Value including Cash (EVIC). Scope 1, 2 and 3 emissions are considered in the ITR score calculation.',
    text12_sc12:
      'The selected market value metric is Enterprise Value including Cash (EVIC). Scope 1 + 2 emissions are considered in the ITR score calculation.',
  },
  introductions: {
    text1:
      'Aligned with Task Force on Climate-Related Financial Disclosures (TCFD) Recommendations',
    text2:
      '​The latest climate research by global scientific bodies such as the Intergovernmental Panel on Climate Change (IPCC) shows that the current pathway of global greenhouse gas emissions will cause adverse climate change impacts. The large-scale and long-term nature of the problem makes it uniquely challenging, especially in the context of financial decision making. Moreover, the current understanding of the potential financial risks posed by climate change—to companies, investors, and the financial system as a whole—is still evolving.',
    text3:
      'There is a growing demand for decision-useful, climate-related information by a range of participants in the financial markets. Investors, lenders, and insurance underwriters are seeking information to assess the impact and effects of an organisation on climate change, that is consistent, comparable, high quality, and clear.',
    text4:
      '​The G20 Financial Stability Board created the Task Force on Climate-related Financial Disclosures (TCFD) to improve and increase reporting of climate-related financial information. The TCFD first officially published its recommendations in June 2017 and cover how a company would make high-quality disclosures to enable users to understand impacts of climate change on organizations. The disclosures should cover 4 key areas: Governance, Strategy, Risk Management, Metrics and Targets.',
    text5:
      '​TCFD reporting guidelines have increasingly become part of evolving climate-related disclosure regulations across the world. In particular, the Metrics and Targets section of the TCFD reporting guidelines, underpins and informs the other components including Governance, Strategy and Risk Management, creating a feedback loop that further informs an organizations’ overarching business management processes. The Metrics and Targets guidelines are data-intensive, calling for historical and forward-looking elements and a range of science-based methodologies.',
    text6:
      '​ICE’s TCFD Reporting Solution can help address these facets of the Metrics and Targets components of the TCFD reporting guidelines, providing support to organizations’ disclosure of a range of climate-related metrics and targets. The solution can also provide support to organizations’ assessment of resilience to different climate-related scenarios (Scenario Analysis is an important component of the Strategy recommendations).The metrics in this report cover emission scopes 1, 2 & 3, as selected by [client name] and are calculated based on the portfolio and benchmark information provided by [client name].',
    text6_sc12:
      '​ICE’s TCFD Reporting Solution can help address these facets of the Metrics and Targets components of the TCFD reporting guidelines, providing support to organizations’ disclosure of a range of climate-related metrics and targets. The solution can also provide support to organizations’ assessment of resilience to different climate-related scenarios (Scenario Analysis is an important component of the Strategy recommendations).The metrics in this report cover emission scopes 1 & 2 only, as selected by [client name] and are calculated based on the portfolio and benchmark information provided by [client name].',
  },
  topIntensities: {
    contributionText:
      'The Top 10 Intensity Contributors displays the top 10 securities with the highest carbon intensity contribution (scopes 1, 2 & 3) within the portfolio. This is calculated by using a company’s revenue, total emissions, and weighting within the portfolio.',
    intensityText:
      'The Top 10 Intensities displays the top 10 securities with the highest carbon intensities (scopes 1, 2 & 3) within the portfolio. This is calculated by using a company’s revenue and total emissions.',
  },
};

export default strings;
