import React, { useState, useEffect } from 'react';
import { getChartDataFromObject } from '../../../helpers';
import { getLegendName, NET_ZERO_LEGENDS } from '../constants';
import SectorSubplotDisplay from './SectorSubplotDisplay';
import SectorSubplotModel from '../../../models/netZero/sectorSubplot';
import useGetPayload from '../../../hooks/useGetPayload';
import { useGetNetZeroSectorSubplotQuery } from '../../../redux/apiHooks';

const SectorSubplot = () => {
  const { auth, filters, payload } = useGetPayload(SectorSubplotModel);
  const {
    data: sectorSubplotData,
    isFetching,
    error,
  } = useGetNetZeroSectorSubplotQuery(payload);

  const [data, setData] = useState({});
  const [impliedTemp, setImpliedTemp] = useState({});
  const { warmingScenario, chartType } = filters;
  const userYearData = auth.userInfo.year;
  const emissionYear = userYearData?.emissions && parseInt(userYearData.emissions, 10) - 1;

  const sectorKeys = sectorSubplotData
    ? Object.keys(sectorSubplotData)
      .filter((key) => key !== 'metadata')
      .sort()
    : [];

  useEffect(() => {
    if (sectorSubplotData && Array.isArray(warmingScenario)) {
      const sortedWarmingScenario = [...warmingScenario].sort();
      const tempImpliedTemp = {};
      const newSectorData = sectorKeys.reduce((acc, sectorKey) => {
        acc[sectorKey] = sortedWarmingScenario.map((item) => ({
          name: getLegendName(item),
          data: getChartDataFromObject(
            sectorSubplotData[sectorKey][chartType].data[item],
          ),
        }));
        acc[sectorKey].push({
          name: NET_ZERO_LEGENDS.expected,
          data: getChartDataFromObject(
            sectorSubplotData[sectorKey][chartType].data.expected,
          ),
        });
        tempImpliedTemp[sectorKey] = sectorSubplotData[sectorKey].implied_temp;
        return acc;
      }, {});
      setImpliedTemp(tempImpliedTemp);
      setData(newSectorData);
    }
  }, [sectorSubplotData, warmingScenario, chartType]);

  return (
    <SectorSubplotDisplay
      loading={isFetching}
      error={error}
      data={data}
      sectorKeys={sectorKeys}
      emissionYear={emissionYear}
      metadata={sectorSubplotData?.[sectorKeys[0]]?.[chartType].metadata}
      impliedTemp={impliedTemp}
    />
  );
};

export default SectorSubplot;
