// eslint-disable-next-line import/no-cycle
import apiSlice, { getQuery } from '../apiSlice';

export const downloadApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDownloadEmissions: builder.query({
      query: getQuery.post('emissions/platform'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
  }),
});

export const {
  useGetDownloadEmissionsQuery,
  useLazyGetDownloadEmissionsQuery,
} = downloadApiSlice;
