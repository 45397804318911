import React from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    color: theme.palette.primary.main,
    height: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  input: {
    display: 'none',
  },
  label: {
    padding: '4px 10px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    cursor: 'pointer',
    whiteSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const UploadFileButton = ({
  onChange,
  id,
  file,
  acceptFileTypes = '.csv, .xls, .xlsx',
  getOnlyFile = true,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <label htmlFor={id} className={classes.label}>
        <CloudUploadIcon /> {file?.name || 'Select File to Upload'}
      </label>
      <input
        className={classes.input}
        accept={acceptFileTypes}
        type="file"
        name={id}
        id={id}
        onChange={(e) => {
          if (getOnlyFile) onChange(e.target.files[0]);
          else onChange(e);
          e.target.value = null;
        }}
      />
    </div>
  );
};

UploadFileButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  file: PropTypes.object,
  acceptFileTypes: PropTypes.string,
  getOnlyFile: PropTypes.bool,
};

export default UploadFileButton;
