import React from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { useSelector } from 'react-redux';
import TabPanel from '../../components/TabPanel';
import { reportTabs } from '../../util/tabs-config';
import useTabValue from '../../hooks/useTabValueHook';
import {
  TCFD_REPORT,
  PORTFOLIO_REPORT,
  BENCHMARK_REPORT,
} from '../../components/FilterSection/constants';
import GenerateReport from './GeneralReport';
import TCFD from './TCFD';
import BenchmarkReport from './Benchmark';

function Reports() {
  const [tabValue, onTabChange] = useTabValue();
  const { loader } = useSelector((state) => state.report);

  return (
    <>
      <Paper position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={onTabChange}
          indicatorColor="secondary"
          fullWidth="true"
          inkBarStyle={{ background: 'blue' }}
          data-testid="ReportsTabs"
        >
          {reportTabs?.map((e) => (
            <Tab
              key={e}
              label={e}
              style={{ fontSize: 11 }}
              data-testid={`tab-${e}`}
            />
          ))}
        </Tabs>
      </Paper>
      <TabPanel value={tabValue} index={TCFD_REPORT}>
        <TCFD customLoader={loader} />
      </TabPanel>
      <TabPanel value={tabValue} index={PORTFOLIO_REPORT}>
        <GenerateReport customLoader={loader} />
      </TabPanel>
      <TabPanel value={tabValue} index={BENCHMARK_REPORT}>
        <BenchmarkReport />
      </TabPanel>
    </>
  );
}
export default Reports;
