import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import { makeStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { LoadingHOC } from '../../util/LoadingHOC';
import UrgentemDisplay from './UrgentemDisplay';
import { initiateFileDownload } from '../../helpers';
import DeletePortfolio from '../../components/DeletePortfolio';
import PortfolioUpload from '../PortfolioUpload';
import DisplayMessage from '../../components/DisplayMessage';
import { CircleBullet } from '../../components/OnTrackWithTargets';
import colorSchema from '../../Themes/config';
import {
  useCoverageMissingIsinsMutation,
  useCoverageS3UploadMutation,
} from '../../redux/apiHooks';
import { tooltipTextData } from '../../constants';
import TitleTooltip from '../../components/Table/TitleTooltip';
import useGetTheme from '../../hooks/useGetTheme';

const missingIsinsMessage = 'The missing securities from your portfolio have been added to the queue to be processed. Any updated coverage will be reflected the following day.';

const UrgentemDisplayWithLoading = LoadingHOC(UrgentemDisplay);

const useStyles = makeStyles((theme) => ({
  card: {
    margin: 15,
    padding: 20,
    width: 300,
  },
  contentView: {
    margin: 12,
  },
  description: {
    paddingTop: 20,
    fontSize: 16,
  },
  uploadBtn: {
    height: 50,
    width: 300,
    marginLeft: theme.spacing(2),
  },
}));

function UrgentemLanding() {
  const classes = useStyles();
  const { currentTheme } = useGetTheme();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showTrafficLights, setShowTrafficLights] = useState(true);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [showMissingIsinsDialog, setShowMissingIsinsDialog] = useState(false);
  const { portfolioTableRes, isLoading } = useSelector((state) => state.auth);
  const [
    coverageMissingIsins,
    { isLoading: isDownloadLoading, originalArgs: downloadArgs },
  ] = useCoverageMissingIsinsMutation();
  const [
    coverageS3Upload,
    { isLoading: isProccessLoading, originalArgs: processIsinsArgs },
  ] = useCoverageS3UploadMutation();

  const onSwitchChange = () => setShowTrafficLights(!showTrafficLights);

  const onProcessIsins = async (data) => {
    if (isProccessLoading) return;
    const body = { version_portfolio: data.version };
    const portfolioId = data.portfolio_id;
    const response = await coverageS3Upload({ portfolioId, body });
    if (response.error) {
      NotificationManager.error(
        typeof response.error === 'string'
          ? response.error
          : 'Something went wrong',
      );
    } else {
      setShowMissingIsinsDialog(true);
    }
  };

  const onDownloadIsins = async (data) => {
    if (isDownloadLoading) return;
    const body = { version_portfolio: data.version };
    const portfolioId = data.portfolio_id;
    const response = await coverageMissingIsins({ portfolioId, body });
    if (response.error) {
      NotificationManager.error(
        typeof response.error === 'string'
          ? response.error
          : 'Something went wrong',
      );
    } else {
      const blob = new Blob([response.data.missing_isin.join('\n')], {
        type: 'application/csv',
      });
      const url = window.URL.createObjectURL(blob);
      initiateFileDownload(url, `${data.name}.csv`);
    }
  };

  const headCells = [
    {
      name: 'Portfolio Name',
      selector: 'name',
      sortable: true,
      right: false,
      wrap: true,
      cell: (row) => (
        <Tooltip title={`Portfolio Id : ${row.portfolio_id}`}>
          <div>{row.name}</div>
        </Tooltip>
      ),
    },
    {
      name: (
        <TitleTooltip
          title="Emissions Coverage (%)"
          tooltipText={tooltipTextData.emission_coverage}
        />
      ),
      selector: 'coverageWeightEmissions',
      sortable: true,
      right: true,
      wrap: true,
    },
    {
      name: (
        <TitleTooltip
          title={`Disclosure Quality${showTrafficLights ? '' : ' (%)'}`}
          tooltipText={tooltipTextData.disclosure_quality}
        />
      ),
      selector: 'disclosureQualityScore',
      right: true,
      style: { justifyContent: showTrafficLights && 'center' },
      cell: (row) => (showTrafficLights ? (
          <div>
            {row.disclosureQualityScoreColor ? (
              <CircleBullet
                backgroundColor={row.disclosureQualityScoreColor}
                isTooltip={false}
              />
            ) : (
              '-'
            )}
          </div>
      ) : (
        row.disclosureQualityScore
      )),
    },
    {
      name: (
        <TitleTooltip
          title={`Historical Performance${showTrafficLights ? '' : ' (%)'}`}
          tooltipText={tooltipTextData.historical_performance}
        />
      ),
      selector: 'historicalPerColor',
      right: true,
      style: { justifyContent: showTrafficLights && 'center' },
      cell: (row) => (showTrafficLights ? (
          <div>
            {row.historicalPerColor ? (
              <CircleBullet
                backgroundColor={row.historicalPerColor}
                isTooltip={false}
              />
            ) : (
              '-'
            )}
          </div>
      ) : (
        row.historicalQualityScore
      )),
    },
    {
      name: (
        <TitleTooltip
          title={`Target Coverage${showTrafficLights ? '' : ' (%)'}`}
          tooltipText={tooltipTextData.target_coverage}
        />
      ),
      selector: 'targetCoverageScoreColor',
      right: true,
      style: { justifyContent: showTrafficLights && 'center' },
      cell: (row) => (showTrafficLights ? (
          <div>
            {row.targetCoverageScoreColor ? (
              <CircleBullet
                backgroundColor={row.targetCoverageScoreColor}
                isTooltip={false}
              />
            ) : (
              '-'
            )}
          </div>
      ) : (
        row.targetsCovQualityScore
      )),
    },
    {
      name: (
        <TitleTooltip
          title="ITR Score"
          tooltipText={tooltipTextData.Itr_score}
        />
      ),
      selector: 'itrScoreColor',
      right: true,
      style: { justifyContent: showTrafficLights && 'center' },
      cell: (row) => (showTrafficLights ? (
          <div>
            {row.itrScoreColor ? (
              <CircleBullet
                backgroundColor={row.itrScoreColor}
                isTooltip={false}
              />
            ) : (
              '-'
            )}
          </div>
      ) : (
        row.itrScore
      )),
    },
    {
      name: (
        <TitleTooltip
          title="On Track for Targets"
          tooltipText={tooltipTextData.on_track_for_targets}
        />
      ),
      selector: 'onTrackColor',
      right: true,
      style: { justifyContent: 'center' },
      cell: (row) => (showTrafficLights ? (
          <div>
            {row.onTrackColor ? (
              <CircleBullet
                backgroundColor={row.onTrackColor}
                isTooltip={false}
              />
            ) : (
              '-'
            )}
          </div>
      ) : (
          <div>
            {row.onTrackColor ? (
              <CircleBullet
                backgroundColor={row.onTrackColor}
                isTooltip={false}
              />
            ) : (
              'N/A'
            )}
          </div>
      )),
    },
    {
      name: 'Processing Date',
      selector: 'date_created',
      sortable: true,
      right: true,
      wrap: true,
      cell: (row) => (
        <div style={{ marginLeft: 40 }}>
          {row.date_created
            && moment(row.date_created).format('DD-MM-YYYY hh:mm:ss')}
        </div>
      ),
    },
    {
      cell: (row) => (
        <Button
          color="primary"
          variant="contained"
          fullWidth
          size="small"
          style={{ marginRight: 10, fontSize: 8 }}
          onClick={() => onProcessIsins(row)}
        >
          {isProccessLoading
          && row.portfolio_id === processIsinsArgs?.portfolioId ? (
            <CircularProgress
              size="16px"
              style={{ color: colorSchema[currentTheme].buttonText }}
            />
            ) : (
              'PROCESS MISSING ISINS'
            )}
        </Button>
      ),
      button: true,
      width: '150px',
    },
    {
      name: 'Missing ISINs',
      cell: (row) => (
        <IconButton onClick={() => onDownloadIsins(row)}>
          {isDownloadLoading
          && row.portfolio_id === downloadArgs?.portfolioId ? (
            <CircularProgress
              size="24px"
              style={{ color: colorSchema[currentTheme].buttonText }}
            />
            ) : (
            <CloudDownloadIcon
              style={{ color: colorSchema[currentTheme].buttonText }}
            />
            )}
        </IconButton>
      ),
      button: true,
      width: '75px',
    },
  ];

  return (
    <>
      <UrgentemDisplayWithLoading
        loading={isLoading}
        classes={classes}
        setShowUploadDialog={setShowUploadDialog}
        portfolioTableRes={portfolioTableRes}
        headCells={headCells}
        onSwitchChange={onSwitchChange}
        showTrafficLights={showTrafficLights}
      />
      <DeletePortfolio
        dialog={showDeleteDialog}
        handleClose={() => setShowDeleteDialog(false)}
      />
      {showUploadDialog ? (
        <PortfolioUpload
          dialog={showUploadDialog}
          handleClose={() => setShowUploadDialog(false)}
        />
      ) : null}
      <DisplayMessage
        dialog={showMissingIsinsDialog}
        handleClose={() => setShowMissingIsinsDialog(false)}
        message={missingIsinsMessage}
      />
    </>
  );
}

export default UrgentemLanding;
