import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from '../../../../util/formatNumber';

const TopIntensities = ({ data }) => {
  const {
    portfolio,
    filters: { emission },
  } = data;
  return (
    <div className="pdf_page">
      <h2>Top 10 by Intensities</h2>
      <div>
        <div
          style={{
            marginTop: 10,
            fontSize: 12,
            borderBottom: '1px solid #000',
            borderTop: '1px solid #000',
            padding: '10px 0 10px 0px',
            color: 'black',
            fontWeight: 'bold',
          }}
        >
          Top 10 Intensity Contributors{' '}
          {emission === 'Sc12' ? '(Scope 1 + 2)' : '(Scope 1, 2 & 3)'}
        </div>
        <table className="list-of-companies">
          <tr>
            <th>Company Name</th>
            <th>
              Contributions
              <br />
              (tCO2e/1M USD)
            </th>
          </tr>
          {portfolio?.top_10_emission?.by_contribution?.data.map((intensity) => (
            <tr key={intensity.Company}>
              <td>{intensity.Company}</td>
              <td>{formatNumber(intensity.Contribution_Scope_123)}</td>
            </tr>
          ))}
        </table>
      </div>
      <div>
        <div
          style={{
            marginTop: 10,
            fontSize: 12,
            borderBottom: '1px solid #000',
            borderTop: '1px solid #000',
            padding: '10px 0 10px 0px',
            color: 'black',
            fontWeight: 'bold',
          }}
        >
          Top 10 Intensities{' '}
          {emission === 'Sc12' ? '(Scope 1 + 2)' : '(Scope 1, 2 & 3)'}
        </div>
        <table className="list-of-companies">
          <tr>
            <th>Company Name</th>
            <th>
              Intensities
              <br />
              (tCO2e/1M USD)
            </th>
          </tr>
          {portfolio?.top_10_emission?.by_intensities?.data.map((intensity) => (
            <tr key={intensity.Company}>
              <td>{intensity.Company}</td>
              <td>{formatNumber(intensity.Intensity_Scope_123)}</td>
            </tr>
          ))}
        </table>
      </div>
      <div>
        The Top 10 Intensity Contributors displays the top 10 companies with the
        highest carbon intensity contribution{' '}
        {emission === 'Sc12' ? '(scope 1 + 2)' : '(scope 1, 2 & 3)'} within the
        portfolio. This is calculated by using a company’s revenue, total
        emissions, and weighting within the portfolio.
      </div>
      <div>
        The Top 10 Intensities displays the top 10 companies with the highest
        carbon intensities{' '}
        {emission === 'Sc12' ? '(scope 1 + 2)' : '(scope 1, 2 & 3)'} within the
        portfolio. This is calculated by using a company’s revenue and total
        emissions.
      </div>
    </div>
  );
};

TopIntensities.propTypes = {
  data: PropTypes.shape({
    portfolio: PropTypes.object,
    benchmark: PropTypes.object,
    auth: PropTypes.object,
    filters: PropTypes.object,
  }),
};

export default TopIntensities;
