import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import {
  fossilFuelCells,
  countryFossilCells,
} from '../../../util/TableHeadConfig';
import { LoadingHOC } from '../../../util/LoadingHOC';
import FossilFuelDisplay from './FossilFuelDisplay';
import useGetPayload from '../../../hooks/useGetPayload';
import FossilFuelModel from '../../../models/strandedAsset/fossilFuel';
import { useGetStrandedFossielFuelFootprintQuery } from '../../../redux/apiHooks';

const FossilFuelDisplayWithLoading = LoadingHOC(FossilFuelDisplay);

const categoriesMap = {
  gas: 'Gas',
  oil: 'Oil',
  coal: 'Coal',
};

const FossilFuel = () => {
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [countryTableData, setCountryTableData] = useState([]);
  const [yAxisTitle, setYAxisTitle] = useState('');

  const { auth, payload } = useGetPayload(FossilFuelModel);
  const {
    data: fossilFuelData,
    isFetching,
    error,
  } = useGetStrandedFossielFuelFootprintQuery(payload);

  const { showBenchmark } = auth;

  let tableCells = fossilFuelCells;

  if (showBenchmark) {
    tableCells = [
      ...fossilFuelCells,
      {
        name: 'Benchmark',
        selector: 'benchmark',
        sortable: true,
        right: true,
        cell: (row) => <div>{new Intl.NumberFormat().format(row.benchmark)}</div>,
      },
    ];
  }

  const getData = () => {
    let cData = [];
    let tData = [];
    const cTableData = [];
    let yTitle = '';
    let benchGas;
    let benchOil;
    let benchCoal;
    if (!isEmpty(fossilFuelData)) {
      yTitle = fossilFuelData.metadata.label;

      const portGas = fossilFuelData.data.portfolio.gas;
      const portOil = fossilFuelData.data.portfolio.oil;
      const portCoal = fossilFuelData.data.portfolio.coal;
      if (showBenchmark) {
        benchGas = fossilFuelData.data.benchmark.gas;
        benchOil = fossilFuelData.data.benchmark.oil;
        benchCoal = fossilFuelData.data.benchmark.coal;
      }

      cData = [
        {
          name: 'Portfolio',
          data: [portGas, portOil, portCoal],
        },
      ];

      if (showBenchmark) {
        cData.push({
          name: 'Benchmark',
          data: [benchGas, benchOil, benchCoal],
        });
      }

      tData = [
        {
          type: 'Gas',
          portfolio: portGas,
          benchmark: benchGas,
        },
        {
          type: 'Oil',
          portfolio: portOil,
          benchmark: benchOil,
        },
        {
          type: 'Coal',
          portfolio: portCoal,
          benchmark: benchCoal,
        },
      ];
      const tableRes = fossilFuelData.data.table.Percentage;

      if (tableRes && Object.keys(tableRes).length > 0) {
        Object.keys(tableRes).forEach((key) => {
          cTableData.push({
            country: key,
            contribution: tableRes[key],
          });
        });
      }
    }
    setChartData(cData);
    setTableData(tData);
    setCountryTableData(cTableData);
    setYAxisTitle(yTitle);
  };

  useEffect(() => {
    getData();
  }, [fossilFuelData]);

  return (
    <React.Fragment>
      <FossilFuelDisplayWithLoading
        error={error}
        loading={isFetching}
        chartData={chartData}
        tableData={tableData}
        yAxisTitle={yAxisTitle}
        fossilFuelCells={tableCells}
        countryTableData={countryTableData}
        countryFossilCells={countryFossilCells}
        categories={Object.values(categoriesMap)}
      />
    </React.Fragment>
  );
};

export default FossilFuel;
