// import produce from 'immer';
import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
// import { cloneDeep } from 'lodash';
// import * as types from '../actionTypes';
import * as constants from '../../components/FilterSection/constants';
import { getTransitionScoreMetricColor } from '../../helpers';
import { formatNumber } from '../../util/formatNumber';

const initialState = {
  currentUser: {},
  verifyUserRes: {},
  userInfo: {},
  // currentFootprint: {
  //   label: constants.WEIGHTED_AVG_INTENSITY_MARKET,
  //   value: constants.WEIGHTED_AVG_INTENSITY_MARKET_VALUE,
  // },
  // currentAssetType: { label: constants.EQUITY, value: constants.EQUITY },
  // currentEmissions: {
  //   label: constants.SCOPE_1_2,
  //   value: constants.SCOPE_1_2_VALUE,
  // },
  // currentYear: '2021', // USE directly from user info
  // currentQuarter: 'Q2', // USE directly from user info
  // currentCurrency: 'USD', // USE directly from user info
  // selectedDownloadMenu: ['summary'],
  tabValue: 0,
  currentCompany: '',
  currentSector: constants.SASB_VALUE,
  moduleName: 'Dashboard',
  isVisible: true, // NOT Changing anywhere
  loading: false,
  // changeEmailRes: '',
  // shouldMakeInitialRequest: false,
  selectedDependancy: '',
  portfolioList: [],
  currentFundsPortfolio: '',
  currentPortfolio: {},
  currentBenchmark: {},
  downloadPortfolioList: [], // Not in use
  // uploadPortfolioRes: {},
  portfolioTableRes: [],
  selectedDownloadPort: ['summary'], // NOt changing anywhere
  // currencyFixRate: {},
  // allPortfolios: [],
  fundsPortList: [],
  showBenchmark: false,
  sequestration: true,
  selectedPortfolios: [],
  reweightFactor: 0,
};

// export default function authReducer(state = { ...intialState }, action) {
//   switch (action.type) {
//     case PURGE:
//       return produce(intialState);
//     case types.SIGNIN_USER_SUCCESS:
//       return produce(state, (draft) => {
//         draft.currentUser = action.payload;
//       });
//     case types.VERIFY_USER_SUCCESS:
//       return produce(state, (draft) => {
//         draft.verifyUserRes = action.payload;
//       });
//     case types.GET_PORTFOLIO_LIST_SUCCESS: {
//       const portfolioList = action.payload;
//       const result = [];
//       if (portfolioList && portfolioList.length > 0) {
//         portfolioList.forEach((portfolio) => {
//           result.push({
//             label: portfolio.name,
//             value: portfolio.portfolio_id,
//             version: portfolio.version,
//           });
//         });
//       }
//       const currentData = {
//         label: portfolioList[0].name,
//         value: portfolioList[0].portfolio_id,
//         version: portfolioList[0].version,
//       };
//       return produce(state, (draft) => {
//         draft.portfolioList = [...result];

//         draft.currentPortfolio = {
//           ...currentData,
//         };

//         draft.currentBenchmark = {
//           ...currentData,
//         };
//       });
//     }
//     case types.GET_PORTFOLIO_LIST_FAILURE:
//       return produce(state, (draft) => {
//         draft.portfolioList = [];
//         draft.currentPortfolio = {};
//         draft.currentBenchmark = {};
//       });
//     case types.GET_FUNDS_PORTFOLIO_LIST_SUCCESS: {
//       const list = action.payload;
//       const res = [];
//       if (list && list.length > 0) {
//         list.forEach((portfolio) => {
//           if (portfolio.is_parent) {
//             res.push({
//               label: portfolio.name,
//               value: portfolio.portfolio_id,
//               version: portfolio.version,
//             });
//           }
//         });
//       }
//       let fundsData = {};

//       if (res && res.length > 0) {
//         fundsData = {
//           label: res[0].label,
//           value: res[0].value,
//           version: res[0].version,
//         };
//       }

//       return produce(state, (draft) => {
//         draft.fundsPortList = [...res];
//         draft.currentFundsPortfolio = {
//           ...fundsData,
//         };
//         draft.allPortfolios = action.payload;
//       });
//     }
//     case types.GET_FUNDS_PORTFOLIO_LIST_FAILURE:
//       return produce(state, (draft) => {
//         draft.fundsPortList = [];
//         draft.currentFundsPortfolio = {};
//       });

//     case types.GET_USER_INFO:
//       return produce(state, (draft) => {
//         draft.userInfo = action.payload;
//       });
//     case types.UPDATE_CURRENCY_SUCCESS:
//       return produce(state, (draft) => {
//         draft.currentYear = action.payload.year;
//         draft.currentQuarter = action.payload.quarter;
//         draft.currentCurrency = action.payload.currency;
//       });
//     case types.SET_PORTFOLIO:
//       return produce(state, (draft) => {
//         draft.currentPortfolio = action.payload;
//       });
//     case types.SET_FOOTPRINT:
//       return produce(state, (draft) => {
//         draft.currentFootprint = action.payload;
//       });
//     case types.SET_ASSET_TYPE:
//       return produce(state, (draft) => {
//         draft.currentAssetType = action.payload;
//       });
//     case types.SET_FUNDS_PORTFOLIO:
//       return produce(state, (draft) => {
//         draft.currentFundsPortfolio = action.payload;
//       });
//     case types.SET_BENCHMARK:
//       return produce(state, (draft) => {
//         draft.currentBenchmark = action.payload;
//       });
//     case types.SET_TAB_SUCCESS:
//       return produce(state, (draft) => {
//         draft.tabValue = action.payload;
//       });
//     case types.SET_MODULE_SUCCESS:
//       return produce(state, (draft) => {
//         draft.moduleName = action.payload;
//       });
//     case types.SET_SECTOR_SUCCESS:
//       return produce(state, (draft) => {
//         draft.currentSector = action.payload;
//       });
//     case types.SET_COMPANY_SUCCESS:
//       return produce(state, (draft) => {
//         draft.currentCompany = action.payload;
//       });
//     case types.SET_FILTER_VISIBILITY:
//       return produce(state, (draft) => {
//         draft.isVisible = action.payload;
//       });
//     case types.SET_REWEIGHT_FACTOR:
//       return produce(state, (draft) => {
//         draft.reweightFactor = action.payload;
//       });
//     case types.SET_LOADING:
//       return produce(state, (draft) => {
//         draft.loading = action.payload;
//       });
//     case types.SET_SHOW_BENCHMARK:
//       return produce(state, (draft) => {
//         draft.showBenchmark = action.payload;
//       });
//     case types.LOGOUT_USER:
//       return cloneDeep(intialState);
//     case types.GET_DOWNLOAD_PORTFOLIOS_SUCCESS:
//       return produce(state, (draft) => {
//         const portfolioList = action.payload;
//         let result = [];
//         if (portfolioList && portfolioList.length > 0) {
//           result = portfolioList.map((portfolio) => ({
//             label: portfolio.name,
//             value: portfolio.portfolio_id,
//             version: portfolio.version,
//           }));
//         }
//         const currentPortfolio = {
//           label: portfolioList[0].name,
//           value: portfolioList[0].portfolio_id,
//           version: portfolioList[0].version,
//         };

//         draft.downloadPortfolioList = result;
//         draft.selectedDownloadPort = currentPortfolio;
//       });
//     case types.GET_DOWNLOAD_PORTFOLIOS_FAILED:
//       return produce(state, (draft) => {
//         draft.downloadPortfolioList = [];
//       });
//     case types.UPLOAD_PORTFOLIO_SUCCESS:
//       return produce(state, (draft) => {
//         draft.uploadPortfolioRes.data = action.payload;
//         draft.uploadPortfolioRes.error = '';
//       });
//     case types.UPLOAD_PORTFOLIO_FAILED:
//       return produce(state, (draft) => {
//         draft.uploadPortfolioRes.data = {};
//         draft.uploadPortfolioRes.error = action.payload;
//       });
//     case types.GET_UPLOAD_PORTFOLIO_LIST_SUCCESS:
//       return produce(state, (draft) => {
//         const { userInfo } = state;
//         const yearEmissions = userInfo.year && userInfo.year.emissions
//           ? userInfo.year.emissions
//           : '2019';
//         const result = [];
//         const response = action.payload;

//         if (response && response.length > 0) {
//           response.forEach((res) => {
//             let coverageEmissions = 0;
//             let weightEmissions = 0;
//             if (res.coverage_emissions && res.coverage_emissions.length > 0) {
//               res.coverage_emissions.forEach((emission) => {
//                 if (emission.year === yearEmissions) {
//                   coverageEmissions = emission.coverage;
//                   weightEmissions = emission.weight_coverage;
//                 }
//               });
//             }
//             result.push({
//               name: res.name,
//               portfolio_id: res.portfolio_id,
//               version: res.version,
//               coverageEmissions,
//               date_created: res.date_created,
//               coverageWeightEmissions: weightEmissions,
//             });
//           });
//         }
//         draft.portfolioTableRes = result;
//       });
//     case types.GET_UPLOAD_PORTFOLIO_LIST_FAILED:
//       return produce(state, (draft) => {
//         draft.portfolioTableRes = [];
//       });
//     case types.CHANGE_EMAIL_SUCCESS:
//       return produce(state, (draft) => {
//         draft.changeEmailRes = '';
//       });
//     case types.SET_DOWNLOAD_PORTFOLIO:
//       return produce(state, (draft) => {
//         draft.selectedDownloadPort = action.payload;
//       });
//     case types.SET_DOWNLOAD_TAGS:
//       return produce(state, (draft) => {
//         draft.selectedDownloadMenu = action.payload;
//       });

//     case types.SET_EMISSIONS_SUCCESS:
//       return produce(state, (draft) => {
//         draft.currentEmissions = action.payload;
//       });
//     case types.GET_FIX_RATE_SUCCESS:
//       return produce(state, (draft) => {
//         draft.currencyFixRate = action.payload;
//       });
//     case types.GET_ACCESS_TOKEN:
//       return produce(state, (draft) => {
//         draft.currentUser.access_token = action.payload.access_token;
//       });
//     case types.UPLOAD_COVERAGE_CHECK_SUCCESS:
//       return produce(state, (draft) => {
//         draft.uploadCoverageCheckRes.data = action.payload;
//         draft.uploadCoverageCheckRes.error = '';
//       });
//     case types.UPLOAD_COVERAGE_CHECK_FAILED:
//       return produce(state, (draft) => {
//         draft.uploadCoverageCheckRes.data = {};
//         draft.uploadCoverageCheckRes.error = action.payload;
//       });
//     case types.SET_SHOULD_MAKE_INTIAL_REQUEST:
//       return produce(state, (draft) => {
//         draft.shouldMakeInitialRequest = action.payload;
//       });
//     case types.UPDATE_USER_FOOTPRINT:
//       return produce(state, (draft) => {
//         draft.userInfo.footprint_parameters = action.payload;
//       });
//     case types.SET_MULTIPLE_PORTFOLIO:
//       return produce(state, (draft) => {
//         draft.selectedPortfolios = action.payload;
//       });
//     default:
//       return state;
//   }
// }

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    [PURGE]: () => initialState,
    signinUserSuccess: (state, action) => {
      state.currentUser = action.payload;
    },
    verifyUserSuccess: (state, action) => {
      state.verifyUserRes = action.payload;
    },
    getPortfolioListSuccess: (state, action) => {
      const portfolioList = action.payload;
      state.portfolioList = [...portfolioList];
      if (portfolioList.length > 0) {
        const currentData = {
          label: portfolioList[0].label,
          value: portfolioList[0].value,
          version: portfolioList[0].version,
        };
        state.currentPortfolio = { ...currentData };
        state.currentBenchmark = { ...currentData };
      }
    },
    getPortfolioListFailure: (state) => {
      state.portfolioList = [];
      state.currentPortfolio = {};
      state.currentBenchmark = {};
    },
    getFundsPortfolioListSuccess: (state, action) => {
      // const list = action.payload;
      const res = action.payload.fundsPortList;
      // if (list && list.length > 0) {
      //   list.forEach((portfolio) => {
      //     if (portfolio.is_parent) {
      //       res.push({
      //         label: portfolio.name,
      //         value: portfolio.portfolio_id,
      //         version: portfolio.version,
      //       });
      //     }
      //   });
      // }
      let fundsData = {};

      if (res && res.length > 0) {
        fundsData = {
          label: res[0].label,
          value: res[0].value,
          version: res[0].version,
        };
      }

      state.fundsPortList = [...res];
      state.currentFundsPortfolio = {
        ...fundsData,
      };
      state.allPortfolios = action.payload.allPortfolios;
    },
    // getFundsPortfolioListFailure: (state) => {
    //   state.fundsPortList = [];
    //   state.currentFundsPortfolio = {};
    // },
    getUserInfoSuccess: (state, action) => {
      state.userInfo = action.payload;
    },
    // updateCurrencySuccess: (state, action) => {
    //   state.currentYear = action.payload.year;
    //   state.currentQuarter = action.payload.quarter;
    //   state.currentCurrency = action.payload.currency;
    // },
    setPortfolioSuccess: (state, action) => {
      state.currentPortfolio = action.payload;
    },
    // setFootprint: (state, action) => {
    //   state.currentFootprint = action.payload;
    // },
    // setAssetType: (state, action) => {
    //   state.currentAssetType = action.payload;
    // },
    setFundsPortfolioSuccess: (state, action) => {
      state.currentFundsPortfolio = action.payload;
    },
    setBenchmarkSuccess: (state, action) => {
      state.currentBenchmark = action.payload;
    },
    setTabValue: (state, action) => {
      state.tabValue = action.payload;
    },
    setModule: (state, action) => {
      state.moduleName = action.payload;
    },
    setSector: (state, action) => {
      state.currentSector = action.payload;
    },
    setCompany: (state, action) => {
      state.currentCompany = action.payload;
    },
    // setFilterVisibility: (state, action) => {
    //   state.isVisible = action.payload;
    // },
    setReweightFactor: (state, action) => {
      state.reweightFactor = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setShowBenchmark: (state, action) => {
      state.showBenchmark = action.payload;
    },
    logoutUserSuccess: () => initialState,
    getDownloadPortfoliosSuccess: (state, action) => {
      const portfolioList = action.payload;
      let result = [];
      const yearEmissions = state?.userInfo?.year?.emissions || '2019';
      if (portfolioList && portfolioList.length > 0) {
        result = portfolioList.map((portfolio) => ({
          label: portfolio.name,
          value: portfolio.portfolio_id,
          version: portfolio.version,
          date_created: portfolio.date_created,
          coverageEmissions: portfolio.coverage_emissions?.filter(
            ({ year }) => year === yearEmissions,
          )[0]?.coverage,
        }));
        const currentPortfolio = {
          label: portfolioList[0].name,
          value: portfolioList[0].portfolio_id,
          version: portfolioList[0].version,
        };
        state.downloadPortfolioList = result;
        state.selectedDownloadPort = currentPortfolio;
      }
    },
    getUploadPortfolioListSuccess: (state, action) => {
      const { userInfo } = state;
      const yearEmissions = userInfo.year && userInfo.year.emissions
        ? userInfo.year.emissions
        : '2019';
      const result = [];
      const response = action.payload;

      if (response && response.length > 0) {
        response.forEach((res) => {
          let coverageEmissions = 0;
          let weightEmissions = 0;
          let disclosureQualityScore = 0;
          let historicalQualityScore = 0;
          let targetsCovQualityScore = 0;
          let itrScore = 0;
          let otTrackColor = 'orange';
          if (res.coverage_emissions && res.coverage_emissions.length > 0) {
            res.coverage_emissions.forEach((emission) => {
              if (emission.year === yearEmissions) {
                coverageEmissions = emission.coverage;
                weightEmissions = emission.weight_coverage;
              }
            });
          }
          if (res.transition_scores && res.transition_scores.length > 0) {
            res.transition_scores.forEach((transitions) => {
              if (transitions.year === yearEmissions) {
                disclosureQualityScore = transitions.disclosure_quality_score;
                historicalQualityScore = transitions.historic_performance;
                targetsCovQualityScore = transitions.target_coverage;
                itrScore = transitions.itr_score;
                otTrackColor = transitions.on_track_for_targets;
              }
            });
          }
          result.push({
            name: res.name,
            portfolio_id: res.portfolio_id,
            version: res.version,
            coverageEmissions,
            date_created: res.date_created,
            coverageWeightEmissions: weightEmissions,
            disclosureQualityScore:
              formatNumber(disclosureQualityScore) || 'N/A',
            historicalQualityScore:
              formatNumber(historicalQualityScore) || 'N/A',
            targetsCovQualityScore:
              formatNumber(targetsCovQualityScore) || 'N/A',
            itrScore: formatNumber(itrScore) || 'N/A',
            disclosureQualityScoreColor:
              disclosureQualityScore !== 0
                ? getTransitionScoreMetricColor(disclosureQualityScore)
                : 'red',
            historicalPerColor: getTransitionScoreMetricColor(
              historicalQualityScore,
              'historic_performace',
            ),
            targetCoverageScoreColor:
              targetsCovQualityScore !== 0
                ? getTransitionScoreMetricColor(targetsCovQualityScore)
                : 'red',
            itrScoreColor: getTransitionScoreMetricColor(itrScore, 'itr_score'),
            onTrackColor: otTrackColor,
          });
        });
      }
      state.portfolioTableRes = result;
    },
    // getUploadPortfolioListFailed: (state) => {
    //   state.portfolioTableRes = [];
    // },
    // changeEmailSuccess: (state) => {
    //   state.changeEmailRes = '';
    // },
    // setDownloadPortfolio: (state, action) => {
    //   state.selectedDownloadPort = action.payload;
    // },
    // setDownloadTagsSuccess: (state, action) => {
    //   state.selectedDownloadMenu = action.payload;
    // },
    // setEmissionsSuccess: (state, action) => {
    //   state.currentEmissions = action.payload;
    // },
    // getFixRateSuccess: (state, action) => {
    //   state.currencyFixRate = action.payload;
    // },
    // getAccessTokenSuccess: (state, action) => {
    //   state.currentUser.access_token = action.payload.access_token;
    // },
    // uploadCoverageCheckSuccess: (state, action) => {
    //   state.uploadCoverageCheckRes.data = action.payload;
    //   state.uploadCoverageCheckRes.error = '';
    // },
    // uploadCoverageCheckFailed: (state, action) => {
    //   state.uploadCoverageCheckRes.data = {};
    //   state.uploadCoverageCheckRes.error = action.payload;
    // },
    // setShouldMakeInitialRequest: (state, action) => {
    //   state.shouldMakeInitialRequest = action.payload;
    // },
    updateUserFootprint: (state, action) => {
      state.userInfo.footprint_parameters = action.payload;
    },
    setMultiplePortfolio: (state, action) => {
      state.selectedPortfolios = action.payload;
    },
  },
});
export const {
  // changeEmailSuccess,
  // getAccessTokenSuccess,
  // getDownloadPortfoliosFailed,
  getDownloadPortfoliosSuccess,
  getFixRateSuccess,
  // getFundsPortfolioListFailure,
  getFundsPortfolioListSuccess,
  getPortfolioListFailure,
  getPortfolioListSuccess,
  // getUploadPortfolioListFailed,
  getUploadPortfolioListSuccess,
  getUserInfoSuccess,
  logoutUserSuccess,
  // setAssetType,
  setBenchmarkSuccess,
  setCompany,
  // setDownloadPortfolio,
  // setDownloadTagsSuccess,
  // setEmissionsSuccess,
  // setFilterVisibility,
  // setFootprint,
  setFundsPortfolioSuccess,
  setLoading,
  setModule,
  setMultiplePortfolio,
  setPortfolioSuccess,
  setReweightFactor,
  setSector,
  // setShouldMakeInitialRequest,
  setShowBenchmark,
  setTabValue,
  signinUserSuccess,
  // updateCurrencySuccess,
  updateUserFootprint,
  // uploadCoverageCheckFailed,
  // uploadCoverageCheckSuccess,
  // uploadPortfolioFailed,
  // uploadPortfolioSuccess,
  verifyUserSuccess,
} = authSlice.actions;

export default authSlice.reducer;
