/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import React from 'react';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { isEmpty } from 'lodash';

/**
 * An array of objects representing footprint data.
 * Only Related to PCAF data
 * @typedef {Array<object>} Footprint Data Array
 *
 * @property {string} name - One of [Loans, Equity, Corporate Bonds]
 * @property {string} Public Attribution
 * @property {string} Private Attribution
 * @property {string} Emission Type
 * @property {string} Methodology - One of [ pcaf, default ]
 */

export const getFootprintParameters = (userFooprintParams, accordionData) => {
  const footprintParams = [];
  Object.keys(userFooprintParams).forEach((key) => {
    const obj = {};
    const i = accordionData && accordionData.findIndex((option) => option.key === key);
    if (i !== -1) {
      obj.name = accordionData && accordionData[i].filterName;
      obj['Public Attribution'] = userFooprintParams[key].attribution.public;
      obj['Private Attribution'] = userFooprintParams[key].attribution.private;
      obj['Emission Type'] = userFooprintParams[key].emissions_type;
      obj.Methodology = userFooprintParams[key].strategy;
    }
    footprintParams.push(obj);
  });
  return footprintParams;
};

export const generatePCAFInfoData = (
  parameterSettingsData,
  footprint,
  footprintMetricFromFilter,
) => {
  const data = {};
  parameterSettingsData.forEach((param) => {
    const obj = {};
    const attribution = {};

    attribution.private = param['Private Attribution'];
    attribution.public = param['Public Attribution'];

    obj.attribution = attribution;
    obj.strategy = param.Methodology;
    obj.emissions_type = param['Emission Type'];
    obj.footprint = param.Methodology === 'pcaf'
      ? 'TotalCarbEmis'
      : footprint === null
        ? footprintMetricFromFilter
        : footprint;

    if (param.name === 'Loans') {
      obj.security_type = 'loan';
      data.Loan = obj;
    } else if (param.name === 'Equity') {
      obj.security_type = 'equity';
      data.Equity = obj;
    } else {
      obj.security_type = 'corporate_bond';
      data.Corporate_Bond = obj;
    }
  });
  return data;
};

const scoreInfo = 'Score 1: Outstanding amount in the company and EVIC are known. Verified emissions of the company are available.\nScore 2: Outstanding amount in the company and EVIC are known. Unverified emissions calculated by the company are available.\nScore 3: Outstanding amount in the company and EVIC are known. Reported company emissions are not known. Emissions are calculated using primary physical activity data of the company’s production and emission factors specific to that primary data.\nScore 4: Outstanding amount in the company, EVIC, and the company’s revenue are known. Emission factors for the sector per unit of revenue are known.\nScore 5: Outstanding amount in the company is known. Emission factors for the sector per unit of asset (e.g., tCO2e per euro of asset in a sector) are known and asset turnover ratios for the sector are known.';

export const disclosureTable = [
  {
    name: '',
    selector: 'name',
    sortable: true,
    right: false,
    wrap: true,
    style: {
      minWidth: 120,
    },
  },
  {
    name: 'Public, complete Scope 1 and 2 data, third-party assurance',
    selector: 'column1',
    sortable: true,
    right: true,
    wrap: true,
  },
  {
    name: 'Public, complete Scope 1 and 2 data, no third-party assurance',
    selector: 'column2',
    sortable: true,
    right: true,
  },
  {
    name: 'Incomplete Scope 1 and 2 data, no third-party assurance',
    selector: 'column3',
    sortable: true,
    right: true,
  },
  {
    name: 'No public data',
    selector: 'column4',
    sortable: true,
    right: true,
  },
  {
    name: 'Not directly analysed',
    selector: 'column5',
    sortable: true,
    right: true,
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    right: true,
  },
];

function NewlineText(props) {
  const { text } = props;
  const newText = text.split('\n').map((str) => <p key={str}>{str}</p>);

  return newText;
}
export const pcafTable = [
  {
    name: (
      <Tooltip title={<NewlineText text={scoreInfo} />}>
        <InfoIcon color="primary" />
      </Tooltip>
    ),
    selector: 'name',
    sortable: false,
    right: false,
    wrap: true,
    style: {
      minWidth: 120,
    },
  },
  {
    name: 'Score 1',
    selector: 'column1',
    sortable: true,
    right: true,
  },
  {
    name: 'Score 2',
    selector: 'column2',
    sortable: true,
    right: true,
  },
  {
    name: 'Score 3',
    selector: 'column3',
    sortable: true,
    right: true,
  },
  {
    name: 'Score 4',
    selector: 'column4',
    sortable: true,
    right: true,
  },
  {
    name: 'Score 5',
    selector: 'column5',
    sortable: true,
    right: true,
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    right: true,
  },
];

export const disclosureCategoryList = [
  'Public, complete Scope 1 and 2 data, third-party assurance',
  'Public, complete Scope 1 and 2 data, no third-party assurance',
  'Incomplete Scope 1 and 2 data, no third-party assurance',
  'No public data',
  'Not directly analysed',
];

export const pcafTableList = [
  'Portfolio Scope 1+2',
  'Benchmark Scope 1+2',
  'Portfolio Scope 1+2+3',
  'Benchmark Scope 1+2+3',
];

const assetTypeMap = {
  'Corporate Bonds': 'Corporate Bond',
  Loans: 'Loan',
  Equity: 'Equity',
};

export const generatePcafScore = ({
  benchmarkScore,
  portfolioScore,
  scoreName,
  assetType,
  showBenchmark,
}) => {
  const type = assetTypeMap[assetType];
  const pcafScore = {};
  const scoreData = [];
  let bSum123 = 0;
  let bSum12 = 0;
  let pSum123 = 0;
  let pSum12 = 0;
  benchmarkScore.forEach((s) => {
    if (!assetType || type === s.type) {
      bSum123 += s.sc123;
      bSum12 += s.sc12;
    }
  });
  portfolioScore.forEach((s) => {
    if (!assetType || type === s.type) {
      pSum123 += s.sc123;
      pSum12 += s.sc12;
    }
  });
  if (showBenchmark) {
    scoreData.push(Math.round(bSum123 * 100 + Number.EPSILON) / 100);
  }
  scoreData.push(Math.round(pSum123 * 100 + Number.EPSILON) / 100);
  if (showBenchmark) {
    scoreData.push(Math.round(bSum12 * 100 + Number.EPSILON) / 100);
  }
  scoreData.push(Math.round(pSum12 * 100 + Number.EPSILON) / 100);
  pcafScore.name = scoreName;
  pcafScore.data = scoreData;
  return pcafScore;
};

export const generatePCAF = ({
  portfolioEmissionData,
  isPcafScore,
  showBenchmark,
  assetType,
}) => {
  const pcaf = [];
  if (isPcafScore) {
    for (let i = 0; i < 5; i++) {
      const pcafScore = generatePcafScore({
        benchmarkScore: showBenchmark
          ? portfolioEmissionData.benchmark?.[`PCAF_score_${i + 1}`] || []
          : [],
        portfolioScore: portfolioEmissionData.portfolio[`PCAF_score_${i + 1}`],
        scoreName: `Score ${i + 1}`,
        showBenchmark,
        assetType,
      });
      pcaf.push(pcafScore);
    }
  } else {
    for (let i = 0; i < 5; i++) {
      const pcafScore = generatePcafScore({
        benchmarkScore: showBenchmark
          ? portfolioEmissionData.benchmark?.[`Disclosure_Category_${i + 1}`]
            || []
          : [],
        portfolioScore:
          portfolioEmissionData.portfolio[`Disclosure_Category_${i + 1}`],
        scoreName: disclosureCategoryList[i],
        showBenchmark,
        assetType,
      });
      pcaf.push(pcafScore);
    }
  }
  return pcaf;
};

export const getReportedInferredData = ({
  pcafData,
  showBenchmark,
  assetType,
}) => {
  const reportedI = [];
  if (!isEmpty(pcafData)) {
    const benchmarkReported = showBenchmark
      ? pcafData.data.benchmark.reported
      : [];
    const benchmarkInferred = showBenchmark
      ? pcafData.data.benchmark.inferred
      : [];

    const portfolioReported = pcafData.data.portfolio.reported;
    const portfolioInferred = pcafData.data.portfolio.inferred;

    reportedI.push(
      generatePcafScore({
        benchmarkScore: benchmarkReported,
        portfolioScore: portfolioReported,
        scoreName: 'Reported',
        showBenchmark,
        assetType,
      }),
    );
    reportedI.push(
      generatePcafScore({
        benchmarkScore: benchmarkInferred,
        portfolioScore: portfolioInferred,
        scoreName: 'Inferred',
        showBenchmark,
        assetType,
      }),
    );
    return reportedI;
  }
  return reportedI;
};

export const getPublicPrivateReportData = ({
  pcafData,
  showBenchmark,
  assetType,
}) => {
  const publicP = [];
  if (!isEmpty(pcafData)) {
    const benchmarkPublic = showBenchmark ? pcafData.data.benchmark.public : [];
    const benchmarkPrivate = showBenchmark
      ? pcafData.data.benchmark.private
      : [];

    const portfolioPublic = pcafData.data.portfolio.public;
    const portfolioPrivate = pcafData.data.portfolio.private;

    publicP.push(
      generatePcafScore({
        benchmarkScore: benchmarkPublic,
        portfolioScore: portfolioPublic,
        scoreName: 'Public',
        assetType,
        showBenchmark,
      }),
    );
    publicP.push(
      generatePcafScore({
        benchmarkScore: benchmarkPrivate,
        portfolioScore: portfolioPrivate,
        scoreName: 'Private',
        assetType,
        showBenchmark,
      }),
    );
    return publicP;
  }
  return publicP;
};

export const generateSectorData = ({
  pcafData,
  showBenchmark,
  assetType,
  emission,
}) => {
  const findType = (data, type) => {
    let flag = false;
    for (const [k, v] of Object.entries(data)) {
      if (!assetType || k === assetType) {
        if (v.findIndex((arr) => arr.type === type) >= 0) {
          flag = true;
          break;
        }
      }
    }

    if (flag) return 0;
    return -1;
  };
  const categories = [];
  const benchmark = [];
  const categoriesData = [];

  if (!isEmpty(pcafData)) {
    const portfolioData = pcafData.data.portfolio.sector;
    const benchmarkData = showBenchmark && pcafData.data.benchmark.sector;

    const pObj = {};
    const bObj = {};
    pObj.name = 'portfolio';
    const pData = [];
    const bData = [];

    for (const [k, v] of Object.entries(portfolioData)) {
      if (!assetType || k === assetType) {
        v.forEach((arr) => {
          if (!categories.includes(arr.type)) categories.push(arr.type);
        });
      }
    }

    if (showBenchmark) {
      bObj.name = 'benchmark';
      for (const [k, v] of Object.entries(benchmarkData)) {
        if (!assetType || k === assetType) {
          v.forEach((arr) => {
            if (!benchmark.includes(arr.type)) benchmark.push(arr.type);
          });
        }
      }
    }

    for (let i = 0; i < benchmark.length; i++) {
      if (!categories.includes(benchmark[i])) {
        categories.push(benchmark[i]);
      }
    }

    categories.forEach((p) => {
      const pO = {};
      const bO = {};
      if (findType(portfolioData, p) === -1) {
        pO.name = p;
        pO.y = 0;
      } else {
        pO.name = p;

        for (const [k, v] of Object.entries(portfolioData)) {
          if (!assetType || k === assetType) {
            if (v.findIndex((arr) => arr.type === p) !== -1) {
              const val = v.find((arr) => arr.type === p);
              emission === 'Sc12' ? (pO.y = val.sc12) : (pO.y = val.sc123);
            }
          }
        }
      }

      pData.push(pO);

      if (showBenchmark) {
        if (findType(benchmarkData, p) === -1) {
          bO.name = p;
          bO.y = 0;
        } else {
          bO.name = p;

          for (const [k, v] of Object.entries(benchmarkData)) {
            if (!assetType || k === assetType) {
              if (v.findIndex((arr) => arr.type === p) !== -1) {
                const val = v.find((arr) => arr.type === p);

                emission === 'Sc12' ? (bO.y = val.sc12) : (bO.y = val.sc123);
              }
            }
          }
        }
        bData.push(bO);
      }
    });

    pObj.data = pData;
    categoriesData.push(pObj);
    if (showBenchmark) {
      bObj.data = bData;
      categoriesData.push(bObj);
    }
  }
  return { categories, categoriesData };
};
