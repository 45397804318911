/* eslint-disable */

import BaseRequest from '../baseRequest';

export default class SectorEmissions extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem } = props;
    const { footprintMetric, sector, marketValue, assetClass } = filterItem;
    this.footprint = footprintMetric;
    this.sector = sector;
    this.market_value = marketValue;
    this.asset_type = assetClass;
  }
}
