import React from 'react';
import PropTypes from 'prop-types';
import DonutChart from '../../Charts/DonutChart';

function ReportDonutChart({ data, height, ...props }) {
  return (
    <DonutChart
      data={data}
      height={height}
      theme={'ICE light'}
      isExportable={false}
      plotLineWidth={1}
      showLegend={true}
      fontSize={7}
      isReport={true}
      chartType="pie"
      {...props}
    />
  );
}

ReportDonutChart.propTypes = {
  data: PropTypes.array,
  height: PropTypes.number,
};

export default ReportDonutChart;
