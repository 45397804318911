import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
// import { get } from 'lodash';
// import { getSummary } from '../../../services/fundOfFunds';
// import {
//   // getFundsPortfolioList,
//   setFundsPortfolio,
// } from '../../../services/auth';
import { LoadingHOC } from '../../../util/LoadingHOC';
import SummaryDisplay from './SummaryDisplay';
import { useLazyGetFoFSummaryQuery } from '../../../redux/apiHooks';

const SummaryDisplayWithLoading = LoadingHOC(SummaryDisplay);

const CACHE_DATA = true;

const Summary = () => {
  // const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [getSummary, { data, error, isFetching }] = useLazyGetFoFSummaryQuery();
  const currentFundsPortfolio = useSelector(
    (state) => state.auth.currentFundsPortfolio,
  );

  // const auth = useSelector((state) => state.auth);

  // const { currentFundsPortfolio, userInfo } = auth;

  // const summary = useSelector((state) => state.fund.summary);

  useEffect(() => {
    if (currentFundsPortfolio?.value) {
      getSummary(currentFundsPortfolio.value, CACHE_DATA);
    }
  }, [currentFundsPortfolio?.value]);

  // const fetchDetails = async () => {
  //   const { fundsData } = await dispatch(getFundsPortfolioList());
  //   if (currentFundsPortfolio.value) {
  //     await dispatch(setFundsPortfolio(currentFundsPortfolio));
  //     fundsData.value = currentFundsPortfolio.value;
  //   }
  //   await dispatch(getSummary(fundsData.value));
  // };

  const getData = () => {
    const cData = [];
    const tData = [];

    if (data && data.length > 0) {
      data.forEach((port) => {
        cData.push({
          name: port.name,
          data: [port.weight],
        });
        tData.push({
          name: port.name,
          weight: port.weight,
          emission: port.coverage_emissions[0].coverage,
          fundamentals: port.coverage_emissions[0].weight_coverage,
        });
      });
    }
    setChartData(cData);
    setTableData(tData);
  };

  useEffect(() => {
    if (data) getData();
  }, [data]);

  return (
    <React.Fragment>
      <Box>
        <SummaryDisplayWithLoading
          loading={isFetching}
          error={error}
          chartData={chartData}
          tableData={tableData}
        />
      </Box>
    </React.Fragment>
  );
};

export default Summary;
