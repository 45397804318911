import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Box,
} from '@material-ui/core';
// import DescriptionIcon from '@material-ui/icons/Description';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import CodeIcon from '@material-ui/icons/Code';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { LinkContainer } from 'react-router-bootstrap';
import Logo from '../../assets/ICE_logo_white.png';
import colorSchema from '../../Themes/config';
import UserSessionHandler from '../../handler';
import {
  useLazyGetCurrencyDataQuery,
  useLazyLogoutQuery,
} from '../../redux/apiHooks';
import useGetTheme from '../../hooks/useGetTheme';

const drawerWidth = 295;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  icon: {
    marginRight: 10,
  },
}));
const Header = () => {
  const history = useHistory();
  const classes = useStyles();
  const [getFixCurrency, { data: currencyFixRate }] = useLazyGetCurrencyDataQuery();
  const [logout] = useLazyLogoutQuery();

  const [anchorEl, setAnchor] = useState(null);
  const { userInfo, currentUser } = useSelector((state) => state.auth);
  const { currentTheme } = useGetTheme();
  const currentCurrency = userInfo?.display_currency || 'USD';
  const emissionYear = userInfo?.year?.emissions || 2019;
  const isEmbeded = UserSessionHandler.getCookie();
  const currentCurrencyYear = userInfo?.year?.currency;
  const currentCurrencyQuarter = userInfo?.quarter?.currency;

  useEffect(() => {
    if (currentCurrencyYear && currentCurrencyQuarter) {
      getFixCurrency({
        year: currentCurrencyYear,
        quarter: currentCurrencyQuarter,
      });
    }
  }, [currentCurrencyYear, currentCurrencyQuarter, currentCurrency]);

  const menuItems = [
    {
      label: 'Settings',
      Icon: SettingsIcon,
      path: '/settings',
      visible: true,
    },
    {
      label: 'API',
      Icon: CodeIcon,
      path: '/urgentem-api',
      visible: true,
    },
    // {
    //   label: 'Private Inference',
    //   Icon: DescriptionIcon,
    //   path: '/private-inference',
    //   visible: true,
    // },
    {
      label: 'Remove Portfolios',
      Icon: SupervisorAccountIcon,
      path: '/remove-portfolios',
      visible: true,
    },
    {
      label: 'Logout',
      Icon: ExitToAppIcon,
      visible: !isEmbeded,
      onClick: () => logout(),
    },
  ];

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar disableGutters={true}>
        <div
          className="navbar-section-toolbar"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div className="logo-container">
            <LinkContainer to="/">
              <img
                src={Logo}
                alt="website logo"
                height="37px"
                style={{ width: '25%' }}
              />
            </LinkContainer>
          </div>

          <Box
            variant="body1"
            color="inherit"
            // noWrap
            style={{ color: colorSchema[currentTheme].tableHeaderText }}
          >
            <div>
              {' '}
              {Object.keys(currentUser).length !== 0
                && `${currentUser?.display_name} / ${currentUser?.client}`}{' '}
            </div>
            <div style={{ fontSize: 12 }}>
              Emissions Dataset Year - {emissionYear}{' '}
            </div>
            <div style={{ fontSize: 12 }}>
              Currency - USD - {currencyFixRate?.[currentCurrency] || 1}{' '}
              {currentCurrency}{' '}
            </div>
          </Box>
        </div>
        <div>
          <IconButton
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={(e) => setAnchor(e.currentTarget)}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchor(null)}
          >
            {menuItems.map(({
              label, Icon, path, onClick, visible,
            }) => {
              if (!visible) return null;
              return (
                <MenuItem
                  key={label}
                  onClick={() => {
                    if (path) history.push(path);
                    if (onClick instanceof Function) onClick();
                  }}
                >
                  <Icon className={classes.icon} />
                  {label}
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
