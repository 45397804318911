import { createTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import colorSchema from './config';
import convertHexToRGBA from '../util/convertHexToRGBA';
// Normal or default theme

export default function createCommonTheme(currentTheme) {
  const theme = createTheme({
    overrides: {
      MuiCard: {
        root: {
          backgroundColor: `${colorSchema[currentTheme].mainBackground} !important`,
          color: colorSchema[currentTheme].text,
          border: '1px solid #b4b4b4',
        },
      },
      MiniDrawer: {
        root: {
          width: '100%',
        },
      },
      MuiButton: {
        root: {
          backgroundColor: `${colorSchema[currentTheme].primary} !important`,
          color: `${colorSchema[currentTheme].buttonText} !important`,
          '&:hover': {
            backgroundColor: `${colorSchema[currentTheme].primary} !important`,
            color: `${colorSchema[currentTheme].buttonText} !important`,
          },
          '&:disabled': {
            backgroundColor: `${colorSchema[currentTheme].disabledBackground} !important`,
            color: `${colorSchema[currentTheme].disabledText} !important`,
          },
        },
      },
      MuiAppBar: {
        root: {
          backgroundColor: `${colorSchema[currentTheme].topNavBackground} !important`,
        },
      },
      MuiDrawer: {
        paperAnchorDockedLeft: {
          background: `${colorSchema[currentTheme].sideNavBackground} !important`,
          width: 90,
          overflowX: 'hidden',
        },
      },
      MuiInputLabel: {
        root: {
          color: colorSchema[currentTheme].inputLabel,

          '&$focused': {
            color: convertHexToRGBA(colorSchema[currentTheme].inputLabel, 55),
          },
        },
      },
      MuiInputBase: {
        root: {
          color: colorSchema[currentTheme].text,
          '&$after': {
            backgroundColor: '#cecece',
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          color: colorSchema[currentTheme].text,
          '&$after': {
            backgroundColor: '#cecece',
          },
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: colorSchema[currentTheme].mainBackground,
        },
      },
      MuiFormLabel: {
        root: {
          color: '#cecece',
        },
      },
      MuiTypography: {
        root: {
          color: `${colorSchema[currentTheme].text} !important`,
          fontSize: 12,
        },
      },
      MuiFormControlLabel: {
        root: {
          marginLeft: 0,
        },
      },
      MuiListItem: {
        button: {
          '&:hover': {
            color: colorSchema[currentTheme].primary,
          },
        },
      },
      MuiChip: {
        root: {
          borderRadius: 'none !important',
        },
        colorPrimary: {
          backgroundColor: `${colorSchema[currentTheme].primary} !important`,
          border: '1px solid transparent',
        },
      },
      MuiList: {
        root: {
          paddingTop: '0px !important',
        },
      },
      MuiAccordionSummary: {
        content: {
          display: 'block !important',
        },
      },
      MUiAutocomplete: {
        root: {
          paddingTop: -3,
        },
      },
      MuiTabs: {
        root: {
          background: colorSchema[currentTheme].tabHeaderBackground,
          color:
            currentTheme === 'ICE light'
              ? colorSchema[currentTheme].tabHeaderText
              : colorSchema[currentTheme].text,
        },
      },
      MuiCheckbox: {
        root: {
          color: colorSchema[currentTheme].primary,
        },
        colorSecondary: {
          '&.Mui-checked': {
            color: colorSchema[currentTheme].primary,
          },
        },
      },
      MuiFab: {
        primary: {
          backgroundColor: colorSchema[currentTheme].primary,
          '&:hover': {
            backgroundColor: colorSchema[currentTheme].primary,
          },
        },
      },
    },

    palette: {
      type: colorSchema[currentTheme].themeType,
      text: {
        secondary: colorSchema[currentTheme].text,
      },
      primary: {
        main: colorSchema[currentTheme].primary,
        light: 'rgb(81, 91, 95)',
        dark: colorSchema[currentTheme].mainBackground,
        contrastText: '#ffffff',
        headerBackground: colorSchema[currentTheme].tabHeaderBackground,
      },
      background: {
        default: colorSchema[currentTheme].mainBackground,
      },
      secondary: {
        main: colorSchema[currentTheme].secondary,
        light: 'rgb(255, 197, 112)',
        dark: colorSchema[currentTheme].mainBackground,
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      // titleBar: {
      //     main: "#414141",
      //     contrastText: "#ffffff",
      // },
      error: {
        main: red.A400,
      },
    },
  });
  return theme;
}
