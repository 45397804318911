import React from 'react';
import PropTypes from 'prop-types';
import image from '../../../../assets/portfolio-report.jpg';

const Introduction = ({ data }) => {
  const { portfolio, benchmark, auth } = data;
  const day = new Date();
  const today = day.toLocaleString('default', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
  return (
    <div className="pdf_page">
      <h1 style={{ fontSize: 40, marginBottom: 0 }}>
        Portfolio Analytics Report for <br />
        {auth.currentUser.client}
      </h1>
      <b style={{ fontSize: 18 }}>Report Date: {today}</b>
      <img className="span-2" src={image} width="100%" height={500} />
      <div>
        <h3>Portfolio Summary</h3>
        <table className="table-of-content">
          <tbody>
            <tr>
              <td>Portfolio Name</td>
              <td>{auth.currentPortfolio.label}</td>
            </tr>
            <tr>
              <td>Benchmark Name</td>
              <td>{auth.currentBenchmark.label}</td>
            </tr>
            <tr>
              <td>Portfolio Value</td>
              <td>{portfolio?.portfolio_value} million</td>
            </tr>
            <tr>
              <td>Currency</td>
              <td>{auth.userInfo.display_currency}</td>
            </tr>
            <tr>
              <td>Portfolio Coverage</td>
              <td>{portfolio?.coverage.emissions}%</td>
            </tr>
            <tr>
              <td>Benchmark Coverage</td>
              <td>{benchmark?.coverage.emissions}%</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h3>
          Report Specifications<sup style={{ fontSize: 6 }}>1</sup>
        </h3>
        <table className="table-of-content">
          <tr className="bold">
            <td>Parameters</td>
            <td>Selection</td>
          </tr>
          <tr>
            <td>Footprint Metric</td>
            <td>Weighted Average Intensity</td>
          </tr>
          <tr>
            <td>Market Value</td>
            <td>Enterprise Value including Cash (EVIC)</td>
          </tr>
          <tr>
            <td>Inference Type</td>
            <td>Average</td>
          </tr>
          <tr>
            <td>Asset Type</td>
            <td>Equity, Corporate Bonds & Loan</td>
          </tr>
          <tr>
            <td>Sector Classification</td>
            <td>ICE</td>
          </tr>
          <tr>
            <td>Emission Scope</td>
            <td>Scope 1, 2 & 3</td>
          </tr>
          <tr>
            <td>Scenario</td>
            <td>NGFS</td>
          </tr>
          <tr>
            <td>Emissions Pathway</td>
            <td>Net Zero 2050, Delayed Transition & Current Policies</td>
          </tr>
          <tr>
            <td>Dataset Period</td>
            <td>{auth?.userInfo?.year?.emissions}</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

Introduction.propTypes = {
  data: PropTypes.shape({
    portfolio: PropTypes.object,
    benchmark: PropTypes.object,
    tempScore: PropTypes.array,
    auth: PropTypes.object,
    filters: PropTypes.object,
  }),
};

export default Introduction;
