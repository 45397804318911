import React from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import TabPanel from '../../components/TabPanel';
import { footprintTabs } from '../../util/tabs-config';
import PortfolioEmission from './PortfolioEmission/PortfolioEmission';
import CarbonEmission from './CarbonEmission/CarbonEmission';
import AvoidedEmission from './AvoidedEmission/AvoidedEmission';
import Disclosure from './Disclosure/Disclosure';
import CarbonAttribution from './CarbonAttribution/CarbonAttribution';
import CompanyEmission from './CompanyEmission/CompanyEmission';
import Company from './Company/Company';

import useTabValue from '../../hooks/useTabValueHook';

function PortfolioFootprint() {
  const [tabValue, onTabChange] = useTabValue();

  return (
    <>
      <Paper position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={onTabChange}
          indicatorColor="secondary"
          data-testid="PortfolioFootprintTabs"
        >
          {footprintTabs.map((e, i) => (
            <Tab
              key={i}
              label={e}
              style={{ fontSize: 11 }}
              data-testid={`tab-${e}`}
            />
          ))}
        </Tabs>
      </Paper>
      <TabPanel value={tabValue} index={0}>
        <PortfolioEmission />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <CarbonEmission />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <CompanyEmission />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <CarbonAttribution />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <Disclosure />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <AvoidedEmission />
      </TabPanel>
      <TabPanel value={tabValue} index={6}>
        <Company />
      </TabPanel>
    </>
  );
}
export default PortfolioFootprint;
