export const EMISSION_YEAR = 2019;

export const NET_ZERO_LEGENDS = {
  expected: 'Estimated Emissions Trajectory',
  SCENARIO_ALIGNED_EMISSIONS_PATHWAY: 'Scenario Aligned Emissions Pathway',
};

export const TRAJECTORY = {
  PORTFOLIO_TRAJECTORY: 'Portfolio Trajectory',
  BENCHMARK_TRAJECTORY: 'Benchmark Trajectory',
};

export const addAlignedPathwaySuffix = (text) => `${text} - Aligned Pathway`;

export const getLegendName = (key) => NET_ZERO_LEGENDS[key] || addAlignedPathwaySuffix(key);
