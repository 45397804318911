import React, { useState } from 'react';
import {
  OutlinedInput,
  InputLabel,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Typography,
  DialogContent,
  Dialog,
  Box,
  DialogActions,
  IconButton,
  Button,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import {
  useValidateUniqueIsinsMutation,
  useGetPortBuilderUploadFromJsonMutation,
} from '../../redux/apiHooks';
import colorSchema from '../../Themes/config';
import { downloadExcel } from '../../helpers';
import useGetTheme from '../../hooks/useGetTheme';
import NumberInputWithCommas from '../NumberInputWithCommas';

const useStyles = makeStyles(() => ({
  dialogContent: {
    borderTopStyle: 'groove',
    borderBottomColor: '#D3D3D3',
    borderTopWidth: '1px',
    borderBottomStyle: 'groove',
    borderBottomWidth: '1px',
    paddingTop: 16,
    paddingBottom: 16,
    width: '600px',
  },
  title: { marginLeft: 20, marginTop: 10, fontSize: 22 },
  boldText: {
    fontWeight: 'bold',
    margin: '16px 0',
  },
}));

const SavePortfolio = () => {
  const classes = useStyles();
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [name, setName] = useState('');
  const [value, setValue] = useState('1000000000');
  const [weighted, setWeighted] = useState('');
  const { currentTheme } = useGetTheme();
  // redux values
  const { data } = useSelector((state) => state.portfolioBuilder);
  const [
    validateUniqueIsinsMutation,
    {
      isLoading: isValidateLoading,
      data: validateResponse,
      isError: isValidateError,
      error,
      reset,
      isUninitialized,
    },
  ] = useValidateUniqueIsinsMutation();

  const [savePortfolioData, { isLoading: isSaveLoading }] = useGetPortBuilderUploadFromJsonMutation();

  const onSave = () => {
    if (isValidateLoading) return;
    validateUniqueIsinsMutation({
      isins: data.map((portfolio) => portfolio.isin),
    });
  };

  const onClose = () => {
    setShowSaveDialog(false);
    reset();
    setName('');
    setValue('');
    setWeighted('');
  };

  const onAgree = async () => {
    if (isSaveLoading) return;
    if (isValidateError) {
      onClose();
      return;
    }
    const payload = {
      name,
      value,
      weighted,
      isins: data.map((portfolio) => portfolio.isin),
    };
    const res = await savePortfolioData(payload);
    if (res.error) {
      NotificationManager.error(
        res.error?.message && res.error?.message !== 'Rejected'
          ? res.error.message
          : 'Something went wrong',
      );
      reset();
    } else {
      const excelData = res.data?.compositions;
      if (excelData) downloadExcel(excelData, `${name}.xlsx`);
      NotificationManager.success('Portfolio Saved Successfully!');
      onClose();
    }
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setShowSaveDialog(true)}
        style={{ width: 250 }}
      >
        Save Portfolio
      </Button>
      {/* Input fields dialog */}
      <Dialog open={showSaveDialog} keepMounted maxWidth={600}>
        <Box className="d-flex flex-space-between">
          <Typography style={{ marginLeft: 20, marginTop: 10, fontSize: 22 }}>
            Upload Portfolio
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={1}>
            <Grid container item alignItems="center" spacing={1}>
              <Grid item xs={12} sm={6}>
                <Typography>Portfolio Name</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl size="small" fullWidth>
                  <OutlinedInput
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item alignItems="center" spacing={1}>
              <Grid item xs={12} sm={6}>
                <Typography>Portfolio Value</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl size="small" fullWidth>
                  <NumberInputWithCommas value={value} setValue={setValue} />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item alignItems="center" spacing={1}>
              <Grid item xs={12} sm={6}>
                <Typography>Weighted Option</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel>Select Option</InputLabel>
                  <Select
                    variant="outlined"
                    label="Select Option"
                    value={weighted}
                    onChange={(e) => setWeighted(e.target.value)}
                  >
                    <MenuItem value="equal">Equal Weighted</MenuItem>
                    <MenuItem value="market_cap">Market Cap Weighted</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onSave}
            style={{ width: 200 }}
            color="primary"
            variant="outlined"
            disabled={!name || !value || !weighted}
          >
            {isValidateLoading ? (
              <CircularProgress
                size="24px"
                style={{ color: colorSchema[currentTheme].buttonText }}
              />
            ) : (
              'Save'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Confirm or Error Dialog */}
      <Dialog
        open={!isUninitialized && !isValidateLoading}
        keepMounted
        style={{ height: '100%' }}
      >
        <Box className="d-flex flex-space-between">
          <Typography className={classes.title}>
            Notice: Updating Annual ISIN Allowance
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent className={classes.dialogContent}>
          {isValidateError ? (
            <Typography>
              {error?.message && error?.message !== 'Rejected'
                ? error.message
                : 'Something went wrong'}
            </Typography>
          ) : (
            <>
              <Typography className={classes.boldText}>
                Please note the portfolio you have constructed includes{' '}
                {validateResponse?.unique_isins} new ISINs.
              </Typography>
              <Typography className={classes.boldText}>
                In order to save the portfolio to your account, the securities
                will need to be counted towards your coverage limit. Your
                updated annual ISIN allowance will be of:{' '}
                {validateResponse?.isins_will_left} ISINs.
              </Typography>
              <Typography>{'Please click on "Agree" to proceed.'}</Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onAgree}
            style={{ width: 200 }}
            color="primary"
            variant="outlined"
          >
            {isSaveLoading ? (
              <CircularProgress
                size="24px"
                style={{ color: colorSchema[currentTheme].buttonText }}
              />
            ) : isValidateError ? (
              'Close'
            ) : (
              'Agree'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SavePortfolio;
