import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import auth from './authReducer';
import filterReducer from './filterReducer';
// import footprint from './footprintReducer';
// import download from './downloadReducer';
// import optimization from './optimizationReducer';
// import riskContrib from './riskContributorReducer';
// import stranded from './strandedAssetReducer';
// import sovereignReducer from './sovereginReducer';

// import scope3 from './scope3Reducer';
// import tempMetric from './tempMetricReducer';
// import flmReducer from './flmReducer';
// import fundReducer from './fundReducer';
import reportReducer from './reportReducer';
// import netZeroReducer from './netZeroReducer';
import portfolioBuilderReducer from './portfolioBuilderReducer';
// eslint-disable-next-line import/no-cycle
import apiSlice from '../apiSlice';
// import tempMetricApiSlice from './tempMetricApi';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth,
  filters: filterReducer,
  // footprint,
  // download,
  // scope3,
  // optimization,
  // risk: riskContrib,
  // stranded,
  // tempMetric,
  // flm: flmReducer,
  // netZero: netZeroReducer,
  // fund: fundReducer,
  // sovereign: sovereignReducer,
  report: reportReducer,
  portfolioBuilder: portfolioBuilderReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  // [tempMetricApiSlice.reducerPath]: tempMetricApiSlice.reducer,
});

export default rootReducer;
