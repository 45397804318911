import BaseRequest from '../baseRequest';

export default class TargetSetting extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem, currentFundsPortfolio } = props;
    const {
      footprintMetric,
      marketValue,
      approach,
      warmingScenario,
      targetScenario,
      emission,
      inferenceType,
      assetClass,
      sector,
    } = filterItem;

    const emissionMap = {
      Sc123: '123',
      Sc12: '12',
    };

    this.scenario_type = targetScenario;
    this.portfolio_id = currentFundsPortfolio.value;
    this.scope_type = emissionMap[emission];
    this.sector = sector;
    this.footprint = approach === 'MarketShare'
      ? 'TotalCarbEmis'
      : footprintMetric === 'CarbIntensityMarketVal'
          || footprintMetric === 'CarbIntensityRev'
        ? 'WeightAvgRev'
        : footprintMetric;
    this.market_value = marketValue;
    this.inference = inferenceType;
    this.asset_type = assetClass;
    this.approach = approach;
    this.scenario = warmingScenario;
  }
}
