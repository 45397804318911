/* eslint-disable react/prop-types */
import React from 'react';
import {
  Grid, Box, MenuItem, Select, FormControl,
} from '@material-ui/core';
import DataTable from '../../../components/Table/DataTable';

import BasicAreaChart from '../../../components/Charts/BasicAreaChart';
import LineChart from '../../../components/Charts/LineChart';

const PortfolioRiskDisplay = (props) => (
  <React.Fragment>
    <Box>
      <Grid>
        <BasicAreaChart
          categories={props.categoriesB}
          data={props.dataB}
          title="Underwater Plot"
          yAxisTitle={props.yAxisTitleB}
          xAxisTitle={props.xAxisTitleB}
        />
      </Grid>
      <Grid>
        <DataTable
          data={props.dataD}
          columns={props.columnsD}
          tableHeading={props.tableHeadingD}
        />
      </Grid>
      <Box>
        <Grid>
          <FormControl
            variant="outlined"
            className={props.className}
            id="dropdown"
          >
            <Select
              labelId="dropdown"
              value={props.valueS}
              onChange={props.onChangeS}
              style={{ fontSize: 14 }}
            >
              <MenuItem value="portfolio">Portfolio</MenuItem>
              {props.showBenchmark && (
                <MenuItem value="benchmark">Benchmark</MenuItem>
              )}
              <MenuItem value="tilted">Tilted</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Box>
      <Grid>
        <LineChart
          data={props.dataL}
          title="Value-at-risk curve"
          xAxisType={props.xAxisTypeL}
          xAxisTitle={props.xAxisTitleL}
          yAxisTitle={props.yAxisTitleL}
          pointStart={props.pointStartL}
          markerEnabled={false}
        />
      </Grid>
      <Grid>
        <LineChart
          data={props.omegaChartData}
          title="Omega curve"
          xAxisType={props.xAxisType}
          xAxisTitle={props.xAxisTitle}
          yAxisTitle={props.yAxisTitle}
          markerEnabled={false}
        />
      </Grid>
    </Box>
  </React.Fragment>
);

export default PortfolioRiskDisplay;
