import React from 'react';
import PropTypes from 'prop-types';
import strings from '../constants';
import ReportTable from '../../../../components/PdfReport/ReportTable';
import { formatNumber } from '../../../../util/formatNumber';
import {
  getCarbonAttributionAnalysis,
  getcarbonDisclosure,
  getCarbonIntensity,
  getCarbonIntensityTable,
  getPcaf,
  getPcafTable,
} from '../../../../helpers/report';
import ReportHorizontalBar from '../../../../components/PdfReport/Charts/ReportHorizontalBar';
import ReportStackedBar from '../../../../components/PdfReport/Charts/ReportStackedBar';

const CARBON_ATTRIBUTION_CHART_HEIGHT = 350;

const carbonIntensityColumns = [
  {
    name: ' ',
    selector: 'name',
    right: false,
    width: '40%',
    bold: true,
  },
  { name: 'Scope 1+2', selector: 'sc12', right: true },
  { name: 'Scope 3', selector: 'sc3', right: true },
  { name: 'Scope 1+2+3', selector: 'sc123', right: true },
];

const carbonIntensityColumnsSc12 = [
  {
    name: ' ',
    selector: 'name',
    right: false,
    width: '40%',
    bold: true,
  },
  { name: 'Scope 1+2', selector: 'sc12', right: true },
];

const pcafColumns = [
  {
    name: ' ',
    selector: 'name',
    right: false,
    width: '11%',
    bold: true,
  },
  { name: 'Porfolio (S12)', selector: 'psc12', right: true },
  { name: 'Benchmark (S12)', selector: 'bsc12', right: true },
  {
    name: 'Porfolio (S123)',
    selector: 'psc123',
    right: true,
  },
  {
    name: 'Benchmark (S123)',
    selector: 'bsc123',
    right: true,
  },
];

const pcafColumnsSc12 = [
  {
    name: ' ',
    selector: 'name',
    right: false,
    width: '11%',
    bold: true,
  },
  { name: 'Porfolio (S12)', selector: 'psc12', right: true },
  { name: 'Benchmark (S12)', selector: 'bsc12', right: true },
];

const EmissionsFootprint = ({ data }) => {
  const {
    portfolio,
    benchmark,
    auth,
    filters: { emission },
  } = data;

  let carbonIntensity = { data: [], yAxisTitle: '', categories: [] };
  let carbonIntensityTable = [];

  let pcaf = { data: [], yAxisTitle: '', categories: [] };
  let pcafTable = [];
  let carbonAttributionAnalysis = { data: [], yAxisTitle: '', categories: [] };
  let carbonDisclosure = { data: [], yAxisTitle: '', categories: [] };

  const isEmissionSc12 = emission === 'Sc12';
  if (portfolio && benchmark) {
    carbonIntensity = getCarbonIntensity(
      portfolio.portfolio_emisisons,
      benchmark.portfolio_emisisons,
      emission,
    );

    carbonIntensityTable = getCarbonIntensityTable(
      portfolio.portfolio_emisisons,
      benchmark.portfolio_emisisons,
    );

    pcaf = getPcaf(portfolio.pcaf, benchmark.pcaf, emission);

    pcafTable = getPcafTable(pcaf.data, emission);

    carbonAttributionAnalysis = getCarbonAttributionAnalysis(
      portfolio.carbon_atribution,
    );

    carbonDisclosure = getcarbonDisclosure(
      portfolio.disclosure,
      benchmark.disclosure,
    );
  }

  return (
    <>
      <div className="pdf_page">
        <h2>Emissions Footprint</h2>
        <div className="span-2">
          <p>{strings.emissions.title}</p>
          <p>{strings.emissions.title2}</p>
        </div>
        <div>
          <h3>Portfolio Footprint (Carbon Intensity)</h3>
          <ReportHorizontalBar
            categories={carbonIntensity.categories}
            data={carbonIntensity.data}
            yAxisTitle={carbonIntensity.yAxisTitle}
          />
          <div>
            <p>
              {(isEmissionSc12
                ? strings.emissions.emissionChartSc12Text
                : strings.emissions.emissionChartText
              ).replace('[client name]', auth.currentUser.client)}
            </p>
            <p>{strings.emissions.footPrintChartText}</p>
          </div>
          <div style={{ marginTop: 17, marginBottom: 20 }}>
            <ReportTable
              columns={
                isEmissionSc12
                  ? carbonIntensityColumnsSc12
                  : carbonIntensityColumns
              }
              data={carbonIntensityTable}
            />
          </div>
        </div>
        <div>
          <h3>Portfolio Footprint (PCAF)</h3>
          <ReportStackedBar
            categories={pcaf.categories}
            data={pcaf.data}
            yAxisTitle={pcaf.yAxisTitle}
            xAxisTitle={pcaf.xAxisTitle}
          />
          <div>
            <p>{strings.emissions.pcaf1}</p>
            <p>{strings.emissions.pcaf2}</p>
          </div>
          <div style={{ marginTop: 2, marginBottom: 20 }}>
            <ReportTable
              columns={isEmissionSc12 ? pcafColumnsSc12 : pcafColumns}
              data={pcafTable}
            />
          </div>
        </div>
      </div>
      <div className="pdf_page">
        <h2>Emissions Footprint</h2>
        <div>
          <h3>Carbon Attribution Analysis</h3>
          <ReportHorizontalBar
            categories={carbonAttributionAnalysis.categories}
            data={carbonAttributionAnalysis.data}
            yAxisTitle={carbonAttributionAnalysis.yAxisTitle}
            height={CARBON_ATTRIBUTION_CHART_HEIGHT}
            barWidth={2}
          />
          <div>
            <p>{strings.emissions.carbonAtt1}</p>
            <p>
              {isEmissionSc12
                ? strings.emissions.carbonAtt2_Sc12
                : strings.emissions.carbonAtt2}
            </p>
          </div>
        </div>
        <div>
          <h3>Carbon Disclosure</h3>
          <ReportStackedBar
            categories={carbonDisclosure.categories}
            data={carbonDisclosure.data}
            yAxisTitle={carbonDisclosure.yAxisTitle}
            xAxisTitle={carbonDisclosure.xAxisTitle}
            max={100}
            height={CARBON_ATTRIBUTION_CHART_HEIGHT}
          />
          <div>
            <p>{strings.emissions.disclouser}</p>
          </div>
        </div>
      </div>
      <div className="pdf_page">
        <h2>Emissions Footprint</h2>
        <div className="span-2">
          <h3>Portfolio Footprint (Metrics)</h3>
          <div>
            <p>
              The footprint metrics calculated are Total carbon emissions by
              EVIC and Weighted Average Carbon Intensity by Revenue
            </p>
          </div>
          <table className="emission-footprint-table">
            <tr>
              <th className="left" style={{ width: '40%' }}>
                Metrics
              </th>
              <th className="left">Scope</th>
              <th className="right">2022</th>
              <th className="right">Units</th>
            </tr>
            <tr>
              <td rowSpan={isEmissionSc12 ? '1' : '2'} className="left bold">
                Total carbon emissions (EVIC)
              </td>
              <td className="left">Scope 1 and 2</td>
              <td className="right bold">
                {formatNumber(portfolio?.TotalCarbEmis?.total?.sc12 || 0)}
              </td>
              <td className="right">tCO2e</td>
            </tr>
            {!isEmissionSc12 && (
              <tr>
                <td className="left">Scope 3</td>
                <td className="right bold">
                  {formatNumber(portfolio?.TotalCarbEmis?.total?.sc3 || 0)}
                </td>
                <td className="right">tCO2e</td>
              </tr>
            )}
            <tr>
              <td rowSpan={isEmissionSc12 ? '1' : '2'} className="left bold">
                Carbon footprint (EVIC)
              </td>
              <td className="left">Scope 1 and 2</td>
              <td className="right bold">
                {formatNumber(portfolio?.CarbonFootprint.total.sc12 || 0)}
              </td>
              <td className="right">tCO2e/$m invested</td>
            </tr>
            {!isEmissionSc12 && (
              <tr>
                <td className="left">Scope 3</td>
                <td className="right bold">
                  {formatNumber(portfolio?.CarbonFootprint.total.sc3 || 0)}
                </td>
                <td className="right">tCO2e/$m invested</td>
              </tr>
            )}
            <tr>
              <td rowSpan={isEmissionSc12 ? '1' : '2'} className="left bold">
                WACI (Revenue)
              </td>
              <td className="left">Scope 1 and 2</td>
              <td className="right bold">
                {formatNumber(portfolio?.WeightAvgMarketVal?.total.sc12 || 0)}
              </td>
              <td className="right">tCO2e/$m invested</td>
            </tr>
            {!isEmissionSc12 && (
              <tr>
                <td className="left">Scope 3</td>
                <td className="right bold">
                  {formatNumber(portfolio?.WeightAvgMarketVal?.total.sc3 || 0)}
                </td>
                <td className="right">tCO2e/$m invested</td>
              </tr>
            )}
          </table>
        </div>
        <div className="span-2">
          <h3>
            Total carbon emissions and carbon footprint asset class breakdown
          </h3>
          <div>
            <p>
              The tables below represent the breakdown of above shown emissions
              by product (asset classes)
            </p>
          </div>
          <table className="emission-footprint-table">
            <tr>
              <th className="left" style={{ width: '30%' }}>
                Total carbon emissions (tCO2e)
              </th>
              <th className="right">Scope1,2</th>
              {!isEmissionSc12 && <th className="right">Scope 3</th>}
            </tr>
            <tr>
              <td className="left bold">Equities</td>
              <td className="right">
                {formatNumber(portfolio?.TotalCarbEmis.Equity.sc12) || '-'}
              </td>
              {!isEmissionSc12 && (
                <td className="right">
                  {formatNumber(portfolio?.TotalCarbEmis.Equity.sc3) || '-'}
                </td>
              )}
            </tr>
            <tr>
              <td className="left bold">Corporate Bonds</td>
              <td className="right">
                {formatNumber(
                  portfolio?.TotalCarbEmis['Corporate Bond'].sc12,
                ) || '-'}
              </td>
              {!isEmissionSc12 && (
                <td className="right">
                  {formatNumber(
                    portfolio?.TotalCarbEmis['Corporate Bond'].sc3,
                  ) || '-'}
                </td>
              )}
            </tr>
            <tr>
              <td className="left bold">Loan</td>
              <td className="right">
                {formatNumber(portfolio?.TotalCarbEmis.Loan.sc12) || '-'}
              </td>
              {!isEmissionSc12 && (
                <td className="right">
                  {formatNumber(portfolio?.TotalCarbEmis.Loan.sc3) || '-'}
                </td>
              )}
            </tr>
            <tr>
              <td className="left bold">Total</td>
              <td className="right bold">
                {formatNumber(portfolio?.TotalCarbEmis.total.sc12) || '-'}
              </td>
              {!isEmissionSc12 && (
                <td className="right bold">
                  {formatNumber(portfolio?.TotalCarbEmis.total.sc3) || '-'}
                </td>
              )}
            </tr>
          </table>
          <table className="emission-footprint-table">
            <tr>
              <th className="left" style={{ width: '30%' }}>
                Carbon footprint (tCO2e/$m invested)
              </th>
              <th className="right">Scope1,2</th>
              {!isEmissionSc12 && <th className="right">Scope 3</th>}
            </tr>
            <tr>
              <td className="left bold">Equities</td>
              <td className="right">
                {formatNumber(portfolio?.CarbonFootprint.Equity.sc12) || '-'}
              </td>
              {!isEmissionSc12 && (
                <td className="right">
                  {formatNumber(portfolio?.CarbonFootprint.Equity.sc3) || '-'}
                </td>
              )}
            </tr>
            <tr>
              <td className="left bold">Corporate Bonds</td>
              <td className="right">
                {formatNumber(
                  portfolio?.CarbonFootprint['Corporate Bond'].sc12,
                ) || '-'}
              </td>
              {!isEmissionSc12 && (
                <td className="right">
                  {formatNumber(
                    portfolio?.CarbonFootprint['Corporate Bond'].sc3,
                  ) || '-'}
                </td>
              )}
            </tr>
            <tr>
              <td className="left bold">Loan</td>
              <td className="right">
                {formatNumber(portfolio?.CarbonFootprint.Loan.sc12) || '-'}
              </td>
              {!isEmissionSc12 && (
                <td className="right">
                  {formatNumber(portfolio?.CarbonFootprint.Loan.sc3) || '-'}
                </td>
              )}
            </tr>
            <tr>
              <td className="left bold">Total</td>
              <td className="right bold">
                {formatNumber(portfolio?.CarbonFootprint.total.sc12) || '-'}
              </td>
              {!isEmissionSc12 && (
                <td className="right bold">
                  {formatNumber(portfolio?.CarbonFootprint.total.sc3) || '-'}
                </td>
              )}
            </tr>
          </table>
        </div>
      </div>
    </>
  );
};

EmissionsFootprint.propTypes = {
  data: PropTypes.shape({
    portfolio: PropTypes.object,
    benchmark: PropTypes.object,
    auth: PropTypes.object,
    filters: PropTypes.object,
  }),
};

export default EmissionsFootprint;
