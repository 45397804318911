class yearQuarter {
  constructor(emissions, fundamentals, targets, currency) {
    this.emissions = emissions;
    this.fundamentals = fundamentals;
    this.targets = targets;
    this.currency = currency;
  }

  yearQuarter = () => this;
}

export default yearQuarter;
