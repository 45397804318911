import React from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import StackedBar from '../../../components/Charts/StackedBar';
import ColumnChart from '../../../components/Charts/ColumnChart';
import PcafTable from '../../../components/PcafTable';

const AssetEmissionsBreakdownDisplay = ({
  selectValue,
  categoriesStack,
  data,
  title,
  yAxisTitle,
  onChange,
  selectValue2,
  data2,
  title2,
  dataColumnChart,
  title3,
  categoriesColumnChart,
  assetType,
  pcafData,
}) => (
  <React.Fragment>
    <div style={{ marginTop: '2%' }}>
      <Grid container>
        <Grid item xs={6}>
          <FormControl variant="outlined">
            <Select
              placeholder="PCAF SCORE"
              style={{ fontSize: 14, width: 300, marginBottom: 20 }}
              value={selectValue}
              disabled={true}
            >
              <MenuItem value="PCAF Score">PCAF Score</MenuItem>
              <MenuItem value="Disclosure Category">
                Disclosure Category
              </MenuItem>
            </Select>
          </FormControl>
          <StackedBar
            categories={categoriesStack}
            data={data}
            title={title}
            yAxisTitle={yAxisTitle}
            xAxisTitle="Scope 1+2 &nbsp &nbsp &nbsp Scope 1+2+3"
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl variant="outlined">
            <InputLabel>REPORTED</InputLabel>
            <Select
              label="REPORTED"
              style={{ fontSize: 14, width: 300, marginBottom: 20 }}
              onChange={(e) => onChange(e)}
              value={selectValue2}
            >
              <MenuItem value="Reported Vs Inferred">
                Reported Vs Inferred
              </MenuItem>
              <MenuItem value="Public Vs Private">Public Vs Private</MenuItem>
            </Select>
          </FormControl>
          <StackedBar
            categories={categoriesStack}
            data={data2}
            title={title2}
            yAxisTitle={yAxisTitle}
            xAxisTitle={
              'Scope 1+2 &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp Scope 1+2+3'
            }
          />
        </Grid>
      </Grid>
      <PcafTable assetType={assetType} pcafData={pcafData} />
      <br />
      <ColumnChart
        categories={categoriesColumnChart}
        data={dataColumnChart}
        title={title3}
        yAxisTitle={yAxisTitle}
      />
    </div>
  </React.Fragment>
);

AssetEmissionsBreakdownDisplay.propTypes = {
  selectValue: PropTypes.any.isRequired,
  categoriesStack: PropTypes.any.isRequired,
  data: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  yAxisTitle: PropTypes.string.isRequired,
  onChange: PropTypes.any.isRequired,
  selectValue2: PropTypes.any.isRequired,
  data2: PropTypes.any.isRequired,
  title2: PropTypes.string.isRequired,
  dataColumnChart: PropTypes.any.isRequired,
  title3: PropTypes.string.isRequired,
  categoriesColumnChart: PropTypes.any.isRequired,
  assetType: PropTypes.string.isRequired,
  pcafData: PropTypes.object,
};

export default AssetEmissionsBreakdownDisplay;
