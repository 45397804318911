import React from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import TabPanel from '../../components/TabPanel';
import { portOptimizationTabs } from '../../util/tabs-config';
import PortOptimization from './PortfolioOptimization/PortfolioOptimization';
import PerformanceAttribution from './PerformanceAttribution/PerformanceAttribution';
import OptimizedEmission from './OptimizedEmission/OptimizedEmission';
import PortfolioRisk from './PortfolioRisk/PortfolioRisk';
import useTabValue from '../../hooks/useTabValueHook';

function PortfolioOptimization() {
  const [tabValue, onTabChange] = useTabValue();

  return (
    <>
      <Paper position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={onTabChange}
          indicatorColor="secondary"
        >
          {portOptimizationTabs.map((e, i) => (
            <Tab
              key={i}
              label={e}
              style={{ fontSize: 11 }}
              data-testid={`tab-${e}`}
            />
          ))}
        </Tabs>
      </Paper>
      <TabPanel value={tabValue} index={0}>
        <PortOptimization />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <PortfolioRisk />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <OptimizedEmission />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <PerformanceAttribution />
      </TabPanel>
    </>
  );
}
export default PortfolioOptimization;
