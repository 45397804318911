import React from 'react';
import RoundSignal from '../../../../components/PdfReport/RoundSignal';

function Glossary() {
  return (
    <div className="pdf_page">
      <h2>Glossary</h2>
      <div className="span-2">
        <p>
          <b>{'Emissions Scopes:'}</b>
          {
            " The Greenhouse Gas (GHG) Protocol Corporate Standard classifies a company's GHG emissions into three 'scopes':"
          }
        </p>
        <p style={{ marginLeft: 30 }}>
          <b>Scope 1:</b> Direct emissions from owned or controlled sources
        </p>
        <p style={{ marginLeft: 30 }}>
          <b>Scope 2:</b> Indirect emissions from the generation of purchased
          energy
        </p>
        <p style={{ marginLeft: 30 }}>
          <b>Scope 3:</b> All indirect emissions (not included in scope 2) that
          occur in the value chain of the reporting company, including both
          upstream and downstream emissions.
        </p>
        <p>
          <b>Weighted Average Carbon Intensity (WACI) (Revenue):</b> Represents
          the portfolio’s exposure to carbon-intensive companies, expressed in
          tons CO2e / $M revenue. This calculation is Task Force on
          Climate-Related Financial Disclosures (TCFD) recommended
        </p>
        <p>
          <b>Enterprise Value including Cash (EVIC):</b> Represents the market
          value of a company in addition to short and long-term debt and
          including any cash on the balance sheet.
        </p>
        <p>
          <b>Implied Temperature Rise (ITR):</b> This metric measures the
          alignment of reported emissions reduction (or decarbonisation) targets
          to a global temperature outcome under a set of forward-looking
          scenarios. The ITR metric can be applied to individual companies and
          to portfolios
        </p>
        <p>
          <b>On Track with Targets:</b>{' '}
          {
            "The 'On Track with Targets' indicator considers the historical emissions trend (from 2015) of the company or portfolio and indicates whether the company or portfolio would achieve its reported emissions reduction targets if the historic trend were to continue. If the historic trend continues until 2030 and this leads to:"
          }
        </p>
        <p
          style={{
            display: 'flex',
            gap: 5,
            marginLeft: 30,
          }}
        >
          <RoundSignal color={'green'} size={12} /> (a) a reduction in emissions
          that are below the expected pathway, the company or portfolio is
          deemed “on track” (green)
        </p>
        <p
          style={{
            display: 'flex',
            gap: 5,
            marginLeft: 30,
          }}
        >
          <RoundSignal color={'orange'} size={12} /> (b) emissions that are
          within 20% of this, the company or portfolio is “partially” on track
          (orange), or
        </p>
        <p
          style={{
            display: 'flex',
            gap: 5,
            marginLeft: 30,
          }}
        >
          <RoundSignal color={'red'} size={12} /> (c) emissions that are more
          than 20% greater than this, the company or portfolio is “not on track”
          (red), to achieve its stated targets.
        </p>
        <p>
          Note that if the company or portfolio has less ambitions targets, it
          is more likely to be “on track” towards them
        </p>
      </div>
    </div>
  );
}

export default Glossary;
