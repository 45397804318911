import React, { useState, useEffect } from 'react';
import { some, sortBy } from 'lodash';
import { LoadingHOC } from '../../../util/LoadingHOC';
import DisclosureDisplay from './DisclosureDisplay';
import useGetPayload from '../../../hooks/useGetPayload';
import PortfolioDisclosure from '../../../models/emissions/portfolioDisclosure';
import BenchmarkDisclosure from '../../../models/emissions/benchmarkDisclosure';
import { useGetEmissionDisclosureQuery } from '../../../redux/apiHooks';

const DisclosureWithLoading = LoadingHOC(DisclosureDisplay);

const categories = ['Portfolio Disclosure', 'Benchmark Disclosure'];

const text = `This chart highlights the number of Scope 3 categories disclosed out
of a possible 15 for company holdings with the portfolio and
benchmark as a percentage. This relates to the issuers that ICE
has directly analysed. A key area of engagement with companies is to
encourage disclosure of Scope 3 emissions. Disclosure of Scope 3
emissions reduces the error in estimating carbon risk exposure and
allows companies to address areas of high carbon intensity within
their value chain. Scope 3 data is becoming increasingly available
and is a critical part of understanding company and portfolio-level
carbon risks - as it generally accounts for around 85% of a typical
company's total emissions.`;

const Disclosure = () => {
  const [stackedChartData, setStackedChartData] = useState([]);
  const [columnChartData, setColumnChartData] = useState([]);
  const [columnCategories, setColumnCategories] = useState([]);

  const { auth, payload: portfolioPayload } = useGetPayload(PortfolioDisclosure);
  const { payload: benchmarkPayload } = useGetPayload(BenchmarkDisclosure);
  const {
    data: portDisclosure,
    isFetching: isPortloading,
    error: portError,
  } = useGetEmissionDisclosureQuery(portfolioPayload);
  const {
    data: benchDisclosure,
    isFetching: isBenchLoading,
    error: benchError,
  } = useGetEmissionDisclosureQuery(benchmarkPayload);
  const { showBenchmark } = auth;

  const getColumnChartData = () => {
    let chartData = [];
    const portValues = [];
    const benchValues = [];
    const categoriesArr = [];

    const portData = portDisclosure?.sc3?.data || [];
    const benchData = benchDisclosure?.sc3?.data || [];
    for (let i = 0; i <= 15; i += 1) {
      let portValue = 0;
      let benchValue = 0;

      if (portData && portData.length > 0) {
        const isPortExist = some(portData, { category: `${i}` });

        if (isPortExist) {
          const index = portData.findIndex((x) => x.category === `${i}`);

          portValue = portData[index].value;
        }
      }
      if (benchData && benchData.length > 0) {
        const isBenchExist = some(benchData, { category: `${i}` });
        if (isBenchExist) {
          const index = benchData.findIndex((x) => x.category === `${i}`);
          benchValue = benchData[index].value;
        }
      }
      portValues.push(portValue);
      benchValues.push(benchValue);
    }

    chartData = [
      {
        name: 'portfolio',
        data: portValues,
      },
    ];
    if (showBenchmark) {
      chartData.push({
        name: 'benchmark',
        data: benchValues,
      });
    }
    setColumnChartData(chartData);
    setColumnCategories(categoriesArr);
  };

  const getStackedDisclosureData = (arr1, arr2) => arr2?.reduce((result, obj2) => {
    if (arr1?.some((obj1) => obj1.category === obj2.category)) {
      return result;
    }
    return [...result, { category: obj2.category, value: 0 }];
  }, arr1);

  const preferredCategoryOrderData = (obj, sortOrder) => sortOrder
    .map((i) => obj.find((j) => j.name === i))
    .filter((cat) => cat !== undefined);

  const getStackedChartData = async () => {
    let chartData = [];
    const categoryOrder = [
      'Public, complete Scope 1 and 2 data, third-party assurance.',
      'Public, complete Scope 1 and 2 data, no third-party assurance.',
      'Incomplete Scope 1 and 2 data, no third-party assurance.',
      'No public data.',
      'Not directly analysed.',
    ];
    const portData = portDisclosure?.sc12?.data || [];
    const benchData = benchDisclosure?.sc12?.data || [];
    if (portData.length > 0) {
      const sortedPortData = sortBy(portData, 'category');
      const sortedBenchData = sortBy(benchData, 'category');

      const arr1 = await getStackedDisclosureData(
        sortedPortData,
        sortedBenchData,
      );
      const arr2 = await getStackedDisclosureData(
        sortedBenchData,
        sortedPortData,
      );

      const sortedNewPortData = sortBy(arr1, 'category');
      const sortedNewBenchData = sortBy(arr2, 'category');

      chartData = sortedNewPortData.map((res, index) => {
        const portValue = res.value;
        const benchValue = (sortedNewBenchData
            && sortedNewBenchData.length > 0
            && sortedNewBenchData[index]?.value)
          || 0;
        return {
          name: res.category,
          data: showBenchmark ? [portValue, benchValue] : [portValue],
        };
      });
      const sortedChartData = preferredCategoryOrderData(
        chartData,
        categoryOrder,
      );
      setStackedChartData(sortedChartData);
    }
  };

  useEffect(() => {
    if (portDisclosure || benchDisclosure) {
      getStackedChartData();
      getColumnChartData();
    }
  }, [portDisclosure, benchDisclosure]);
  return (
    <DisclosureWithLoading
      error={portError || benchError}
      stackedBarCategories={categories}
      columnChartCategories={columnCategories}
      stackedBarData={stackedChartData}
      columnChartData={columnChartData}
      stackedBarChartKey="DISCLOSURE_SCOPE12"
      columnChartKey="DISCLOSURE_SCOPE3"
      text={text}
      loading={isPortloading || isBenchLoading}
    />
  );
};
export default Disclosure;
