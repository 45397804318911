export const WeightAvg = 'Weighted Average Market';
export const METRIC = 'Footprint Metric';
export const MARKET = 'Market Value';
export const ASSET = 'Asset Class';
export const ASSET_TYPE = 'Asset Type';
export const INFERENCE_TYPE = 'Inference Type';
export const EMISSIONS = 'Emissions';
export const EMISSIONS2 = 'Emissions2';
export const METHODOLOGY = 'Methodology';
export const SCOPE = 'Scope';
export const EMISSION_SCOPE = 'Emissions Scope';

export const SCENARIO_DB = 'Scenario Database';
export const APPROACH = 'Approach';
export const ALIGNMENT = 'Alignment Year';
export const SET_1_5_SCENARIO = 'Set 1.5 Scenario';
export const EMISSION_TYPE = 'Emission Type';
export const MATERIALITY_TYPE = 'Materiality Type';
export const WHAT_IF_SCENARIO = 'What-If Scenario';
export const AGGREGATION = 'Aggregation';
export const SCORE_TYPE = 'Score Type';
export const DEFAULT_TYPE = 'Default Value';
export const STRATEGY = 'Strategy';
export const REBALANCE_FREQUENCY = 'Rebalance Frequency';
export const ATTRIBUTION = 'Attribution Type';
export const WARNING_SCENARIO = 'Warming Scenario';
export const DATERANGE = 'Date Range';
export const SECTOR_CLASSIFICATION = 'Sector Classification';
export const SENTIMENT_TOPIC = 'Sentiment Topics';
export const BUTTON_TEXT = 'Load Data';
export const ADD_FILTER = 'Add Filter';

export const WEIGHTED_AVG_INTENSITY_REV = 'Weighted Average Intensity (Revenue)';
export const WEIGHTED_AVG_INTENSITY_MARKET = 'Weighted Average Intensity';
export const WEIGHTED_AVG_INTENSITY_MARKET_MARKET = 'Weighted Average Intensity (Market Value)';
export const TOTAL_CARBON_EMISSION = 'Total Carbon Emissions';
export const ABSOLUTE_EMISSIONS = 'Absolute Emissions';
export const INTENSITY_OF_POPULATION = 'Intensity of Population';
export const INTENSITY_OF_GDP = 'Intensity of GDP';
export const INTENSITY_OF_PPP = 'Intensity of PPP-Adjusted GDP';
export const FINANCED_EMISSIONS = 'Financed Emissions';
export const INTENSITY_OF_SOVEREIGN_DEBT = 'Intensity of Sovereign Debt';
export const PORTFOLIO = 'Portfolio';
export const SECTOR = 'Sector';

export const WEIGHTED_AVG_INTENSITY_REV_VALUE = 'WeightAvgRev';
export const WEIGHTED_AVG_INTENSITY_MARKET_VALUE = 'WeightAvgMarketVal';
export const TOTAL_CARBON_EMISSION_VALUE = 'TotalCarbEmis';
export const ABSOLUTE_EMISSIONS_VALUE = 'no_attribution';
export const INTENSITY_OF_POPULATION_VALUE = 'population';
export const INTENSITY_OF_GDP_VALUE = 'gdp';
export const INTENSITY_OF_PPP_VALUE = 'pp_adj_gdp';
export const INTENSITY_OF_SOVEREIGN_DEBT_VALUE = 'sovereign_debt';
export const PORTFOLIO_VALUE = 'matPort';
export const SECTOR_VALUE = 'matSector';
export const GROUP_SECTOR_VALUE = 'sector';

export const FINANCED_EMISSIONS_VALUE = 'TotalCarbEmis';

export const MARKET_CAPITALIZATION = 'Market Capitalization';
export const MARKET_CAPITALIZATION_DEBT = 'Market Capitalization + Total Debt';
export const TOTAL_DEBT = 'Total Debt';
export const ENTERPRISE = 'Enterprise Value';
export const ENTERPRISE_VALUE_CASH = 'Enterprise Value Including Cash';

export const MARKET_CAPITALIZATION_VALUE = 'MarketCap';
export const MARKET_CAPITALIZATION_DEBT_VALUE = 'MarketCapDebt';
export const TOTAL_DEBT_VALUE = 'Debt';
export const ENTERPRISE_VALUE = 'EnterpriseVal';
export const ENTERPRISE_VALUE_CASH_VALUE = 'EnterpriseValCash';

export const EQUITY = 'Equity';
export const CORPORATE = 'Corporate Bonds';
export const LOANS = 'Loans';

export const EQUITY_VALUE = 'Eq';
export const CORPORATE_VALUE = 'CB';
export const LOANS_VALUE = 'Loan';

export const AVERAGE = 'Average';
export const MAXIMUM = 'Maximum';
export const REPORTED = 'Reported';
export const INFERRED_AVERAGE = 'Inferred Average';
export const INFERRED_MAXIMUM = 'Inferred Maximum';

export const AVERAGE_VALUE = 'Avg';
export const MAXIMUM_VALUE = 'Max';
export const REPORTED_VALUE = 'reported';

export const SCOPE_1_2 = 'Scope 1+2';
export const SCOPE_1_2_3 = 'Scope 1+2+3';
export const SCOPE_1 = 'Scope 1';
export const SCOPE_2 = 'Scope 2';
export const SCOPE_3 = 'Scope 3';
export const SCOPE3_CATEGORIES = 'Scope 3 categories';

export const CUSTOM = 'Custom';
export const PCAF = 'PCAF';

export const CUSTOM_VALUE = 'default';
export const PCAF_VALUE = 'pcaf';

export const SCOPE_1_VALUE = 'Sc1';
export const SCOPE_2_VALUE = 'Sc2';
export const SCOPE_3_VALUE = 'Sc3';
export const SCOPE_1_2_3_VALUE = 'Sc123';
export const SCOPE_1_2_VALUE = 'Sc12';
export const SCOPE3_CATEGORIES_VALUE = 'sc3_categories';

export const IPCC_1_5 = 'IPCC 1.5';
export const IEA = 'IEA';
export const NGFS = 'NGFS';
export const NGFS_V3 = 'NGFS Phase 3';
export const NGFS_V4 = 'NGFS Phase 4';
export const SSP = 'SSPs';

export const IPCC_1_5_VALUE = 'IPCC';
export const NGFS_V3_VALUE = 'ngfs_3';
export const NGFS_V4_VALUE = 'ngfs_4';
export const SSP_VALUE = 'ssp';

export const MARKET_SHARE = 'Market Share';
export const RELATIVE_ALIGNMENT = 'Relative Alignment';
export const RELATIVE_ALIGNMENT_VALUE = 'RelativeAlignment';

export const LOW_ENERGY_DEMAND_1_5 = 'Low Energy Demand';
export const SSP_1 = 'SSP1';
export const SSP_2 = 'SSP2';

export const CARBON_MOMENTUM = 'Carbon Momentum';
export const EMISSIONS_REDUCTION = 'Emissions Reduction';

export const CARBON_MOMENTUM_VALUE = 'momentum';
export const EMISSIONS_REDUCTION_VALUE = 'emissions_reduction';

export const NONE = 'None';
export const YEARLY = 'Yearly';
export const QUATERLY = 'Quarterly';
export const MONTHLY = 'Monthly';

export const NONE_VALUE = 'N';
export const YEARLY_VALUE = 'Y';
export const QUATERLY_VALUE = 'Q';
export const MONTHLY_VALUE = 'M';

export const POPULATION = 'Population';
export const GDP = 'GDP';
export const PPP_ADJUSTED_GDP = 'PPP-Adjusted GDP';

export const POPULATION_VALUE = 'population';
export const GDP_VALUE = 'gdp';
export const PPP_ADJUSTED_GDP_VALUE = 'pp_adj_gdp';

// IPCC
export const SSP_1_26 = 'SSP1-26';
export const SSP_2_26 = 'SSP2-26';
export const LOW_ENERGY_DEMAND = 'Low Energy Demand';
export const SSP_4_26 = 'SSP4-26';
export const SSP_5_26 = 'SSP5-26';

export const SSP_1_26_VALUE = 'SSP1-26';
export const SSP_2_26_VALUE = 'SSP2-26';
export const LOW_ENERGY_DEMAND_VALUE = 'LowEnergyDemand';
export const SSP_4_26_VALUE = 'SSP4-26';
export const SSP_5_26_VALUE = 'SSP5-26';

// IPCC[v2]

export const SSP_1_19 = 'SSP1-19';
export const SSP_1_19_VALUE = 'SSP1-19';

export const SSP_1_34 = 'SSP1-34';
export const SSP_1_34_VALUE = 'SSP1-34';

export const SSP_1_45 = 'SSP1-45';
export const SSP_1_45_VALUE = 'SSP1-45';

export const SSP_BASELINE = 'SSP1-Baseline';
export const SSP_1_BASELINE_VALUE = 'SSP1-Baseline';

export const SSP2_19 = 'SSP2-19';
export const SSP2_19_VALUE = 'SSP2-19';

export const SSP_2_34 = 'SSP2-34';
export const SSP_2_34_VALUE = 'SSP2-34';

export const SSP_2_45 = 'SSP2-45';
export const SSP_2_45_VALUE = 'SSP2-45';

export const SSP_2_60 = 'SSP2-60';
export const SSP_2_60_VALUE = 'SSP2-60';

export const SSP_2_BASELINE = 'SSP2-Baseline';
export const SSP_2_BASELINE_VALUE = 'SSP2-Baseline';

export const SSP_3_34 = 'SSP3-34';
export const SSP_3_34_VALUE = 'SSP3-34';

export const SSP_3_45 = 'SSP3-45';
export const SSP_3_45_VALUE = 'SSP3-45';

export const SSP_3_60 = 'SSP3-60';
export const SSP_3_60_VALUE = 'SSP3-60';

export const SSP_3_BASELINE = 'SSP3-Baseline';
export const SSP_3_BASELINE_VALUE = 'SSP3-Baseline';
export const SSP_5_BASELINE_VALUE = 'SSP5-Baseline';

export const MARKET_SHARE_VALUE = 'MarketShare';

// IEA
export const BEYOND_2_DEGREES = 'Beyond 2 Degrees';
export const TWO_DEGREES = '2 Degrees';
export const REFERENCE_TECHNOLOGY = 'REFERENCE_TECHNOLOGY';

export const BEYOND_2_DEGREES_VALUE = 'Beyond2';
export const TWO_DEGREES_VALUE = '2';
export const REFERENCE_TECHNOLOGY_VALUE = 'ReferenceTechnology';

// NGFS
export const CURRENT_POLICIES = 'Current policies (Hot house world, Rep)';
export const IMMEDIATE_2C = 'Immediate 2C with CDR (Orderly, Rep)';
export const DELAYED_2C = 'Delayed 2C with CDR (Disorderly, Alt)';
export const IMMEDIATE_1_5C = 'Immediate 1.5C with CDR (Orderly, Alt)';
export const IMMEDIATE_1_5C_LTD_CDR = 'Immediate 1.5C with limited CDR (Disorderly, Alt)';
export const IMMEDIATE_2C_LTD_CDR = 'Immediate 2C with limited CDR (Orderly, Alt)';
export const NATIONALLY = 'Nationally determined contributions (NDCs) (Hot house world, Alt)';
export const DELAYED_2C_LTD = 'Delayed 2C with limited CDR (Disorderly, Rep)';
export const NDC_NGFS = 'Nationally determined contributions (NDCs) (Hot house world, Alt)';
export const BELOW_2C = 'Below 2C';
export const BELOW_2C_DEGREE = 'Below 2°C';
export const NDCs = 'NDCs (Hot house world, Alt)';
export const DELAYED_TRANSITION = 'Delayed transition';
export const DIVERGENT_NET_ZERO = 'Divergent Net Zero';
export const NET_ZERO_2050 = 'Net Zero 2050';
export const FRAGMENTED_WORLD = 'Fragmented World';
export const LOW_DEMAND = 'Low demand';
export const NET_ZERO_HISTORICAL_EMISSIONS = 'Historical Emissions';
export const NET_ZERO_TRUE = 'True';
export const NET_ZERO_FALSE = 'False';

export const NDC_VALUE = 'Nationally Determined Contributions (NDCs)';
export const CURRENT_POLICIES_VALUE = 'Current Policies';
export const NET_ZERO_TRUE_VALUE = true;
export const NET_ZERO_FALSE_VALUE = false;
export const YEAR_2020 = '2020';
export const YEAR_2030 = '2030';
export const YEAR_2040 = '2040';
export const YEAR_2050 = '2050';
export const YEAR_2060 = '2060';

// Emissions Type
export const NET = 'Net';
export const GROSS = 'Gross';
export const PRODUCTION = 'Production';
export const CONSUMPTION = 'Consumption';
export const CO2 = 'CO2';
export const CO2e = 'CO2e';

export const NET_VALUE = 'net';
export const GROSS_VALUE = 'gross';
export const PRODUCTION_VALUE = 'production';
export const CONSUMPTION_VALUE = 'consumption';

// portfolio Builder
export const CLASSIFICATION_ICE = 'sector';
export const CLASSIFICATION_SASB = 'Classification(SASB)';
export const PORT_ABSOLUTE_EMISSIONS = 'Absolute Emissions';
export const MARKET_CAP = 'Market Cap';
export const COUNTRY = 'Country';
export const REGION = 'Region';

export const CLASSIFICATION_ICE_VALUE = 'sector';
export const CLASSIFICATION_SASB_VALUE = 'classification_sasb';
export const PORT_ABSOLUTE_EMISSIONS_VALUE = 'emission';
export const MARKET_CAP_VALUE = 'market_cap';
export const COUNTRY_VALUE = 'country';
export const REGION_VALUE = 'region';
export const ASSET_VALUE = 'asset_type';

export const PORTFOLIO_EMISSIONS = 0;
export const SECTORAL_EMISSIONS = 1;
export const COMPANY_EMISSIONS = 2;
export const CARBON_ATTRIBUTION = 3;
export const DISCLOSURE = 4;
export const AVOIDED_EMISSIONS = 5;
export const CARBON_OFFSET_COMPANY = 6;

export const PORTFOLIO_EMISSIONS2 = 0;
export const ASSET_BREAKDOWN = 1;

export const PORTFOLIO_ALIGNMENT = 0;
export const TARGET_SETTING = 1;
export const COMPANY_PROFILE = 2;
export const CARBON_ADJUSTED_RETURNS = 3;

export const PORTFOLIO_RETURNS = 0;
export const PORTFOLIO_RISK = 1;
export const OPTIMIZED_EMISSION = 2;
export const PERFORMANCE_ATTRIBUTION = 3;

export const DECARBONATION_MONITOR = 0;
export const NET_ZERO_ANALYSIS = 1;
export const NET_ZERO_TARGET_SETTINGS = 2;
export const SECTOR_SUBPLOT = 3;
export const NET_ZERO_COMPANY_ALIGNMENT = 4;

export const TCFD_REPORT = 0;
export const PORTFOLIO_REPORT = 1;
export const BENCHMARK_REPORT = 2;

export const FOSSIL_FUEL_RESERVES_FOOTPRINT = 0;
export const COAL_POWER_ANALYSIS = 1;

export const FOF_COMPOSITION = 0;
export const FOF_FOOTPRINT = 1;
export const FOF_ALIGNMENT = 2;
export const FOF_TARGET_SETTING = 3;

export const COUNTRY_EMISSIONS = 1;
export const HISTORICAL_EMISSIONS = 2;

export const PORTFOLIO_SCOPE3_HEATMAP = 0;
export const SECTORAL_SCOPE3_HEATMAP = 1;

export const PORTFOLIO_SCORE = 0;
export const COMPANY_ANALYSIS = 1;
export const TEMP_ATTRIBUTION = 2;
export const SECTORAL_SCORE = 3;
export const CONTRIBUTION_ANALYSIS = 4;
export const HEATMAP = 5;

export const PORTFOLIO_ANALYSIS = 0;
export const SECTOR_ANALYSIS = 1;
export const NLP_COMPANY_ANALYSIS = 2;
export const ARTICAL_ANALYSIS = 3;

export const DOWNLOAD = 0;

export const PORTFOLIO_VIEW = 0;
export const ALLOCATION_DETAILS = 1;

export const TRUE = true;
export const FALSE = false;

export const YEAR = 'Year';
export const INTENSITY_CONTRIBUTION_SCOPE = 'Intensity Contribution Scope';

export const yearKey = 'year';
export const intensityKey = 'intensityScope';

export const YEAR_1 = '1 Year';
export const YEAR_3 = '3 Years';
export const YEAR_5 = '5 Years';

export const YEAR_1_VALUE = '1Y';
export const YEAR_3_VALUE = '3Y';
export const YEAR_5_VALUE = '5Y';

// Temperature Score
export const WEIGHTED_AVG = 'Weighted Average';
export const TOTAL_EMISSIONS = 'Total Emissions';
export const MARKET_OWNED_EMISSIONS = 'Market Owned Emissions';
export const ENTERPRISE_OWNED_EMISSIONS = 'Enterprise Owned Emissions';
export const ENTERPRISE_VALUE_INCLUDING_CASH = 'Enterprise Value Including Cash';
export const REVENUE_OWNED_EMISSIONS = 'Revenue Owned Emissions';

export const TOTAL_EMISSIONS_VALUE = 'TETS';
export const WEIGHTED_AVG_VALUE = 'WATS';
export const MARKET_OWNED_EMISSIONS_VALUE = 'MOTS';
export const ENTERPRISE_OWNED_EMISSIONS_VALUE = 'EOTS';
export const ENTERPRISE_VALUE_INCLUDING_CASH_VALUE = 'ECOTS';
export const REVENUE_OWNED_EMISSIONS_VALUE = 'ROTS';

export const NON = 'None';
export const TARGETS_15 = 'All companies without targets set a 1.5 degree score';
export const TARGETS_175 = 'All companies without targets set a 1.75 degree score';
export const TOP_10_15 = 'Top 10 companies set 1.5 degrees score';
export const TOP_10_175 = 'Top 10 companies set 1.75 degree score';

export const NON_VALUE = 0;
export const TARGETS_15_VALUE = 1;
export const TARGETS_175_VALUE = 2;
export const TOP_10_15_VALUE = 3;
export const TOP_10_175_VALUE = 4;

export const SHORT_TERM_SCORE = 'Short Term Score';
export const MID_TERM_SCORE = 'Mid Term Score';
export const LONG_TERM_SCORE = 'Long Term Score';

export const SHORT_TERM_SCORE_VALUE = 'shortTerm';
export const MID_TERM_SCORE_VALUE = 'midTerm';
export const LONG_TERM_SCORE_VALUE = 'longTerm';

export const DEFAULT_VALUE_100 = '1.00';
export const DEFAULT_VALUE_150 = '1.50';
export const DEFAULT_VALUE_200 = '2.00';
export const DEFAULT_VALUE_250 = '2.50';
export const DEFAULT_VALUE_300 = '3.00';
export const DEFAULT_VALUE_320 = '3.20';
export const DEFAULT_VALUE_350 = '3.50';
export const DEFAULT_VALUE_400 = '4.00';
export const DEFAULT_VALUE_450 = '4.50';
export const DEFAULT_VALUE_500 = '5.00';

export const INFERRED_AVG = 'Inferred Average';
export const INFERRED_MAX = 'Inferred Maximum';
export const BY_PCAF_SCORE = 'By PCAF Score';

export const INFERRED_AVG_VALUE = 'avg';
export const INFERRED_MAX_VALUE = 'max';
export const BY_PCAF_SCORE_VALUE = 'by_pcaf_score';

// keys
export const metricKey = 'footprintMetric';
export const warmingScenarioKey = 'warmingScenario';
export const assetClassKey = 'assetClass';
export const assetTypeKey = 'assetType';
export const marketValueKey = 'marketValue';
export const inferenceTypeKey = 'inferenceType';
export const emissionKey = 'emission';
export const emission2Key = 'emission2';
export const methodologyKey = 'strategy';
export const alignmentYearKey = 'alignmentYear';
export const targetScenarioKey = 'targetScenario';
export const approachKey = 'approach';
export const portScenarioKey = 'portScenario';
export const dateRangeKey = 'dateRange';
export const emissionTypeKey = 'emissionType';
export const emissionsKey = 'emissions_type';
export const includeHistoricalKey = 'includeHistorical';
export const sectorClassificationKey = 'sector';
export const chartTypeKey = 'chartType';

export const materialityKey = 'materiality';
export const strategyKey = 'strategy';
export const rebalanceFrequencyKey = 'rebalanceFrequency';
export const attributionKey = 'attribution';
export const aggregationKey = 'aggregation';
export const whatIfScenarioKey = 'scenario';
export const scoreTypeKey = 'scoreType';
export const defaultValueKey = 'defaultValue';
export const emissionsTypeKey = 'emissionsType';

// date
export const START_DATE = 'Start Date';
export const END_DATE = 'End Date';

export const START_DATE_VALUE = 'startDate';
export const END_DATE_VALUE = 'endDate';

// accordion

export const PUBLIC_ATTRIBUTION = 'Public Attribution';
export const PRIVATE_ATTRIBUTION = 'Private Attribution';

// label
export const REVENUE = 'Revenue';
export const MARKET_CAPITALIZATION_TOTAL_DEBT = 'Market Capitalisation + Debt';

// label value
export const REVENUE_VALUE = 'Revenue';

// NLP sector
export const CONSUMER_GOODS = 'Consumer Goods';
export const EXTRACTIVES_MINERALS = 'Extractives and Minerals Processing';
export const FINANCIALS = 'Financials';
export const FOOD_BEVERAGE = 'Food and Beverage';
export const HEALTH_CARE = 'Health Care';
export const INFRA = 'Infrastructure';
export const ENERGY = 'Renewable Resources and Alternative Energy';
export const RESOURCE_TRANSFORMATION = 'Resource Transformation';
export const SERVICES = 'Services';
export const TECH_COMM = 'Technology and Communications';
export const TRANSPORTATION = 'Transportation';

export const INTENSITY = 'Intensity';
export const EMISSION = 'Emission';
export const EMISSION_SOURCE = 'Emission Source';
export const SOVEREIGN = 'Sovereign';

export const INTENSITY_VALUE = 'intensity';
export const EMISSION_VALUE = 'emission';
export const EMISSION_SOURCE_VALUE = 'emission_source';
export const SOVEREIGN_VALUE = 'sovereign';

export const SECTOR_CLASSIFICATION_VALUE = 'sector';
export const SASB = 'SASB';
export const ICE = 'ICE';

export const SASB_VALUE = 'SASB';
export const ICE_VALUE = 'ICE';

// Scope Categories
export const SCOPE_12_TOTAL = 'Scope_12_Total';
export const PURCHASED_GOODS_AND_SERVICES = 'Purchased_Goods_and_Services';
export const CAPITAL_GOODS = 'Capital_Goods';
export const FUEL_AND_ENERGY_RELATED_ACTIVITIES = 'Fuel_and_Energy_Related_Activities';
export const UPSTREAM_TRANSPORT_AND_DISTRIBUTION = 'Upstream_Transport_and_Distribution';
export const WASTE_GENERATED = 'Waste_Generated';
export const BUSINESS_TRAVEL = 'Business_Travel';
export const EMPLOYEE_COMMUTING = 'Employee_Commuting';
export const UPSTREAM_LEASED_ASSETS = 'Upstream_Leased_Assets';
export const DOWNSTREAM_TRANSPORT_DISTRIBUTION = 'Downstream_Transport_Distribution';
export const PROCESSING_OF_SOLD_PRODUCTS = 'Processing_of_Sold_Products';
export const USE_OF_SOLD_PRODUCTS = 'Use_of_Sold_Products';
export const END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS = 'End_of_Life_Treatment_of_Sold_Products';
export const DOWNSTREAM_LEASED_ASSETS = 'Downstream_Leased_Assets';
export const FRANCHISES = 'Franchises';
export const INVESTMENTS = 'Investments';

export const INCLUDE = 'Include';
export const INCLUDE_KEY = 'include';
export const TARGETS = 'Targets';
export const SBTI_TEMP_SCORE = 'SBTi Temperature Score';
export const INCLUDE_NONE = 'None';
export const GROUPE_BY = 'Group By';
export const GROUPE_BY_KEY = 'group_by';

export const INCLUDE_TARGETS_KEY = 'includeTargets';
export const INCLUDE_TARGETS = 'Include Targets';
export const INCLUDE_TARGETS_NO = 'No';
export const INCLUDE_TARGETS_YES = 'Yes';
export const INCLUDE_TARGETS_NO_VALUE = false;
export const INCLUDE_TARGETS_YES_VALUE = true;

export const SHOW_NORMALISED_KEY = 'isNormalised';
export const SHOW_NORMALISED = 'Normalised Financed Emissions';
export const SHOW_NORMALISED_NO = 'No';
export const SHOW_NORMALISED_YES = 'Yes';
export const SHOW_NORMALISED_NO_VALUE = false;
export const SHOW_NORMALISED_YES_VALUE = true;

// Alignment Measurement
export const ALIGNMENT_MEASUREMENT = 'Alignment Measurement';
export const ALIGNMENT_EMISSIONS = 'Emissions';
export const NORMALISED_EMISSION = 'Normalised emissions';
export const CUMULATIVE_EMISSION = 'Cumulative emissions';
export const NORMALISED_CUMULATIVE_EMISSIONS = 'Normalised Cumulative Emissions';
export const NORMALISED_EMISSION_VALUE = 'normalised_emissions';
export const CUMULATIVE_EMISSION_VALUE = 'cumulative_emissions';
export const ALIGNMENT_EMISSIONS_VALUE = 'emissions';
export const NORMALISED_CUMULATIVE_EMISSIONS_VALUE = 'normalised_cumulative_emissions';

// Carbon Offset Market
// export const PORTFOLIO_EMISSIONS = 0;
// export const SECTORAL_EMISSIONS = 1;
