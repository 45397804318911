import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { LoadingHOC } from '../../../util/LoadingHOC';
import HeatmapDisplay from './HeatmapDisplay';
import useGetPayload from '../../../hooks/useGetPayload';
import Model from '../../../models/tempScore/portfolioScore';
import { useGetTempMetricHeatmapQuery } from '../../../redux/apiHooks';

const HeatmapDisplayWithLoading = LoadingHOC(HeatmapDisplay);

const Scope3Heatmap = () => {
  const [chartData, setChartData] = useState([]);
  const [yCategories, setYCategories] = useState([]);
  const [xCategories, setXCategories] = useState([]);

  const { filters, payload } = useGetPayload(Model);
  const {
    data: heatmapData,
    isFetching,
    error,
  } = useGetTempMetricHeatmapQuery(payload);

  const { scoreType, emission } = filters;

  const getScoreType = () => {
    if (scoreType === 'shortTerm') {
      return 'short';
    }
    if (scoreType === 'longTerm') {
      return 'long';
    }
    return 'mid';
  };

  const getChartData = () => {
    const cData = [];
    const xCat = [];
    const yCat = [];

    if (!isEmpty(heatmapData)) {
      const score = getScoreType();

      const res = heatmapData[score][emission];

      if (res.length > 0) {
        res.forEach((data) => {
          if (!xCat.includes(data.x)) {
            xCat.push(data.x);
          }
          if (!yCat.includes(data.y)) {
            yCat.push(data.y);
          }
          const xValue = xCat.indexOf(data.x);
          const yValue = yCat.indexOf(data.y);

          cData.push([xValue, yValue, data.z]);
        });
      }
    }

    setChartData(cData);
    setYCategories(yCat);
    setXCategories(xCat);
  };

  useEffect(() => {
    getChartData();
  }, [heatmapData, scoreType, emission]);

  return (
    <HeatmapDisplayWithLoading
      error={error}
      loading={isFetching}
      chartKey="PORT_HEATMAP"
      yAxisCategories={yCategories}
      chartData={chartData}
      xAxisCategories={xCategories}
      isSectoral={true}
    />
  );
};

export default Scope3Heatmap;
