import React from 'react';
import PropTypes from 'prop-types';
import ReportTable from '../../../../components/PdfReport/ReportTable';
import {
  getEmissionsBreakdown,
  getEmissionsByContribution,
  getEmissionsByWeight,
} from '../../../../helpers/report';
import ReportHorizontalBar from '../../../../components/PdfReport/Charts/ReportHorizontalBar';

const emissionsBreakdownColumns = [
  {
    name: ' ',
    selector: 'type',
    right: false,
  },
  {
    name: 'Production',
    selector: 'production',
    right: true,
    noDataText: '-',
  },
  {
    name: 'Export',
    selector: 'exported',
    right: true,
    noDataText: '-',
  },
  {
    name: 'Import',
    selector: 'imported',
    right: true,
    noDataText: '-',
  },
  {
    name: 'Comsumption',
    selector: 'consumption',
    right: true,
    noData: '-',
  },
];
function SovereignEmissions({ data }) {
  const { portfolio, benchmark, auth } = data;
  const {
    currentUser: { client },
  } = auth;

  let emissionsBreakdown = { data: [], categories: [], yAxisTitle: '' };
  let emissionsBreakdownTable = [];
  let emissionsByContribution = { data: [], categories: [], yAxisTitle: '' };
  let emissionsByWeight = { data: [], categories: [], yAxisTitle: '' };

  if (portfolio && benchmark) {
    emissionsBreakdown = getEmissionsBreakdown(
      portfolio.sovereign,
      benchmark.sovereign,
    );

    emissionsBreakdownTable = [
      {
        type: 'Portfolio',
        ...portfolio.sovereign?.portfolio?.data?.portfolio,
      },
      {
        type: 'Benchmark',
        ...benchmark.sovereign?.portfolio?.data?.portfolio,
      },
    ];
    emissionsByContribution = getEmissionsByContribution(
      portfolio.sovereign,
      benchmark.sovereign,
    );

    emissionsByWeight = getEmissionsByWeight(
      portfolio.sovereign,
      benchmark.sovereign,
    );
  }
  return (
    <div className="pdf_page">
      <h2>Sovereign Emissions</h2>
      <div className="span-2">
        <h3>Sovereign Emissions Breakdown (Portfolio)</h3>
        <ReportHorizontalBar
          categories={emissionsBreakdown.categories}
          data={emissionsBreakdown.data}
          yAxisTitle={emissionsBreakdown.yAxisTitle}
        />
        <div>
          <p>
            The Sovereign Emissions Breakdown chart shows the weighted intensity
            breakdown for each emission scope at the portfolio-level for the
            portfolio and\ the benchmark, selected by {client}.
          </p>
          <p>The attribution type is Gross Domestic Product (GDP).</p>
          <p>
            Where a sovereign bond/s are held within the portfolio or benchmark,
            the bonds’ corresponding countries emissions and economics (in this
            case, GDP) data is collected. Each of the sovereign bonds country
            emissions and GDP data is then aggregated to the portfolio-level
            based on its weight within the portfolio or benchmark. This method
            is applied to the above and below charts.
          </p>
        </div>
        <div style={{ marginTop: 2, marginBottom: 20 }}>
          <ReportTable
            columns={emissionsBreakdownColumns}
            data={emissionsBreakdownTable}
          />
        </div>
      </div>
      <div>
        <h3>Country Emissions by Contribution</h3>
        <ReportHorizontalBar
          categories={emissionsByContribution.categories}
          data={emissionsByContribution.data}
          yAxisTitle={emissionsByContribution.yAxisTitle}
        />
        <div>
          <p>
            The Country Emissions by Contribution chart shows the carbon
            intensity contribution by country for the portfolio and the
            benchmark, selected by {client}.
          </p>
          <p>
            The attribution type is GDP and the emission type is Production.
          </p>
        </div>
      </div>
      <div>
        <h3>Country Emissions by Weight</h3>
        <ReportHorizontalBar
          categories={emissionsByWeight.categories}
          data={emissionsByWeight.data}
          yAxisTitle={emissionsByWeight.yAxisTitle}
        />
        <div>
          <p>
            The Country Emissions by Weight chart shows the weighted carbon
            intensity breakdown by country for the portfolio and the benchmark,
            as selected by {client}.
          </p>
          <p>
            The attribution type is GDP and the emission type is Production.
          </p>
        </div>
      </div>
    </div>
  );
}

SovereignEmissions.propTypes = {
  data: PropTypes.shape({
    portfolio: PropTypes.object,
    benchmark: PropTypes.object,
    tempScore: PropTypes.array,
    auth: PropTypes.object,
    filters: PropTypes.object,
  }),
};
export default SovereignEmissions;
