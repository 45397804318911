import React from 'react';
import { useSelector } from 'react-redux';
import { LoadingHOC } from '../../../util/LoadingHOC';
import PortfolioViewDisplay from './PortfolioViewDisplay';
import { portfolioBuilderCells } from '../../../util/TableHeadConfig';

const PortfolioViewWithLoading = LoadingHOC(PortfolioViewDisplay);

const PortfolioEmission = () => {
  const { data, error } = useSelector((state) => state.portfolioBuilder);

  return (
    <PortfolioViewWithLoading
      error={error}
      dataTableData={data}
      columns={portfolioBuilderCells}
      tableHeading="PORTFOLIO_INTENSITY"
      isTrial={true}
    />
  );
};

export default PortfolioEmission;
