import React from 'react';
import PropTypes from 'prop-types';
import ReportColumnChart from '../../../../components/PdfReport/Charts/ReportColumnChart';

function Scope3Overview({ data }) {
  const { portfolio, benchmark } = data;
  let emissionDisclosureData = [];

  if (portfolio?.scope_3_disclosure && benchmark?.scope_3_disclosure) {
    emissionDisclosureData = [
      {
        name: 'portfolio',
        data: portfolio.scope_3_disclosure.data.map(({ value }) => value),
      },
      {
        name: 'benchmark',
        data: benchmark.scope_3_disclosure.data.map(({ value }) => value),
      },
    ];
  }

  return (
    <div
      className="pdf_page pdf_page1"
      data-footer={'[2] https://ghgprotocol.org/scope-3-calculation-guidance-2'}
    >
      <h2>Scope 3 Overview</h2>
      <div className="span-2">
        <h3>Scope 3 Carbon Emissions Disclosure</h3>
        <ReportColumnChart categories={[]} data={emissionDisclosureData} />
        <div>
          <p style={{ marginBottom: 5 }}>
            The Scope 3 Carbon Emissions Disclosure chart highlights the number
            of Scope 3 categories disclosed out of a possible 15, for company
            holdings within the portfolio and benchmark. This relates to the
            issuers that ICE has directly analysed. Disclosure of Scope 3
            emissions can reduce the error in estimating carbon risk exposure
            and allows companies to address areas of high carbon intensity
            within their value chain. Scope 3 data is becoming increasingly
            available and is a critical part of understanding company and
            portfolio-level carbon risks - as it generally accounts for around
            85 percent of a typical company’s total emissions.
          </p>
        </div>
        <div className="categories-list">
          <p>
            The 15 Scope 3 Categories, as defined by the Greenhouse Gas
            Protocol[2], are listed below:
          </p>
          <p>Category 0: No Data</p>
          <p>Category 1: Purchased Goods & Services</p>
          <p>Category 2: Capital Goods</p>
          <p>Category 3: Fuel & Energy Related Activities</p>
          <p>Category 4: Upstream Transportation & Distribution</p>
          <p>Category 5: Waste Generated in Operations</p>
          <p>Category 6: Business Travel</p>
          <p>Category 7: Employee Commuting</p>
          <p>Category 8: Upstream Leased Assets</p>
          <p>Category 9: Downstream Transportation & Distribution</p>
          <p>Category 10: Processing of Sold Products</p>
          <p>Category 11: Use of Sold Products</p>
          <p>Category 12: End-of-Life Treatment of Products</p>
          <p>Category 13: Downstream Leased Assets</p>
          <p>Category 14: Franchises</p>
          <p>Category 15: Investments</p>
        </div>
      </div>
    </div>
  );
}

Scope3Overview.propTypes = {
  data: PropTypes.shape({
    portfolio: PropTypes.object,
    benchmark: PropTypes.object,
    tempScore: PropTypes.array,
    auth: PropTypes.object,
    filters: PropTypes.object,
  }),
};
export default Scope3Overview;
