import React from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import TabPanel from '../../components/TabPanel';
import { tempMetricTabs } from '../../util/tabs-config';
import PortTemperatureScore from './PortTempScore/PortTemperatureScore';
import CompanyAnalysis from './CompanyAnalysis/CompanyAnalysis';
import Attribution from './Attribution/Attribution';
import Heatmap from './Heatmap/Heatmap';
import ContributionAnalysis from './ContributionAnalysis/ContributionAnalysis';
import SectoralTempScore from './SectoralTempScore/SectoralTempScore';
import useTabValue from '../../hooks/useTabValueHook';

function TemperatureMetric() {
  const [tabValue, onTabChange] = useTabValue();

  return (
    <>
      <Paper position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={onTabChange}
          indicatorColor="secondary"
          data-testid="TemperatureMetricTabs"
        >
          {tempMetricTabs
            && tempMetricTabs.map((e) => (
              <Tab
                key={e}
                label={e}
                style={{ fontSize: 11 }}
                data-testid={`tab-${e}`}
              />
            ))}
        </Tabs>
      </Paper>

      <TabPanel value={tabValue} index={0}>
        <PortTemperatureScore />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <CompanyAnalysis />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Attribution />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <SectoralTempScore />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <ContributionAnalysis />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <Heatmap />
      </TabPanel>
    </>
  );
}
export default TemperatureMetric;
