import { useDispatch, useSelector } from 'react-redux';
import { setTabValue } from '../redux/reducers/authReducer';

/**
 * A custom hook to handle current tab
 * @important: handleChange accepts 2nd parameter as value
 */

const useTabValue = () => {
  const tabValue = useSelector((state) => state.auth.tabValue);

  const dispatch = useDispatch();

  const onTabChange = (event, newValue) => {
    dispatch(setTabValue(newValue));
  };

  return [tabValue, onTabChange];
};

export default useTabValue;
