import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import DataTable from '../../../components/Table/DataTable';
import HorizontalBar from '../../../components/Charts/HorizontalBar';

const CoalPowerDisplay = ({ chartData, tableData, coalPowerCells }) => (
  <React.Fragment>
    <Grid container>
      <Grid item xs={12}>
        <HorizontalBar
          categories={['']}
          data={chartData}
          title="Coal Power Analysis"
          yAxisTitle="MW"
        />
      </Grid>
      <Grid item xs={12}>
        <Box mt={1}>
          <DataTable
            data={tableData}
            columns={coalPowerCells}
            tableHeading="COAL_POWER"
          />
        </Box>
      </Grid>
    </Grid>
  </React.Fragment>
);

CoalPowerDisplay.propTypes = {
  chartData: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  coalPowerCells: PropTypes.array.isRequired,
};

export default CoalPowerDisplay;
