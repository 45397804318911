/* eslint-disable */

import BaseRequest from '../baseRequest';

export default class CompanyEmission extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem } = props;
    const {
      footprintMetric,
      sector,
      marketValue,
      assetClass,
      inferenceType,
      emission,
    } = filterItem;
    this.footprint = footprintMetric;
    this.sector = sector;
    this.market_value = marketValue;
    this.asset_type = assetClass;
    this.inference = inferenceType;
    this.scope = emission;
  }
}
