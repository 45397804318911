/* eslint-disable react/prop-types */

import React from 'react';
import LineChart from '../../../components/Charts/LineChart';

const HistoricalEmissionDisplay = (props) => (
  <React.Fragment>
    <LineChart
      data={props.data}
      yAxisTitle={props.yAxisTitle}
      title={props.title}
      markerEnabled={true}
      plotLineWidth={2}
      xAxisType="datetime"
    />
  </React.Fragment>
);

export default HistoricalEmissionDisplay;
