import BaseRequest from '../baseRequest';

export default class CompanyModel extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem } = props;
    const {
      assetClass, inferenceType, emission, sector,
    } = filterItem;
    this.asset_type = assetClass;
    this.inference = inferenceType;
    this.sector = sector;
    this.scope = emission;
  }
}
