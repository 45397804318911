import { useSelector } from 'react-redux';

const useGetPayload = (Model) => {
  const auth = useSelector((state) => state.auth);
  const filterItem = useSelector((state) => state.filters);
  const modelInstance = new Model({ ...auth, filterItem });

  return {
    auth,
    filters: filterItem,
    payload: modelInstance.toJsonObject(),
  };
};

export default useGetPayload;
