import BaseRequest from './baseRequest';

export default class Tcfd extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem } = props;
    const { sector, targetScenario } = filterItem;

    this.sector = sector;
    this.scope = filterItem.emission;
    this.scenario = 'Net Zero 2050';
    this.scenario_type = targetScenario;
    this.emission_type = 'net';
    this.inference = 'Avg';
    this.footprint = 'WeightAvgMarketVal';
    this.market_value = 'EnterpriseValCash';
    this.asset_type = ['Eq', 'CB', 'Loan'];
    this.include_historical = true;
    this.show_ccs = false;
  }
}
