import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { LoadingHOC } from '../../../util/LoadingHOC';
import ContributionAnalysisDisplay from './ContributionAnalysisDisplay';
import useGetPayload from '../../../hooks/useGetPayload';
import Model from '../../../models/tempScore/portfolioScore';
import { useGetTempMetricContributionQuery } from '../../../redux/apiHooks';

const ContributionAnalysisWithLoading = LoadingHOC(ContributionAnalysisDisplay);

const ContributionAnalysis = () => {
  const { payload } = useGetPayload(Model);
  const {
    data: contributionAnalysisData,
    isFetching,
    error,
  } = useGetTempMetricContributionQuery(payload);

  const [chartCategories, setChartCategories] = useState([]);
  const [investmentData, setInvetsmentData] = useState([]);
  const [contribData, setContribData] = useState([]);

  const filterItem = useSelector((state) => state.filters);
  const { scoreType, emission } = filterItem;

  const getScoreType = () => {
    if (scoreType === 'shortTerm') {
      return 'short';
    }
    if (scoreType === 'longTerm') {
      return 'long';
    }
    return 'mid';
  };

  const getChartData = () => {
    // let cData = [];
    const tempValues = [];
    const contriData = [
      {
        name: 'Contribution',
        data: [],
      },
    ];
    const investData = [
      {
        name: 'Investment',
        data: [],
      },
    ];
    const cCategories = [];
    if (!isEmpty(contributionAnalysisData)) {
      const score = getScoreType();

      const res = contributionAnalysisData[score][emission];
      if (res.length > 0) {
        res.forEach((data) => {
          contriData[0].data.push({
            name: data.sector,
            y: data.contribution,
          });
          investData[0].data.push({
            name: data.sector,
            y: data.investment,
          });
          tempValues.push(data.temperature_score);
          chartCategories.push(data.sector);
        });
        // cData = [
        //   {
        //     name: 'Temperature Score',
        //     data: tempValues,
        //   },
        // ];
      }
    }
    // setChartData(cData);
    setChartCategories(cCategories);
    setInvetsmentData(investData);
    setContribData(contriData);
  };

  useEffect(() => {
    getChartData();
  }, [contributionAnalysisData, scoreType, emission]);

  return (
    <React.Fragment>
      <ContributionAnalysisWithLoading
        loading={isFetching}
        error={error}
        investmentData={investmentData}
        contribData={contribData}
        investmentChartKey="INVESTMENT"
        contributionChartKey="CONTRIBUTION"
      />
    </React.Fragment>
  );
};

export default ContributionAnalysis;
