import React from 'react';
import '../table.css';
import AssetEmissionsBreakdownDisplay from './AssetEmissionsBreakdownDisplay';
import { LoadingHOC } from '../../../util/LoadingHOC';
import usePcafScreenData from '../../../hooks/usePcafScreenData';
import useGetPayload from '../../../hooks/useGetPayload';
import BreakdownByAssetClassPcaf from '../../../models/pcaf/breakdownByAssetClassPcaf';
import { useGetEmissionPcafQuery } from '../../../redux/apiHooks';

const AssetEmissionsBreakdownWithLoading = LoadingHOC(
  AssetEmissionsBreakdownDisplay,
);

export default function AssetEmissionsBreakdown() {
  const { filters, payload } = useGetPayload(BreakdownByAssetClassPcaf);
  const { data, isFetching, error } = useGetEmissionPcafQuery(payload);
  const { assetType } = filters;

  const {
    emission,
    // portfolioEmissionPcaf,
    portfolioFootprintReported,
    sectorCategories,
    sectorCategoriesData,
    reportedInferredData,
    categories,
    portfolioFootprint,
    pcafScoreDisclosureCategoryData,
    tempBaryAxisTitle,
    updatePortfolioFootprintReported,
  } = usePcafScreenData({ data, assetType });

  return (
    <AssetEmissionsBreakdownWithLoading
      error={error}
      loading={isFetching}
      selectValue={portfolioFootprint}
      categoriesStack={categories}
      data={pcafScoreDisclosureCategoryData}
      title={`${assetType} Footprint (${portfolioFootprint})`}
      yAxisTitle={tempBaryAxisTitle}
      onChange={(e) => {
        updatePortfolioFootprintReported(e.target.value);
      }}
      selectValue2={portfolioFootprintReported}
      data2={reportedInferredData}
      title2={`${assetType} Footprint (${portfolioFootprintReported})`}
      tableHeading="PORTFOLIO_FOOTPRINT"
      categoriesColumnChart={sectorCategories}
      dataColumnChart={sectorCategoriesData}
      title3={`Sector Intensity ${
        emission === 'Sc12' ? 'Scope 1+2' : 'Scope 1+2+3'
      }`}
      chartKey2="PORTFOLIO_FOOTPRINT_REPORTED_VS_INFERRED"
      dynamicTitle2={`${assetType} Footprint (${portfolioFootprintReported})`}
      assetType={assetType}
      pcafData={data}
    />
  );
}
