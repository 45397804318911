import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import BaseChart from './BaseChart';
import useCurrentTheme from '../../hooks/useCurrentTheme';
import ChartProps from './ChartProps';

highchartsMore(Highcharts);
solidGauge(Highcharts);
function GaugeChart(props) {
  const { usedStyles } = useCurrentTheme(props.theme);
  const options = {
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: [],
    },
    pane: {
      startAngle: 0,
      endAngle: 360,
      background: [
        {
          outerRadius: '90%',
          innerRadius: '66%',
          borderWidth: 0,
        },
      ],
    },
    toottip: { enabled: false },
    plotOptions: {
      solidgauge: {
        tooltip: {
          valueSuffix: '%',
        },
        borderWidth: '34px',
        dataLabels: {
          enabled: true,
          useHTML: true,
          allowOverlap: true,
        },
        linecap: 'round',
        stickyTracking: false,
        rounded: true,
      },
    },
    series: [
      {
        borderColor: usedStyles.chartColors[0],
        dataLabels: {
          formatter() {
            return `<span style="color: ${usedStyles.chartText}; padding: 0; margin: 0; text">${this.point.y}%</br>${props.chartLabel}</span>`;
          },
          y: -10,
          style: {
            textShadow: false,
            fontSize: '11px',
          },
          borderWidth: 0,
          padding: 0,
        },
        data: [
          {
            color: usedStyles.chartColors[1],
            radius: '78%',
            innerRadius: '78%',
            y: props.data,
          },
        ],
      },
    ],
  };
  return (
    <BaseChart
      {...props}
      Highcharts={Highcharts}
      chartType="solidgauge"
      options={options}
    />
  );
}

GaugeChart.propTypes = {
  ...ChartProps,
  chartLabel: PropTypes.string,
};
export default GaugeChart;
