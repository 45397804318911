import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../../components/Table/DataTable';
import ColumnChart from '../../../components/Charts/ColumnChart';

const TargetSettingDisplay = ({
  categories,
  chartData,
  tableData,
  yAxisTitle,
  tableColumns,
}) => (
  <React.Fragment>
    <ColumnChart
      categories={categories}
      data={chartData}
      title="Target Setting"
      yAxisTitle={yAxisTitle}
    />
    <DataTable
      data={tableData}
      columns={tableColumns}
      tableHeading="TARGET_SETTING"
    />
  </React.Fragment>
);

TargetSettingDisplay.propTypes = {
  categories: PropTypes.array.isRequired,
  chartData: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  tableColumns: PropTypes.array.isRequired,
  yAxisTitle: PropTypes.string.isRequired,
};

export default TargetSettingDisplay;
