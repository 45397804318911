// eslint-disable-next-line import/no-cycle
import apiSlice, { getQuery } from '../apiSlice';

const transformErrorResponse = ({ data }) => data?.message || 'Data not found for given Portfolio.';

export const carbonMarketApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPortfolioCarbonMarket: builder.query({
      query: getQuery.get('/carbon_offset/portfolio'),
      transformErrorResponse,
    }),
    getSectorCarbonMarket: builder.query({
      query: getQuery.post('/carbon_offset/sector'),
      transformErrorResponse,
    }),
    getCompanyCarbonMarket: builder.query({
      query: getQuery.post('/carbon_offset/company'),
      transformErrorResponse,
    }),
  }),
});

export const {
  useGetPortfolioCarbonMarketQuery,
  useGetSectorCarbonMarketQuery,
  useGetCompanyCarbonMarketQuery,
  useLazyGetPortfolioCarbonMarketQuery,
  useLazyGetSectorCarbonMarketQuery,
  useLazyGetCompanyCarbonMarketQuery,
} = carbonMarketApiSlice;
