/* eslint-disable */
import React from 'react';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core';
import colorSchema from '../../Themes/config';
import useGetTheme from '../../hooks/useGetTheme';

const createDatePickerTheme = currentTheme => {
  const defaultMaterialTheme = createTheme({
    overrides: {
      MuiButton: {
        root: {
          backgroundColor: `${colorSchema[currentTheme].primary} !important`,
          color: `${colorSchema[currentTheme].buttonText} !important`,
          '&:hover': {
            backgroundColor: `${colorSchema[currentTheme].primary} !important`,
            color: `${colorSchema[currentTheme].buttonText} !important`,
          },
        },
      },
      MuiPickersToolbarText: {
        toolbarBtnSelected: {
          backgroundColor:
            currentTheme !== 'light' &&
            `${colorSchema[currentTheme].mainBackground} !important`,
          color: currentTheme === 'light' && 'white !important',
        },
        toolbarTxt: {
          backgroundColor:
            currentTheme !== 'light' &&
            `${colorSchema[currentTheme].mainBackground} !important`,
          color: currentTheme === 'light' && 'white',
        },
      },
      MuiCard: {
        root: {
          backgroundColor: `${colorSchema[currentTheme].mainBackground} !important`,
          color: colorSchema[currentTheme].text,
          border: '1px solid #b4b4b4',
        },
      },
      MuiDrawer: {
        paperAnchorDockedLeft: {
          background: `${colorSchema[currentTheme].sideNavBackground} !important`,
          width: 73,
        },
      },
      MuiInputLabel: {
        root: {
          color: colorSchema[currentTheme].inputLabel,
        },
      },
      MuiInputBase: {
        root: {
          color: colorSchema[currentTheme].text,
          '&$after': {
            backgroundColor: '#cecece',
          },
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: colorSchema[currentTheme].mainBackground,
        },
      },
      MuiFormLabel: {
        root: {
          color: '#cecece',
        },
      },
      MuiFormControl: {
        root: {
          marginTop: 10,
        },
      },
      MuiTypography: {
        root: {
          color:
            currentTheme !== 'light' &&
            `${colorSchema[currentTheme].text} !important`,
          fontSize: 12,
        },
      },
    },
    palette: {
      type: colorSchema[currentTheme].themeType,
      primary: {
        main: colorSchema[currentTheme].primary,
        light: 'rgb(81, 91, 95)',
        dark: colorSchema[currentTheme].mainBackground,
        contrastText: '#ffffff',
      },
      background: {
        default: colorSchema[currentTheme].mainBackground,
      },
      secondary: {
        main: colorSchema[currentTheme].secondary,
        light: 'rgb(255, 197, 112)',
        dark: colorSchema[currentTheme].mainBackground,
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
    },
  });
  return defaultMaterialTheme;
};

const DatePickers = ({ label, onChange, value, maxDate, minDate }) => {
  const { currentTheme } = useGetTheme();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} className="date-picker">
      <ThemeProvider theme={createDatePickerTheme(currentTheme)}>
        <KeyboardDatePicker
          label={label}
          value={value}
          id="date-picker-inline"
          variant="inline"
          format="MM-DD-yyyy"
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
          // renderInput={params => <TextField {...params} />}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};
export default DatePickers;
