/* eslint-disable */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import UserSessionHandler from '../handler';
import SsoLogin from '../screens/auth/SsoLogin';

function AuthenticatedRoute({ component: SubComp, authenticated, ...rest }) {
  const SSO_LOGIN_URL = `${process.env.REACT_APP_SSO_API_BASE_URL}/appUserLogin?redirectUrl=${window.location.origin}/wb&loginApp=CTF#/pageLogin`;
  const currentUser = UserSessionHandler.currentSession();
  const iceCredentials =
    window.location.pathname === '/wb' && window.location.search;
  let url = '';
  const urlParams = new URLSearchParams(window.location.search);
  const isEmbeded = urlParams.get('embed');
  const theme = urlParams.get('theme');
  const allowedValues = ['true', 'True', '1'];
  if (theme) {
    const sfTheme = (theme === 'light' ? 'ICE light' : theme) || 'ICE light';
    UserSessionHandler.setSFCookie(sfTheme);
    UserSessionHandler.setAppTheme(sfTheme);
  }
  if (
    !currentUser &&
    !iceCredentials &&
    isEmbeded &&
    allowedValues.includes(isEmbeded)
  ) {
    url = process.env.REACT_APP_SF_URL;
    UserSessionHandler.setCookie();
  } else {
    url = SSO_LOGIN_URL;
  }

  return (
    <Route
      {...rest}
      render={props =>
        currentUser ? (
          <SubComp {...props} {...rest} />
        ) : iceCredentials ? (
          <SsoLogin {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location, url },
            }}
          />
        )
      }
    />
  );
}
export default AuthenticatedRoute;
