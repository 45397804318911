/* eslint-disable react/prop-types */
import React from 'react';
import { disclosureCategoriesMap } from '../contants';

function Overview({ data }) {
  const { singleIsin } = data;
  const category = singleIsin?.disclosure?.sc12?.data[0]?.category;
  const sbtiVerified = singleIsin?.sbti_vaidated_targets_per;
  const sbtiVerifiedDisplay = sbtiVerified !== 0 ? `Yes - ${sbtiVerified}%` : 'No';
  return (
    <div
      className="pdf_page pdf_page1"
      data-footer={
        '[1] All directly analysed companies from our dataset within the respective ICE Entity Sub Industry Group are included in the benchmark, with a minimum of 5 companies required to formulate the benchmark.\n[2] For purposes of this report, only companies with a disclosure category of 1-4 are considered for the analysis. Category 5 is excluded.'
      }
    >
      <h2 className="span-2">Overview - {singleIsin?.name}</h2>
      <div className="span-2 overview-info overview-info-category">
        Disclosure Category: <span>{category}</span>{' '}
        <img
          style={{ width: 35, height: 35 }}
          src={disclosureCategoriesMap.get(category)?.logo}
        />
      </div>

      <div className="overview-info">
        ICE Entity Sector: <span>{singleIsin?.sector}</span>
      </div>

      <div className="overview-info">
        ICE Entity Sub Industry Group:{' '}
        <span>{singleIsin?.sub_industry_group}</span>
        <sup>1</sup>
      </div>

      <div className="overview-info">
        Reported Emissions Reduction Targets:{' '}
        <span>{singleIsin?.has_targets ? 'Yes' : 'No'}</span>
      </div>

      <div className="overview-info">
        SBTi Verified: <span>{sbtiVerifiedDisplay}</span>
      </div>

      <h2 className="span-2">
        ICE Emission Data Disclosure Category Classification<sup>2</sup>
      </h2>

      {[...disclosureCategoriesMap.values()].map(
        ({ logo, title, description }) => (
          <div key={title} className="span-2 overview_cat_classification">
            <img src={logo} />
            <b>{title}</b>
            <p>{description}</p>
          </div>
        ),
      )}
    </div>
  );
}

export default Overview;
