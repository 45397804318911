import BaseRequest from '../baseRequest';

export default class PortfolioReturns extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem, reweightFactor } = props;
    const {
      footprintMetric,
      sector,
      marketValue,
      emission,
      inferenceType,
      strategy,
      dateRange,
      rebalanceFrequency,
    } = filterItem;
    this.asset_type = ['Eq'];
    this.footprint = footprintMetric;
    this.sector = sector;
    this.market_value = marketValue;
    this.scope = emission;
    this.inference = inferenceType;
    this.strategy = strategy;
    this.reweight_factor = reweightFactor;
    this.start_date = dateRange?.startDate ? dateRange?.startDate : undefined;
    this.end_date = dateRange?.endDate ? dateRange?.endDate : undefined;
    this.rebalance_frequency = rebalanceFrequency;
  }
}
