import BaseRequest from '../baseRequest';

export default class SovereignPortfolioEmissions extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem } = props;
    const { footprintMetric, alignmentYear, attribution } = filterItem;
    this.footprint = footprintMetric === 'FinancedEmissions'
      ? 'TotalCarbEmis'
      : footprintMetric;
    this.asset_type = ['Sov'];
    this.attribution = footprintMetric === 'FinancedEmissions' ? 'sovereign_debt' : attribution;
    this.year_of_emissions = alignmentYear - 3;
    this.sector = 'SASB';
    // this.emissionType = emissionType;
  }
}
