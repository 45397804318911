import React from 'react';
import useCurrentTheme from '../../hooks/useCurrentTheme';
import BaseChart from './BaseChart';
import ChartProps from './ChartProps';

function DonutChart(props) {
  const { usedStyles } = useCurrentTheme(props.theme);
  const options = {
    tooltip: {
      pointFormat: '<b>{point.additionalData}</b>',
      valueSuffix: '%',
    },
    plotOptions: {
      pie: {
        size: props.size,
        innerSize: '50%',
        dataLabels: {
          format: '{point.name}<br />{point.y} ',
          color: usedStyles.chartText,
        },
      },
    },
    series: [
      {
        // #TO-DO: This should be chart type
        data: props.data,
      },
    ],
  };
  return <BaseChart {...props} options={options} />;
}

DonutChart.propTypes = {
  ...ChartProps,
};
export default DonutChart;
