import React from 'react';
import PropTypes from 'prop-types';
import strings from '../constants';
import ReportTable from '../../../../components/PdfReport/ReportTable';
import {
  getCumlativeEmissionsTable,
  getDivergenceModel,
  getTemperatureScore,
  getBinaryTargetMeasure,
} from '../../../../helpers/report';
import ReportStackedBar from '../../../../components/PdfReport/Charts/ReportStackedBar';
import ReportLineChart from '../../../../components/PdfReport/Charts/ReportLineChart';
import ReportStackedColumn from '../../../../components/PdfReport/Charts/ReportStackedColumn';

const cumulativeEmissionsColumns = [
  {
    name: ' ',
    selector: 'name',
    right: false,
    width: '40%',
  },
  { name: '2030', selector: 2030, right: true },
  { name: '2050', selector: 2050, right: true },
];

const Alignment = ({ theme, data }) => {
  const {
    portfolio,
    benchmark,
    auth,
    filters: { emission },
  } = data;
  const emissionYear = auth?.userInfo?.year?.emissions - 1 || '2019';

  let alignment = { data: [], yAxisTitle: '' };
  let cumulativeEmissions = { data: [], yAxisTitle: '' };
  let cumulativeEmissionsTable = [];
  let temperatureScore = { data: [], yAxisTitle: '', categories: [] };
  let binaryTargetMeasure = [];

  if (portfolio && benchmark) {
    alignment = getDivergenceModel(
      portfolio.portfolio_alignment?.normalised_emissions,
    );
    cumulativeEmissions = getDivergenceModel(
      portfolio.portfolio_alignment?.cumulative_emissions,
    );
    cumulativeEmissionsTable = getCumlativeEmissionsTable(
      portfolio.portfolio_alignment?.cumulative_emissions,
    );
    binaryTargetMeasure = getBinaryTargetMeasure(portfolio?.coverage);
    temperatureScore = getTemperatureScore(
      portfolio.portfolio_alignment,
      benchmark.portfolio_alignment,
      theme,
    );
  }

  return (
    <>
      <div
        className="pdf_page"
        data-footer={
          '[1] Benchmark Divergence Models, refers to the “benchmark” climate transition pathway, as used by the TCFD framework. The TCFD “Benchmark” is the transition pathway or selected climate scenario \n(for purposes of this report, the TCFD benchmark is Net Zero 2050). This is different to the Benchmark Portfolio, as provided by Testing Client Name.\n https://www.tcfdhub.org/wp-content/uploads/2021/10/PAT_Measuring_Portfolio_Alignment_Technical_Considerations-9.8.pdf'
        }
      >
        <h2>Portfolio Alignment</h2>
        <div className="span-2">
          <p>{strings.alignment.text1}</p>
          <div>
            <p>{strings.alignment.text2}</p>
            <ul style={{ fontFamily: 'Verdana,Geneva,sans-serif' }}>
              <li>
                {strings.alignment.text3.replace(
                  '[client name]',
                  auth.currentUser.client,
                )}
              </li>
              <li>{strings.alignment.text4}</li>
              <li>{strings.alignment.text5}</li>
            </ul>
          </div>
        </div>
        <h3 className="span-2">Binary Target Measure</h3>
        <ReportStackedBar
          categories={['']}
          data={binaryTargetMeasure}
          max={100}
        />
        <div>
          <p>
            {emission === 'Sc12'
              ? strings.alignment.text6_sc12
              : strings.alignment.text6}
          </p>
        </div>
        <div>
          <h3>Benchmark Divergance Model - Alignment</h3>
          <ReportLineChart
            data={alignment.data}
            yAxisTitle={alignment.yAxisTitle}
            emissionYear={emissionYear}
            xAxisType="datetime"
            height={220}
          />
          <div>
            <p>{strings.alignment.text7}</p>
            <p>
              {emission === 'Sc12'
                ? strings.alignment.text8_sc12
                : strings.alignment.text8}
            </p>
          </div>
        </div>
        <div>
          <h3>Benchmark Divergance model - Cumulative Emissions </h3>
          <ReportLineChart
            data={cumulativeEmissions.data}
            yAxisTitle={cumulativeEmissions.yAxisTitle}
            emissionYear={emissionYear}
            xAxisType="datetime"
            height={220}
          />
          <div style={{ marginTop: 2, marginBottom: 5 }}>
            <ReportTable
              columns={cumulativeEmissionsColumns}
              data={cumulativeEmissionsTable}
            />
          </div>
          <div>
            <p>{strings.alignment.text9}</p>
            <p>
              {emission === 'Sc12'
                ? strings.alignment.text10_sc12
                : strings.alignment.text10}
            </p>
          </div>
        </div>
      </div>
      <div className="pdf_page">
        <h3 className="span-2">Implied Temperature Rise Score</h3>
        <ReportStackedColumn
          data={temperatureScore.data}
          categories={temperatureScore.categories}
          yAxisTitle={temperatureScore.yAxisTitle}
        />
        <div>
          <p>{strings.alignment.text11}</p>
          <p>
            {emission === 'Sc12'
              ? strings.alignment.text12_sc12
              : strings.alignment.text12}
          </p>
        </div>
      </div>
    </>
  );
};

Alignment.propTypes = {
  theme: PropTypes.string.isRequired,
  data: PropTypes.shape({
    portfolio: PropTypes.object,
    benchmark: PropTypes.object,
    auth: PropTypes.object,
    filters: PropTypes.object,
  }),
};

export default Alignment;
