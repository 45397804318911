import { push } from 'connected-react-router';
import { NotificationManager } from 'react-notifications';
// import { intersection } from 'lodash';
import UserSessionHandler from '../../handler';
import apiSlice, { getQuery, RTK_TAGS } from '../apiSlice';
import {
  getUserInfoSuccess,
  signinUserSuccess,
  setLoading,
} from './authReducer';
import { setFilterItemSuccess } from './filterReducer';

export const userAuthSliceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfo: builder.query({
      query: getQuery.get('/user/info'),
      providesTags: [RTK_TAGS.USER],
      async onQueryStarted(_, { dispatch, getState, queryFulfilled }) {
        try {
          dispatch(setLoading(true));
          const { data: response } = await queryFulfilled;
          // const isEmbeded = UserSessionHandler.getCookie();
          // const themeInUrl = UserSessionHandler.getSFCookie();
          dispatch(getUserInfoSuccess(response));
          // if (isEmbeded || themeInUrl) {
          //   UserSessionHandler.setAppTheme(themeInUrl || 'ICE light');
          // } else if (context.currentTheme !== response?.theme) {
          //   UserSessionHandler.setAppTheme(response.theme);
          // }
          if (!getState().filters.dateRange?.endDate) {
            dispatch(
              setFilterItemSuccess({
                key: 'dateRange',
                value: {
                  startDate:
                    getState().filters.dateRange?.startDate || '2020-01-01',
                  endDate: response.backtest_end_date,
                },
              }),
            );
          }
          dispatch(setLoading(false));
        } catch (error) {
          if (error?.description?.type === 'change_pwd') {
            NotificationManager.error('Please change the password !');
          }
          dispatch(setLoading(false));
        } finally {
          dispatch(setLoading(false));
        }
      },
    }),
    updateUserInfo: builder.mutation({
      query: getQuery.put('/user/info'),
      // invalidatesTags: (_, __, arg) => {
      //   const invalidTags = [RTK_TAGS.USER];
      //   const argKeys = Object.keys(arg);
      //   const currencyDataRefetchKeys = ['year', 'quarter', 'version', 'display_currency', 'currency_config'];
      //   if (intersection(argKeys, currencyDataRefetchKeys).length > 0) invalidTags.push(RTK_TAGS.CURRENCY);
      //   return invalidTags;
      // },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(getUserInfoSuccess(data));
        dispatch(
          userAuthSliceApi.util.updateQueryData(
            'getUserInfo',
            undefined,
            () => data,
          ),
        );
      },
    }),
    getCurrencyData: builder.query({
      query: ({ year, quarter }) => ({
        url: `currencies/?year=${year}&quarter=${quarter.slice(
          1,
          quarter.length,
        )}`,
      }),
      providesTags: [RTK_TAGS.CURRENCY],
    }),
    useSsoSignIn: builder.query({
      query: getQuery.post('/user/sso_sign_in'),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLoading(true));
          const { data: response } = await queryFulfilled;
          dispatch(signinUserSuccess(response));
          UserSessionHandler.login(response);
          UserSessionHandler.setSupplyCookie(response.access_token);
          if (response?.warning) NotificationManager.warning(response.warning);
          if (response?.message) NotificationManager.success(response.message);
          dispatch(push('/'));
        } finally {
          dispatch(setLoading(false));
        }
      },
    }),
    logout: builder.query({
      query: getQuery.post('/user/sso_sign_out'),
    }),
  }),
});

export const {
  useGetUserInfoQuery,
  useLazyGetUserInfoQuery,
  useUpdateUserInfoMutation,
  useLogoutQuery,
  useLazyLogoutQuery,
  useLazyUseSsoSignInQuery,
  useUseSsoSignInQuery,
  useGetCurrencyDataQuery,
  useLazyGetCurrencyDataQuery,
} = userAuthSliceApi;
