// eslint-disable-next-line import/no-cycle
import apiSlice, { getQuery } from '../apiSlice';

export const optimizationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOptimization: builder.query({
      query: getQuery.post('/portfolio_optimisation/optimisation'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getOptimizationPerformanceAttribution: builder.query({
      query: getQuery.post('/portfolio_optimisation/performance_attribution'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
  }),
});

export const {
  useGetOptimizationQuery,
  useLazyGetOptimizationQuery,
  useGetOptimizationPerformanceAttributionQuery,
  useLazyGetOptimizationPerformanceAttributionQuery,
} = optimizationApiSlice;
