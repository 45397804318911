/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import ReportTable from '../../../../components/PdfReport/ReportTable';
import sc3cat1 from '../../../../assets/report/sc3-cat-1.jpeg';
import sc3cat2 from '../../../../assets/report/sc3-cat-2.jpeg';
import sc3cat3 from '../../../../assets/report/sc3-cat-3.jpeg';
import sc3cat4 from '../../../../assets/report/sc3-cat-4.jpeg';
import sc3cat5 from '../../../../assets/report/sc3-cat-5.jpeg';
import sc3cat6 from '../../../../assets/report/sc3-cat-6.jpeg';
import sc3cat7 from '../../../../assets/report/sc3-cat-7.jpeg';
import sc3cat8 from '../../../../assets/report/sc3-cat-8.jpeg';
import sc3cat9 from '../../../../assets/report/sc3-cat-9.jpeg';
import sc3cat10 from '../../../../assets/report/sc3-cat-10.jpeg';
import sc3cat11 from '../../../../assets/report/sc3-cat-11.jpeg';
import sc3cat12 from '../../../../assets/report/sc3-cat-12.jpeg';
import sc3cat13 from '../../../../assets/report/sc3-cat-13.jpeg';
import sc3cat14 from '../../../../assets/report/sc3-cat-14.jpeg';
import sc3cat15 from '../../../../assets/report/sc3-cat-15.jpeg';
import {
  getComparativeSc3Emisssions,
  getComparativeSc3EmisssionsTable,
  getShareofSc3Emissions,
} from '../../../../helpers/report';
import ProgressBarChart from '../../../../components/ProgressBarChart';
import ReportHorizontalBar from '../../../../components/PdfReport/Charts/ReportHorizontalBar';
import ReportDonutChart from '../../../../components/PdfReport/Charts/ReportDonutChart';

const categoriesImages = [
  sc3cat1,
  sc3cat2,
  sc3cat3,
  sc3cat4,
  sc3cat5,
  sc3cat6,
  sc3cat7,
  sc3cat8,
  sc3cat9,
  sc3cat10,
  sc3cat11,
  sc3cat12,
  sc3cat13,
  sc3cat14,
  sc3cat15,
];

const comparativeSc12EmisssionsTable = [
  {
    name: ' ',
    selector: 'name',
    right: false,
    bold: true,
  },
  { name: 'Scope 3', selector: 'sc3', right: true },
];

function Scope3Emissions({ data }) {
  const { singleIsin, benchmark } = data;
  let comparativeSc3Emisssions = { data: [], yAxisTitle: '', categories: [] };
  let comparativeSc3EmisssionsTable = [];
  let shareofSc3Emissions = { data: [] };
  let scope3InfoTable = [];
  let noOfCompanyCategoriesDisclosed = 0;
  let noOfBenchmarkCategoriesDisclosed = 0;
  let companyName = '';
  let industryBenchmark = '';

  if (singleIsin && benchmark) {
    comparativeSc3Emisssions = getComparativeSc3Emisssions(
      singleIsin.portfolio_emisisons,
      benchmark.portfolio_emisisons,
    );

    comparativeSc3EmisssionsTable = getComparativeSc3EmisssionsTable(
      singleIsin.portfolio_emisisons,
      benchmark.portfolio_emisisons,
    );

    shareofSc3Emissions = getShareofSc3Emissions(
      singleIsin.scope3_share,
      'ICE light',
    );

    scope3InfoTable = benchmark.scope3_info.table;
    noOfCompanyCategoriesDisclosed = benchmark?.scope3_info?.avg_no_of_company_categories_reported;
    noOfBenchmarkCategoriesDisclosed = benchmark?.scope3_info?.avg_no_of_benchmark_categories_reported;
    companyName = singleIsin.name;
    industryBenchmark = singleIsin.sub_industry_group;
  }

  return (
    <div
      className="pdf_page"
      data-footer={`[4] The Most Reported Scope 3 Categories by ${industryBenchmark} uses reported data only and does not consider the materiality of the Scope 3 Categories. Where data is available,\nthe top 3 most reported categories will be displayed`}
    >
      <h2>Scope 3 Emissions</h2>
      <div>
        <h3>Comparative Scope 3 Emissions (Intensity Footprint)</h3>
        <ReportHorizontalBar
          categories={comparativeSc3Emisssions.categories}
          data={comparativeSc3Emisssions.data}
          yAxisTitle={comparativeSc3Emisssions.yAxisTitle}
          xAxisType="datetime"
        />
        <div className="report-text">
          <p>
            The Comparative Scope 3 Emissions (Intensity Footprint) chart
            displays the carbon intensity (or footprint) of {companyName}'s,
            comparative to its {industryBenchmark} peers, in terms of Scope 3.
          </p>
          <p>The selected footprint metric is WACI (Revenue).</p>
        </div>
        <ReportTable
          columns={comparativeSc12EmisssionsTable}
          data={comparativeSc3EmisssionsTable}
        />
      </div>
      <div>
        <h3>Share of Emissions within Scope 3</h3>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ReportDonutChart
            data={shareofSc3Emissions.data}
            height={240}
            width={350}
            size={140}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <p>
            The Share of Emissions within Scope 3 chart shows that{' '}
            {singleIsin?.scope3_share?.max_category.replaceAll('_', ' ')} is{' '}
            {companyName}'s highest contributing Scope 3 category and accounts
            for {shareofSc3Emissions.data[0]?.y}% of {companyName}'s total Scope
            3 Emissions.
          </p>
        </div>
      </div>
      <div className="span-2">
        <h3 className="table-heading">Scope 3 Analysis (Reported)</h3>
        <ReportTable
          columns={[
            {
              headerFormat: (
                <div>
                  Most Reported Scope 3 Categories by{' '}
                  {singleIsin?.sub_industry_group}
                  <sup>4</sup>
                </div>
              ),
              selector: 'category',
              right: false,
              bold: true,
              width: '30%',
              render: ({ row }) => (
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                    gap: 5,
                    margin: '10px 3px',
                  }}
                >
                  <img
                    src={categoriesImages[row.category_number - 1]}
                    width={30}
                    height={30}
                  />
                  <b>
                    Cat {row.category_number}:{' '}
                    {row.category.replaceAll('_', ' ')}
                  </b>
                </div>
              ),
            },
            {
              name: `Percentage of Companies in ${singleIsin?.sub_industry_group} Reporting in Category`,
              selector: 'per_of_comapnies_reported',
              right: true,
              render: ({ data: rowData }) => (rowData ? `${rowData}%` : '-'),
            },
            {
              name: `Intensity (Median) of ${singleIsin?.sub_industry_group}\n (GHG Intensity tCO2e / 1M USD)`,
              selector: 'intensity',
              right: true,
              render: (row) => `${row.data || '-'}`,
            },
            {
              name: `Reported Intensity of ${singleIsin?.name}\n (GHG Intensity tCO2e / 1M USD)`,
              selector: 'company_intensity',
              right: true,
              render: (row) => `${row.data || '-'}`,
            },
          ]}
          data={scope3InfoTable}
        />
        <div>
          <p>
            The Most Reported Scope 3 Analysis table displays the most reported
            Scope 3 Categories that are derived from reported values within the{' '}
            {industryBenchmark} and a comparison against {companyName}).
          </p>
        </div>
      </div>
      <div className="span-2">
        <h3>Scope 3 Category Disclosure and Industry Average</h3>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            width: 350,
            gap: 30,
            margin: '30px auto 0px',
          }}
        >
          <ProgressBarChart
            max={15}
            value={noOfCompanyCategoriesDisclosed}
            text={
              <div>
                <div>
                  Number of Scope 3 Categories Disclosed - {singleIsin?.name}
                </div>
                <div style={{ marginTop: 5, fontStyle: 'italic' }}>
                  Reported only
                </div>
              </div>
            }
          />
          <ProgressBarChart
            max={15}
            value={noOfBenchmarkCategoriesDisclosed}
            text={
              <div>
                <div>
                  Average Number of Scope 3 Categories Disclosed -{' '}
                  {industryBenchmark}
                </div>
                <div style={{ marginTop: 5, fontStyle: 'italic' }}>
                  Reported only
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Scope3Emissions;
