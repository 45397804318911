import React from 'react';
import PropTypes from 'prop-types';
import ReportTable from '../../../../components/PdfReport/ReportTable';
import ReportStackedColumn from '../../../../components/PdfReport/Charts/ReportStackedColumn';
import ReportStackedBar from '../../../../components/PdfReport/Charts/ReportStackedBar';

const DEFAULT_TEMPERATURE_SCORE = 3.2;

const avoidedEmissionsIntensityColumns = [
  {
    name: 'Portfolio',
    selector: 'name',
    sortable: true,
    right: false,
    wrap: true,
  },
  {
    name: 'Short Term Score °C\n(2021 - 2025)',
    selector: 'shortTerm.score',
    sortable: true,
    right: true,
  },
  {
    name: 'Mid Term Score °C\n(2026 - 2036)',
    selector: 'midTerm.score',
    sortable: true,
    right: true,
  },
  {
    name: 'Long Term Score °C\n(>2036)',
    selector: 'longTerm.score',
    sortable: true,
    right: true,
  },
  {
    name: 'Coverage %',
    selector: 'coverage.total',
    sortable: true,
    right: true,
  },
];

function TemperatureMetrics({ data }) {
  const { tempScore } = data;
  let temperatureScoreData = [];
  let companyTemperatureScore = [];
  if (tempScore) {
    const sc123Data = tempScore.filter((row) => row.name.endsWith('1+2+3'));
    temperatureScoreData = [
      {
        name: 'Temp Score',
        data: sc123Data.map((row) => ({
          y: row.midTerm.score,
          color: row.name.split(' ')[0] === 'Portfolio' ? '#000000' : '#71C5EB',
        })),
      },
    ];
    companyTemperatureScore = Object.entries(
      sc123Data.find((row) => row.name.startsWith('Portfolio')).midTerm.bands,
    ).map(([key, value]) => ({
      name: key.replace('_', ' to '),
      data: [value.temperature_score],
    }));
  }

  return (
    <div className="pdf_page">
      <h2>Temperature Metrics (SBTi)</h2>
      <div>
        <h3>Temperature Score</h3>
        <ReportStackedColumn
          data={temperatureScoreData}
          categories={['Portfolio', 'Benchmark']}
          yAxisTitle={'Temperature Score<br /> (degrees C)'}
          yAxisPlotline={[
            { color: '#849AFF', width: 2, value: DEFAULT_TEMPERATURE_SCORE },
          ]}
        />
        {temperatureScoreData.length > 0 && (
          <div style={{ fontSize: 7, textAlign: 'center' }}>
            The default temperature score is {DEFAULT_TEMPERATURE_SCORE}
          </div>
        )}
      </div>
      <div>
        <h3>Company-Level Temperature Score</h3>
        <ReportStackedBar
          categories={['', '']}
          data={companyTemperatureScore}
          xAxisTitle={'Number of Companies'}
        />
      </div>
      <div className="span-2">
        <h3>Temperature Score by Targets Table including Coverage</h3>
        <div style={{ marginTop: 10 }}>
          <ReportTable
            columns={avoidedEmissionsIntensityColumns}
            data={tempScore || []}
          />
        </div>
        <div>
          <p>
            The Temperature Metric translates corporate GHG emission reduction
            targets into portfolio-level temperature scores. This is achieved by
            calculating, for each company, temperature ratings (ITR scores)
            based on the company’s GHG emission reduction targets. Where no
            targets are given, a default score is used. Temperature ratings at
            company-level are weighted to generate the portfolio-level
            temperature ratings. The portfolio temperature score is dependent on
            the selected emissions scope and timeframe, short-term, mid-term or
            long-term.
          </p>
          <p>
            The selected score type for the Temperature Score and Company-Level
            Temperature Score chars is Mid Term Score °C (2026-2036).
          </p>
        </div>
      </div>
    </div>
  );
}

TemperatureMetrics.propTypes = {
  data: PropTypes.shape({
    portfolio: PropTypes.object,
    benchmark: PropTypes.object,
    tempScore: PropTypes.array,
    auth: PropTypes.object,
    filters: PropTypes.object,
  }),
};
export default TemperatureMetrics;
