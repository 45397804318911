import { useSelector } from 'react-redux';

const useGetTheme = () => {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const currentTheme = userInfo?.theme || 'light';
  return {
    currentTheme,
  };
};

export default useGetTheme;
