import { Box } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { setFundsPortfolio } from '../../../services/auth';
import SelectwithSearch from '../../Autocomplete';
import { useGetUserFundsPortfolioListQuery } from '../../../redux/apiHooks';
import { setFundsPortfolioSuccess } from '../../../redux/reducers/authReducer';

const SelectFundsPortfolio = () => {
  const dispatch = useDispatch();
  const { data, isFetching } = useGetUserFundsPortfolioListQuery();
  const currentFundsPortfolio = useSelector(
    (state) => state.auth.currentFundsPortfolio,
  );
  const fundsPortList = data?.fundsPortList || [];

  const onFundsPortfolioChange = async (currentValue) => {
    let portfolio = {};
    if (fundsPortList && fundsPortList.length > 0) {
      portfolio = fundsPortList.find((port) => port.label === currentValue);
      if (currentValue) dispatch(setFundsPortfolioSuccess(portfolio));
    }
  };

  return (
    <Box data-testid="select-funds-portfolio">
      <SelectwithSearch
        heading={'Select Funds Portfolio'}
        data={fundsPortList && fundsPortList.length > 0 ? fundsPortList : []}
        defaultValue={currentFundsPortfolio}
        handleChange={onFundsPortfolioChange}
        type="portfolio"
        currentValue={currentFundsPortfolio}
        disabled={isFetching}
      />
    </Box>
  );
};

export default SelectFundsPortfolio;
