import React from 'react';
import PropTypes from 'prop-types';
import image from '../../../../assets/Getty-936160866_Horiz_color.jpg';
import strings from '../constants';

const { introductions } = strings;
const Introduction = ({ data }) => {
  const {
    portfolio,
    benchmark,
    auth,
    filters: { emission, sector },
  } = data;

  const day = new Date();
  const today = day.toLocaleString('default', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
  const clientName = auth.currentUser.client;
  let fontSize;
  if (clientName.length < 20) {
    fontSize = 40;
  } else if (clientName.length < 60) {
    fontSize = 33;
  } else if (clientName.length < 80) {
    fontSize = 27;
  } else {
    fontSize = 22;
  }
  return (
    <div className="pdf_page">
      <h1 className="span-2" style={{ fontSize, marginBottom: 0 }}>
        Climate Related Portfolio
        {fontSize === 40 ? <br /> : null} Assessment for {clientName}
      </h1>
      <b className="span-2" style={{ fontSize: 18, color: 'blue' }}>
        {strings.introductions.text1}
      </b>
      <img className="span-2" src={image} width={700} height={400} />
      <div>
        <h3>Contents</h3>
        <table className="table-of-content">
          <tbody>
            <tr>
              <td>Emissions Footprint</td>
              <td>2</td>
            </tr>
            <tr>
              <td>Fossil-Fuels & Stranded Assets</td>
              <td>3</td>
            </tr>
            <tr>
              <td>Portfolio Alignment</td>
              <td>4</td>
            </tr>
            <tr>
              <td>Avoided Emissions</td>
              <td>6</td>
            </tr>
            <tr>
              <td>Top 10 by Intensities</td>
              <td>7</td>
            </tr>
            <tr>
              <td>Disclaimer</td>
              <td>8</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <div>
          <h3>Report Specification</h3>
          <table className="table-of-content">
            <tr>
              <td>Report Date</td>
              <td>{today}</td>
            </tr>
            <tr>
              <td>Emission Scope</td>
              <td>
                {emission === 'Sc12' ? 'Scope 1 & 2 only' : 'Scope 1, 2 & 3'}
              </td>
            </tr>
            <tr>
              <td>Sector Classification</td>
              <td>{sector}</td>
            </tr>
            <tr>
              <td>Dataset Period</td>
              <td>{auth.userInfo.year.emissions}</td>
            </tr>
            {/* <tr>
              <td>Benchmark Dataset Period</td>
              <td>Latest data where available</td>
            </tr> */}
          </table>
        </div>
        <div>
          <h3>Portfolio Summary</h3>
          <table className="table-of-content">
            <tr>
              <td>Portfolio Name</td>
              <td>{auth?.currentPortfolio?.label}</td>
            </tr>
            <tr>
              <td>Benchmark Name</td>
              <td>{auth?.currentBenchmark.label}</td>
            </tr>
            <tr>
              <td>Portfolio Coverage </td>
              <td>{portfolio?.coverage.emissions}%</td>
            </tr>
            <tr>
              <td>Benchmark Coverage</td>
              <td>{benchmark?.coverage.emissions}%</td>
            </tr>
          </table>
        </div>
      </div>
      <div className="span-2">
        <h3>Introduction</h3>
        <div>
          <p>{introductions.text2}</p>
          <p>{introductions.text3}</p>
          <p>{introductions.text4}</p>
          <p>{introductions.text5}</p>
          <p>
            {(emission === 'Sc123'
              ? introductions.text6
              : introductions.text6_sc12
            ).replaceAll('[client name]', auth.currentUser.client)}
          </p>
        </div>
      </div>
    </div>
  );
};

Introduction.propTypes = {
  currentTheme: PropTypes.string.isRequired,
  data: PropTypes.shape({
    portfolio: PropTypes.object,
    benchmark: PropTypes.object,
    auth: PropTypes.object,
    filters: PropTypes.object,
  }),
};

export default Introduction;
