import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectwithSearch from '../../Autocomplete';
import { useLazyGetSovereignTimeseriesCountriesQuery } from '../../../redux/apiHooks';
import { setCountrySuccess } from '../../../redux/reducers/filterReducer';

const SelectCountry = () => {
  const dispatch = useDispatch();

  const { currentPortfolio } = useSelector((state) => state.auth);
  const { country: selectedCountry } = useSelector((state) => state.filters);
  const [getCountries, { data: countries = [], isFetching }] = useLazyGetSovereignTimeseriesCountriesQuery();

  useEffect(() => {
    if (!currentPortfolio.value) return;
    getCountries({
      portfolio_id: currentPortfolio.value,
      version_portfolio: currentPortfolio.version,
    }).then(({ data }) => {
      if (data?.[0]) dispatch(setCountrySuccess(data?.[0]));
    });
  }, [currentPortfolio.value, currentPortfolio.version]);

  const onCountryChange = async (currentValue) => {
    let country = {};
    if (countries && countries.length > 0) {
      countries.forEach((con) => {
        if (con.label === currentValue) {
          country = { ...con };
        }
      });
    }
    if (currentValue) dispatch(setCountrySuccess(country));
  };

  return (
    <Box mt={2} data-testid="select-country">
      <SelectwithSearch
        heading={'Select Country'}
        data={countries}
        defaultValue={
          countries.length ? selectedCountry : { label: 'N/A', value: 'N/A' }
        }
        handleChange={onCountryChange}
        type="country"
        currentValue={
          countries.length ? selectedCountry : { label: 'N/A', value: 'N/A' }
        }
        disabled={!(isFetching || countries.length)}
      />
    </Box>
  );
};

export default SelectCountry;
