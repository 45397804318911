/* eslint-disable react/prop-types */

import React from 'react';
import { Box, Grid } from '@material-ui/core';
import DataTable from '../../../components/Table/DataTable';
import GaugeChart from '../../../components/Charts/GaugeChart';
import '../styles.css';
import HorizontalBar from '../../../components/Charts/HorizontalBar';
import DonutChart from '../../../components/Charts/DonutChart';

const AvoidedEmissionDisplay = (props) => (
  <React.Fragment>
    <HorizontalBar
      categories={props.categories}
      data={props.horizontalBarData}
      title={props.chartTitle}
      yAxisTitle={props.yAxisTitle}
    />
    <DataTable
      data={props.dataTableData}
      columns={props.columns}
      tableHeading={props.tableHeading}
      isTrial={props.isTrial}
    />
    {props.avoidedData.length ? (
      <Grid container marginTop={100}>
        <Grid item xs={6}>
          <DonutChart
            data={props.avoidedData}
            title="Avoided Emissions Breakdown (%)"
            chartType="pie"
            height={350}
            width={400}
          />
        </Grid>
        <Grid item xs={6}>
          <GaugeChart
            data={props.avoidedPercentage}
            chartLabel="reported avoided emissions"
          />
        </Grid>
      </Grid>
    ) : (
      <Box align="center" className="error-msg avoided_not_found_text">
        Avoided Emission Details not found
      </Box>
    )}
  </React.Fragment>
);

export default AvoidedEmissionDisplay;
