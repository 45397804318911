/* eslint-disable */
import React from 'react';
import Chip from '@material-ui/core/Chip';

export default function FilterTags({
  name,
  action,
  pathName,
  filterName,
  selected,
}) {
  if (selected) {
    return (
      <Chip
        size="small"
        label={name}
        color="primary"
        onClick={() => action(pathName, filterName, name, !selected)}
        data-testid={`tag-${name}`}
      />
    );
  }
  return (
    <Chip
      variant="outlined"
      size="small"
      label={name}
      onClick={() => action(pathName, filterName, name, !selected)}
      data-testid={`tag-${name}`}
    />
  );
}
