import BaseRequest from '../baseRequest';

export default class DecarbonisationMonitor extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem } = props;
    const { marketValue, emission } = filterItem;

    this.footprint = 'TotalCarbEmis';
    this.asset_type = ['Eq', 'CB'];
    this.market_value = marketValue;
    this.inference = 'Avg';
    this.scope = emission;
  }
}
