import React from 'react';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import TimelineIcon from '@material-ui/icons/Timeline';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import GetAppIcon from '@material-ui/icons/GetApp';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import BarChartIcon from '@material-ui/icons/BarChart';
import CodeIcon from '@material-ui/icons/Code';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import PublicIcon from '@material-ui/icons/Public';

const CATEGORIES_MAP = {
  sc12: 'Scope 1+2',
  sc3: 'Scope 3',
  sc123: 'Scope 1+2+3',
};

const disclosureCategoryMap = new Map();
disclosureCategoryMap.set(
  0,
  'Category 1: Public, complete Scope 1 and 2 data, third-party assurance',
);
disclosureCategoryMap.set(
  1,
  'Category 2: Public, complete Scope 1 and 2 data, no third-party assurance',
);
disclosureCategoryMap.set(
  2,
  'Category 3: Incomplete Scope 1 and 2 data, no third-party assurance',
);
disclosureCategoryMap.set(3, 'Category 4: No public data');
disclosureCategoryMap.set(4, 'Category 5: Not directly analysed');

const pcafCategoryMap = new Map();
pcafCategoryMap.set(0, 'Score 1: Verified Reported Emissions Data');
pcafCategoryMap.set(1, 'Score 2: Unverified Reported Emissions Data');
pcafCategoryMap.set(2, 'Score 3: Emissions Data based on Company`s Production');
pcafCategoryMap.set(3, 'Score 4: Estimated Emissions Data based on Sector');
pcafCategoryMap.set(
  4,
  'Score 5: Estimated Emissions Data with very limited support',
);

const tableMap = new Map();

tableMap.set(0, 'Portfolio Scope 1+2');
tableMap.set(1, 'Benchmark Scope 1+2');
tableMap.set(2, 'Portfolio Scope 1+2+3');
tableMap.set(3, 'Benchmark Scope 1+2+3');

const moduleInfo = [
  {
    title: 'Emissions Footprint',
    description: [
      'Scope 1, 2 and 3 Footprint',
      'Disclosure Quality',
      'Carbon Attribution',
    ],
    icon: 'GraphicEqIcon',
  },
  {
    title: 'Transition Risks',
    description: ['Fossil Fuel Reserve Footprint', 'Country Breakdown'],
    icon: 'BubbleChartIcon',
  },
  {
    title: 'Climate Related Opportunities',
    description: [
      'Avoided Emissions Footprint',
      'Avoided Emissions Breakdown',
      'Reported Avoided Emissions',
    ],
    icon: 'BarChartIcon',
  },
  {
    title: 'Portfolio Alignment',
    description: [
      'Binary Target Measurement',
      'Benchmark Divergence Model',
      'Implied Temperature Rise Score',
    ],
    icon: 'InsertChartIcon',
  },
];

const getAvataricon = (icon) => {
  switch (icon) {
    case 'GraphicEqIcon':
      return <GraphicEqIcon fontSize="large" />;
    case 'VerticalSplitIcon':
      return <VerticalSplitIcon fontSize="large" />;
    case 'InsertChartIcon':
      return <InsertChartIcon fontSize="large" />;
    case 'TimelineIcon':
      return <TimelineIcon fontSize="large" />;
    case 'BubbleChartIcon':
      return <BubbleChartIcon fontSize="large" />;
    case 'ViewWeekIcon':
      return <ViewWeekIcon fontSize="large" />;
    case 'AccountTreeIcon':
      return <AccountTreeIcon fontSize="large" />;
    case 'BarChartIcon':
      return <BarChartIcon fontSize="large" />;
    case 'GetAppIcon':
      return <GetAppIcon fontSize="large" />;
    case 'CodeIcon':
      return <CodeIcon fontSize="large" />;
    case 'AssignmentReturnedIcon':
      return <AssignmentReturnedIcon fontSize="large" />;
    case 'Public':
      return <PublicIcon fontSize="large" />;
    default:
      return <AssignmentReturnedIcon fontSize="large" />;
  }
};

const portEmissionCells = [
  {
    name: 'Portfolios (tCO2e)',
    selector: 'name',
    sortable: true,
    right: false,
    wrap: true,
  },
  {
    name: CATEGORIES_MAP.sc12,
    selector: 'sc12',
    sortable: true,
    right: true,
    cell: (row) => <div>{new Intl.NumberFormat().format(row.sc12)}</div>,
  },
  {
    name: CATEGORIES_MAP.sc3,
    selector: 'sc3',
    sortable: true,
    right: true,
    cell: (row) => <div>{new Intl.NumberFormat().format(row.sc3)}</div>,
  },
  {
    name: CATEGORIES_MAP.sc123,
    selector: 'sc123',
    sortable: true,
    right: true,
    cell: (row) => <div>{new Intl.NumberFormat().format(row.sc123)}</div>,
  },
];

export const tooltipTextData = {
  emission_coverage:
    'Emissions Coverage % is the weighted coverage of the portfolio, as are all other Transition Metric indicators.',
  disclosure_quality:
    'This is based on the weighted percentage of companies with disclosure category 1 or 2 within the portfolio. Where greater than 50% is green, between 50% and 20% is yellow, and less than 20% is red.',
  historical_performance:
    'This is based on the average change in EVIC intensity of the portfolio over two years in percentage, calculated as ((current year WACI by EVIC - previous (2yr historical) year WACI by EVIC / (previous year (2yr historical) WACI by EVIC)) / 2. If a 2 year history is not available, only 1 year will be used. A change below 0% is green, a change between 0% and 10% is yellow, and a change above 10% is red.',
  target_coverage:
    'This is based on the weighted percentage of companies that has set decarbonisation targets within the portfolio. Where greater than 50% is green, between 50% and 20% is yellow, and less than 20% is red.',
  Itr_score:
    'This is based on the Scope 1 & 2 Implied Temperature Rise Score of the portfolio. An aggregated score below 1.5 degree is green, between 1.5 and 2 degree is yellow and above 2 degree is red.',
  on_track_for_targets:
    'This is based on the comparison of the portfolio historic emissions trend with the commitments until 2030. A reduction in emissions below expected pathway is green, emissions within 20% of expected pathway is yellow, and emissions greater than 20% of expected pathway is red.',
  portfolio_value:
    'The total value of the Portfolio expressed in million dollars.',
  intensity_scope12:
    'The weighted carbon emissions of a portfolio expressed in terms of tons of carbon dioxide equivalent (tCO2e) by enterprise value including cash (EVIC) (tCO2e / 1M)',
  company_ab_s12:
    'The carbon emissions (or absolute emissions) of a company expressed in terms of tons of carbon dioxide equivalent (tCO2e)',
  company_is_s12:
    'The carbon emissions of a company expressed in terms of tons of carbon dioxide equivalent (tCO2e) by enterprise value including cash (EVIC) (tCO2e / 1M)',
  company_disclosure_category:
    "This is based on ICE Disclosure Category Classification. Category 1: Complete with Accepted Assurance (covers at least 95% of a company's global emissions and is third party verified) & Category 2: Complete without Accepted Assurance (covers at least 95% of a company's global emissions and is not third party verified) is green, Category 3: Incomplete not third party verified is orange, and Category 4: No public data, & Category 5: Not directly analysed is red.",
  company_historical_performance:
    'This is based on the average change in EVIC intensity of the company over two years in percentage, calculated as ((current year WACI by EVIC - previous (2yr historical) year WACI by EVIC / (previous year (2yr historical) WACI by EVIC)) / 2. If a 2 year history is not available, only 1 year will be used. A change below 0% is green, a change between 0% and 10% is yellow, and a change above 10% is red.',
  company_target_coverage:
    'This is based on whether or not the company has set a GHG emissions reduction target (or targets) and have been SBTi validated. If the company has targets including some that are SBTI-validated then green, if the company has targets but none are SBTI-validated then orange, and if the company has no targets, then red.',
  company_itr_score:
    'This is based on the Scope 1 & 2 Implied Temperature Rise Score of the company. An aggregated score below 1.5 degree is green, between 1.5 and 2 degree is yellow and above 2 degree is red.',
  company_on_track_for_targets:
    'This is based on the comparison of the company historic emissions trend with the commitments until 2030. A reduction in emissions below expected pathway is green, emissions within 20% of expected pathway is yellow, and emissions greater than 20% of expected pathway is red.',
};

export {
  CATEGORIES_MAP,
  disclosureCategoryMap,
  tableMap,
  moduleInfo,
  getAvataricon,
  pcafCategoryMap,
  portEmissionCells,
};
