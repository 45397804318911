import React, { useState, useEffect, useCallback } from 'react';
import { isEmpty } from 'lodash';
import HistoricalEmissionsDisplay from './HistoricalEmissionsDisplay';
import { LoadingHOC } from '../../../util/LoadingHOC';
import useGetPayload from '../../../hooks/useGetPayload';
import SovereignHistoricalEmissionsModel from '../../../models/sovereign/historicalEmissions';
import { useGetSovereignTimeseriesQuery } from '../../../redux/apiHooks';

const HistoricalEmissionsWithLoading = LoadingHOC(HistoricalEmissionsDisplay);

const HistoricalEmissions = () => {
  const [contribData, setEmissionData] = useState([]);

  const { payload } = useGetPayload(SovereignHistoricalEmissionsModel);
  const { data, isFetching, error } = useGetSovereignTimeseriesQuery(payload);

  const getChartData = (production, consumption) => {
    const techChartData = [
      {
        name: 'Consumption',
        data: [],
        color: '#E91E63',
      },
      {
        name: 'Production',
        data: [],
        color: '#CCFF00',
      },
    ];

    Object.keys(consumption).forEach((year) => {
      techChartData[0].data.push([
        Date.UTC(year, '01', '01'),
        consumption[year],
      ]);
    });
    Object.keys(production).forEach((year) => {
      techChartData[1].data.push([
        Date.UTC(year, '01', '01'),
        production[year],
      ]);
    });
    return techChartData;
  };

  const getHistoricalEmisionData = useCallback(() => {
    const productionData = data?.data?.production || {};
    const consumptionData = data?.data?.consumption || {};
    const result = getChartData(productionData, consumptionData);
    setEmissionData(result);
  }, [data]);

  useEffect(() => {
    if (!isEmpty(data?.data)) getHistoricalEmisionData();
  }, [getHistoricalEmisionData]);

  const yTitle = `${data?.metadata?.production?.label} in ${data?.metadata?.production?.unit}`;

  return (
    <HistoricalEmissionsWithLoading
      error={error}
      loading={isFetching}
      data={contribData}
      chartKey="HISTORICAL_EMISSION"
      yAxisTitle={yTitle}
      title="History of Production and Consumption Emissions"
    />
  );
};

export default HistoricalEmissions;
