import BaseSettingsRequest from '../baseSettingsRequest';

export default class Theme extends BaseSettingsRequest {
  constructor(
    userInfo,
    yearEmissions,
    yearFundamentals,
    yearTargets,
    quarterEmissions,
    quarterFundamentals,
    quarterTargets,
    versionEmissions,
    versionFundamentals,
    theme,
    targetYear,
    targetQuarter,
  ) {
    super(
      userInfo,
      yearEmissions,
      yearFundamentals,
      yearTargets,
      quarterEmissions,
      quarterFundamentals,
      quarterTargets,
      versionEmissions,
      versionFundamentals,
      theme,
      targetYear,
      targetQuarter,
    );
    this.theme = theme;
  }
}
