import React from 'react';
import PropTypes from 'prop-types';
import { OutlinedInput } from '@material-ui/core';

const NumberInputWithCommas = ({
  value,
  setValue,
  disabled = false,
  placeholderVal = '-',
}) => {
  const handleValueChange = (e) => {
    const numericValue = e.target.value.replace(/,/g, '');
    if (numericValue && !Number.isNaN(numericValue)) {
      setValue(numericValue);
    } else {
      setValue('0');
    }
  };
  const hasDecimalAtEnd = value.charAt(value.length - 1) === '.';
  const numValue = hasDecimalAtEnd
    ? parseFloat(value.slice(0, value.length - 1))
    : parseFloat(value);
  const val = numValue.toLocaleString('en-GB', { maximumFractionDigits: 10 })
    + (hasDecimalAtEnd ? '.' : '');
  return (
    <OutlinedInput
      type="text"
      fullWidth
      value={Number.isNaN(val) || val === 'NaN' ? placeholderVal : val}
      disabled={disabled}
      onChange={handleValueChange}
    />
  );
};

NumberInputWithCommas.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholderVal: PropTypes.string,
};

export default NumberInputWithCommas;
