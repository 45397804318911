import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import { Route, Switch, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import more from 'highcharts/highcharts-more';
import { PersistGate } from 'redux-persist/es/integration/react';
import './App.css';
// eslint-disable-next-line camelcase
import grouped_categories from 'highcharts-grouped-categories';
import { ConnectedRouter } from 'connected-react-router';
import AuthenticatedRoute from './layouts/AuthenticatedRoute';
import { store, persistor, history } from './redux/store';
import Base from './layouts/Base';
import SsoLogin from './screens/auth/SsoLogin';
import ErrorBoundary from './screens/ErrorBoundary';
import CustomThemeProvider from './Themes/customThemeProvider';

// React notifications css import
import 'react-notifications/lib/notifications.css';

// theme colors
// import colorSchema from './Themes/config';
// import useGetTheme from './hooks/useGetTheme';

// Highcharts import
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/heatmap')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

grouped_categories(Highcharts);
require('dotenv').config();

more(Highcharts);

const App = () => {
  useEffect(() => {
    Sentry.init({
      environment: process.env.REACT_APP_ENV,
      dsn: 'https://a092ae200946420f8adcecea70c1ee1a@o1015769.ingest.sentry.io/5981440',
      ignoreErrors: [/status code 401/],
      beforeSend: (event) => (process.env.REACT_APP_ENV === 'Test' ? null : event),
    });
  }, []);

  const handleRedirection = () => {
    persistor.purge();
    const { state } = useLocation();
    const SSO_LOGIN_URL = `${process.env.REACT_APP_SSO_API_BASE_URL}/appUserLogin?redirectUrl=${window.location.origin}/wb&loginApp=CTF#/pageLogin`;
    window.location.href = state?.url || SSO_LOGIN_URL;
    return null;
  };
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <CustomThemeProvider>
          <ConnectedRouter history={history}>
            <div>
              <ErrorBoundary>
                <Switch>
                  <Route path="/login" component={() => handleRedirection()} />
                  <Route exact path="/wb/:credentials" component={SsoLogin} />
                  <AuthenticatedRoute path="/" component={Base} />
                </Switch>
              </ErrorBoundary>
            </div>
          </ConnectedRouter>
          <NotificationContainer />
        </CustomThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
