import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { LoadingHOC } from '../../../util/LoadingHOC';
import CompanyDisplay from './CompanyDisplay';
import { groupArrayBykey } from '../../../helpers';
import { carbonMarketCell } from '../../../util/TableHeadConfig';
import useGetPayload from '../../../hooks/useGetPayload';
import CompanyModel from '../../../models/carbonMarket/company';
import { useGetCompanyCarbonMarketQuery } from '../../../redux/apiHooks';

const CompanyWithLoading = LoadingHOC(CompanyDisplay);

const Company = () => {
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [yAxisTitle, setYAxisTitle] = useState('');
  const [xAxisLabel, setXAxisTitle] = useState('');

  const { payload } = useGetPayload(CompanyModel);
  const { data, isFetching, error } = useGetCompanyCarbonMarketQuery(payload);

  const getData = async () => {
    if (data && Object.keys(data).length > 0) {
      const companyData = data.data;
      if (companyData) {
        const groupedSectorData = companyData && groupArrayBykey(companyData, 'sector');
        const d = Object.entries(groupedSectorData).map((sectors) => ({
          name: sectors[0],
          data: sectors[1].map((values) => ({
            x: values.carbon_offsets,
            y: values.emissions,
            z: values.weight,
            company: values.company_name,
          })),
        }));
        const tableArray = companyData.map((companies) => ({
          company: companies.company_name,
          isin: companies.pisin,
          sasb_sector: companies.sector,
          weight: companies.weight,
          carbon_offset: companies.carbon_offsets,
          emissions: companies.emissions,
        }));
        setChartData(d);
        setTableData(tableArray);
        setYAxisTitle(data.metadata.y_axis);
        setXAxisTitle(data.metadata.x_axis);
      }
    }
  };

  useEffect(() => {
    if (data && !isEmpty(data)) getData();
  }, [data]);

  return (
    <CompanyWithLoading
      error={error}
      loading={isFetching}
      data={chartData}
      dataTableData={tableData}
      columns={carbonMarketCell}
      xAxisLabel={xAxisLabel}
      yAxisLabel={yAxisTitle}
    />
  );
};

export default Company;
