import React from 'react';
import PropTypes from 'prop-types';

import { formatNumber } from '../../../../util/formatNumber';
import { getSectorData } from '../../../../helpers/report';
import ReportHorizontalBar from '../../../../components/PdfReport/Charts/ReportHorizontalBar';

function Sectors({ data }) {
  const { portfolio, benchmark, auth } = data;
  let contribution = { data: [], yAxisTitle: '', categories: [] };
  let weight = { data: [], yAxisTitle: '', categories: [] };

  if (portfolio && benchmark) {
    contribution = getSectorData(
      portfolio.sector_emission?.sector_contribution,
      benchmark.sector_emission?.sector_contribution,
    );

    weight = getSectorData(
      portfolio.sector_emission?.sector_weights,
      benchmark.sector_emission?.sector_weights,
    );
  }

  return (
    <div className="pdf_page">
      <h3 className="span-2">
        Top 10 Companies by Intensity & Portfolio Weights
      </h3>
      <div>
        <div className="list-of-companies_header">
          Top 10 Intensity Contributors (Scope 1, 2 & 3)
        </div>
        <table className="list-of-companies">
          <tr>
            <th>Company Name</th>
            <th>
              Contributions
              <br />
              (tCO2e/1M USD)
            </th>
          </tr>
          {portfolio?.top_10_emission?.by_contribution?.data.map((intensity) => (
            <tr key={intensity.Company}>
              <td>{intensity.Company}</td>
              <td>{formatNumber(intensity.Contribution_Scope_123)}</td>
            </tr>
          ))}
        </table>
      </div>
      <div>
        <div
          className="list-of-companies_header"
          style={{ marginLeft: -10, paddingLeft: 7 }}
        >
          Top 10 Intensities (Scope 1, 2 & 3)
        </div>
        <table className="list-of-companies">
          <tr>
            <th>Company Name</th>
            <th>
              Intensities
              <br />
              (tCO2e/1M USD)
            </th>
          </tr>
          {portfolio?.top_10_emission?.by_intensities?.data.map((intensity) => (
            <tr key={intensity.Company}>
              <td>{intensity.Company}</td>
              <td>{formatNumber(intensity.Intensity_Scope_123)}</td>
            </tr>
          ))}
        </table>
      </div>
      <div>
        <div>
          <p>
            The Top 10 Intensity Contributors displays the top 10 companies with
            the highest carbon intensity contribution (scopes 1, 2 & 3) within
            the portfolio. This is calculated by using a company’s revenue,
            total emissions, and weighting within the portfolio.
          </p>
        </div>
      </div>
      <div>
        <div>
          <p>
            The Top 10 Intensities displays the top 10 companies with the
            highest carbon intensities (scopes 1, 2 & 3) within the portfolio.
            This is calculated by using a company’s revenue and total emissions.
          </p>
        </div>
      </div>
      <div>
        <h3>Sectoral Contributions</h3>
        <ReportHorizontalBar
          categories={contribution.categories}
          data={contribution.data}
          yAxisTitle={contribution.yAxisTitle}
          height={300}
        />
        <div>
          <p>
            The Sectoral Contributions chart displays the weighted contribution
            of each sector towards the total carbon intensity of the portfolio
            and benchmark, selected by {auth.currentUser.client}.
          </p>
        </div>
      </div>
      <div>
        <h3>Sectoral Weights</h3>
        <ReportHorizontalBar
          categories={weight.categories}
          data={weight.data}
          yAxisTitle={weight.yAxisTitle}
          height={300}
        />
        <div>
          <p>
            The Sectoral Weights chart displays the allocated weight in each
            sector within the portfolio and the benchmark, selected by{' '}
            {auth.currentUser.client}.
          </p>
        </div>
      </div>
    </div>
  );
}

Sectors.propTypes = {
  data: PropTypes.shape({
    portfolio: PropTypes.object,
    benchmark: PropTypes.object,
    tempScore: PropTypes.array,
    auth: PropTypes.object,
    filters: PropTypes.object,
  }),
};

export default Sectors;
