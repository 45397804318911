import React from 'react';
import PropTypes from 'prop-types';
import LineAndScatterChart from '../../Charts/LineAndScatterChart';

function ReportLineAndScatterChart({ data, emissionYear, yAxisTitle }) {
  return (
    <LineAndScatterChart
      data={data}
      emissionYear={emissionYear}
      yAxisTitle={yAxisTitle}
      height={250}
      theme={'ICE light'}
      isExportable={false}
      plotLineWidth={1}
      showLegend={true}
      fontSize={7}
      yAxisGridLineWidth={0.3}
    />
  );
}

ReportLineAndScatterChart.propTypes = {
  data: PropTypes.array,
  emissionYear: PropTypes.number,
  yAxisTitle: PropTypes.string,
};

export default ReportLineAndScatterChart;
