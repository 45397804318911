import BaseRequest from './baseRequest';

export default class Report extends BaseRequest {
  constructor(props) {
    super(props);

    this.attribution = 'gdp';
    this.scale_by_sector = true;
    // this.currency_date = {
    //   year: 2020,
    //   quarter: 'Q1',
    // };
    this.display_ccy = 'USD';
    this.benchmark_on = true;
    this.sector = 'ICE';
    this.scope = 'Sc123';
    this.scenario = 'Net Zero 2050';
    this.scenario_type = 'ngfs_3';
    this.emission_type = 'net';
    this.inference = 'Avg';
    this.footprint = 'WeightAvgMarketVal';
    this.market_value = 'EnterpriseValCash';
    this.asset_type = ['Eq', 'CB', 'Loan'];
    this.include_historical = true;
    this.show_ccs = false;
  }
}
