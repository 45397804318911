import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import { portfolioRisk } from '../../../util/TableHeadConfig';
import PortfolioRiskDisplay from './PortfolioRiskDisplay';
import { LoadingHOC } from '../../../util/LoadingHOC';
import useGetPayload from '../../../hooks/useGetPayload';
import PortfolioReturns from '../../../models/optimization/portfolioReturns';
import { useGetOptimizationQuery } from '../../../redux/apiHooks';

const PortfolioRiskWithLoading = LoadingHOC(PortfolioRiskDisplay);

const useStyles = makeStyles(() => ({
  formControl: {
    width: 300,
    margin: 15,
  },
}));

const PortFolioRisk = () => {
  const classes = useStyles();
  const [underWaterData, setunderWaterData] = useState([]);
  const [underWatercategories, setunderWatercategories] = useState([]);
  const [varCurveChartData, setVarCurveChartData] = useState({});
  const [varCurveFilter, setvarCurveFilter] = useState('portfolio');
  const [omegaChartData, setOmegaChartData] = useState([]);
  const [tableData, settableData] = useState([]);

  const { auth, payload } = useGetPayload(PortfolioReturns);
  const {
    data: optimizationData,
    isFetching,
    error,
  } = useGetOptimizationQuery(payload);

  const { showBenchmark } = auth;

  const getTableData = () => {
    const names = [
      'Maximum Drawdown',
      'Daily Historical VaR (95)',
      'Daily Parametric VaR (95)',
      'Monthly Historical VaR (95)',
      'Monthly Parametric VaR (95)',
    ];
    const benchmarkTableData = showBenchmark
      ? optimizationData.benchmark.table_and_charts.metrics_table
      : {};
    const portfolioTableData = optimizationData.portfolio.table_and_charts.metrics_table;
    const tiltedTableData = optimizationData.tilted.table_and_charts.metrics_table;
    const tabledata = names.map((name) => ({
      name,
      portfolio: portfolioTableData[name],
      benchmark: benchmarkTableData[name],
      tilted: tiltedTableData[name],
    }));
    return tabledata;
  };

  const getvarcurveData = () => {
    const portfoliolineData = optimizationData.portfolio.table_and_charts.var_curve;
    const tiltedlineData = optimizationData.tilted.table_and_charts.var_curve;
    let benchmark;
    if (showBenchmark) {
      const benchmarklineData = optimizationData.benchmark.table_and_charts.var_curve;
      benchmark = Object.keys(benchmarklineData).map((el) => ({
        name: el,
        data: Object.entries(benchmarklineData[el]),
      }));
    }

    return {
      varCurveData: {
        portfolio: Object.keys(portfoliolineData).map((el) => ({
          name: el,
          data: Object.entries(portfoliolineData[el]),
        })),
        tilted: Object.keys(tiltedlineData).map((el) => ({
          name: el,
          data: Object.entries(tiltedlineData[el]),
        })),
        ...benchmark,
      },
    };
  };

  const getOmegaChartData = () => {
    const portfolioOmegaData = optimizationData.portfolio.table_and_charts.omega_curve;
    const tiltedOmegaData = optimizationData.tilted.table_and_charts.omega_curve;

    const omegaData = [
      {
        name: 'portfoilio',
        data: Object.entries(portfolioOmegaData),
      },
      {
        name: 'tilted',
        data: Object.entries(tiltedOmegaData),
      },
    ];

    if (showBenchmark) {
      const benchmarkOmegaData = optimizationData.benchmark.table_and_charts.omega_curve;
      omegaData.push({
        name: 'benchmark',
        data: Object.entries(benchmarkOmegaData),
      });
    }
    return omegaData;
  };

  const handleVarCurveChange = async (e) => {
    setvarCurveFilter(e.target.value);
    // if (!isEmpty(optimizationData)) {
    //   const { varCurveData } = getvarcurveData(optimizationData);
    //   setVarCurveChartData({ ...varCurveData });
    // }
  };

  const getareaData = () => {
    const portfolioareaData = optimizationData.portfolio.table_and_charts.underwater_plot;
    const tiltedareaData = optimizationData.tilted.table_and_charts.underwater_plot;
    const underWaterPlotData = [
      {
        name: 'Portfolio',
        data: Object.values(portfolioareaData),
      },
      {
        name: 'Tilted',
        data: Object.values(tiltedareaData),
      },
    ];

    if (showBenchmark) {
      const benchmarkareaData = optimizationData.benchmark.table_and_charts.underwater_plot;
      underWaterPlotData.push({
        name: 'Benchmark',
        data: Object.values(benchmarkareaData),
      });
    }
    return {
      underWatercategoriesArr: Object.keys(portfolioareaData),
      underWaterDataArr: underWaterPlotData,
    };
  };

  const getData = () => {
    const { underWaterDataArr, underWatercategoriesArr } = getareaData();
    const { varCurveData } = getvarcurveData();
    const omegaData = getOmegaChartData();
    const underWaterTableData = getTableData();
    setunderWaterData(underWaterDataArr);
    setunderWatercategories(underWatercategoriesArr);
    setVarCurveChartData({ ...varCurveData });
    setOmegaChartData(omegaData);
    settableData(underWaterTableData);
  };

  useEffect(() => {
    if (!isEmpty(optimizationData)) getData();
  }, [optimizationData]);

  const {
    var_curve: varCurve,
    omega_curve: omegaCurve,
    underwater_plot: underWaterPlot,
  } = optimizationData?.metadata?.charts || {};
  const varCurveYaxisTitle = varCurve?.y_axis || '';
  const varCurveXaxisTitle = varCurve?.x_axis || '';
  const omegaYaxisTitle = omegaCurve?.y_axis || '';
  const omegaXaxisTitle = omegaCurve?.x_axis || '';
  const underwaterXaxisTitle = underWaterPlot?.x_axis || '';
  const underwaterYaxisTitle = underWaterPlot?.y_axis || '';

  const portRiskCells = showBenchmark
    ? [
      ...portfolioRisk,
      {
        name: 'Benchmark(%)',
        selector: 'benchmark',
        sortable: true,
        right: true,
        cell: (row) => (
            <div>{new Intl.NumberFormat().format(row.benchmark)}</div>
        ),
      },
    ]
    : portfolioRisk;

  return (
    <PortfolioRiskWithLoading
      loading={isFetching}
      error={error}
      showBenchmark={showBenchmark}
      categoriesB={underWatercategories}
      dataB={underWaterData}
      yAxisTitleB={underwaterYaxisTitle}
      xAxisTitleB={underwaterXaxisTitle}
      className={classes.formControl}
      dataD={tableData}
      columnsD={portRiskCells}
      tableHeadingD="PORTFOLIO_RISK"
      valueS={varCurveFilter}
      onChangeS={handleVarCurveChange}
      dataL={varCurveChartData[varCurveFilter]}
      xAxisTypeL="string"
      xAxisTitleL={varCurveXaxisTitle}
      yAxisTitleL={varCurveYaxisTitle}
      pointStartL={1}
      omegaChartData={omegaChartData}
      xAxisType="number"
      xAxisTitle={omegaXaxisTitle}
      yAxisTitle={omegaYaxisTitle}
    />
  );
};

export default PortFolioRisk;
