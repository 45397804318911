import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import './Pdf.css';

function Pdf({ downloading, children }) {
  return (
    <div>
      {downloading && <LinearProgress thickness={2} />}
      <div className="pdf_container">{children}</div>
    </div>
  );
}

Pdf.propTypes = {
  isVisible: PropTypes.bool,
  filename: PropTypes.string,
  children: PropTypes.children,
  downloading: PropTypes.bool,
};

export default Pdf;
