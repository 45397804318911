import BaseRequest from './baseRequest';

export default class Benchmark extends BaseRequest {
  constructor(props) {
    super(props);
    this.benchmark_on = true;
    this.version_fundamentals = '1';
    this.version_emissions = '11';
    this.scope = 'Sc123';
    this.scenario = 'Net Zero 2050';
    this.scenario_type = 'ngfs_3';
    this.emission_type = 'net';
    this.inference = 'Avg';
    this.footprint = 'WeightAvgMarketVal';
    this.market_value = 'EnterpriseValCash';
    this.asset_type = ['Eq', 'CB', 'Loan'];
    this.include_historical = true;
    // need to remove them from the base request
    this.portfolio_id = undefined;
    this.version_portfolio = undefined;
    this.benchmark_id = undefined;
    this.version_benchmark = undefined;
  }
}
