import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { LoadingHOC } from '../../../util/LoadingHOC';
import CarbonEmissionDisplay from './CarbonEmissionDisplay';
import { getSortedData, getEmission } from '../../../helpers';
import useGetPayload from '../../../hooks/useGetPayload';
import SectorEmissions from '../../../models/emissions/sectorEmissions';
import { useGetEmissionSectorEmissionsQuery } from '../../../redux/apiHooks';

const CarbonEmissionsWithLoading = LoadingHOC(CarbonEmissionDisplay);

const CarbonEmission = () => {
  const { auth, filters, payload } = useGetPayload(SectorEmissions);
  const {
    data: sectorData,
    isFetching,
    error,
  } = useGetEmissionSectorEmissionsQuery(payload);

  const { showBenchmark } = auth;
  const { inferenceType, emission } = filters;

  const [sectorIntensityData, setIntensityData] = useState([]);
  const [contribData, setContribData] = useState([]);
  const [sectorWeightData, setSectorWeightData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [yAxisTitle, setYTitle] = useState('');

  const getChartData = (portData, benchData, emissionIntensity) => {
    let portfolio = [];
    let benchmark = [];
    let result = [];
    let sectorCategories = [];

    const sortedPortData = getSortedData(portData);
    const sortedbenchData = getSortedData(benchData);

    if (sortedPortData && Object.keys(sortedPortData).length > 0) {
      portfolio = Object.keys(sortedPortData).map((sector) => {
        const portValue = sortedPortData[sector][emissionIntensity];
        return portValue;
      });
    }

    if (sortedbenchData && Object.keys(sortedbenchData).length > 0) {
      benchmark = Object.keys(sortedbenchData).map((sector) => {
        const benchValue = sortedbenchData[sector][emissionIntensity];
        return benchValue;
      });
    }
    result = [
      {
        name: 'Portfolio',
        data: [...portfolio],
      },
    ];

    if (showBenchmark) {
      result.push({
        name: 'Benchmark',
        data: [...benchmark],
      });
    }
    sectorCategories = Object.keys(sortedPortData);
    setCategories(sectorCategories);
    return result;
  };

  const getSectorIntensity = () => {
    const emissionIntensity = getEmission(emission);
    let yTitle = '';
    let result = [];
    if (!isEmpty(sectorData.data)) {
      yTitle = `${sectorData.metadata.intensity.label} ${sectorData.metadata.intensity.units}`;

      const portData = inferenceType === 'Avg'
        ? sectorData.data.portfolio.avg.intensity
        : sectorData.data.portfolio.max.intensity;
      const benchData = showBenchmark
        ? inferenceType === 'Avg'
          ? sectorData.data.benchmark.avg.intensity
          : sectorData.data.benchmark.max.intensity
        : [];
      result = getChartData(portData, benchData, emissionIntensity);
    }

    setIntensityData(result);
    setYTitle(yTitle);
  };
  const getSectorContribution = () => {
    const emissionContrib = getEmission(emission);
    let result = [];
    if (!isEmpty(sectorData.data)) {
      const portData = inferenceType === 'Avg'
        ? sectorData.data.portfolio.avg.contribution
        : sectorData.data.portfolio.max.contribution;
      const benchData = showBenchmark
        ? inferenceType === 'Avg'
          ? sectorData.data.benchmark.avg.contribution
          : sectorData.data.benchmark.max.contribution
        : [];
      result = getChartData(portData, benchData, emissionContrib);
    }
    setContribData(result);
  };

  const getSectorWeight = () => {
    let result = [];

    if (!isEmpty(sectorData.data)) {
      const portData = inferenceType === 'Avg'
        ? sectorData.data.portfolio.avg.weights
        : sectorData.data.portfolio.max.weights;
      const benchData = showBenchmark
        ? inferenceType === 'Avg'
          ? sectorData.data.benchmark.avg.weights
          : sectorData.data.benchmark.max.weights
        : [];
      const sortedPortData = getSortedData(portData);
      const sortedbenchData = getSortedData(benchData);
      result = [
        {
          name: 'Portfolio',
          data: Object.values(sortedPortData),
        },
      ];
      if (showBenchmark) {
        result.push({
          name: 'Benchmark',
          data: Object.values(sortedbenchData),
        });
      }
    }

    setSectorWeightData(result);
  };

  // const getSectorData = () => {
  //   const sectorData  = getLineChartData(portfolioEmissionEasyRead?.data, inferenceTypeValue, CATEGORIES_MAP);
  //   const contriButionData = getLineChartData(portfolioEmissionEasyRead?.data, inferenceTypeValue, CATEGORIES_MAP);
  //   const sectorWeightData = getLineChartData(portfolioEmissionEasyRead?.data, inferenceTypeValue, CATEGORIES_MAP);
  // };

  useEffect(() => {
    if (sectorData) {
      getSectorIntensity();
      getSectorContribution();
      getSectorWeight();
    }
  }, [sectorData, inferenceType, emission]);

  return (
    <CarbonEmissionsWithLoading
      error={error}
      categories={categories}
      horizontalBarData_1={sectorIntensityData}
      horizontalBarData_2={contribData}
      horizontalBarData_3={sectorWeightData}
      yAxisTitle={yAxisTitle}
      loading={isFetching}
    />
  );
};

export default CarbonEmission;
