/* eslint-disable no-useless-escape */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import {
  Grid,
  Card,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
// import {
// updateCurrency,
// getFixRate,
// getUserInfo,
// updateUserInfo,
// } from '../../services/auth';
import manual from '../../assets/User Manual.pdf';
import colorSchema from '../../Themes/config';
import Currency from '../../models/settings/currency';
import Theme from '../../models/settings/theme';
import UserSessionHandler from '../../handler';
// import CoverageCheck from './Cards/CoverageCheck';
// import PrivateInference from './Cards/PrivateInference';
import DeletePortfolio from '../../components/DeletePortfolio';
import { useUpdateUserInfoMutation } from '../../redux/apiHooks';
import apiSlice from '../../redux/apiSlice';
import useGetTheme from '../../hooks/useGetTheme';

const quarterOptions = ['Q1', 'Q2', 'Q3', 'Q4'];

const useStyles = makeStyles(() => ({
  card: {
    padding: 10,
  },
  cardTitle: {
    marginLeft: 10,
    marginBottom: 20,
    borderBottom: '1px solid rgb(180,180,180)',
    paddingBottom: 10,
  },
  headingText: {
    color: 'black',
    fontFamily: 'Helvetica',
    fontWeight: 'bold',
    padding: 10,
  },
  passwordInput: {
    border: 'none !important',
  },
  contentText: {
    color: 'black',
    fontFamily: 'Helvetica',
    fontStyle: 'italic',
    padding: 10,
  },
  btn: {
    width: '100%',
    height: 40,
    fontSize: 11,
  },
  labelText: {
    color: 'black',
    fontFamily: 'Helvetica',
    padding: 13,
    fontSize: 16,
  },
  dropdown: {
    width: 200,
    height: 40,
  },
}));
const Settings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const themeInUrl = UserSessionHandler.getSFCookie();
  const [updateUserInfo] = useUpdateUserInfoMutation();

  const auth = useSelector((state) => state.auth);
  // const portfolioTableRes = useSelector((state) => state.auth.portfolioTableRes);

  const { userInfo } = auth;
  // const currentUser = auth && auth.currentUser ? auth.currentUser : {};
  const yearOptions = userInfo?.allowed_years;
  const currenyConfigs = userInfo?.currency_config;
  const currencyYears = Object.keys(currenyConfigs || {});
  const isTrial = userInfo?.trial;

  const currentYear = userInfo?.year?.currency;
  const currentCurrency = get(userInfo, 'display_currency', 'USD');
  const currentQuarter = userInfo?.quarter?.currency;
  const [year, setYear] = useState(currentYear);

  const currencyQuarters = currenyConfigs && currenyConfigs[year];

  const [quarter, setQuarter] = useState(currentQuarter);
  const [currency, setCurrency] = useState(currentCurrency);
  const [showDeletePortfolioDiag, setShowDeletePortfolioDiag] = useState(false);

  const [emissionYear, setEmissionYear] = useState(
    get(userInfo?.year, 'emissions', '2019'),
  );
  const [emissionQuarter, setEmissionQuarter] = useState(
    get(userInfo?.quarter, 'emissions', 'Q1'),
  );
  const [emissionVersion, setEmissionVersion] = useState(
    get(userInfo?.version, 'emissions', 11),
  );
  const [fundamentalYear, setFundamentalYear] = useState(
    get(userInfo?.year, 'fundamentals', '2019'),
  );
  const [fundamentalQuarter, setFundamentalQuarter] = useState(
    get(userInfo.quarter, 'fundamentals', 'Q1'),
  );
  const [fundamentalVersion, setFundamentalVersion] = useState(
    get(userInfo.version, 'fundamentals', '11'),
  );
  const targetYear = get(userInfo?.year, 'targets', '2020');
  const targetQuarter = userInfo?.quarter?.targets !== undefined
    ? userInfo?.quarter?.targets
    : userInfo?.quarter?.emissions;
  const context = useGetTheme();
  const [currentTheme, setCurrentTheme] = useState(context.currentTheme);
  // const user = new User(userInfo);
  // const reData = user.toJsonObject();

  const updateCurrencyHandler = async () => {
    const currencyModel = new Currency(
      userInfo,
      emissionYear,
      fundamentalYear,
      year,
      emissionQuarter,
      fundamentalQuarter,
      quarter,
      emissionVersion,
      fundamentalVersion,
      currentTheme,
      targetYear,
      targetQuarter,
      currency,
    );
    const data = currencyModel.toJsonObject();
    dispatch(apiSlice.util.resetApiState());
    updateUserInfo(data);
  };

  const handleUserInfoChanges = async () => {
    const themeModel = new Theme(
      userInfo,
      emissionYear,
      fundamentalYear,
      year,
      emissionQuarter,
      fundamentalQuarter,
      quarter,
      emissionVersion,
      fundamentalVersion,
      currentTheme,
      targetYear,
      targetQuarter,
      currency,
    );
    const data = themeModel.toJsonObject();
    updateUserInfo(data);
  };

  const allThemes = Object.keys(colorSchema);

  const handleThemeChange = async (theme) => {
    // localStorage.setItem("appTheme", theme);

    const themeModel = new Theme(
      userInfo,
      emissionYear,
      fundamentalYear,
      year,
      emissionQuarter,
      fundamentalQuarter,
      quarter,
      emissionVersion,
      fundamentalVersion,
      theme,
    );
    const data = themeModel.toJsonObject();
    updateUserInfo(data);
    setCurrentTheme(theme);
    // context.setTheme(theme);
    // handleUserInfoChanges();
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <Typography
              variant="h5"
              align="left"
              color="textPrimary"
              className={classes.cardTitle}
            >
              My Profile
            </Typography>
            <Grid container>
              <Grid item xs={4}>
                <Typography className={classes.headingText}>Email :</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.contentText}>
                  {userInfo?.email || ''}
                </Typography>
              </Grid>
            </Grid>
            {!isTrial && (
              <Grid container>
                <Grid item xs={4}>
                  <Typography className={classes.headingText}>
                    User Manual:
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.contentText}>
                  <a
                    href={manual}
                    style={{ color: colorSchema[currentTheme].primary }}
                  >
                    User Manual
                  </a>
                </Grid>
              </Grid>
            )}

            {!themeInUrl && (
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography className={classes.headingText}>
                    Select Theme :
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined">
                    <Select
                      placeholder="Select Theme"
                      value={currentTheme}
                      className={classes.dropdown}
                      onChange={(e) => {
                        handleThemeChange(e.target.value);
                      }}
                    >
                      {allThemes.map((theme, index) => (
                        <MenuItem value={theme} key={index}>
                          {theme}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              style={{
                marginBottom: 20,
                borderBottom: '1px solid rgb(180,180,180)',
              }}
            >
              <Typography
                variant="h5"
                align="left"
                color="textPrimary"
                style={{ marginBotom: 20, marginLeft: 10 }}
              >
                Currency Settings
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginBottom: 10 }}
                onClick={updateCurrencyHandler}
              >
                Update
              </Button>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography className={classes.labelText}>
                  Select Year :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined">
                  <Select
                    placeholder="Select year"
                    value={year}
                    className={classes.dropdown}
                    onChange={(e) => {
                      setYear(e.target.value);
                    }}
                  >
                    {currencyYears.map((year) => (
                      <MenuItem value={year} key={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography className={classes.labelText}>
                  Select Quarter :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined">
                  <Select
                    placeholder="Select quarter"
                    value={quarter}
                    className={classes.dropdown}
                    onChange={(e) => {
                      setQuarter(e.target.value);
                    }}
                  >
                    {currencyQuarters
                      && currencyQuarters.map((quarter) => (
                        <MenuItem value={quarter} key={quarter}>
                          {quarter}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography className={classes.labelText}>
                  Select Currency:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined">
                  <Select
                    placeholder="Select currency"
                    value={currency}
                    className={classes.dropdown}
                    onChange={(e) => {
                      setCurrency(e.target.value);
                    }}
                  >
                    <MenuItem value="USD">USD ($)</MenuItem>
                    <MenuItem value="EUR">EUR (€)</MenuItem>
                    <MenuItem value="GBP">GBP (£)</MenuItem>
                    <MenuItem value="AUD">AUD ($)</MenuItem>
                    <MenuItem value="NZD">NZ ($)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              style={{
                marginBottom: 20,
                borderBottom: '1px solid rgb(180,180,180)',
              }}
            >
              <Typography
                variant="h5"
                align="left"
                color="textPrimary"
                style={{ marginBotom: 20, marginLeft: 10 }}
              >
                Emission Settings
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginBottom: 10 }}
                onClick={handleUserInfoChanges}
              >
                Update
              </Button>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography className={classes.labelText}>
                  Select Year :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined">
                  <Select
                    placeholder="Select year"
                    value={emissionYear}
                    className={classes.dropdown}
                    onChange={(e) => {
                      setEmissionYear(e.target.value);
                    }}
                    disabled
                  >
                    {yearOptions
                      && yearOptions.map((year) => (
                        <MenuItem value={year} key={year}>
                          {year}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography className={classes.labelText}>
                  Select Quarter :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined">
                  <Select
                    placeholder="Select quarter"
                    value={emissionQuarter}
                    className={classes.dropdown}
                    onChange={(e) => {
                      setEmissionQuarter(e.target.value);
                    }}
                    disabled
                  >
                    {quarterOptions
                      && quarterOptions.map((quarter) => (
                        <MenuItem value={quarter} key={quarter}>
                          {quarter}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography className={classes.labelText}>
                  Select Version :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined">
                  <Select
                    placeholder="Select quarter"
                    value={emissionVersion}
                    className={classes.dropdown}
                    onChange={(e) => {
                      setEmissionVersion(e.target.value);
                    }}
                    disabled
                  >
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              style={{
                marginBottom: 20,
                borderBottom: '1px solid rgb(180,180,180)',
              }}
            >
              <Typography
                variant="h5"
                align="left"
                color="textPrimary"
                style={{ marginBotom: 20, marginLeft: 10 }}
              >
                Fundamental Settings
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginBottom: 10 }}
                onClick={handleUserInfoChanges}
              >
                Update
              </Button>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography className={classes.labelText}>
                  Select Year :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined">
                  <Select
                    placeholder="Select year"
                    value={fundamentalYear}
                    className={classes.dropdown}
                    onChange={(e) => {
                      setFundamentalYear(e.target.value);
                    }}
                    disabled
                  >
                    {yearOptions
                      && yearOptions.map((year) => (
                        <MenuItem value={year} key={year}>
                          {year}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography className={classes.labelText}>
                  Select Quarter :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined">
                  <Select
                    placeholder="Select quarter"
                    value={fundamentalQuarter}
                    className={classes.dropdown}
                    onChange={(e) => {
                      setFundamentalQuarter(e.target.value);
                    }}
                    disabled
                  >
                    {quarterOptions
                      && quarterOptions.map((quarter) => (
                        <MenuItem value={quarter} key={quarter}>
                          {quarter}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography className={classes.labelText}>
                  Select Version :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined">
                  <Select
                    placeholder="Select quarter"
                    value={emissionVersion}
                    className={classes.dropdown}
                    onChange={(e) => {
                      setFundamentalVersion(e.target.value);
                    }}
                    disabled
                  >
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {/* <Grid item xs={5}>
          <CoverageCheck classes={classes} />
        </Grid>
        <Grid item xs={5}>
          <PrivateInference classes={classes} />
        </Grid> */}
      </Grid>
      <DeletePortfolio
        dialog={showDeletePortfolioDiag}
        handleClose={() => setShowDeletePortfolioDiag(false)}
        isMultiselect={true}
      />
    </Box>
  );
};

export default Settings;
