import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import PieChart from '../../../components/Charts/PieChart';

const ContributionAnalysisDisplay = ({ investmentData, contribData }) => (
  <React.Fragment>
    <Grid container>
      <Grid item xs={6}>
        <PieChart title="Investments" data={investmentData} />
      </Grid>
      <Grid item xs={6}>
        <PieChart title="Contributions" data={contribData} />
      </Grid>
    </Grid>
  </React.Fragment>
);

ContributionAnalysisDisplay.propTypes = {
  investmentData: PropTypes.array.isRequired,
  contribData: PropTypes.array.isRequired,
};

export default ContributionAnalysisDisplay;
