import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import LineAndScatterChart from '../../../components/Charts/LineAndScatterChart';

const AlignmentDisplay = ({
  lineChartData,
  handleDeselect,
  isDeselect,
  chartKey,
}) => (
  <React.Fragment>
    <LineAndScatterChart
      data={lineChartData}
      chartKey={chartKey}
      title="Funds Alignment"
      xAxisType="datetime"
      yAxisTitle="Global Emissions (%)"
    />
    <Button onClick={handleDeselect}>
      {isDeselect ? 'Select All' : '(De)select All'}
    </Button>
  </React.Fragment>
);

AlignmentDisplay.propTypes = {
  lineChartData: PropTypes.array.isRequired,
  isDeselect: PropTypes.bool.isRequired,
  handleDeselect: PropTypes.func.isRequired,
  chartKey: PropTypes.string.isRequired,
};

export default AlignmentDisplay;
