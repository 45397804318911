import React, { useState, useEffect, useCallback } from 'react';
import { isEmpty } from 'lodash';
import {
  portfolioEmissionCategories,
  portfolioEmissionCategoriesMap,
} from '../constants';
import PortfolioEmissionsDisplay from './PortfolioEmissionsDisplay';
import { LoadingHOC } from '../../../util/LoadingHOC';
import useGetPayload from '../../../hooks/useGetPayload';
import SovereignPortfolioEmissionsModel from '../../../models/sovereign/portfolioEmissions';
import { useGetSovereignPortfolioTotalQuery } from '../../../redux/apiHooks';
import { emissionsCells } from '../../../util/TableHeadConfig';

const PortfolioEmissionsWithLoading = LoadingHOC(PortfolioEmissionsDisplay);

const PortfolioEmissions = () => {
  const [chartData, setChartData] = useState([]);
  const [yAxisTitle, setYAxisTitle] = useState('');
  const [tableData, setTableData] = useState([]);

  const { auth, payload } = useGetPayload(SovereignPortfolioEmissionsModel);
  const { data, isFetching, error } = useGetSovereignPortfolioTotalQuery(payload);

  const { showBenchmark } = auth;

  const getEmissionChartData = useCallback(() => {
    const emissionChartData = [
      {
        name: 'Portfolio',
        data: [],
      },
    ];

    if (showBenchmark) {
      emissionChartData.push({
        name: 'Benchmark',
        data: [],
      });
    }

    if (!isEmpty(data)) {
      const title = data ? data?.metadata?.unit : '';
      const emissionsData = data || {};
      const portData = emissionsData?.data?.portfolio;
      if (portData) {
        Object.keys(portfolioEmissionCategoriesMap).forEach((key) => {
          emissionChartData[0].data.push(portData[key]);
        });
      }
      const benchData = emissionsData?.data?.benchmark;
      if (benchData && showBenchmark) {
        Object.keys(portfolioEmissionCategoriesMap).forEach((key) => {
          emissionChartData[1].data.push(benchData[key]);
        });
      }
      setChartData(emissionChartData);
      setYAxisTitle(title);
    }
  }, [data]);

  const getTableData = () => {
    const emissionsData = data || {};
    const emissionTableData = [
      {
        name: 'Portfolio',
      },
    ];

    if (showBenchmark) {
      emissionTableData.push({
        name: 'Benchmark',
      });
    }
    if (!isEmpty(emissionsData)) {
      const portData = emissionsData.data?.portfolio || {};

      if (portData) {
        Object.keys(portData).forEach((key) => {
          emissionTableData[0][key] = portData[key];
        });
      }
      if (showBenchmark) {
        const benchData = emissionsData?.data?.benchmark || {};
        if (benchData) {
          Object.keys(benchData).forEach((key) => {
            emissionTableData[1][key] = benchData[key];
          });
        }
      }
      setTableData(emissionTableData);
    }
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      getTableData();
      getEmissionChartData();
    }
  }, [data, getEmissionChartData]);
  return (
    <PortfolioEmissionsWithLoading
      error={error}
      loading={isFetching}
      categories={portfolioEmissionCategories}
      chartData={chartData}
      yAxisTitle={yAxisTitle}
      text="The portfolio level bar chart shows the weighted intensity breakdown for each emission scope."
      tableData={tableData}
      tableColumns={emissionsCells(yAxisTitle)}
      tableHeading="PORTFOLIO_INTENSITY"
    />
  );
};

export default PortfolioEmissions;
