/* eslint-disable react/prop-types */

import React from 'react';
import ColumnChart from '../../../components/Charts/ColumnChart';
import StackedBar from '../../../components/Charts/StackedBar';

const DisclosureDisplay = (props) => (
  <React.Fragment>
    <StackedBar
      categories={props.stackedBarCategories}
      data={props.stackedBarData}
      title="Scope 1+2 Carbon Emissions Disclosure"
      yAxisTitle="%"
      max={100}
    />
    <ColumnChart
      categories={props.columnChartCategories}
      data={props.columnChartData}
      title="Scope 3 Carbon Emissions Disclosure"
      yAxisTitle="Percentage Disclosed (%)"
      xAxisTitle="Number of Scope 3 Categories Disclosed"
    />
    <div
      style={{
        fontSize: 14,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      }}
    >
      {props.text}
    </div>
  </React.Fragment>
);

export default DisclosureDisplay;
