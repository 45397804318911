export default class TempMetricArray {
  constructor(props) {
    const { currentPortfolio, userInfo } = props;
    this.portfolio_id = currentPortfolio.value;
    this.version_portfolio = currentPortfolio.version;
    this.default_score = '3.20';

    this.scope = ['Sc12', 'Sc123', 'Sc3'];
    this.year_targets = userInfo.year.emissions;
  }

  toJsonObject() {
    return { ...this };
  }
}
