import React from 'react';
import PropTypes from 'prop-types';
import LineChart from '../../Charts/LineChart';

function ReportLineChart(props) {
  return (
    <LineChart
      height={250}
      theme={'ICE light'}
      isExportable={false}
      showLegend={true}
      fontSize={7}
      {...props}
    />
  );
}

ReportLineChart.propTypes = {
  data: PropTypes.array,
  yAxisTitle: PropTypes.string,
  xAxisTitle: PropTypes.string,
  emissionYear: PropTypes.number,
};

export default ReportLineChart;
