import React from 'react';
import { utils, write } from 'xlsx';
import countries from './util/countries';
import * as constants from './components/FilterSection/constants';
import { CircleBullet } from './components/OnTrackWithTargets';
import { formatNumber } from './util/formatNumber';

export const sortByValue = (arr, key) => {
  const sortedData = typeof key === 'string'
    ? arr.sort((a, b) => (a[key].toLowerCase() > b[key].toLowerCase() ? 1 : -1))
    : arr.sort((a, b) => b[key] - a[key]);
  return sortedData;
};

export const getCountriesByCode = (countryCodes) => countries.filter(
  (country) => country?.value && countryCodes.includes(country.value),
);

export const getSortedData = (data) => Object.entries(data)
  .sort()
  .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

export const getLineChartData = (response, nameObj) => {
  const { data, metadata } = response;
  const lineChartData = Object.entries(data).map(([key, value]) => ({
    name: nameObj[key],
    data: Object.keys(value).map((year) => [
      Date.UTC(year, '01', '01'),
      value[year],
    ]),
  }));

  return {
    chartData: lineChartData,
    title: metadata.title,
    unit: metadata.units,
  };
};

export const getScenarioValue = (currentSc) => {
  const scenarios = [
    { key: '1', value: { number: 1, engagement_type: 1 } },
    { key: '2', value: { number: 2, engagement_type: 1 } },
    { key: '3', value: { number: 3, engagement_type: 1 } },
    { key: '4', value: { number: 3, engagement_type: 2 } },
  ];
  const res = scenarios.find((scenario) => scenario.key === currentSc.toString())?.value
    || null;
  return res;
};

export const getCategoryKeyForScope3 = (category, emission) => {
  if (emission === 'Sc123') {
    switch (category) {
      case constants.SCOPE_12_TOTAL:
        return 0;
      case constants.PURCHASED_GOODS_AND_SERVICES:
        return 1;
      case constants.CAPITAL_GOODS:
        return 2;
      case constants.FUEL_AND_ENERGY_RELATED_ACTIVITIES:
        return 3;
      case constants.UPSTREAM_TRANSPORT_AND_DISTRIBUTION:
        return 4;
      case constants.WASTE_GENERATED:
        return 5;
      case constants.BUSINESS_TRAVEL:
        return 6;
      case constants.EMPLOYEE_COMMUTING:
        return 7;
      case constants.UPSTREAM_LEASED_ASSETS:
        return 8;
      case constants.DOWNSTREAM_TRANSPORT_DISTRIBUTION:
        return 9;
      case constants.PROCESSING_OF_SOLD_PRODUCTS:
        return 10;
      case constants.USE_OF_SOLD_PRODUCTS:
        return 11;
      case constants.END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS:
        return 12;
      case constants.DOWNSTREAM_LEASED_ASSETS:
        return 13;
      case constants.FRANCHISES:
        return 14;
      case constants.INVESTMENTS:
        return 15;
      default:
        return 'N/A';
    }
  } else {
    switch (category) {
      case constants.PURCHASED_GOODS_AND_SERVICES:
        return 0;
      case constants.CAPITAL_GOODS:
        return 1;
      case constants.FUEL_AND_ENERGY_RELATED_ACTIVITIES:
        return 2;
      case constants.UPSTREAM_TRANSPORT_AND_DISTRIBUTION:
        return 3;
      case constants.WASTE_GENERATED:
        return 4;
      case constants.BUSINESS_TRAVEL:
        return 5;
      case constants.EMPLOYEE_COMMUTING:
        return 6;
      case constants.UPSTREAM_LEASED_ASSETS:
        return 7;
      case constants.DOWNSTREAM_TRANSPORT_DISTRIBUTION:
        return 8;
      case constants.PROCESSING_OF_SOLD_PRODUCTS:
        return 9;
      case constants.USE_OF_SOLD_PRODUCTS:
        return 10;
      case constants.END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS:
        return 11;
      case constants.DOWNSTREAM_LEASED_ASSETS:
        return 12;
      case constants.FRANCHISES:
        return 13;
      case constants.INVESTMENTS:
        return 14;
      default:
        return 'N/A';
    }
  }
};

export const getArrayByObject = (data) => Object.entries(data).map(([key]) => ({
  label: key,
  value: key,
}));

export const convertToXlsx = (data) => {
  if (Array.isArray(data)) {
    data = { Sheet1: data };
  }
  const workbook = utils.book_new();
  Object.keys(data).forEach((key) => {
    const sheet = utils.json_to_sheet(data[key]);
    utils.book_append_sheet(workbook, sheet, key);
  });

  // Generate a binary string from the workbook
  const excelBuffer = write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  });

  // Convert the array buffer to a Blob and create a URL
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = URL.createObjectURL(blob);

  return url;
};

export const convertArrayOfObjectsToCSV = (array) => {
  let result;

  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = array.length > 0 ? Object.keys(array[0]) : [];
  const columns = Object.keys(array[0]).map((string) => (string === null ? '' : `"${string}"`));

  result = '';
  result += columns.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += `"${item[key]}"`;

      ctr += 1;
    });
    result += lineDelimiter;
  });

  return result;
};

export const initiateFileDownload = (url, filename) => {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.click();
};

export const downloadCSV = (array, filename = 'export.csv') => {
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv === null) return;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }
  initiateFileDownload(encodeURI(csv), filename);
};

/**
 * Function to download an Excel file from given data.
 * @param {Object|Array} data - The data to be exported to Excel. It can be either an object with multiple sheets or a single array for one sheet.
 * @param {string} filename - The name of the Excel file to be downloaded. (Default: 'export.xlsx')
 * */
export const downloadExcel = (data, filename = 'export.xlsx') => {
  const excelUrl = convertToXlsx(data);
  initiateFileDownload(excelUrl, filename);
};

const arithmaticArray = [
  { value: 'gt', label: '>' },
  { value: 'lt', label: '<' },
  { value: 'eq', label: '==' },
  { value: 'gte', label: '>=' },
  { value: 'lte', label: '<=' },
  { value: 'range', label: 'Range' },
];

const assetTypeArray = [
  { value: 'Equity', label: 'Equity' },
  { value: 'Corporate Bond', label: 'Corporate Bond' },
  { value: 'Loan', label: 'Loan' },
];

const target = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'Sbti Approved', label: 'SBTi Targets Only' },
];

const marketCap = [
  { value: 'small-cap', label: 'Small Cap' },
  { value: 'mid-cap', label: 'Mid Cap' },
  { value: 'large-cap', label: 'Large Cap' },
  { value: 'mega-cap', label: 'Mega Cap' },
];

const disclosureCat = [
  { value: 1, label: 'Category 1' },
  { value: 2, label: 'Category 2' },
  { value: 3, label: 'Category 3' },
  { value: 4, label: 'Category 4' },
  { value: 5, label: 'Category 5' },
];

export const sectorMap = {
  ice_sector: 'sector_count',
};

export const getArrayMap = (type, metaData) => {
  switch (type) {
    case 'country':
    case 'region':
    case 'sector_count':
      return getArrayByObject(metaData[type] || []);
    case 'asset_type':
    case 'security_type':
      return assetTypeArray;
    case 'targets':
      return target;
    case 'market_cap':
      return marketCap;
    case 'disclosure_sc12':
      return disclosureCat;
    default:
      return arithmaticArray;
  }
};

export const getFilterkey = (type) => {
  switch (type) {
    case 'targets':
    case 'market_cap':
      return 'value';
    default:
      return 'condition';
  }
};
export const getDateFromYear = (year) => Date.UTC(year, '00', '01');

// { 2020: 11, 2021: 12 } ==> [ {2020, 11 }, { 2021, 12} ]
export const getChartDataFromObject = (data) => (data
  ? Object.entries(data).map(([year, value]) => [
    getDateFromYear(year),
    value,
  ])
  : []);

export const groupArrayBykey = (arr, key) => arr.reduce((acc, cur) => {
  if (!acc[cur[key]]) {
    acc[cur[key]] = [];
  }
  acc[cur[key]].push(cur);
  return acc;
}, {});

const disclosure_colors = {
  1: 'green',
  2: 'green',
  3: 'orange',
  4: 'red',
  5: 'red',
};

// Default Case: is used for Disclousre Quality Score and Target Coverage Score
export const getTransitionScoreMetricColor = (metric, keyName) => {
  const numberMetric = Number(metric);
  const color = {
    historic_performace: () => (numberMetric < 0 ? 'green' : numberMetric > 10 ? 'red' : 'orange'),
    itr_score: () => (numberMetric <= 1.5 ? 'green' : numberMetric > 2.0 ? 'red' : 'orange'),
    disclosure: () => disclosure_colors[numberMetric],
    default: () => (numberMetric > 50 ? 'green' : numberMetric < 20 ? 'red' : 'orange'),
  };
  return metric || metric === 0 ? (color[keyName] || color.default)() : null;
};

export const getTargetCoverageLabelOrColor = (
  target_coverage,
  has_sbti_approved_target,
  isColor = false,
) => {
  if (isColor) {
    if (target_coverage && has_sbti_approved_target) return 'green';
    if (target_coverage && !has_sbti_approved_target) return 'orange';
    if (!target_coverage && !has_sbti_approved_target) return 'red';
  } else {
    if (target_coverage && has_sbti_approved_target) return 'Yes (SBTi)';
    if (target_coverage && !has_sbti_approved_target) return 'Yes (non-SBTi)';
    if (!target_coverage && !has_sbti_approved_target) return 'No targets';
  }
  return null;
};

export const isEmptyValue = (value) => {
  if (value || value === 0) return false;
  return true;
};

export const getCellValue = ({
  value,
  color,
  showColor = false,
  emptyValue = '-',
  isNumber = false,
  showNotation = false,
}) => {
  if (isEmptyValue(value)) return emptyValue;
  if (showColor) {
    return <CircleBullet backgroundColor={color} isTooltip={false} />;
  }
  if (isNumber) return formatNumber(value, showNotation);
  return value;
};

export const scoreTypeMap = {
  shortTerm: 'short',
  longTerm: 'long',
  midTerm: 'mid',
};
export const displayNumber = (num) => parseFloat(num.toFixed(2));

export const getEmission = (type) => type.toLowerCase();

export const objectToQueryString = (obj, addQuestionMark = true) => {
  const queryString = Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');

  if (addQuestionMark) return `?${queryString}`;
  return queryString;
};
