import apiSlice, { getQuery, RTK_TAGS } from '../apiSlice';
import {
  getDownloadPortfoliosSuccess,
  getFundsPortfolioListSuccess,
  getPortfolioListSuccess,
  getUploadPortfolioListSuccess,
  setLoading,
} from './authReducer';

export const userDataSliceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserPortfolioList: builder.query({
      query: getQuery.get('/portfolio'),
      transformResponse: (response) => response.map((portfolio) => ({
        label: portfolio.name,
        value: portfolio.portfolio_id,
        version: portfolio.version,
      })),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(getPortfolioListSuccess(data));
      },
      providesTags: [RTK_TAGS.PORTFOLIO_LIST],
    }),
    getUserFundsPortfolioList: builder.query({
      query: getQuery.get('/portfolio/?portfolio_type=PC&is_full=true'),
      transformErrorResponse: ({ data }) => data?.message,
      transformResponse: (response) => ({
        allPortfolios: response,
        fundsPortList: response
          .filter((portfolio) => portfolio.is_parent)
          .map((portfolio) => ({
            label: portfolio.name,
            value: portfolio.portfolio_id,
            version: portfolio.version,
          })),
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(getFundsPortfolioListSuccess(data));
      },
      providesTags: [RTK_TAGS.PORTFOLIO_LIST],
    }),
    getUserUploadPortfolioList: builder.query({
      query: getQuery.get('portfolio/?is_full=True&short=False'),
      transformErrorResponse: ({ data }) => data?.message,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        dispatch(setLoading(true));
        try {
          const { data } = await queryFulfilled;
          dispatch(getUploadPortfolioListSuccess(data));
        } finally {
          dispatch(setLoading(false));
        }
      },
      providesTags: [RTK_TAGS.PORTFOLIO_LIST],
    }),
    getUserDownloadPortfolios: builder.query({
      query: getQuery.get('portfolio/?is_benchmark=False&short=False'),
      transformErrorResponse: ({ data }) => data?.message,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(getDownloadPortfoliosSuccess(data));
      },
      providesTags: [RTK_TAGS.PORTFOLIO_LIST],
    }),
    getUserDeletePortfolios: builder.mutation({
      query: getQuery.delete('portfolio/?portfolio_ids='),
      transformErrorResponse: ({ data }) => data?.message,
      invalidatesTags: [RTK_TAGS.PORTFOLIO_LIST],
    }),
    uploadPortfolioRequest: builder.mutation({
      query: (data) => {
        const { body, isFund } = data;
        return {
          url: `upload/?type_upload=${isFund}`,
          method: 'POST',
          body,
        };
      },
      transformErrorResponse: ({ data }) => data?.message,
      invalidatesTags: [RTK_TAGS.PORTFOLIO_LIST],
    }),
    uploadCoverageCheck: builder.mutation({
      query: getQuery.post('/coverage_check'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    uploadPrivateInference: builder.mutation({
      query: getQuery.post('/private_inference'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getCoverageDetails: builder.mutation({
      query: (data) => {
        const { body, portfolioId } = data;
        return {
          url: `/portfolio/coverage/${portfolioId}/`,
          method: 'POST',
          body,
          responseHandler: (response) => response.text(),
        };
      },
      transformErrorResponse: ({ data }) => data?.message,
    }),
    coverageMissingIsins: builder.mutation({
      query: ({ portfolioId, body }) => ({
        url: `/portfolio/coverage_missing_isins/${portfolioId}`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    coverageS3Upload: builder.mutation({
      query: ({ portfolioId, body }) => ({
        url: `/portfolio/coverage_s3_upload/${portfolioId}`,
        method: 'POST',
        body,
        responseHandler: (response) => response.text(),
      }),
      transformErrorResponse: ({ data }) => {
        const jsonData = JSON.parse(data);
        return jsonData?.message;
      },
    }),
    getPortCompanyTransitionScore: builder.query({
      query: getQuery.get('portfolio/company_transition_scores?portfolio_id='),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    validateUniqueIsins: builder.mutation({
      query: getQuery.post('/validate_unique_isins/'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getPrivateInference: builder.query({
      query: getQuery.get('/private_inference/reports'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
  }),
});

export const {
  useGetUserDownloadPortfoliosQuery,
  useGetUserFundsPortfolioListQuery,
  useGetUserPortfolioListQuery,
  useGetUserUploadPortfolioListQuery,
  useLazyGetUserDownloadPortfoliosQuery,
  useLazyGetUserFundsPortfolioListQuery,
  useLazyGetUserPortfolioListQuery,
  useLazyGetUserUploadPortfolioListQuery,
  useUploadCoverageCheckMutation,
  useUploadPrivateInferenceMutation,
  useGetCoverageDetailsMutation,
  useGetUserDeletePortfoliosMutation,
  useUploadPortfolioRequestMutation,
  useCoverageMissingIsinsMutation,
  useCoverageS3UploadMutation,
  useGetPortCompanyTransitionScoreQuery,
  useLazyGetPortCompanyTransitionScoreQuery,
  useValidateUniqueIsinsMutation,
  useGetPrivateInferenceQuery,
  useLazyGetPrivateInferenceQuery,
} = userDataSliceApi;
