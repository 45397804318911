import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../../components/Table/DataTable';

const PortfolioViewDisplay = ({
  dataTableData,
  columns,
  tableHeading,
  isTrial,
}) => (
  <DataTable
    data={dataTableData}
    columns={columns}
    tableHeading={tableHeading}
    isTrial={isTrial}
    paginationRowsPerPageOptions={[20, 40, 100]}
    paginationPerPage={20}
    isExportEnabled={false}
  />
);

PortfolioViewDisplay.propTypes = {
  dataTableData: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  tableHeading: PropTypes.string.isRequired,
  isTrial: PropTypes.bool.isRequired,
};

export default PortfolioViewDisplay;
