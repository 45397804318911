import BaseRequest from '../baseRequest';

export default class SectoralScore extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem } = props;
    const { aggregation, sector, defaultValue } = filterItem;

    this.aggregation_method = aggregation;
    this.default_score = defaultValue;
    this.sector_classification = sector;
  }
}
