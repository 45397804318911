import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from '../Table/DataTable';
import { useGetUserDeletePortfoliosMutation } from '../../redux/apiHooks';

const headCells = [
  {
    name: 'Portfolio Name',
    selector: 'name',
    sortable: true,
    right: false,
    wrap: true,
  },
  {
    name: 'Emission Coverage (%)',
    selector: 'coverageEmissions',
    sortable: true,
    right: true,
    wrap: true,
  },
  {
    name: 'Version',
    selector: 'version',
    sortable: true,
    right: true,
    wrap: true,
  },
  {
    name: 'Processing Date',
    selector: 'date_created',
    sortable: true,
    right: true,
    wrap: true,
    cell: (row) => (
      <div style={{ marginLeft: 40 }}>
        {row.date_created
          && moment(row.date_created).format('DD-MM-YYYY hh:mm:ss')}
      </div>
    ),
  },
];

const DeletePortfolio = ({ dialog, handleClose, isMultiselect = false }) => {
  const [selectedPortfolio, setSelectedPortfolio] = useState([]);
  const portfolioTableRes = useSelector((state) => state.auth.portfolioTableRes);
  const dispatch = useDispatch();
  const [deletePortfolioRequest] = useGetUserDeletePortfoliosMutation();

  const deletePortfolio = async () => {
    if (selectedPortfolio.length === 0) {
      NotificationManager.error('Please select portfolio to be deleted');
      return;
    }
    try {
      await dispatch(deletePortfolioRequest(selectedPortfolio.join()));
      NotificationManager.success('Portfolio deleted successfully');
      handleClose();
      setSelectedPortfolio([]);
      window.location.reload();
    } catch (error) {
      NotificationManager.error('Unable to delete the portfolio !');
    }
  };

  const handleSelectedRowsChange = (selectedPortfolios) => {
    const portIds = [];
    if (selectedPortfolios && selectedPortfolios.length > 0) {
      selectedPortfolios.forEach((portfolio) => {
        portIds.push(portfolio.portfolio_id);
      });
    }
    setSelectedPortfolio(portIds);
  };

  return (
    <Dialog open={dialog} keepMounted onClose={handleClose} maxWidth="sm">
      <DialogTitle>Delete Portfolio</DialogTitle>
      <DialogContent>
        {isMultiselect ? (
          <DataTable
            data={portfolioTableRes}
            columns={headCells}
            tableHeading="UPLOAD_PORTFOLIO"
            isSelectableRows={true}
            handleSelection={handleSelectedRowsChange}
          />
        ) : (
          <RadioGroup
            aria-label="portfolio"
            name="portfolio"
            value={selectedPortfolio[0]}
            onChange={(e) => setSelectedPortfolio([e.target.value])}
          >
            {portfolioTableRes.map((portfolio) => (
              <FormControlLabel
                key={portfolio.portfolio_id}
                value={portfolio.portfolio_id}
                control={<Radio />}
                label={portfolio.name}
              />
            ))}
          </RadioGroup>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={deletePortfolio}>
          Delete
        </Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

DeletePortfolio.propTypes = {
  handleClose: PropTypes.func.isRequired,
  dialog: PropTypes.bool.isRequired,
  isMultiselect: PropTypes.bool,
};

export default DeletePortfolio;
