// /* eslint-disable */
import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import PropType from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import getTheme from './index';
import colorSchema from './config';
// import UserSessionHandler from '../handler';

// eslint-disable-next-line no-unused-vars
// export const CustomThemeContext = React.createContext({
//   currentTheme: 'light',
//   setTheme: null,
// });

// eslint-disable-next-line no-return-assign
const createHighchartTheme = (currentTheme) => (Highcharts.theme = {
  colors: colorSchema[currentTheme].chartColors,
  chart: {
    backgroundColor: colorSchema[currentTheme].mainBackground,
  },
  title: {
    style: {
      color: colorSchema[currentTheme].text,
      font: 'bold 18px "Trebuchet MS", Verdana, sans-serif',
    },
  },
  subtitle: {
    style: {
      color: '#666666',
      fontSize: 18,
      fontWeight: 'bold',
    },
  },
  legend: {
    itemStyle: {
      font: '9pt Trebuchet MS, Verdana, sans-serif',
      color: 'black',
    },
    itemHoverStyle: {
      color: 'gray',
    },
  },
  lang: {
    numericSymbols: null,
    thousandsSep: ',',
  },
});
// eslint-disable-next-line import/prefer-default-export
const CustomThemeProvider = ({ children }) => {
  // Read current theme from localStorage or maybe from an api
  const userInfo = useSelector((state) => state.auth.userInfo);

  // State to hold the selected theme name
  // const [themeName, _setThemeName] = useState(currentTheme);

  const currentTheme = userInfo?.theme || 'light';
  // Retrieve the theme object by theme name
  const theme = getTheme(currentTheme);

  useEffect(() => {
    Highcharts.setOptions(createHighchartTheme(currentTheme));
  }, [currentTheme]);

  // Wrap _setThemeName to store new theme names in localStorage
  // const setThemeName = (name) => {
  //   UserSessionHandler.setAppTheme(name);
  //   _setThemeName(name);
  // };

  // const contextValue = {
  //   currentTheme: themeName,
  //   setTheme: setThemeName,
  // };

  return (
    // <CustomThemeContext.Provider value={contextValue}>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
    // </CustomThemeContext.Provider>
  );
};

CustomThemeProvider.propTypes = {
  children: PropType.node.isRequired,
};

export default CustomThemeProvider;
// const withCustomTheme = (Child) => (props) => (
//   <CustomThemeProvider.Consumer>
//     {(context) => <Child {...props} {...context} />}
//     {/* Another option is:  {context => <Child {...props} context={context}/>} */}
//   </CustomThemeProvider.Consumer>
// );

// export { CustomThemeProvider, withCustomTheme };
