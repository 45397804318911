// import basic from "./default";
// import dark from "./dark";
import common from './common';
/* const themes = {
  basic,
  dark,
}; */

export default function getTheme(theme) {
  // return themes[theme];
  return common(theme);
}
