import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { formatNumber } from '../../util/formatNumber';
import {
  disclosureTable,
  generatePCAF,
  pcafTable,
  pcafTableList,
} from '../../util/pcafHelpers';
import ReactDataTable from '../Table/DataTable';

const PcafTable = ({ assetType, pcafData }) => {
  const [tableData, setTableData] = useState([]);
  const showBenchmark = useSelector((state) => state.auth.showBenchmark);
  const filterItem = useSelector((state) => state.filters);
  const { emission, strategy } = filterItem;

  const generateTable = (i, name, pcaf) => {
    const obj = {};
    let total = 0;
    obj.name = name;
    for (let j = 0; j < 5; j += 1) {
      obj[`column${j + 1}`] = formatNumber(pcaf[j].data[i]);
      total += pcaf[j].data[i];
    }
    obj.total = formatNumber(total);
    return obj;
  };

  const generateTable2 = (pcaf, benchmark) => {
    const table = [];
    if (benchmark) {
      pcafTableList.forEach((key, i) => table.push(generateTable(3 - i, key, pcaf)));
    } else {
      table.push(generateTable(1, pcafTableList[0], pcaf));
      table.push(generateTable(0, pcafTableList[2], pcaf));
    }
    return table;
  };

  useEffect(() => {
    let pcaf = [];
    if (!isEmpty(pcafData)) {
      let table = [];
      pcaf = [
        ...generatePCAF({
          portfolioEmissionData: pcafData.data,
          isPcafScore: strategy === 'pcaf',
          showBenchmark,
          assetType,
        }),
      ];
      table = [...generateTable2(pcaf, showBenchmark)];
      setTableData(table);
    }
  }, [strategy, pcafData, assetType, emission]);

  return (
    <ReactDataTable
      data={tableData}
      columns={strategy === 'pcaf' ? pcafTable : disclosureTable}
      tableHeading="PORTFOLIO_FOOTPRINT"
    />
  );
};

PcafTable.propTypes = {
  assetType: PropTypes.string,
  pcafData: PropTypes.object,
};

export default PcafTable;
