/* eslint-disable react/prop-types */
import React from 'react';
import HorizontalBar from '../../../components/Charts/HorizontalBar';
import DataTable from '../../../components/Table/DataTable';

const PortfolioEmissionDisplay = (props) => (
  <React.Fragment>
    <HorizontalBar
      categories={props.categories}
      data={props.horizontalBarData}
      title="Portfolio Footprint"
      yAxisTitle={props.yAxisTitle}
    />
    <DataTable
      data={props.dataTableData}
      columns={props.columns}
      tableHeading={props.tableHeading}
    />
  </React.Fragment>
);

export default PortfolioEmissionDisplay;
