import React from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import TabPanel from '../../components/TabPanel';
import { footprintTabs2 } from '../../util/tabs-config';
import PortfolioEmission from './PortfolioEmission/PortfolioEmission';
import AssetEmissionsBreakdown from './AssetEmissionsBreakdown/AssetEmissionsBreakdown';
import useTabValue from '../../hooks/useTabValueHook';

function PortfolioFootprint2() {
  const [tabValue, onTabChange] = useTabValue();

  return (
    <>
      <Paper position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={onTabChange}
          indicatorColor="secondary"
        >
          {footprintTabs2
            && footprintTabs2.map((e, i) => (
              <Tab key={i} label={e} style={{ fontSize: 11 }} />
            ))}
        </Tabs>
      </Paper>
      <TabPanel value={tabValue} index={0}>
        <PortfolioEmission />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <AssetEmissionsBreakdown />
      </TabPanel>
    </>
  );
}
export default PortfolioFootprint2;
