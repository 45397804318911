import React, { useState, useEffect } from 'react';
import { some, findIndex, isEmpty } from 'lodash';
import { riskContribCells } from '../../../util/TableHeadConfig';
import RiskContributorDisplay from './RiskContributorDisplay';
import { LoadingHOC } from '../../../util/LoadingHOC';
import { useGetCarbonRiskContributorsQuery } from '../../../redux/apiHooks';
import useGetPayload from '../../../hooks/useGetPayload';
import RiskContributorModel from '../../../models/carbonRisk/riskContributor';

const RiskContributorLoading = LoadingHOC(RiskContributorDisplay);

const RiskContributor = () => {
  const { filters, payload } = useGetPayload(RiskContributorModel);
  const {
    data: riskContribData,
    isFetching,
    error,
  } = useGetCarbonRiskContributorsQuery(payload);
  const { year, intensityScope } = filters;

  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const getChartData = () => {
    const newTableData = [];
    let newChartData = [];
    riskContribData.data.forEach((res) => {
      newTableData.push({
        company: res.Company,
        isin: res.ISIN,
        sasb_sector: res.Sector,
        weight: res.Weight,
        annualized_return:
          year === '1Y'
            ? res.ContributionAnnualised1Y
            : year === '3Y'
              ? res.ContributionAnnualised3Y
              : res.ContributionAnnualised5Y,
        annualized_risk:
          year === '1Y'
            ? res.ContributionRisk1Y
            : year === '3Y'
              ? res.ContributionRisk3Y
              : res.ContributionRisk5Y,
        intensity:
          intensityScope === 'Sc12' ? res.ContribSc12 : res.ContribSc123,
      });
      if (some(newChartData, { name: res.Sector })) {
        const index = findIndex(newChartData, { name: res.Sector });
        const xVal = year === '1Y'
          ? res.ContributionRisk1Y
          : year === '3Y'
            ? res.ContributionRisk3Y
            : res.ContributionRisk5Y;
        const yVal = year === '1Y'
          ? res.ContributionAnnualised1Y
          : year === '3Y'
            ? res.ContributionAnnualised3Y
            : res.ContributionAnnualised5Y;
        const zVal = intensityScope === 'Sc12' ? res.ContribSc12 : res.ContribSc123;
        newChartData[index].data.push({
          x: xVal,
          y: yVal,
          z: zVal,
          company: res.Company,
        });
      } else {
        const xVal = year === '1Y'
          ? res.ContributionRisk1Y
          : year === '3Y'
            ? res.ContributionRisk3Y
            : res.ContributionRisk5Y;
        const yVal = year === '1Y'
          ? res.ContributionAnnualised1Y
          : year === '3Y'
            ? res.ContributionAnnualised3Y
            : res.ContributionAnnualised5Y;
        const zVal = intensityScope === 'Sc12' ? res.ContribSc12 : res.ContribSc123;
        const company = res.Company;
        newChartData = [
          ...newChartData,
          {
            name: res.Sector,
            data: [
              {
                x: xVal,
                y: yVal,
                z: zVal,
                company,
              },
            ],
          },
        ];
      }
    });
    setTableData(newTableData);
    setChartData(newChartData);
  };

  useEffect(() => {
    if (!isEmpty(riskContribData)) {
      getChartData();
    }
  }, [riskContribData]);

  return (
    <RiskContributorLoading
      loading={isFetching}
      error={error}
      chartKey="RISK_CONTRIBUTOR"
      data={chartData}
      xAxisLabel="Contribution to Annualized Risk"
      yAxisLabel="Contribution to Annualized Return"
      zAxisLabel="Contribution to Intensity"
      xAxisTitle={`Contribution to Annualized Risk (${year})`}
      yAxisTitle={`Contribution to Annualized Return (${year})`}
      dataTableData={tableData}
      columns={riskContribCells}
      tableHeading="RISK_CONTRIBUTOR"
    />
  );
};

export default RiskContributor;
