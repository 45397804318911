import NetZeroAnalysis from '../screens/NetZeroAnalysis';
// import ForwardLookingAnalysis from '../screens/ForwardLookingAnalysis';
import PortfolioFootprint from '../screens/PortfolioFootprint';
import PortfolioOptimization from '../screens/PortfolioOptimization';
import Summary from '../screens/Summary';
import PortfolioFootprint2 from '../screens/PortfolioFootprint2';
import PortfolioCarbonRisk from '../screens/PortfolioCarbonRisk';
import SovereignAnalytics from '../screens/SovereignAnalytics';
import Scope3Materiality from '../screens/Scope3Materiality';
import TemperatureMetric from '../screens/TemperatureMetric';
import FundOfFunds from '../screens/FundOfFunds';
import StrandedAssetsAnalysis from '../screens/StrandedAssetsAnalysis';
import UrgentemDownload from '../screens/UrgentemDownload';
import UrgentemApi from '../screens/UrgentemApi';
import PortfolioBuilder from '../screens/PortFolioBuilder';
// import VoluntaryCarbonMarkets from '../screens/VoluntaryCarbonMarkets';
import UrgentemLanding from '../screens/UrgentemLanding';
import Dashboards from '../screens/Dashboards';
import Reports from '../screens/Reports';
// import PrivateInference from '../screens/PrivateInference';
import RemovePortfolios from '../screens/RemovePortfolios';
import Settings from '../screens/Settings';

export const RouteData = [
  {
    name: 'Indicator',
    url: '/portfolio-summary',
    icon: 'TableChartIcon',
    fullName: 'Transition Indicator',
    Component: Summary,
    isPublic: true,
  },
  {
    name: 'Emissions',
    url: '/portfolio-footprint',
    icon: 'GraphicEqIcon',
    fullName: 'Portfolio Emissions',
    extraModuleName: 'portfolio_emissions',
    Component: PortfolioFootprint,
  },
  {
    name: 'PCAF',
    url: '/pcaf',
    icon: 'GraphicEqIcon',
    fullName: 'PCAF',
    extraModuleName: 'portfolio_emissions2',
    Component: PortfolioFootprint2,
  },
  {
    name: 'Sovereign',
    url: '/sovereign-analytics',
    icon: 'Public',
    fullName: 'Sovereign Analytics',
    extraModuleName: 'sovereign',
    Component: SovereignAnalytics,
  },
  {
    name: 'Scope3',
    url: '/scope3-materiality',
    icon: 'VerticalSplitIcon',
    fullName: 'Scope 3 Materiality',
    extraModuleName: 'scope_3_heatmap',
    Component: Scope3Materiality,
  },
  {
    name: 'Optimization',
    url: '/portfolio-optimization',
    icon: 'TimelineIcon',
    fullName: 'Portfolio Optimization',
    extraModuleName: 'optimisation',
    Component: PortfolioOptimization,
  },
  {
    name: 'Carbon risk',
    url: '/portfolio-carbon-risk',
    icon: 'BubbleChartIcon',
    fullName: 'Portfolio Carbon Risk',
    extraModuleName: 'carbon_risk',
    Component: PortfolioCarbonRisk,
  },
  // {
  //   name: 'FLM',
  //   url: '/forward-looking-analysis',
  //   icon: 'ViewWeekIcon',
  //   fullName: 'Forward Looking Analysis',
  //   extraModuleName: 'forward_looking_metrics',
  //   Component: ForwardLookingAnalysis,
  // },
  {
    name: 'Net-zero analysis',
    url: '/net-zero-analysis',
    icon: 'ViewWeekIcon',
    fullName: 'Net-zero analysis',
    extraModuleName: 'net_zero',
    Component: NetZeroAnalysis,
  },
  {
    name: 'Temp score',
    url: '/temperature-metric',
    icon: 'InsertChartIcon',
    fullName: 'Temperature Metric',
    extraModuleName: 'temperature_metric',
    Component: TemperatureMetric,
  },
  {
    name: 'Fund Of Funds',
    url: '/fund-of-funds',
    icon: 'AccountTreeIcon',
    fullName: 'Fund Of Funds',
    extraModuleName: 'fund_of_funds',
    Component: FundOfFunds,
  },
  {
    name: 'Stranded',
    url: '/stranded-assets-analysis',
    icon: 'BarChartIcon',
    fullName: 'Stranded Asset Analysis',
    extraModuleName: 'stranded_assets',
    Component: StrandedAssetsAnalysis,
  },
  {
    name: 'Download',
    url: '/urgentem-download',
    icon: 'GetAppIcon',
    fullName: 'Download',
    extraModuleName: 'download',
    Component: UrgentemDownload,
  },
  {
    name: 'Api',
    url: '/urgentem-api',
    icon: 'CodeIcon',
    fullName: 'API',
    hideOnSidebar: true,
    Component: UrgentemApi,
    hideFilters: true,
  },
  {
    name: 'Reports',
    url: '/reports',
    icon: 'AssignmentReturnedIcon',
    fullName: 'Reports',
    extraModuleName: 'reports',
    Component: Reports,
  },
  {
    name: 'Dashboards',
    url: '/dashboards',
    icon: 'DashboardIcon',
    fullName: 'Dashboards',
    extraModuleName: 'dashboards',
    Component: Dashboards,
    hideFilters: true,
    isPortfolioIndependent: true,
  },
  {
    name: 'Portfolio Screener',
    url: '/portfolio-screener',
    icon: 'FilterAltIcon',
    fullName: 'Portfolio Screener',
    isPublic: true,
    Component: PortfolioBuilder,
  },
  // {
  //   name: 'Carbon Offsets',
  //   url: '/carbon-offset',
  //   icon: 'AssignmentReturnedIcon',
  //   fullName: 'Carbon Offsets',
  //   isPublic: true,
  //   Component: VoluntaryCarbonMarkets,
  // },
  // {
  //   name: 'Private Inference',
  //   url: '/private-inference',
  //   icon: 'AssignmentReturnedIcon',
  //   fullName: 'Private Inference',
  //   hideOnSidebar: true,
  //   Component: PrivateInference,
  //   hideFilters: true,
  // },
  {
    name: 'Settings',
    url: '/settings',
    fullName: 'Settings',
    hideOnSidebar: true,
    Component: Settings,
    hideFilters: true,
  },
  {
    name: 'Remove Portfolios',
    url: '/remove-portfolios',
    icon: 'AssignmentReturnedIcon',
    fullName: 'Remove Portfolios',
    hideOnSidebar: true,
    Component: RemovePortfolios,
    hideFilters: true,
  },
  {
    // Note: Keep at the end
    name: 'Urgentem Dashboard',
    url: '/',
    fullName: 'Urgentem Dashboard',
    hideOnSidebar: true,
    isPublic: true,
    exact: false,
    Component: UrgentemLanding,
    hideFilters: true,
  },
];

export const getModuleNameFromPath = (path) => RouteData.filter((item) => item.url === path)[0]?.name;

export const getShowFiltersPaths = (path) => {
  const noFiltersPaths = RouteData.filter(({ hideFilters }) => hideFilters).map(
    ({ url }) => url,
  );
  if (noFiltersPaths.includes(path)) return false;
  if (RouteData.find(({ url }) => path === url)) return true;
  return false;
};
