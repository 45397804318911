/* eslint-disable import/no-cycle */
// import { createBrowserHistory } from 'history';
// import { NotificationManager } from 'react-notifications';
// import { push } from 'connected-react-router';
// import _ from 'lodash';
// import {
// getAvoidedEmissions,
// getCarbonAttribution,
// getDisclosureData,
// getPortfolioEmissionEasyRead,
// getSectorEmission,
//   getPortfolioEmissionPcaf,
// } from './footprint';
// import {
// getDownloadDetails,
// getTempMetricArray,
// } from './download';
// import { getScope3Data, getSectorScope3Data } from './scope3';
// import {
//   getPortOptimizationData,
//   getPerformanceAttrData,
// } from './optimization';
// import { getRiskContributorData } from './riskContribution';
// import { getCoalPowerData, getFossilFuelData } from './strandedAsset';
// import {
//   getTempScoreData,
//   getCompanyAnalysisData,
//   getTempAttribution,
//   getContribAnalysis,
//   getHeatmapData,
//   getSectoralTempScore,
// } from './tempMetric';
// import {
//   // getPortfolioAlignment,
//   // getTargetSetting,
//   getCompanyProfileData,
//   getCompanies,
//   getCarbonCompanies,
//   getCarbonReturnsLineData,
//   getCarbonReturnsTableData,
// } from './flm';
// import {
// netZeroTargetSettings,
// getPortfolioAlignmentV2,
// getCompanyAlignmentData,
// getCompanyAlignmentTableData,
// getAllCompanies,
// getDecarbonisationMonitorData,
// getSectorSubplot,
// } from './netZero';

// import {
// getAlignment,
// getSummary,
// getFootprint,
// getFundTargetSetting,
// getSectorLevel,
// } from './fundOfFunds';
// import {
//   getSovereignPortfolioEmission,
//   getCountryEmission,
//   getHistoricalEmission,
// } from './sovereign';
// import UserSessionHandler from '../handler';
// import { persistor } from '../redux/store';
// import { getPortfolioBuilderViewData } from './portfolioBuilder';

// import { api } from './api';
import { isEmpty } from 'lodash';
import {
  setLoading,
  // changeEmailSuccess,
  // getAccessTokenSuccess,
  // getDownloadPortfoliosFailed,
  // getDownloadPortfoliosSuccess,
  // getFixRateSuccess,
  // getFundsPortfolioListFailure,
  // getFundsPortfolioListSuccess,
  // getPortfolioListFailure,
  // getPortfolioListSuccess,
  // getUploadPortfolioListFailed,
  // getUploadPortfolioListSuccess,
  // getUserInfoSuccess,
  // logoutUserSuccess,
  // setBenchmarkSuccess,
  // setDownloadTagsSuccess,
  // setFundsPortfolioSuccess,
  // setLoading,
  // setPortfolioSuccess,
  // setReweightFactor,
  // signinUserSuccess,
  // updateCurrencySuccess,
  updateUserFootprint,
  // uploadCoverageCheckFailed,
  // uploadCoverageCheckSuccess,
  // uploadPortfolioFailed,
  // uploadPortfolioSuccess,
  // verifyUserSuccess,
} from '../redux/reducers/authReducer';
// import PortfolioEmissions from '../models/emissions/portfolioEmissions';
// import AvoidedEmissions from '../models/emissions/avoidedEmissions';
// import SectorEmissions from '../models/emissions/sectorEmissions';
// import PortfolioDisclosure from '../models/emissions/portfolioDisclosure';
// import BenchmarkDisclosure from '../models/emissions/benchmarkDisclosure';
// import PortfolioScope3Heatmap from '../models/scope3/portfolioScope3Heatmap';
// import SectoralScope3Heatmap from '../models/scope3/sectoralScope3Heatmap';
// import CountryEmissions from '../models/sovereign/countryEmissions';
// import SovereignPortfolioEmissions from '../models/sovereign/portfolioEmissions';
// import HistoricalEmissions from '../models/sovereign/historicalEmissions';
// import CarbonAttribution from '../models/emissions/carbonAttribution';
// import PortfolioReturns from '../models/optimization/portfolioReturns';
// import PerformanceAttribution from '../models/optimization/performanceAttribution';
// import FossilFuel from '../models/strandedAsset/fossilFuel';
// import CoalPower from '../models/strandedAsset/coalPower';
// import RiskContributor from '../models/carbonRisk/riskContributor';
// import PortfolioScore from '../models/tempScore/portfolioScore';
// import Attribution from '../models/tempScore/attribution';
// import ContributionAnalysis from '../models/tempScore/contributionAnalysis';
// import TempCompanyAnalysis from '../models/tempScore/companyAnalysis';
// import Heatmap from '../models/tempScore/heatmap';
// import SectoralScore from '../models/tempScore/sectoralScore';
// import Portfolio from '../models/netZero/portfolio';
// import Sector from '../models/netZero/sector';
// import Decarbonisation from '../models/netZero/decarbonisation';
// import SectorSubplot from '../models/netZero/sectorSubplot';

// import Tcfd from '../models/tcfd';
import PortfolioBuilderView from '../models/portfolioBuilderView';

import {
  // CompanyAlignment,
  // CompanyTableAlignment,
  CompanyProfile,
} from '../models/netZero/company';
// import { Companies, FlmCompanyProfile } from '../models/flm/company';
// import PortfolioAlignment from '../models/flm/portAlignment';
// import FLMTargetSetting from '../models/flm/targetSetting';
// import {
//   CarbonAdjustedCompanies,
//   CarbonAdjustedLine,
//   CarbonAdjustedTable,
// } from '../models/flm/carbonAdjustedReturn';
// import Alignment from '../models/FOF/alignment';
// import Footprint from '../models/FOF/footprint';
// import TargetSetting from '../models/FOF/targetSetting';
// import Download from '../models/download';
// import * as constants from '../components/FilterSection/constants';
// import PortfolioEmissionPcaf from '../models/pcaf/portfolioEmissionPcaf';
// import BreakdownByAssetClassPcaf from '../models/pcaf/breakdownByAssetClassPcaf';
// import Report from '../models/report';
import {
  // getTcfdData,
  // getGenerateReportData,
  getBenchmarkReportData,
  getBenchmarkCompanies,
} from './report';
import Benchmark from '../models/benchmark';
// import {
//   setCountrySuccess,
//   setFilterItemSuccess,
// } from '../redux/reducers/filterReducer';
// import TempMetricArray from '../models/download/tempMetric';
// import {
// setCountrySuccess,
//   setFilterItemSuccess,
// } from '../redux/reducers/filterReducer';
// import TempMetricArray from '../models/download/tempMetric';
// import { getTcfdData, getGenerateReportData } from './report';
import { portfolioBuilderApiSlice, userAuthSliceApi } from '../redux/apiHooks';
import {
  getPortBuilderFailed,
  getPortBuilerSuccess,
} from '../redux/reducers/portfolioBuilderReducer';
// const history = createBrowserHistory({ window });

/**
Makes an API request.
@param {Object} options - The options for the API request.
@param {function} options.dispatch - The dispatch function used for Redux actions.
@param {Object} options.auth - Authentication data from redux [state.auth]
@param {Object} options.filters - Filters data from redux [state.filters]
@param {Object} [options.optionalParams] - Optional parameters for the API request.
@param {Object} [state] - State of the entire application
@returns {Promise} A promise representing the API request.
*/

export const requestApi = async ({
  dispatch,
  auth,
  filters,
  optionalParams,
  state,
}) => {
  const {
    // showBenchmark,
    moduleName,
    tabValue,
    // allPortfolios,
    // currentFundsPortfolio,
    // currentPortfolio,
    // currentBenchmark,
  } = auth;
  const authWithFilters = { ...auth, filterItem: { ...filters } };
  // const data = {};

  // let childrenIds = [];
  // const result = [];
  // if (allPortfolios && allPortfolios.length > 0) {
  //   allPortfolios.forEach((portfolio) => {
  //     if (portfolio.portfolio_id === currentFundsPortfolio.value) {
  //       childrenIds = portfolio.children_id;
  //     }
  //   });
  // }
  // if (childrenIds && childrenIds.length > 0) {
  //   childrenIds.forEach((id) => {
  //     allPortfolios.forEach((portfolio) => {
  //       if (portfolio.id === id) {
  //         result.push(portfolio.portfolio_id);
  //       }
  //     });
  //   });
  // }
  // if (!currentPortfolio?.value || !currentBenchmark?.value) {
  //   if (!currentPortfolio?.value) NotificationManager.error('Portfolio is not selected');
  //   else if (!currentBenchmark?.value) NotificationManager.error('Benchmark is not selected');
  //   else NotificationManager.error('Portfolio and Benchmark are not selected');
  //   return;
  // }

  if (moduleName === 'Reports') {
    if (tabValue === 0) {
      // if (optionalParams?.loadData) {
      //   const tcfd = new Tcfd(authWithFilters);
      //   const tcfdData = tcfd.toJsonObject();
      //   const tcfdPortfolioScore = new PortfolioScore(authWithFilters);
      //   const tcfdTempScore = tcfdPortfolioScore.toJsonObject();
      //   dispatch(getTcfdData(tcfdData, tcfdTempScore));
      // }
    } else if (tabValue === 1) {
      // if (optionalParams?.loadData) {
      //   const report = new Report(authWithFilters);
      //   const reportData = report.toJsonObject();
      //   const reportPortfolioScore = new PortfolioScore(authWithFilters);
      //   const reportTempScore = reportPortfolioScore.toJsonObject();
      //   dispatch(
      //     getGenerateReportData(reportData, {
      //       ...reportTempScore,
      //       benchmark_on: true,
      //     }),
      //   );
      // }
    } else if (tabValue === 2) {
      if (optionalParams?.loadData) {
        const benchmarkReport = new Benchmark({
          ...authWithFilters,
          isin: state.report.benchmarkReport.company,
        });
        const benchmarkReportData = benchmarkReport.toJsonObject();
        dispatch(getBenchmarkReportData(benchmarkReportData));
      } else {
        const companyProfile = new CompanyProfile(authWithFilters);
        const companyProfileData = companyProfile.toJsonObject();
        dispatch(getBenchmarkCompanies(companyProfileData));
      }
    }
  } else if (moduleName === 'Portfolio Screener') {
    if (tabValue === 0) {
      // eslint-disable-next-line no-use-before-define
      dispatch(getPortfolioBuilderData());
      // const selectedfilters = optionalParams.filter(
      //   (o) => o.isSelected && (o.value || o.value === 0),
      // );
      // const newArr = selectedfilters.length
      //   ? selectedfilters.map((fields) => ({
      //     [fields.filterType]: Array.isArray(fields.value)
      //       ? fields.value.map((v) => ({ condition: 'eq', value: v }))
      //       : [
      //         {
      //           condition: fields.condition || 'eq',
      //           value:
      //                 fields.condition === 'range'
      //                   ? [Number(fields.gt), Number(fields.lt)]
      //                   : fields.value,
      //         },
      //       ],
      //   }))
      //   : [];
      // const portfolioBuilderView = new PortfolioBuilderView(
      //   authWithFilters,
      //   newArr || [],
      // );
      // const data = portfolioBuilderView.toJsonObject();
      // // dispatch(getPortfolioBuilderViewData(data));
      // dispatch(setLoading(true));
      // await dispatch(
      //   portfolioBuilderApiSlice.endpoints.getPortBuilderPortConstruction.initiate(
      //     data,
      //   ),
      // );
      // dispatch(setLoading(false));
    }
  }
  // switch (moduleName) {
  // case 'Download':
  //   switch (tabValue) {
  //     case 0:
  //       const downloadData = new Download(authWithFilters);
  //       const data = downloadData.toJsonObject();
  //       dispatch(getDownloadDetails(data));
  //       if (filters.include?.includes(constants.SBTI_TEMP_SCORE)) {
  //         const metricData = new TempMetricArray(authWithFilters);
  //         dispatch(getTempMetricArray(metricData.toJsonObject()));
  //       }
  //       break;
  //     default:
  //       break;
  //   }
  //   break;
  // case 'PCAF':
  //   switch (tabValue) {
  //     case 0: {
  //       const pcafPortfolioData = new PortfolioEmissionPcaf(authWithFilters);
  //       data = pcafPortfolioData.toJsonObject();
  //       await dispatch(getPortfolioEmissionPcaf(data));
  //       break;
  //     }
  //     case 1: {
  //       const pcafPortfolioData = new BreakdownByAssetClassPcaf(
  //         authWithFilters,
  //       );
  //       data = pcafPortfolioData.toJsonObject();
  //       await dispatch(getPortfolioEmissionPcaf(data));
  //       break;
  //     }
  //     default:
  //       break;
  //   }
  //   break;
  // case 'Emissions':
  //   switch (tabValue) {
  // case 0:
  //   const portfolioEmissions = new PortfolioEmissions(authWithFilters);
  //   data = portfolioEmissions.toJsonObject();
  //   await dispatch(getPortfolioEmissionEasyRead(data));
  //   break;
  // case 1:
  //   const sectorEmissions = new SectorEmissions(authWithFilters);
  //   data = sectorEmissions.toJsonObject();
  //   await dispatch(getSectorEmission(data));
  //   break;
  // case 2:
  //   const carbonAttribution = new CarbonAttribution(authWithFilters);
  //   data = carbonAttribution.toJsonObject();
  //   await dispatch(getCarbonAttribution(data));
  //   break;
  // case 3:
  //   const portfolioDisclosure = new PortfolioDisclosure(authWithFilters);
  //   const portData = portfolioDisclosure.toJsonObject();
  //   await dispatch(getDisclosureData(portData, 'portfolio'));

  //   if (showBenchmark) {
  //     const benchmarkDisclosure = new BenchmarkDisclosure(
  //       authWithFilters,
  //       true,
  //     );
  //     const benchData = benchmarkDisclosure.toJsonObject();
  //     await dispatch(getDisclosureData(benchData, 'benchmark'));
  //   }

  //   break;
  // case 4:
  //   const avoidedEmissions = new AvoidedEmissions(authWithFilters);
  //   data = avoidedEmissions.toJsonObject();
  //   await dispatch(getAvoidedEmissions(data));
  //   break;
  //   default:
  //     break;
  // }
  // break;

  // case 'Scope3':
  //   switch (tabValue) {
  //     case 0:
  //       const portfolioScope3Heatmap = new PortfolioScope3Heatmap(
  //         authWithFilters,
  //       );
  //       data = portfolioScope3Heatmap.toJsonObject();
  //       await dispatch(getScope3Data(data));
  //       break;

  //     case 1:
  //       const sectoralScope3Heatmap = new SectoralScope3Heatmap(
  //         authWithFilters,
  //       );
  //       data = sectoralScope3Heatmap.toJsonObject();
  //       await dispatch(getSectorScope3Data(data));
  //       break;

  //     default:
  //       break;
  //   }
  //   break;
  // case 'Fund Of Funds':
  //   switch (tabValue) {
  // case 0:
  //   await dispatch(getSummary(currentFundsPortfolio.value));
  //   break;
  // case 1:
  //   const footprint = new Footprint(authWithFilters);
  //   data = footprint.toJsonObject();
  //   await dispatch(getFootprint(data));
  //   await dispatch(getSectorLevel(data));
  //   break;
  // case 2:
  //   const alignment = new Alignment(authWithFilters);
  //   data = alignment.toJsonObject();
  //   await dispatch(getAlignment(data));
  //   break;
  // case 3:
  //   const targetSetting = new TargetSetting(authWithFilters);
  //   data = targetSetting.toJsonObject();
  //   await dispatch(getFundTargetSetting(data));
  //   break;
  // default:
  // await dispatch(getSummary(currentFundsPortfolio.value));
  //     break;
  // }
  // break;
  // case 'FLM':
  //   switch (tabValue) {
  //     // case 0: {
  //     //   const portfolioAlignment = new PortfolioAlignment(authWithFilters);
  //     //   data = portfolioAlignment.toJsonObject();
  //     //   await dispatch(getPortfolioAlignment(data));
  //     //   break;
  //     // }
  //     // case 1:
  //     //   const targetSetting = new FLMTargetSetting(authWithFilters);
  //     //   data = targetSetting.toJsonObject();
  //     //   await dispatch(getTargetSetting(data));
  //     //   break;
  //     case 2:
  //       const companies = new Companies(authWithFilters);
  //       data = companies.toJsonObject();
  //       const companyData = await dispatch(
  //         getCompanies(
  //           data,
  //           optionalParams || {
  //             companyId: auth.currentCompany,
  //             sector: auth.currentSector,
  //           },
  //         ),
  //       );
  //       if (
  //         companyData?.response
  //         && Object.keys(companyData?.response).length > 0
  //       ) {
  //         const flmCompanyProfile = new FlmCompanyProfile(authWithFilters);
  //         let requestData = flmCompanyProfile.toJsonObject();

  //         requestData = {
  //           ...requestData,
  //           isin: companyData.currentCompany,
  //         };
  //         await dispatch(getCompanyProfileData(requestData));
  //       }
  //       break;
  //     case 3:
  //       const flmCompanyProfile = new CarbonAdjustedCompanies(
  //         authWithFilters,
  //       );
  //       data = flmCompanyProfile.toJsonObject();
  //       const company = await dispatch(
  //         getCarbonCompanies(
  //           data,
  //           optionalParams || {
  //             companyId: auth.currentCompany,
  //             sector: auth.currentSector,
  //           },
  //         ),
  //       );
  //       if (company?.response && Object.keys(company.response).length > 0) {
  //         const carbonAdjustedLine = new CarbonAdjustedLine(authWithFilters);
  //         let carbonAdjustedLineData = carbonAdjustedLine.toJsonObject();

  //         const carbonAdjustedTable = new CarbonAdjustedTable(
  //           authWithFilters,
  //         );
  //         let carbonAdjustedTableData = carbonAdjustedTable.toJsonObject();

  //         carbonAdjustedLineData = {
  //           ...carbonAdjustedLineData,
  //           isin: company.currentCompany,
  //           // ticket: company.ticket,
  //         };
  //         carbonAdjustedTableData = {
  //           ...carbonAdjustedTableData,
  //           isin: company.currentCompany,
  //           // ticket: company.ticket,
  //         };
  //         await dispatch(getCarbonReturnsLineData(carbonAdjustedLineData));
  //         await dispatch(getCarbonReturnsTableData(carbonAdjustedTableData));
  //       }
  //       break;
  //     default: {
  //       break;
  //     }
  //   }
  //   break;
  // case 'Optimization':
  //   switch (tabValue) {
  //     case 0:
  //       const portfolioReturns = new PortfolioReturns(authWithFilters);
  //       data = portfolioReturns.toJsonObject();
  //       await dispatch(getPortOptimizationData(data));
  //       break;
  //     case 1:
  //       const portfolioRisk = new PortfolioReturns(authWithFilters);
  //       data = portfolioRisk.toJsonObject();
  //       await dispatch(getPortOptimizationData(data));
  //       break;
  //     case 2:
  //       const optimizedEmissions = new PortfolioReturns(authWithFilters);
  //       data = optimizedEmissions.toJsonObject();
  //       await dispatch(getPortOptimizationData(data));
  //       break;
  //     case 3:
  //       const performanceAttribution = new PerformanceAttribution(
  //         authWithFilters,
  //       );
  //       data = performanceAttribution.toJsonObject();
  //       await dispatch(getPerformanceAttrData(data));
  //       break;
  //     default:
  //       const optimization = new PortfolioReturns(authWithFilters);
  //       data = optimization.toJsonObject();
  //       await dispatch(getPortOptimizationData(data));
  //       break;
  //   }
  //   break;
  // case 'Carbon risk':
  //   switch (tabValue) {
  //     case 0:
  //       // data = getRequestData('RISK_CONTRIBUTOR', auth);
  //       // await dispatch(getRiskContributorData(data));
  //       const riskContributor = new RiskContributor(authWithFilters);
  //       data = riskContributor.toJsonObject();
  //       await dispatch(getRiskContributorData(data));
  //       break;
  //     default:
  //       // data = getRequestData('RISK_CONTRIBUTOR', auth);
  //       const carbonRisk = new RiskContributor(authWithFilters);
  //       data = riskContributor.toJsonObject();
  //       await dispatch(getRiskContributorData(data));
  //       break;
  //   }
  //   break;
  // case 'Stranded':
  //   switch (tabValue) {
  //     case 0:
  //       const fossilFuel = new FossilFuel(authWithFilters);
  //       data = fossilFuel.toJsonObject();
  //       await dispatch(getFossilFuelData(data));
  //       break;
  //     default:
  //       const coalPower = new CoalPower(authWithFilters);
  //       data = coalPower.toJsonObject();
  //       await dispatch(getCoalPowerData(data));
  //       break;
  //   }
  //   break;
  // case 'Temp score':
  //   switch (tabValue) {
  //     case 0:
  //       const portfolioScore = new PortfolioScore(authWithFilters);
  //       data = portfolioScore.toJsonObject();
  //       await dispatch(getTempScoreData(data));
  //       break;
  //     case 1:
  //       const companyAnalysis = new TempCompanyAnalysis(authWithFilters);
  //       data = companyAnalysis.toJsonObject();
  //       await dispatch(getCompanyAnalysisData(data));
  //       break;
  //     case 2:
  //       const attribution = new Attribution(authWithFilters);
  //       data = attribution.toJsonObject();
  //       await dispatch(getTempAttribution(data));
  //       break;
  //     case 3:
  //       const sectoralScore = new SectoralScore(authWithFilters);
  //       data = sectoralScore.toJsonObject();
  //       await dispatch(getSectoralTempScore(data));
  //       break;
  //     case 4:
  //       const contributionAnalysis = new ContributionAnalysis(
  //         authWithFilters,
  //       );
  //       data = contributionAnalysis.toJsonObject();
  //       await dispatch(getContribAnalysis(data));
  //       break;
  //     case 5:
  //       const heatmap = new Heatmap(authWithFilters);
  //       data = heatmap.toJsonObject();
  //       await dispatch(getHeatmapData(data));
  //       break;
  //     default:
  //       // const tempScore = new PortfolioScore(authWithFilters);
  //       // data = tempScore.toJsonObject();
  //       // await dispatch(getTempScoreData(data));
  //       break;
  //   }
  //   break;
  // case 'Sovereign':
  //   switch (tabValue) {
  //     case 0:
  //       const sovereignPortfolioEmissions = new SovereignPortfolioEmissions(
  //         authWithFilters,
  //       );
  //       data = sovereignPortfolioEmissions.toJsonObject();
  //       await dispatch(getSovereignPortfolioEmission(data));
  //       break;

  //     case 1:
  //       const countryEmissions = new CountryEmissions(authWithFilters);
  //       data = countryEmissions.toJsonObject();
  //       await dispatch(getCountryEmission(data));
  //       break;

  //     case 2:
  //       const historicalEmissions = new HistoricalEmissions(authWithFilters);
  //       data = historicalEmissions.toJsonObject();
  //       await dispatch(getHistoricalEmission(data));
  //       break;

  //     default:
  //       break;
  //   }
  //   break;

  // case 'Net-zero analysis':
  //   switch (tabValue) {
  // case constants.DECARBONATION_MONITOR:
  //   const decarbonisation = new Decarbonisation(authWithFilters);
  //   data = decarbonisation.toJsonObject();
  //   await dispatch(getDecarbonisationMonitorData(data));
  //   break;
  // case constants.NET_ZERO_ANALYSIS:
  //   const portfilioAlignment = new Portfolio(authWithFilters);
  //   data = portfilioAlignment.toJsonObject();
  //   await dispatch(getPortfolioAlignmentV2(data));
  //   break;
  // case constants.NET_ZERO_TARGET_SETTINGS:
  //   const targetSetting = new Sector(authWithFilters);
  //   data = targetSetting.toJsonObject();
  //   await dispatch(netZeroTargetSettings(data));
  //   break;
  // case constants.NET_ZERO_COMPANY_ALIGNMENT:
  //   const companyProfile = new CompanyProfile(authWithFilters);
  //   data = companyProfile.toJsonObject();
  //   const { response, currentCompany, sector } = await dispatch(
  //     getAllCompanies(
  //       data,
  //       optionalParams || {
  //         companyId: auth.currentCompany,
  //         sector: auth.currentSector,
  //       },
  //     ),
  //   );
  // if (response && Object.keys(response).length > 0) {
  // const companyAlignment = new CompanyAlignment(authWithFilters);
  // let companyData = companyAlignment.toJsonObject();
  // companyData = {
  //   ...companyData,
  //   isin: currentCompany,
  //   industry: sector,
  // };
  // await dispatch(getCompanyAlignmentData(companyData));
  // const companyTableAlignment = new CompanyTableAlignment(
  //   authWithFilters,
  // );
  // let dataTable = companyTableAlignment.toJsonObject();
  // dataTable = {
  //   ...dataTable,
  //   industry: sector,
  // };
  // await dispatch(getCompanyAlignmentTableData(dataTable));
  // }
  // break;
  // case constants.SECTOR_SUBPLOT:
  //   const sectorSubplot = new SectorSubplot(authWithFilters);
  //   data = sectorSubplot.toJsonObject();
  //   await dispatch(getSectorSubplot(data));
  //   break;
  // default:
  //   break;
  // }
  // break;

  // case 'Reports':
  //   switch (tabValue) {
  //     case 0:
  //       if (optionalParams?.loadData) {
  //         const tcfd = new Tcfd(authWithFilters);
  //         const tcfdData = tcfd.toJsonObject();

  //         const tcfdPortfolioScore = new PortfolioScore(authWithFilters);
  //         const tcfdTempScore = tcfdPortfolioScore.toJsonObject();

  //         dispatch(getTcfdData(tcfdData, tcfdTempScore));
  //       }
  //       break;
  //     case 1:
  //       if (optionalParams?.loadData) {
  //         const report = new Report(authWithFilters);
  //         const reportData = report.toJsonObject();

  //         const reportPortfolioScore = new PortfolioScore(authWithFilters);
  //         const reportTempScore = reportPortfolioScore.toJsonObject();
  //         dispatch(
  //           getGenerateReportData(reportData, {
  //             ...reportTempScore,
  //             benchmark_on: true,
  //           }),
  //         );
  //       }
  //       break;
  //     case 2:
  //       if (optionalParams?.loadData) {
  //         const benchmarkReport = new Benchmark({
  //           ...authWithFilters,
  //           isin: state.report.benchmarkReport.company,
  //         });
  //         const benchmarkReportData = benchmarkReport.toJsonObject();
  //         dispatch(getBenchmarkReportData(benchmarkReportData));
  //       } else {
  //         const companyProfile = new CompanyProfile(authWithFilters);
  //         const companyProfileData = companyProfile.toJsonObject();
  //         dispatch(getBenchmarkCompanies(companyProfileData));
  //       }
  //       break;
  //     }
  //     default:
  //       break;
  //   }
  //   break;
  // case 'Portfolio Screener':
  //   switch (tabValue) {
  //     case 0: {
  //       const selectedfilters = optionalParams.filter(
  //         (o) => o.isSelected && (o.value || o.value === 0),
  //       );
  //       const newArr = selectedfilters.length
  //         ? selectedfilters.map((fields) => ({
  //           [fields.filterType]: Array.isArray(fields.value)
  //             ? fields.value.map((v) => ({ condition: 'eq', value: v }))
  //             : [
  //               {
  //                 condition: fields.condition || 'eq',
  //                 value:
  //                       fields.condition === 'range'
  //                         ? [Number(fields.gt), Number(fields.lt)]
  //                         : fields.value,
  //               },
  //             ],
  //         }))
  //         : [];
  //       const portfolioBuilderView = new PortfolioBuilderView(
  //         authWithFilters,
  //         newArr || [],
  //       );
  //       const data = portfolioBuilderView.toJsonObject();
  //       // dispatch(getPortfolioBuilderViewData(data));
  //       dispatch(setLoading(true));
  //       await dispatch(
  //         portfolioBuilderApiSlice.endpoints.getPortBuilderPortConstruction.initiate(
  //           data,
  //         ),
  //       );
  //       dispatch(setLoading(false));
  //       break;
  //     }
  //     default:
  //       break;
  //   }
  //   break;
  // default:
  //   break;
  // }
};

export const dispatchRequestApi = (optionalParams) => async (dispatch, getState) => {
  const { auth, filters, ...state } = getState();
  await requestApi({
    dispatch,
    auth,
    filters,
    optionalParams,
    state,
  });
};

// export const signinUser = (data) => async (dispatch) => {
//   try {
//     dispatch(setLoading(true));
//     const response = await api.post('user/sign_in', data);
//     const userDetails = {
//       ...response,
//       userName: data.user_name,
//     };
//     dispatch(signinUserSuccess(userDetails));
//     UserSessionHandler.login(response);
//     UserSessionHandler.setSupplyCookie(response.access_token);
//     response?.warning && NotificationManager.warning(response.warning);
//     dispatch(setLoading(false));
//     history.push('/');
//   } catch (error) {
//     dispatch(setLoading(false));
//     throw new Error(error?.message);
//   }
// };

// export const verifyUser = (data) => async (dispatch) => {
//   try {
//     dispatch(setLoading(true));
//     const response = await api.post('accounts/reset_password', data);
//     dispatch(verifyUserSuccess(response));
//     dispatch(setLoading(false));
//   } catch (error) {
//     const message = error?.message || '';
//     dispatch(setLoading(false));
//     throw new Error(message);
//   }
// };

// export const getPortfolioList = () => async (dispatch) => {
//   try {
//     dispatch(setLoading(true));
//     const data = await api.get('portfolio');
//     dispatch(getPortfolioListSuccess(data));
//     dispatch(setLoading(false));
//   } catch (error) {
//     dispatch(getPortfolioListFailure());
//     dispatch(setLoading(false));
//   }
// };

// export const getFundsPortfolioList = () => async (dispatch) => {
//   try {
//     dispatch(setLoading(true));
//     const response = await api.get('portfolio/?portfolio_type=PC&is_full=true');
//     dispatch(getFundsPortfolioListSuccess(response));
//     const list = response && response.length > 0 ? response : [];

//     let fundsData = {};
//     const res = [];
//     if (list && list.length > 0) {
//       list.forEach((portfolio) => {
//         if (portfolio.is_parent) {
//           res.push({
//             label: portfolio.name,
//             value: portfolio.portfolio_id,
//             version: portfolio.version,
//           });
//         }
//       });
//     }

//     fundsData = {
//       label: res.length > 0 ? res[0].label : '',
//       value: res.length > 0 ? res[0].value : '',
//       version: res.length > 0 ? res[0].version : '',
//     };
//     dispatch(setLoading(false));
//     return { list, fundsData };
//   } catch (error) {
//     dispatch(getFundsPortfolioListFailure());
//     dispatch(setLoading(false));
//   }
// };

// export const getUserInfo = (data, context) => async (dispatch, getState) => {
//   try {
//     dispatch(setLoading(true));
//     const response = await api.get('user/info');
//     const isEmbeded = UserSessionHandler.getCookie();
//     const themeInUrl = UserSessionHandler.getSFCookie();

//     if (isEmbeded || themeInUrl) {
//       UserSessionHandler.setAppTheme(themeInUrl || 'ICE light');
//       context.setTheme(themeInUrl || 'ICE light');
//     } else if (context.currentTheme !== response?.theme) {
//       UserSessionHandler.setAppTheme(response.theme);
//       context.setTheme(response.theme);
//     }
//     dispatch(getUserInfoSuccess(response));
//     if (!getState().filters.dateRange?.endDate) {
//       dispatch(
//         setFilterItemSuccess({
//           key: 'dateRange',
//           value: {
//             startDate: getState().filters.dateRange?.startDate || '2020-01-01',
//             endDate: response.backtest_end_date,
//           },
//         }),
//       );
//     }
//     // dispatch(getFixRate(response?.year?.currency, response?.quarter?.currency));
//     dispatch(setLoading(false));
//   } catch (error) {
//     error?.description?.type === 'change_pwd'
//       && NotificationManager.error('Please change the password !');
//     dispatch(setLoading(false));
//   }
// };

// export const updateUserInfo = (data, context) => async (dispatch) => {
//   try {
//     dispatch(setLoading(true));
//     const response = await api.put('user/info', data);
//     dispatch(getUserInfoSuccess(response));

//     if (context.currentTheme !== response?.theme) {
//       if (!response?.theme) {
//         UserSessionHandler.setAppTheme('light');
//       } else {
//         UserSessionHandler.setAppTheme(response?.theme);
//       }
//       dispatch(setLoading(false));
//     }
//   } catch (error) {
//     dispatch(setLoading(false));
//   }
// };

// export const getUploadPortfolioList = () => async (dispatch) => {
//   try {
//     dispatch(setLoading(true));
//     const data = await api.get('portfolio/?is_full=True&short=False');
//     dispatch(getUploadPortfolioListSuccess(data));
//     dispatch(setLoading(false));
//   } catch (error) {
//     dispatch(getUploadPortfolioListFailed());
//     dispatch(setLoading(false));
//   }
// };

// export const updateCurrency = (data) => async (dispatch) => {
//   dispatch(updateCurrencySuccess(data));
// };

// export const setPortfolio = (portfolio, filterType) => async (dispatch) => {
//   await dispatch(setPortfolioSuccess(portfolio));
//   if (filterType !== 'manual') {
//     dispatch(dispatchRequestApi());
//   }
// };

// export const setFundsPortfolio = (portfolio) => async (dispatch) => {
//   await dispatch(setFundsPortfolioSuccess(portfolio));
//   dispatch(dispatchRequestApi());
// };

// export const setBenchmark = (benchmark, filterType) => async (dispatch) => {
//   await dispatch(setBenchmarkSuccess(benchmark));
//   if (filterType !== 'manual') {
//     dispatch(dispatchRequestApi());
//   }
// };

// export const setFilterItem = (data) => async (dispatch) => {
//   await dispatch(setFilterItemSuccess(data));
//   if (
//     data.key !== 'intensityScope'
//     && data.key !== 'year'
//     && data.type !== 'manual'
//   ) {
//     dispatch(dispatchRequestApi());
//   }
// };

// export const setLogin = () => async () => {
//   history.push('/login');
//   localStorage.setItem('version', process.env.REACT_APP_VERSION);
// };

// export const setReweightData = (value) => async (dispatch) => {
//   await dispatch(setReweightFactor(value));
//   dispatch(dispatchRequestApi());
// };

// export const logoutUser = () => async (dispatch) => {
//   try {
//     dispatch(setLoading(true));
//     await api.post('user/sso_sign_out');
//     setTimeout(() => persistor.purge(), 200);
//     UserSessionHandler.removeCookie();
//     setTimeout(() => persistor.purge(), 200);
//     UserSessionHandler.logout();
//     dispatch(logoutUserSuccess());
//     dispatch(push('/login'));
//     dispatch(setLoading(false));
//   } catch (error) {
//     NotificationManager.error('Logout Failed ! try again');
//     dispatch(setLoading(false));
//   }
// };

// export const getDownloadPortfolios = () => async (dispatch) => {
//   try {
//     const data = await api.get('portfolio/?is_benchmark=False');
//     dispatch(getDownloadPortfoliosSuccess(data));
//   } catch (error) {
//     const errMessage = error?.message || null;
//     dispatch(getDownloadPortfoliosFailed(errMessage));
//   }
// };

// export const generateReport = (data) => async (dispatch) => {
//   try {
//     dispatch(setLoading(true));
//     await api.post('reports_new/full', data);
//   } catch (error) {
//     dispatch(setLoading(false));
//   }
// };

// export const uploadPortfolioRequest = (data, isFund) => async (dispatch, getState) => {
//   try {
//     dispatch(setLoading(true));
//     const response = await api.post(`upload/?type_upload=${isFund}`, data);
//     dispatch(uploadPortfolioSuccess(response));
//     // dispatch(getUploadPortfolioList());
//     dispatch(getPortfolioList(getState().auth.currentUser.client));
//     dispatch(setLoading(false));
//   } catch (error) {
//     const message = error?.message || '';
//     dispatch(uploadPortfolioFailed(message));
//     dispatch(setLoading(false));
//     throw new Error(message);
//   }
// };

// export const changeEmail = (data) => async (dispatch) => {
//   try {
//     dispatch(setLoading(true));
//     const response = await api.post('user/change_email', data);
//     dispatch(changeEmailSuccess(response));
//     dispatch(setLoading(false));
//   } catch (error) {
//     const message = error?.message || '';
//     dispatch(setLoading(false));
//     throw new Error(message);
//   }
// };

// export const verifyCode = (data) => async (dispatch) => {
//   try {
//     dispatch(setLoading(true));
//     const response = await api.post('user/verify', data);
//     // dispatch(actions.verifyCodeSuccess(response.data)); // No reducer action
//     dispatch(setLoading(false));
//   } catch (error) {
//     const message = error?.message || '';
//     dispatch(setLoading(false));
//     throw new Error(message);
//   }
// };

// export const changePassword = (data) => async (dispatch) => {
//   try {
//     dispatch(setLoading(true));
//     await api.post('user/change_pwd', data);
//     dispatch(setLoading(false));
//   } catch (error) {
//     const message = error?.message || '';
//     dispatch(setLoading(false));
//     throw new Error(message);
//   }
// };
// export const deletePortfolioRequest = (portfolios) => async (dispatch, getState) => {
//   try {
//     dispatch(setLoading(true));
//     await api.delete(`portfolio/?portfolio_ids=${portfolios.join()}`);
//     dispatch(getUploadPortfolioList());
//     dispatch(getPortfolioList(getState().auth.currentUser.client));
//     dispatch(setLoading(false));
//   } catch (error) {
//     const message = error?.message || '';
//     dispatch(setLoading(false));
//     throw new Error(message);
//   }
// };

// export const setDownloadTags = (tags) => async (dispatch, getState) => {
//   const { auth } = getState();
//   const { userInfo, selectedDownloadPort } = auth;
//   const yearEmissions = userInfo.year.emissions;

//   const data = {
//     year: yearEmissions,
//     field: tags.join(';'),
//     portfolio_id: selectedDownloadPort.value,
//     version_portfolio: selectedDownloadPort.version,
//   };
//   await dispatch(setDownloadTagsSuccess(tags));
//   await dispatch(getDownloadDetails(data));
// };

// export const getFixRate = (year, quarter) => async (dispatch) => {
//   try {
//     dispatch(setLoading(true));
//     const response = await api.get(
//       `currencies/?year=${year}&quarter=${quarter.slice(1, quarter.length)}`,
//     );
//     dispatch(getFixRateSuccess(response));
//     dispatch(setLoading(false));
//   } catch (error) {
//     const message = error?.message || '';
//     dispatch(setLoading(false));
//     throw new Error(message);
//   }
// };

// export const getAccessToken = (type) => async (dispatch) => {
//   try {
//     dispatch(setLoading(true));
//     const response = await api.post('user/refresh', {}, { type });
//     dispatch(getAccessTokenSuccess(response));
//     UserSessionHandler.updateAuthToken(response.access_token);
//     dispatch(setLoading(false));
//   } catch (error) {
//     dispatch(setLoading(false));
//     const errorType = error ? error.type : null;
//     if (errorType && errorType === 're-login') {
//       NotificationManager.error('Session Expired!');
//       dispatch(logoutUser());
//     }
//   }
// };

// export const setCountry = (country) => async (dispatch) => {
//   await dispatch(setCountrySuccess(country));
//   dispatch(dispatchRequestApi());
// };

// export const verifySsoCredentials = (credentials) => async (dispatch) => {
//   try {
//     // dispatch(setLoading(true));
//     const response = await api.post('user/sso_sign_in', { credentials });
//     dispatch(signinUserSuccess(response));
//     UserSessionHandler.login(response);
//     UserSessionHandler.setSupplyCookie(response.access_token);
//     response?.warning && NotificationManager.warning(response.warning);
//     response?.message && NotificationManager.success(response.message);
//     dispatch(push('/'));
//     // dispatch(setLoading(false));
//   } catch (error) {
//     const message = error?.message
//       ? error?.message
//       : 'Failed to verify ICE credentials';
//     NotificationManager.error(message, '');
//     dispatch(logoutUser());
//     // dispatch(setLoading(false));
//   }
// };

// export const uploadCoverageCheck = async (data) => {
//   try {
//     return await api.post('/coverage_check', data);
//   } catch (error) {
//     NotificationManager.error(
//       error?.message || 'Something went wrong. Please try again later',
//     );
//   }
// };

// export const uploadPrivateInference = async (data) => {
//   try {
//     return await api.post('/private_inference', data);
//   } catch (error) {
//     NotificationManager.error(
//       error?.message || 'Something went wrong. Please try again later',
//     );
//   }
// };

// export const getCoverageDetails = async (portfolioId, data) => {
//   try {
//     const response = await api.post(
//       `/portfolio/coverage/${portfolioId}/`,
//       data,
//     );
//     return response;
//   } catch (e) {
//     return { error: e };
//   }
// };

// TODO: Remove : doens't need as not async operation

// NOT USED
// export const setEmissions = (emissions) => async (dispatch) => {
//   await dispatch(actions.setEmissionsSuccess(emissions));
// };

// using setMultiplePortfolio from authReducer
// export const setPortfolios = (portfolios) => async (dispatch) => dispatch(actions.setMultiPortfoliosSuccess(portfolios));

// NOT USED
// export const setFootprint = (footprint) => async (dispatch) => {
//   await dispatch(actions.setFootprintSuccess(footprint));
// };

// NOT USED
// export const setAssetType = (assetType) => async (dispatch) => {
//   await dispatch(actions.setAssetTypeSuccess(assetType));
// };

// using setTabValue from authReducer
// export const setTabValue = (value) => async (dispatch) => {
//   dispatch(actions.setTabValueSuccess(value));
// };

// using setModule from authReducer
// export const setModule = (value) => async (dispatch) => {
//   await dispatch(actions.setModuleSuccess(value));
// };

// using setSector from authReducer
// export const setSector = (value) => async (dispatch) => {
//   await dispatch(actions.setSectorSuccess(value));
// };

// using setCompany from authReducer
// export const setCompany = (value) => async (dispatch) => {
//   await dispatch(actions.setCompanySuccess(value));
// };

// NOT USED
// export const setFilterVisibility = (value) => async (dispatch) => {
//   dispatch(actions.setFilterVisibilitySuccess(value));
// };

// using setFilterItemSuccess from filterReducer
// export const updateFilterItem = (data) => async (dispatch) => {
//   dispatch(setFilterItemSuccess(data));
// };

// NOT USED
// export const setDownloadPortfolio = (portfolio) => async (dispatch) => {
//   dispatch(actions.setDownloadPortfolioSuccess(portfolio));
// };

// export const updateUserFootprintData = (footprintData) => (dispatch) => {
//   dispatch(
//     updateUserFootprint({
//       ...footprintData.footprint_parameters,
//     }),
//   );
//   // dispatch(updateUserInfo(footprintData));
//   dispatch(userAuthSliceApi.endpoints.updateUserInfo.initiate(footprintData));
// };

export const updateUserFootprintData = (footprintData) => (dispatch) => {
  dispatch(
    updateUserFootprint({
      ...footprintData.footprint_parameters,
    }),
  );
  // dispatch(updateUserInfo(footprintData));
  dispatch(userAuthSliceApi.endpoints.updateUserInfo.initiate(footprintData));
};

export const getPortfolioBuilderData = () => async (dispatch, getState) => {
  const { portfolioBuilder, auth } = getState();
  const { selectorFields } = portfolioBuilder;
  const selectedfilters = selectorFields.filter(
    (o) => o.isSelected
      && (!isEmpty(o.value)
        || typeof o.value === 'number'
        || (o.condition === 'range' && (o.gt || o.lt))),
  );
  const newArr = selectedfilters.length
    ? selectedfilters.map((fields) => ({
      [fields.filterType]: Array.isArray(fields.value)
        ? fields.value.map((v) => ({ condition: 'eq', value: v }))
        : [
          {
            condition: fields.condition || 'eq',
            value:
                  fields.condition === 'range'
                    ? [Number(fields.gt), Number(fields.lt)]
                    : fields.value,
          },
        ],
    }))
    : [];
  const portfolioBuilderView = new PortfolioBuilderView(auth, newArr || []);
  dispatch(setLoading(true));
  const response = await dispatch(
    portfolioBuilderApiSlice.endpoints.getPortBuilderPortConstruction.initiate(
      portfolioBuilderView.toJsonObject(),
    ),
  );
  const { data, error, isError } = response;
  if (isError) {
    dispatch(getPortBuilderFailed(error));
    dispatch(setLoading(false));
  } else {
    dispatch(getPortBuilerSuccess(data));
    dispatch(setLoading(false));
  }
};
