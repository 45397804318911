import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import DataTable from '../../components/Table/DataTable';

const UrgentemDownloadDisplay = ({
  downloadDataHandler,
  downloadData,
  columns,
  trial,
}) => (
  <Box>
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <div
        style={{
          fontWeight: 'bold',
          fontSize: 18,
          paddingBottom: 10,
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          paddingLeft: 10,
        }}
      >
        Emissions Data Download
      </div>
      {!trial && (
        <Button
          variant="outlined"
          color="primary"
          onClick={downloadDataHandler}
          style={{ marginBottom: 10 }}
          disabled={downloadData?.data && downloadData.data.length === 0}
        >
          Download Data
        </Button>
      )}
    </Box>
    <DataTable
      data={downloadData?.data || []}
      columns={columns}
      tableHeading="DOWNLOAD"
      isScroll={Boolean(downloadData?.data)}
      isTrial={trial}
      isExportEnabled={false}
    />
  </Box>
);

UrgentemDownloadDisplay.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  downloadDataHandler: PropTypes.func.isRequired,
  downloadData: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  trial: PropTypes.bool.isRequired,
};

export default UrgentemDownloadDisplay;
