import React from 'react';
import {
  Grid, FormControl, Select, MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import StackedBar from '../../../components/Charts/StackedBar';
import ColumnChart from '../../../components/Charts/ColumnChart';
import PcafTable from '../../../components/PcafTable';

const PortfolioEmissionDisplay = ({
  selectValue,
  categoriesStack,
  dataStackedBar,
  title,
  yAxisTitle,
  onChange,
  selectValue2,
  dataStackedBar2,
  title2,
  categories,
  dataColumnChart,
  title3,
  pcafData,
}) => (
  <React.Fragment>
    <div style={{ marginTop: '2%' }}>
      <Grid container>
        <Grid item xs={6}>
          <FormControl variant="outlined" style={{ width: '100px' }}>
            <Select
              placeholder="PCAF SCORE"
              value={selectValue}
              style={{ fontSize: 14, width: 300, marginBottom: 20 }}
              disabled={true}
            >
              <MenuItem value="PCAF Score">PCAF Score</MenuItem>
              <MenuItem value="Disclosure Category">
                Disclosure Category
              </MenuItem>
            </Select>
          </FormControl>
          <StackedBar
            categories={categoriesStack}
            data={dataStackedBar}
            title={title}
            yAxisTitle={yAxisTitle}
            xAxisTitle="Scope 1+2 &nbsp &nbsp &nbsp Scope 1+2+3"
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl variant="outlined" style={{ width: '100px' }}>
            <Select
              placeholder="REPORTED"
              style={{ fontSize: 14, width: 300, marginBottom: 20 }}
              onChange={(e) => onChange(e)}
              value={selectValue2}
            >
              <MenuItem value="Reported Vs Inferred">
                Reported Vs Inferred
              </MenuItem>
              <MenuItem value="Public Vs Private">Public Vs Private</MenuItem>
            </Select>
          </FormControl>
          <StackedBar
            categories={categoriesStack}
            data={dataStackedBar2}
            title={title2}
            yAxisTitle={yAxisTitle}
            xAxisTitle={
              'Scope 1+2 &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp Scope 1+2+3'
            }
          />
        </Grid>
      </Grid>
      <PcafTable pcafData={pcafData} />
      <br />
      <ColumnChart
        categories={categories}
        data={dataColumnChart}
        title={title3}
        yAxisTitle={yAxisTitle}
      />
    </div>
  </React.Fragment>
);

PortfolioEmissionDisplay.propTypes = {
  selectValue: PropTypes.any.isRequired,
  categoriesStack: PropTypes.any.isRequired,
  dataStackedBar: PropTypes.any.isRequired,
  chartKey: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  yAxisTitle: PropTypes.string.isRequired,
  onChange: PropTypes.any.isRequired,
  selectValue2: PropTypes.any.isRequired,
  dataStackedBar2: PropTypes.any.isRequired,
  chartKey2: PropTypes.any.isRequired,
  dynamicTitle2: PropTypes.string.isRequired,
  categories: PropTypes.any.isRequired,
  dataColumnChart: PropTypes.any.isRequired,
  chartKeyColumnChart: PropTypes.any.isRequired,
  title2: PropTypes.string.isRequired,
  title3: PropTypes.string.isRequired,
  pcafData: PropTypes.object,
};

export default PortfolioEmissionDisplay;
