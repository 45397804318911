export default class baseRequest {
  constructor(props, benchmarkScore) {
    const {
      currentPortfolio, currentBenchmark, showBenchmark, moduleName,
    } = props;
    this.portfolio_id = benchmarkScore
      ? currentBenchmark.value
      : currentPortfolio.value;
    this.version_portfolio = benchmarkScore
      ? currentBenchmark.version
      : currentPortfolio.version;
    this.benchmark_id = currentBenchmark.value;
    this.version_benchmark = currentBenchmark.version;
    this.benchmark_on = moduleName === 'Reports' ? true : showBenchmark;
  }

  toJsonObject() {
    return { ...this };
  }
}
