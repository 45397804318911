/* eslint-disable import/no-extraneous-dependencies */
import Cookies from 'js-cookie';

const authSessionKey = 'auth';
const appThemeKey = 'appTheme';
const sfThemeKey = 'sfTheme';

const removeSession = () => localStorage.removeItem(authSessionKey);

const readAppTheme = () => localStorage.getItem(appThemeKey);

const setSession = (session) => localStorage.setItem(authSessionKey, JSON.stringify(session));

const readSession = (key) => {
  const value = localStorage.getItem(key);
  return value && value !== 'undefined' ? JSON.parse(value) : null;
};
export const setAppTheme = (theme) => localStorage.setItem(appThemeKey, theme);

export const login = (user) => {
  setSession(user);
};

export const logout = () => {
  removeSession();
  window.location.reload();
};

export const currentSession = () => readSession(authSessionKey);

const updateSession = (accessToken) => {
  const session = currentSession();
  session.access_token = accessToken;
  setSession(session);
};

export const getAccessToken = () => readSession(authSessionKey)?.access_token;

export const getRefreshToken = () => readSession(authSessionKey)?.refresh_token;

export const updateAuthToken = (accessToken) => updateSession(accessToken);

export const currentTheme = readAppTheme();

export const setCookie = () => Cookies.set('embed', 'true');

export const getCookie = () => Cookies.get('embed');

export const removeCookie = () => Cookies.remove('embed');

export const setSupplyCookie = (token) => Cookies.set('auth_token', token);
export const setSFCookie = (theme) => Cookies.set(sfThemeKey, theme);

export const getSFCookie = () => Cookies.get(sfThemeKey);
