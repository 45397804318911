import React from 'react';
import { Switch as Toggle, FormControlLabel, Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setShowBenchmark } from '../../../redux/reducers/authReducer';

const BenchmarkToggle = () => {
  const showBenchmark = useSelector((state) => state.auth.showBenchmark);
  const dispatch = useDispatch();

  return (
    <Box>
      <FormControlLabel
        className="filter-part"
        control={
          <Toggle
            checked={showBenchmark}
            onChange={() => {
              dispatch(setShowBenchmark(!showBenchmark));
            }}
            name=""
            color="primary"
          />
        }
        data-testid="show-benchmark"
        label="Show Benchmark"
      />
    </Box>
  );
};

export default BenchmarkToggle;
