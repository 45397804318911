import React from 'react';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
// import { dispatchRequestApi } from '../../../services/auth';
import { setFilterItemSuccess } from '../../../redux/reducers/filterReducer';

const FilterOnLoadButton = () => {
  const dispatch = useDispatch();
  return (
    <Button
      onClick={() => {
        // dispatch(dispatchRequestApi({ loadData: true }));
        dispatch(setFilterItemSuccess({ key: 'loadData', value: true }));
      }}
      style={{ marginTop: '20px' }}
      fullWidth
    >
      Load Data
    </Button>
  );
};

export default FilterOnLoadButton;
