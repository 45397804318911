/* eslint-disable prefer-const */
import html2pdf from 'html2pdf.js';
import ICELOGO from '../assets/ICE_logo.png';

const addFooterAndPage = (pageNo, footer) => (pdf) => {
  pdf.setPage(pageNo);
  pdf.setFontSize(7);
  pdf.setTextColor(150);
  const img = new Image();
  img.src = ICELOGO;
  img.alt = 'Icelogo';
  pdf.addImage(img, 'PNG', pdf.internal.pageSize.getWidth() - 40, 10, 25, 25);
  pdf.text(
    `${pageNo}`,
    pdf.internal.pageSize.getWidth() / 1.05,
    pdf.internal.pageSize.getHeight() / 1.05,
  );
  pdf.setTextColor(150);
  pdf.setFontSize(6);
  if (footer) {
    pdf.text(
      footer,
      pdf.internal.pageSize.getWidth() / 15,
      pdf.internal.pageSize.getHeight() / 1.05
        - footer.split('\n').length * 7
        - 5,
    );
  }
  pdf.text(
    `Climate Transition Analytics\n © ${new Date().getFullYear()} Intercontinental Exchange, Inc`,
    pdf.internal.pageSize.getWidth() / 15,
    pdf.internal.pageSize.getHeight() / 1.05,
  );
};
const handleDownloadPdf = async (filename, onDone) => {
  const [firstPage, ...otherPages] = Array.from(
    document.querySelectorAll('.pdf_page'),
  );
  const opt = {
    margin: 30,
    quality: 2,
    allowTaint: true,
    filename,
    image: { type: 'jpeg', quality: 2 },
    enableLinks: true,
    html2canvas: {
      logging: false,
      scale: 3.2,
      dpi: 192,
      letterRendering: true,
      getContext: '3d',
      useCORS: true,
    },
    jsPDF: {
      unit: 'pt',
      format: 'a4',
      orientation: 'portrait',
      compressPDF: true,
    },
  };

  let doc = html2pdf()
    .set(opt)
    .from(firstPage)
    .toPdf()
    .get('pdf')
    .then(addFooterAndPage(1, firstPage.dataset.footer));
  otherPages.forEach((page, index) => {
    doc = doc
      .get('pdf')
      .then((pdf) => {
        pdf.addPage();
      })
      .from(page)
      .toContainer()
      .toCanvas()
      .toPdf();
    doc.get('pdf').then(addFooterAndPage(index + 2, page.dataset.footer));
  });
  doc.save().then(onDone);
};

export default handleDownloadPdf;
