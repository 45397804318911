import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
// import { setPortfolio } from '../../../services/auth';
import SelectwithSearch from '../../Autocomplete';
import config from '../config';
import { setPortfolioSuccess } from '../../../redux/reducers/authReducer';

const SelectPortfolio = () => {
  const {
    loading,
    portfolioList: portfolios,
    downloadPortfolioList,
    currentPortfolio,
    tabValue,
    moduleName,
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const path = window.location.pathname;
  const moduleConfig = config.find((obj) => obj.path === path) || {};
  const { moduleTabs } = moduleConfig;
  const filterType = moduleTabs?.find(({ tab }) => tab === tabValue)?.type;
  const portfolioList = moduleName === 'Download' ? downloadPortfolioList : portfolios;

  const onPortfolioChange = async (currentValue) => {
    const portfolio = portfolioList.find((port) => port.label === currentValue);
    if (currentValue && portfolio) dispatch(setPortfolioSuccess(portfolio, filterType));
  };
  return (
    <Box data-testid="select-portfolio">
      <SelectwithSearch
        heading={'Select Portfolio'}
        data={portfolioList}
        defaultValue={currentPortfolio}
        handleChange={onPortfolioChange}
        type="portfolio"
        currentValue={currentPortfolio}
        disabled={loading}
      />
    </Box>
  );
};

export default SelectPortfolio;
