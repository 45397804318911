import BaseRequest from '../baseRequest';

const getScoreType = (scoreType) => {
  if (scoreType === 'shortTerm') {
    return 'short';
  }
  if (scoreType === 'longTerm') {
    return 'long';
  }
  return 'mid';
};

export default class TempCompanyAnalysis extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem } = props;
    const {
      aggregation, sector, emission, defaultValue, scoreType,
    } = filterItem;
    const score = getScoreType(scoreType);

    this.aggregation_method = aggregation;
    this.default_score = defaultValue;
    this.sector_classification = sector;
    this.scope = emission;
    this.term = score;
  }
}
