import { createSlice } from '@reduxjs/toolkit';
// import produce from 'immer';
// import actions from '../actions';
// import * as types from '../actionTypes';

const initialState = {
  data: [],
  metaData: {},
  selectorFields: [
    {
      id: 0,
      name: 'Region',
      filterType: 'region',
      condition: '',
      value: '',
      scope: '',
      filter: '',
      isVisible: false,
      isSelected: false,
      label: 'Select Region',
    },
    {
      id: 1,
      name: 'Country',
      filterType: 'country',
      condition: '',
      value: '',
      scope: '',
      filter: '',
      isVisible: false,
      isSelected: false,
      label: 'Select Country',
    },
    {
      id: 2,
      name: 'Sector',
      filterType: 'ice_sector',
      condition: '',
      value: '',
      scope: '',
      filter: '',
      isVisible: false,
      isSelected: false,
      label: 'Select Sector',
    },
    {
      id: 3,
      name: 'Intensity - Scope 12',
      filterType: 'intensity_sc12',
      condition: '',
      value: '',
      scope: 'sc12',
      filter: '',
      isVisible: false,
      isSelected: false,
      label: 'Select Intensity - Scope 12',
    },
    {
      id: 4,
      name: 'Market Cap',
      filterType: 'market_cap',
      condition: '',
      value: '',
      scope: '',
      filter: '',
      isVisible: false,
      isSelected: false,
      label: 'Select Market Cap',
    },
    {
      id: 5,
      name: 'Disclosure',
      filterType: 'disclosure_sc12',
      condition: '',
      value: '',
      scope: '',
      filter: '',
      isVisible: false,
      isSelected: false,
      label: 'Select Disclosure',
    },
    {
      id: 6,
      name: 'Targets',
      filterType: 'targets',
      condition: '',
      value: '',
      scope: '',
      filter: '',
      isVisible: false,
      isSelected: false,
      label: 'Companies with Targets',
    },
    {
      id: 7,
      name: 'Asset Type',
      filterType: 'security_type',
      condition: '',
      value: '',
      scope: '',
      filter: '',
      isVisible: false,
      isSelected: false,
      label: 'Select Asset Type',
    },
  ],
  // savePortfolioObj: {
  //   name: '',
  //   value: '',
  //   weighted: '',
  // },
  error: '',
  savePortfolioRes: {}, // TODO: Remove
  makeApiCall: false,
};

// export default function portfolioBuilderReducer(
//   state = { ...initialState },
//   action,
// ) {
//   switch (action.type) {
//     case types.GET_PORTFOLIO_BUILDER_SUCCESS:
//       return produce(state, (draft) => {
//         draft.data = action.payload.data;
//         draft.metaData = action.payload.meta_data;
//         draft.error = '';
//       });
//     case types.GET_PORTFOLIO_BUILDER_FAILED:
//       return produce(state, (draft) => {
//         draft.data = [];
//         draft.metaData = {};
//         draft.error = action.error;
//       });
//     case types.SET_PORTFOLIO_BUILDER_FILEDS:
//       return produce(state, (draft) => {
//         draft.selectorFields = action.payload;
//       });
//     case types.SET_SAVE_PORTFOLIO_VALUES:
//       return produce(state, (draft) => {
//         draft.savePortfolioObj = action.payload;
//       });
//     case types.GET_SAVE_PORTFOLIO_SUCCESS:
//       return produce(state, (draft) => {
//         draft.savePortfolioRes.data = action.payload;
//         draft.savePortfolioRes.error = '';
//       });
//     case types.GET_SAVE_PORTFOLIO_FAILED:
//       return produce(state, (draft) => {
//         draft.savePortfolioRes.data = '';
//         draft.savePortfolioRes.error = action.error;
//       });
//     case types.CLEAR_ALL_PORTFOLIO_BUILDER_FILEDS:
//       return produce(state, (draft) => {
//         draft.selectorFields = intialState.selectorFields;
//         draft.makeApiCall = true;
//       });
//     case types.CLEAR_PORTFOLIO_BUILDER_FILEDS:
//       return produce(state, (draft) => {
//         draft.selectorFields[action.payload] = intialState.selectorFields[action.payload];
//         draft.makeApiCall = true;
//       });
//     case types.RESET_MAKE_API_CALL:
//       return produce(state, (draft) => {
//         draft.makeApiCall = false;
//       });
//     case types.SET_PORTFOLIO_BUILDER_VISIBLE_FIELD:
//       return produce(state, (draft) => {
//         const updatedData = state.selectorFields.map((f) => (f.id === action.payload
//           ? {
//             ...f,
//             isVisible: !f.isVisible,
//           }
//           : f));
//         draft.selectorFields = updatedData;
//       });
//     default:
//       return state;
//   }
// }

// const portfolioBuilderReducer = createReducer(initialState, (builder) => {
//   builder
//     .addCase(actions.getPortBuilerSuccess, (state, action) => {
//       state.data = action.payload.data;
//       state.metaData = action.payload.meta_data;
//       state.error = '';
//     })
//     .addCase(actions.getPortBuilderFailed, (state, action) => {
//       state.data = [];
//       state.metaData = {};
//       state.error = action.error;
//     })
//     .addCase(actions.setPortBuilderFields, (state, action) => {
//       state.selectorFields = action.payload;
//     })
//     .addCase(actions.setSavePortValues, (state, action) => {
//       state.savePortfolioObj = action.payload;
//     })
//     .addCase(actions.getSavePortSuccess, (state, action) => {
//       state.savePortfolioRes.data = action.payload;
//       state.savePortfolioRes.error = '';
//     })
//     .addCase(actions.getSavePortFailed, (state, action) => {
//       state.savePortfolioRes.data = '';
//       state.savePortfolioRes.error = action.error;
//     })
//     .addCase(actions.clearAllPortBuilderFields, (state) => {
//       state.selectorFields = initialState.selectorFields;
//       state.makeApiCall = true;
//     })
//     .addCase(actions.clearPortfolioBuilderFields, (state, action) => {
//       state.selectorFields[action.payload] = initialState.selectorFields[action.payload];
//       state.makeApiCall = true;
//     })
//     .addCase(actions.resetMakeApiCall, (state) => {
//       state.makeApiCall = false;
//     })
//     .addCase(actions.setPortBuilderVisibleField, (state, action) => {
//       const updatedData = state.selectorFields.map((f) => (f.id === action.payload
//         ? {
//           ...f,
//           isVisible: !f.isVisible,
//         }
//         : f));
//       state.selectorFields = updatedData;
//     });
// });
// export default portfolioBuilderReducer;

export const portfolioBuilderSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getPortBuilerSuccess: (state, action) => {
      state.data = action.payload.data;
      state.metaData = action.payload.meta_data;
      state.error = '';
    },
    getPortBuilderFailed: (state, action) => {
      state.data = [];
      state.metaData = {};
      state.error = action.payload;
    },
    setPortBuilderFields: (state, action) => {
      state.selectorFields = action.payload;
    },
    setSavePortValues: (state, action) => {
      state.savePortfolioObj = action.payload;
    },
    getSavePortSuccess: (state, action) => {
      state.savePortfolioRes.data = action.payload;
      state.savePortfolioRes.error = '';
    },
    getSavePortFailed: (state, action) => {
      state.savePortfolioRes.data = '';
      state.savePortfolioRes.error = action.error;
    },
    clearAllPortBuilderFields: (state) => {
      state.selectorFields = initialState.selectorFields;
      state.makeApiCall = true;
    },
    clearPortfolioBuilderFields: (state, action) => {
      state.selectorFields[action.payload] = initialState.selectorFields[action.payload];
      state.makeApiCall = true;
    },
    resetMakeApiCall: (state) => {
      state.makeApiCall = false;
    },
    setPortBuilderVisibleField: (state, action) => {
      const updatedData = state.selectorFields.map((f) => (f.id === action.payload
        ? {
          ...f,
          isVisible: !f.isVisible,
        }
        : f));
      state.selectorFields = updatedData;
    },
  },
});

export const {
  getPortBuilerSuccess,
  getPortBuilderFailed,
  setPortBuilderFields,
  setSavePortValues,
  getSavePortSuccess,
  getSavePortFailed,
  clearAllPortBuilderFields,
  clearPortfolioBuilderFields,
  resetMakeApiCall,
  setPortBuilderVisibleField,
} = portfolioBuilderSlice.actions;

export default portfolioBuilderSlice.reducer;
