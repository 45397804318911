/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import handleDownloadPdf from '../../../helpers/downloadPdf';
import useGetPayload from '../../../hooks/useGetPayload';
import './index.css';

import Benchmark from '../../../models/benchmark';
import {
  useGetFlmCompaniesQuery,
  useLazyGetReportBenchMarkQuery,
} from '../../../redux/apiHooks';
import { setFilterItemSuccess } from '../../../redux/reducers/filterReducer';
import { CompanyProfile } from '../../../models/netZero/company';
import sortArrayByKey from '../../../util/sortArrayByKey';
import BenchmarkDisplay from './BenchmarkDisplay';
import { LoadingHOC } from '../../../util/LoadingHOC';

const BenchmarkDisplayWithLoading = LoadingHOC(BenchmarkDisplay);
function transformCompanyList(list) {
  return list.map(({ company_id: companyId, name }) => ({
    value: companyId,
    label: name,
  }));
}
const BenchmarkReport = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [currentSector, setCurrentSector] = useState('');
  const [currentCompany, setCurrentCompany] = useState('');

  const [currentSectorList, setCurrentSectorList] = useState([]);
  const [currentCompanyList, setCurrentCompanyList] = useState([]);

  const { loadData } = useSelector((state) => state.filters);

  const { payload: benchmarkPaylod, auth } = useGetPayload(Benchmark);
  const { payload: flmPayload } = useGetPayload(CompanyProfile);

  const { data: flmData, isFetching: flmFetching } = useGetFlmCompaniesQuery(flmPayload);
  const [benchmarkTrigger, { data: benchmarkData, isFetching, error }] = useLazyGetReportBenchMarkQuery();

  useEffect(() => {
    if (flmData) {
      const sectorList = flmData.sectors;
      let sector = currentSector;
      let company = currentCompany;
      if (!sector || !sectorList.includes(sector)) {
        // eslint-disable-next-line prefer-destructuring
        sector = sectorList[0];
      }

      const companiesList = [...flmData.companiesData[sector]].sort(
        sortArrayByKey('name'),
      );

      if (
        !company
        || companiesList.findIndex((comp) => comp.company_id === company) === -1
      ) {
        company = companiesList[0].company_id;
      }

      // state.benchmarkReport.companyResponse = action.payload;
      setCurrentSectorList(sectorList);
      setCurrentSector(sector);
      setCurrentCompanyList(transformCompanyList(companiesList));
      setCurrentCompany(company);
    }
  }, [flmData]);

  useEffect(() => {
    if (loadData) {
      benchmarkTrigger(
        {
          ...benchmarkPaylod,
          isin: currentCompany,
        },
        true,
      );
      dispatch(setFilterItemSuccess({ key: 'loadData', value: false }));
    }
  }, [loadData]);

  useEffect(() => {
    if (benchmarkData) {
      setButtonDisabled(false);
    }
  }, [benchmarkData]);

  const onDownloadClick = async () => {
    setLoading(true);
    setButtonDisabled(true);
    handleDownloadPdf('Benchmarking Report', () => {
      setLoading(false);
      setButtonDisabled(false);
    });
  };

  const handleCompanyChange = (e) => {
    setCurrentCompany(e.target.value);
  };

  const handleSectorChange = (e) => {
    const sector = e.target.value;
    const companiesList = [...flmData.companiesData[sector]].sort(
      sortArrayByKey('name'),
    );
    const company = companiesList[0].company_id;

    setCurrentSector(sector);
    setCurrentCompany(company);
    setCurrentCompanyList(transformCompanyList(companiesList));
  };

  return (
    <BenchmarkDisplayWithLoading
      error={error}
      loading={isFetching || flmFetching}
      currentSector={currentSector}
      handleSectorChange={handleSectorChange}
      currentSectorList={currentSectorList}
      currentCompany={currentCompany}
      handleCompanyChange={handleCompanyChange}
      currentCompanyList={currentCompanyList}
      onDownloadClick={onDownloadClick}
      buttonDisabled={buttonDisabled}
      downloading={loading}
      data={{
        singleIsin: benchmarkData?.single_isin,
        benchmark: benchmarkData?.benchmark,
        auth,
      }}
    />
  );
};

export default BenchmarkReport;
