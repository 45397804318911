const setDependancyFilters = (
  filterItem,
  key,
  newFilters,
  list,
  isFilterValueAllowed,
  tabData,
) => {
  const filterReduxValue = typeof filterItem === 'object' && !Array.isArray(filterItem)
    ? filterItem[key]
    : filterItem;
  const filterValue = list.options.find((d) => (Array.isArray(filterReduxValue)
    ? filterReduxValue.indexOf(d.value)
    : d.value === filterReduxValue))?.value;
  if (tabData && !tabData?.allow_multiple && Array.isArray(filterReduxValue)) {
    const index = newFilters.findIndex((f) => f.key === key);
    const object = {
      key,
      value:
        // filterReduxValue[0]
        list.options.find((d) => d.value === filterReduxValue[0])?.value
        || list.options.find((d) => d.name === list?.default[0]).value,
    };
    if (index === -1) {
      newFilters.push(object);
    } else {
      newFilters[index] = object;
    }
  } else if (
    tabData
    && tabData?.allow_multiple
    && typeof filterReduxValue === 'string'
  ) {
    const index = newFilters.findIndex((f) => f.key === key);
    const object = {
      key,
      value: [
        list.options.find((d) => d.name === filterReduxValue)?.value
          || list.options.find((d) => d.name === list?.default[0]).value,
      ],
    };
    if (index === -1) {
      newFilters.push(object);
    } else {
      newFilters[index] = object;
    }
  } else if (
    typeof filterReduxValue !== 'object'
    && filterReduxValue !== filterValue
  ) {
    const index = newFilters.findIndex((f) => f.key === key);
    const object = {
      key,
      value: list.options.find((d) => d.name === list?.default[0]).value,
    };
    if (index === -1) {
      newFilters.push(object);
    } else {
      newFilters[index] = object;
    }
  }
  list.options.forEach((option) => {
    if (option.value === filterReduxValue) {
      isFilterValueAllowed.push(option);
    }
    if (
      Array.isArray(filterReduxValue)
      && filterReduxValue.includes(option.value)
    ) {
      isFilterValueAllowed.push(option);
    }
  });
};

export default setDependancyFilters;
