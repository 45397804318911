import React, { useState, useEffect } from 'react';
import { flatten, sortBy } from 'lodash';
import { targetFundCells } from '../../../util/TableHeadConfig';
import { LoadingHOC } from '../../../util/LoadingHOC';
import TargetSettingDisplay from './TargetSettingDisplay';
import TargetSettingModel from '../../../models/FOF/targetSetting';
import useGetPayload from '../../../hooks/useGetPayload';
import {
  useGetFoFTargetSettingQuery,
  useGetUserFundsPortfolioListQuery,
} from '../../../redux/apiHooks';

const TargetSettingDisplayWithLoading = LoadingHOC(TargetSettingDisplay);

const TargetSetting = () => {
  const { payload, filters } = useGetPayload(TargetSettingModel);
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentSector, setSector] = useState('');
  const [isSelected, setSelected] = useState(true);
  const [barChartData, setBarChartData] = useState([]);
  const [yAxisTitle, setYAxisTitle] = useState('');
  const [companies, setCompanies] = useState([]);

  const { data: fundsData } = useGetUserFundsPortfolioListQuery();
  const allPortfolios = fundsData?.allPortfolios || [];

  const { alignmentYear } = filters;

  const {
    data: targetSettingData,
    error,
    isFetching,
  } = useGetFoFTargetSettingQuery(payload);

  const getCategories = (category) => Object.keys(category).map((k) => ({
    name: k,
    categories: ['Allowance', 'Contribution'],
  }));

  const getPortfolioName = (id) => {
    let portName = '';
    if (allPortfolios && allPortfolios.length > 0) {
      allPortfolios.forEach((portfolio) => {
        if (portfolio.portfolio_id === id) {
          portName = portfolio.name;
        }
      });
    }
    return portName;
  };

  // useFundsOfFundsRequest();

  const handleSectorChange = (e) => {
    const sectorName = e.target.value;
    let bChartData = [];
    const tData = [];
    const allowanceValues = [];
    const contribValues = [];
    if (targetSettingData && Object.keys(targetSettingData).length > 0) {
      Object.keys(targetSettingData).forEach((id) => {
        const contrib = targetSettingData[id].portfolio.data.intensity;
        const allowance = targetSettingData[id].allowance.data[alignmentYear];
        const annualRed = targetSettingData[id].reduction.data[alignmentYear];

        allowanceValues.push(allowance[sectorName]);
        contribValues.push(contrib[sectorName]);

        tData.push({
          name: getPortfolioName(id),
          contribution: contrib?.[sectorName] || 0,
          allowance: allowance?.[sectorName] || 0,
          annualRed: annualRed?.[sectorName] || 0,
        });
      });
    }
    bChartData = [
      {
        name: 'Allowance',
        data: allowanceValues,
      },
      {
        name: 'Contribution',
        data: contribValues,
      },
    ];

    setTableData(tData);
    setSector(sectorName);
    setBarChartData(bChartData);
  };

  const getChartData = () => {
    const cData = [];
    let sortedCategories = [];
    const tData = [];
    let title = '';
    let bChartData = [];
    const allowanceValues = [];
    const contribValues = [];
    const comp = [];
    let currSector = '';
    if (targetSettingData && Object.keys(targetSettingData).length > 0) {
      Object.keys(targetSettingData).forEach((id, index) => {
        const contrib = targetSettingData[id].portfolio.data.intensity;
        const allowance = targetSettingData[id].allowance.data[alignmentYear];
        const annualRed = targetSettingData[id].reduction.data[alignmentYear];
        const colors = [
          '#1E2732',
          '#F7DC81',
          '#7d7551',
          '#31d6c9',
          '#bbbfbf',
          '#a0d911',
          '#36cfc9',
          '#40a9ff',
          '#f759ab',
          '#22075e',
        ];
        title = targetSettingData[id].axis_title;
        const res1 = [];
        const res2 = [];
        if (allowance && Object.keys(allowance).length > 0) {
          Object.keys(allowance)
            .sort()
            .forEach((key) => {
              res1.push(allowance[key]);
            });
        }
        if (contrib && Object.keys(contrib).length > 0) {
          Object.keys(contrib)
            .sort()
            .forEach((key) => {
              res2.push(contrib[key]);
            });
        }
        const portfolioData = res1.map((e, i) => [e, res2[i]]);

        cData.push({
          name: getPortfolioName(id),
          data: flatten(portfolioData),
          color: colors[index],
        });
        if (index === 0) {
          const cat = getCategories(contrib);
          sortedCategories = sortBy(cat, (category) => category.name);
          // eslint-disable-next-line prefer-destructuring
          currSector = Object.keys(contrib)[0];
        }

        tData.push({
          name: getPortfolioName(id),
          contribution: contrib?.[currSector] || 0,
          allowance: allowance?.[currSector] || 0,
          annualRed: annualRed?.[currSector] || 0,
        });
        allowanceValues.push(contrib?.[currSector] || 0);
        contribValues.push(allowance?.[currSector] || 0);

        comp.push(getPortfolioName(id));
      });
    }
    bChartData = [
      {
        name: 'Allowance',
        data: allowanceValues,
      },
      {
        name: 'Contribution',
        data: contribValues,
      },
    ];

    setSector(currSector);
    setChartData(cData);
    setCategories(sortedCategories);
    setTableData(tData);
    setYAxisTitle(title);
    setBarChartData(bChartData);
    setCompanies(companies);
  };

  useEffect(() => {
    if (targetSettingData) getChartData();
  }, [targetSettingData, alignmentYear]);

  return (
    <React.Fragment>
      <TargetSettingDisplayWithLoading
        error={error}
        loading={isFetching}
        isSelected={isSelected}
        categories={categories}
        chartData={chartData}
        yAxisTitle={yAxisTitle}
        companies={companies}
        barChartData={barChartData}
        currentSector={currentSector}
        handleSectorChange={handleSectorChange}
        setSelected={setSelected}
        tableData={tableData}
        targetFundCells={targetFundCells}
        chartKey="FUND_TARGET_SETTINGS"
        tableHeading="PORTFOLIO_INTENSITY"
      />
    </React.Fragment>
  );
};

export default TargetSetting;
