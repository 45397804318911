import sortArrayByKey from '../../util/sortArrayByKey';
// eslint-disable-next-line import/no-cycle
import apiSlice, { getQuery } from '../apiSlice';

export const netZeroApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNetZeroDecarbonisation: builder.query({
      query: getQuery.post('forward_looking_metrics/decarbonisation_monitor'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getNetZeroPortfolio: builder.query({
      query: getQuery.post(
        'forward_looking_metrics/portfolio_alignment_better',
      ),
      transformErrorResponse: ({ data }) => data?.message || 'Data not found for given Criteria',
    }),
    getNetZeroSector: builder.query({
      query: getQuery.post('forward_looking_metrics/sector_alignment_better'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getNetZeroSectorSubplot: builder.query({
      query: getQuery.post('forward_looking_metrics/sector_subplots'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    // For company Tab
    getNetZeroSectorCompanies: builder.query({
      query: getQuery.post('forward_looking_metrics/sectors/companies'),
      transformResponse: (response) => ({
        sectors: Object.keys(response).sort(),
        companiesData: Object.keys(response).reduce((acc, sector) => {
          acc[sector] = response[sector]?.sort(sortArrayByKey('name')) || [];
          return acc;
        }, {}),
      }),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getNetZeroCompaniesData: builder.query({
      query: getQuery.post('forward_looking_metrics/company_alignment_better'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getNetZeroCompaniesTableData: builder.query({
      query: getQuery.post('forward_looking_metrics/alignment_table'),
      transformResponse: (response) => response?.sort(sortArrayByKey('company_name')),
      transformErrorResponse: ({ data }) => data?.message,
    }),
  }),
});

export const {
  useGetNetZeroDecarbonisationQuery,
  useLazyGetNetZeroDecarbonisationQuery,
  useGetNetZeroPortfolioQuery,
  useLazyGetNetZeroPortfolioQuery,
  useGetNetZeroSectorQuery,
  useLazyGetNetZeroSectorQuery,
  useGetNetZeroSectorSubplotQuery,
  useLazyGetNetZeroSectorSubplotQuery,
  useGetNetZeroSectorCompaniesQuery,
  useLazyGetNetZeroSectorCompaniesQuery,
  useGetNetZeroCompaniesDataQuery,
  useLazyGetNetZeroCompaniesDataQuery,
  useGetNetZeroCompaniesTableDataQuery,
  useLazyGetNetZeroCompaniesTableDataQuery,
} = netZeroApiSlice;
