/* eslint-disable import/prefer-default-export */
const columnsWithStringType = [
  'ISIN',
  'Company (Short) Name',
  'ICE Uniform Entity Sector',
  'Region',
  'Emissions Year',
  'Disclosure Category',
  'Number of Scope 3 Categories Disclosed',
  'Intensity Average Inference Scope 1 & 2 Total (tCO2e/$m Revenue) - Source',
  'Intensity Average Inference Scope 1, 2 & 3 Total (tCO2e/$m Revenue) - Source',
  'Intensity Average Inference Scope 3 Purchased Goods and Services (tCO2e/$m Revenue) - Source',
  'Intensity Average Inference Scope 3 Capital Goods (tCO2e/$m Revenue) - Source',
  'Intensity Average Inference Scope 3 Fuel and Energy Related Activities (tCO2e/$m Revenue) - Source',
  'Intensity Average Inference Scope 3 Upstream Transport and Distribution (tCO2e/$m Revenue) - Source',
  'Intensity Average Inference Scope 3 Waste Generated (tCO2e/$m Revenue) - Source',
  'Intensity Average Inference Scope 3 Business Travel (tCO2e/$m Revenue) - Source',
  'Intensity Average Inference Scope 3 Employee Commuting (tCO2e/$m Revenue) - Source',
  'Intensity Average Inference Scope 3 Upstream Leased Assets (tCO2e/$m Revenue) - Source',
  'Intensity Average Inference Scope 3 Downstream Transport Distribution (tCO2e/$m Revenue) - Source',
  'Intensity Average Inference Scope 3 Processing of Sold Products (tCO2e/$m Revenue) - Source',
  'Intensity Average Inference Scope 3 Use of Sold Products (tCO2e/$m Revenue) - Source',
  'Intensity Average Inference Scope 3 End of Life Treatment of Sold Products (tCO2e/$m Revenue) - Source',
  'Intensity Average Inference Scope 3 Downstream Leased Assets (tCO2e/$m Revenue) - Source',
  'Intensity Average Inference Scope 3 Franchises (tCO2e/$m Revenue) - Source',
  'Intensity Average Inference Scope 3 Investments (tCO2e/$m Revenue) - Source',
  'Maximum Emissions Intensity Scope 1 & 2 Total (tCO2e/$m Revenue) - Source',
  'Maximum Emissions Intensity Scope 1, 2 & 3 Total (tCO2e/$m Revenue) - Source',
  'Maximum Emissions Intensity Scope 3 Purchased Goods and Services (tCO2e/$m Revenue) - Source',
  'Maximum Emissions Intensity Scope 3 Capital Goods (tCO2e/$m Revenue) - Source',
  'Maximum Emissions Intensity Scope 3 Fuel and Energy Related Activities (tCO2e/$m Revenue) - Source',
  'Maximum Emissions Intensity Scope 3 Upstream Transport and Distribution (tCO2e/$m Revenue) - Source',
  'Maximum Emissions Intensity Scope 3 Waste Generated (tCO2e/$m Revenue) - Source',
  'Maximum Emissions Intensity Scope 3 Business Travel (tCO2e/$m Revenue) - Source',
  'Maximum Emissions Intensity Scope 3 Employee Commuting (tCO2e/$m Revenue) - Source',
  'Maximum Emissions Intensity Scope 3 Upstream Leased Assets (tCO2e/$m Revenue) - Source',
  'Maximum Emissions Intensity Scope 3 Downstream Transport Distribution (tCO2e/$m Revenue) - Source',
  'Maximum Emissions Intensity Scope 3 Processing of Sold Products (tCO2e/$m Revenue) - Source',
  'Maximum Emissions Intensity Scope 3 Use of Sold Products (tCO2e/$m Revenue) - Source',
  'Maximum Emissions Intensity Scope 3 End of Life Treatment of Sold Products (tCO2e/$m Revenue) - Source',
  'Maximum Emissions Intensity Scope 3 Downstream Leased Assets (tCO2e/$m Revenue) - Source',
  'Maximum Emissions Intensity Scope 3 Franchises (tCO2e/$m Revenue) - Source',
  'Maximum Emissions Intensity Scope 3 Investments (tCO2e/$m Revenue) - Source',
  'Reported Emissions Intensity Scope 3 7 Employee Commuting (tCO2e/$m Revenue)',
  'Reported Emissions Intensity Scope 3 8 Upstream Leased Assets (tCO2e/$m Revenue)',
  'Reported Emissions Intensity Scope 3 10 Processing of Sold Products (tCO2e/$m Revenue)',
  'Reported Emissions Intensity Scope 3 11 Use of Sold Products (tCO2e/$m Revenue)',
  'Reported Emissions Intensity Scope 3 12 End of Life Treatment of Sold Products (tCO2e/$m Revenue)',
  'Reported Emissions Intensity Scope 3 13 Downstream Leased Assets (tCO2e/$m Revenue)',
  'Reported Emissions Intensity Scope 3 14 Franchises (tCO2e/$m Revenue)',
  'Reported Emissions Intensity Scope 3 15 Investments (tCO2e/$m Revenue)',
  'Reported Emissions Intensity Scope 3 Other (tCO2e/$m Revenue)',
  'Reported Emissions Scope 3 7 Employee Commuting (tCO2e)',
  'Reported Emissions Scope 3 8 Upstream Leased Assets (tCO2e)',
  'Reported Emissions Scope 3 10 Processing of Sold Products (tCO2e)',
  'Reported Emissions Scope 3 11 Use of Sold Products (tCO2e)',
  'Reported Emissions Scope 3 12 End of Life Treatment of Sold Products (tCO2e)',
  'Reported Emissions Scope 3 13 Downstream Leased Assets (tCO2e)',
  'Reported Emissions Scope 3 14 Franchises (tCO2e)',
  'Reported Emissions Scope 3 15 Investments (tCO2e)',
  'Reported Emissions Scope 3 Other (tCO2e)',
  'Primary Data Source',
  'Reported Emissions Scope 1, 2 & 3 Total (tCO2e/$m Revenue) - Source',
  'Reported Emissions Scope 3 1 Purchased Goods and Services (tCO2e/$m Revenue) - Source',
  'Reported Emissions Scope 3 2 Capital Goods (tCO2e/$m Revenue) - Source',
  'Reported Emissions Scope 3 3 Fuel and Energy Related Activities (tCO2e/$m Revenue) - Source',
  'Reported Emissions Scope 3 4 Upstream Transport and Distribution (tCO2e/$m Revenue) - Source',
  'Reported Emissions Scope 3 5 Waste Generated (tCO2e/$m Revenue) - Source',
  'Reported Emissions Scope 3 6 Business Travel (tCO2e/$m Revenue) - Source',
  'Reported Emissions Scope 3 7 Employee Commuting (tCO2e/$m Revenue) - Source',
  'Reported Emissions Scope 3 8 Upstream Leased Assets (tCO2e/$m Revenue) - Source',
  'Reported Emissions Scope 3 9 Downstream Transport Distribution (tCO2e/$m Revenue) - Source',
  'Reported Emissions Scope 3 10 Processing of Sold Products (tCO2e/$m Revenue) - Source',
  'Reported Emissions Scope 3 11 Use of Sold Products (tCO2e/$m Revenue) - Source',
  'Reported Emissions Scope 3 12 End of Life Treatment of Sold Products (tCO2e/$m Revenue) - Source',
  'Reported Emissions Scope 3 13 Downstream Leased Assets (tCO2e/$m Revenue) - Source',
  'Reported Emissions Scope 3 14 Franchises (tCO2e/$m Revenue) - Source',
  'Reported Emissions Scope 3 15 Investments (tCO2e/$m Revenue) - Source',
];

export { columnsWithStringType };
