import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import AlignmentDescription from '../../../components/AlignmentDescription';
import LineChart from '../../../components/Charts/LineChart';

const PortfolioAlignmentDisplay = ({
  tempScore,
  alignmentData,
  cumulativeData,
  techData,
  alignmentMeta,
  cumulativeMeta,
  techMeta,
  emissionYear,
}) => (
  <React.Fragment>
    <Box>
      <div className="revert" style={{ paddingLeft: 16 }}>
        <h2>Portfolio Alignment Analysis</h2>
        <p>
          Implied Temperature Rise: <strong>{tempScore} °C</strong>
        </p>
      </div>
      <Grid container>
        <Grid item xs={6}>
          <LineChart
            data={alignmentData}
            title={alignmentMeta.title}
            yAxisTitle={alignmentMeta.units}
            emissionYear={emissionYear}
            xAxisType="datetime"
            markerEnabled={false}
          />
        </Grid>
        <Grid item xs={6}>
          <LineChart
            data={cumulativeData}
            title={cumulativeMeta.title}
            yAxisTitle={cumulativeMeta.units}
            emissionYear={emissionYear}
            xAxisType="datetime"
            markerEnabled={false}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <LineChart
            data={techData}
            title={techMeta.title}
            yAxisTitle={techMeta.units}
            xAxisType="datetime"
            markerEnabled={false}
          />
        </Grid>
        <Grid item xs={6}>
          <AlignmentDescription tempScore={tempScore} />
        </Grid>
      </Grid>
    </Box>
  </React.Fragment>
);

PortfolioAlignmentDisplay.propTypes = {
  alignmentData: PropTypes.array.isRequired,
  cumulativeData: PropTypes.array.isRequired,
  techData: PropTypes.array.isRequired,
  tempScore: PropTypes.string.isRequired,
  alignmentMeta: PropTypes.object.isRequired,
  cumulativeMeta: PropTypes.object.isRequired,
  techMeta: PropTypes.object.isRequired,
  emissionYear: PropTypes.number.isRequired,
};

export default PortfolioAlignmentDisplay;
