import React, { useState, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import filterConfig from '../../../util/filter-config';
import { avoidedEmissionCells } from '../../../util/TableHeadConfig';
import { LoadingHOC } from '../../../util/LoadingHOC';
import AvoidedEmissionDisplay from './AvoidedEmissionDisplay';
import AvoidedEmissionsModel from '../../../models/emissions/avoidedEmissions';
import { useGetEmissionAvoidedEmissionsQuery } from '../../../redux/apiHooks';
import useGetPayload from '../../../hooks/useGetPayload';

const AvoidedEmissionsWithLoading = LoadingHOC(AvoidedEmissionDisplay);

const categoriesMap = {
  sc12: 'Scope 1+2',
  sc3: 'Scope 3',
  sc123: 'Scope 1+2+3',
  avoided_emissions: 'Avoided Emissions',
};

const AvoidedEmission = () => {
  const { auth, filters, payload } = useGetPayload(AvoidedEmissionsModel);
  const { data: avoidedEmissionData, isFetching } = useGetEmissionAvoidedEmissionsQuery(payload);

  const { userInfo, showBenchmark } = auth;
  const { footprintMetric } = filters;

  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [yAxisTitle, setYAxisTitle] = useState('');
  const [avoidedData, setDonutChartData] = useState([]);
  const [avoidedPercentage, setAvoidedPercentage] = useState([]);

  const trial = get(userInfo, 'trial', false);

  const getTableData = () => {
    const intensityData = [
      {
        name: 'Portfolio Avg Intensity',
        sc12: 0,
        sc3: 0,
        sc123: 0,
        avoided_emissions: 0,
      },
    ];

    if (showBenchmark) {
      intensityData.push({
        name: 'Benchmark Avg Intensity',
        sc12: 0,
        sc3: 0,
        sc123: 0,
        avoided_emissions: 0,
      });
    }

    if (!isEmpty(avoidedEmissionData)) {
      const portData = avoidedEmissionData.portfolio.data;

      if (portData) {
        Object.keys(portData).forEach((key) => {
          intensityData[0][key] = portData[key];
        });
      }
      if (showBenchmark) {
        const benchData = avoidedEmissionData.benchmark.data;
        if (benchData) {
          Object.keys(benchData).forEach((key) => {
            intensityData[1][key] = benchData[key];
          });
        }
      }
    }
    setTableData(intensityData);
  };

  const getEmissionChartData = () => {
    const emissionChartData = [
      {
        name: 'Portfolio',
        data: [],
      },
    ];

    if (showBenchmark) {
      emissionChartData.push({
        name: 'Benchmark',
        data: [],
      });
    }

    if (!isEmpty(avoidedEmissionData)) {
      const title = avoidedEmissionData.portfolio?.metadata?.chart_label || '';
      setYAxisTitle(title);
      const portData = avoidedEmissionData.portfolio.data;
      if (portData) {
        Object.keys(categoriesMap).forEach((key) => {
          emissionChartData[0].data.push(portData[key]);
        });
      }
      if (showBenchmark) {
        const benchData = avoidedEmissionData.benchmark.data;
        if (benchData) {
          Object.keys(categoriesMap).forEach((key) => {
            emissionChartData[1].data.push(benchData[key]);
          });
        }
      }
    }
    setChartData(emissionChartData);
  };

  const getDountChartData = () => {
    const avoided = avoidedEmissionData?.avoided_emission || {};
    const { data = [], percentage = 0 } = avoided;
    const avoidedEmisionData = data
      && data.map((av) => ({
        name: av.category,
        y: av.sum,
        additionalData: av.description,
      }));
    setDonutChartData(avoidedEmisionData);
    setAvoidedPercentage(percentage);
  };

  useEffect(() => {
    if (avoidedEmissionData) {
      getEmissionChartData();
      getTableData();
      getDountChartData();
    }
  }, [avoidedEmissionData]);

  const fpMetricOptions = filterConfig[0].tagsList;
  let metric = '';

  if (fpMetricOptions && fpMetricOptions.length > 0) {
    fpMetricOptions.forEach((option) => {
      if (option.value === footprintMetric) {
        metric = option.name;
      }
    });
  }
  const chartTitle = `Portfolio Intensity - ${metric}`;
  return (
    <AvoidedEmissionsWithLoading
      yAxisTitle={yAxisTitle}
      chartTitle={chartTitle}
      columns={avoidedEmissionCells}
      categories={Object.values(categoriesMap)}
      horizontalBarData={chartData}
      dataTableData={tableData}
      tableHeading="AVOIDED_EMISSIONS"
      isTrial={trial}
      avoidedData={avoidedData}
      avoidedPercentage={avoidedPercentage}
      loading={isFetching}
    />
  );
};
export default AvoidedEmission;
