/* eslint-disable camelcase */
import { isEmpty } from 'lodash';
import { CATEGORIES_MAP, pcafCategoryMap } from '../constants';
import { SCOPE_12_TOTAL } from '../components/FilterSection/constants';
import { getCategoryKeyForScope3, getChartDataFromObject } from '../helpers';
import colorSchema from '../Themes/config';

export function getEmissionFootprint(portfolio, benchmark, emission) {
  if (portfolio && benchmark) {
    let categoriesMap;
    if (emission === 'Sc12') {
      categoriesMap = {
        sc12: 'Scope 1+2',
        avoided_emissions: 'Avoided Emissions',
      };
    } else {
      categoriesMap = {
        sc12: 'Scope 1+2',
        sc3: 'Scope 3',
        sc123: 'Scope 1+2+3',
        avoided_emissions: 'Avoided Emissions',
      };
    }
    const data = [
      {
        name: 'Portfolio',
        data: Object.keys(categoriesMap).map(
          (category) => portfolio.data[category],
        ),
      },
      {
        name: 'Benchmark',
        data: Object.keys(categoriesMap).map(
          (category) => benchmark.data[category],
        ),
      },
    ];

    return {
      categories: Object.values(categoriesMap),
      data,
      yAxisTitle: portfolio?.metadata?.chart_label,
    };
  }
  return { data: [], yAxisTitle: '', categories: [] };
}

export function getEmissionBreakdown(portfolio) {
  if (portfolio) {
    return {
      categories: [''],
      data: portfolio.avoided_emission.data.map(({ category, sum }) => ({
        name: category,
        data: [sum],
      })),
      yAxisTitle: 'Avoided Emissions (%)',
    };
  }
  return { data: [], yAxisTitle: '', categories: [''] };
}

export function getAvoidedEmission(portfolio) {
  if (portfolio) {
    return {
      categories: [''],
      yAxisTitle: 'Avoided Emissions (%)',
      data: [
        {
          name: 'Avoided Emissions - Reported',
          data: [portfolio.avoided_emission.percentage],
        },
        {
          name: 'Avoided Emissions - Not Reported',
          data: [portfolio.avoided_emission.non_reported_percentage],
        },
      ],
    };
  }
  return { data: [], yAxisTitle: '', categories: [''] };
}

export function getAvoidedEmissionTable(portfolio, benchmark) {
  return [
    { ...portfolio?.data, ...portfolio?.metadata },
    { ...benchmark?.data, ...benchmark?.metadata },
  ];
}

export function getcarbonDisclosure(portfolio, benchmark) {
  if (portfolio && benchmark) {
    const categoryOrder = [
      'Public, complete Scope 1 and 2 data, third-party assurance.',
      'Public, complete Scope 1 and 2 data, no third-party assurance.',
      'Incomplete Scope 1 and 2 data, no third-party assurance.',
      'No public data.',
      'Not directly analysed.',
    ];
    const portfolioMap = new Map();
    portfolio.sc12.data.forEach(({ category, value }) => {
      portfolioMap.set(category, value);
    });

    const benchmarkMap = new Map();
    benchmark.sc12.data.forEach(({ category, value }) => {
      benchmarkMap.set(category, value);
    });

    const data = categoryOrder.map((category, key) => ({
      name: `Category ${key + 1}: ${category} `,
      data: [portfolioMap.get(category) || 0, benchmarkMap.get(category) || 0],
    }));
    return {
      data,
      categories: ['Portfolio', 'Benchmark'],
      yAxisTitle: 'in Percentage (%)',
      xAxisTitle: 'Disclosure',
    };
  }
  return {
    data: [],
    categories: [],
    yAxisTitle: '',
    xAxisTitle: '',
  };
}

export function getCarbonIntensity(portfolio, benchmark, emission) {
  if (portfolio && benchmark) {
    let data;
    if (emission === 'Sc12') {
      data = [
        {
          name: 'Portfolio',
          data: [
            {
              name: CATEGORIES_MAP.sc12,
              y: portfolio.data.sc12,
            },
          ],
        },
        {
          name: 'Benchmark',
          data: [
            {
              name: CATEGORIES_MAP.sc12,
              y: benchmark.data.sc12,
            },
          ],
        },
      ];
    } else {
      data = [
        {
          name: 'Portfolio',
          data: Object.entries(portfolio.data).map(([key, value]) => ({
            name: CATEGORIES_MAP[key],
            y: value,
          })),
        },
        {
          name: 'Benchmark',
          data: Object.entries(benchmark.data).map(([key, value]) => ({
            name: CATEGORIES_MAP[key],
            y: value,
          })),
        },
      ];
    }
    const { label, unit } = portfolio.metadata;
    const yAxisTitle = `${label.split(' ').slice(-2).join(' ')} ${unit}`;
    return {
      data,
      yAxisTitle,
      categories:
        emission === 'Sc12' ? ['Scope 1+2'] : Object.values(CATEGORIES_MAP),
    };
  }
  return { data: [], yAxisTitle: '', categories: [] };
}

const calculatePcaf = (score) => Math.round(score * 100 + Number.EPSILON) / 100;
export function getPcaf(portfolio, benchmark, emission) {
  if (portfolio && benchmark) {
    const data = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 5; i++) {
      let bSum123 = 0;
      let bSum12 = 0;
      let pSum123 = 0;
      let pSum12 = 0;

      benchmark.data[`PCAF_score_${i}`].forEach((sc) => {
        bSum123 += sc.sc123;
        bSum12 += sc.sc12;
      });
      portfolio.data[`PCAF_score_${i}`].forEach((sc) => {
        pSum123 += sc.sc123;
        pSum12 += sc.sc12;
      });

      data.push({
        name: pcafCategoryMap.get(i - 1),
        data:
          emission === 'Sc12'
            ? [bSum12, pSum12]
            : [bSum123, pSum123, bSum12, pSum12].map(calculatePcaf),
      });
    }
    const { label, units } = portfolio.metadata;
    const yAxisTitle = `${label.split(' ').slice(-2).join(' ')} ${units}`;
    return {
      data,
      yAxisTitle,
      xAxisTitle:
        emission === 'Sc12'
          ? 'Scope 1+2'
          : 'Scope 1+2 &nbsp;&nbsp;&nbsp;&nbsp;Scope 1+2+3',
      categories:
        emission === 'Sc12'
          ? ['Benchmark', 'Portfolio']
          : ['Benchmark', 'Portfolio', 'Benchmark', 'Portfolio'],
    };
  }
  return {
    data: [],
    yAxisTitle: '',
    xAxisTitle: '',
    categories: [],
  };
}

export function getSc12Pcaf(portfolio, benchmark) {
  if (portfolio && benchmark) {
    const data = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 5; i++) {
      let bSum12 = 0;
      let pSum12 = 0;

      benchmark.data[`PCAF_score_${i}`].forEach((sc) => {
        bSum12 += sc.sc12;
      });
      portfolio.data[`PCAF_score_${i}`].forEach((sc) => {
        pSum12 += sc.sc12;
      });

      data.push({
        name: pcafCategoryMap.get(i - 1),
        data: [pSum12, bSum12].map(calculatePcaf),
      });
    }
    const { label, units } = portfolio.metadata;
    const yAxisTitle = `${label.split(' ').slice(-2).join(' ')} ${units}`;
    return {
      data,
      yAxisTitle,
      categories: ['Corporate', 'Industry Benchmark'],
    };
  }
  return { data: [], yAxisTitle: '', categories: [] };
}

export function getCarbonAttributionAnalysis(portfolio) {
  if (portfolio) {
    let categories = [];
    // TO-DO: Can simplify using entries and reduce
    const data = Object.keys(portfolio.data || {}).map((name) => {
      let values = [];
      values = Object.values(portfolio.data[name]);
      categories = Object.keys(portfolio.data[name]);
      return {
        name: name.replaceAll('_', ' '),
        data: values,
      };
    });
    const yAxisTitle = `GHG Intensity (tCO2e)/${
      portfolio?.metadata?.label?.split('/')[1] || ''
    }`;
    return {
      data,
      yAxisTitle,
      categories: categories.map((c) => c.replace(/(\S+\s*){1,2}/g, '$&<br />')),
    };
  }
  return { data: [], yAxisTitle: '', categories: [] };
}

export function getCarbonIntensityTable(portfolio, benchmark) {
  return [
    {
      name: 'Porfolio',
      sc12: 0,
      sc3: 0,
      sc123: 0,
      ...portfolio?.data,
    },
    {
      name: 'Benchmark',
      sc12: 0,
      sc3: 0,
      sc123: 0,
      ...benchmark?.data,
    },
  ];
}

export function getPcafTable(pcafChartData, emission) {
  if (emission === 'Sc12') {
    return pcafChartData.map(({ name, data: [bsc12, psc12] }) => ({
      name: name.split(':')[0],
      psc12,
      bsc12,
    }));
  }
  return pcafChartData.map(
    ({ name, data: [bsc123, psc123, bsc12, psc12] }) => ({
      name: name.split(':')[0],
      bsc123,
      psc123,
      psc12,
      bsc12,
    }),
  );
}

export function getSc12PcafTable(pcafChartData) {
  return pcafChartData.map(({ name, data: [psc12, bsc12] }) => ({
    name: name.split(':')[0],
    psc12,
    bsc12,
  }));
}

export function getReservesFootprint(portfolio, benchmark) {
  if (portfolio && benchmark) {
    const data = [
      {
        name: 'Portfolio',
        data: [
          portfolio.data.portfolio.gas,
          portfolio.data.portfolio.oil,
          portfolio.data.portfolio.coal,
        ],
      },
      {
        name: 'Benchmark',
        data: [
          benchmark.data.benchmark.gas,
          benchmark.data.benchmark.oil,
          benchmark.data.benchmark.coal,
        ],
      },
    ];
    const categories = ['Gas', 'Oil', 'Coal'];
    const yAxisTitle = `GHG Intensity (tCO2e)/${
      portfolio?.metadata?.label.split('/')[1] || ''
    }`;
    return { data, categories, yAxisTitle };
  }
  return { data: [], yAxisTitle: '', categories: [] };
}

export function getContributionBreakdown(portfolio, theme) {
  if (portfolio) {
    const countryData = Object.entries(portfolio.data.table.Percentage)
      .map(([name, y], key) => ({
        name,
        y,
        color: colorSchema[theme].chartColors[key],
      }))
      .filter(Boolean);
    let data = [];
    let categories = [];
    const yAxisTitle = 'Contribution to total footprint (%)';
    if (countryData.length > 0) {
      data = [{ name: 'Country', data: countryData }];
      categories = countryData.map(({ name }) => name);
    }
    return { data, categories, yAxisTitle };
  }
  return { data: [], yAxisTitle: '', categories: [] };
}

export function getReservesFootprintTable(portfolio, benchmark) {
  const portfolioData = portfolio?.data?.portfolio || {};
  const benchmarkData = benchmark?.data?.benchmark || {};
  return [
    {
      type: 'Coal',
      portfolio: portfolioData.coal || 0,
      benchmark: benchmarkData.coal || 0,
    },
    {
      type: 'Oil',
      portfolio: portfolioData.oil || 0,
      benchmark: benchmarkData.oil || 0,
    },
    {
      type: 'Gas',
      portfolio: portfolioData.gas || 0,
      benchmark: benchmarkData.gas || 0,
    },
  ];
}

export function getContributionBreakdownTable(portfolio) {
  return Object.entries(portfolio?.data?.table?.Percentage || {}).map(
    ([country, value]) => ({ country, value }),
  );
}

export function getDivergenceModel(portfolio) {
  if (portfolio) {
    const alignmentData = portfolio?.data || {};
    const yAxisTitle = portfolio?.metadata?.units || '';
    const data = [
      {
        name: 'Estimated Emissions Trajectory',
        data: getChartDataFromObject(alignmentData.expected),
      },
      {
        name: 'Net Zero 2050',
        data: getChartDataFromObject(alignmentData['Net Zero 2050']),
      },
      {
        name: 'Delayed Transition',
        data: getChartDataFromObject(alignmentData['Delayed transition']),
      },
      {
        name: 'Current Policies',
        data: getChartDataFromObject(alignmentData['Current Policies']),
      },
    ];
    return { data, yAxisTitle };
  }
  return { data: [], yAxisTitle: '' };
}

export function getTemperatureScore(portfolio, benchmark, theme) {
  if (portfolio && benchmark) {
    const data = [
      {
        name: 'Temp Score',
        data: [
          {
            y: portfolio?.implied_temp || 0,
            color: colorSchema[theme].chartColors[0],
          },
          {
            y: benchmark.implied_temp || 0,
            color: colorSchema[theme].chartColors[1],
          },
        ],
      },
    ];
    return {
      data,
      categories: ['Portfolio', 'Benchmark'],
      yAxisTitle: 'Temperature Score<br /> (degrees C)',
    };
  }
  return { data: [], yAxisTitle: '', categories: [] };
}

export function getCumlativeEmissionsTable(portfolio) {
  if (portfolio) {
    const alignmentData = portfolio?.data || {};
    return [
      {
        name: 'Estimated Emissions Trajectory',
        2030: alignmentData.expected[2030],
        2050: alignmentData.expected[2050],
      },
      {
        name: 'Net Zero 2050',
        2030: alignmentData['Net Zero 2050'][2030],
        2050: alignmentData['Net Zero 2050'][2050],
      },
      {
        name: 'Delayed Transition',
        2030: alignmentData['Delayed transition'][2030],
        2050: alignmentData['Delayed transition'][2050],
      },
      {
        name: 'Current Policies',
        2030: alignmentData['Current Policies'][2030],
        2050: alignmentData['Current Policies'][2050],
      },
    ];
  }
  return [];
}

export function getBinaryTargetMeasure(portfolio) {
  if (portfolio) {
    const { sbti_validated, targets } = portfolio;

    const barData = [
      {
        name: 'Companies with SBTI Validated Targets',
        data: [sbti_validated],
      },
      {
        name: 'Companies with non-SBTi Validated Targets',
        data: [Math.round((targets - sbti_validated) * 100) / 100],
      },
      {
        name: 'No targets',
        data: [Math.round((100 - targets) * 100) / 100],
      },
    ];

    return barData;
  }
  return [];
}

export function getHeatmapChartData(portfolio) {
  if (portfolio) {
    const { sectors = [], data = [] } = portfolio;

    const categories = [];
    for (let i = 0; i < 15; i += 1) {
      categories.push(`cat ${i + 1}`);
    }

    const chartData = data.reduce((acc, { category, sector, contribution }) => {
      if (!categories.includes('total 1 + 2') && category === SCOPE_12_TOTAL) {
        categories.unshift('total 1 + 2');
      }
      const categoryKey = getCategoryKeyForScope3(category, 'Sc123');
      if (categoryKey === 'N/A') return acc;
      acc.push([
        categoryKey,
        sectors.indexOf(sector),
        parseFloat(contribution.toFixed(2)),
      ]);
      return acc;
    }, []);

    return {
      data: chartData,
      xAxisCategories: categories,
      yAxisCategories: sectors,
    };
  }
  return { data: [], xAxisCategories: [], yAxisCategories: [] };
}

export function getSectorData(portfolio, benchmark) {
  if (portfolio && benchmark) {
    const agg = {};
    Object.entries(portfolio.data).forEach(([key, value]) => {
      agg[key] = {
        portfolio: typeof value === 'object' ? value.sc123 : value,
      };
    });

    Object.entries(benchmark.data).forEach(([key, value]) => {
      agg[key] = {
        ...agg[key],
        benchmark: typeof value === 'object' ? value.sc123 : value,
      };
    });

    const categories = Object.keys(agg).sort();
    const data = [
      {
        name: 'Portfolio',
        data: categories.map((category) => agg[category].portfolio || 0),
      },
      {
        name: 'Benchmark',
        data: categories.map((category) => agg[category].benchmark || 0),
      },
    ];
    return {
      data,
      categories,
      yAxisTitle: `${portfolio.metadata.label} ${portfolio.metadata.units}`,
    };
  }
  return { data: [], yAxisTitle: '', categories: [] };
}

export function getEmissionsBreakdown(portfolio, benchmark) {
  if (portfolio) {
    const EMISSION_BREAKDOWN_CATEGORIES = {
      Consumption: 'consumption',
      Import: 'imported',
      Export: 'exported',
      Production: 'production',
    };
    const categoriesMap = Object.entries(EMISSION_BREAKDOWN_CATEGORIES);
    const data = [
      {
        name: 'Portfolio',
        data: categoriesMap.map(([key, value]) => ({
          name: key,
          y: portfolio.portfolio.data.portfolio[value],
        })),
      },
      {
        name: 'Benchmark',
        data: categoriesMap.map(([key, value]) => ({
          name: key,
          y: benchmark ? benchmark.portfolio.data.portfolio[value] : [],
        })),
      },
    ];
    return {
      data,
      categories: Object.keys(EMISSION_BREAKDOWN_CATEGORIES),
      yAxisTitle: `${portfolio.portfolio.metadata.label} ${portfolio.portfolio.metadata.unit}`,
    };
  }
  return { data: [], categories: [], yAxisTitle: '' };
}

export function getEmissionsByContribution(portfolio, benchmark) {
  if (portfolio) {
    const contributionCategories = new Set();
    const data = [
      {
        name: 'Portfolio',
        data: Object.entries(
          portfolio.country_contributions.data.production,
        ).map(([key, value]) => {
          contributionCategories.add(key);
          return { name: key, y: value };
        }),
      },
      {
        name: 'Benchmark',
        data: benchmark
          ? Object.entries(benchmark.country_contributions.data.production).map(
            ([key, value]) => {
              contributionCategories.add(key);
              return { name: key, y: value };
            },
          )
          : [],
      },
    ];
    const { label, units } = portfolio.country_contributions.metadata;

    return {
      data,
      categories: [...contributionCategories],
      yAxisTitle: `${label} ${units}`,
    };
  }
  return { data: [], categories: [], yAxisTitle: '' };
}

export function getEmissionsByWeight(portfolio, benchmark) {
  if (portfolio) {
    const weightsCategories = new Set();

    const data = [
      {
        name: 'Portfolio',
        data: Object.entries(portfolio.country_weights.data.production).map(
          ([key, value]) => {
            weightsCategories.add(key);
            return { name: key, y: value };
          },
        ),
      },
      {
        name: 'Benchmark',
        data: benchmark
          ? Object.entries(benchmark.country_weights.data.production).map(
            ([key, value]) => {
              weightsCategories.add(key);
              return { name: key, y: value };
            },
          )
          : [],
      },
    ];

    const { label, units } = portfolio.country_weights.metadata;

    return {
      data,
      categories: [...weightsCategories],
      yAxisTitle: `${label} ${units}`,
    };
  }
  return { data: [], categories: [], yAxisTitle: '' };
}

export function getLatestYearObject(data) {
  const latestYear = Object.keys(data)
    .map((value) => parseInt(value, 10))
    .sort()
    .reverse()[0];
  return data[`${latestYear}`];
}

export function getComparativeSc12Emisssions(company, benchmark) {
  const companyLatestYear = company
    ? getLatestYearObject(company.Revenue.data)
    : {};
  const benchmarkLatestYear = benchmark
    ? getLatestYearObject(benchmark.Revenue.data)
    : {};
  const data = [
    {
      name: 'Corporate',
      data: [companyLatestYear.sc12, companyLatestYear.sc123],
    },
    {
      name: 'Industry Benchmark',
      data: [benchmarkLatestYear.sc12, benchmarkLatestYear.sc123],
    },
  ];
  const { metadata } = company.Revenue;
  return {
    data,
    categories: ['Scope 1 + 2', 'Scope 1 + 2 + 3'],
    yAxisTitle: `${metadata.label.split(' ').slice(-2).join(' ')} ${
      metadata.unit
    }`,
  };
}

export function getComparativeSc12EmisssionsTable(company, benchmark) {
  const companyLatestYear = company
    ? getLatestYearObject(company.Revenue.data)
    : {};
  const benchmarkLatestYear = benchmark
    ? getLatestYearObject(benchmark.Revenue.data)
    : {};
  return [
    { name: 'Corporate', ...companyLatestYear },
    { name: 'Industry Benchmark', ...benchmarkLatestYear },
  ];
}

export function getShareofSc12Emissions(company, theme) {
  return {
    data: [
      {
        name: 'Scope 1+2',
        y: parseFloat(company?.sc12?.toFixed(2)),
        color: colorSchema[theme].chartColors[0],
      },
      {
        name: 'Scope 3',
        y: parseFloat(company?.sc3?.toFixed(2)),
        color: colorSchema[theme].chartColors[1],
      },
    ],
  };
}

export function createDisclosureMap(data) {
  const dataMap = new Map();
  data.forEach(({ category, value }) => {
    dataMap.set(category, value);
  });
  return dataMap;
}

export function getCarbonDisclosureQuality(company, benchmark) {
  if (company) {
    const companyMap = createDisclosureMap(company.sc12.data);
    const benchmarkMap = createDisclosureMap(benchmark.sc12.data);
    const { metadata } = company.sc12;
    return {
      categories: ['Corporate', 'Industry Benchmark'],
      data: [...benchmarkMap.keys()].map((name) => ({
        name,
        data: [companyMap.get(name) || 0, benchmarkMap.get(name) || 0],
      })),
      yAxisTitle: metadata.unit,
    };
  }
  return {
    categories: [],
    data: [],
    yAxisTitle: '',
  };
}

export function getSc12HistoricalEmissions(company, benchmark) {
  if (company) {
    const data = [
      {
        name: 'Corporate',
        data: Object.entries(company.Revenue.data).map(([key, value]) => [
          Date.UTC(key, '01', '01'),
          value.sc12,
        ]),
      },
      {
        name: 'Industry Benchmark',
        data: Object.entries(benchmark.Revenue.data).map(([key, value]) => [
          Date.UTC(key, '01', '01'),
          value.sc12,
        ]),
      },
    ];
    const { metadata } = company.Revenue;
    return {
      data,
      yAxisTitle: `${metadata.label.split(-3).join(' ')} ${metadata.unit}`,
      xAxisTitle: '',
    };
  }
  return { data: [], yAxisTitle: '' };
}

export function getSc12HistoricalEmissionsTable(company, benchmark) {
  const dataMap = new Map();
  if (company) {
    Object.entries(company.Revenue.data).forEach(([key, value]) => {
      dataMap.set(key, { corporate: value.sc12 });
    });
  }

  if (benchmark) {
    Object.entries(benchmark.Revenue.data).forEach(([key, value]) => {
      dataMap.set(key, { ...dataMap.get(key), benchmark: value.sc12 });
    });
  }
  return [...dataMap.entries()].map(([year, obj]) => ({ year, ...obj }));
}

export function getComparativeSc3Emisssions(company, benchmark) {
  const companyLatestYear = company
    ? getLatestYearObject(company.Revenue.data)
    : {};
  const benchmarkLatestYear = benchmark
    ? getLatestYearObject(benchmark.Revenue.data)
    : {};
  const data = [
    {
      name: 'Corporate',
      data: [companyLatestYear.sc3],
    },
    {
      name: 'Industry Benchmark',
      data: [benchmarkLatestYear.sc3],
    },
  ];
  const { metadata } = company.Revenue;
  return {
    data,
    categories: ['Scope 3'],
    yAxisTitle: `${metadata.label.split(' ').slice(-2).join(' ')} ${
      metadata.unit
    }`,
  };
}

export function getComparativeSc3EmisssionsTable(company, benchmark) {
  const companyLatestYear = company
    ? getLatestYearObject(company.Revenue.data)
    : {};
  const benchmarkLatestYear = benchmark
    ? getLatestYearObject(benchmark.Revenue.data)
    : {};
  return [
    { name: 'Corporate', ...companyLatestYear },
    { name: 'Industry Benchmark', ...benchmarkLatestYear },
  ];
}

export function getShareofSc3Emissions(company, theme) {
  return {
    data: [
      {
        name: 'Max',
        y: parseFloat(company?.max?.toFixed(2)),
        color: colorSchema[theme].chartColors[0],
      },
      {
        name: 'Other',
        y: parseFloat(company?.other?.toFixed(2)),
        color: colorSchema[theme].chartColors[1],
      },
    ],
  };
}

const defaultChartData = {
  type: 'line',
  marker: {
    enabled: false,
  },
  enableMouseTracking: true,
  // add: name
  // add: data
};

const SECTOR_SUBPLOT_LEGENDS = {
  expected: 'Estimated Emissions Tragectory',
  SCENARIO_ALIGNED_EMISSIONS_PATHWAY: 'Scenario Aligned Emissions Pathway',
  'Below 2°C': 'Below 2°C - Aligned Pathway',
  'Current Policies': 'Current Policies - Aligned Pathway',
  'Delayed transition': 'Delayed transition - Aligned Pathway',
  'Divergent Net Zero': 'Divergent Net Zero - Aligned Pathway',
  'Nationally Determined Contributions (NDCs)':
    'Nationally Determined Contributions (NDCs) - Aligned Pathway',
  'Net Zero 2050': 'Net Zero 2050 - Aligned Pathway',
};

const WARMING_SCENARIO = ['Net Zero 2050'];
export function getNetZeroExpectedAlignment(corporate, userEmissionYear) {
  if (corporate) {
    let emissionYear = null;
    const alignmentData = corporate.alignment?.data || {};
    const newChartData = [];
    if (!isEmpty(alignmentData.historical_emissions)) {
      let tempMinEmissionYear = null;
      Object.entries(alignmentData.historical_emissions).forEach(([year]) => {
        if (
          tempMinEmissionYear === null
          || tempMinEmissionYear > parseInt(year, 10)
        ) {
          tempMinEmissionYear = parseInt(year, 10);
        }
      });
      newChartData.push({
        type: 'scatter',
        name: 'Historical Emissions (Reported)',
        data: getChartDataFromObject(alignmentData.historical_emissions),
        marker: {
          radius: 4,
          symbol: 'circle',
        },
      });
      emissionYear = tempMinEmissionYear - 1;
    } else {
      emissionYear = userEmissionYear;
    }
    WARMING_SCENARIO.forEach((key) => {
      if (alignmentData[key]) {
        newChartData.push({
          ...defaultChartData,
          name: SECTOR_SUBPLOT_LEGENDS[key],
          data: getChartDataFromObject(alignmentData[key]),
        });
      }
    });
    newChartData.push({
      ...defaultChartData,
      name: 'Expected Trajectory',
      data: getChartDataFromObject(alignmentData.expected),
    });
    return {
      data: newChartData,
      emissionYear,
      yAxisTitle: corporate?.alignment?.metadata?.units || '',
    };
  }
  return {
    data: [],
    emissionYear: userEmissionYear,
    yAxisTitle: '',
  };
}

export function getNetZeroExpectedAlignmentTable(corporate) {
  if (corporate) {
    const alignmentData = corporate.alignment?.data || {};
    const data = [];
    if (!isEmpty(alignmentData.historical_emissions)) {
      data.push({
        name: 'Historical Emissions',
        ...alignmentData.historical_emissions,
      });
    }
    data.push(
      Object.entries(alignmentData)
        .map(([key, value]) => ({ name: key, ...value }))
        .filter((row) => WARMING_SCENARIO.includes(row.name)),
    );
    data.push({ name: 'Expected Trajectory', ...alignmentData.expected });
    return data.flat();
  }
  return [];
}

export function getCompanyBenchmarkComparisonTable(company, benchmark) {
  const data = [];
  data.push({
    name: company.name,
    implied_temp: company.portfolio_alignment?.implied_temp,
    target: company.has_targets ? 'Yes' : 'No',
    percent_of_companies_with_targets:
      company.portfolio_alignment?.percent_of_companies_with_targets,
    on_track_indicator: company.portfolio_alignment?.on_track_indicator,
  });
  data.push({
    name: company.sub_industry_group,
    implied_temp: benchmark?.portfolio_alignment?.implied_temp,
    target: benchmark.has_targets ? 'Yes' : 'No',
    percent_of_companies_with_targets:
      benchmark?.portfolio_alignment?.percent_of_companies_with_targets,
    on_track_indicator: benchmark?.portfolio_alignment?.on_track_indicator,
  });
  return data;
}
