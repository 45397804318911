/* eslint-disable react/prop-types */
import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';

export const LoadingHOC = (WrappedComponent) => function HOC(props) {
  const loading = useSelector((state) => state.auth.loading);
  let error = props.error || props.error_2;
  if (error && typeof error !== 'string') error = 'Something went wrong';

  return (
      <>
        {props.customLoader || props.loading || loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
              alignContent: 'center',
            }}
            data-testid="circular-loader"
          >
            <CircularProgress />
          </div>
        ) : error ? (
          <Box
            align="center"
            className="error-msg"
            style={{ marginTop: 20, fontSize: 16 }}
          >
            {error}
          </Box>
        ) : (
          <WrappedComponent {...props} />
        )}
      </>
  );
};

export default LoadingHOC;
