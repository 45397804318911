import sortArrayByKey from '../../util/sortArrayByKey';
// eslint-disable-next-line import/no-cycle
import apiSlice, { getQuery } from '../apiSlice';

export const flmApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFlmPortfolioAlignment: builder.query({
      query: getQuery.post('/forward_looking_metrics/portfolio_alignment'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getFlmTargetSetting: builder.query({
      query: getQuery.post('/forward_looking_metrics/target_setting'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getFlmCompanies: builder.query({
      query: getQuery.post('forward_looking_metrics/sectors/companies'),
      // transformResponse: (response) => ({
      //   sectors: Object.keys(response).sort(),
      //   companiesData: response,
      // }),
      transformResponse: (response) => ({
        sectors: Object.keys(response).sort(),
        companiesData: Object.keys(response).reduce((acc, sector) => {
          acc[sector] = response[sector]?.sort(sortArrayByKey('name')) || [];
          return acc;
        }, {}),
      }),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getFlmCompanyProfile: builder.query({
      query: getQuery.post('forward_looking_metrics/company_profile'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getFlmCarbonCompanies: builder.query({
      query: getQuery.post(
        'forward_looking_metrics/carbon_adjusted_returns/available_companies_price',
      ),
      transformResponse: (response) => ({
        sectors: Object.keys(response).sort(),
        companiesData: Object.keys(response).reduce((acc, sector) => {
          acc[sector] = response[sector]?.sort(sortArrayByKey('name')) || [];
          return acc;
        }, {}),
      }),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getFlmCarbonReturnsLineData: builder.query({
      query: getQuery.post(
        'forward_looking_metrics/carbon_adjusted_returns/linechart',
      ),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getFlmCarbonReturnsTableData: builder.query({
      query: getQuery.post(
        'forward_looking_metrics/carbon_adjusted_returns/table',
      ),
      transformErrorResponse: ({ data }) => data?.message,
    }),
  }),
});

export const {
  useGetFlmPortfolioAlignmentQuery,
  useLazyGetFlmPortfolioAlignmentQuery,
  useGetFlmTargetSettingQuery,
  useLazyGetFlmTargetSettingQuery,
  useGetFlmCompaniesQuery,
  useGetFlmCompanyProfileQuery,
  useGetFlmCarbonCompaniesQuery,
  useGetFlmCarbonReturnsLineDataQuery,
  useGetFlmCarbonReturnsTableDataQuery,
  useLazyGetFlmCarbonCompaniesQuery,
  useLazyGetFlmCarbonReturnsLineDataQuery,
  useLazyGetFlmCarbonReturnsTableDataQuery,
  useLazyGetFlmCompaniesQuery,
  useLazyGetFlmCompanyProfileQuery,
} = flmApiSlice;
