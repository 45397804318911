import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Box,
  OutlinedInput,
  InputLabel,
  Grid,
  Select,
  MenuItem,
  Typography,
  IconButton,
  FormControl,
  makeStyles,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { NotificationManager } from 'react-notifications';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import CustomCheckbox from '../../components/Checkbox';
import UploadFileButton from '../../components/FileUploadButton';
import DownloadFileButton from '../../components/DownloadFileButton';
import NumberInputWithCommas from '../../components/NumberInputWithCommas';
import { useUploadPortfolioRequestMutation } from '../../redux/apiHooks';
import colorSchema from '../../Themes/config';
import useGetTheme from '../../hooks/useGetTheme';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const PORTFOLIO_TYPES = {
  FUNDS_OF_FUNDS: 'Fund of Funds',
  LONG_SHORT_PORTFOLIO: 'Long-Short Portfolio',
  PORTFOLIO: 'Portfolio',
};

const useStyles = makeStyles(() => ({
  dialogContent: {
    borderTopStyle: 'groove',
    borderBottomColor: '#D3D3D3',
    borderTopWidth: '1px',
    borderBottomStyle: 'groove',
    borderBottomWidth: '1px',
    paddingTop: 16,
    paddingBottom: 16,
  },
}));

const PortfolioUpload = ({ dialog, handleClose }) => {
  const classes = useStyles();
  const { currentTheme } = useGetTheme();
  const [selectPortfolio, setSelectPortfolio] = useState('Portfolio');
  const [portfolioName, setPortfolioName] = useState('');
  const [isBenchmark, setBenchmarkValue] = useState(false);
  const [rebalance, setRebalance] = useState(false);
  const [shortRebalance, setShortRebalance] = useState(false);
  const [longRebalance, setLongRebalance] = useState(false);
  const [value, setValue] = useState('1000000000');
  const [longValue, setLongValue] = useState('1000000000');
  const [shortValue, setShortValue] = useState('1000000000');
  const [template, setTemplate] = useState('weights');
  const [currency, setCurrency] = useState('USD');
  const [file, setFile] = useState();
  const [shortFile, setShortFile] = useState();
  const [longFile, setLongFile] = useState();
  const [uploadPortfolioRequest, { isLoading: isUploading }] = useUploadPortfolioRequestMutation();

  const { currentUser } = useSelector((state) => state.auth);
  const hasFundsOfFunds = currentUser?.extra_modules?.includes('fund_of_funds');

  const reset = () => {
    handleClose();
    setValue('');
    setCurrency('USD');
    setPortfolioName('');
    setShortValue('');
    setLongValue('');
    setRebalance(false);
    setShortRebalance(false);
    setLongRebalance(false);
    setBenchmarkValue(false);
    setTemplate('weights');
    setSelectPortfolio('Portfolio');
  };

  const handleCloseDialog = () => {
    handleClose();
    reset();
  };

  const uploadPortfolio = async () => {
    if (isUploading) return;
    if (!portfolioName) {
      NotificationManager.error('Portfolio Name cannot be empty!');
      return;
    }

    let portfolio = '';
    const data = new FormData();
    if (selectPortfolio === PORTFOLIO_TYPES.FUNDS_OF_FUNDS) {
      portfolio = 'fund_of_funds';
      data.append('compositions', file);
      if (template === 'weights') {
        data.append('value', value);
      }
      data.append('name', portfolioName);
      data.append('currency', currency);
      data.append('is_benchmark', isBenchmark);
      data.append('auto_rebalance', rebalance);
      data.append('template_option', template);
    }

    if (selectPortfolio === PORTFOLIO_TYPES.LONG_SHORT_PORTFOLIO) {
      portfolio = 'long_short';

      data.append('long', longFile);
      data.append('short', shortFile);
      if (template === 'weights') {
        data.append('long_value', longValue);
        data.append('short_value', shortValue);
      }

      data.append('name', portfolioName);
      data.append('is_benchmark', isBenchmark);
      data.append('short_rebalance', shortRebalance);
      data.append('long_rebalance', longRebalance);
      data.append('template_option', template);
    }

    if (selectPortfolio === PORTFOLIO_TYPES.PORTFOLIO) {
      portfolio = 'long';
      data.append('compositions', file);
      if (template === 'weights') {
        data.append('value', value);
      }
      data.append('name', portfolioName);
      data.append('is_benchmark', isBenchmark);
      data.append('auto_rebalance', rebalance);
      data.append('template_option', template);
    }
    const res = await uploadPortfolioRequest({ body: data, isFund: portfolio });
    if (res.data) {
      NotificationManager.success(
        'Your portfolio has been uploaded and is being processed. You will see your uploaded portfolio table updated once the processing has been completed.',
      );
      reset();
    } else if (res.error) {
      NotificationManager.error(
        typeof res.error === 'string' ? res.error : 'Something went wrong',
      );
    }
  };

  const downloadTemplate = () => {
    const data = [];
    const obj = {};
    let fileName = '';

    if (selectPortfolio === PORTFOLIO_TYPES.FUNDS_OF_FUNDS) {
      fileName = 'Fund_of_Funds_Template';
      if (template === 'weights') {
        obj.FundName = '';
        obj.FundWeight = '';
        obj.ISIN = '';
        obj.ISINWeight = '';
      } else {
        obj.FundName = '';
        obj.ISIN = '';
        obj.Value = '';
      }
    }

    if (selectPortfolio === PORTFOLIO_TYPES.LONG_SHORT_PORTFOLIO) {
      fileName = 'Long_Short_Portfolio_Template';
      obj.ISIN = '';
      if (template === 'values') {
        obj.Value = '';
      } else {
        obj.Weight = '';
      }
    }

    if (selectPortfolio === PORTFOLIO_TYPES.PORTFOLIO) {
      fileName = 'Portfolio_Template';
      obj.ISIN = '';
      if (template === 'values') {
        obj.Value = '';
      } else {
        obj.Weight = '';
      }
    }

    data.push(obj);
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { Sheet1: ws }, SheetNames: ['Sheet1'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const fileData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(fileData, fileName + fileExtension);
  };

  const handleTemplateChange = (e) => {
    setTemplate(e.target.value);
  };
  const isLongShortPortfolio = selectPortfolio === 'Long-Short Portfolio';
  return (
    <Dialog open={dialog} keepMounted style={{ height: '100%' }}>
      <Box className="d-flex flex-space-between">
        <Typography style={{ marginLeft: 20, marginTop: 10, fontSize: 22 }}>
          Upload Portfolio
        </Typography>
        <IconButton onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent className={classes.dialogContent}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} sm={6}>
            <Typography>Portfolio Type</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="select-portfolio">
                Select Portfolio Type
              </InputLabel>
              <Select
                labelId="select-portfolio"
                variant="outlined"
                label="Select Portfolio Type"
                value={selectPortfolio}
                onChange={(e) => setSelectPortfolio(e.target.value)}
              >
                <MenuItem value={PORTFOLIO_TYPES.PORTFOLIO}>Portfolio</MenuItem>
                <MenuItem
                  value={PORTFOLIO_TYPES.FUNDS_OF_FUNDS}
                  disabled={!hasFundsOfFunds}
                >
                  Fund of Funds
                </MenuItem>
                <MenuItem
                  value={PORTFOLIO_TYPES.LONG_SHORT_PORTFOLIO}
                  disabled={!hasFundsOfFunds}
                >
                  Long-Short Portfolio
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography>Portfolio Name</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <OutlinedInput
              type="text"
              fullWidth
              value={portfolioName}
              onChange={(e) => setPortfolioName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Template Option</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Select Template</InputLabel>
              <Select
                variant="outlined"
                label="Select Template"
                value={template}
                onChange={handleTemplateChange}
              >
                <MenuItem value="values">Values</MenuItem>
                <MenuItem value="weights">Weights</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Select Currency</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Select Currency</InputLabel>
              <Select
                variant="outlined"
                label="Select Currency"
                disabled
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <MenuItem value="USD">USD ($)</MenuItem>
                <MenuItem value="EUR">EUR (€)</MenuItem>
                <MenuItem value="GBP">GBP (£)</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {isLongShortPortfolio ? (
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography>Long Value</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberInputWithCommas
                value={template === 'values' ? '-' : longValue}
                disabled={template === 'values'}
                setValue={setLongValue}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>Upload Long</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <UploadFileButton
                file={longFile}
                onChange={setLongFile}
                id={'components-long-file'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>Short Value</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberInputWithCommas
                value={template === 'values' ? '-' : shortValue}
                disabled={template === 'values'}
                setValue={setShortValue}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>Upload Short</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <UploadFileButton
                file={shortFile}
                onChange={setShortFile}
                id={'components-file'}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <CustomCheckbox
                label="Long Auto-Rebalance Weights"
                name="longAutoRebalanceWeights"
                action={(e) => {
                  setLongRebalance(e.target.checked);
                }}
                color="default"
                value={longRebalance}
                checked={longRebalance}
                disabled={template === 'values'}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomCheckbox
                label="Is Benchmark"
                name="benchmark"
                action={(e) => {
                  setBenchmarkValue(e.target.checked);
                }}
                color="default"
                value={isBenchmark}
                checked={isBenchmark}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomCheckbox
                label="Short Auto-Rebalance Weights"
                name="shortAutoRebalanceWeights"
                action={(e) => {
                  setShortRebalance(e.target.checked);
                }}
                color="default"
                value={shortRebalance}
                checked={shortRebalance}
                disabled={template === 'values'}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography>Value</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberInputWithCommas
                value={template === 'values' ? '-' : value}
                disabled={template === 'values'}
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>Upload File</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <UploadFileButton
                file={file}
                onChange={setFile}
                id={'portfolio-file'}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <CustomCheckbox
                label="Auto-Rebalance Weights"
                name="autoRebalanceWeights"
                action={(e) => {
                  setRebalance(e.target.checked);
                }}
                color="default"
                value={rebalance}
                checked={rebalance}
                disabled={template === 'values'}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomCheckbox
                label="Is Benchmark"
                name="benchmark"
                action={(e) => {
                  setBenchmarkValue(e.target.checked);
                }}
                color="default"
                value={isBenchmark}
                checked={isBenchmark}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <DownloadFileButton
          onClick={downloadTemplate}
          label={'Download Template'}
        />
        <Button
          onClick={uploadPortfolio}
          style={{ width: 200 }}
          color="primary"
          variant="outlined"
        >
          {isUploading ? (
            <CircularProgress
              size="24px"
              style={{ color: colorSchema[currentTheme].buttonText }}
            />
          ) : (
            'Upload'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PortfolioUpload.propTypes = {
  dialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default PortfolioUpload;
