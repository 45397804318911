import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const CheckBox = ({
  label, checked, value, action, disabled = false,
}) => (
  <FormControlLabel
    disabled={disabled}
    control={<Checkbox checked={checked} onChange={action} value={value} />}
    label={label}
  />
);

CheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  value: PropTypes.any,
  action: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default CheckBox;
