import React, { useState, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { LoadingHOC } from '../../../util/LoadingHOC';
import PortTempScoreDisplay from './PortTempScoreDisplay';
import 'react-circular-progressbar/dist/styles.css';
import useGetPayload from '../../../hooks/useGetPayload';
import Model from '../../../models/tempScore/portfolioScore';
import { useGetTempMetricPortfolioScoreQuery } from '../../../redux/apiHooks';

const AttributionDisplayWithLoading = LoadingHOC(PortTempScoreDisplay);

const PortTemperatureScore = () => {
  const chartComponent = useRef(null);

  const { auth, filters, payload } = useGetPayload(Model);
  const {
    data: tempScoreData,
    isFetching,
    error,
  } = useGetTempMetricPortfolioScoreQuery(payload);

  const { emission, scoreType, defaultValue } = filters;
  const { showBenchmark } = auth;

  const [companyChartData, setCompanyChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [targetData, setTargetData] = useState('');
  const [portScore, setPortScore] = useState(defaultValue);
  const [benchScore, setBenchScore] = useState(defaultValue);
  const [spiralXValue, setSpiralXValue] = useState([]);

  const getCompanyData = (tempData) => {
    const result = [];
    const res = tempData[scoreType].bands;

    if (res && Object.keys(res).length > 0) {
      Object.keys(res).forEach((band) => {
        const tempValue = res[band].temperature_score;
        result.push({
          name: band.replace('_', '-'),
          data: [tempValue],
        });
      });
    }
    return result;
  };

  const getData = () => {
    let tData = [];
    let pScore = 0;
    let bScore = 0;
    let coverage = '';
    let companyData = [];

    if (!isEmpty(tempScoreData)) {
      switch (emission) {
        case 'Sc12':
          pScore = tempScoreData[0][scoreType].score;
          bScore = showBenchmark && tempScoreData[3][scoreType].score;
          coverage = tempScoreData[0].coverage;
          companyData = getCompanyData(tempScoreData[0]);
          break;
        case 'Sc123':
          pScore = tempScoreData[2][scoreType].score;
          bScore = showBenchmark && tempScoreData[5][scoreType].score;
          coverage = tempScoreData[2].coverage;
          companyData = getCompanyData(tempScoreData[2]);
          break;
        case 'Sc3':
          pScore = tempScoreData[1][scoreType].score;
          bScore = showBenchmark && tempScoreData[4][scoreType].score;
          coverage = tempScoreData[1].coverage;
          companyData = getCompanyData(tempScoreData[1]);
          break;
        default:
          pScore = tempScoreData[0][scoreType].score;
          bScore = showBenchmark && tempScoreData[2][scoreType].score;
          coverage = tempScoreData[0].coverage;
          companyData = getCompanyData(tempScoreData[0]);
          break;
      }
      if (showBenchmark) {
        tData = [...tempScoreData];
      } else {
        const onlyPortfolio = tempScoreData.filter(
          (row) => row.name.split(' ')[0] !== 'Benchmark',
        );
        tData = [...onlyPortfolio];
      }
    }
    let xValue = [];
    if (showBenchmark) {
      xValue = ['Portfolio', 'Benchmark', 'Default Score'];
    } else {
      xValue = ['Portfolio', 'Default Score'];
    }
    setSpiralXValue([...xValue]);
    setTableData(tData);
    setTargetData([coverage.total, coverage.sbti_validated]);
    setCompanyChartData(companyData);
    setPortScore(pScore);
    setBenchScore(bScore);
  };
  const defaultChartValue = parseFloat(defaultValue);
  let tempScoreChartData = [];
  if (showBenchmark) {
    tempScoreChartData = [portScore, benchScore, defaultChartValue];
  } else {
    tempScoreChartData = [portScore, defaultChartValue];
  }

  useEffect(() => {
    getData();
  }, [tempScoreData, emission, scoreType, defaultValue, showBenchmark]);
  return (
    <AttributionDisplayWithLoading
      error={error}
      loading={isFetching}
      tempScoreChartData={tempScoreChartData}
      spiralXValue={spiralXValue}
      companyChartData={companyChartData}
      targetData={targetData}
      tableData={tableData}
      ref={chartComponent}
    />
  );
};

export default PortTemperatureScore;
