import React from 'react';
import PropTypes from 'prop-types';
import useCurrentTheme from '../../hooks/useCurrentTheme';
import BaseChart from './BaseChart';
import ChartProps from './ChartProps';

function HeatmapChart({ legendHeight, colorAxis, ...props }) {
  const { usedTheme } = useCurrentTheme(props.theme);
  const options = {
    xAxis: {
      labels: {
        formatter() {
          return this.value.name && this.value.name.replace(/ /g, '<br />');
        },
        rotation: 0,
      },
    },
    yAxis: {
      reversed: true,
    },
    legend: {
      y: 30,
      symbolHeight: legendHeight || 150,
    },
    series: [{ data: props.data }],
    toottip: {
      formatter() {
        const xValue = props.xAxisCategories[this.point.options.x];
        const yValue = props.yAxisCategories[this.point.options.y];
        const zValue = this.point.options.value;

        return `${xValue}<br/>${yValue}<br /><b>${zValue}</b>`;
      },
    },
    plotOptions: {
      series: {
        borderWidth: 0.4,
        dataLabels: {
          enabled: true,
          color: '#000000',
          style: {
            fontWeight: 'normal',
            textOutline: 'none',
            fontSize: props.fontSize || 11,
          },
        },
      },
    },
    colorAxis: {
      min: colorAxis?.min || 0,
      max: colorAxis?.max || 100,
      minColor: usedTheme === 'dark' ? '#b9eea4' : '#a7f7c1',
      maxColor: '#ff4d4f',
      reversed: false,
    },
  };
  return (
    <BaseChart
      {...props}
      chartType="heatmap"
      height={props.height || 630}
      xAxisTitle={null}
      yAxisTitle={null}
      options={options}
    />
  );
}

HeatmapChart.propTypes = {
  ...ChartProps,
  legendHeight: PropTypes.number,
  colorAxis: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
};
export default HeatmapChart;
