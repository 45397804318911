import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { LoadingHOC } from '../../util/LoadingHOC';
import DataTable from '../../components/Table/DataTable';
import CustomToggleSwitch from '../../components/CustomToggleSwitch';

const SummaryDisplay = ({
  onSwitchChange,
  showTrafficLights,
  portfolioData,
  portfolioColumns,
  companiesData,
  companiesColumns,
}) => (
  <>
    <Grid>
      <Typography>
        Select a portfolio and view various transition metrics at the company
        level.
      </Typography>
      <CustomToggleSwitch
        checkedValue={showTrafficLights}
        label="Show Indicator"
        labelPlacement="end"
        onSwitchChange={onSwitchChange}
      />
    </Grid>
    <DataTable
      data={portfolioData}
      columns={portfolioColumns}
      noRowsPerPage={true}
    />
    <br />
    <DataTable
      data={companiesData}
      columns={companiesColumns}
      noRowsPerPage={true}
    />
  </>
);

SummaryDisplay.propTypes = {
  onSwitchChange: PropTypes.func.isRequired,
  showTrafficLights: PropTypes.bool.isRequired,
  portfolioData: PropTypes.array.isRequired,
  portfolioColumns: PropTypes.array.isRequired,
  companiesData: PropTypes.array.isRequired,
  companiesColumns: PropTypes.array.isRequired,
};

export default LoadingHOC(SummaryDisplay);
