/* eslint-disable react/prop-types */

import React from 'react';
import ColumnChart from '../../../components/Charts/ColumnChart';

const PerformanceAttributionDisplay = (props) => (
  <React.Fragment>
    <ColumnChart
      categories={props.categories}
      data={props.data}
      title="Performance Attribution"
      yAxisTitle="Returns (%)"
    />
  </React.Fragment>
);

export default PerformanceAttributionDisplay;
