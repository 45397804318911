import React from 'react';
import PropTypes from 'prop-types';
import ColumnChart from '../../Charts/ColumnChart';

function ReportColumnChart({ data, categories }) {
  return (
    <ColumnChart
      categories={categories}
      data={data}
      chartKey={'SCOPE3_DISCLOSURE'}
      theme={'ICE light'}
      height={350}
      fontSize={8}
      isExportable={false}
      plotLineWidth={0.3}
    />
  );
}

ReportColumnChart.propTypes = {
  data: PropTypes.array,
  categories: PropTypes.array,
};

export default ReportColumnChart;
