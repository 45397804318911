import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import OptimizedEmissionDisplay from './OptimizedEmissionDisplay';
import { LoadingHOC } from '../../../util/LoadingHOC';
import useGetPayload from '../../../hooks/useGetPayload';
import PortfolioReturns from '../../../models/optimization/portfolioReturns';
import { useGetOptimizationQuery } from '../../../redux/apiHooks';
import { displayNumber } from '../../../helpers';

const OptimizedEmissionWithLoading = LoadingHOC(OptimizedEmissionDisplay);

const categories = ['Scope 1+2', 'Scope 3', 'Scope 1+2+3'];

const PortfolioOptimization = () => {
  const [yAxisTitle, setYAxisTitle] = useState('');
  const [intensityData, setIntensityData] = useState([]);
  const [weightCategories, setWeightCategories] = useState([]);
  const [contribCategories, setContribCategories] = useState([]);
  const [weightData, setWeightData] = useState([]);
  const [contribData, setContribData] = useState([]);

  const { auth, payload } = useGetPayload(PortfolioReturns);
  const {
    data: optimizationData,
    isFetching,
    error,
  } = useGetOptimizationQuery(payload);
  const { showBenchmark } = auth;

  // const formatDate = (currentDate) => {
  //   const year = currentDate.toString().slice(0, 4);
  //   const month = currentDate.toString().slice(4, 6) - 1;
  //   const date = currentDate.toString().slice(6, 8);
  //   return { year, month, date };
  // };

  // const getTableKey = (key) => {
  //   switch (key) {
  //     case 'Annualised1Y':
  //       return 'Annualized (1Y)';
  //     case 'Annualised3Y':
  //       return 'Annualized (3Y)';
  //     case 'Annualised5Y':
  //       return 'Annualized (5Y)';
  //     case 'Return3Y':
  //       return 'Return (3Y)';
  //     case 'Return5Y':
  //       return 'Return (5Y)';
  //     case 'Risk1Y':
  //       return 'Risk (1Y)';
  //     case 'Risk3Y':
  //       return 'Risk (3Y)';
  //     case 'Risk5Y':
  //       return 'Risk (5Y)';
  //     case 'SharpeRatio1Y':
  //       return 'Sharpe Ratio (1Y)';
  //     case 'SharpeRatio3Y':
  //       return 'Sharpe Ratio (3Y)';
  //     case 'SharpeRatio5Y':
  //       return 'Sharpe Ratio (5Y)';
  //     default:
  //       return null;
  //   }
  // };
  // const getTableData = (response) => {
  //   const tableData = [];
  //   let count = 0;

  //   if (
  //     response.portfolio
  //     && response.portfolio.table
  //     && Object.keys(response.portfolio.table.length > 0)
  //   ) {
  //     const data = response.portfolio.table;
  //     Object.keys(data).forEach((key) => {
  //       const tableKey = getTableKey(key);
  //       if (!key.includes('3Y')) {
  //         tableData[count] = {
  //           name: tableKey,
  //           portfolio: parseFloat(data[key]).toFixed(2),
  //         };
  //         count += 1;
  //       }
  //     });
  //   }
  //   if (
  //     response.benchmark
  //     && response.benchmark.table
  //     && Object.keys(response.benchmark.table.length > 0)
  //   ) {
  //     const data = response.benchmark.table;
  //     count = 0;
  //     Object.keys(data).forEach((key) => {
  //       if (!key.includes('3Y')) {
  //         tableData[count] = {
  //           ...tableData[count],
  //           benchmark: parseFloat(data[key]).toFixed(2),
  //         };
  //         count += 1;
  //       }
  //     });
  //   }
  //   if (
  //     response.tilted
  //     && response.tilted.table
  //     && Object.keys(response.tilted.table.length > 0)
  //   ) {
  //     const data = response.tilted.table;
  //     count = 0;

  //     Object.keys(data).forEach((key) => {
  //       if (!key.includes('3Y')) {
  //         tableData[count] = {
  //           ...tableData[count],
  //           tilted: parseFloat(data[key]).toFixed(2),
  //         };
  //         count += 1;
  //       }
  //     });
  //   }
  //   return tableData;
  // };
  // const getLineChartData = (response) => {
  //   const lineChartData = [
  //     {
  //       name: 'Portfolio',
  //       data: [],
  //     },
  //     {
  //       name: 'Benchmark',
  //       data: [],
  //     },
  //     {
  //       name: 'Tilted',
  //       data: [],
  //     },
  //   ];
  //   if (
  //     response.portfolio
  //     && response.portfolio.linechart
  //     && Object.keys(response.portfolio.linechart.length > 0)
  //   ) {
  //     const portDate = response.portfolio.linechart;
  //     Object.keys(portDate).forEach((currentDate) => {
  //       const { year, month, date } = formatDate(currentDate);
  //       lineChartData[0].data.push([
  //         Date.UTC(year, month, date),
  //         portDate[currentDate],
  //       ]);
  //     });
  //   }
  //   if (
  //     response.benchmark
  //     && response.benchmark.linechart
  //     && Object.keys(response.benchmark.linechart.length > 0)
  //   ) {
  //     const benchDate = response.benchmark.linechart;
  //     Object.keys(benchDate).forEach((currentDate) => {
  //       const { year, month, date } = formatDate(currentDate);
  //       lineChartData[1].data.push([
  //         Date.UTC(year, month, date),
  //         benchDate[currentDate],
  //       ]);
  //     });
  //   }
  //   if (
  //     response.tilted
  //     && response.tilted.linechart
  //     && Object.keys(response.tilted.linechart.length > 0)
  //   ) {
  //     const tiltedDate = response.tilted.linechart;
  //     Object.keys(tiltedDate).forEach((currentDate) => {
  //       const { year, month, date } = formatDate(currentDate);
  //       lineChartData[2].data.push([
  //         Date.UTC(year, month, date),
  //         tiltedDate[currentDate],
  //       ]);
  //     });
  //   }
  //   return lineChartData;
  // };
  const getIntensityData = () => {
    const intensityPortSc12 = displayNumber(
      optimizationData.tilted.Intensities.portfolio.sc12,
    );
    const intensityPortSc123 = displayNumber(
      optimizationData.tilted.Intensities.portfolio.sc123,
    );
    const intensityPortSc3 = displayNumber(
      optimizationData.tilted.Intensities.portfolio.sc3,
    );

    const intensityTiltedSc12 = displayNumber(
      optimizationData.tilted.Intensities.tilted.sc12,
    );
    const intensityTiltedSc123 = displayNumber(
      optimizationData.tilted.Intensities.tilted.sc123,
    );
    const intensityTiltedSc3 = displayNumber(
      optimizationData.tilted.Intensities.tilted.sc3,
    );

    const intensity = [
      {
        name: 'Portfolio',
        data: [intensityPortSc12, intensityPortSc3, intensityPortSc123],
      },
      {
        name: 'Tilted',
        data: [intensityTiltedSc12, intensityTiltedSc3, intensityTiltedSc123],
      },
    ];

    if (showBenchmark) {
      const benchmarkTiltedSc12 = displayNumber(
        optimizationData.benchmark.Intensities.benchmark.sc12,
      );
      const benchmarkTiltedSc123 = displayNumber(
        optimizationData.benchmark.Intensities.benchmark.sc123,
      );
      const benchmarkTiltedSc3 = displayNumber(
        optimizationData.benchmark.Intensities.benchmark.sc3,
      );
      intensity.push({
        name: 'Benchmark',
        data: [benchmarkTiltedSc12, benchmarkTiltedSc3, benchmarkTiltedSc123],
      });
    }

    return intensity;
  };
  const getData = async () => {
    let yAxisLabel = '';
    let intensity = [];
    const weightCategoriesData = [];
    const contribCategoriesData = [];
    const weight = [
      {
        name: 'Portfolio',
        data: [],
      },
      {
        name: 'Tilted',
        data: [],
      },
    ];

    const contrib = [
      {
        name: 'Portfolio',
        data: [],
      },
      {
        name: 'Tilted',
        data: [],
      },
    ];

    if (showBenchmark) {
      weight.push({
        name: 'Benchmark',
        data: [],
      });
      contrib.push({
        name: 'Benchmark',
        data: [],
      });
    }

    if (!isEmpty(optimizationData)) {
      yAxisLabel = optimizationData.chart_label;
      intensity = getIntensityData();

      const portSectorWeights = optimizationData.tilted.Intensities.portfolio.sector_weight;
      const tiltedSectorWeights = optimizationData.tilted.Intensities.tilted.sector_weight;
      const benchmarkSectorWeights = showBenchmark
        && optimizationData.benchmark.Intensities.benchmark.sector_weight;
      const portSectorContribs = optimizationData.tilted.Intensities.portfolio.sectoral_contribution;
      const tiltedSectorContribs = optimizationData.tilted.Intensities.tilted.sectoral_contribution;
      const benchmarkSectorContribs = showBenchmark
        && optimizationData.benchmark.Intensities.benchmark.sectoral_contribution;

      if (!isEmpty(portSectorWeights)) {
        Object.keys(portSectorWeights).forEach((key) => {
          weight[0].data.push(portSectorWeights[key]);
          weightCategoriesData.push(key);
        });
      }
      if (!isEmpty(tiltedSectorWeights)) {
        Object.keys(tiltedSectorWeights).forEach((key) => {
          weight[1].data.push(tiltedSectorWeights[key]);
        });
      }
      if (showBenchmark && !isEmpty(benchmarkSectorWeights)) {
        Object.keys(benchmarkSectorWeights).forEach((key) => {
          weight[2].data.push(benchmarkSectorWeights[key]);
        });
      }
      if (!isEmpty(portSectorContribs)) {
        Object.keys(portSectorContribs).forEach((key) => {
          contrib[0].data.push(portSectorContribs[key]);
          contribCategoriesData.push(key);
        });
      }
      if (!isEmpty(tiltedSectorContribs)) {
        Object.keys(tiltedSectorContribs).forEach((key) => {
          contrib[1].data.push(tiltedSectorContribs[key]);
        });
      }
      if (showBenchmark && !isEmpty(benchmarkSectorContribs)) {
        Object.keys(benchmarkSectorContribs).forEach((key) => {
          contrib[2].data.push(benchmarkSectorContribs[key]);
        });
      }
    }
    setYAxisTitle(yAxisLabel);
    setIntensityData(intensity);
    setWeightCategories(weightCategoriesData);
    setContribCategories(contribCategoriesData);
    setWeightData(weight);
    setContribData(contrib);
  };

  useEffect(() => {
    getData();
  }, [optimizationData]);

  return (
    <OptimizedEmissionWithLoading
      loading={isFetching}
      error={error}
      categories_1={categories}
      data_1={intensityData}
      chartKey_1="PORT_OPTIMIZATION_INTENSITY"
      yAxisTitle={yAxisTitle}
      categories_2={weightCategories}
      data_2={weightData}
      chartKey_2="PORT_OPTIMIZATION_WEIGHT"
      categories_3={contribCategories}
      data_3={contribData}
      chartKey_3="PORT_OPTIMIZATION_CONTRIB"
    />
  );
};
export default PortfolioOptimization;
