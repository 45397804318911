import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Button } from '@material-ui/core';
import SavePortfolio from '../SavePortfolio';
import {
  getSingleFieldDropdown,
  getMultiFieldDropdown,
} from '../../util/portfolioBuilderComp';
import {
  clearAllPortBuilderFields,
  clearPortfolioBuilderFields,
  resetMakeApiCall,
  setPortBuilderFields,
  setPortBuilderVisibleField,
} from '../../redux/reducers/portfolioBuilderReducer';
import { getPortfolioBuilderData } from '../../services/auth';
import useCurrentTheme from '../../hooks/useCurrentTheme';

export default function MultiAddFilters() {
  // redux values
  const {
    data, metaData, selectorFields, makeApiCall,
  } = useSelector(
    (state) => state.portfolioBuilder,
  );

  // dispatch
  const dispatch = useDispatch();
  const { usedTheme, usedStyles } = useCurrentTheme();

  const selectedFieldsLength = selectorFields.filter((o) => o.isSelected).length;

  const handleClick = (value, filterType, id) => {
    const updatedData = selectorFields.map((f) => (f.id === id
      ? {
        ...f,
        [filterType]: value,
        isSelected: true,
        isVisible: true,
      }
      : f));
    dispatch(setPortBuilderFields(updatedData));
  };

  const handleClickAway = (field) => {
    if (
      field.filterType === 'intensity_sc12'
      && (field.value || (field.gt && field.lt))
    ) {
      const newState = selectorFields.map((obj) => (obj.id === field.id ? { ...obj, isVisible: false } : obj));
      dispatch(setPortBuilderFields(newState));
    }
  };

  const clearAllSelection = () => dispatch(clearAllPortBuilderFields());

  const onCloseHandler = (id) => dispatch(setPortBuilderVisibleField(id));

  const loadData = () => {
    const newSelectedFields = selectorFields.map((obj) => ({
      ...obj,
      isVisible: false,
    }));
    dispatch(setPortBuilderFields(newSelectedFields));
    dispatch(getPortfolioBuilderData());
  };

  useEffect(() => {
    if (makeApiCall) {
      loadData();
      dispatch(resetMakeApiCall());
    }
  }, [makeApiCall]);

  const onClearClick = (id) => dispatch(clearPortfolioBuilderFields(id));

  const renderDynamicDropdownComp = (fields) => {
    switch (fields.filterType) {
      case 'intensity_sc12':
        return getSingleFieldDropdown({
          fields,
          handleClick,
          onClearClick,
          selectorFields,
          onCloseHandler,
          isEditable: fields.value || (fields.gt && fields.lt),
          usedStyles,
        });
      default:
        return getMultiFieldDropdown({
          fields,
          handleClick,
          onClearClick,
          metaData,
          currentTheme: usedTheme,
          usedStyles,
        });
    }
  };

  return (
    <React.Fragment>
      {data.length || selectedFieldsLength ? (
        <>
          <div>
            {selectorFields.map((fields) => (
              <ClickAwayListener
                key={fields.id}
                onClickAway={() => handleClickAway(fields)}
              >
                {renderDynamicDropdownComp(fields)}
              </ClickAwayListener>
            ))}
          </div>
        </>
      ) : null}
      <div
        style={{
          marginTop: 10,
          display: 'flex',
          justifyContent: 'space-between',
          maxWidth: 250,
        }}
      >
        <Button color="primary" variant="contained" onClick={loadData}>
          Apply Filters
        </Button>
        <Button
          variant="contained"
          onClick={clearAllSelection}
          disabled={selectedFieldsLength < 1}
        >
          Clear All Filters
        </Button>
      </div>
      <div style={{ marginTop: 10, display: 'flex', maxWidth: 250 }}>
        <SavePortfolio />
      </div>
    </React.Fragment>
  );
}
