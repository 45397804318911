import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const AlignmentDescription = ({ tempScore }) => {
  const currentPortfolio = useSelector((state) => state.auth.currentPortfolio);
  const portfolioName = currentPortfolio?.label;
  return (
    <React.Fragment>
      {tempScore && (
        <div style={{ marginLeft: 64 }}>
          <p>
            The <b>{portfolioName}</b> portfolio has an overall temperature
            score of <b>{tempScore}</b>°C. This score is based on the cumulative
            estimated emissions of the portfolio. This is calculated using
            company- level emissions reduction targets and the historical
            emissions of companies within the portfolio, i.e. it includes
            actions to date and as well as commitments.
          </p>
          <p>
            The emission trajectories - both the portfolio&apos;s expected
            trajectory and the scenario-aligned pathway - are assumed to reflect
            net emissions. This acknowledges that company targets may include
            the generation of negative emissions.
          </p>
          <p>
            The amount of carbon capture and storage (CCS) that must be financed
            by the portfolio to be aligned to the selected scenario is shown in
            the technology risk plot, indicating the importance of investing in
            these technologies to limit global warming.
          </p>
        </div>
      )}
    </React.Fragment>
  );
};

AlignmentDescription.propTypes = {
  tempScore: PropTypes.string.isRequired,
};

export default AlignmentDescription;
