/* eslint-disable react/prop-types */
import React from 'react';

import { Button } from '@material-ui/core';
import Pdf from '../../../components/PdfReport/Pdf';
import Introduction from './Pages/Introduction';
import Overview from './Pages/Overview';
import EmissionsFootprint from './Pages/EmissionsFootprint';
import Scope3Emissions from './Pages/Scope3Emissions';
import ForwardLookingAlignment from './Pages/ForwardLookingAlignment';
import Appendix from './Pages/Appendix';
import Glossary from './Pages/Glossary';
import Disclaimer from './Pages/Disclaimer';
import OutlinedDropdown from '../../../components/OutlinedDropdown';

function BenchmarkDisplay({
  currentSector,
  handleSectorChange,
  currentSectorList,
  currentCompany,
  handleCompanyChange,
  currentCompanyList,
  onDownloadClick,
  buttonDisabled,
  downloading,
  data,
}) {
  return (
    <div style={{ width: 710 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <OutlinedDropdown
          label="Sector"
          value={currentSector}
          onChange={handleSectorChange}
          options={currentSectorList}
        />
        <OutlinedDropdown
          label="Company"
          value={currentCompany}
          onChange={handleCompanyChange}
          options={currentCompanyList}
        />
      </div>
      <div style={{ marginLeft: 10, marginBottom: 10 }}>
        <Button
          type="button"
          onClick={onDownloadClick}
          disabled={buttonDisabled}
        >
          Download Report
        </Button>
      </div>
      <Pdf downloading={downloading}>
        <Introduction data={data} />
        <Overview data={data} />
        <EmissionsFootprint data={data} />
        <Scope3Emissions data={data} />
        <ForwardLookingAlignment data={data} />
        <Appendix data={data} />
        <Glossary data={data} />
        <Disclaimer />
      </Pdf>
    </div>
  );
}

export default BenchmarkDisplay;
