import React from 'react';
import PropTypes from 'prop-types';
import useCurrentTheme from '../../hooks/useCurrentTheme';
import BaseChart from './BaseChart';
import ChartProps from './ChartProps';

function BubbleChart({
  xAxisLabel, yAxisLabel, zAxisLabel, ...props
}) {
  const { usedTheme } = useCurrentTheme(props.theme);
  const options = {
    yAxis: {
      startOnTick: false,
      endOnTick: false,
      maxPadding: 0.2,
    },
    tooltip: {
      useHTML: true,
      headerFormat: '<div>',
      pointFormat: `<h3>{point.company}</h3>
        <div>${xAxisLabel}:{point.x}</div>
        <div>${yAxisLabel}:{point.y}</div>
        <div>${zAxisLabel}:{point.z}</div>`,
      footerFormat: '</div>',
      followPointer: true,
    },
    plotOptions: {
      series: {
        borderWidth: 1,
        borderColor: usedTheme === 'dark' ? '#000000' : '#FFFFFF',
      },
    },
  };
  return (
    <BaseChart
      {...props}
      chartType="bubble"
      legendType="horizontal"
      xAxisGridlineShow={true}
      yAxisGridlineShow={true}
      options={options}
    />
  );
}

BubbleChart.propTypes = {
  ...ChartProps,
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
  zAxisLabel: PropTypes.string,
};
export default BubbleChart;
