import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CustomToggleSwitch = ({
  checkedValue,
  onSwitchChange,
  label,
  labelPlacement,
  fontSize,
}) => (
  <FormControlLabel
    control={
      <Switch
        checked={checkedValue}
        onChange={onSwitchChange}
        color="primary"
      />
    }
    label={<span style={{ fontSize }}>{label}</span>}
    labelPlacement={labelPlacement}
  />
);

CustomToggleSwitch.propTypes = {
  checkedValue: PropTypes.bool.isRequired,
  onSwitchChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  labelPlacement: PropTypes.string,
  fontSize: PropTypes.string,
};

export default CustomToggleSwitch;
