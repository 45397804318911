import { createSlice } from '@reduxjs/toolkit';
import * as constants from '../../components/FilterSection/constants';

const initialState = {
  updatedFilterModule: '', // used for api calls for all modules.
  sector: constants.SASB_VALUE,
  footprintMetric: constants.WEIGHTED_AVG_INTENSITY_REV_VALUE,
  marketValue: constants.MARKET_CAPITALIZATION_VALUE,
  assetClass: [constants.EQUITY_VALUE, constants.CORPORATE_VALUE],
  inferenceType: constants.AVERAGE_VALUE,
  emission: constants.SCOPE_1_2_VALUE,
  aggregation: constants.WEIGHTED_AVG_VALUE,
  scenario: '0',
  scoreType: constants.SHORT_TERM_SCORE_VALUE,
  defaultValue: '3.20',
  year: '1Y',
  materiality: constants.PORTFOLIO_VALUE,
  intensityScope: constants.SCOPE_1_2_VALUE,
  portScenario: constants.LOW_ENERGY_DEMAND_VALUE,
  targetScenario: constants.IPCC_1_5_VALUE,
  warmingScenario: constants.LOW_ENERGY_DEMAND_VALUE,
  approach: constants.RELATIVE_ALIGNMENT_VALUE,
  alignmentYear: '2020',
  returnYear: '3',
  strategy: constants.CARBON_MOMENTUM_VALUE,
  dateRange: {
    startDate: '2018-01-01',
    endDate: '', // backtest_end_date from userinfo
  },
  relevance: true,
  weighting: true,
  disclosure_category: [1],
  emissionYear: '2017',
  rebalanceFrequency: 'Y',
  sequestration: true,
  emissionsType: constants.NET_VALUE,
  assetType: constants.EQUITY,
  includeHistorical: true,
  filterData: [],
  includeTargets: true,
  chartType: 'normalised',
  include: [constants.TARGETS],
  loadData: false,
  isNormalised: true,
};

// export default function filterReducer(state = { ...intialState }, action) {
//   switch (action.type) {
//     case types.GET_USER_INFO:
//       return produce(state, (draft) => {
//         draft.alignmentYear = action.payload?.year?.currency?.toString();
//         draft.emissionYear = '2017';
//         draft.attribution = 'population';
//         draft.emissionType = 'production';
//         if (state.moduleName === 'PCAF') {
//           draft.strategy = action.payload.footprint_parameters.Loan.strategy;
//           draft.emissionsType = action.payload.footprint_parameters.Loan.emissions_type;
//           draft.footprintMetric = action.payload.footprint_parameters.Loan.footprint;
//         }
//       });
//     case types.SET_FILTER_ITEM:
//       return produce(state, (draft) => {
//         draft[action.payload.key] = action.payload.value;
//       });
//     case types.SET_COUNTRY:
//       return produce(state, (draft) => {
//         draft.country = action.payload;
//       });
//     case types.SET_EMISSIONS_SUCCESS:
//       return produce(state, (draft) => {
//         draft.emission = 'Sc123';
//       });
//     default:
//       return state;
//   }
// }

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    // TODO: Figure this out
    getUserInfo: (state, action) => {
      state.alignmentYear = action.payload?.year?.currency?.toString();
      state.emissionYear = '2017';
      state.attribution = 'population';
      state.emissionType = 'production';
      if (state.moduleName === 'PCAF') {
        state.strategy = action.payload.footprint_parameters.Loan.strategy;
        state.emissionsType = action.payload.footprint_parameters.Loan.emissions_type;
        state.footprintMetric = action.payload.footprint_parameters.Loan.footprint;
      }
    },
    setFilterItemSuccess: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    setCountrySuccess: (state, action) => {
      state.country = action.payload;
    },
    // setEmissionsSuccess: (state) => {
    //   state.emission = 'Sc123';
    // },
  },
});

export const {
  getUserInfo,
  setCountrySuccess,
  // setEmissionsSuccess,
  setFilterItemSuccess,
} = filterSlice.actions;

export default filterSlice.reducer;
