import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { netZeroTargetSettingCells } from '../../../util/TableHeadConfig';
import { LoadingHOC } from '../../../util/LoadingHOC';
import TargetSettingDisplay from './TargetSettingDisplay';
import SectorModel from '../../../models/netZero/sector';
import useGetPayload from '../../../hooks/useGetPayload';
import { useGetNetZeroSectorQuery } from '../../../redux/apiHooks';

const TargetSettingWithLoading = LoadingHOC(TargetSettingDisplay);

const TargetSetting = () => {
  const { auth, filters, payload } = useGetPayload(SectorModel);
  const {
    data: sectorData,
    isFetching,
    error,
  } = useGetNetZeroSectorQuery(payload);

  const { userInfo } = auth;
  const { alignmentYear } = filters;

  const emissionYear = userInfo?.year?.emissions;

  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [categories, setCategories] = useState([]);

  const getData = () => {
    let cData = [];
    const tempTableData = [];
    const emissionsExpectedValues = [];
    const emissionsCurrentValues = [];
    const emissionsAlignedValues = [];

    if (!isEmpty(sectorData)) {
      const emissionsCurrent = sectorData.emissions?.current || {};
      const emissionsExpected = sectorData.emissions?.expected || {};
      const emissionsAligned = sectorData.emissions?.aligned || {};
      const reductionsRequired = sectorData.reductions?.required_average_reduction || {};
      const reductionsExpected = sectorData.reductions?.expected_average_reduction || {};

      const sectorKeys = Object.keys(emissionsCurrent).sort();
      if (!isEmpty(emissionsCurrent)) {
        sectorKeys.forEach((sector) => {
          emissionsCurrentValues.push(emissionsCurrent[sector]);
          tempTableData.push({
            sector,
            current_emissions: emissionsCurrent[sector],
            estimated_emissions: emissionsExpected[sector],
            aligned_emissions: emissionsAligned[sector],
            emissions_reduction_estimated: reductionsExpected[sector],
            emissions_reduction_required: reductionsRequired[sector],
          });
        });
      }
      if (!isEmpty(emissionsExpected)) {
        sectorKeys.forEach((sector) => {
          emissionsExpectedValues.push(emissionsExpected[sector]);
        });
      }
      if (!isEmpty(emissionsAligned)) {
        sectorKeys.forEach((sector) => {
          emissionsAlignedValues.push(emissionsAligned[sector]);
        });
      }

      cData = [
        {
          name: 'Current',
          data: emissionsCurrentValues,
        },
        {
          name: `Estimated for ${alignmentYear}`,
          data: emissionsExpectedValues,
        },
        {
          name: `Aligned in ${alignmentYear}`,
          data: emissionsAlignedValues,
        },
      ];
      setChartData(cData);
      setCategories(sectorKeys);
      setTableData(tempTableData);
    }
  };

  useEffect(() => {
    if (!isEmpty(sectorData)) {
      getData();
    }
  }, [sectorData, alignmentYear]);

  const yAxisTitle = sectorData?.emissions_metadata
    ? `${sectorData.emissions_metadata?.title} (${sectorData.emissions_metadata?.units})`
    : '';
  const units = sectorData?.emissions_metadata?.units || '';
  const tableColumns = netZeroTargetSettingCells(
    emissionYear,
    alignmentYear,
    units,
  );

  return (
    <TargetSettingWithLoading
      loading={isFetching}
      error={error}
      categories={categories}
      chartData={chartData}
      yAxisTitle={yAxisTitle}
      tableData={tableData}
      tableColumns={tableColumns}
    />
  );
};

export default TargetSetting;
