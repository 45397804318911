const footprintTabs = [
  'Portfolio Emissions',
  'Sector Emissions',
  'Company Emissions',
  'Carbon Attribution',
  'Disclosure',
  'Avoided Emissions',
  'Carbon Offset',
];

const footprintTabs2 = ['Portfolio Emissions', 'Breakdown by Asset Class'];
const scope3Tabs = ['Portfolio Scope 3 Heatmap', 'Sectoral Scope 3 Heatmap'];
const portOptimizationTabs = [
  'Portfolio Returns',
  'Portfolio Risk',
  'Optimized Emissions',
  'Performance Attribution',
];
const carbonRiskTabs = ['Portfolio Carbon Risk'];
const strandedAssetTabs = [
  'Fossil-Fuel Reserves Footprint',
  'Coal Power Analysis',
];
const tempMetricTabs = [
  'Portfolio Score',
  'Company Analysis',
  'Attribution',
  'Sectoral Score',
  'Contribution Analysis',
  'Heatmap',
];
const forwardTabs = [
  'Portfolio Alignment',
  'Target Setting',
  'Company Profile',
  'Carbon Adjusted Returns',
];

const fundOfFundsTabs = [
  'Composition',
  'Footprint',
  'Alignment',
  'Target Setting',
];

const sovereignAnalyticsTabs = [
  'Portfolio Emissions',
  'Country Emissions',
  'Historical Emissions',
];

const netZeroTabs = [
  'Decarbonisation Monitor',
  'Portfolio',
  'Sector',
  'Subplots',
  'Company',
];

const reportTabs = ['Tcfd', 'Portfolio Analytics Report', 'Benchmark Report'];

const portfolioBuilderTabs = ['Portfolio View', 'Allocation Details'];

const voluntaryCarbonMarketTabs = ['Company'];

export {
  footprintTabs,
  footprintTabs2,
  scope3Tabs,
  portOptimizationTabs,
  carbonRiskTabs,
  strandedAssetTabs,
  tempMetricTabs,
  forwardTabs,
  fundOfFundsTabs,
  sovereignAnalyticsTabs,
  netZeroTabs,
  reportTabs,
  portfolioBuilderTabs,
  voluntaryCarbonMarketTabs,
};
