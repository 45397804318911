/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { LoadingHOC } from '../../../util/LoadingHOC';
import CompanyEmissionDisplay from './CompanyEmissionDisplay';
import { getEmission } from '../../../helpers';
import useGetPayload from '../../../hooks/useGetPayload';
import CompanyEmissionModel from '../../../models/emissions/companyEmission';
import { useGetCompanyEmissionsQuery } from '../../../redux/apiHooks';
import useInferenceValue from '../../../hooks/useInferenceTypeHook';
import { companyEmissionCells } from '../../../util/TableHeadConfig';

const CompanyEmissionsWithLoading = LoadingHOC(CompanyEmissionDisplay);

const CompanyEmission = () => {
  const { filters, payload } = useGetPayload(CompanyEmissionModel);
  const {
    inferenceType, emission, footprintMetric, sector,
  } = filters;

  const {
    data: companyData,
    isFetching,
    error,
  } = useGetCompanyEmissionsQuery(payload);

  const inferenceValue = useInferenceValue(inferenceType);
  const emissionIntensity = getEmission(emission);

  const [sectorIntensityData, setIntensityData] = useState([]);
  const [contribData, setContribData] = useState([]);
  const [contriCategories, setContriCategories] = useState([]);
  const [intensityCategories, setIntensityCategories] = useState([]);
  const [contributionYaxisTitle, setContributionYTitle] = useState('');
  const [intensitiesYaxisTitle, setIntensityYTitle] = useState('');
  const [tableData, setTableData] = useState([]);
  const [selectedValue, setSelectedContriValue] = useState('top');

  const getIntensityData = (arr, n) => {
    if (n > arr.length) {
      return arr;
    }
    const data = arr.slice().sort((a, b) => b.y - a.y);
    return selectedValue === 'top' ? data.slice(0, n) : data.slice(-n);
  };

  const getChartData = (data) => {
    const chartArr = [
      {
        name: 'Portfolio',
        data,
      },
    ];
    return chartArr;
  };

  const getIntensityContribution = () => {
    let yTitle = '';
    if (!isEmpty(companyData.data)) {
      yTitle = `${companyData.metadata.contribution_label} ${companyData.metadata.unit}`;
      const portData = companyData.data.map((company) => ({
        y: company[inferenceValue].contribution[emissionIntensity],
        name: company.company_name,
      }));
      const data = getIntensityData(portData, 10);
      setContribData(getChartData(data));
      setContriCategories(data.map((d) => d.name));
      setContributionYTitle(yTitle);
    }
  };
  const getIntensities = () => {
    let yTitle = '';
    if (!isEmpty(companyData.data)) {
      yTitle = `${companyData.metadata.intensity_label} ${companyData.metadata.unit}`;
      const portData = companyData.data.map((company) => ({
        y: company[inferenceValue].intensity[emissionIntensity],
        name: company.company_name,
      }));
      const data = getIntensityData(portData, 10);
      setIntensityData(getChartData(data));
      setIntensityYTitle(yTitle);
      setIntensityCategories(data.map((d) => d.name));
      const tableContent = companyData.data.map((company) => ({
        ...company,
        emission: company[inferenceValue].emissions[emissionIntensity],
        contribution: company[inferenceValue].contribution[emissionIntensity],
        intensity: company[inferenceValue].intensity[emissionIntensity],
      }));
      setTableData(tableContent);
    }
  };

  useEffect(() => {
    if (companyData) {
      getIntensityContribution();
      getIntensities();
    }
  }, [companyData, selectedValue]);
  return (
    <CompanyEmissionsWithLoading
      error={error}
      contriCategories={contriCategories}
      intensityCategories={intensityCategories}
      horizontalBarData_1={contribData}
      selectedValue={selectedValue}
      horizontalBarData_2={sectorIntensityData}
      contributionYaxisTitle={contributionYaxisTitle}
      intensitiesYaxisTitle={intensitiesYaxisTitle}
      loading={isFetching}
      contributionTitle={
        footprintMetric === 'TotalCarbEmis'
          ? 'Contributors by Emissions'
          : 'Contributors by Intensities'
      }
      intensitiesTitle={
        footprintMetric === 'TotalCarbEmis' ? 'Emissions' : 'Intensities'
      }
      onChange={(e) => {
        setSelectedContriValue(e.target.value);
      }}
      columns={
        companyData
          ? companyEmissionCells(
            companyData?.metadata?.columns,
            emissionIntensity,
            sector,
          )
          : []
      }
      tableData={tableData}
    />
  );
};

export default CompanyEmission;
