import BaseRequest from '../baseRequest';
import { getScenarioValue } from '../../helpers';

export default class PortfolioScore extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem } = props;
    const { aggregation, defaultValue, scenario } = filterItem;
    this.aggregation_method = aggregation;
    this.default_score = defaultValue;
    this.what_if = getScenarioValue(scenario);
  }
}
