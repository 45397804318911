import React from 'react';
import { Paper } from '@material-ui/core';
// import TabPanel from '../../components/TabPanel';
// import { portfolioBuilderTabs } from '../../util/tabs-config';
// import useTabValue from '../../hooks/useTabValueHook';
import PortfolioView from './PortfolioView/PortfolioView';

function PortfolioFootprint() {
  // const [tabValue, onTabChange] = useTabValue();

  return (
    <Paper position="static" color="default">
      <PortfolioView />
    </Paper>
  );
}
export default PortfolioFootprint;
