import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { push } from 'connected-react-router';
import { NotificationManager } from 'react-notifications';
import UserSessionHandler from '../handler';
import { objectToQueryString } from '../helpers';
import { logoutUserSuccess } from './reducers/authReducer';
// eslint-disable-next-line import/no-cycle
// import { persistor } from './store';

export const CACHE_TIME = 60 * 60 * 3; // In seconds

export const RTK_TAGS = {
  USER: 'User',
  CURRENCY: 'Currency',
  PORTFOLIO_LIST: 'Portfolio_List',
};

const embedCookie = UserSessionHandler.getCookie();
const BASE_API_URL = embedCookie && process.env.REACT_APP_SF_API_URL
  ? process.env.REACT_APP_SF_API_URL
  : process.env.REACT_APP_API_URL;

const baseQueryWithReauth = async (args, api, extraOptions) => {
  const baseUrl = extraOptions?.baseUrl || BASE_API_URL;
  const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = UserSessionHandler.getAccessToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });
  const result = await baseQuery(args, api, extraOptions);
  const isTokenExpired = result?.error?.status === 401 || result?.error?.status === 403;
  const isUserLoggingOut = args.url === '/user/sso_sign_out';
  const isAuthFailed = args.url === '/user/sso_sign_in' && result.error;
  if (isTokenExpired || isUserLoggingOut || isAuthFailed) {
    if (isTokenExpired) {
      NotificationManager.error(
        'Missing Authentication Header. Pls re-login again',
      );
    }
    if (isAuthFailed) {
      const message = result.error?.message || 'Failed to verify ICE credentials';
      NotificationManager.error(message);
    }
    try {
      // eslint-disable-next-line no-use-before-define
      api.dispatch(apiSlice.util.resetApiState());
      UserSessionHandler.removeCookie();
      UserSessionHandler.logout();
      // eslint-disable-next-line no-undef
      // setTimeout(() => persistor.purge(), 200);
      api.dispatch(logoutUserSuccess());
      api.dispatch(push('/login'));
      return result;
    } catch (e) {
      NotificationManager.error('Logout Failed ! try again');
    }
  }
  return result;
};

const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  keepUnusedDataFor: CACHE_TIME,
  tagTypes: Object.values(RTK_TAGS),
});

export const getQuery = {
  get:
    (url) => (query = '') => {
      if (typeof query === 'object') return url + objectToQueryString(query);
      return url + query;
    },
  post: (url) => (body) => ({
    url,
    method: 'POST',
    body,
  }),
  put: (url) => (body) => ({
    url,
    method: 'PUT',
    body,
  }),
  delete:
    (url) => (query = '') => ({
      url: url + query,
      method: 'DELETE',
    }),
};

export default apiSlice;
