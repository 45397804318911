import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import DataTable from '../../components/Table/DataTable';
import {
  useGetUserDownloadPortfoliosQuery,
  useGetUserDeletePortfoliosMutation,
} from '../../redux/apiHooks';

const usePickerStyle = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    color: `${theme.palette.primary.main} !important`,
    backgroundColor: 'transparent !important',
    height: 35,
    display: 'flex',
    padding: '4px 10px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    gap: '10px',
    '&:hover': {
      backgroundColor: 'transparent !important',
      color: `${theme.palette.primary.main} !important`,
    },
  },
}));

const headCells = [
  {
    name: 'Portfolio Name',
    selector: 'label',
    sortable: true,
    right: false,
    wrap: true,
  },
  {
    name: 'Emission Coverage (%)',
    selector: 'coverageEmissions',
    sortable: true,
    right: true,
    wrap: true,
  },
  {
    name: 'Processing Date',
    selector: 'date_created',
    sortable: true,
    right: true,
    wrap: true,
    cell: (row) => (
      <div style={{ marginLeft: 40 }}>
        {row.date_created
          && moment(row.date_created).format('DD-MM-YYYY hh:mm:ss')}
      </div>
    ),
  },
];
const RemovePortfolios = () => {
  const classes = usePickerStyle();
  const [dialog, setDialog] = useState(false);
  const [selectedPortfolios, setSelectedPortfolios] = useState([]);

  const { isFetching } = useGetUserDownloadPortfoliosQuery();
  const [deletePortfolioRequest, { isLoading }] = useGetUserDeletePortfoliosMutation();

  const downloadPortfolioList = useSelector(
    (state) => state.auth.downloadPortfolioList,
  );

  const deletePortfolio = async () => {
    const { error } = await deletePortfolioRequest(selectedPortfolios.join());
    if (error) {
      NotificationManager.error(
        typeof error === 'string' ? error : 'Unable to delete the portfolio!',
      );
    } else {
      NotificationManager.success('Portfolio deleted successfully');
    }
    setDialog(false);
  };

  const handleSelectedRowsChange = (selPortfolios) => {
    setSelectedPortfolios(selPortfolios.map((portfolio) => portfolio.value));
  };

  return (
    <>
      <Box>
        <Box
          mt={1}
          mb={1}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            color="primary"
            disabled={selectedPortfolios.length === 0}
            style={{ width: 150 }}
            onClick={() => setDialog(true)}
          >
            Delete
          </Button>
        </Box>
        <DataTable
          data={downloadPortfolioList}
          columns={headCells}
          tableHeading="UPLOAD_PORTFOLIO"
          isSelectableRows={true}
          handleSelection={handleSelectedRowsChange}
          loading={isFetching}
          selectableRowSelected={(item) => selectedPortfolios.includes(item.value)
          }
        />
      </Box>
      <Dialog open={dialog}>
        <DialogTitle>
          Are you sure you want to delete{' '}
          {selectedPortfolios.length === 1
            ? '1 Portfolio'
            : `${selectedPortfolios.length} Portfolios`}
          ?
        </DialogTitle>
        <DialogActions>
          <Button
            className={classes.container}
            onClick={() => setDialog(false)}
          >
            Cancel
          </Button>
          <LoadingButton loading={isLoading} onClick={deletePortfolio}>
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RemovePortfolios;
