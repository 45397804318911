import BaseRequest from '../baseRequest';

export default class FossilFuel extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem, moduleName } = props;
    const { footprintMetric, assetClass, marketValue } = filterItem;

    this.asset_type = assetClass;
    this.footprint = moduleName === 'TCFD' ? 'WeightAvgMarketVal' : footprintMetric;
    this.market_value = moduleName === 'TCFD' ? 'EnterpriseValCash' : marketValue;
  }
}
