import React from 'react';
import PropTypes from 'prop-types';
import ChartProps from './ChartProps';
import useCurrentTheme from '../../hooks/useCurrentTheme';
import BaseChart from './BaseChart';

function StackedBar({ barWidth, max, ...props }) {
  const { usedStyles } = useCurrentTheme(props.theme);
  const options = {
    yAxis: {
      max,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        pointWidth: barWidth,
        borderWidth: 1,
        borderColor: usedStyles.themeType === 'dark' ? '#000000' : '#FFFFFF',
      },
    },
  };
  return (
    <BaseChart
      {...props}
      chartType="bar"
      legendType="horizontal"
      options={options}
    />
  );
}

StackedBar.propTypes = {
  ...ChartProps,
  barWidth: PropTypes.number,
};
export default StackedBar;
