const colorSchema = {
  light: {
    themeType: 'light',
    primary: '#1E2732',
    secondary: '#1E2732',
    buttonText: '#F7DC81',
    disabledBackground: '#6f7378',
    disabledText: '#898485',
    mainBackground: '#f5f5f5',
    topNavBackground: '#1E2732',
    sideNavBackground: '#ffffff',
    tabHeaderBackground: '#ffffff',
    tableHeaderBackground: '#1E2732',
    tableHeaderText: '#F7DC81',
    text: '#000000',
    textSecondary: '#000000',
    chartText: '#000000',
    inputLabel: '#000000bd',
    iconColor: '#000000',
    clearIconColor: '#000000',
    chartColors: [
      '#1E2732',
      '#F7DC81',
      '#7d7551',
      '#31d6c9',
      '#bbbfbf',
      '#a0d911',
      '#36cfc9',
      '#40a9ff',
      '#f759ab',
      '#22075e',
    ],
  },

  dark: {
    themeType: 'dark',
    primary: '#1BDECB',
    secondary: '#1BDECB',
    buttonText: '#000000',
    disabledBackground: '#9bded8',
    disabledText: '#6e6a6a',
    topNavBackground: '#424242',
    sideNavBackground: '#424242',
    tabHeaderBackground: '#424242',
    tableHeaderBackground: '#424242',
    tableHeaderText: '#FFFFFF',
    mainBackground: '#303030',
    text: '#FFFFFF',
    textSecondary: '#FFFFFF',
    chartText: '#fff',
    inputLabel: '#ffffffbd',
    iconColor: '#FFFFFF',
    clearIconColor: '#FFFFFF',
    chartColors: [
      '#1bdecb',
      '#FF991F',
      '#C595FB',
      '#FF0000',
      '#b7efa3',
      '#0E77CA',
      '#77a1e5',
      '#c42525',
      '#a6c96a',
    ],
  },
  cosmos: {
    themeType: 'dark',
    primary: '#ADEF97',
    secondary: '#ADEF97',
    buttonText: '#000000',
    disabledBackground: '#d5e8cf',
    disabledText: '#817777',
    topNavBackground: '#334e68',
    sideNavBackground: '#334e68',
    tabHeaderBackground: '#2e445b',
    tableHeaderBackground: '#2e445b',
    tableHeaderText: '#FFFFFF',
    mainBackground: '#102a43',
    text: '#FFFFFF',
    textSecondary: '#FFFFFF',
    chartText: '#FFFFFF',
    inputLabel: '#ffffffbd',
    iconColor: '#FFFFFF',
    clearIconColor: '#FFFFFF',
    chartColors: [
      '#ADEF97',
      '#FF991F',
      '#C595FB',
      '#FF0000',
      '#ccd9c7',
      '#0E77CA',
      '#1f3d6e',
      '#c42525',
      '#bf6dac',
    ],
  },
  night: {
    themeType: 'dark',
    primary: '#2f8d8f',
    secondary: '#2f8d8f',
    buttonText: '#fff',
    disabledBackground: '#7ba4a5',
    disabledText: '#d7d0d0',
    topNavBackground: '#2a3f54',
    sideNavBackground: '#2a3f54',
    tabHeaderBackground: '#2e445b',
    tableHeaderBackground: '#2e445b',
    tableHeaderText: '#FFFFFF',
    mainBackground: '#4b5b67',
    text: '#FFFFFF',
    textSecondary: '#FFFFFF',
    chartText: '#00eaea',
    inputLabel: '#ffffffbd',
    iconColor: '#FFFFFF',
    clearIconColor: '#FFFFFF',
    chartColors: [
      '#2f8d8f',
      '#2a3f54',
      '#00eaea',
      '#2c86a0',
      '#788fa1',
      '#0E77CA',
      '#77a1e5',
      '#c42525',
      '#a6c96a',
    ],
  },
  'ICE light': {
    themeType: 'light',
    primary: '#71C5EB', // ICE blue
    secondary: '#FFFFFF', // white
    buttonText: '#000000', // black
    disabledBackground: '#a5d1e4',
    disabledText: '#858383',
    mainBackground: '#FFFFFF',
    topNavBackground: '#4E4E4E',
    sideNavBackground: '#4E4E4E',
    tabHeaderBackground: '#4E4E4E',
    tableHeaderBackground: '#4E4E4E',
    tabHeaderText: '#FFFFFF',
    tableHeaderText: '#FFFFFF', // black
    selectedFilterText: '#000000',
    text: '#000000',
    textSecondary: '#FFFFFF',
    chartText: '#000000',
    inputLabel: '#000000bd',
    iconColor: '#FFFFFF',
    clearIconColor: '#000000',
    chartColors: [
      '#000000',
      '#71C5EB',
      '#839AFF',
      '#02EFDB',
      '#7CEF4A',
      '#0F364A',
      '#97AE88',
      '#40a9ff',
      '#f759ab',
      '#22075e',
    ],
  },
};

export default colorSchema;
