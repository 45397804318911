import BaseRequest from '../baseRequest';

export default class RiskContributor extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem } = props;
    const {
      footprintMetric, assetClass, marketValue, inferenceType, sector,
    } = filterItem;

    this.asset_type = assetClass;
    this.footprint = footprintMetric;
    this.market_value = Array.isArray(marketValue)
      ? marketValue[0]
      : marketValue;
    this.inference = inferenceType;
    this.sector = sector;
  }
}
