/* eslint-disable react/prop-types */
import React from 'react';
import DataTable from '../../../components/Table/DataTable';
import BubbleChart from '../../../components/Charts/BubbleChart';

const RiskContributorDisplay = (props) => (
  <React.Fragment>
    <BubbleChart
      data={props.data}
      title="Portfolio Risk Contributions*"
      xAxisLabel={props.xAxisLabel}
      yAxisLabel={props.yAxisLabel}
      zAxisLabel={props.zAxisLabel}
      xAxisTitle={props.xAxisTitle}
      yAxisTitle={props.yAxisTitle}
    />
    <DataTable
      data={props.dataTableData}
      columns={props.columns}
      tableHeading={props.tableHeading}
    />
  </React.Fragment>
);

export default RiskContributorDisplay;
