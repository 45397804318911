import React from 'react';
import PropTypes from 'prop-types';
import useCurrentTheme from '../../hooks/useCurrentTheme';
import BaseChart from './BaseChart';
import ChartProps from './ChartProps';

function ColumnChart({ barWidth, ...props }) {
  const { usedStyles } = useCurrentTheme(props.theme);
  const options = {
    chart: {
      zoomType: 'xy',
    },
    legend: {
      layout: 'horizontal',
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        // #TO-DO: Move Barwidth to BaseChart
        pointWidth: barWidth,
        borderColor: usedStyles.themeType === 'dark' ? '#000000' : '#FFFFFF',
      },
    },
  };
  return (
    <BaseChart
      {...props}
      height={props.height || 550}
      chartType="column"
      yAxisGridlineShow={true}
      options={options}
    />
  );
}

ColumnChart.propTypes = {
  ...ChartProps,
  barWidth: PropTypes.number,
};
export default ColumnChart;
