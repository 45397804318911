import * as constants from './constants';

const config = [
  {
    moduleName: 'Emission',
    path: '/portfolio-footprint',
    moduleTabs: [
      {
        tab: constants.PORTFOLIO_EMISSIONS,
        show_toggle: constants.TRUE,
        benchmark_disabled: (showBenchmark) => !showBenchmark,
      },
      {
        tab: constants.SECTORAL_EMISSIONS,
        show_toggle: constants.TRUE,
        benchmark_disabled: (showBenchmark) => !showBenchmark,
      },
      {
        tab: constants.COMPANY_EMISSIONS,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.TRUE,
      },
      {
        tab: constants.CARBON_ATTRIBUTION,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.FALSE,
      },
      {
        tab: constants.AVOIDED_EMISSIONS,
        show_toggle: constants.TRUE,
        benchmark_disabled: (showBenchmark) => !showBenchmark,
      },
      {
        tab: constants.DISCLOSURE,
        show_toggle: constants.TRUE,
        benchmark_disabled: (showBenchmark) => !showBenchmark,
      },
      {
        tab: constants.CARBON_OFFSET_COMPANY,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.TRUE,
      },
    ],
    filters: [
      {
        filterName: constants.METRIC,
        key: constants.metricKey,
        options: [
          {
            name: constants.WEIGHTED_AVG_INTENSITY_REV,
            value: constants.WEIGHTED_AVG_INTENSITY_REV_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_ATTRIBUTION,
              constants.AVOIDED_EMISSIONS,
              constants.COMPANY_EMISSIONS,
            ],
          },
          {
            name: constants.WEIGHTED_AVG_INTENSITY_MARKET_MARKET,
            value: constants.WEIGHTED_AVG_INTENSITY_MARKET_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_ATTRIBUTION,
              constants.AVOIDED_EMISSIONS,
              constants.COMPANY_EMISSIONS,
            ],
          },
          {
            name: constants.TOTAL_CARBON_EMISSION,
            value: constants.TOTAL_CARBON_EMISSION_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_ATTRIBUTION,
              constants.AVOIDED_EMISSIONS,
              constants.COMPANY_EMISSIONS,
            ],
          },
        ],
        default: [constants.WEIGHTED_AVG_INTENSITY_REV],
        tabs: [
          {
            tab: constants.PORTFOLIO_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTORAL_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.CARBON_ATTRIBUTION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.AVOIDED_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.COMPANY_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.MARKET,
        key: constants.marketValueKey,
        options: [
          {
            name: constants.MARKET_CAPITALIZATION,
            value: constants.MARKET_CAPITALIZATION_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_ATTRIBUTION,
              constants.AVOIDED_EMISSIONS,
            ],
          },
          {
            name: constants.MARKET_CAPITALIZATION_DEBT,
            value: constants.MARKET_CAPITALIZATION_DEBT_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_ATTRIBUTION,
              constants.AVOIDED_EMISSIONS,
              constants.COMPANY_EMISSIONS,
            ],
          },
          {
            name: constants.TOTAL_DEBT,
            value: constants.TOTAL_DEBT_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_ATTRIBUTION,
              constants.AVOIDED_EMISSIONS,
              constants.COMPANY_EMISSIONS,
            ],
          },
          {
            name: constants.ENTERPRISE,
            value: constants.ENTERPRISE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_ATTRIBUTION,
              constants.AVOIDED_EMISSIONS,
              constants.COMPANY_EMISSIONS,
            ],
          },
          {
            name: constants.ENTERPRISE_VALUE_CASH,
            value: constants.ENTERPRISE_VALUE_CASH_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_ATTRIBUTION,
              constants.AVOIDED_EMISSIONS,
              constants.COMPANY_EMISSIONS,
            ],
          },
        ],
        default: [constants.MARKET_CAPITALIZATION],
        tabs: [
          {
            tab: constants.PORTFOLIO_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTORAL_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.CARBON_ATTRIBUTION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.AVOIDED_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.COMPANY_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.ASSET,
        key: constants.assetClassKey,
        options: [
          {
            name: constants.EQUITY,
            value: constants.EQUITY_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_ATTRIBUTION,
              constants.AVOIDED_EMISSIONS,
              constants.CARBON_OFFSET_COMPANY,
              constants.COMPANY_EMISSIONS,
            ],
          },
          {
            name: constants.CORPORATE,
            value: constants.CORPORATE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_ATTRIBUTION,
              constants.AVOIDED_EMISSIONS,
              constants.CARBON_OFFSET_COMPANY,
              constants.COMPANY_EMISSIONS,
            ],
          },
          {
            name: constants.LOANS,
            value: constants.LOANS_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_ATTRIBUTION,
              constants.AVOIDED_EMISSIONS,
              constants.COMPANY_EMISSIONS,
            ],
          },
        ],
        default: [constants.EQUITY, constants.CORPORATE],
        tabs: [
          {
            tab: constants.PORTFOLIO_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
          {
            tab: constants.SECTORAL_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
          {
            tab: constants.CARBON_ATTRIBUTION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
          {
            tab: constants.AVOIDED_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
          {
            tab: constants.CARBON_OFFSET_COMPANY,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
          {
            tab: constants.COMPANY_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.INFERENCE_TYPE,
        key: constants.inferenceTypeKey,
        options: [
          {
            name: constants.AVERAGE,
            value: constants.AVERAGE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_ATTRIBUTION,
              constants.AVOIDED_EMISSIONS,
              constants.CARBON_OFFSET_COMPANY,
              constants.COMPANY_EMISSIONS,
            ],
          },
          {
            name: constants.MAXIMUM,
            value: constants.MAXIMUM_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_ATTRIBUTION,
              constants.AVOIDED_EMISSIONS,
            ],
          },
        ],
        default: [constants.AVERAGE],
        tabs: [
          {
            tab: constants.PORTFOLIO_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTORAL_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.CARBON_ATTRIBUTION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.AVOIDED_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.CARBON_OFFSET_COMPANY,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.COMPANY_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.EMISSIONS,
        key: constants.emissionKey,
        options: [
          {
            name: constants.SCOPE_1_2,
            value: constants.SCOPE_1_2_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_ATTRIBUTION,
              constants.CARBON_OFFSET_COMPANY,
              constants.COMPANY_EMISSIONS,
            ],
          },
          {
            name: constants.SCOPE_1_2_3,
            value: constants.SCOPE_1_2_3_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_ATTRIBUTION,
              constants.CARBON_OFFSET_COMPANY,
              constants.COMPANY_EMISSIONS,
            ],
          },
        ],
        default: [constants.SCOPE_1_2],
        tabs: [
          {
            tab: constants.PORTFOLIO_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTORAL_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.CARBON_ATTRIBUTION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.CARBON_OFFSET_COMPANY,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.COMPANY_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.SECTOR_CLASSIFICATION,
        key: constants.sectorClassificationKey,
        options: [
          {
            name: constants.SASB,
            value: constants.SASB_VALUE,
            allowedTabs: [
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_ATTRIBUTION,
              constants.COMPANY_EMISSIONS,
            ],
          },
          {
            name: constants.ICE,
            value: constants.ICE_VALUE,
            allowedTabs: [
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_ATTRIBUTION,
              constants.COMPANY_EMISSIONS,
            ],
          },
        ],
        default: [constants.SASB],
        tabs: [
          {
            tab: constants.SECTORAL_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.CARBON_ATTRIBUTION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.COMPANY_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],

        dependency: [],
      },
      {
        filterName: constants.SECTOR_CLASSIFICATION,
        key: constants.sectorClassificationKey,
        options: [
          {
            name: constants.SASB,
            value: constants.SASB_VALUE,
            allowedTabs: [constants.CARBON_OFFSET_COMPANY],
          },
          {
            name: constants.ICE,
            value: constants.ICE_VALUE,
            allowedTabs: [constants.CARBON_OFFSET_COMPANY],
          },
        ],
        default: [constants.SASB],
        tabs: [
          {
            tab: constants.CARBON_OFFSET_COMPANY,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],

        dependency: [],
      },
    ],
  },
  {
    moduleName: 'FLM',
    path: '/forward-looking-analysis',
    moduleTabs: [
      {
        tab: constants.PORTFOLIO_ALIGNMENT,
        show_toggle: constants.TRUE,
        benchmark_disabled: (showBenchmark) => !showBenchmark,
      },
      {
        tab: constants.TARGET_SETTING,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.TRUE,
      },
      {
        tab: constants.COMPANY_PROFILE,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.TRUE,
      },
      {
        tab: constants.CARBON_ADJUSTED_RETURNS,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.TRUE,
      },
    ],
    filters: [
      {
        filterName: constants.METRIC,
        key: constants.metricKey,
        options: [
          {
            name: constants.WEIGHTED_AVG_INTENSITY_REV,
            value: constants.WEIGHTED_AVG_INTENSITY_REV_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_ALIGNMENT,
              constants.TARGET_SETTING,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
          {
            name: constants.WEIGHTED_AVG_INTENSITY_MARKET_MARKET,
            value: constants.WEIGHTED_AVG_INTENSITY_MARKET_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_ALIGNMENT,
              constants.TARGET_SETTING,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
          {
            name: constants.TOTAL_CARBON_EMISSION,
            value: constants.TOTAL_CARBON_EMISSION_VALUE,
            allowedTabs: [
              constants.TARGET_SETTING,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
        ],
        default: [constants.WEIGHTED_AVG_INTENSITY_REV],
        tabs: [
          {
            tab: constants.PORTFOLIO_ALIGNMENT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.TARGET_SETTING,
            disabled: (current) => current.approach === 'MarketShare',
            allow_multiple: constants.FALSE,
            disabled_default: [constants.TOTAL_CARBON_EMISSION],
          },
          {
            tab: constants.COMPANY_PROFILE,
            disabled: (current) => current.approach === 'MarketShare',
            allow_multiple: constants.FALSE,
            disabled_default: [constants.TOTAL_CARBON_EMISSION],
          },
          {
            tab: constants.CARBON_ADJUSTED_RETURNS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.MARKET,
        key: constants.marketValueKey,
        options: [
          {
            name: constants.MARKET_CAPITALIZATION,
            value: constants.MARKET_CAPITALIZATION_VALUE,
            allowedTabs: [
              constants.TARGET_SETTING,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
          {
            name: constants.MARKET_CAPITALIZATION_DEBT,
            value: constants.MARKET_CAPITALIZATION_DEBT_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_ALIGNMENT,
              constants.TARGET_SETTING,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
          {
            name: constants.TOTAL_DEBT,
            value: constants.TOTAL_DEBT_VALUE,
            allowedTabs: [
              constants.TARGET_SETTING,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
          {
            name: constants.ENTERPRISE,
            value: constants.ENTERPRISE_VALUE,
            allowedTabs: [
              constants.TARGET_SETTING,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
          {
            name: constants.ENTERPRISE_VALUE_CASH,
            value: constants.ENTERPRISE_VALUE_CASH_VALUE,
            allowedTabs: [
              constants.TARGET_SETTING,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
        ],
        default: [constants.MARKET_CAPITALIZATION],
        tabs: [
          {
            tab: constants.PORTFOLIO_ALIGNMENT,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
            default: [constants.MARKET_CAPITALIZATION_DEBT],
          },
          {
            tab: constants.TARGET_SETTING,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.COMPANY_PROFILE,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.CARBON_ADJUSTED_RETURNS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.ASSET,
        key: constants.assetClassKey,
        options: [
          {
            name: constants.EQUITY,
            value: constants.EQUITY_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_ALIGNMENT,
              constants.TARGET_SETTING,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
          {
            name: constants.CORPORATE,
            value: constants.CORPORATE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_ALIGNMENT,
              constants.TARGET_SETTING,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
          {
            name: constants.LOANS,
            value: constants.LOANS_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_ALIGNMENT,
              constants.TARGET_SETTING,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
        ],
        default: [constants.EQUITY, constants.CORPORATE],
        tabs: [
          {
            tab: constants.PORTFOLIO_ALIGNMENT,
            disabled: () => constants.TRUE,
            allow_multiple: constants.TRUE,
          },
          { tab: constants.TARGET_SETTING, disabled: () => constants.TRUE },
          {
            tab: constants.COMPANY_PROFILE,
            disabled: () => constants.TRUE,
            allow_multiple: constants.TRUE,
            default: [constants.EQUITY, constants.CORPORATE],
          },
          {
            tab: constants.CARBON_ADJUSTED_RETURNS,
            disabled: () => constants.TRUE,
            default: [constants.EQUITY],
            allow_multiple: constants.TRUE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.INFERENCE_TYPE,
        key: constants.inferenceTypeKey,
        options: [
          {
            name: constants.AVERAGE,
            value: constants.AVERAGE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_ALIGNMENT,
              constants.TARGET_SETTING,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
          {
            name: constants.MAXIMUM,
            value: constants.MAXIMUM_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_ALIGNMENT,
              constants.TARGET_SETTING,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
        ],
        default: [constants.AVERAGE],
        tabs: [
          {
            tab: constants.PORTFOLIO_ALIGNMENT,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.TARGET_SETTING,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.COMPANY_PROFILE,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.CARBON_ADJUSTED_RETURNS,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.EMISSIONS,
        key: constants.emissionKey,
        options: [
          {
            name: constants.SCOPE_1_2_3,
            value: constants.SCOPE_1_2_3_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_ALIGNMENT,
              constants.TARGET_SETTING,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
          {
            name: constants.SCOPE_1_2,
            value: constants.SCOPE_1_2_VALUE,
            allowedTabs: [
              constants.TARGET_SETTING,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
        ],
        default: [constants.SCOPE_1_2_3],
        tabs: [
          {
            tab: constants.PORTFOLIO_ALIGNMENT,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.TARGET_SETTING,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.COMPANY_PROFILE,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.CARBON_ADJUSTED_RETURNS,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.SET_1_5_SCENARIO,
        key: constants.portScenarioKey,
        options: [
          {
            name: constants.SSP_1,
            value: constants.SSP_1_26_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_ALIGNMENT,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
          {
            name: constants.SSP_2,
            value: constants.SSP_2_26_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_ALIGNMENT,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
          {
            name: constants.LOW_ENERGY_DEMAND_1_5,
            value: constants.LOW_ENERGY_DEMAND_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_ALIGNMENT,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
        ],
        default: [constants.LOW_ENERGY_DEMAND_1_5],
        tabs: [
          {
            tab: constants.PORTFOLIO_ALIGNMENT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.COMPANY_PROFILE,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.SCENARIO_DB,
        key: constants.targetScenarioKey,
        options: [
          {
            name: constants.IPCC_1_5,
            value: constants.IPCC_1_5_VALUE,
            allowedTabs: [constants.TARGET_SETTING, constants.COMPANY_PROFILE],
          },
          {
            name: constants.IEA,
            value: constants.IEA,
            allowedTabs: [constants.TARGET_SETTING, constants.COMPANY_PROFILE],
          },
          {
            name: constants.NGFS,
            value: constants.NGFS,
            allowedTabs: [constants.TARGET_SETTING, constants.COMPANY_PROFILE],
          },
        ],
        default: [constants.IPCC_1_5],
        tabs: [
          { tab: constants.TARGET_SETTING, disabled: () => constants.FALSE },
        ],
        dependency: [],
      },
      {
        filterName: constants.WARNING_SCENARIO,
        key: constants.warmingScenarioKey,
        options: [
          {
            dependantName: constants.IPCC_1_5_VALUE,
            options: [
              {
                name: constants.SSP_1_26,
                value: constants.SSP_1_26_VALUE,
                allowedTabs: [
                  constants.PORTFOLIO_ALIGNMENT,
                  constants.COMPANY_PROFILE,
                ],
              },
              {
                name: constants.SSP_2_26,
                value: constants.SSP_2_26_VALUE,
                allowedTabs: [
                  constants.PORTFOLIO_ALIGNMENT,
                  constants.COMPANY_PROFILE,
                ],
              },
              {
                name: constants.LOW_ENERGY_DEMAND,
                value: constants.LOW_ENERGY_DEMAND_VALUE,
                allowedTabs: [
                  constants.PORTFOLIO_ALIGNMENT,
                  constants.COMPANY_PROFILE,
                ],
              },
              {
                name: constants.SSP_4_26,
                value: constants.SSP_4_26_VALUE,
                allowedTabs: [
                  constants.PORTFOLIO_ALIGNMENT,
                  constants.COMPANY_PROFILE,
                ],
              },
              {
                name: constants.SSP_5_26,
                value: constants.SSP_5_26_VALUE,
                allowedTabs: [
                  constants.PORTFOLIO_ALIGNMENT,
                  constants.COMPANY_PROFILE,
                ],
              },
            ],
            default: [constants.LOW_ENERGY_DEMAND],
          },
          {
            dependantName: constants.IEA,
            options: [
              {
                name: constants.BEYOND_2_DEGREES,
                value: constants.BEYOND_2_DEGREES_VALUE,
                allowedTabs: [constants.TARGET_SETTING],
              },
              {
                name: constants.TWO_DEGREES,
                value: constants.TWO_DEGREES_VALUE,
                allowedTabs: [constants.TARGET_SETTING],
              },
              {
                name: constants.REFERENCE_TECHNOLOGY,
                value: constants.REFERENCE_TECHNOLOGY_VALUE,
                allowedTabs: [constants.TARGET_SETTING],
              },
            ],
            default: [constants.BEYOND_2_DEGREES],
          },
          {
            dependantName: constants.NGFS,
            options: [
              {
                name: constants.CURRENT_POLICIES,
                value: constants.CURRENT_POLICIES,
                allowedTabs: [constants.TARGET_SETTING],
              },
              {
                name: constants.IMMEDIATE_2C,
                value: constants.IMMEDIATE_2C,
                allowedTabs: [constants.TARGET_SETTING],
              },
              {
                name: constants.DELAYED_2C,
                value: constants.DELAYED_2C,
                allowedTabs: [constants.TARGET_SETTING],
              },
              {
                name: constants.IMMEDIATE_1_5C,
                value: constants.IMMEDIATE_1_5C,
                allowedTabs: [constants.TARGET_SETTING],
              },
              {
                name: constants.IMMEDIATE_1_5C_LTD_CDR,
                value: constants.IMMEDIATE_1_5C_LTD_CDR,
                allowedTabs: [constants.TARGET_SETTING],
              },
              {
                name: constants.IMMEDIATE_2C_LTD_CDR,
                value: constants.IMMEDIATE_2C_LTD_CDR,
                allowedTabs: [constants.TARGET_SETTING],
              },
              {
                name: constants.DELAYED_2C_LTD,
                value: constants.DELAYED_2C_LTD,
                allowedTabs: [constants.TARGET_SETTING],
              },
            ],
            default: [constants.CURRENT_POLICIES],
          },
        ],
        default: [constants.LOW_ENERGY_DEMAND],
        tabs: [
          {
            tab: constants.TARGET_SETTING,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [constants.targetScenarioKey],
      },
      {
        filterName: constants.ALIGNMENT,
        key: constants.alignmentYearKey,
        options: [
          {
            name: constants.YEAR_2030,
            value: constants.YEAR_2030,
            allowedTabs: [constants.TARGET_SETTING],
          },
          {
            name: constants.YEAR_2040,
            value: constants.YEAR_2040,
            allowedTabs: [constants.TARGET_SETTING],
          },
          {
            name: constants.YEAR_2050,
            value: constants.YEAR_2050,
            allowedTabs: [constants.TARGET_SETTING],
          },
          {
            name: constants.YEAR_2060,
            value: constants.YEAR_2060,
            allowedTabs: [constants.TARGET_SETTING],
          },
        ],
        default: [constants.YEAR_2030],
        tabs: [
          {
            tab: constants.TARGET_SETTING,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.APPROACH,
        key: constants.approachKey,
        options: [
          {
            name: constants.MARKET_SHARE,
            value: constants.MARKET_SHARE_VALUE,
            allowedTabs: [constants.TARGET_SETTING, constants.COMPANY_PROFILE],
          },
          {
            name: constants.RELATIVE_ALIGNMENT,
            value: constants.RELATIVE_ALIGNMENT_VALUE,
            allowedTabs: [constants.TARGET_SETTING, constants.COMPANY_PROFILE],
          },
        ],
        default: [constants.MARKET_SHARE],
        tabs: [
          {
            tab: constants.TARGET_SETTING,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.COMPANY_PROFILE,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.SECTOR_CLASSIFICATION,
        key: constants.sectorClassificationKey,
        options: [
          {
            name: constants.SASB,
            value: constants.SASB_VALUE,
            allowedTabs: [
              constants.TARGET_SETTING,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
          {
            name: constants.ICE,
            value: constants.ICE_VALUE,
            allowedTabs: [
              constants.TARGET_SETTING,
              constants.COMPANY_PROFILE,
              constants.CARBON_ADJUSTED_RETURNS,
            ],
          },
        ],
        default: [constants.SASB],
        tabs: [
          {
            tab: constants.TARGET_SETTING,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.COMPANY_PROFILE,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.CARBON_ADJUSTED_RETURNS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
    ],
  },
  {
    moduleName: 'Optimization',
    path: '/portfolio-optimization',
    moduleTabs: [
      {
        tab: constants.PORTFOLIO_RETURNS,
        show_toggle: constants.TRUE,
        benchmark_disabled: (showBenchmark) => !showBenchmark,
      },
      {
        tab: constants.PORTFOLIO_RISK,
        show_toggle: constants.TRUE,
        benchmark_disabled: (showBenchmark) => !showBenchmark,
      },
      {
        tab: constants.OPTIMIZED_EMISSION,
        show_toggle: constants.TRUE,
        benchmark_disabled: (showBenchmark) => !showBenchmark,
      },
      {
        tab: constants.PERFORMANCE_ATTRIBUTION,
        show_toggle: constants.FALSE,
        benchmark_disabled: (showBenchmark) => !showBenchmark,
      },
    ],
    filters: [
      {
        filterName: constants.METRIC,
        key: constants.metricKey,
        options: [
          {
            name: constants.WEIGHTED_AVG_INTENSITY_REV,
            value: constants.WEIGHTED_AVG_INTENSITY_REV_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_RETURNS,
              constants.PORTFOLIO_RISK,
              constants.OPTIMIZED_EMISSION,
            ],
          },
          {
            name: constants.WEIGHTED_AVG_INTENSITY_MARKET_MARKET,
            value: constants.WEIGHTED_AVG_INTENSITY_MARKET_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_RETURNS,
              constants.PORTFOLIO_RISK,
              constants.OPTIMIZED_EMISSION,
            ],
          },
          {
            name: constants.TOTAL_CARBON_EMISSION,
            value: constants.TOTAL_CARBON_EMISSION_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_RETURNS,
              constants.PORTFOLIO_RISK,
              constants.OPTIMIZED_EMISSION,
            ],
          },
        ],
        default: [constants.WEIGHTED_AVG_INTENSITY_REV],
        tabs: [
          {
            tab: constants.PORTFOLIO_RETURNS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.PORTFOLIO_RISK,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.OPTIMIZED_EMISSION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.MARKET,
        key: constants.marketValueKey,
        options: [
          {
            name: constants.MARKET_CAPITALIZATION,
            value: constants.MARKET_CAPITALIZATION_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_RETURNS,
              constants.PORTFOLIO_RISK,
              constants.OPTIMIZED_EMISSION,
            ],
          },
          {
            name: constants.MARKET_CAPITALIZATION_DEBT,
            value: constants.MARKET_CAPITALIZATION_DEBT_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_RETURNS,
              constants.PORTFOLIO_RISK,
              constants.OPTIMIZED_EMISSION,
            ],
          },
          {
            name: constants.TOTAL_DEBT,
            value: constants.TOTAL_DEBT_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_RETURNS,
              constants.PORTFOLIO_RISK,
              constants.OPTIMIZED_EMISSION,
            ],
          },
          {
            name: constants.ENTERPRISE,
            value: constants.ENTERPRISE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_RETURNS,
              constants.PORTFOLIO_RISK,
              constants.OPTIMIZED_EMISSION,
            ],
          },
          {
            name: constants.ENTERPRISE_VALUE_CASH,
            value: constants.ENTERPRISE_VALUE_CASH_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_RETURNS,
              constants.PORTFOLIO_RISK,
              constants.OPTIMIZED_EMISSION,
            ],
          },
        ],
        default: [constants.MARKET_CAPITALIZATION],
        tabs: [
          {
            tab: constants.PORTFOLIO_RETURNS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.PORTFOLIO_RISK,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.OPTIMIZED_EMISSION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.INFERENCE_TYPE,
        key: constants.inferenceTypeKey,
        options: [
          {
            name: constants.AVERAGE,
            value: constants.AVERAGE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_RETURNS,
              constants.PORTFOLIO_RISK,
              constants.OPTIMIZED_EMISSION,
            ],
          },
          {
            name: constants.MAXIMUM,
            value: constants.MAXIMUM_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_RETURNS,
              constants.PORTFOLIO_RISK,
              constants.OPTIMIZED_EMISSION,
            ],
          },
        ],
        default: [constants.AVERAGE],
        tabs: [
          {
            tab: constants.PORTFOLIO_RETURNS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.PORTFOLIO_RISK,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.OPTIMIZED_EMISSION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.EMISSIONS,
        key: constants.emissionKey,
        options: [
          {
            name: constants.SCOPE_1_2,
            value: constants.SCOPE_1_2_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_RETURNS,
              constants.PORTFOLIO_RISK,
              constants.OPTIMIZED_EMISSION,
            ],
          },
          {
            name: constants.SCOPE_1_2_3,
            value: constants.SCOPE_1_2_3_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_RETURNS,
              constants.PORTFOLIO_RISK,
              constants.OPTIMIZED_EMISSION,
            ],
          },
          {
            name: constants.SCOPE_3,
            value: constants.SCOPE_3_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_RETURNS,
              constants.PORTFOLIO_RISK,
              constants.OPTIMIZED_EMISSION,
            ],
          },
        ],
        default: [constants.SCOPE_1_2],
        tabs: [
          {
            tab: constants.PORTFOLIO_RETURNS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.PORTFOLIO_RISK,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.OPTIMIZED_EMISSION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.STRATEGY,
        key: constants.strategyKey,
        options: [
          {
            name: constants.CARBON_MOMENTUM,
            value: constants.CARBON_MOMENTUM_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_RETURNS,
              constants.PORTFOLIO_RISK,
              constants.OPTIMIZED_EMISSION,
            ],
          },
          {
            name: constants.EMISSIONS_REDUCTION,
            value: constants.EMISSIONS_REDUCTION_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_RETURNS,
              constants.PORTFOLIO_RISK,
              constants.OPTIMIZED_EMISSION,
            ],
          },
        ],
        default: [constants.CARBON_MOMENTUM],
        tabs: [
          {
            tab: constants.PORTFOLIO_RETURNS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.PORTFOLIO_RISK,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.OPTIMIZED_EMISSION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.DATERANGE,
        key: constants.dateRangeKey,
        type: 'datePicker',
        options: [
          {
            label: constants.START_DATE,
            value: '',
            name: constants.START_DATE_VALUE,
            allowedTabs: [constants.PORTFOLIO_RETURNS],
          },
          {
            label: constants.END_DATE,
            value: '',
            name: constants.END_DATE_VALUE,
            allowedTabs: [constants.PORTFOLIO_RETURNS],
          },
        ],
        tabs: [
          {
            tab: constants.PORTFOLIO_RETURNS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.PORTFOLIO_RISK,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.OPTIMIZED_EMISSION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.PERFORMANCE_ATTRIBUTION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
        default: [],
      },
      {
        filterName: constants.REBALANCE_FREQUENCY,
        key: constants.rebalanceFrequencyKey,
        options: [
          {
            name: constants.NONE,
            value: constants.NONE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_RETURNS,
              constants.PORTFOLIO_RISK,
              constants.OPTIMIZED_EMISSION,
            ],
          },
          {
            name: constants.YEARLY,
            value: constants.YEARLY_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_RETURNS,
              constants.PORTFOLIO_RISK,
              constants.OPTIMIZED_EMISSION,
            ],
          },
          {
            name: constants.QUATERLY,
            value: constants.QUATERLY_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_RETURNS,
              constants.PORTFOLIO_RISK,
              constants.OPTIMIZED_EMISSION,
            ],
          },
          {
            name: constants.MONTHLY,
            value: constants.MONTHLY_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_RETURNS,
              constants.PORTFOLIO_RISK,
              constants.OPTIMIZED_EMISSION,
            ],
          },
        ],
        default: [constants.YEARLY],
        tabs: [
          {
            tab: constants.PORTFOLIO_RETURNS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.PORTFOLIO_RISK,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.OPTIMIZED_EMISSION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.SECTOR_CLASSIFICATION,
        key: constants.sectorClassificationKey,
        options: [
          {
            name: constants.SASB,
            value: constants.SASB_VALUE,
            allowedTabs: [
              constants.OPTIMIZED_EMISSION,
              constants.PERFORMANCE_ATTRIBUTION,
            ],
          },
          {
            name: constants.ICE,
            value: constants.ICE_VALUE,
            allowedTabs: [
              constants.OPTIMIZED_EMISSION,
              constants.PERFORMANCE_ATTRIBUTION,
            ],
          },
        ],
        default: [constants.SASB],
        tabs: [
          {
            tab: constants.OPTIMIZED_EMISSION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.PERFORMANCE_ATTRIBUTION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
    ],
  },
  {
    moduleName: 'Net-zero analysis',
    path: '/net-zero-analysis',
    moduleTabs: [
      {
        tab: constants.DECARBONATION_MONITOR,
        show_toggle: constants.TRUE,
        benchmark_disabled: (showBenchmark) => !showBenchmark,
      },
      {
        tab: constants.NET_ZERO_ANALYSIS,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.TRUE,
      },
      {
        tab: constants.NET_ZERO_TARGET_SETTINGS,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.TRUE,
      },
      {
        tab: constants.NET_ZERO_COMPANY_ALIGNMENT,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.TRUE,
      },
      {
        tab: constants.SECTOR_SUBPLOT,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.TRUE,
      },
    ],
    filters: [
      {
        filterName: constants.METRIC,
        key: constants.metricKey,
        options: [
          {
            name: constants.TOTAL_CARBON_EMISSION,
            value: constants.TOTAL_CARBON_EMISSION_VALUE,
            allowedTabs: [
              constants.DECARBONATION_MONITOR,
              constants.NET_ZERO_ANALYSIS,
              constants.NET_ZERO_COMPANY_ALIGNMENT,
            ],
          },
        ],
        default: [constants.TOTAL_CARBON_EMISSION],
        tabs: [
          {
            tab: constants.DECARBONATION_MONITOR,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.NET_ZERO_ANALYSIS,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.NET_ZERO_COMPANY_ALIGNMENT,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.MARKET,
        key: constants.marketValueKey,
        options: [
          {
            name: constants.MARKET_CAPITALIZATION,
            value: constants.MARKET_CAPITALIZATION_VALUE,
            allowedTabs: [
              constants.DECARBONATION_MONITOR,
              constants.NET_ZERO_ANALYSIS,
              constants.NET_ZERO_TARGET_SETTINGS,
              constants.SECTOR_SUBPLOT,
            ],
          },
          {
            name: constants.MARKET_CAPITALIZATION_DEBT,
            value: constants.MARKET_CAPITALIZATION_DEBT_VALUE,
            allowedTabs: [
              constants.DECARBONATION_MONITOR,
              constants.NET_ZERO_ANALYSIS,
              constants.NET_ZERO_TARGET_SETTINGS,
              constants.SECTOR_SUBPLOT,
            ],
          },
          {
            name: constants.TOTAL_DEBT,
            value: constants.TOTAL_DEBT_VALUE,
            allowedTabs: [
              constants.DECARBONATION_MONITOR,
              constants.NET_ZERO_ANALYSIS,
              constants.SECTOR_SUBPLOT,
            ],
          },
          {
            name: constants.ENTERPRISE,
            value: constants.ENTERPRISE_VALUE,
            allowedTabs: [
              constants.DECARBONATION_MONITOR,
              constants.NET_ZERO_ANALYSIS,
              constants.NET_ZERO_TARGET_SETTINGS,
              constants.SECTOR_SUBPLOT,
            ],
          },
          {
            name: constants.ENTERPRISE_VALUE_CASH,
            value: constants.ENTERPRISE_VALUE_CASH_VALUE,
            allowedTabs: [
              constants.DECARBONATION_MONITOR,
              constants.NET_ZERO_ANALYSIS,
              constants.NET_ZERO_TARGET_SETTINGS,
              constants.SECTOR_SUBPLOT,
            ],
          },
        ],
        default: [constants.MARKET_CAPITALIZATION],
        tabs: [
          {
            tab: constants.DECARBONATION_MONITOR,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.NET_ZERO_ANALYSIS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.NET_ZERO_TARGET_SETTINGS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTOR_SUBPLOT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.ASSET,
        key: constants.assetClassKey,
        options: [
          {
            name: constants.EQUITY,
            value: constants.EQUITY_VALUE,
            allowedTabs: [constants.NET_ZERO_ANALYSIS],
          },
          {
            name: constants.CORPORATE,
            value: constants.CORPORATE_VALUE,
            allowedTabs: [constants.NET_ZERO_ANALYSIS],
          },
          {
            name: constants.LOANS,
            value: constants.LOANS_VALUE,
            allowedTabs: [constants.NET_ZERO_ANALYSIS],
          },
        ],
        default: [constants.EQUITY, constants.CORPORATE],
        tabs: [
          {
            tab: constants.NET_ZERO_ANALYSIS,
            disabled: () => constants.TRUE,
            allow_multiple: constants.TRUE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.INFERENCE_TYPE,
        key: constants.inferenceTypeKey,
        options: [
          {
            name: constants.AVERAGE,
            value: constants.AVERAGE_VALUE,
            allowedTabs: [constants.NET_ZERO_ANALYSIS],
          },
          {
            name: constants.MAXIMUM,
            value: constants.MAXIMUM_VALUE,
            allowedTabs: [constants.NET_ZERO_ANALYSIS],
          },
        ],
        default: [constants.AVERAGE],
        tabs: [
          {
            tab: constants.NET_ZERO_ANALYSIS,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.EMISSIONS,
        key: constants.emissionKey,
        options: [
          {
            name: constants.SCOPE_1_2,
            value: constants.SCOPE_1_2_VALUE,
            allowedTabs: [
              constants.DECARBONATION_MONITOR,
              constants.NET_ZERO_ANALYSIS,
              constants.NET_ZERO_TARGET_SETTINGS,
              constants.NET_ZERO_COMPANY_ALIGNMENT,
              constants.SECTOR_SUBPLOT,
            ],
          },
          {
            name: constants.SCOPE_1_2_3,
            value: constants.SCOPE_1_2_3_VALUE,
            allowedTabs: [
              constants.DECARBONATION_MONITOR,
              constants.NET_ZERO_ANALYSIS,
              constants.NET_ZERO_TARGET_SETTINGS,
              constants.NET_ZERO_COMPANY_ALIGNMENT,
              constants.SECTOR_SUBPLOT,
            ],
          },
        ],
        default: [constants.SCOPE_1_2],
        tabs: [
          {
            tab: constants.DECARBONATION_MONITOR,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.NET_ZERO_ANALYSIS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.NET_ZERO_TARGET_SETTINGS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.NET_ZERO_COMPANY_ALIGNMENT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTOR_SUBPLOT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.SCENARIO_DB,
        key: constants.targetScenarioKey,
        options: [
          {
            name: constants.SSP,
            value: constants.SSP_VALUE,
            allowedTabs: [
              constants.NET_ZERO_ANALYSIS,
              constants.NET_ZERO_TARGET_SETTINGS,
              constants.NET_ZERO_COMPANY_ALIGNMENT,
              constants.SECTOR_SUBPLOT,
            ],
          },
          {
            name: constants.NGFS_V3,
            value: constants.NGFS_V3_VALUE,
            allowedTabs: [
              constants.NET_ZERO_ANALYSIS,
              constants.NET_ZERO_TARGET_SETTINGS,
              constants.NET_ZERO_COMPANY_ALIGNMENT,
              constants.SECTOR_SUBPLOT,
            ],
          },
          {
            name: constants.NGFS_V4,
            value: constants.NGFS_V4_VALUE,
            allowedTabs: [
              constants.NET_ZERO_ANALYSIS,
              constants.NET_ZERO_TARGET_SETTINGS,
              constants.NET_ZERO_COMPANY_ALIGNMENT,
              constants.SECTOR_SUBPLOT,
            ],
          },
        ],
        default: [constants.NGFS_V3],
        tabs: [
          {
            tab: constants.NET_ZERO_ANALYSIS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.NET_ZERO_TARGET_SETTINGS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.NET_ZERO_COMPANY_ALIGNMENT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTOR_SUBPLOT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],

        dependency: [],
      },
      {
        filterName: constants.WARNING_SCENARIO,
        key: constants.warmingScenarioKey,
        // eslint-disable-next-line arrow-body-style
        skipRequest: (tabValue) => {
          // disabled for portfolio, sectorSubplot, company tabs
          return [
            constants.NET_ZERO_ANALYSIS,
            constants.SECTOR_SUBPLOT,
            constants.NET_ZERO_COMPANY_ALIGNMENT,
          ].includes(tabValue);
        },
        options: [
          {
            dependantName: constants.SSP_VALUE,
            options: [
              { name: constants.SSP_1_19, value: constants.SSP_1_19_VALUE },
              { name: constants.SSP_1_26, value: constants.SSP_1_26_VALUE },
              {
                name: constants.SSP_2_45_VALUE,
                value: constants.SSP_2_45_VALUE,
              },
              {
                name: constants.SSP_3_BASELINE_VALUE,
                value: constants.SSP_3_BASELINE_VALUE,
              },
              {
                name: constants.SSP_5_BASELINE_VALUE,
                value: constants.SSP_5_BASELINE_VALUE,
              },
            ],
            default: [constants.SSP_1_26],
          },
          {
            dependantName: constants.NGFS_V3_VALUE,
            options: [
              {
                name: constants.CURRENT_POLICIES_VALUE,
                value: constants.CURRENT_POLICIES_VALUE,
              },
              {
                name: constants.BELOW_2C_DEGREE,
                value: constants.BELOW_2C_DEGREE,
              },
              { name: constants.NDC_VALUE, value: constants.NDC_VALUE },
              {
                name: constants.DELAYED_TRANSITION,
                value: constants.DELAYED_TRANSITION,
              },
              {
                name: constants.DIVERGENT_NET_ZERO,
                value: constants.DIVERGENT_NET_ZERO,
              },
              { name: constants.NET_ZERO_2050, value: constants.NET_ZERO_2050 },
            ],
            default: [constants.NET_ZERO_2050],
          },
          {
            dependantName: constants.NGFS_V4_VALUE,
            options: [
              {
                name: constants.CURRENT_POLICIES_VALUE,
                value: constants.CURRENT_POLICIES_VALUE,
              },
              {
                name: constants.BELOW_2C_DEGREE,
                value: constants.BELOW_2C_DEGREE,
              },
              { name: constants.NDC_VALUE, value: constants.NDC_VALUE },
              {
                name: constants.DELAYED_TRANSITION,
                value: constants.DELAYED_TRANSITION,
              },
              {
                name: constants.FRAGMENTED_WORLD,
                value: constants.FRAGMENTED_WORLD,
              },
              {
                name: constants.LOW_DEMAND,
                value: constants.LOW_DEMAND,
              },
              { name: constants.NET_ZERO_2050, value: constants.NET_ZERO_2050 },
            ],
            default: [constants.NET_ZERO_2050],
          },
        ],
        default: [constants.BELOW_2C_DEGREE],
        tabs: [
          {
            tab: constants.NET_ZERO_ANALYSIS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
          {
            tab: constants.NET_ZERO_TARGET_SETTINGS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.NET_ZERO_COMPANY_ALIGNMENT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
          {
            tab: constants.SECTOR_SUBPLOT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
        ],

        dependency: [constants.targetScenarioKey],
      },
      {
        filterName: constants.EMISSION_TYPE,
        key: constants.emissionsTypeKey,
        options: [
          {
            name: constants.NET,
            value: constants.NET_VALUE,
            allowedTabs: [
              constants.NET_ZERO_ANALYSIS,
              constants.NET_ZERO_TARGET_SETTINGS,
              constants.NET_ZERO_COMPANY_ALIGNMENT,
              constants.SECTOR_SUBPLOT,
            ],
          },
          {
            name: constants.GROSS,
            value: constants.GROSS_VALUE,
            allowedTabs: [
              constants.NET_ZERO_ANALYSIS,
              constants.NET_ZERO_TARGET_SETTINGS,
              constants.NET_ZERO_COMPANY_ALIGNMENT,
              constants.SECTOR_SUBPLOT,
            ],
          },
        ],
        default: [constants.NET],
        tabs: [
          // {
          //   tab: constants.NET_ZERO_ANALYSIS,
          //   disabled: () => constants.FALSE,
          //   allow_multiple: constants.FALSE,
          // },
          // {
          //   tab: constants.NET_ZERO_TARGET_SETTINGS,
          //   disabled: () => constants.FALSE,
          //   allow_multiple: constants.FALSE,
          // },
          // {
          //   tab: constants.NET_ZERO_COMPANY_ALIGNMENT,
          //   disabled: () => constants.FALSE,
          //   allow_multiple: constants.FALSE,
          // },
          // {
          //   tab: constants.SECTOR_SUBPLOT,
          //   disabled: () => constants.FALSE,
          //   allow_multiple: constants.FALSE,
          // },
        ],
        dependency: [],
      },
      {
        filterName: constants.ALIGNMENT,
        key: constants.alignmentYearKey,
        options: [
          {
            name: constants.YEAR_2030,
            value: constants.YEAR_2030,
            allowedTabs: [constants.NET_ZERO_TARGET_SETTINGS],
          },
          {
            name: constants.YEAR_2040,
            value: constants.YEAR_2040,
            allowedTabs: [constants.NET_ZERO_TARGET_SETTINGS],
          },
          {
            name: constants.YEAR_2050,
            value: constants.YEAR_2050,
            allowedTabs: [
              constants.NET_ZERO_TARGET_SETTINGS,
              constants.NET_ZERO_COMPANY_ALIGNMENT,
            ],
          },
          {
            name: constants.YEAR_2060,
            value: constants.YEAR_2060,
            allowedTabs: [constants.NET_ZERO_TARGET_SETTINGS],
          },
        ],
        default: [constants.YEAR_2030],
        tabs: [
          {
            tab: constants.NET_ZERO_TARGET_SETTINGS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.SECTOR_CLASSIFICATION,
        key: constants.sectorClassificationKey,
        options: [
          {
            name: constants.SASB,
            value: constants.SASB_VALUE,
            allowedTabs: [
              constants.NET_ZERO_COMPANY_ALIGNMENT,
              constants.NET_ZERO_TARGET_SETTINGS,
              constants.SECTOR_SUBPLOT,
            ],
          },
          {
            name: constants.ICE,
            value: constants.ICE_VALUE,
            allowedTabs: [
              constants.NET_ZERO_COMPANY_ALIGNMENT,
              constants.NET_ZERO_TARGET_SETTINGS,
              constants.SECTOR_SUBPLOT,
            ],
          },
        ],
        default: [constants.SASB],
        tabs: [
          {
            tab: constants.NET_ZERO_COMPANY_ALIGNMENT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.NET_ZERO_TARGET_SETTINGS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTOR_SUBPLOT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.ALIGNMENT_MEASUREMENT,
        key: constants.chartTypeKey,
        options: [
          {
            name: constants.ALIGNMENT_EMISSIONS,
            value: constants.ALIGNMENT_EMISSIONS_VALUE,
            allowedTabs: [constants.SECTOR_SUBPLOT],
          },
          {
            name: constants.NORMALISED_EMISSION,
            value: constants.NORMALISED_EMISSION_VALUE,
            allowedTabs: [constants.SECTOR_SUBPLOT],
          },
          {
            name: constants.CUMULATIVE_EMISSION,
            value: constants.CUMULATIVE_EMISSION_VALUE,
            allowedTabs: [constants.SECTOR_SUBPLOT],
          },
        ],
        default: [constants.NORMALISED_EMISSION],
        tabs: [
          {
            tab: constants.SECTOR_SUBPLOT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.INCLUDE_TARGETS,
        key: constants.INCLUDE_TARGETS_KEY,
        options: [
          {
            name: constants.INCLUDE_TARGETS_YES,
            value: constants.INCLUDE_TARGETS_YES_VALUE,
            allowedTabs: [
              constants.NET_ZERO_ANALYSIS,
              constants.NET_ZERO_COMPANY_ALIGNMENT,
              constants.NET_ZERO_TARGET_SETTINGS,
              constants.SECTOR_SUBPLOT,
            ],
          },
          {
            name: constants.INCLUDE_TARGETS_NO,
            value: constants.INCLUDE_TARGETS_NO_VALUE,
            allowedTabs: [
              constants.NET_ZERO_ANALYSIS,
              constants.NET_ZERO_COMPANY_ALIGNMENT,
              constants.NET_ZERO_TARGET_SETTINGS,
              constants.SECTOR_SUBPLOT,
            ],
          },
        ],
        default: [constants.INCLUDE_TARGETS_YES],
        tabs: [
          {
            tab: constants.NET_ZERO_ANALYSIS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.NET_ZERO_TARGET_SETTINGS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTOR_SUBPLOT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.NET_ZERO_COMPANY_ALIGNMENT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.SHOW_NORMALISED,
        key: constants.SHOW_NORMALISED_KEY,
        options: [
          {
            name: constants.SHOW_NORMALISED_YES,
            value: constants.SHOW_NORMALISED_YES_VALUE,
            allowedTabs: [constants.NET_ZERO_ANALYSIS],
          },
          {
            name: constants.SHOW_NORMALISED_NO,
            value: constants.SHOW_NORMALISED_NO_VALUE,
            allowedTabs: [constants.NET_ZERO_ANALYSIS],
          },
        ],
        default: [constants.SHOW_NORMALISED_YES],
        tabs: [
          {
            tab: constants.NET_ZERO_ANALYSIS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.GROUPE_BY,
        key: constants.GROUPE_BY_KEY,
        options: [
          {
            name: constants.REGION,
            value: constants.REGION_VALUE,
            allowedTabs: [constants.SECTOR_SUBPLOT],
          },
          {
            name: constants.SECTOR,
            value: constants.GROUP_SECTOR_VALUE,
            allowedTabs: [constants.SECTOR_SUBPLOT],
          },
        ],
        default: [constants.REGION],
        tabs: [
          {
            tab: constants.SECTOR_SUBPLOT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
    ],
  },
  {
    moduleName: 'Emission2',
    path: '/pcaf',
    moduleTabs: [
      {
        tab: constants.PORTFOLIO_EMISSIONS2,
        show_toggle: constants.TRUE,
        benchmark_disabled: (showBenchmark) => !showBenchmark,
      },
      {
        tab: constants.ASSET_BREAKDOWN,
        show_toggle: constants.TRUE,
        benchmark_disabled: (showBenchmark) => !showBenchmark,
      },
    ],
    filters: [
      {
        filterName: constants.METHODOLOGY,
        key: constants.methodologyKey,
        options: [
          {
            name: constants.CUSTOM,
            value: constants.CUSTOM_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS2,
              constants.ASSET_BREAKDOWN,
            ],
          },
          {
            name: constants.PCAF,
            value: constants.PCAF_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS2,
              constants.ASSET_BREAKDOWN,
            ],
          },
        ],
        default: [constants.CUSTOM], // populated on load from info api
        tabs: [
          {
            tab: constants.PORTFOLIO_EMISSIONS2,
            disabled: () => constants.FALSE,
          },
          {
            tab: constants.ASSET_BREAKDOWN,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.METRIC,
        key: constants.metricKey,
        options: [
          {
            name: constants.WEIGHTED_AVG_INTENSITY_MARKET,
            value: constants.WEIGHTED_AVG_INTENSITY_MARKET_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS2,
              constants.ASSET_BREAKDOWN,
            ],
          },
          {
            name: constants.FINANCED_EMISSIONS,
            value: constants.FINANCED_EMISSIONS_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS2,
              constants.ASSET_BREAKDOWN,
            ],
          },
        ],
        default: [constants.FINANCED_EMISSIONS],
        tabs: [
          {
            tab: constants.PORTFOLIO_EMISSIONS2,
            disabled: (current) => current.strategy === 'pcaf',
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.ASSET_BREAKDOWN,
            disabled: (current) => current.strategy === 'pcaf',
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.EMISSIONS,
        key: constants.emissionKey,
        options: [
          {
            name: constants.SCOPE_1_2_3,
            value: constants.SCOPE_1_2_3_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS2,
              constants.ASSET_BREAKDOWN,
            ],
          },
          {
            name: constants.SCOPE_1_2,
            value: constants.SCOPE_1_2_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS2,
              constants.ASSET_BREAKDOWN,
            ],
          },
        ],
        default: [constants.SCOPE_1_2],
        tabs: [
          {
            tab: constants.PORTFOLIO_EMISSIONS2,
            disabled: () => constants.FALSE,
          },
          {
            tab: constants.ASSET_BREAKDOWN,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.ASSET_TYPE,
        key: constants.assetTypeKey,
        options: [
          {
            name: constants.LOANS,
            value: constants.LOANS,
            allowedTabs: [constants.ASSET_BREAKDOWN],
          },
          {
            name: constants.EQUITY,
            value: constants.EQUITY,
            allowedTabs: [constants.ASSET_BREAKDOWN],
          },
          {
            name: constants.CORPORATE,
            value: constants.CORPORATE,
            allowedTabs: [constants.ASSET_BREAKDOWN],
          },
        ],
        default: [constants.CORPORATE],
        tabs: [
          {
            tab: constants.ASSET_BREAKDOWN,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.EMISSION_TYPE,
        key: constants.emissionsTypeKey,
        options: [
          {
            name: constants.INFERRED_AVG,
            value: constants.INFERRED_AVG_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS2,
              constants.ASSET_BREAKDOWN,
            ],
          },
          {
            name: constants.INFERRED_MAX,
            value: constants.INFERRED_MAX_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS2,
              constants.ASSET_BREAKDOWN,
            ],
          },

          {
            name: constants.BY_PCAF_SCORE,
            value: constants.BY_PCAF_SCORE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS2,
              constants.ASSET_BREAKDOWN,
            ],
          },
        ],
        default: [constants.INFERRED_AVERAGE], // populated on load from info api
        tabs: [
          {
            tab: constants.PORTFOLIO_EMISSIONS2,
            disabled: (current) => current.strategy === 'pcaf',
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.ASSET_BREAKDOWN,
            disabled: (current) => current.strategy === 'pcaf',
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.SECTOR_CLASSIFICATION,
        key: constants.sectorClassificationKey,
        options: [
          {
            name: constants.SASB,
            value: constants.SASB_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS2,
              constants.ASSET_BREAKDOWN,
            ],
          },
          {
            name: constants.ICE,
            value: constants.ICE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS2,
              constants.ASSET_BREAKDOWN,
            ],
          },
        ],
        default: [constants.SASB],
        tabs: [
          {
            tab: constants.PORTFOLIO_EMISSIONS2,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.ASSET_BREAKDOWN,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],

        dependency: [],
      },
    ],
    accordionOptions: [
      {
        filterName: constants.EQUITY,
        key: 'Equity',
        tabs: {
          0: 'all',
          1: 'assetType',
        },
        default: [],
        options: [
          {
            name: constants.PUBLIC_ATTRIBUTION,
            options: [
              { label: constants.REVENUE, value: constants.REVENUE },
              {
                label: constants.MARKET_CAPITALIZATION,
                value: constants.MARKET_CAPITALIZATION_VALUE,
              },
              {
                label: constants.TOTAL_DEBT,
                value: constants.TOTAL_DEBT_VALUE,
              },
              {
                label: constants.MARKET_CAPITALIZATION_TOTAL_DEBT,
                value: constants.MARKET_CAPITALIZATION_DEBT_VALUE,
              },
              {
                label: constants.ENTERPRISE,
                value: constants.ENTERPRISE_VALUE,
              },
              {
                label: constants.ENTERPRISE_VALUE_CASH,
                value: constants.ENTERPRISE_VALUE_CASH_VALUE,
              },
            ],
          },
          {
            name: constants.PRIVATE_ATTRIBUTION,
            options: [
              { label: constants.REVENUE, value: constants.REVENUE },
              {
                label: constants.MARKET_CAPITALIZATION,
                value: constants.MARKET_CAPITALIZATION_VALUE,
              },
              {
                label: constants.TOTAL_DEBT,
                value: constants.TOTAL_DEBT_VALUE,
              },
              {
                label: constants.MARKET_CAPITALIZATION_TOTAL_DEBT,
                value: constants.MARKET_CAPITALIZATION_DEBT_VALUE,
              },
              {
                label: constants.ENTERPRISE,
                value: constants.ENTERPRISE_VALUE,
              },
              {
                label: constants.ENTERPRISE_VALUE_CASH,
                value: constants.ENTERPRISE_VALUE_CASH_VALUE,
              },
            ],
          },
        ],
      },
      {
        filterName: constants.LOANS,
        key: 'Loan',
        tabs: {
          0: 'all',
          1: 'assetType',
        },
        default: [],
        options: [
          {
            name: constants.PUBLIC_ATTRIBUTION,
            options: [
              { label: constants.REVENUE, value: constants.REVENUE },
              {
                label: constants.MARKET_CAPITALIZATION,
                value: constants.MARKET_CAPITALIZATION_VALUE,
              },
              {
                label: constants.TOTAL_DEBT,
                value: constants.TOTAL_DEBT_VALUE,
              },
              {
                label: constants.MARKET_CAPITALIZATION_TOTAL_DEBT,
                value: constants.MARKET_CAPITALIZATION_DEBT_VALUE,
              },
              {
                label: constants.ENTERPRISE,
                value: constants.ENTERPRISE_VALUE,
              },
              {
                label: constants.ENTERPRISE_VALUE_CASH,
                value: constants.ENTERPRISE_VALUE_CASH_VALUE,
              },
            ],
          },
          {
            name: constants.PRIVATE_ATTRIBUTION,
            options: [
              { label: constants.REVENUE, value: constants.REVENUE },
              {
                label: constants.MARKET_CAPITALIZATION,
                value: constants.MARKET_CAPITALIZATION_VALUE,
              },
              {
                label: constants.TOTAL_DEBT,
                value: constants.TOTAL_DEBT_VALUE,
              },
              {
                label: constants.MARKET_CAPITALIZATION_TOTAL_DEBT,
                value: constants.MARKET_CAPITALIZATION_DEBT_VALUE,
              },
              {
                label: constants.ENTERPRISE,
                value: constants.ENTERPRISE_VALUE,
              },
              {
                label: constants.ENTERPRISE_VALUE_CASH,
                value: constants.ENTERPRISE_VALUE_CASH_VALUE,
              },
            ],
          },
        ],
      },
      {
        filterName: constants.CORPORATE,
        key: 'Corporate_Bond',
        tabs: {
          0: 'all',
          1: 'assetType',
        },
        default: [],
        options: [
          {
            name: constants.PUBLIC_ATTRIBUTION,
            options: [
              { label: constants.REVENUE, value: constants.REVENUE },
              {
                label: constants.MARKET_CAPITALIZATION,
                value: constants.MARKET_CAPITALIZATION_VALUE,
              },
              {
                label: constants.TOTAL_DEBT,
                value: constants.TOTAL_DEBT_VALUE,
              },
              {
                label: constants.MARKET_CAPITALIZATION_TOTAL_DEBT,
                value: constants.MARKET_CAPITALIZATION_DEBT_VALUE,
              },
              {
                label: constants.ENTERPRISE,
                value: constants.ENTERPRISE_VALUE,
              },
              {
                label: constants.ENTERPRISE_VALUE_CASH,
                value: constants.ENTERPRISE_VALUE_CASH_VALUE,
              },
            ],
          },
          {
            name: constants.PRIVATE_ATTRIBUTION,
            options: [
              { label: constants.REVENUE, value: constants.REVENUE },
              {
                label: constants.MARKET_CAPITALIZATION,
                value: constants.MARKET_CAPITALIZATION_VALUE,
              },
              {
                label: constants.TOTAL_DEBT,
                value: constants.TOTAL_DEBT_VALUE,
              },
              {
                label: constants.MARKET_CAPITALIZATION_TOTAL_DEBT,
                value: constants.MARKET_CAPITALIZATION_DEBT_VALUE,
              },
              {
                label: constants.ENTERPRISE,
                value: constants.ENTERPRISE_VALUE,
              },
              {
                label: constants.ENTERPRISE_VALUE_CASH,
                value: constants.ENTERPRISE_VALUE_CASH_VALUE,
              },
            ],
            default: [constants.REVENUE],
          },
        ],
      },
    ],
  },
  {
    moduleName: 'Scope3',
    path: '/scope3-materiality',
    moduleTabs: [
      {
        tab: constants.PORTFOLIO_SCOPE3_HEATMAP,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.TRUE,
      },
      {
        tab: constants.SECTORAL_SCOPE3_HEATMAP,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.TRUE,
      },
    ],
    filters: [
      {
        filterName: constants.ASSET,
        key: constants.assetClassKey,
        options: [
          {
            name: constants.EQUITY,
            value: constants.EQUITY_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
          {
            name: constants.CORPORATE,
            value: constants.CORPORATE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
          {
            name: constants.LOANS,
            value: constants.LOANS_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
        ],
        default: [constants.EQUITY, constants.CORPORATE],
        tabs: [
          {
            tab: constants.PORTFOLIO_SCOPE3_HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
          {
            tab: constants.SECTORAL_SCOPE3_HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
        ],

        dependency: [],
      },
      {
        filterName: constants.INFERENCE_TYPE,
        key: constants.inferenceTypeKey,
        options: [
          {
            name: constants.AVERAGE,
            value: constants.AVERAGE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
          {
            name: constants.MAXIMUM,
            value: constants.MAXIMUM_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
        ],
        default: [constants.AVERAGE],
        tabs: [
          {
            tab: constants.PORTFOLIO_SCOPE3_HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTORAL_SCOPE3_HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],

        dependency: [],
      },
      {
        filterName: constants.EMISSIONS,
        key: constants.emissionKey,
        options: [
          {
            name: constants.SCOPE_1_2_3,
            value: constants.SCOPE_1_2_3_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
          {
            name: constants.SCOPE_3,
            value: constants.SCOPE_3_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
        ],
        default: [constants.SCOPE_1_2_3],
        tabs: [
          {
            tab: constants.PORTFOLIO_SCOPE3_HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTORAL_SCOPE3_HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.MATERIALITY_TYPE,
        key: constants.materialityKey,
        options: [
          {
            name: constants.PORTFOLIO,
            value: constants.PORTFOLIO_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
          {
            name: constants.SECTOR,
            value: constants.SECTOR_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
        ],
        default: [constants.PORTFOLIO],
        tabs: [
          {
            tab: constants.PORTFOLIO_SCOPE3_HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTORAL_SCOPE3_HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.SECTOR_CLASSIFICATION,
        key: constants.sectorClassificationKey,
        options: [
          {
            name: constants.SASB,
            value: constants.SASB_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
          {
            name: constants.ICE,
            value: constants.ICE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
        ],
        default: [constants.SASB],
        tabs: [
          {
            tab: constants.PORTFOLIO_SCOPE3_HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTORAL_SCOPE3_HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],

        dependency: [],
      },
    ],
  },
  {
    moduleName: 'Stranded',
    path: '/stranded-assets-analysis',
    moduleTabs: [
      {
        tab: constants.FOSSIL_FUEL_RESERVES_FOOTPRINT,
        show_toggle: constants.TRUE,
        benchmark_disabled: (showBenchmark) => !showBenchmark,
      },
      {
        tab: constants.COAL_POWER_ANALYSIS,
        show_toggle: constants.TRUE,
        benchmark_disabled: (showBenchmark) => !showBenchmark,
      },
    ],
    filters: [
      {
        filterName: constants.METRIC,
        key: constants.metricKey,
        options: [
          {
            name: constants.WEIGHTED_AVG_INTENSITY_REV,
            value: constants.WEIGHTED_AVG_INTENSITY_REV_VALUE,
            allowedTabs: [constants.FOSSIL_FUEL_RESERVES_FOOTPRINT],
          },
          {
            name: constants.WEIGHTED_AVG_INTENSITY_MARKET_MARKET,
            value: constants.WEIGHTED_AVG_INTENSITY_MARKET_VALUE,
            allowedTabs: [constants.FOSSIL_FUEL_RESERVES_FOOTPRINT],
          },
          {
            name: constants.TOTAL_CARBON_EMISSION,
            value: constants.TOTAL_CARBON_EMISSION_VALUE,
            allowedTabs: [constants.FOSSIL_FUEL_RESERVES_FOOTPRINT],
          },
        ],
        default: [constants.WEIGHTED_AVG_INTENSITY_REV],
        tabs: [
          {
            tab: constants.FOSSIL_FUEL_RESERVES_FOOTPRINT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.MARKET,
        key: constants.marketValueKey,
        options: [
          {
            name: constants.MARKET_CAPITALIZATION,
            value: constants.MARKET_CAPITALIZATION_VALUE,
            allowedTabs: [constants.FOSSIL_FUEL_RESERVES_FOOTPRINT],
          },
          {
            name: constants.MARKET_CAPITALIZATION_DEBT,
            value: constants.MARKET_CAPITALIZATION_DEBT_VALUE,
            allowedTabs: [constants.FOSSIL_FUEL_RESERVES_FOOTPRINT],
          },
          {
            name: constants.TOTAL_DEBT,
            value: constants.TOTAL_DEBT_VALUE,
            allowedTabs: [constants.FOSSIL_FUEL_RESERVES_FOOTPRINT],
          },
          {
            name: constants.ENTERPRISE,
            value: constants.ENTERPRISE_VALUE,
            allowedTabs: [constants.FOSSIL_FUEL_RESERVES_FOOTPRINT],
          },
          {
            name: constants.ENTERPRISE_VALUE_CASH,
            value: constants.ENTERPRISE_VALUE_CASH_VALUE,
            allowedTabs: [constants.FOSSIL_FUEL_RESERVES_FOOTPRINT],
          },
        ],
        default: [constants.MARKET_CAPITALIZATION],
        tabs: [
          {
            tab: constants.FOSSIL_FUEL_RESERVES_FOOTPRINT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.ASSET,
        key: constants.assetClassKey,
        options: [
          {
            name: constants.EQUITY,
            value: constants.EQUITY_VALUE,
            allowedTabs: [constants.FOSSIL_FUEL_RESERVES_FOOTPRINT],
          },
          {
            name: constants.CORPORATE,
            value: constants.CORPORATE_VALUE,
            allowedTabs: [constants.FOSSIL_FUEL_RESERVES_FOOTPRINT],
          },
          {
            name: constants.LOANS,
            value: constants.LOANS_VALUE,
            allowedTabs: [constants.FOSSIL_FUEL_RESERVES_FOOTPRINT],
          },
        ],
        default: [constants.EQUITY, constants.CORPORATE],
        tabs: [
          {
            tab: constants.FOSSIL_FUEL_RESERVES_FOOTPRINT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
        ],
        dependency: [],
      },
    ],
  },
  {
    moduleName: 'Fund Of Funds',
    path: '/fund-of-funds',
    moduleTabs: [
      {
        tab: constants.FOF_COMPOSITION,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.FALSE,
      },
      {
        tab: constants.FOF_FOOTPRINT,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.FALSE,
      },
      {
        tab: constants.FOF_ALIGNMENT,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.FALSE,
      },
      {
        tab: constants.FOF_TARGET_SETTING,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.FALSE,
      },
    ],
    filters: [
      {
        filterName: constants.METRIC,
        key: constants.metricKey,
        tabs: [
          {
            tab: constants.FOF_FOOTPRINT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.FOF_ALIGNMENT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.FOF_TARGET_SETTING,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        options: [
          {
            name: constants.WEIGHTED_AVG_INTENSITY_REV,
            value: constants.WEIGHTED_AVG_INTENSITY_REV_VALUE,
            allowedTabs: [
              constants.FOF_FOOTPRINT,
              constants.FOF_ALIGNMENT,
              constants.FOF_TARGET_SETTING,
            ],
          },
          {
            name: constants.WEIGHTED_AVG_INTENSITY_MARKET_MARKET,
            value: constants.WEIGHTED_AVG_INTENSITY_MARKET_VALUE,
            allowedTabs: [
              constants.FOF_FOOTPRINT,
              constants.FOF_ALIGNMENT,
              constants.FOF_TARGET_SETTING,
            ],
          },
          {
            name: constants.TOTAL_CARBON_EMISSION,
            value: constants.TOTAL_CARBON_EMISSION_VALUE,
            allowedTabs: [
              constants.FOF_FOOTPRINT,
              constants.FOF_TARGET_SETTING,
            ],
          },
        ],
        default: [constants.WEIGHTED_AVG_INTENSITY_REV],
        dependency: [],
      },
      {
        filterName: constants.MARKET,
        key: constants.marketValueKey,
        options: [
          {
            name: constants.MARKET_CAPITALIZATION,
            value: constants.MARKET_CAPITALIZATION_VALUE,
            allowedTabs: [
              constants.FOF_FOOTPRINT,
              constants.FOF_ALIGNMENT,
              constants.FOF_TARGET_SETTING,
            ],
          },
          {
            name: constants.MARKET_CAPITALIZATION_DEBT,
            value: constants.MARKET_CAPITALIZATION_DEBT_VALUE,
            allowedTabs: [
              constants.FOF_FOOTPRINT,
              constants.FOF_ALIGNMENT,
              constants.FOF_TARGET_SETTING,
            ],
          },
          {
            name: constants.TOTAL_DEBT,
            value: constants.TOTAL_DEBT_VALUE,
            allowedTabs: [
              constants.FOF_FOOTPRINT,
              constants.FOF_ALIGNMENT,
              constants.FOF_TARGET_SETTING,
            ],
          },
          {
            name: constants.ENTERPRISE,
            value: constants.ENTERPRISE_VALUE,
            allowedTabs: [
              constants.FOF_FOOTPRINT,
              constants.FOF_ALIGNMENT,
              constants.FOF_TARGET_SETTING,
            ],
          },
          {
            name: constants.ENTERPRISE_VALUE_CASH,
            value: constants.ENTERPRISE_VALUE_CASH_VALUE,
            allowedTabs: [
              constants.FOF_FOOTPRINT,
              constants.FOF_ALIGNMENT,
              constants.FOF_TARGET_SETTING,
            ],
          },
        ],
        default: [constants.MARKET_CAPITALIZATION],
        tabs: [
          {
            tab: constants.FOF_FOOTPRINT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.FOF_ALIGNMENT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.FOF_TARGET_SETTING,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.ASSET,
        key: constants.assetClassKey,
        options: [
          {
            name: constants.EQUITY,
            value: constants.EQUITY_VALUE,
            allowedTabs: [
              constants.FOF_FOOTPRINT,
              constants.FOF_ALIGNMENT,
              constants.FOF_TARGET_SETTING,
            ],
          },
          {
            name: constants.CORPORATE,
            value: constants.CORPORATE_VALUE,
            allowedTabs: [
              constants.FOF_FOOTPRINT,
              constants.FOF_ALIGNMENT,
              constants.FOF_TARGET_SETTING,
            ],
          },
          {
            name: constants.LOANS,
            value: constants.LOANS_VALUE,
            allowedTabs: [
              constants.FOF_FOOTPRINT,
              constants.FOF_ALIGNMENT,
              constants.FOF_TARGET_SETTING,
            ],
          },
        ],
        default: [constants.EQUITY, constants.CORPORATE],
        tabs: [
          {
            tab: constants.FOF_FOOTPRINT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
          {
            tab: constants.FOF_ALIGNMENT,
            disabled: () => constants.TRUE,
            allow_multiple: constants.TRUE,
          },
          {
            tab: constants.FOF_TARGET_SETTING,
            disabled: () => constants.TRUE,
            allow_multiple: constants.TRUE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.INFERENCE_TYPE,
        key: constants.inferenceTypeKey,
        options: [
          {
            name: constants.AVERAGE,
            value: constants.AVERAGE_VALUE,
            allowedTabs: [
              constants.FOF_FOOTPRINT,
              constants.FOF_ALIGNMENT,
              constants.FOF_TARGET_SETTING,
            ],
          },
          {
            name: constants.MAXIMUM,
            value: constants.MAXIMUM_VALUE,
            allowedTabs: [
              constants.FOF_FOOTPRINT,
              constants.FOF_ALIGNMENT,
              constants.FOF_TARGET_SETTING,
            ],
          },
        ],
        default: [constants.AVERAGE],
        tabs: [
          {
            tab: constants.FOF_FOOTPRINT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.FOF_ALIGNMENT,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.FOF_TARGET_SETTING,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.EMISSIONS,
        key: constants.emissionKey,
        options: [
          {
            name: constants.SCOPE_1_2,
            value: constants.SCOPE_1_2_VALUE,
            allowedTabs: [
              constants.FOF_FOOTPRINT,
              constants.FOF_TARGET_SETTING,
            ],
          },
          {
            name: constants.SCOPE_1_2_3,
            value: constants.SCOPE_1_2_3_VALUE,
            allowedTabs: [
              constants.FOF_FOOTPRINT,
              constants.FOF_ALIGNMENT,
              constants.FOF_TARGET_SETTING,
            ],
          },
        ],
        default: [constants.SCOPE_1_2],
        tabs: [
          {
            tab: constants.FOF_FOOTPRINT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.FOF_ALIGNMENT,
            disabled: () => constants.TRUE,
            default: [constants.SCOPE_1_2_3],
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.FOF_TARGET_SETTING,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.SET_1_5_SCENARIO,
        key: constants.portScenarioKey,
        options: [
          {
            name: constants.SSP_1,
            value: constants.SSP_1,
            allowedTabs: [constants.FOF_ALIGNMENT],
          },
          {
            name: constants.SSP_2,
            value: constants.SSP_2,
            allowedTabs: [constants.FOF_ALIGNMENT],
          },
          {
            name: constants.LOW_ENERGY_DEMAND_1_5,
            value: constants.LOW_ENERGY_DEMAND_VALUE,
            allowedTabs: [constants.FOF_ALIGNMENT],
          },
        ],
        default: [constants.LOW_ENERGY_DEMAND_1_5],
        tabs: [
          {
            tab: constants.FOF_ALIGNMENT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.SCENARIO_DB,
        key: constants.targetScenarioKey,
        options: [
          {
            name: constants.IPCC_1_5,
            value: constants.IPCC_1_5_VALUE,
            allowedTabs: [constants.FOF_TARGET_SETTING],
          },
          {
            name: constants.IEA,
            value: constants.IEA,
            allowedTabs: [constants.FOF_TARGET_SETTING],
          },
          {
            name: constants.NGFS,
            value: constants.NGFS,
            allowedTabs: [constants.FOF_TARGET_SETTING],
          },
        ],
        default: [constants.NGFS],
        tabs: [
          {
            tab: constants.FOF_TARGET_SETTING,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.APPROACH,
        key: constants.approachKey,
        options: [
          {
            name: constants.MARKET_SHARE,
            value: constants.MARKET_SHARE_VALUE,
            allowedTabs: [constants.FOF_TARGET_SETTING],
          },
          {
            name: constants.RELATIVE_ALIGNMENT,
            value: constants.RELATIVE_ALIGNMENT_VALUE,
            allowedTabs: [constants.FOF_TARGET_SETTING],
          },
        ],
        default: [constants.MARKET_SHARE],
        tabs: [
          {
            tab: constants.FOF_TARGET_SETTING,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.WARNING_SCENARIO,
        key: constants.warmingScenarioKey,
        options: [
          {
            dependantName: constants.IPCC_1_5_VALUE,
            options: [
              { name: constants.SSP_1_26, value: constants.SSP_1_26_VALUE },
              { name: constants.SSP_2_26, value: constants.SSP_2_26_VALUE },
              {
                name: constants.LOW_ENERGY_DEMAND,
                value: constants.LOW_ENERGY_DEMAND_VALUE,
              },
              { name: constants.SSP_4_26, value: constants.SSP_4_26_VALUE },
              { name: constants.SSP_5_26, value: constants.SSP_5_26_VALUE },
            ],
            default: [constants.LOW_ENERGY_DEMAND],
          },
          {
            dependantName: constants.IEA,
            options: [
              {
                name: constants.BEYOND_2_DEGREES,
                value: constants.BEYOND_2_DEGREES_VALUE,
              },
              {
                name: constants.TWO_DEGREES,
                value: constants.TWO_DEGREES_VALUE,
              },
              {
                name: constants.REFERENCE_TECHNOLOGY,
                value: constants.REFERENCE_TECHNOLOGY_VALUE,
              },
            ],
            default: [constants.BEYOND_2_DEGREES],
          },
          {
            dependantName: constants.NGFS,
            options: [
              {
                name: constants.CURRENT_POLICIES,
                value: constants.CURRENT_POLICIES,
              },
              {
                name: constants.IMMEDIATE_2C,
                value: constants.IMMEDIATE_2C,
              },
              {
                name: constants.DELAYED_2C,
                value: constants.DELAYED_2C,
              },
              {
                name: constants.IMMEDIATE_1_5C,
                value: constants.IMMEDIATE_1_5C,
              },
              {
                name: constants.IMMEDIATE_1_5C_LTD_CDR,
                value: constants.IMMEDIATE_1_5C_LTD_CDR,
              },
              {
                name: constants.IMMEDIATE_2C_LTD_CDR,
                value: constants.IMMEDIATE_2C_LTD_CDR,
              },
              {
                name: constants.DELAYED_2C_LTD,
                value: constants.DELAYED_2C_LTD,
              },
            ],
            default: [constants.CURRENT_POLICIES],
          },
        ],
        default: [constants.LOW_ENERGY_DEMAND],
        tabs: [
          {
            tab: constants.FOF_TARGET_SETTING,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],

        dependency: [constants.targetScenarioKey],
      },
      {
        filterName: constants.ALIGNMENT,
        key: constants.alignmentYearKey,
        options: [
          {
            name: constants.YEAR_2030,
            value: constants.YEAR_2030,
            allowedTabs: [constants.FOF_TARGET_SETTING],
          },
          {
            name: constants.YEAR_2040,
            value: constants.YEAR_2040,
            allowedTabs: [constants.FOF_TARGET_SETTING],
          },
          {
            name: constants.YEAR_2050,
            value: constants.YEAR_2050,
            allowedTabs: [constants.FOF_TARGET_SETTING],
          },
          {
            name: constants.YEAR_2060,
            value: constants.YEAR_2060,
            allowedTabs: [constants.FOF_TARGET_SETTING],
          },
        ],
        default: [constants.YEAR_2030],
        tabs: [
          {
            tab: constants.FOF_TARGET_SETTING,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.SECTOR_CLASSIFICATION,
        key: constants.sectorClassificationKey,
        options: [
          {
            name: constants.SASB,
            value: constants.SASB_VALUE,
            allowedTabs: [
              constants.FOF_FOOTPRINT,
              constants.FOF_TARGET_SETTING,
            ],
          },
          {
            name: constants.ICE,
            value: constants.ICE_VALUE,
            allowedTabs: [
              constants.FOF_FOOTPRINT,
              constants.FOF_TARGET_SETTING,
            ],
          },
        ],
        default: [constants.SASB],
        tabs: [
          {
            tab: constants.FOF_TARGET_SETTING,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.FOF_FOOTPRINT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
    ],
  },
  {
    moduleName: 'Sovereign',
    path: '/sovereign-analytics',
    moduleTabs: [
      {
        tab: constants.PORTFOLIO_EMISSIONS,
        show_toggle: constants.TRUE,
        benchmark_disabled: (showBenchmark) => !showBenchmark,
      },
      {
        tab: constants.COUNTRY_EMISSIONS,
        show_toggle: constants.TRUE,
        benchmark_disabled: (showBenchmark) => !showBenchmark,
      },
      { tab: constants.HISTORICAL_EMISSIONS, show_toggle: constants.FALSE },
    ],
    filters: [
      {
        filterName: constants.METRIC,
        key: constants.metricKey,
        options: [
          {
            name: constants.ABSOLUTE_EMISSIONS,
            value: constants.ABSOLUTE_EMISSIONS_VALUE,
            allowedTabs: [constants.HISTORICAL_EMISSIONS],
          },
          {
            name: constants.WEIGHTED_AVG_INTENSITY_MARKET,
            value: constants.WEIGHTED_AVG_INTENSITY_MARKET_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.COUNTRY_EMISSIONS,
            ],
          },
          {
            name: constants.INTENSITY_OF_POPULATION,
            value: constants.INTENSITY_OF_POPULATION_VALUE,
            allowedTabs: [constants.HISTORICAL_EMISSIONS],
          },
          {
            name: constants.INTENSITY_OF_GDP,
            value: constants.INTENSITY_OF_GDP_VALUE,
            allowedTabs: [constants.HISTORICAL_EMISSIONS],
          },
          {
            name: constants.INTENSITY_OF_PPP,
            value: constants.INTENSITY_OF_PPP_VALUE,
            allowedTabs: [constants.HISTORICAL_EMISSIONS],
          },
          // {
          //   name: constants.FINANCED_EMISSIONS,
          //   value: constants.FINANCED_EMISSIONS_VALUE,
          //   allowedTabs: [constants.PORTFOLIO_EMISSIONS],
          // },
        ],
        default: [constants.WEIGHTED_AVG_INTENSITY_MARKET],
        tabs: [
          {
            tab: constants.PORTFOLIO_EMISSIONS,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
            default: [constants.WEIGHTED_AVG_INTENSITY_MARKET],
          },
          {
            tab: constants.COUNTRY_EMISSIONS,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
            default: [constants.WEIGHTED_AVG_INTENSITY_MARKET],
          },
          {
            tab: constants.HISTORICAL_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
            default: [constants.ABSOLUTE_EMISSIONS],
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.ATTRIBUTION,
        key: constants.attributionKey,
        options: [
          {
            name: constants.POPULATION,
            value: constants.POPULATION_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.COUNTRY_EMISSIONS,
            ],
          },
          {
            name: constants.GDP,
            value: constants.GDP_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.COUNTRY_EMISSIONS,
            ],
          },
          {
            name: constants.PPP_ADJUSTED_GDP,
            value: constants.PPP_ADJUSTED_GDP_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.COUNTRY_EMISSIONS,
            ],
          },
        ],
        default: [constants.POPULATION],
        tabs: [
          {
            tab: constants.PORTFOLIO_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.COUNTRY_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.EMISSION_TYPE,
        key: constants.emissionTypeKey,
        options: [
          {
            name: constants.PRODUCTION,
            value: constants.PRODUCTION_VALUE,
            allowedTabs: [constants.COUNTRY_EMISSIONS],
          },
          {
            name: constants.CONSUMPTION,
            value: constants.CONSUMPTION_VALUE,
            allowedTabs: [constants.COUNTRY_EMISSIONS],
          },
          {
            name: constants.CO2,
            value: constants.CO2,
            allowedTabs: [constants.PORTFOLIO_EMISSIONS],
          },
          {
            name: constants.CO2e,
            value: constants.CO2e,
            allowedTabs: [constants.PORTFOLIO_EMISSIONS],
          },
        ],
        default: [constants.PRODUCTION],
        tabs: [
          {
            tab: constants.COUNTRY_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          // {
          //   tab: constants.PORTFOLIO_EMISSIONS,
          //   disabled: () => constants.FALSE,
          //   allow_multiple: constants.FALSE,
          //   default: [constants.CO2],
          // },
        ],
        dependency: [],
      },
    ],
  },
  {
    moduleName: 'Carbon risk',
    path: '/portfolio-carbon-risk',
    moduleTabs: [
      {
        tab: constants.PORTFOLIO_SCOPE3_HEATMAP,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.TRUE,
      },
    ],
    filters: [
      {
        filterName: constants.ASSET,
        key: constants.assetClassKey,
        options: [
          {
            name: constants.EQUITY,
            value: constants.EQUITY_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
          {
            name: constants.CORPORATE,
            value: constants.CORPORATE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
          {
            name: constants.LOANS,
            value: constants.LOANS_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
        ],
        default: [constants.EQUITY, constants.CORPORATE],
        tabs: [
          {
            tab: constants.PORTFOLIO_SCOPE3_HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
          {
            tab: constants.SECTORAL_SCOPE3_HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
        ],

        dependency: [],
      },
      {
        filterName: constants.INFERENCE_TYPE,
        key: constants.inferenceTypeKey,
        options: [
          {
            name: constants.AVERAGE,
            value: constants.AVERAGE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
          {
            name: constants.MAXIMUM,
            value: constants.MAXIMUM_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
        ],
        default: [constants.AVERAGE],
        tabs: [
          {
            tab: constants.PORTFOLIO_SCOPE3_HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTORAL_SCOPE3_HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],

        dependency: [],
      },
      {
        filterName: constants.EMISSIONS,
        key: constants.emissionKey,
        options: [
          {
            name: constants.SCOPE_3,
            value: constants.SCOPE_3_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
          {
            name: constants.SCOPE_1_2_3,
            value: constants.SCOPE_1_2_3_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
        ],
        default: [constants.SCOPE_1_2_3],
        tabs: [
          {
            tab: constants.PORTFOLIO_SCOPE3_HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTORAL_SCOPE3_HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.YEAR,
        key: constants.yearKey,
        options: [
          {
            name: constants.YEAR_1,
            value: constants.YEAR_1_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
          {
            name: constants.YEAR_3,
            value: constants.YEAR_3_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
          {
            name: constants.YEAR_5,
            value: constants.YEAR_5_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCOPE3_HEATMAP,
              constants.SECTORAL_SCOPE3_HEATMAP,
            ],
          },
        ],
        default: [constants.YEAR_1],
        tabs: [
          {
            tab: constants.PORTFOLIO_SCOPE3_HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTORAL_SCOPE3_HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],

        dependency: [],
      },
      {
        filterName: constants.SECTOR_CLASSIFICATION,
        key: constants.sectorClassificationKey,
        options: [
          {
            name: constants.SASB,
            value: constants.SASB_VALUE,
            allowedTabs: [constants.PORTFOLIO_SCOPE3_HEATMAP],
          },
          {
            name: constants.ICE,
            value: constants.ICE_VALUE,
            allowedTabs: [constants.PORTFOLIO_SCOPE3_HEATMAP],
          },
        ],
        default: [constants.SASB],
        tabs: [
          {
            tab: constants.PORTFOLIO_SCOPE3_HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
    ],
  },
  {
    moduleName: 'Temp score',
    path: '/temperature-metric',
    moduleTabs: [
      {
        tab: constants.PORTFOLIO_SCORE,
        show_toggle: constants.TRUE,
        benchmark_disabled: (showBenchmark) => !showBenchmark,
      },
      {
        tab: constants.COMPANY_ANALYSIS,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.TRUE,
      },
      {
        tab: constants.TEMP_ATTRIBUTION,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.FALSE,
      },
      {
        tab: constants.SECTORAL_SCORE,
        show_toggle: constants.TRUE,
        benchmark_disabled: (showBenchmark) => !showBenchmark,
      },
      {
        tab: constants.CONTRIBUTION_ANALYSIS,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.TRUE,
      },
      {
        tab: constants.HEATMAP,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.TRUE,
      },
    ],
    filters: [
      {
        filterName: constants.EMISSIONS,
        key: constants.emissionKey,
        options: [
          {
            name: constants.SCOPE_3,
            value: constants.SCOPE_3_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.COMPANY_ANALYSIS,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
          {
            name: constants.SCOPE_1_2_3,
            value: constants.SCOPE_1_2_3_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.COMPANY_ANALYSIS,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
          {
            name: constants.SCOPE_1_2,
            value: constants.SCOPE_1_2_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.COMPANY_ANALYSIS,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
        ],
        default: [constants.SCOPE_1_2],

        tabs: [
          {
            tab: constants.PORTFOLIO_SCORE,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.COMPANY_ANALYSIS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.TEMP_ATTRIBUTION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTORAL_SCORE,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.CONTRIBUTION_ANALYSIS,
            disabled: () => constants.FALSE,
          },
          {
            tab: constants.HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.AGGREGATION,
        key: constants.aggregationKey,
        options: [
          {
            name: constants.WEIGHTED_AVG,
            value: constants.WEIGHTED_AVG_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.COMPANY_ANALYSIS,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },

          {
            name: constants.TOTAL_EMISSIONS,
            value: constants.TOTAL_EMISSIONS_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.COMPANY_ANALYSIS,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
          {
            name: constants.MARKET_OWNED_EMISSIONS,
            value: constants.MARKET_OWNED_EMISSIONS_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.COMPANY_ANALYSIS,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
          {
            name: constants.ENTERPRISE_OWNED_EMISSIONS,
            value: constants.ENTERPRISE_OWNED_EMISSIONS_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.COMPANY_ANALYSIS,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
          {
            name: constants.ENTERPRISE_VALUE_INCLUDING_CASH,
            value: constants.ENTERPRISE_VALUE_INCLUDING_CASH_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.COMPANY_ANALYSIS,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
          {
            name: constants.REVENUE_OWNED_EMISSIONS,
            value: constants.REVENUE_OWNED_EMISSIONS_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.COMPANY_ANALYSIS,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
        ],
        default: [constants.REVENUE_OWNED_EMISSIONS],
        tabs: [
          {
            tab: constants.PORTFOLIO_SCORE,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.COMPANY_ANALYSIS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.TEMP_ATTRIBUTION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTORAL_SCORE,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.CONTRIBUTION_ANALYSIS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.WHAT_IF_SCENARIO,
        key: constants.whatIfScenarioKey,
        options: [
          {
            name: constants.NON,
            value: constants.NON_VALUE,
            allowedTabs: [constants.PORTFOLIO_SCORE],
          },
          {
            name: constants.TARGETS_15,
            value: constants.TARGETS_15_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
          {
            name: constants.TARGETS_175,
            value: constants.TARGETS_175_VALUE,
            allowedTabs: [constants.PORTFOLIO_SCORE],
          },
          {
            name: constants.TOP_10_15,
            value: constants.TOP_10_15_VALUE,
            allowedTabs: [constants.PORTFOLIO_SCORE],
          },
          {
            name: constants.TOP_10_175,
            value: constants.TOP_10_175_VALUE,
            allowedTabs: [constants.PORTFOLIO_SCORE],
          },
        ],
        default: [constants.NON],
        tabs: [
          {
            tab: constants.PORTFOLIO_SCORE,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.SCORE_TYPE,
        key: constants.scoreTypeKey,
        options: [
          {
            name: constants.SHORT_TERM_SCORE,
            value: constants.SHORT_TERM_SCORE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.COMPANY_ANALYSIS,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
          {
            name: constants.MID_TERM_SCORE,
            value: constants.MID_TERM_SCORE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.COMPANY_ANALYSIS,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
          {
            name: constants.LONG_TERM_SCORE,
            value: constants.LONG_TERM_SCORE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.COMPANY_ANALYSIS,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
        ],
        default: [constants.SHORT_TERM_SCORE],
        tabs: [
          {
            tab: constants.PORTFOLIO_SCORE,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.COMPANY_ANALYSIS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.TEMP_ATTRIBUTION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTORAL_SCORE,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.CONTRIBUTION_ANALYSIS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.DEFAULT_TYPE,
        key: constants.defaultValueKey,
        options: [
          {
            name: constants.DEFAULT_VALUE_100,
            value: constants.DEFAULT_VALUE_100,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
          {
            name: constants.DEFAULT_VALUE_150,
            value: constants.DEFAULT_VALUE_150,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
          {
            name: constants.DEFAULT_VALUE_200,
            value: constants.DEFAULT_VALUE_200,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
          {
            name: constants.DEFAULT_VALUE_250,
            value: constants.DEFAULT_VALUE_250,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
          {
            name: constants.DEFAULT_VALUE_300,
            value: constants.DEFAULT_VALUE_300,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
          {
            name: constants.DEFAULT_VALUE_320,
            value: constants.DEFAULT_VALUE_320,
            allowedTabs: [0, 2, 3, 4, 5],
          },
          {
            name: constants.DEFAULT_VALUE_350,
            value: constants.DEFAULT_VALUE_350,
            allowedTabs: [0, 2, 3, 4, 5],
          },
          {
            name: constants.DEFAULT_VALUE_400,
            value: constants.DEFAULT_VALUE_400,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
          {
            name: constants.DEFAULT_VALUE_450,
            value: constants.DEFAULT_VALUE_450,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
          {
            name: constants.DEFAULT_VALUE_500,
            value: constants.DEFAULT_VALUE_500,
            allowedTabs: [
              constants.PORTFOLIO_SCORE,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
        ],
        default: [constants.DEFAULT_VALUE_320],
        tabs: [
          {
            tab: constants.PORTFOLIO_SCORE,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.TEMP_ATTRIBUTION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTORAL_SCORE,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.CONTRIBUTION_ANALYSIS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.SECTOR_CLASSIFICATION,
        key: constants.sectorClassificationKey,
        options: [
          {
            name: constants.SASB,
            value: constants.SASB_VALUE,
            allowedTabs: [
              constants.COMPANY_ANALYSIS,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
          {
            name: constants.ICE,
            value: constants.ICE_VALUE,
            allowedTabs: [
              constants.COMPANY_ANALYSIS,
              constants.TEMP_ATTRIBUTION,
              constants.SECTORAL_SCORE,
              constants.CONTRIBUTION_ANALYSIS,
              constants.HEATMAP,
            ],
          },
        ],
        default: [constants.SASB],
        tabs: [
          {
            tab: constants.COMPANY_ANALYSIS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.TEMP_ATTRIBUTION,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTORAL_SCORE,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.CONTRIBUTION_ANALYSIS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.HEATMAP,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
    ],
  },
  {
    moduleName: 'Download',
    path: '/urgentem-download',
    moduleTabs: [
      {
        tab: constants.PORTFOLIO_ANALYSIS,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.TRUE,
      },
    ],
    filters: [
      {
        filterName: constants.EMISSION_SCOPE,
        key: constants.emissionKey,
        options: [
          {
            name: constants.SCOPE_1_2,
            value: constants.SCOPE_1_2_VALUE.toLowerCase(),
            allowedTabs: [constants.DOWNLOAD],
          },
          {
            name: constants.SCOPE_1_2_3,
            value: constants.SCOPE_1_2_3_VALUE.toLowerCase(),
            allowedTabs: [constants.DOWNLOAD],
          },
          {
            name: constants.SCOPE_1,
            value: constants.SCOPE_1_VALUE.toLowerCase(),
            allowedTabs: [constants.DOWNLOAD],
          },
          {
            name: constants.SCOPE_2,
            value: constants.SCOPE_2_VALUE.toLowerCase(),
            allowedTabs: [constants.DOWNLOAD],
          },
          {
            name: constants.SCOPE_3,
            value: constants.SCOPE_3_VALUE.toLowerCase(),
            allowedTabs: [constants.DOWNLOAD],
          },
          {
            name: constants.SCOPE3_CATEGORIES,
            value: constants.SCOPE3_CATEGORIES_VALUE,
            allowedTabs: [constants.DOWNLOAD],
          },
        ],
        default: [constants.SCOPE_1_2],
        tabs: [
          {
            tab: constants.DOWNLOAD,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.INFERENCE_TYPE,
        key: constants.inferenceTypeKey,
        options: [
          {
            name: constants.AVERAGE,
            value: constants.INFERRED_AVG_VALUE,
            allowedTabs: [constants.DOWNLOAD],
          },
          {
            name: constants.MAXIMUM,
            value: constants.INFERRED_MAX_VALUE,
            allowedTabs: [constants.DOWNLOAD],
          },
          {
            name: constants.REPORTED,
            value: constants.REPORTED_VALUE,
            allowedTabs: [constants.DOWNLOAD],
          },
        ],
        default: [constants.AVERAGE],
        tabs: [
          {
            tab: constants.DOWNLOAD,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.EMISSION_TYPE,
        key: constants.emissionsTypeKey,
        options: [
          {
            name: constants.EMISSION,
            value: constants.EMISSION_VALUE,
            allowedTabs: [constants.DOWNLOAD, 2],
          },
          {
            name: constants.INTENSITY,
            value: constants.INTENSITY_VALUE,
            allowedTabs: [constants.DOWNLOAD],
          },
          {
            name: constants.EMISSION_SOURCE,
            value: constants.EMISSION_SOURCE_VALUE,
            allowedTabs: [constants.DOWNLOAD],
          },
          {
            name: constants.SOVEREIGN,
            value: constants.SOVEREIGN_VALUE,
            allowedTabs: [constants.DOWNLOAD],
          },
        ],
        default: [constants.EMISSION],
        tabs: [
          {
            tab: constants.DOWNLOAD,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.MARKET,
        key: constants.marketValueKey,
        options: [
          {
            name: constants.MARKET_CAPITALIZATION,
            value: constants.MARKET_CAPITALIZATION_VALUE,
            allowedTabs: [constants.DOWNLOAD],
          },
          {
            name: constants.REVENUE,
            value: constants.REVENUE_VALUE,
            allowedTabs: [constants.DOWNLOAD],
          },
          {
            name: constants.ENTERPRISE,
            value: constants.ENTERPRISE_VALUE,
            allowedTabs: [constants.DOWNLOAD],
          },
          {
            name: constants.ENTERPRISE_VALUE_CASH,
            value: constants.ENTERPRISE_VALUE_CASH_VALUE,
            allowedTabs: [constants.DOWNLOAD],
          },
        ],
        default: [constants.MARKET_CAPITALIZATION],
        tabs: [
          {
            tab: constants.DOWNLOAD,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.INCLUDE,
        key: constants.INCLUDE_KEY,
        options: [
          {
            name: constants.TARGETS,
            value: constants.TARGETS,
            allowedTabs: [constants.DOWNLOAD],
          },
          {
            name: constants.SBTI_TEMP_SCORE,
            value: constants.SBTI_TEMP_SCORE,
            allowedTabs: [constants.DOWNLOAD],
          },
          {
            name: constants.INCLUDE_NONE,
            value: constants.INCLUDE_NONE,
            allowedTabs: [constants.DOWNLOAD],
          },
        ],
        default: [constants.TARGETS],
        tabs: [
          {
            tab: constants.DOWNLOAD,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
        ],
        dependency: [],
      },
      // {
      //   filterName: constants.INCLUDE_TARGETS,
      //   key: constants.INCLUDE_TARGETS_KEY,
      //   options: [
      //     {
      //       name: constants.INCLUDE_TARGETS_YES,
      //       value: constants.INCLUDE_TARGETS_YES_VALUE,
      //       allowedTabs: [constants.DOWNLOAD],
      //     },
      //     {
      //       name: constants.INCLUDE_TARGETS_NO,
      //       value: constants.INCLUDE_TARGETS_NO_VALUE,
      //       allowedTabs: [constants.DOWNLOAD],
      //     },
      //   ],
      //   default: [constants.INCLUDE_TARGETS_YES_VALUE],
      //   tabs: [
      //     {
      //       tab: constants.DOWNLOAD,
      //       disabled: () => constants.FALSE,
      //       allow_multiple: constants.FALSE,
      //     },
      //   ],
      //   dependency: [],
      // },
    ],
  },
  {
    moduleName: 'API',
    path: '/urgentem-api',
    moduleTabs: [
      {
        tab: constants.PORTFOLIO_ALIGNMENT,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.TRUE,
      },
    ],
    filters: [],
  },
  {
    moduleName: '',
    path: '/wb',
    filters: [],
  },
  {
    moduleName: 'Reports',
    path: '/reports',
    moduleTabs: [
      {
        tab: constants.TCFD_REPORT,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.FALSE,
      },
      {
        tab: constants.PORTFOLIO_REPORT,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.FALSE,
      },
      {
        tab: constants.BENCHMARK_REPORT,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.TRUE,
      },
    ],
    filters: [
      {
        filterName: constants.EMISSIONS,
        key: constants.emissionKey,
        options: [
          {
            name: constants.SCOPE_1_2_3,
            value: constants.SCOPE_1_2_3_VALUE,
            allowedTabs: [constants.TCFD_REPORT],
          },
          {
            name: constants.SCOPE_1_2,
            value: constants.SCOPE_1_2_VALUE,
            allowedTabs: [constants.TCFD_REPORT],
          },
        ],
        default: [constants.SCOPE_1_2_3],
        tabs: [
          {
            tab: constants.TCFD_REPORT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.ASSET,
        key: constants.assetClassKey,
        options: [
          {
            name: constants.EQUITY,
            value: constants.EQUITY_VALUE,
            allowedTabs: [constants.TCFD_REPORT],
          },
          {
            name: constants.CORPORATE,
            value: constants.CORPORATE_VALUE,
            allowedTabs: [constants.TCFD_REPORT],
          },
          {
            name: constants.LOANS,
            value: constants.LOANS_VALUE,
            allowedTabs: [constants.TCFD_REPORT],
          },
        ],
        default: [constants.EQUITY, constants.CORPORATE, constants.LOANS],
        tabs: [
          {
            tab: constants.TCFD_REPORT,
            disabled: () => constants.TRUE,
            allow_multiple: constants.TRUE,
            default: [constants.EQUITY, constants.CORPORATE, constants.LOANS],
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.SCENARIO_DB,
        key: constants.targetScenarioKey,
        options: [
          {
            name: constants.NGFS,
            value: constants.NGFS_V3_VALUE,
            allowedTabs: [constants.TCFD_REPORT],
          },
        ],
        default: [constants.NGFS],
        tabs: [
          {
            tab: constants.TCFD_REPORT,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.WARNING_SCENARIO,
        key: constants.warmingScenarioKey,
        options: [
          {
            dependantName: constants.NGFS,
            options: [
              { name: constants.NET_ZERO_2050, value: constants.NET_ZERO_2050 },
            ],
            default: [constants.NET_ZERO_2050],
          },
        ],
        default: [constants.NET_ZERO_2050],
        tabs: [
          {
            tab: constants.TCFD_REPORT,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
          },
        ],

        dependency: [constants.targetScenarioKey],
      },
      {
        filterName: constants.SECTOR_CLASSIFICATION,
        key: constants.sectorClassificationKey,
        options: [
          {
            name: constants.SASB,
            value: constants.SASB_VALUE,
            allowedTabs: [constants.TCFD_REPORT],
          },
          {
            name: constants.ICE,
            value: constants.ICE_VALUE,
            allowedTabs: [constants.TCFD_REPORT],
          },
        ],
        default: [constants.SASB],
        tabs: [
          {
            tab: constants.TCFD_REPORT,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.BUTTON_TEXT,
        key: constants.BUTTON_TEXT,
        type: 'button',
        options: [],
        default: [],
        tabs: [
          {
            tab: constants.TCFD_REPORT,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.PORTFOLIO_REPORT,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.BENCHMARK_REPORT,
            disabled: () => constants.TRUE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
    ],
  },
  {
    moduleName: 'Portfolio Screener',
    path: '/portfolio-screener',
    moduleTabs: [
      {
        tab: constants.PORTFOLIO_VIEW,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.FALSE,
        type: 'manual',
      },
      {
        tab: constants.ALLOCATION_DETAILS,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.FALSE,
        type: 'manual',
      },
    ],
    filters: [
      {
        filterName: constants.ADD_FILTER,
        key: constants.ADD_FILTER,
        type: 'MultipleFilter',
        options: [
          {
            name: constants.REGION,
            value: constants.REGION_VALUE,
            allowedTabs: [constants.PORTFOLIO_VIEW],
          },
          {
            name: constants.COUNTRY,
            value: constants.COUNTRY_VALUE,
            allowedTabs: [constants.PORTFOLIO_VIEW],
          },
          {
            name: constants.CLASSIFICATION_ICE,
            value: constants.CLASSIFICATION_ICE_VALUE,
            allowedTabs: [constants.PORTFOLIO_VIEW],
          },
          {
            name: constants.INTENSITY,
            value: constants.INTENSITY_VALUE,
            allowedTabs: [constants.PORTFOLIO_VIEW],
          },
          {
            name: constants.MARKET_CAP,
            value: constants.MARKET_CAP_VALUE,
            allowedTabs: [constants.PORTFOLIO_VIEW],
          },
        ],
        default: [],
        tabs: [
          {
            tab: constants.PORTFOLIO_VIEW,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
    ],
  },
  {
    moduleName: 'Carbon Offsets',
    path: '/carbon-offset',
    moduleTabs: [
      // {
      //   tab: constants.PORTFOLIO_EMISSIONS,
      //   show_toggle: constants.TRUE,
      //   benchmark_disabled: (showBenchmark) => !showBenchmark,
      // },
      // {
      //   tab: constants.SECTORAL_EMISSIONS,
      //   show_toggle: constants.TRUE,
      //   benchmark_disabled: (showBenchmark) => !showBenchmark,
      // },
      {
        tab: constants.CARBON_OFFSET_COMPANY,
        show_toggle: constants.FALSE,
        benchmark_disabled: () => constants.FALSE,
      },
    ],
    filters: [
      {
        filterName: constants.ASSET,
        key: constants.assetClassKey,
        options: [
          {
            name: constants.EQUITY,
            value: constants.EQUITY_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_OFFSET_COMPANY,
            ],
          },
          {
            name: constants.CORPORATE,
            value: constants.CORPORATE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_OFFSET_COMPANY,
            ],
          },
        ],
        default: [constants.EQUITY, constants.CORPORATE],
        tabs: [
          {
            tab: constants.PORTFOLIO_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
          {
            tab: constants.SECTORAL_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
          {
            tab: constants.CARBON_OFFSET_COMPANY,
            disabled: () => constants.FALSE,
            allow_multiple: constants.TRUE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.INFERENCE_TYPE,
        key: constants.inferenceTypeKey,
        options: [
          {
            name: constants.AVERAGE,
            value: constants.AVERAGE_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_OFFSET_COMPANY,
            ],
          },
        ],
        default: [constants.AVERAGE],
        tabs: [
          {
            tab: constants.PORTFOLIO_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTORAL_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.CARBON_OFFSET_COMPANY,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.EMISSIONS,
        key: constants.emissionKey,
        options: [
          {
            name: constants.SCOPE_1_2,
            value: constants.SCOPE_1_2_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_OFFSET_COMPANY,
            ],
          },
          {
            name: constants.SCOPE_1_2_3,
            value: constants.SCOPE_1_2_3_VALUE,
            allowedTabs: [
              constants.PORTFOLIO_EMISSIONS,
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_OFFSET_COMPANY,
            ],
          },
        ],
        default: [constants.SCOPE_1_2],
        tabs: [
          {
            tab: constants.PORTFOLIO_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.SECTORAL_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.CARBON_OFFSET_COMPANY,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],
        dependency: [],
      },
      {
        filterName: constants.SECTOR_CLASSIFICATION,
        key: constants.sectorClassificationKey,
        options: [
          {
            name: constants.SASB,
            value: constants.SASB_VALUE,
            allowedTabs: [
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_OFFSET_COMPANY,
            ],
          },
          {
            name: constants.ICE,
            value: constants.ICE_VALUE,
            allowedTabs: [
              constants.SECTORAL_EMISSIONS,
              constants.CARBON_OFFSET_COMPANY,
            ],
          },
        ],
        default: [constants.SASB],
        tabs: [
          {
            tab: constants.SECTORAL_EMISSIONS,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
          {
            tab: constants.CARBON_OFFSET_COMPANY,
            disabled: () => constants.FALSE,
            allow_multiple: constants.FALSE,
          },
        ],

        dependency: [],
      },
    ],
  },
];

export default config;
