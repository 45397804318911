import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Button } from '@material-ui/core';
import DataTable from '../../components/Table/DataTable';
import CustomToggleSwitch from '../../components/CustomToggleSwitch';

const UrgentemLandingDisplay = ({
  classes,
  portfolioTableRes,
  headCells,
  setShowUploadDialog,
  onSwitchChange,
  showTrafficLights,
}) => (
  <React.Fragment>
    <Grid container>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 16,
        }}
      >
        <CustomToggleSwitch
          checkedValue={showTrafficLights}
          label="Show Indicator"
          labelPlacement="end"
          onSwitchChange={onSwitchChange}
          fontSize={12}
        />
        <Button
          variant="outlined"
          color="primary"
          className={classes.uploadBtn}
          onClick={() => setShowUploadDialog(true)}
        >
          Upload Portfolio
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <DataTable
            data={portfolioTableRes}
            columns={headCells}
            tableHeading="UPLOAD_PORTFOLIO"
          />
        </Box>
        <br />
        <p style={{ fontSize: 10 }}>
          The summary overview for the portfolios in this table is recalculated
          daily at midnight (UTC). Any changes or updates will be reflected in
          the table starting from the next day. Please note that the information
          provided here is based on the latest recalculation.
        </p>
      </Grid>
    </Grid>
  </React.Fragment>
);

UrgentemLandingDisplay.propTypes = {
  showTrafficLights: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  portfolioTableRes: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  setShowUploadDialog: PropTypes.func.isRequired,
  onSwitchChange: PropTypes.func.isRequired,
};

export default UrgentemLandingDisplay;
