import React from 'react';
import PropTypes from 'prop-types';

import { getHeatmapChartData } from '../../../../helpers/report';
import ReportHeatMapChart from '../../../../components/PdfReport/Charts/ReportHeatmapChart';

function Scope3Overview({ data }) {
  const { portfolio } = data;

  let heatMapData = { data: [], xAxisCategories: [], yAxisCategories: [] };

  if (portfolio) {
    heatMapData = getHeatmapChartData(portfolio.scope3_portfolio);
  }

  return (
    <div className="pdf_page">
      <div className="span-2">
        <h3>Supply and Value Chain Scope 3 Materiality</h3>
        <ReportHeatMapChart
          data={heatMapData.data}
          yAxisCategories={heatMapData.yAxisCategories}
          xAxisCategories={heatMapData.xAxisCategories}
        />
        <div>
          <p>
            The Supply and Value Chain Scope 3 Materiality heatmap provides a
            breakdown of carbon risk exposure within sectoral supply and value
            chains. Each Sector is scaled by the maximum Scope 1 + 2 or Scope 3
            category by carbon intensity. The most material source is assigned
            the number of 100 and then all remaining categories are assigned a
            number relative to 100 in terms of materiality. This enables
            identifying the hotspots within the Scope 3 emissions of companies
            that require further assessment.
          </p>
        </div>
      </div>
    </div>
  );
}

Scope3Overview.propTypes = {
  data: PropTypes.shape({
    portfolio: PropTypes.object,
    benchmark: PropTypes.object,
    tempScore: PropTypes.array,
    auth: PropTypes.object,
    filters: PropTypes.object,
  }),
};

export default Scope3Overview;
