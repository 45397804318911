/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import UserSessionHandler from '../handler';
import { store } from '../redux/store';
// import { logoutUser } from './auth';
import { setLoading } from '../redux/reducers/authReducer';
import { userAuthSliceApi } from '../redux/apiHooks';

const injectToken = async (config) => {
  try {
    const { type } = config;
    store.dispatch(setLoading(true));
    const AuthToken = type === 'refresh'
      ? await UserSessionHandler.getRefreshToken()
      : await UserSessionHandler.getAccessToken();
    if (AuthToken && config.headers) {
      config.headers.Authorization = `Bearer ${AuthToken}` || '';
    }
    return config;
  } catch (error) {
    store.dispatch(setLoading(false));
    return config;
  }
};

const Api = () => {
  const embedCookie = UserSessionHandler.getCookie();
  const BASE_API_URL = embedCookie && process.env.REACT_APP_SF_API_URL
    ? process.env.REACT_APP_SF_API_URL
    : process.env.REACT_APP_API_URL;

  const instance = axios.create({
    baseURL: BASE_API_URL,
    withCredentials: false,
  });

  instance.defaults.headers.post['Content-Type'] = 'application/json';
  instance.defaults.headers.post['x-api-key'] = '_yF0FT6hgogJxSF1G0sAl3d9d4pQwxhuiRSS8FxAWb8';
  instance.defaults.headers.get['x-api-key'] = '_yF0FT6hgogJxSF1G0sAl3d9d4pQwxhuiRSS8FxAWb8';

  instance.interceptors.request.use(injectToken, (error) => Promise.reject(error));
  instance.interceptors.response.use(
    async (response) => {
      if (
        response.config.url !== `${process.env.REACT_APP_API_URL}/portfolio/`
      ) {
        store.dispatch(setLoading(false));
      }
      return response;
    },
    async (error) => {
      const { response } = error;
      if (
        error.response
        && error.response.status === 403
        && error.response.data.type === 're_login'
      ) {
        await store.dispatch(userAuthSliceApi.endpoints.logout.initiate());
        NotificationManager.error('This login was blocked. Pls re-login again');
      }
      if (error.response && error.response.status === 401) {
        await store.dispatch(userAuthSliceApi.endpoints.logout.initiate());
        NotificationManager.error(
          'Missing Authentication Header. Pls re-login again',
        );
      }
      store.dispatch(setLoading(false));
      return Promise.reject(response?.data);
    },
  );

  return {
    get: async (url, config) => {
      const response = await instance.get(url, config);
      return response.data;
    },
    post: async (url, data, config) => {
      const response = await instance.post(url, data, config);
      return response.data;
    },
    put: async (url, data, config) => {
      const response = await instance.put(url, data, config);
      return response.data;
    },
    delete: async (url, data, config) => {
      const response = await instance.delete(url, data, config);
      return response.data;
    },
  };
};

export const api = Api();
