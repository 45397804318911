import BaseRequest from '../../baseRequest';

export default class CompanyAlignment extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem } = props;
    const {
      footprintMetric,
      assetClass,
      emission,
      // warmingScenario,
      targetScenario,
      // emissionsType,
      alignmentYear,
      sector,
      includeTargets,
    } = filterItem;
    this.asset_type = assetClass;
    this.footprint = footprintMetric;
    this.inference = 'Avg';
    this.sector = sector;
    this.scope = emission;
    // this.scenario = warmingScenario;
    this.scenario_type = targetScenario;
    this.emission_type = 'net';
    this.alignment_year = alignmentYear;
    this.include_historical = true;
    // this.industry = 'Infrastructure';
    // this.pisin = currentCompany;
    this.include_targets = includeTargets;
  }
}
