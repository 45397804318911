import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import DataTable from '../../../components/Table/DataTable';
import HorizontalBar from '../../../components/Charts/HorizontalBar';

const FossilFuelDisplay = ({
  chartData,
  tableData,
  yAxisTitle,
  fossilFuelCells,
  countryTableData,
  countryFossilCells,
  categories,
}) => (
  <React.Fragment>
    <Grid container>
      <Grid item xs={12}>
        <HorizontalBar
          title="Fossil-Fuel Reserves Footprint"
          categories={categories}
          data={chartData}
          yAxisTitle={yAxisTitle}
        />
      </Grid>
      <Grid item xs={6}>
        <Box style={{ marginRight: 10 }}>
          <DataTable
            data={tableData}
            columns={fossilFuelCells}
            tableHeading="FOSSIL_FUEL"
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <DataTable
          data={countryTableData}
          columns={countryFossilCells}
          tableHeading="FOSSIL_FUEL_COUNTRY"
        />
        <div style={{ fontSize: 11 }}>
          * Where applicable only top 10 countries shown.
        </div>
      </Grid>
      <div
        style={{
          fontSize: 14,
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          marginTop: 10,
        }}
      >
        The fossil fuel footprint measures the portfolio&apos;s exposure to
        Coal, Oil and Gas reserves. The Coal, Oil and Gas emissions underlying
        the footprint calculation are based on a list of 100 companies with the
        largest reserves. The breakdown of the footprint by country shows the
        contribution of the country (based on the country of domicile) to the
        total footprint.
      </div>
    </Grid>
  </React.Fragment>
);

FossilFuelDisplay.propTypes = {
  chartData: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  coalPowerCells: PropTypes.array.isRequired,
  trial: PropTypes.bool.isRequired,
  yAxisTitle: PropTypes.string.isRequired,
  fossilFuelCells: PropTypes.array.isRequired,
  countryTableData: PropTypes.array.isRequired,
  countryFossilCells: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
};

export default FossilFuelDisplay;
