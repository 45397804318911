import { initiateFileDownload } from '../../helpers';
import apiSlice from '../apiSlice';

export const helperApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    downloadPDFFile: builder.mutation({
      queryFn: async (
        { url, filename = 'file.pdf' } = {},
        _,
        __,
        baseQuery,
      ) => {
        const result = await baseQuery({
          url,
          responseHandler: (response) => response.blob(),
        });
        const windowUrl = window.URL || window.webkitURL;
        const blobPDF = windowUrl.createObjectURL(result.data);
        initiateFileDownload(blobPDF, filename);
        return { data: null };
      },
    }),
  }),
});

export const { useDownloadPDFFileMutation } = helperApiSlice;
