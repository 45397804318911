/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './index.css';
import handleDownloadPdf from '../../../helpers/downloadPdf';

import Tcfd from '../../../models/tcfd';

import useGetPayload from '../../../hooks/useGetPayload';
import { useLazyGetReportTcfdQuery } from '../../../redux/apiHooks';
import { setFilterItemSuccess } from '../../../redux/reducers/filterReducer';
import { LoadingHOC } from '../../../util/LoadingHOC';
import TCFDDisplay from './TCFDDiplay';

const TCFDWithLoading = LoadingHOC(TCFDDisplay);

const GenerateReport = () => {
  const dispatch = useDispatch();

  const { currentPortfolio } = useSelector((state) => state.auth);
  const { loadData } = useSelector((state) => state.filters);

  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const { payload: tcfdPayload, auth, filters } = useGetPayload(Tcfd);

  const [tcfdTrigger, { data, isFetching, error }] = useLazyGetReportTcfdQuery(tcfdPayload);

  useEffect(() => {
    if (data) {
      setButtonDisabled(false);
    }
  }, [data]);

  useEffect(() => {
    if (loadData) {
      tcfdTrigger(tcfdPayload, true);
      dispatch(setFilterItemSuccess({ key: 'loadData', value: false }));
    }
  }, [loadData]);

  const onDownloadClick = async () => {
    setLoading(true);
    setButtonDisabled(true);
    handleDownloadPdf(
      `TCFD_Report_${currentPortfolio.label}_${filters.emission}.pdf`,
      () => {
        setLoading(false);
        setButtonDisabled(false);
      },
    );
  };

  return (
    <TCFDWithLoading
      error={error}
      loading={isFetching}
      onDownloadClick={onDownloadClick}
      buttonDisabled={buttonDisabled}
      downloading={loading}
      data={{
        portfolio: data?.portfolio,
        benchmark: data?.benchmark,
        auth,
        filters,
      }}
    />
  );
};

export default GenerateReport;
