/* eslint-disable react/prop-types */

import React from 'react';
import HorizontalBar from '../../../components/Charts/HorizontalBar';

const OptimizedEmissionDisplay = (props) => (
  <React.Fragment>
    <HorizontalBar
      categories={props.categories_1}
      data={props.data_1}
      title="Portfolio Carbon Intensity"
      yAxisTitle={props.yAxisTitle}
    />

    <HorizontalBar
      categories={props.categories_2}
      data={props.data_2}
      title="Sector Weights"
      yAxisTitle="Weight (%)"
    />

    <HorizontalBar
      categories={props.categories_3}
      data={props.data_3}
      title="Sector Contribution"
      yAxisTitle={props.yAxisTitle}
    />
  </React.Fragment>
);

export default OptimizedEmissionDisplay;
