import React from 'react';
import PropTypes from 'prop-types';

import { CATEGORIES_MAP } from '../../../../constants';
import ReportTable from '../../../../components/PdfReport/ReportTable';
import {
  getAvoidedEmission,
  getAvoidedEmissionTable,
  getEmissionBreakdown,
  getEmissionFootprint,
} from '../../../../helpers/report';
import ReportHorizontalBar from '../../../../components/PdfReport/Charts/ReportHorizontalBar';
import ReportStackedBar from '../../../../components/PdfReport/Charts/ReportStackedBar';

const avoidedEmissionsColumns = [
  {
    name: ' ',
    selector: 'label',
    right: false,
  },
  {
    name: CATEGORIES_MAP.sc12,
    selector: 'sc12',
    right: true,
  },
  {
    name: CATEGORIES_MAP.sc3,
    selector: 'sc3',
    right: true,
  },
  {
    name: CATEGORIES_MAP.sc123,
    selector: 'sc123',
    right: true,
  },
  {
    name: 'Avoided Emissions',
    selector: 'avoided_emissions',
    right: true,
  },
];

function AvoidedEmissions({ data }) {
  const { portfolio, benchmark } = data;

  let emissionFootprint = { data: [], yAxisTitle: '', categories: [] };
  let avoidedEmissionsTable = [];
  let emissionBreakdown = { data: [], yAxisTitle: '', categories: [] };
  let avoidedEmission = { data: [], yAxisTitle: '', categories: [] };

  if (portfolio && benchmark) {
    emissionFootprint = getEmissionFootprint(
      portfolio.avoided_emissions,
      benchmark.avoided_emissions,
    );

    avoidedEmissionsTable = getAvoidedEmissionTable(
      portfolio.avoided_emissions,
      benchmark.avoided_emissions,
    );

    emissionBreakdown = getEmissionBreakdown(portfolio.avoided_emissions);

    avoidedEmission = getAvoidedEmission(portfolio.avoided_emissions);
  }
  return (
    <div className="pdf_page">
      <h2>Avoided Emissions</h2>
      <div className="span-2">
        <h3>Avoided Emissions Footprint</h3>
        <ReportHorizontalBar
          categories={emissionFootprint.categories}
          data={emissionFootprint.data}
          yAxisTitle={emissionFootprint.yAxisTitle}
        />
        <div>
          <p>
            The Avoided (Carbon) Emissions Footprint chart displays the
            estimated avoided emissions for the portfolio. Avoided emissions
            estimates are calculated using sector-level methodologies -
            specifically calculating the carbon savings produced by the
            constituent companies compared to their industry peers. This
            methodology looks at avoided emissions in specific sectors that have
            direct contributions towards carbon savings (e.g. Transportation and
            Electricity).
          </p>
        </div>
      </div>
      <div className="span-2">
        <h3>Intensity Table Including Avoided Emissions</h3>
        <div style={{ marginTop: 2, marginBottom: 20 }}>
          <ReportTable
            columns={avoidedEmissionsColumns}
            data={avoidedEmissionsTable}
          />
        </div>
      </div>
      <div>
        <h3>Avoided Emissions Breakdown</h3>
        <ReportStackedBar
          categories={emissionBreakdown.categories}
          data={emissionBreakdown.data}
          yAxisTitle={emissionBreakdown.yAxisTitle}
          max={100}
        />
        <div>
          <p>
            The Avoided Emissions Breakdown chart expands on the total
            calculated portfolio avoided emissions, looking at the different
            sectors contributing to the avoided emissions in the portfolio.
            Examples include Transportation - where the methodology looks at
            avoided emissions from contributions to the manufacturing of
            electric vehicles and the supporting infrastructure enabling this
            shift to electric vehicles (e.g. charging stations).
          </p>
        </div>
      </div>
      <div>
        <h3>Reported Avoided Emissions</h3>
        <ReportStackedBar
          categories={avoidedEmission.categories}
          data={avoidedEmission.data}
          yAxisTitle={avoidedEmission.yAxisTitle}
          max={100}
          showLabels={false}
          barWidth={15}
        />
        <div>
          <p>
            The Reported Avoided Emissions chart provides the percentage of
            companies within the portfolio that publicly report or do not report
            avoided emissions.
          </p>
        </div>
      </div>
    </div>
  );
}

AvoidedEmissions.propTypes = {
  data: PropTypes.shape({
    portfolio: PropTypes.object,
    benchmark: PropTypes.object,
    tempScore: PropTypes.array,
    auth: PropTypes.object,
    filters: PropTypes.object,
  }),
};

export default AvoidedEmissions;
