import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import HeatmapChart from '../../../components/Charts/HeatmapChart';

const HeatmapDisplay = ({ chartData, yAxisCategories, xAxisCategories }) => (
  <React.Fragment>
    <Box>
      <HeatmapChart
        title="Temperature Score per Sector and Region"
        yAxisTitle="Sector"
        yAxisCategories={yAxisCategories}
        data={chartData}
        xAxisCategories={xAxisCategories}
        height={250}
        colorAxis={{ min: 1, max: 4 }}
      />
    </Box>
  </React.Fragment>
);

HeatmapDisplay.propTypes = {
  chartData: PropTypes.array.isRequired,
  yAxisCategories: PropTypes.array.isRequired,
  xAxisCategories: PropTypes.array.isRequired,
  chartKey: PropTypes.string.isRequired,
  isSectoral: PropTypes.bool.isRequired,
};

export default HeatmapDisplay;
