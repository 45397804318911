import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
// import { setReweightData } from '../../../services/auth';
import { portOptimizationCells } from '../../../util/TableHeadConfig';
// import { setLoading } from '../../../redux/reducers/authReducer';
import PortfolioOptimizationDisplay from './PortfolioOptimizationDisplay';
import { LoadingHOC } from '../../../util/LoadingHOC';
import useGetPayload from '../../../hooks/useGetPayload';
import PortfolioReturns from '../../../models/optimization/portfolioReturns';
import { useGetOptimizationQuery } from '../../../redux/apiHooks';
import { setReweightFactor } from '../../../redux/reducers/authReducer';

const PortfolioOptimizationWithLoading = LoadingHOC(
  PortfolioOptimizationDisplay,
);

const PortfolioOptimization = () => {
  const dispatch = useDispatch();

  const { auth, payload } = useGetPayload(PortfolioReturns);
  const {
    data: optimizationData,
    isFetching,
    error,
  } = useGetOptimizationQuery(payload);
  const { reweightFactor, showBenchmark } = auth;

  const [lineChartData, setLineChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [reWeightFactor, setReweightfactor] = useState(reweightFactor);
  // const [, setIntensityData] = useState([]);
  // const [, setWeightCategories] = useState([]);
  // const [, setContribCategories] = useState([]);
  // const [, setWeightData] = useState([]);
  // const [, setContribData] = useState([]);

  const formatDate = (currentDate) => {
    const year = currentDate.toString().slice(0, 4);
    const month = currentDate.toString().slice(4, 6) - 1;
    const date = currentDate.toString().slice(6, 8);
    return { year, month, date };
  };
  const handleReweightFactor = (event, newValue) => {
    setReweightfactor(newValue);
  };
  const submitReweightFactor = () => {
    dispatch(setReweightFactor(reWeightFactor));
  };

  const getTableData = (response) => {
    const names = [
      'CAGR (%)',
      'Annualised Return (%)',
      'Standard Deviation (%)',
      'Sharpe Ratio',
      'Calmar Ratio',
    ];
    const benchmarkTableData = showBenchmark
      ? response.benchmark.table_and_charts.metrics_table
      : {};
    const portfolioTableData = response.portfolio.table_and_charts.metrics_table;
    const tiltedTableData = response.tilted.table_and_charts.metrics_table;
    const tabledata = names.map((name) => {
      const data = {
        name,
        portfolio: portfolioTableData[name],
        tilted: tiltedTableData[name],
      };
      if (showBenchmark) {
        data.benchmark = benchmarkTableData[name];
      }
      return data;
    });
    return tabledata;
  };

  const getLineChartData = (response) => {
    const chartData = [
      {
        name: 'Portfolio',
        data: [],
      },
      {
        name: 'Tilted',
        data: [],
      },
    ];

    if (showBenchmark) {
      chartData.push({
        name: 'Benchmark',
        data: [],
      });
    }

    if (!isEmpty(response?.portfolio?.linechart)) {
      const portDate = response.portfolio.linechart;
      Object.keys(portDate).forEach((currentDate) => {
        const { year, month, date } = formatDate(currentDate);
        chartData[0].data.push([
          Date.UTC(year, month, date),
          portDate[currentDate],
        ]);
      });
    }
    if (!isEmpty(response?.tilted?.linechart)) {
      const tiltedDate = response.tilted.linechart;
      Object.keys(tiltedDate).forEach((currentDate) => {
        const { year, month, date } = formatDate(currentDate);
        chartData[1].data.push([
          Date.UTC(year, month, date),
          tiltedDate[currentDate],
        ]);
      });
    }
    if (showBenchmark && !isEmpty(response?.benchmark?.linechart)) {
      const benchDate = response.benchmark.linechart;
      Object.keys(benchDate).forEach((currentDate) => {
        const { year, month, date } = formatDate(currentDate);
        chartData[2].data.push([
          Date.UTC(year, month, date),
          benchDate[currentDate],
        ]);
      });
    }
    return chartData;
  };
  // const getIntensityData = (response) => {
  //   let intensityData = [];
  //   const intensityPortSc12 = parseFloat(
  //     response.tilted.Intensities.portfolio.sc12.toFixed(2),
  //   );
  //   const intensityPortSc123 = parseFloat(
  //     response.tilted.Intensities.portfolio.sc123.toFixed(2),
  //   );
  //   const intensityPortSc3 = parseFloat(
  //     response.tilted.Intensities.portfolio.sc3.toFixed(2),
  //   );

  //   const intensityTiltedSc12 = parseFloat(
  //     response.tilted.Intensities.tilted.sc12.toFixed(2),
  //   );
  //   const intensityTiltedSc123 = parseFloat(
  //     response.tilted.Intensities.tilted.sc123.toFixed(2),
  //   );
  //   const intensityTiltedSc3 = parseFloat(
  //     response.tilted.Intensities.tilted.sc3.toFixed(2),
  //   );

  //   intensityData = [
  //     {
  //       name: 'Portfolio',
  //       data: [intensityPortSc12, intensityPortSc3, intensityPortSc123],
  //     },
  //     {
  //       name: 'Tilted',
  //       data: [intensityTiltedSc12, intensityTiltedSc3, intensityTiltedSc123],
  //     },
  //   ];
  //   return intensityData;
  // };

  // const getData = async () => {
  // let yAxisLabel = '';
  // let optimizationlineChartData = [];
  // let optimizationTableData = [];
  // let intensityData = [];
  // const weightCategories = [];
  // const contribCategories = [];
  // const weightData = [
  //   {
  //     name: 'Portfolio',
  //     data: [],
  //   },
  //   {
  //     name: 'Tilted',
  //     data: [],
  //   },
  // ];
  // const contribData = [
  //   {
  //     name: 'Portfolio',
  //     data: [],
  //   },
  //   {
  //     name: 'Tilted',
  //     data: [],
  //   },
  // ];

  // if (!isEmpty(optimizationData)) {
  //   optimizationlineChartData = getLineChartData(optimizationData);
  //   optimizationTableData = getTableData(optimizationData);
  // intensityData = getIntensityData(response);

  // const portSectorWeights = optimizationData.tilted.Intensities.portfolio.sector_weight;
  // const tiltedSectorWeights = optimizationData.tilted.Intensities.tilted.sector_weight;
  // const portSectorContribs = optimizationData.tilted.Intensities.portfolio.sectoral_contribution;
  // const tiltedSectorContribs = optimizationData.tilted.Intensities.tilted.sectoral_contribution;

  // if (portSectorWeights && Object.keys(portSectorWeights).length > 0) {
  //   Object.keys(portSectorWeights).forEach((key) => {
  //     weightData[0].data.push(portSectorWeights[key]);
  //     weightCategories.push(key);
  //   });
  // }
  // if (tiltedSectorWeights && Object.keys(tiltedSectorWeights).length > 0) {
  //   Object.keys(tiltedSectorWeights).forEach((key) => {
  //     weightData[1].data.push(tiltedSectorWeights[key]);
  //   });
  // }
  // if (portSectorContribs && Object.keys(portSectorContribs).length > 0) {
  //   Object.keys(portSectorContribs).forEach((key) => {
  //     contribData[0].data.push(portSectorContribs[key]);
  //     contribCategories.push(key);
  //   });
  // }
  // if (
  //   tiltedSectorContribs
  //   && Object.keys(tiltedSectorContribs).length > 0
  // ) {
  //   Object.keys(tiltedSectorContribs).forEach((key) => {
  //     contribData[1].data.push(tiltedSectorContribs[key]);
  //   });
  // }
  //   setLineChartData(optimizationlineChartData);
  //   setTableData(optimizationTableData);
  // }
  // setYAxisTitle(yAxisLabel);
  // setIntensityData(intensityData);
  // setWeightCategories(weightCategories);
  // setContribCategories(contribCategories);
  // setWeightData(weightData);
  // setContribData(contribData);

  // await dispatch(setLoading(false));
  // };

  useEffect(() => {
    if (!isEmpty(optimizationData)) {
      const optimizationlineChartData = getLineChartData(optimizationData);
      const optimizationTableData = getTableData(optimizationData);
      setLineChartData(optimizationlineChartData);
      setTableData(optimizationTableData);
    }
  }, [optimizationData, reweightFactor]);

  const portOptimizationCell = showBenchmark
    ? [
      ...portOptimizationCells,
      {
        name: 'Benchmark',
        selector: 'benchmark',
        sortable: true,
        right: true,
        cell: (row) => (
            <div>{new Intl.NumberFormat().format(row.benchmark)}</div>
        ),
      },
    ]
    : portOptimizationCells;

  return (
    <PortfolioOptimizationWithLoading
      loading={isFetching}
      error={error}
      value={reWeightFactor}
      onChange={handleReweightFactor}
      onClick={submitReweightFactor}
      lineChartData={lineChartData}
      chartKey="PORT_OPTIMIZATION"
      data={tableData}
      columns={[...portOptimizationCell]}
      tableHeading="PORT_OPTIMIZATION"
    />
  );
};
export default PortfolioOptimization;
