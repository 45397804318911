import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import PieChart from '../../../components/Charts/PieChart';
import StackedColumn from '../../../components/Charts/StackedColumn';
import HorizontalBar from '../../../components/Charts/HorizontalBar';

const FootPrintDisplay = ({
  pieChartdata,
  yAxisTitle,
  stackedColChartData,
  categories,
  currentSector,
  handleSectorChange,
  parentData,
  parentCategories,
}) => (
  <React.Fragment>
    <Grid container>
      <Grid item xs={6}>
        <PieChart
          title="Funds' Footprint Contribution"
          data={pieChartdata}
          isFundOfFunds={true}
          tooltipHeading={yAxisTitle}
        />
      </Grid>
      <Grid item xs={6}>
        <StackedColumn
          title="Funds' Sectoral Intensity"
          data={stackedColChartData}
          categories={categories}
          yAxisTitle={yAxisTitle}
          showLegend={false}
        />
      </Grid>
    </Grid>
    <div
      style={{
        fontSize: 14,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      The pie chart shows the composition of the total fund of funds footprint,
      by displaying the contribution of the funds for the selected scope. Where
      necessary, the funds&apos; footprints are weighted by the funds&apos;
      weights. The sector intensities of the funds are calculated as single
      portfolios.{' '}
    </div>
    <Grid item xs={4}>
      <FormControl variant="outlined">
        <InputLabel>Select Sector</InputLabel>
        <Select
          label="Select Sector"
          value={currentSector}
          onChange={handleSectorChange}
          style={{ fontSize: 14, width: 300, marginBottom: 20 }}
        >
          {categories.length > 0
            && categories.map((sector) => (
              <MenuItem key={sector} value={sector}>
                {sector}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Grid>
    <Grid container>
      <Grid item xs={12}>
        <HorizontalBar
          title={`Funds' Sectoral Breakdown of Intensity: ${currentSector}`}
          data={parentData}
          categories={parentCategories}
          yAxisTitle={yAxisTitle}
          isEnabled="false"
          tooltipText="Sector Intensity"
        />
      </Grid>
    </Grid>
  </React.Fragment>
);

FootPrintDisplay.propTypes = {
  pieChartdata: PropTypes.array.isRequired,
  stackedColChartData: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  parentCategories: PropTypes.array.isRequired,
  currentSector: PropTypes.string.isRequired,
  yAxisTitle: PropTypes.string.isRequired,
  handleSectorChange: PropTypes.func.isRequired,
  parentData: PropTypes.array.isRequired,
};

export default FootPrintDisplay;
