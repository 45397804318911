import React from 'react';
import PropTypes from 'prop-types';
import strings from '../constants';
import ReportTable from '../../../../components/PdfReport/ReportTable';
import {
  getContributionBreakdown,
  getContributionBreakdownTable,
  getReservesFootprint,
  getReservesFootprintTable,
} from '../../../../helpers/report';
import ReportHorizontalBar from '../../../../components/PdfReport/Charts/ReportHorizontalBar';

const reservesFootprintColumns = [
  { name: 'Fossil Fuel Type', selector: 'type', right: false },
  {
    name: 'Portfolio GHG Intensity (tCO2e)',
    selector: 'portfolio',
    right: true,
  },
  {
    name: 'Benchmark GHG Intensity (tCO2e)',
    selector: 'benchmark',
    right: true,
  },
];

const contributionBreakdownColumns = [
  { name: 'Country', selector: 'country', right: false },
  {
    name: 'Contribution to Portfolio Footprint (%)',
    selector: 'value',
    right: true,
  },
];

const FossilAndAssets = ({ theme, data }) => {
  const { portfolio, benchmark } = data;

  let reservesFootprint = { data: [], yAxisTitle: '', categories: [] };
  let reservesFootprintTable = [];

  let contributionBreakdown = { data: [], yAxisTitle: '', categories: [] };
  let contributionBreakdownTable = [];

  if (portfolio && benchmark) {
    reservesFootprint = getReservesFootprint(
      portfolio.fossil_fuel,
      benchmark.fossil_fuel,
    );
    reservesFootprintTable = getReservesFootprintTable(
      portfolio.fossil_fuel,
      benchmark.fossil_fuel,
    );
    contributionBreakdown = getContributionBreakdown(
      portfolio.fossil_fuel,
      theme,
    );
    contributionBreakdownTable = getContributionBreakdownTable(
      portfolio.fossil_fuel,
    );
  }

  return (
    <div className="pdf_page">
      <h2>Fossil-Fuels & Stranded Assets</h2>
      <div className="span-2">
        <p>{strings.fossilFuel.text1}</p>
        <p>{strings.fossilFuel.text2}</p>
      </div>
      <div>
        <h3>Fossil-Fuel Reserves Footprint</h3>
        <ReportHorizontalBar
          categories={reservesFootprint.categories}
          data={reservesFootprint.data}
          yAxisTitle={reservesFootprint.yAxisTitle}
        />
        <div>
          <p>{strings.fossilFuel.text3}</p>
          <p>{strings.fossilFuel.text4}</p>
        </div>
        <div style={{ marginTop: 10, marginBottom: 20 }}>
          <ReportTable
            columns={reservesFootprintColumns}
            data={reservesFootprintTable}
          />
        </div>
      </div>
      <div>
        <h3>Country Contributions Breakdown</h3>
        <ReportHorizontalBar
          categories={contributionBreakdown.categories}
          data={contributionBreakdown.data}
          yAxisTitle={contributionBreakdown.yAxisTitle}
        />
        <div>
          <p>{strings.fossilFuel.text5}</p>
          <p>{strings.fossilFuel.text6}</p>
        </div>
        <div style={{ marginTop: 21, marginBottom: 20 }}>
          <ReportTable
            headerHeight={25}
            columns={contributionBreakdownColumns}
            data={contributionBreakdownTable}
          />
        </div>
      </div>
    </div>
  );
};

FossilAndAssets.propTypes = {
  theme: PropTypes.string.isRequired,
  data: PropTypes.shape({
    portfolio: PropTypes.object,
    benchmark: PropTypes.object,
    auth: PropTypes.object,
    filters: PropTypes.object,
  }),
};

export default FossilAndAssets;
