/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getNumberFormatter } from '../../util/formatNumber';

const useStyles = makeStyles(() => ({
  row: {
    borderBottom: '1px solid black !important',
  },
  heading: {
    background: '#4e4e4e',
    color: 'white',
    fontWeight: 'bold',
  },
  data: {
    paddingTop: 5,
    paddingBottom: 3,
  },
}));

function ReportTable({
  columns, data, headerHeight, width = '100%',
}) {
  const classes = useStyles();
  return (
    <table style={{ width }}>
      <thead>
        <tr style={{ height: headerHeight }}>
          {columns.map(({
            name, headerFormat, right, width: colWidth,
          }) => (
            <th
              key={name}
              className={classes.heading}
              style={{
                textAlign: right ? 'right' : 'left',
                ...(colWidth ? { width: colWidth } : null),
              }}
            >
              {headerFormat
                || name.split('\n').map((text) => <div key={text}>{text}</div>)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.length > 0
          && data.map((row, index) => (
            <tr className={classes.row} key={index}>
              {columns.map((column) => {
                const text = getNumberFormatter(column.selector)(row)
                  || column.noDataText
                  || '';
                return (
                  <td
                    className={classes.data}
                    style={{
                      textAlign: column.right ? 'right' : 'left',
                      fontWeight: column.bold ? 'bold' : 'normal',
                    }}
                    key={text}
                  >
                    {column.render
                      ? column.render({
                        data: getNumberFormatter(column.selector)(row),
                        row,
                      })
                      : text}
                  </td>
                );
              })}
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default ReportTable;
