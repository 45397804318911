import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { coalPowerCells } from '../../../util/TableHeadConfig';
import { LoadingHOC } from '../../../util/LoadingHOC';
import CoalPowerDisplay from './CoalPowerDisplay';
import useGetPayload from '../../../hooks/useGetPayload';
import CoalPowerModel from '../../../models/strandedAsset/coalPower';
import { useGetStrandedCoalPowerAnalysisQuery } from '../../../redux/apiHooks';

const CoalPowerDisplayWithLoading = LoadingHOC(CoalPowerDisplay);

const CoalPower = () => {
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const { auth, payload } = useGetPayload(CoalPowerModel);
  const {
    data: fossilFuelData,
    isFetching,
    error,
  } = useGetStrandedCoalPowerAnalysisQuery(payload);

  const { showBenchmark } = auth;

  const getData = () => {
    let cData = [];
    let tData = [];

    if (!isEmpty(fossilFuelData)) {
      const portValue = fossilFuelData.data.chart.portfolio;
      const benchValue = showBenchmark && fossilFuelData.data.chart.benchmark;

      cData = [
        {
          name: 'portfolio',
          data: [portValue],
        },
      ];

      if (showBenchmark) {
        cData.push({
          name: 'benchmark',
          data: [benchValue],
        });
      }

      tData = fossilFuelData.data.table;
    }
    setChartData(cData);
    setTableData(tData);
  };

  useEffect(() => {
    getData();
  }, [fossilFuelData]);

  return (
    <React.Fragment>
      <CoalPowerDisplayWithLoading
        error={error}
        loading={isFetching}
        chartData={chartData}
        tableData={tableData}
        coalPowerCells={coalPowerCells}
      />
    </React.Fragment>
  );
};

export default CoalPower;
