// eslint-disable-next-line import/no-extraneous-dependencies
import { configureStore } from '@reduxjs/toolkit';
// https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
// eslint-disable-next-line import/no-cycle
import rootReducer from './reducers';
import apiSlice from './apiSlice';
// import tempMetricApiSlice from './reducers/tempMetricApi';

export const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'filters'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    apiSlice.middleware,
    // tempMetricApiSlice.middleware,
    thunk,
    routerMiddleware(history),
    reduxImmutableStateInvariant(),
  ],
});

export const persistor = persistStore(store);
