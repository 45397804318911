/* eslint-disable react/prop-types */
import React from 'react';
import { getLatestYearObject } from '../../../../helpers/report';
import { formatNumber } from '../../../../util/formatNumber';
import { disclosureCategoriesMap } from '../contants';

// eslint-disable-next-line no-unused-vars
function Appendix({ data }) {
  const { singleIsin, benchmark } = data;
  const corporate = singleIsin?.name;
  const category = singleIsin?.disclosure?.sc12?.data[0]?.category;
  const industryBenchmark = singleIsin?.sub_industry_group;
  const singleIsinEmissionsFootprint = getLatestYearObject(
    singleIsin?.portfolio_emisisons?.EnterpriseValCash?.data || {},
  );
  const benchmarkEmissionsFootprint = getLatestYearObject(
    benchmark?.portfolio_emisisons?.EnterpriseValCash?.data || {},
  );
  return (
    <div className="pdf_page">
      <h2 className="table-heading">Appendix</h2>
      <div className="span-2">
        <p>Summary Statistics</p>
        <table className="appendix_table">
          <tr>
            <th className="left">Company</th>
            <th className="right">{corporate}</th>
            <th className="right">{industryBenchmark}</th>
          </tr>
          <tr>
            <td className="left">No of Companies</td>
            <td className="right">1</td>
            <td className="right">{benchmark?.no_of_companies}</td>
          </tr>
          <tr>
            <td className="left">Disclosure Category (ICE)</td>
            <td className="right">
              Category {disclosureCategoriesMap.get(category)?.no}
            </td>
            <td className="right">
              {benchmark?.disclosure?.sc12?.data.map((categoryRow) => (
                <div key={categoryRow.category}>
                  Category
                  {disclosureCategoriesMap.get(categoryRow.category)?.no}:{' '}
                  {categoryRow.value}%
                </div>
              ))}
            </td>
          </tr>
          <tr>
            <td className="left">Emissions Footprint (WACI by EVIC)</td>
            <td className="right bold">tCO2e / 1M USD</td>
            <td className="right bold">tCO2e / 1M USD</td>
          </tr>
          <tr>
            <td className="left">
              <span style={{ marginLeft: 10 }}>Scope 1</span>
            </td>
            <td className="right">{singleIsinEmissionsFootprint?.sc1}</td>
            <td className="right">{benchmarkEmissionsFootprint?.sc1}</td>
          </tr>
          <tr>
            <td className="left">
              <span style={{ marginLeft: 10 }}>Scope 2</span>
            </td>
            <td className="right">{singleIsinEmissionsFootprint?.sc2}</td>
            <td className="right">{benchmarkEmissionsFootprint?.sc2}</td>
          </tr>
          <tr>
            <td className="left">
              <span style={{ marginLeft: 10 }}>Scope 3</span>
            </td>
            <td className="right">{singleIsinEmissionsFootprint?.sc3}</td>
            <td className="right">{benchmarkEmissionsFootprint?.sc3}</td>
          </tr>
          <tr>
            <td className="left">Reported Emissions Reduction Targets</td>
            <td className="right">{singleIsin?.has_targets ? 'Yes' : 'No'}</td>
            <td className="right">{benchmark?.has_targets ? 'Yes' : 'No'}</td>
          </tr>
          <tr>
            <td className="left">ITR Score</td>
            <td className="right">
              {singleIsin?.portfolio_alignment?.implied_temp}
            </td>
            <td className="right">
              {benchmark?.portfolio_alignment?.implied_temp}
            </td>
          </tr>
        </table>
      </div>
      <h3 className="span-2 table-heading">
        Top 10 by Intensities Contributors (Industry Benchmark)
      </h3>
      <div>
        <div className="list-of-companies_header">
          Top 10 Intensity Contributors (Scope 1, 2 & 3)
        </div>
        <table className="list-of-companies">
          <tr>
            <th>Company Name</th>
            <th>
              Contributions
              <br />
              (tCO2e/1M USD)
            </th>
          </tr>
          {benchmark?.top_10_emission?.by_contribution?.data.map((intensity) => (
            <tr key={intensity.Company}>
              <td>{intensity.Company}</td>
              <td>{formatNumber(intensity.Contribution_Scope_123)}</td>
            </tr>
          ))}
        </table>
        <div className="report-text">
          <p>
            The Top 10 Intensity Contributors displays the top 10 companies with
            the highest carbon intensity contribution (scopes 1, 2, & 3) within
            the portfolio. This is calculated by using a company’s revenue,
            total emissions, and weighting within the portfolio.
          </p>
        </div>
      </div>
      <div>
        <div className="list-of-companies_header">
          Top 10 Intensities (Scope 1, 2 & 3)
        </div>
        <table className="list-of-companies">
          <tr>
            <th>Company Name</th>
            <th>
              Intensities
              <br />
              (tCO2e/1M USD)
            </th>
          </tr>
          {benchmark?.top_10_emission?.by_intensities?.data.map((intensity) => (
            <tr key={intensity.Company}>
              <td>{intensity.Company}</td>
              <td>{formatNumber(intensity.Intensity_Scope_123)}</td>
            </tr>
          ))}
        </table>
        <div className="report-text">
          <p>
            The Top 10 Intensities displays the top 10 companies with the
            highest carbon intensities (scopes 1, 2, & 3) within the portfolio.
            This is calculated by using a company’s revenue and total emissions.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Appendix;
