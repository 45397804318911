import Version from './version';
import YearQuarter from './yearQuarter';

export default class baseSettingsRequest {
  constructor(
    userInfo,
    yearEmissions,
    yearFundamentals,
    currencyYear,
    quarterEmissions,
    quarterFundamentals,
    currencyTarget,
    versionEmissions,
    versionFundamentals,
    theme,
    targetYear,
    targetQuarter,
  ) {
    const { display_name: displayName } = userInfo;
    this.display_name = displayName;
    this.year = new YearQuarter(
      yearEmissions,
      yearFundamentals,
      targetYear,
      currencyYear,
    ).yearQuarter();
    this.quarter = new YearQuarter(
      quarterEmissions,
      quarterFundamentals,
      targetQuarter,
      currencyTarget,
    ).yearQuarter();
    this.version = new Version(versionEmissions, versionFundamentals).version();
  }

  toJsonObject() {
    return { ...this };
  }
}
