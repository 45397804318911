import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../../components/Table/DataTable';
import BubbleChart from '../../../components/Charts/BubbleChart';

const CompanyAnalysisDisplay = ({
  chartData,
  trial,
  tableData,
  columns,
  yAxisLabel,
  yAxisTitle,
}) => (
  <React.Fragment>
    <BubbleChart
      title="Company Level Analysis"
      data={chartData}
      xAxisLabel="Temperature Score"
      yAxisLabel={yAxisLabel}
      zAxisLabel="Weight"
      yAxisTitle={yAxisTitle}
      isExportEnabled={!trial}
    />
    <DataTable
      data={tableData}
      columns={columns}
      tableHeading="COMPANY_ANALYSIS"
    />
  </React.Fragment>
);

CompanyAnalysisDisplay.propTypes = {
  chartData: PropTypes.array.isRequired,
  trial: PropTypes.bool.isRequired,
  tableData: PropTypes.array.isRequired,
  companyAnalysisCells: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  yAxisLabel: PropTypes.string.isRequired,
  yAxisTitle: PropTypes.string.isRequired,
};

export default CompanyAnalysisDisplay;
