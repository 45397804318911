import React from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SelectwithSearch from '../../Autocomplete';
import config from '../config';
import { setBenchmarkSuccess } from '../../../redux/reducers/authReducer';

const SelectBenchmark = () => {
  const {
    portfolioList: portfolios,
    currentBenchmark,
    showBenchmark,
    loading,
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const tabValue = useSelector((state) => state.auth.tabValue);
  const path = window.location.pathname;
  const moduleConfig = config.find((obj) => obj.path === path) || {};
  const { moduleTabs } = moduleConfig;
  const filterType = moduleTabs?.find(({ tab }) => tab === tabValue)?.type;

  const isDisabled = moduleTabs
    ?.find(({ tab }) => tab === tabValue)
    ?.benchmark_disabled(showBenchmark) || loading;

  const onBenchmarkChange = async (currentValue) => {
    let benchmark = {};
    if (portfolios && portfolios.length > 0) {
      portfolios.forEach((port) => {
        if (port.label === currentValue) {
          benchmark = { ...port };
        }
      });
    }
    if (currentValue) dispatch(setBenchmarkSuccess(benchmark, filterType));
  };

  return (
    <Box mt={2} data-testid="select-benchmark">
      <SelectwithSearch
        heading={'Select Benchmark'}
        data={portfolios && portfolios.length > 0 ? portfolios : []}
        defaultValue={currentBenchmark}
        handleChange={onBenchmarkChange}
        type="benchmark"
        currentValue={currentBenchmark}
        disabled={isDisabled}
      />
    </Box>
  );
};

export default SelectBenchmark;
