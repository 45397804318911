class version {
  constructor(emissions, fundamentals) {
    this.emissions = emissions;
    this.fundamentals = fundamentals;
  }

  version = () => this;
}

export default version;
