import React from 'react';
import PropTypes from 'prop-types';
import StackedColumn from '../../Charts/StackedColumn';

function ReportStackedColumn(props) {
  return (
    <StackedColumn
      data={props.data}
      categories={props.categories}
      yAxisTitle={props.yAxisTitle}
      width={350}
      theme={'ICE light'}
      height={180}
      isExportable={false}
      showLegend={false}
      fontSize={7}
      plotLineWidth={1}
      showDataLabels={true}
      yAxisGridlineShow={true}
      {...props}
    />
  );
}

ReportStackedColumn.propTypes = {
  categories: PropTypes.array,
  data: PropTypes.array,
  yAxisTitle: PropTypes.string,
  yAxisPlotline: PropTypes.array,
};

export default ReportStackedColumn;
