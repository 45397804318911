import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { LoadingHOC } from '../../../util/LoadingHOC';
import AlignmentDisplay from './AlignmentDisplay';
import AlignmentModel from '../../../models/FOF/alignment';
import useGetPayload from '../../../hooks/useGetPayload';
import {
  useGetFoFPortfolioAlignmentQuery,
  useGetUserFundsPortfolioListQuery,
} from '../../../redux/apiHooks';

const AlignmentDisplayWithLoading = LoadingHOC(AlignmentDisplay);

const Alignment = () => {
  const [lineChartData, setLineChartData] = useState([]);
  const [isDeselect, setDeselect] = useState(false);

  const { payload, auth, filters } = useGetPayload(AlignmentModel);
  const {
    data: alignment,
    error,
    isFetching,
  } = useGetFoFPortfolioAlignmentQuery(payload);

  const { data: fundsData } = useGetUserFundsPortfolioListQuery();
  const allPortfolios = fundsData?.allPortfolios || [];

  const { currentFundsPortfolio, userInfo } = auth;
  const { portScenario } = filters;
  const emissionYear = userInfo.year.emissions;

  const getPortfolioName = (id) => {
    let portName = '';
    if (allPortfolios && allPortfolios.length > 0) {
      allPortfolios.forEach((portfolio) => {
        if (portfolio.portfolio_id === id) {
          portName = portfolio.name;
        }
      });
    }
    return portName;
  };

  const getChartData = (isSelected = false) => {
    const alignmentData = alignment.data;
    const childIds = [];
    const onePoint = [];
    const scenarioValue = [];
    const two = [];
    const twoPointSeven = [];
    let parentValue = {};
    const yr = emissionYear + 1;

    if (alignmentData && Object.keys(alignmentData).length > 0) {
      const scenario = portScenario === 'LowEnergyDemand'
        ? 'LowEnergyDemand'
        : portScenario === 'SSP226'
          ? 'SSP2-26'
          : 'SSP1-26';

      const chartData = [];
      const data = alignmentData.scenarios;
      if (alignmentData.dots && Object.keys(alignmentData.dots.length > 0)) {
        const scatterExtraData = {
          type: 'scatter',
          marker: {
            radius: 4,
            symbol: 'circle',
          },
        };
        Object.keys(alignmentData.dots).forEach((el) => {
          if (el !== currentFundsPortfolio.value && !isSelected) {
            chartData.push({
              ...scatterExtraData,
              name: getPortfolioName(el),
              data: [[Date.UTC(yr, '01', '01'), alignmentData.dots[el]]],
            });
          } else {
            parentValue = {
              ...scatterExtraData,
              name: currentFundsPortfolio.label,
              data: [[Date.UTC(yr, '01', '01'), alignmentData.dots[el]]],
            };
          }
        });
      }
      if (data && Object.keys(data).length > 0) {
        Object.keys(data).forEach((year) => {
          onePoint.push([
            Date.UTC(year, '01', '01'),
            data[year].OnePointSevenFive,
          ]);
          scenarioValue.push([
            Date.UTC(year, '01', '01'),
            data[year][scenario],
          ]);
          two.push([Date.UTC(year, '01', '01'), data[year].Two]);
          twoPointSeven.push([
            Date.UTC(year, '01', '01'),
            data[year].TwoPointSeven,
          ]);
        });
      }
      chartData.push({
        name: 'One Point Seven Five',
        data: onePoint,
      });
      chartData.push({
        name: scenario,
        data: scenarioValue,
      });
      chartData.push({
        name: 'Two',
        data: two,
      });
      chartData.push({
        name: 'Two Point Seven',
        data: twoPointSeven,
      });
      chartData.push(parentValue);
      if (Object.keys(alignmentData.dots).length > 0) {
        Object.keys(alignmentData.dots).forEach((key) => {
          if (key !== currentFundsPortfolio.value) {
            childIds.push(key);
          }
        });
      }
      const chartDataWithType = chartData.map((d) => {
        if (d.type === 'scatter') return d;
        return {
          ...d,
          type: 'line',
          marker: { enabled: false },
        };
      });

      setLineChartData(chartDataWithType);
      // setPortIds(childIds);
    }
  };

  const handleDeselect = async () => {
    const value = !isDeselect;
    setDeselect(value);
    getChartData(value);
  };

  useEffect(() => {
    if (alignment) getChartData();
  }, [alignment, portScenario]);

  return (
    <React.Fragment>
      <Box>
        <AlignmentDisplayWithLoading
          error={error}
          loading={isFetching}
          lineChartData={lineChartData}
          chartKey="FUND_ALIGNMENT"
          handleDeselect={handleDeselect}
          isDeselect={isDeselect}
        />
      </Box>
    </React.Fragment>
  );
};

export default Alignment;
