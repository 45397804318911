const portfolioEmissionCategoriesMap = {
  production: 'Production',
  // exported: 'Export',
  // imported: 'Import',
  consumption: 'Consumption',
};
const countryEmissisonCharts = {
  contributions: {
    chartName: 'contributions',
    chartTitle: 'Contribution',
    chartKey: 'SOVEREIGN_CONTRIBUTION',
  },
  intensities: {
    chartName: 'intensities',
    chartTitle: 'Intensity',
    chartKey: 'SOVEREIGN_INTENSITY',
  },
  weights: {
    chartName: 'weights',
    chartTitle: 'Weight',
    chartKey: 'SOVEREIGN_WEIGHT',
  },
};
const portfolioEmissionCategories = [
  'Production',
  // 'Export',
  // 'Import',
  'Consumption',
];
export {
  portfolioEmissionCategoriesMap,
  countryEmissisonCharts,
  portfolioEmissionCategories,
};
