/* eslint-disable react/prop-types */
import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import FilterTags from '../tags';
import colorSchema from '../../../Themes/config';
import config from '../config';
import { updateUserFootprintData } from '../../../services/auth';
import {
  generatePCAFInfoData,
  getFootprintParameters,
} from '../../../util/pcafHelpers';
import useGetTheme from '../../../hooks/useGetTheme';

const FilterAccordion = ({
  option,
  isAccordionOptionDisabled,
  fetchOption,
  onAccordionChange,
}) => {
  const { currentTheme } = useGetTheme();
  const { pathname } = window.location;
  const path = pathname.replace(/\/$/, '');
  return (
    <Accordion
      style={{
        position: 'relative',
        background: 'none',
        width: 250,
      }}
      defaultExpanded={true}
      key={option.filterName}
    >
      <AccordionSummary
        style={{
          backgroundColor: colorSchema[currentTheme].primary,
          borderRadius: '5px',
        }}
        expandIcon={
          <ExpandMoreIcon
            style={{ color: colorSchema[currentTheme].buttonText }}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        data-testid={option.filterName}
      >
        <span
          style={{
            color: colorSchema[currentTheme].buttonText,
            paddingLeft: '10px',
          }}
        >
          {option.filterName}
        </span>
      </AccordionSummary>

      {option.options.map((opt) => (
        <Accordion
          style={{
            position: 'relative',
            background: 'none',
            width: 250,
          }}
          key={opt.name}
          disabled={isAccordionOptionDisabled(option.filterName, opt.name)}
        >
          <AccordionSummary
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            data-testid={opt.name}
            // id="additional-actions1-header"
            expandIcon={<ArrowDropDownIcon />}
          >
            <div
              style={{
                display: 'block',
                color: colorSchema[currentTheme].inputLabel,
              }}
            >
              {opt.name}
            </div>
            <div
              data-testid="selected-value"
              style={{
                fontSize: 12,
                font: 'inherit',
                fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                fontWeight: '500',
              }}
            >
              {
                opt.options.find(
                  (o) => o.value
                    === fetchOption(option.filterName, opt.name, opt.options),
                )?.label
              }
            </div>
          </AccordionSummary>

          <AccordionDetails style={{ display: 'block' }}>
            {opt.options.map((opti) => (
              <FilterTags
                key={opti.label}
                name={opti.label}
                selected={
                  fetchOption(option.filterName, opt.name, opt.options)
                  === opti.value
                }
                pathName={path}
                filterName={opti.label}
                action={() => onAccordionChange(
                  opti.label,
                  option.filterName,
                  opt.name,
                  opt.options,
                )
                }
              />
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Accordion>
  );
};

const FilterAccordionDetails = ({
  option,
  isAccordionOptionDisabled,
  fetchOption,
  onAccordionChange,
  accordionData,
  filterItem,
}) => {
  const { currentTheme } = useGetTheme();
  const { pathname } = window.location;
  return (
    <Accordion
      style={{
        position: 'relative',
        background: 'none',
        width: 250,
      }}
      key={option.name}
      disabled={isAccordionOptionDisabled(
        accordionData.find((data) => data.filterName === filterItem.assetType)
          .filterName,
        option.name,
      )}
    >
      <AccordionSummary
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        data-testid={option.name}
        expandIcon={<ArrowDropDownIcon />}
      >
        <div
          style={{
            display: 'block',
            color: colorSchema[currentTheme].inputLabel,
          }}
        >
          {option.name}
        </div>
        <div
          style={{
            fontSize: 12,
            font: 'inherit',
            fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
            fontWeight: '500',
          }}
        >
          {
            option.options.find(
              (o) => o.value
                === fetchOption(
                  accordionData.find(
                    (data) => data.filterName === filterItem.assetType,
                  ).filterName,
                  option.name,
                  option.options,
                ),
            )?.label
          }
        </div>
      </AccordionSummary>

      <AccordionDetails style={{ display: 'block' }}>
        {option.options.map((o) => (
          <FilterTags
            key={o.label}
            name={o.label}
            selected={
              fetchOption(
                accordionData.find(
                  (data) => data.filterName === filterItem.assetType,
                ).filterName,
                option.name,
                option.options,
              ) === o.value
            }
            pathName={pathname}
            filterName={option.name}
            action={() => onAccordionChange(
              o.label,
              accordionData.find(
                (data) => data.filterName === filterItem.assetType,
              ).filterName,
              option.name,
              option.options,
            )
            }
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

const FilterPcafAccordion = () => {
  const { pathname } = window.location;
  const path = pathname.replace(/\/$/, '');
  const moduleConfig = config.filter((obj) => obj.path === path);
  const accordionData = moduleConfig?.[0]?.accordionOptions
    ? [...config.filter((obj) => obj.path === path)?.[0]?.accordionOptions]
    : null;

  if (!accordionData) return null;

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const filterItem = useSelector((state) => state.filters);

  const { tabValue, userInfo } = auth;

  const fetchOption = (optionName, optName) => {
    const footprintParams = getFootprintParameters(
      userInfo.footprint_parameters,
      accordionData,
    );
    const filter = footprintParams.find((data) => data.name === optionName);
    const d = filter[optName];
    return d;
  };

  const onAccordionChange = async (
    propertyValue,
    accordionName,
    propertyName,
    propertyOptions,
  ) => {
    const footprintParams = getFootprintParameters(
      userInfo.footprint_parameters,
      accordionData,
    );
    const updatedData = footprintParams.map((param) => {
      let newPropertyName = param[propertyName];
      if (param.name === accordionName) {
        newPropertyName = propertyOptions.filter(
          (d) => d.label === propertyValue,
        )[0].value;
      }
      return {
        ...param,
        [propertyName]: newPropertyName,
      };
    });
    const updatedUserInfo = {
      footprint_parameters: generatePCAFInfoData(
        updatedData,
        null,
        filterItem.footprintMetric,
      ),
    };
    dispatch(updateUserFootprintData(updatedUserInfo));
  };

  const isAccordionOptionDisabled = (name) => {
    const footprintParams = getFootprintParameters(
      userInfo.footprint_parameters,
      accordionData,
    );
    const j = footprintParams.findIndex((data) => data.name === name);
    return footprintParams[j].Methodology === 'pcaf';
  };

  // {accordionData
  //   && accordionData.some((data) => data?.tabs[tabValue] === 'all') && <br />}
  if (accordionData?.some((data) => data?.tabs[tabValue] === 'all')) {
    return accordionData.map((option, index) => (
      <FilterAccordion
        option={option}
        key={index}
        isAccordionOptionDisabled={isAccordionOptionDisabled}
        fetchOption={fetchOption}
        onAccordionChange={onAccordionChange}
      />
    ));
  }
  if (accordionData?.some((data) => data?.tabs[tabValue] === 'assetType')) {
    return accordionData
      .find((data) => data.filterName === filterItem.assetType)
      .options.map((option, index) => (
        <FilterAccordionDetails
          option={option}
          key={index}
          isAccordionOptionDisabled={isAccordionOptionDisabled}
          fetchOption={fetchOption}
          onAccordionChange={onAccordionChange}
          accordionData={accordionData}
          filterItem={filterItem}
        />
      ));
  }
  return null;
};

export default FilterPcafAccordion;
