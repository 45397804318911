import React from 'react';

function Disclaimer() {
  return (
    <div className="pdf_page pdf_page1">
      <h2 className="span-2">Disclaimer</h2>
      <div className="span-2">
        <p>LIMITATIONS:</p>
        <p>
          This document contains information that is confidential and
          proprietary property and/or trade secret of Intercontinental Exchange,
          Inc. and/or its affiliates (the “ICE Group”), is not to be published,
          reproduced, copied, modified, disclosed or used without the express
          written consent of ICE Group.
        </p>
        <p>
          This document is provided for informational purposes only. The
          information contained herein is subject to change without notice and
          does not constitute any form of warranty, representation, or
          undertaking. Nothing herein should in any way be deemed to alter the
          legal rights and obligations contained in agreements between ICE Group
          and its respective clients relating to any of the products or services
          described herein. Nothing herein is intended to constitute legal, tax,
          accounting, investment, or other professional advice. Users of the
          report should consult with an attorney, tax, or accounting
          professional regarding any specific legal, tax, or accounting
          situation.
        </p>
        <p>
          All feature values included in the report, including those that are
          reflections of data provided by other data providers as well as
          forecasts of expectations of change, are estimates based upon the
          currently available information, are provided as is, and should be
          treated as estimates and forecasts with substantial potential
          deviations from underlying values.
        </p>
        <p>
          There are many methodologies (including computer-based analytical
          modelling) available to calculate and determine information such as
          the information described herein. The climate related metrics
          contained herein may not generate results that correlate to actual
          outcomes, and/or actual behaviour of the market. There may be errors
          or defects in the ICE Group software, databases, or methodologies that
          may cause resultant data to be inappropriate for use for certain
          purposes or use cases, and/or within certain applications. Certain
          historical data may be subject to periodic updates over time due to
          recalibration processes, including, without limitation enhancement of
          the models and increased coverage. Although the ICE Group may elect to
          update the data it uses from time to time, it has no obligation to do
          so.
        </p>
        <p>
          ICE Group are not registered as nationally registered statistical
          rating organizations, nor should this information be construed to
          constitute an assessment of the creditworthiness of any company or
          financial instrument.
        </p>
        <p>
          GHG emissions information available is either compiled from publicly
          reported information or estimated, as indicated in the applicable
          product and services.
        </p>
        <p>
          Trademarks of the ICE Group include: Intercontinental Exchange, ICE,
          ICE block design, NYSE, ICE Data Services, and New York Stock
          Exchange. Information regarding additional trademarks and intellectual
          property rights of Intercontinental Exchange, Inc. and/or its
          affiliates is located at https://www.theice.com/terms-of-use. Other
          products, services, or company names mentioned herein are the property
          of, and may be the service mark or trademark of, their respective
          owners.
        </p>
        <p>© 2023 Intercontinental Exchange, Inc.</p>
      </div>
    </div>
  );
}

export default Disclaimer;
