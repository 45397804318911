import React from 'react';
import PropTypes from 'prop-types';
import useCurrentTheme from '../../hooks/useCurrentTheme';
import BaseChart from './BaseChart';
import ChartProps from './ChartProps';

function PieChart({ isFundOfFunds, ...props }) {
  const { usedStyles } = useCurrentTheme(props.theme);
  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    tooltip: {
      pointFormat: isFundOfFunds
        ? '{series.name}: <b>{point.y}</b> {tooltipHeading}'
        : '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    plotOptions: {
      pie: {
        size: '100%',
        allowPointSelect: true,
        cursor: 'pointer',
        showInLegend: true,
        dataLabels: {
          enabled: true,
          format: isFundOfFunds ? '{point.y} ' : '{point.percentage:.1f} %',
          color: usedStyles.chartText,
          style: {
            color: usedStyles.chartText,
            fontSize: props.fontSize,
            // #TO-DO: Test this if this is really required
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            shadow: false,
            fontWeight: '400',
          },
        },
      },
    },
  };
  return (
    <BaseChart
      {...props}
      chartType="pie"
      legendType="horizontal"
      options={options}
    />
  );
}

PieChart.propTypes = {
  ...ChartProps,
  isFundOfFunds: PropTypes.bool,
};
export default PieChart;
