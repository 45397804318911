import React, { useState, useEffect } from 'react';
import { LoadingHOC } from '../../../util/LoadingHOC';
import PortfolioEmissionDisplay from './PortfolioEmissionDisplay';
import { useGetEmissionPortfolioEmissionQuery } from '../../../redux/apiHooks';
import PortfolioEmissions from '../../../models/emissions/portfolioEmissions';
import useGetPayload from '../../../hooks/useGetPayload';
import { portEmissionCells } from '../../../util/TableHeadConfig';

const PortfolioEmissionsWithLoading = LoadingHOC(PortfolioEmissionDisplay);

const CATEGORIES_MAP = {
  sc12: 'Scope 1+2',
  sc3: 'Scope 3',
  sc123: 'Scope 1+2+3',
};

const PortfolioEmission = () => {
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [yAxisTitle, setYAxisTitle] = useState('');
  const [tableLabel, setTableLabel] = useState('');

  const { auth, filters, payload } = useGetPayload(PortfolioEmissions);
  const { data, isFetching, error } = useGetEmissionPortfolioEmissionQuery(payload);

  const { showBenchmark } = auth;
  const { inferenceType } = filters;

  const getData = async () => {
    const inferences = ['avg', 'max'];
    const names = ['portfolio'];
    if (showBenchmark) names.push('benchmark');
    const currentInference = Array.isArray(inferenceType)
      ? inferenceType[0]
      : inferenceType.toLowerCase();

    const intensityChartData = [];
    const intensityTableData = [];
    let yTitle = '';
    let label = '';

    names.forEach((name) => {
      const tmpObj = data[name] ? data[name][currentInference] : {};

      const chartObj = [];
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const property in tmpObj.data) {
        chartObj.push({
          name: CATEGORIES_MAP[property],
          y: tmpObj.data[property],
        });
      }
      intensityChartData.push({ name, data: chartObj });

      const chartLabel = tmpObj?.metadata?.label.split(' ').slice(-2);
      yTitle = chartLabel && `${chartLabel.join(' ')} ${tmpObj.metadata.unit}`;
      label = tmpObj?.metadata?.unit;

      inferences.forEach((infType) => {
        const { ...tmp } = data[name] ? data[name][infType].data : {};
        tmp.name = data[name] ? data[name][infType].metadata.label : '';
        intensityTableData.push(tmp);
      });
    });
    setChartData(intensityChartData);
    setTableData(intensityTableData);
    setYAxisTitle(yTitle);
    setTableLabel(label);
  };

  useEffect(() => {
    if (data) getData();
  }, [data, inferenceType]);

  return (
    <PortfolioEmissionsWithLoading
      error={error}
      categories={Object.values(CATEGORIES_MAP)}
      horizontalBarData={chartData}
      yAxisTitle={yAxisTitle}
      dataTableData={tableData}
      columns={portEmissionCells(tableLabel, CATEGORIES_MAP)}
      tableHeading="PORTFOLIO_INTENSITY"
      loading={isFetching}
    />
  );
};

export default PortfolioEmission;
