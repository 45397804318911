import React from 'react';
import PropTypes from 'prop-types';

import ReportTable from '../../../../components/PdfReport/ReportTable';
import {
  getContributionBreakdown,
  getContributionBreakdownTable,
  getReservesFootprint,
  getReservesFootprintTable,
} from '../../../../helpers/report';
import ReportHorizontalBar from '../../../../components/PdfReport/Charts/ReportHorizontalBar';

const reserverFootprintColumns = [
  { name: 'Fossil Fuel Type', selector: 'type', right: false },
  {
    name: 'Portfolio GHG Intensity (tCO2e)',
    selector: 'portfolio',
    right: true,
  },
  {
    name: 'Benchmark GHG Intensity (tCO2e)',
    selector: 'benchmark',
    right: true,
  },
];

const contributionBreakdownColumns = [
  { name: 'Country', selector: 'country', right: false },
  {
    name: 'Contribution to Portfolio Footprint (%)',
    selector: 'value',
    right: true,
  },
];
function FossilFuelsAndStrandedAssets({ data }) {
  const { portfolio, benchmark } = data;

  let reservesFootprint = { data: [], yAxisTitle: '', categories: [] };
  let reservesFootprintTable = [];

  let contributionBreakdown = { data: [], yAxisTitle: '', categories: [] };
  let contributionBreakdownTable = [];

  if (portfolio && benchmark) {
    reservesFootprint = getReservesFootprint(
      portfolio.fossil_fuel,
      benchmark.fossil_fuel,
    );
    reservesFootprintTable = getReservesFootprintTable(
      portfolio.fossil_fuel,
      benchmark.fossil_fuel,
    );
    contributionBreakdown = getContributionBreakdown(
      portfolio.fossil_fuel,
      'ICE light',
    );
    contributionBreakdownTable = getContributionBreakdownTable(
      portfolio.fossil_fuel,
    );
  }
  return (
    <div className="pdf_page">
      <h2>Fossil-Fuels & Stranded Assets</h2>
      <div>
        <h3>Fossil-Fuel Reserves Footprint</h3>
        <ReportHorizontalBar
          categories={reservesFootprint.categories}
          data={reservesFootprint.data}
          yAxisTitle={reservesFootprint.yAxisTitle}
        />
        <div>
          <p>
            The Fossil-Fuel Reserve Footprint chart measures the portfolio and
            benchmark’s exposure to coal, oil and gas reserves. The footprint
            calculation is based on a list of 100 companies with the largest
            reserves of coal, oil, and gas.
          </p>
        </div>
        <div style={{ marginTop: 10, marginBottom: 20 }}>
          <ReportTable
            columns={reserverFootprintColumns}
            data={reservesFootprintTable}
          />
        </div>
      </div>
      <div>
        <h3>Country Contributions Breakdown</h3>
        <ReportHorizontalBar
          categories={contributionBreakdown.categories}
          data={contributionBreakdown.data}
          yAxisTitle={contributionBreakdown.yAxisTitle}
        />
        <div>
          <p>
            The Country Contributions Breakdown chart displays the contribution
            of the country (based on the country of domicile) to the total
            portfolio footprint.
          </p>
        </div>
        <div style={{ marginTop: 21, marginBottom: 20 }}>
          <ReportTable
            columns={contributionBreakdownColumns}
            data={contributionBreakdownTable}
            headerHeight={25}
          />
        </div>
      </div>
    </div>
  );
}

FossilFuelsAndStrandedAssets.propTypes = {
  data: PropTypes.shape({
    portfolio: PropTypes.object,
    benchmark: PropTypes.object,
    tempScore: PropTypes.array,
    auth: PropTypes.object,
    filters: PropTypes.object,
  }),
};
export default FossilFuelsAndStrandedAssets;
