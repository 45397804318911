import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { LoadingHOC } from '../../../util/LoadingHOC';
import PortfolioAlignmentDisplay from './PortAlignmentDisplay';
import { EMISSION_YEAR, getLegendName, NET_ZERO_LEGENDS } from '../constants';
import { getChartDataFromObject } from '../../../helpers';
import PortfolioModel from '../../../models/netZero/portfolio';
import useGetPayload from '../../../hooks/useGetPayload';
import { useGetNetZeroPortfolioQuery } from '../../../redux/apiHooks';

const PortfolioAlignmentWithLoading = LoadingHOC(PortfolioAlignmentDisplay);

const PortAlignmentV2 = () => {
  const [cumulativeData, setCumulativeData] = useState([]);
  const [techData, setTechData] = useState([]);
  const [alignmentData, setAlignmentData] = useState([]);

  const { auth, filters, payload } = useGetPayload(PortfolioModel);
  const {
    data: portfolioData,
    isFetching,
    error,
  } = useGetNetZeroPortfolioQuery(payload);
  const { userInfo } = auth;
  const { warmingScenario, isNormalised } = filters;

  const alignmentKey = isNormalised ? 'normalised_emissions' : 'emissions';
  const cumulativeEmissionKey = 'cumulative_emissions';

  const getChartData = () => {
    const alignmentD = portfolioData[alignmentKey].data || {};
    const cumulativeD = portfolioData[cumulativeEmissionKey].data || {};
    const technologyD = portfolioData.technology_risk.data || {};
    if (!isEmpty(alignmentD)) {
      const alignmentChartData = [];
      warmingScenario.forEach((key) => {
        if (alignmentD[key]) {
          alignmentChartData.push({
            name: getLegendName(key),
            data: getChartDataFromObject(alignmentD[key]),
          });
        }
      });
      alignmentChartData.push({
        name: NET_ZERO_LEGENDS.expected,
        data: getChartDataFromObject(alignmentD.expected),
      });
      setAlignmentData(alignmentChartData);
    }
    if (!isEmpty(cumulativeD)) {
      const cumulativeChartData = [];
      warmingScenario.forEach((key) => {
        if (cumulativeD[key]) {
          cumulativeChartData.push({
            name: getLegendName(key),
            data: getChartDataFromObject(cumulativeD[key]),
          });
        }
      });
      cumulativeChartData.push({
        name: NET_ZERO_LEGENDS.expected,
        data: getChartDataFromObject(cumulativeD.expected),
      });
      setCumulativeData(cumulativeChartData);
    }
    if (!isEmpty(technologyD)) {
      const techChartData = [];
      warmingScenario.forEach((key) => {
        if (technologyD[`${key}_ccs`]) {
          techChartData.push({
            name: `${key} - Aligned CCS`,
            data: getChartDataFromObject(technologyD[`${key}_ccs`]),
          });
        }
        if (technologyD[`${key}_gross`]) {
          techChartData.push({
            name: `${key} - Aligned Gross Emissions`,
            data: getChartDataFromObject(technologyD[`${key}_gross`]),
          });
        }
      });
      setTechData(techChartData);
    }
  };

  useEffect(() => {
    if (!isEmpty(portfolioData) && Array.isArray(warmingScenario)) {
      getChartData();
    }
  }, [portfolioData, warmingScenario, isNormalised]);

  const alignmentMeta = portfolioData?.[alignmentKey]?.metadata || {};
  const cumulativeMeta = portfolioData?.[cumulativeEmissionKey]?.metadata || {};
  const techMeta = portfolioData?.technology_risk?.metadata || {};
  const tempScore = portfolioData?.implied_temp?.toString() || '';
  const emissionYear = userInfo?.year?.emissions
    ? userInfo.year.emissions - 1
    : EMISSION_YEAR;
  return (
    <React.Fragment>
      <PortfolioAlignmentWithLoading
        loading={isFetching}
        error={error}
        tempScore={tempScore}
        alignmentData={alignmentData}
        cumulativeData={cumulativeData}
        techData={techData}
        alignmentMeta={alignmentMeta}
        cumulativeMeta={cumulativeMeta}
        techMeta={techMeta}
        emissionYear={emissionYear || undefined}
      />
    </React.Fragment>
  );
};

export default PortAlignmentV2;
