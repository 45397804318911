import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  generatePCAF,
  generateSectorData,
  getPublicPrivateReportData,
  getReportedInferredData,
} from '../util/pcafHelpers';

const usePcafScreenData = ({ assetType, data: pcafData }) => {
  const [portfolioFootprintReported, setPortfolioFootprintReported] = useState(
    'Reported Vs Inferred',
  );

  const [sectorCategories, setSectorCategories] = useState([]);
  const [sectorCategoriesData, setSectorCategoriesData] = useState([]);
  const [reportedInferredData, setReportedInferredData] = useState([]);

  const showBenchmark = useSelector((state) => state.auth.showBenchmark);
  const { strategy, emission } = useSelector((state) => state.filters);

  const categories = showBenchmark
    ? ['Benchmark', 'Portfolio', 'Benchmark', 'Portfolio']
    : ['Portfolio', 'Portfolio'];
  const portfolioFootprint = strategy === 'pcaf' ? 'PCAF Score' : 'Disclosure Category';
  const pcafScoreDisclosureCategoryData = isEmpty(pcafData)
    ? []
    : [
      ...generatePCAF({
        portfolioEmissionData: pcafData.data,
        isPcafScore: strategy === 'pcaf',
        showBenchmark,
        assetType,
      }),
    ];

  const updatePortfolioFootprintReported = (newValue) => {
    if (newValue === 'Reported Vs Inferred') {
      setReportedInferredData(
        getReportedInferredData({
          pcafData,
          showBenchmark,
          assetType,
        }),
      );
    } else {
      setReportedInferredData(
        getPublicPrivateReportData({
          pcafData,
          showBenchmark,
          assetType,
        }),
      );
    }
    if (portfolioFootprintReported !== newValue) {
      setPortfolioFootprintReported(newValue);
    }
  };

  useEffect(() => {
    updatePortfolioFootprintReported(portfolioFootprintReported);

    if (pcafData) {
      const { categories: newCategories, categoriesData } = generateSectorData({
        pcafData,
        showBenchmark,
        emission,
        assetType,
      });
      setSectorCategories(newCategories);
      setSectorCategoriesData(categoriesData);
    }
  }, [pcafData, strategy, emission]);

  const tempBaryAxisTitle = `${pcafData?.metadata?.label} ${pcafData?.metadata?.units}`;

  return {
    emission,
    // portfolioEmissionPcaf,
    portfolioFootprintReported,
    sectorCategories,
    sectorCategoriesData,
    reportedInferredData,
    categories,
    portfolioFootprint,
    pcafScoreDisclosureCategoryData,
    tempBaryAxisTitle,
    updatePortfolioFootprintReported,
  };
};

export default usePcafScreenData;
