import BaseRequest from '../../baseRequest';

export default class CompanyProfile extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem, moduleName } = props;
    const { assetClass, sector, includeTargets } = filterItem;

    this.asset_type = assetClass;
    this.sector = moduleName === 'Reports' ? 'ICE' : sector;
    this.include_targets = includeTargets;
  }
}
