import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import OnTrackWithTargets from '../../../components/OnTrackWithTargets';
import LineChart from '../../../components/Charts/LineChart';

const DecarbonisationMonitorDisplay = ({
  portfolioPercentage,
  chartData,
  yAxisTitle,
  unit,
  emissionYear,
  onTrackColor,
}) => (
  <React.Fragment>
    <Grid
      container
      item
      justifyContent="space-between"
      xs={12}
      style={{ padding: 16 }}
    >
      <Grid item>
        Portfolio Target Coverage: <strong>{portfolioPercentage}</strong>
      </Grid>
      <OnTrackWithTargets
        backgroundColor={onTrackColor}
        source="portfolio"
        addApostrophe={true}
      />
    </Grid>
    <Grid container>
      <Grid item xs={12}>
        <LineChart
          data={chartData}
          title={yAxisTitle}
          yAxisTitle={unit}
          emissionYear={emissionYear}
          height={500}
          markerEnabled={false}
          xAxisType="datetime"
        />
      </Grid>
    </Grid>
    <div
      style={{
        fontSize: 14,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      }}
    >
      This chart shows how the financed emissions of the portfolio have changed
      in the past and expected to change in future, based on reported and
      estimated emissions, company targets and sectoral emission trends from a
      current policies scenario. This is compared to a benchmark portfolio, and
      is relevant for understanding exposure to current and future regulatory
      policies targeted at reducing greenhouse gas emissions
    </div>
  </React.Fragment>
);

DecarbonisationMonitorDisplay.propTypes = {
  chartData: PropTypes.array.isRequired,
  yAxisTitle: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  emissionYear: PropTypes.number.isRequired,
  portfolioPercentage: PropTypes.string.isRequired,
  onTrackColor: PropTypes.string,
};

export default DecarbonisationMonitorDisplay;
