import React from 'react';
import { FormControl, OutlinedInput } from '@material-ui/core';
import SelectDropdown from '../components/CustomFormComponent/SelectDropdown';
import MultiSelectDropdown from '../components/CustomFormComponent/MultiSelectDropdown';
import { getArrayMap, getFilterkey, sectorMap } from '../helpers';

export const renderRangeInputComp = (props) => {
  const { fields, handleClick, usedStyles } = props;

  const onChangeCapture = (filterType, changeValue) => {
    let value = parseFloat(changeValue);
    if (Number.isNaN(value)) value = '';
    handleClick(value, filterType, fields?.id, true);
  };
  return (
    <>
      {fields.condition === 'range' ? (
        <>
          <FormControl
            sx={{
              width: 250,
              marginTop: 1,
              marginLeft: 0.3,
              backgroundColor: '#FFFFFF',
            }}
            size="small"
            error={!fields.gt && fields.gt !== 0}
            style={{
              minWidth: 250,
              marginTop: 7,
              marginLeft: 3,
              backgroundColor: usedStyles.mainBackground,
            }}
          >
            <OutlinedInput
              placeholder="Greater than"
              onChangeCapture={(event) => onChangeCapture('gt', event.target.value)
              }
              value={fields.gt}
              key="grater than"
              type="number"
            />
          </FormControl>
          <FormControl
            sx={{
              width: 250,
              marginTop: 1,
              marginLeft: 0.3,
              backgroundColor: '#FFFFFF',
            }}
            size="small"
            style={{
              minWidth: 250,
              marginTop: 7,
              marginLeft: 3,
              backgroundColor: usedStyles.mainBackground,
            }}
            error={!fields.lt && fields.lt !== 0}
          >
            <OutlinedInput
              placeholder="Less than"
              onChangeCapture={(event) => onChangeCapture('lt', event.target.value)
              }
              value={fields.lt}
              key="less than"
              type="number"
            />
          </FormControl>
        </>
      ) : (
        <>
          <FormControl
            sx={{
              width: 250,
              marginTop: 1,
              marginLeft: 0.3,
              backgroundColor: '#FFFFFF',
            }}
            size="small"
            error={!fields.value && fields.value !== 0}
            style={{
              minWidth: 250,
              marginTop: 7,
              marginLeft: 3,
              backgroundColor: usedStyles.mainBackground,
            }}
          >
            <OutlinedInput
              placeholder="Please enter value"
              onChangeCapture={(event) => onChangeCapture('value', event.target.value)
              }
              value={fields.value}
              key="value"
              type="number"
            />
          </FormControl>
        </>
      )}
    </>
  );
};

export function getSingleFieldDropdown(props) {
  const {
    fields,
    handleClick,
    onClearClick,
    selectorFields,
    onCloseHandler,
    isEditable,
    currentTheme,
    usedStyles,
  } = props;

  return (
    <div style={{ display: 'flex' }}>
      <SelectDropdown
        filterData={getArrayMap(fields.filterType)}
        handleChange={handleClick}
        fields={fields}
        isEditable={isEditable}
        type={getFilterkey(fields.filterType)}
        dropdownFields={selectorFields}
        labelText={fields.label}
        onClearClick={onClearClick}
        onCloseHandler={onCloseHandler}
      />
      {fields.isVisible ? (
        <>
          {!['targets'].includes(fields.filterType)
            && renderRangeInputComp({
              fields,
              handleClick,
              currentTheme,
              usedStyles,
            })}
        </>
      ) : null}
    </div>
  );
}

export function getMultiFieldDropdown(props) {
  const {
    fields, handleClick, onClearClick, metaData, currentTheme,
  } = props;
  return (
    <div style={{ display: 'flex' }}>
      <MultiSelectDropdown
        options={getArrayMap(
          fields.filterType === 'ice_sector'
            ? sectorMap[fields.filterType]
            : fields.filterType,
          metaData,
        )}
        isEditable={Boolean(fields.value)}
        handleChange={(event) => handleClick(event, 'value', fields.id, true)}
        currentValue={fields.value || []}
        labelText={fields.label}
        onClearClick={onClearClick}
        fields={fields}
        currentTheme={currentTheme}
      />
    </div>
  );
}
