/* eslint-disable */

import BaseRequest from '../baseRequest';

export default class PortfolioScope3Heatmap extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem } = props;
    const { sector, assetClass, inferenceType, emission, materiality } =
      filterItem;
    this.sector = sector;
    this.asset_type = assetClass;
    this.inference = inferenceType;
    this.scope = emission === 'Sc12' ? 'Sc123' : emission;
    this.scale_by_sector = materiality === 'matSector';
  }
}
