import BaseRequest from '../baseRequest';

export default class Portfolio extends BaseRequest {
  constructor(props, benchmarkScore) {
    super(props, benchmarkScore);
    const { filterItem, moduleName } = props;
    const {
      emission,
      // warmingScenario,
      marketValue,
      targetScenario,
      // emissionsType,
      assetClass,
      includeTargets,
    } = filterItem;

    this.asset_type = moduleName === 'TCFD' ? assetClass : ['Eq', 'CB'];
    this.inference = 'Avg';
    this.footprint = 'TotalCarbEmis';
    this.scope = emission;
    this.market_value = moduleName === 'TCFD' ? 'EnterpriseValCash' : marketValue;
    this.sector = 'SASB';
    // this.scenario = warmingScenario;
    this.scenario_type = targetScenario;
    // this.emission_type = moduleName === 'TCFD' ? 'net' : emissionsType;
    this.emission_type = 'net';
    this.include_historical = true;
    this.include_targets = includeTargets;
    this.show_ccs = true;
  }
}
