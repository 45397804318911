import React from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { Card, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import colorSchema from '../../Themes/config';
import convertHexToRGBA from '../../util/convertHexToRGBA';
import { getNumberFormatter } from '../../util/formatNumber';
import { downloadCSV } from '../../helpers';
import useGetTheme from '../../hooks/useGetTheme';

function createTableTheme(currentTheme) {
  const customStyles = {
    headCells: {
      style: {
        fontSize: 12,
        textAlign: 'right',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        background: colorSchema[currentTheme].tableHeaderBackground,
        color: colorSchema[currentTheme].tableHeaderText,
      },
      activeSortStyle: {
        color: colorSchema[currentTheme].tableHeaderText,
        '&:focus': {
          color: colorSchema[currentTheme].tableHeaderText,
        },
      },
      inactiveSortStyle: {
        '&:hover': {
          color: colorSchema[currentTheme].tableHeaderText,
        },
      },
    },
    rows: {
      style: {
        minHeight: 40, // override the row height
      },
    },
    cells: {
      style: {
        lineHeight: 1,
        fontSize: 12,
      },
    },
  };
  const scollStyle = {
    table: {
      style: {
        width: 1,
      },
    },
    headCells: {
      style: {
        fontSize: 12,
        textAlign: 'right',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        background: colorSchema[currentTheme].tableHeaderBackground,
        color: colorSchema[currentTheme].tableHeaderText,
      },
      activeSortStyle: {
        '&:focus': {
          color: colorSchema[currentTheme].tableHeaderText,
        },
      },
      inactiveSortStyle: {
        '&:hover': {
          color: colorSchema[currentTheme].tableHeaderText,
        },
      },
    },
    cells: {
      style: {
        fontSize: 12,
        background: colorSchema[currentTheme].mainBackground,
      },
    },
    rows: {
      style: {
        minHeight: 60,
        borderBottom: '1px solid red',
      },
    },
  };

  createTheme(currentTheme, {
    text: {
      primary: colorSchema[currentTheme].text,
      secondary: colorSchema[currentTheme].text,
      disabled: convertHexToRGBA(colorSchema[currentTheme].text, 12),
    },
    background: {
      default: colorSchema[currentTheme].mainBackground,
    },
    context: {
      background: colorSchema[currentTheme].mainBackground,
      text: colorSchema[currentTheme].text,
      fontFamily: 'Lucida Grande',
    },
    divider: {
      default: convertHexToRGBA(colorSchema[currentTheme].text, 12),
    },
    button: {
      default: colorSchema[currentTheme].text,
      focus: 'rgba(255, 255, 255, .54)',
      hover: 'rgba(255, 255, 255, .12)',
      disabled: convertHexToRGBA(colorSchema[currentTheme].text, 18),
    },
    // sortFocus: {
    //   default: 'rgba(255, 255, 255, .54)',
    // },
    // selected: {
    //   default: 'rgba(0, 0, 0, .7)',
    //   text: '#FFFFFF',
    // },
    // highlightOnHover: {
    //   default: 'rgba(0, 0, 0, .7)',
    //   text: '#FFFFFF',
    // },
    // striped: {
    //   default: 'rgba(0, 0, 0, .87)',
    //   text: '#FFFFFF',
    // },
  });
  return {
    customStyles,
    scollStyle,
  };
}

const ReactDataTable = ({
  columns,
  data,
  loading,
  isScroll,
  isSelectableRows,
  handleSelection,
  isExportEnabled = true,
  paginationRowsPerPageOptions = [10, 25, 100],
  paginationPerPage = 10,
  noDataMessage,
  noRowsPerPage = false,
  selectableRowSelected,
}) => {
  const { currentTheme } = useGetTheme();
  const processedCols = columns.map((col) => ({
    format: getNumberFormatter(col.selector),
    ...col,
  }));
  const auth = useSelector((state) => state.auth);
  const { userInfo } = auth;
  const isTrial = get(userInfo, 'trial', false);
  return isSelectableRows ? (
    <Card style={{ position: 'relative' }}>
      <DataTable
        noHeader={true}
        columns={processedCols}
        data={data}
        pagination={true}
        paginationPerPage={paginationPerPage}
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        highlightOnHover={false}
        progressPending={loading}
        progressComponent={
          <CircularProgress
            style={{ margin: 16, color: colorSchema[currentTheme].primary }}
          />
        }
        theme={currentTheme}
        customStyles={
          isScroll
            ? createTableTheme(currentTheme).scollStyle
            : createTableTheme(currentTheme).customStyles
        }
        onSelectedRowsChange={(e) => {
          handleSelection(e.selectedRows);
        }}
        noDataComponent={<CustomNoDataComponent message={noDataMessage} />}
        selectableRows
        selectableRowSelected={selectableRowSelected}
      />
    </Card>
  ) : (
    <Card style={{ position: 'relative', width: '100%' }}>
      <DataTable
        noHeader={true}
        columns={processedCols}
        data={data}
        pagination={true}
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        paginationComponentOptions={{ noRowsPerPage }}
        highlightOnHover={false}
        progressPending={loading}
        progressComponent={
          <CircularProgress
            style={{ margin: 16, color: colorSchema[currentTheme].primary }}
          />
        }
        paginationPerPage={paginationPerPage}
        theme={currentTheme}
        customStyles={
          isScroll
            ? createTableTheme(currentTheme).scollStyle
            : createTableTheme(currentTheme).customStyles
        }
        noDataComponent={<CustomNoDataComponent message={noDataMessage} />}
        selectableRowSelected={selectableRowSelected}
      />
      {isExportEnabled && !isTrial && (
        <CloudDownloadOutlinedIcon
          onClick={() => downloadCSV(data)}
          style={{
            fontSize: 30,
            position: 'absolute',
            left: 10,
            bottom: 10,
            color: colorSchema[currentTheme].text,
          }}
        />
      )}
    </Card>
  );
};

const CustomNoDataComponent = ({ message }) => (
  <p style={{ margin: 16 }}>{message || 'There are no records to display'}</p>
);

CustomNoDataComponent.propTypes = {
  message: PropTypes.string,
};
ReactDataTable.defaultProps = {
  isScroll: false,
  isExportEnabled: true,
  noRowsPerPage: false,
};
ReactDataTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  isScroll: PropTypes.bool,
  isSelectableRows: PropTypes.bool,
  handleSelection: PropTypes.func,
  isExportEnabled: PropTypes.bool,
  paginationRowsPerPageOptions: PropTypes.array,
  paginationPerPage: PropTypes.number,
  noDataMessage: PropTypes.string,
  noRowsPerPage: PropTypes.bool,
  selectableRowSelected: PropTypes.func,
};

export default ReactDataTable;
