/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import FilterTags from '../tags';
import colorSchema from '../../../Themes/config';
import { updateUserFootprintData } from '../../../services/auth';
import * as constants from '../constants';
import config from '../config';
import {
  generatePCAFInfoData,
  getFootprintParameters,
} from '../../../util/pcafHelpers';
import { setFilterItemSuccess } from '../../../redux/reducers/filterReducer';
import useGetTheme from '../../../hooks/useGetTheme';

const FilterDropdown = ({
  data, allowedTabs, filterData, setFilterData,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { currentTheme } = useGetTheme();
  const {
    moduleName, loading, tabValue, userInfo,
  } = useSelector(
    (state) => state.auth,
  );
  const filterItem = useSelector((state) => state.filters);
  const dispatch = useDispatch();
  const isDisabled = allowedTabs?.disabled(filterItem);
  const { pathname } = window.location;
  const path = pathname.replace(/\/$/, '');
  const moduleConfig = config.filter((obj) => obj.path === path);
  const accordionData = moduleConfig.length && moduleConfig[0]?.accordionOptions;

  // TODO: Remove
  const updateParameterSettingsData = async (key, value) => {
    const footprintParams = getFootprintParameters(
      userInfo.footprint_parameters,
      accordionData,
    );
    const updatedData = footprintParams.map((d) => {
      const newData = {};
      if (key === 'emissionsType') newData['Emission Type'] = value;
      if (key === 'strategy') {
        newData.Methodology = value;
        if (value === 'pcaf') {
          newData['Public Attribution'] = constants.ENTERPRISE_VALUE_CASH_VALUE;
          newData['Private Attribution'] = constants.MARKET_CAPITALIZATION_DEBT_VALUE;
          newData['Emission Type'] = 'by_pcaf_score';
        }
        if (value === 'default') {
          newData['Emission Type'] = 'avg';
        }
      }
      return {
        ...d,
        ...newData,
      };
    });
    const updatedUserInfo = {
      footprint_parameters: generatePCAFInfoData(
        updatedData,
        key === 'footprintMetric' ? value : null,
        filterItem.footprintMetric,
      ),
    };
    dispatch(updateUserFootprintData(updatedUserInfo));
  };

  // TODO: Very specific functions for specific tabs, rethink implementation
  const setEmissionData = (key) => {
    if (
      moduleName === 'Net-zero analysis'
      && tabValue === constants.NET_ZERO_COMPANY_ALIGNMENT
      && key === 'includeHistorical'
    ) {
      dispatch(
        setFilterItemSuccess({
          key: 'emission',
          value: 'Sc12',
        }),
      );
    }
  };

  const setwarmingScenario = (key, value) => {
    if (key === 'targetScenario') {
      let warmingScenario = '';
      switch (value) {
        case 'IPCC':
          switch (moduleName) {
            case 'Net-zero analysis':
              warmingScenario = constants.SSP_1_26;
              break;
            case 'Fund Of Funds':
              warmingScenario = constants.LOW_ENERGY_DEMAND_VALUE;
              break;
            case 'FLM':
              warmingScenario = constants.LOW_ENERGY_DEMAND_VALUE;
              break;
            default:
              break;
          }
          break;
        case 'ssp':
          switch (moduleName) {
            case 'Net-zero analysis':
              warmingScenario = constants.SSP_1_26;
              break;
            default:
              break;
          }
          break;
        case 'ngfs_3':
          switch (moduleName) {
            case 'Net-zero analysis':
              warmingScenario = constants.CURRENT_POLICIES_VALUE;
              break;
            default:
              break;
          }
          break;
        case 'IEA':
          warmingScenario = constants.BEYOND_2_DEGREES_VALUE;
          break;
        case 'NGFS':
          switch (moduleName) {
            case 'FLM':
              warmingScenario = constants.CURRENT_POLICIES;
              break;
            case 'Net-zero analysis':
              warmingScenario = constants.BELOW_2C;
              break;
            case 'Fund Of Funds':
              warmingScenario = constants.CURRENT_POLICIES;
              break;
            default:
              break;
          }
          break;
        default:
          warmingScenario = constants.LOW_ENERGY_DEMAND_VALUE;
          break;
      }
      dispatch(
        setFilterItemSuccess({
          key: 'warmingScenario',
          value: warmingScenario,
        }),
      );
    }
  };

  // eslint-disable-next-line consistent-return
  const setfootPrintMetrics = (key, value) => {
    if (key === 'approach') {
      let footprintMetric = '';
      switch (moduleName) {
        case 'FLM':
          switch (value) {
            case 'MarketShare':
              footprintMetric = constants.TOTAL_CARBON_EMISSION_VALUE;
              break;
            case 'RelativeAlignment':
              footprintMetric = constants.WEIGHTED_AVG_INTENSITY_REV_VALUE;
              break;
            default:
              break;
          }
          break;
        default:
          footprintMetric = constants.WEIGHTED_AVG_INTENSITY_REV_VALUE;
          break;
      }
      dispatch(
        setFilterItemSuccess({
          key: 'footprintMetric',
          value: footprintMetric,
        }),
      );
      return footprintMetric;
    }
  };

  const updateTags = async (optionName, value, selected, key) => {
    const index = filterData.findIndex((element) => element.key === key);
    const isMultiSelect = filterData[index].tabs.find(
      (t) => t.tab === tabValue,
    ).allow_multiple;
    let selectedValue = isMultiSelect
      ? Array.isArray(filterItem[key])
        ? [...filterItem[key]]
        : [filterItem[key]]
      : [filterItem[key]];
    if (isMultiSelect) {
      if (selected && selectedValue.length > 1) {
        filterData[index].current = filterData[index].current.filter(
          (op) => op !== optionName,
        );
        selectedValue = selectedValue.filter((val) => val !== value);
      } else if (!selectedValue.includes(value)) {
        filterData[index].current.push(optionName);
        selectedValue.push(value);
      }
    } else {
      filterData[index].current = [...filterData[index].current, optionName];
      selectedValue = value;

      if (accordionData != null) {
        if (key === 'emissionsType' || key === 'strategy') {
          if (value === 'pcaf') {
            const footprintMetric = 'TotalCarbEmis';

            dispatch(
              setFilterItemSuccess({
                key: 'footprintMetric',
                value: footprintMetric,
              }),
            );
            dispatch(
              setFilterItemSuccess({
                key: 'emissionsType',
                value: 'by_pcaf_score',
              }),
            );
          }
        }

        await updateParameterSettingsData(key, value);
      }
    }
    setFilterData([...filterData]);
    setEmissionData(key, value);
    setwarmingScenario(key, value);
    setfootPrintMetrics(key, value);
    dispatch(
      setFilterItemSuccess({
        key,
        value: selectedValue,
      }),
    );
  };

  return (
    <Accordion
      style={{
        position: 'relative',
        background: 'none',
        width: 250,
      }}
      disabled={loading || isDisabled}
      expanded={isExpanded}
      key={data.key}
      onChange={() => setIsExpanded((prev) => !prev)}
    >
      <AccordionSummary
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        data-testid={data.filterName}
        expandIcon={<ArrowDropDownIcon />}
      >
        <div style={{ color: colorSchema[currentTheme].inputLabel }}>
          {data.filterName}
        </div>
        <div
          data-testid="selected-value"
          style={{
            fontSize: 12,
            font: 'inherit',
            fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
            fontWeight: '500',
            color: colorSchema[currentTheme].selectedFilterText,
          }}
        >
          {data?.current ? data?.current.join(',') : ''}
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'block', marginTop: '5px' }}>
        {data.options.map((op) => (data.dependency.length
          ? data.dependency.map(
            (dep) => filterItem[dep] === op.dependantName
                  && op.options.map((options) => (
                    <FilterTags
                      key={options.value}
                      name={options.name}
                      selected={
                        data?.current
                          ? data?.current.includes(options.name)
                          : ''
                      }
                      pathName={pathname}
                      filterName={data.filterName}
                      action={() => updateTags(
                        options.name,
                        options.value,
                        data?.current.includes(options.name),
                        data.key,
                      )
                      }
                    />
                  )),
          )
          : op.allowedTabs
              && op.allowedTabs.includes(tabValue) && (
                <FilterTags
                  key={op.name}
                  role={op.name}
                  name={op.name}
                  selected={
                    data?.current ? data?.current.includes(op.name) : ''
                  }
                  pathName={pathname}
                  filterName={data.filterName}
                  action={() => updateTags(
                    op.name,
                    op.value,
                    data?.current ? data?.current.includes(op.name) : '',
                    data.key,
                  )
                  }
                />
          )))}
      </AccordionDetails>
    </Accordion>
  );
};

export default FilterDropdown;
