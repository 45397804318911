import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { LoadingHOC } from '../../../util/LoadingHOC';
import CarbonAttributionDisplay from './CarbonAttributionDisplay';
import useGetPayload from '../../../hooks/useGetPayload';
import CarbonAttributionModel from '../../../models/emissions/carbonAttribution';
import { useGetEmissionCarbonAttributionQuery } from '../../../redux/apiHooks';

const CarbonAttributionWithLoading = LoadingHOC(CarbonAttributionDisplay);

const CarbonAttribution = () => {
  const { payload } = useGetPayload(CarbonAttributionModel);
  const {
    data: carbonAttribData,
    isFetching,
    error,
  } = useGetEmissionCarbonAttributionQuery(payload);

  const [chartData, setChartData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [chartLabel, setChartLabel] = useState('');

  const getChartData = () => {
    let carbonChartData = [];
    let carbonCategories = [];
    let carbonChartLabel = '';

    if (!isEmpty(carbonAttribData.data)) {
      carbonChartLabel = carbonAttribData.metadata.label;

      carbonChartData = Object.keys(carbonAttribData.data).map((name) => {
        let values = [];
        values = Object.values(carbonAttribData.data[name]);

        carbonCategories = Object.keys(carbonAttribData.data[name]);
        return {
          name: name.replaceAll('_', ' '),
          data: values,
        };
      });
      setChartLabel(carbonChartLabel);
      setChartData(carbonChartData);
      setCategories(carbonCategories);
    }
  };

  useEffect(() => {
    if (carbonAttribData) getChartData();
  }, [carbonAttribData]);

  return (
    <CarbonAttributionWithLoading
      error={error}
      categories={categories}
      data={chartData}
      yAxisTitle={chartLabel}
      loading={isFetching}
    />
  );
};
export default CarbonAttribution;
