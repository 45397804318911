/* eslint-disable import/no-cycle */
import apiSlice, { getQuery } from '../apiSlice';

export const strandedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStrandedFossielFuelFootprint: builder.query({
      query: getQuery.post('stranded_assets/fossil_fuel_footprint'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getStrandedCoalPowerAnalysis: builder.query({
      query: getQuery.post('stranded_assets/coal_power_analysis'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
  }),
});

export const {
  useGetStrandedCoalPowerAnalysisQuery,
  useGetStrandedFossielFuelFootprintQuery,
  useLazyGetStrandedCoalPowerAnalysisQuery,
  useLazyGetStrandedFossielFuelFootprintQuery,
} = strandedApiSlice;
