/* eslint-disable react/prop-types */

import React from 'react';
import {
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@material-ui/core';
import HeatmapChart from '../../../components/Charts/HeatmapChart';

const Scope3HeatmapDisplay = (props) => (
  <React.Fragment>
    <HeatmapChart
      yAxisCategories={props.yAxisCategories}
      data={props.data}
      title="Supply and Value Chain Scope 3 Materiality"
      xAxisCategories={props.xAxisCategories}
      legendHeight={540}
    />
    <Link onClick={props.onDialogHandler}>
      Explore the scope 3 categories classification
    </Link>
    <div style={{ font: 'inherit' }}>
      This module provides a granular breakdown of carbon risk exposure within
      sectoral supply and value chains. This can be reviewed from the sectoral
      and portfolio perspectives Sector Analysis: Each Sector is scaled by the
      maximum Scope 3 or Scope 1+2 category by carbon intensity. Portfolio
      Analysis: All Scope 3 and Scope 1+2 carbon intensity figures are scaled by
      the maximum category for the portfolio as a whole.
    </div>

    <Dialog open={props.dialogOpen} keepMounted fullWidth={true}>
      <DialogTitle>Category Classification</DialogTitle>
      <DialogContent>{props.dialogContent}</DialogContent>
      <DialogActions>
        <Button onClick={props.onDialogHandler}>Cancel</Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
);

export default Scope3HeatmapDisplay;
