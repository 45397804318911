import { get } from 'lodash';

// How to use : Array.sort(sortArrayByKey('fieldName'))
export default function sortArrayByKey(selector) {
  return (a, b) => {
    const A = get(a, selector).toUpperCase();
    const B = get(b, selector).toUpperCase();
    if (A < B) return -1;
    if (A > B) return 1;
    return 0;
  };
}
