import React from 'react';
import PropTypes from 'prop-types';
import BaseChart from './BaseChart';
import ChartProps from './ChartProps';

function LineChart({
  xAxisType,
  emissionYear,
  pointStart,
  markerEnabled = false,
  plotLineWidth,
  ...props
}) {
  const options = {
    xAxis: {
      type: xAxisType,
      plotLines: emissionYear
        ? [
          {
            color: '#FF0000',
            width: 1,
            value: Date.UTC(emissionYear, '01', '01'),
          },
        ]
        : null,
    },
    plotOptions: {
      series: {
        pointStart: pointStart || 0,
        label: { connectorAllowed: false },
        marker: { enabled: markerEnabled },
        // #TO-DO: Check if this is same as point width
        lineWidth: plotLineWidth || 1,
      },
    },
    title: {
      y: 5,
    },
  };
  return (
    <BaseChart
      {...props}
      chartType="spline"
      height={props.height || 500}
      yAxisGridlineShow={true}
      legendType="horizontal"
      options={options}
    />
  );
}
LineChart.propTypes = {
  ...ChartProps,
  emissionYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xAxisType: PropTypes.string,
  pointStart: PropTypes.number,
  markerEnabled: PropTypes.bool,
  plotLineWidth: PropTypes.number,
};
export default LineChart;
