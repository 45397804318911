import apiSlice, { getQuery, RTK_TAGS } from '../apiSlice';

export const portfolioBuilderApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPortBuilderPortConstruction: builder.query({
      query: getQuery.post('portfolio_construction/filter_porfolio'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getPortBuilderUploadFromJson: builder.mutation({
      query: getQuery.post('upload_from_json'),
      transformErrorResponse: ({ data }) => data,
      invalidatesTags: [RTK_TAGS.PORTFOLIO_LIST],
    }),
  }),
});

export const {
  useGetPortBuilderPortConstructionQuery,
  useLazyGetPortBuilderPortConstructionQuery,
  useGetPortBuilderUploadFromJsonMutation,
} = portfolioBuilderApiSlice;
