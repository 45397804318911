import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSelector } from 'react-redux';

import useCurrentTheme from '../../hooks/useCurrentTheme';
import ChartProps from './ChartProps';

function combineObjects(o1, o2) {
  if (!o1) {
    return o2;
  }
  if (!o2) {
    return o1;
  }

  const combinedKeys = [...Object.keys(o1), ...Object.keys(o2)];
  const combinedObject = {};
  combinedKeys.forEach((key) => {
    if (o2[key]) {
      if (o1[key] && typeof o2[key] === 'object' && !Array.isArray(o2[key])) {
        combinedObject[key] = combineObjects(o1[key], o2[key], key);
      } else {
        combinedObject[key] = o2[key];
      }
    } else {
      combinedObject[key] = o1[key];
    }
  });
  return combinedObject;
}

function BaseChart({
  Highcharts: propsHighcharts,

  theme,
  isExportable = true,

  width,
  height,
  chartType,
  fontSize,

  data,
  categories,

  title: propsTitle,
  subtitle: propsSubtitle,

  xAxisTitle,
  xAxisCategories,
  xAxisGridlineShow = false,
  xAxisLabelsEnabled = true,

  yAxisTitle,
  yAxisCategories,
  yAxisGridlineShow = false,
  yAxisLabelsEnabled = true,

  showLegend = true,
  legendType = 'vertical',
  showDataLabels = false,

  options,
  customOptions,
}) {
  const trial = useSelector((state) => state.auth.userInfo.trial);
  const { usedTheme, usedStyles: styles } = useCurrentTheme(theme);
  const fontFamily = 'Roboto, Helvetica, Arial, sans-serif';
  const textStyle = { color: styles.chartText, fontSize, fontFamily };

  const chart = {
    backgroundColor: styles.mainBackground,
    width: width || null,
    height: height || null,
  };
  if (chartType) {
    chart.type = chartType;
  }

  let title = {
    x: 5,
    y: 25,
    style: { fontFamily },
  };
  if (typeof propsTitle === 'object') {
    title = { ...title, ...propsTitle };
  } else {
    title.text = propsTitle;
    title.align = 'left';
  }

  const subtitle = {};
  if (subtitle) {
    if (typeof propsSubtitle === 'object') {
      subtitle.text = propsSubtitle.text;
      subtitle.align = propsSubtitle.align;
    } else {
      subtitle.text = propsSubtitle;
      subtitle.align = 'left';
    }
  } else {
    subtitle.text = null;
    subtitle.align = 'left';
  }

  const xAxis = {
    categories: xAxisCategories || categories,
    title: {
      text: xAxisTitle,
      style: textStyle,
    },
    labels: {
      enabled: xAxisLabelsEnabled,
      style: textStyle,
    },
  };
  if (xAxisGridlineShow === true) {
    xAxis.gridLineWidth = 1;
    xAxis.gridLineColor = usedTheme === 'dark' ? '#403e38' : '#dddddd';
  }

  const yAxis = {
    title: {
      text: yAxisTitle,
      align: 'high',
      style: textStyle,
    },
    labels: {
      enabled: yAxisLabelsEnabled,
      style: textStyle,
    },
  };
  if (yAxisCategories) {
    yAxis.categories = yAxisCategories;
  }

  if (yAxisGridlineShow === true) {
    yAxis.gridLineWidth = 1;
    yAxis.gridLineColor = usedTheme === 'dark' ? '#403e38' : '#dddddd';
  } else {
    yAxis.gridLineWidth = 0;
  }

  let legend = {
    enabled: showLegend,
    itemStyle: textStyle,
  };

  if (fontSize) {
    legend.itemStyle.font = `6pt ${fontFamily}`;
  }

  if (legendType === 'vertical') {
    legend = {
      ...legend,
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      y: 20,
    };
  }

  const series = data && data.length > 0
    ? data.map((dt, i) => ({
      ...dt,
      color: styles.chartColors[i],
      dataLabels: { enabled: showDataLabels },
    }))
    : data;
  const exporting = {
    enabled: isExportable && !trial,
    buttons: {
      contextButton: {
        menuItems: [
          'printChart',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'downloadSVG',
          'separator',
          'downloadCSV',
          'downloadXLS',
          'openInCloud',
        ],
      },
    },
  };

  const credits = { enabled: false };

  const generatedOptions = {
    chart,
    title,
    subtitle,
    xAxis,
    yAxis,
    legend,
    series,
    exporting,
    credits,
  };
  const reducedObject = [generatedOptions, options, customOptions].reduce(
    (acc, value) => {
      if (!acc) {
        return acc;
      }
      return combineObjects(acc, value);
    },
  );
  return (
    <HighchartsReact
      highcharts={propsHighcharts || Highcharts}
      options={reducedObject}
    />
  );
}

BaseChart.propTypes = {
  ...ChartProps,
  chartType: PropTypes.string,
  Highcharts: PropTypes.any,
  customOptions: PropTypes.object,
};

// export const ChartProps = {
//   Highcharts: PropTypes.any,
//   theme: PropTypes.string,
//   isExportable: PropTypes.bool,

//   width: PropTypes.number,
//   height: PropTypes.number,
//   chartType: PropTypes.string,
//   fontSize: PropTypes.number,

//   data: PropTypes.oneOfType([
//     PropTypes.array,
//     PropTypes.object,
//   ]),
//   categories: PropTypes.array,

//   // Declare shape of and subtitle
//   title: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.shape({
//       text: PropTypes.string,
//       align: PropTypes.string,
//     }),
//   ]),
//   subtitle: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.shape({
//       text: PropTypes.string,
//       align: PropTypes.string,
//     }),
//   ]),

//   xAxisTitle: PropTypes.string,
//   xAxisCategories: PropTypes.array,
//   xAxisGridlineShow: PropTypes.bool,
//   xAxisLabelsEnabled: PropTypes.bool,

//   yAxisTitle: PropTypes.string,
//   yAxisCategories: PropTypes.array,
//   yAxisGridlineShow: PropTypes.bool,
//   yAxisLabelsEnabled: PropTypes.bool,

//   showLegend: PropTypes.bool,
//   legendType: PropTypes.string,

//   options: PropTypes.object,
//   customOptions: PropTypes.object,
// };
export default BaseChart;
