import BaseRequest from '../baseRequest';

export default class SectorSubplot extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem } = props;
    const {
      emission,
      // warmingScenario,
      targetScenario,
      // emissionsType,
      includeHistorical,
      approach,
      alignmentYear,
      assetClass,
      sector,
      // chartType,
      includeTargets,
      group_by,
      marketValue,
    } = filterItem;
    this.approach = approach;
    this.asset_type = assetClass;
    this.inference = 'Avg';
    this.footprint = 'TotalCarbEmis';
    this.scope = emission;
    this.market_value = marketValue;
    this.sector = sector;
    // this.scenario = warmingScenario;
    this.scenario_type = targetScenario;
    this.emission_type = 'net';
    this.alignment_year = alignmentYear;
    this.include_historical = includeHistorical;
    // this.chart_type = chartType;
    this.include_targets = includeTargets;
    this.group_by = group_by;
  }
}
