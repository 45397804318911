import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { LoadingHOC } from '../../../util/LoadingHOC';
import SectoralTempScoreDisplay from './SectoralTempScoreDisplay';
import useGetPayload from '../../../hooks/useGetPayload';
import Model from '../../../models/tempScore/sectoralScore';
import { useGetTempMetricSectoralTempScoreQuery } from '../../../redux/apiHooks';

const SectoralTempScoreDisplayWithLoading = LoadingHOC(
  SectoralTempScoreDisplay,
);

const SectoralTempScore = () => {
  const { auth, payload } = useGetPayload(Model);
  const {
    data: sectoralTempScoreData,
    isFetching,
    error,
  } = useGetTempMetricSectoralTempScoreQuery(payload);

  const [chartData, setChartData] = useState([]);
  const [chartCategories, setChartCategories] = useState([]);
  const { showBenchmark } = auth;
  const filterItem = useSelector((state) => state.filters);
  const { scoreType, emission } = filterItem;

  const getScoreType = () => {
    if (scoreType === 'shortTerm') {
      return 'short';
    }
    if (scoreType === 'longTerm') {
      return 'long';
    }
    return 'mid';
  };

  const getChartData = () => {
    const cCategories = [];
    const portValues = [];
    const benchValues = [];
    let cData = [];

    if (!isEmpty(sectoralTempScoreData)) {
      const score = getScoreType();
      const res = sectoralTempScoreData[score][emission];
      if (res && Object.keys(res).length > 0) {
        Object.keys(res).forEach((sector) => {
          const portValue = res[sector].portfolio_score
            ? res[sector].portfolio_score
            : 0;
          const benchValue = res[sector].benchmark_score
            ? res[sector].benchmark_score
            : 0;
          portValues.push(portValue);
          benchValues.push(benchValue);
          cCategories.push(sector);
        });
      }
    }
    cData = [
      {
        name: 'portfolio',
        data: portValues,
      },
    ];

    if (showBenchmark) {
      cData.push({
        name: 'benchmark',
        data: benchValues,
      });
    }

    setChartData(cData);
    setChartCategories(cCategories);
  };

  useEffect(() => {
    getChartData();
  }, [sectoralTempScoreData, scoreType, emission]);

  return (
    <React.Fragment>
      <SectoralTempScoreDisplayWithLoading
        loading={isFetching}
        error={error}
        chartCategories={chartCategories}
        chartData={chartData}
        chartKey="CONTRIBUTION_ANALYSIS"
        isLegend={false}
      />
    </React.Fragment>
  );
};

export default SectoralTempScore;
