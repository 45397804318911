import React from 'react';
import PropTypes from 'prop-types';

import ReportTable from '../../../../components/PdfReport/ReportTable';
import {
  getCumlativeEmissionsTable,
  getDivergenceModel,
  getTemperatureScore,
  getBinaryTargetMeasure,
} from '../../../../helpers/report';
import ReportStackedBar from '../../../../components/PdfReport/Charts/ReportStackedBar';
import ReportLineChart from '../../../../components/PdfReport/Charts/ReportLineChart';
import ReportStackedColumn from '../../../../components/PdfReport/Charts/ReportStackedColumn';

const cumulativeEmissionsColumns = [
  {
    name: ' ',
    selector: 'name',
    right: false,
    width: '40%',
  },
  { name: '2030', selector: 2030, right: true },
  { name: '2050', selector: 2050, right: true },
];
function NetZero({ theme, data }) {
  const { portfolio, benchmark, auth } = data;
  const {
    userInfo,
    currentUser: { client },
  } = auth;
  let binaryTargetMeasure = [];
  let alignment = { data: [], yAxisTitle: '' };

  let cumulativeEmissions = { data: [], yAxisTitle: '' };
  let cumulativeEmissionsTable = [];
  let temperatureScore = { data: [], yAxisTitle: '', categories: [] };

  if (portfolio && benchmark) {
    alignment = getDivergenceModel(
      portfolio.portfolio_alignment?.normalised_emissions,
    );
    cumulativeEmissions = getDivergenceModel(
      portfolio.portfolio_alignment?.cumulative_emissions,
    );
    cumulativeEmissionsTable = getCumlativeEmissionsTable(
      portfolio.portfolio_alignment?.cumulative_emissions,
    );
    binaryTargetMeasure = getBinaryTargetMeasure(portfolio?.coverage);
    temperatureScore = getTemperatureScore(
      portfolio.portfolio_alignment,
      benchmark.portfolio_alignment,
      theme,
    );
  }
  return (
    <div
      className="pdf_page"
      data-footer={`[3] Benchmark Divergence Models, refers to the “benchmark” climate transition pathway, as used by the TCFD framework. The TCFD “Benchmark” is the transition pathway or selected climate scenario\n(for purposes of this report, the TCFD benchmark is Net Zero 2050). This is different to the Benchmark Portfolio, as provided by ${client}.\nhttps://www.tcfdhub.org/wp-content/uploads/2021/10/PAT_Measuring_Portfolio_Alignment_Technical_Considerations-9.8.pdf`}
    >
      <h2>Net-Zero (Portfolio Alignment)</h2>
      <div className="span-2">
        <p>
          ICE’s Portfolio Alignment reporting solution (which is in line with
          the Task Force on Climate-Related Financial Disclosures (TCFD)
          recommendations) provides three broad categories of forward-looking
          portfolio alignment analytics:
        </p>
        <ul>
          <li>
            Binary target measurements: measures the alignment of a portfolio
            with a given climate outcome based on the percent of companies in{' '}
            {client} portfolio with declared net zero/Paris-alignment targets.
          </li>
          <li>
            Benchmark divergence models[3]: assesses portfolio alignment by
            constructing normative benchmarks (emissions pathways that describe
            what must be done to achieve a given warming target) from
            forward-looking climate scenarios and comparing constituent company
            emissions against them. They can then be aggregated at the sector or
            portfolio level.
          </li>
          <li>
            Implied Temperature Rise (ITR) scores: extends benchmark divergence
            models one step further, translating an assessment of alignment/
            misalignment with a benchmark into a measure of the consequences of
            that alignment in the form of an ITR score that describes the most
            likely global warming outcome if the global economy was to exhibit
            same level of ambition as the counterparty in question.
          </li>
        </ul>
      </div>
      <h3 className="span-2">Binary Target Measure</h3>
      <ReportStackedBar
        categories={['']}
        data={binaryTargetMeasure}
        max={100}
        barWidth={15}
        showLabels={false}
      />
      <div>
        <p>
          The Binary Target Measurement chart displays the percentage of
          companies in the portfolio that have or have not disclosed emission
          reduction targets (covering Scope 1, 2, and 3). For those companies
          that have disclosed emission reduction targets, this is broken down to
          include whether they are Science Based Targets initiative (SBTi)
          validated or non-SBTi validated targets.
        </p>
      </div>
      <div>
        <h3>Benchmark Divergence Model - Alignment</h3>
        <ReportLineChart
          data={alignment.data}
          yAxisTitle={alignment.yAxisTitle}
          emissionYear={userInfo?.year?.emissions - 1 || '2019'}
          xAxisType="datetime"
        />
        <div></div>
        <p>
          The Alignment chart shows: (i) the emissions trajectory of the
          portfolio’s financed emissions which is estimated based on constituent
          company targets and historical changes in the emissions intensities,
          and (ii) pathways that are aligned to the Network for Greening of the
          Financial System (NGFS) scenarios: Net Zero 2050, Delayed Transition
          and Current Policies. The Net Zero 2050 scenario is an orderly below
          1.5C scenario, while the Delayed Transition is a disorderly below 2C
          scenario, and the Current Policies scenario results in global mean
          temperature risk of 3.2C. These serve as a benchmark transition or
          baseline pathway, from which it can be established how closely the
          portfolio is aligned to different temperature outcomes.
        </p>
        <p>
          The alignment chart effectively highlights how closely aligned the
          portfolio’s emissions trajectory is to the selected scenarios until
          2050.
        </p>
      </div>
      <div>
        <h3>Benchmark Divergance Model - Cumulative Emissions</h3>
        <ReportLineChart
          data={cumulativeEmissions.data}
          yAxisTitle={cumulativeEmissions.yAxisTitle}
          emissionYear={userInfo?.year?.emissions - 1 || '2019'}
          xAxisType="datetime"
        />
        <div style={{ marginTop: 2, marginBottom: 15 }}>
          <ReportTable
            columns={cumulativeEmissionsColumns}
            data={cumulativeEmissionsTable}
          />
        </div>
        <div>
          <p>
            The Cumulative Emissions chart displays the cumulative emissions
            corresponding to the estimated emissions trajectory and scenario
            aligned pathways (NGFS - Net Zero 2050, Delayed Transition, and
            Current Policies). The cumulative emissions are subsequently used
            for calculating the implied temperature risk score as well.
          </p>
        </div>
      </div>
      <div></div>
      <div className="html2pdf__page-break"></div>
      <h3 className="span-2">Implied Temperature Rise Score</h3>
      <ReportStackedColumn
        data={temperatureScore.data}
        categories={temperatureScore.categories}
        yAxisTitle={temperatureScore.yAxisTitle}
      />
      <div>
        <p>
          The Implied Temperature Rise (ITR) Score chart is calculated by
          factoring in the cumulative emission pathways, associated temperatures
          derived from the NGFS scenario database and calculating the TCRE
          (Transient Climate Response to Cumulative Carbon Emissions) - the
          ratio of globally average surface temperature changes per unit of CO2
          emitted. For the calculation of the ITR score, the TCRE is
          portfolio-specific, based on the industries, financed by the
          portfolio.
        </p>
      </div>
    </div>
  );
}

NetZero.propTypes = {
  theme: PropTypes.string.isRequired,
  data: PropTypes.shape({
    portfolio: PropTypes.object,
    benchmark: PropTypes.object,
    tempScore: PropTypes.array,
    auth: PropTypes.object,
    filters: PropTypes.object,
  }),
};

export default NetZero;
