import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  formControl: {
    width: 300,
    margin: 10,
  },
}));

function OutlinedDropdown({
  label, value, onChange, options,
}) {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        value={value}
        onChange={onChange}
        style={{ fontSize: 14 }}
      >
        {options.map((opt) => (typeof opt === 'string' ? (
            <MenuItem key={opt} value={opt}>
              {opt}
            </MenuItem>
        ) : (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.label}
            </MenuItem>
        )))}
      </Select>
    </FormControl>
  );
}

OutlinedDropdown.propTypes = {
  label: PropTypes.string,
  value: PropTypes.value,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

export default OutlinedDropdown;
