import React from 'react';
import PropTypes from 'prop-types';
import HorizontalBar from '../../../components/Charts/HorizontalBar';

const SectoralTempScoreDisplay = ({ chartCategories, chartData }) => (
  <HorizontalBar
    categories={chartCategories}
    data={chartData}
    title="Temperature Scores per sector (℃)"
    yAxisTitle="Temperature Score"
  />
);

SectoralTempScoreDisplay.propTypes = {
  chartData: PropTypes.array.isRequired,
  chartCategories: PropTypes.array.isRequired,
};

export default SectoralTempScoreDisplay;
