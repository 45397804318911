import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(() => ({
  tooltipFont: {
    fontSize: 12,
  },
  iconStyle: {
    marginLeft: 8,
    fontSize: 18,
  },
  tooltipContainer: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
}));

const TitleTooltip = ({ title, tooltipText, style }) => {
  const classes = useStyles();
  return (
    <div className={classes.tooltipContainer}>
      <div style={style}>{title}</div>
      {tooltipText ? (
        <Tooltip
          title={<div className={classes.tooltipFont}>{tooltipText}</div>}
        >
          <InfoOutlinedIcon className={classes.iconStyle} />
        </Tooltip>
      ) : null}
    </div>
  );
};

TitleTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  style: PropTypes.object,
};

export default TitleTooltip;
