import React from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import TabPanel from '../../components/TabPanel';
import { scope3Tabs } from '../../util/tabs-config';
import Scope3Heatmap from './Scope3Heatmap/Scope3Heatmap';
import SectoralScope3Heatmap from './SectoralScope3Heatmap/SectoralScope3Heatmap';
import useTabValue from '../../hooks/useTabValueHook';

function Scope3Materiality() {
  const [tabValue, onTabChange] = useTabValue();

  return (
    <>
      <Paper position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={onTabChange}
          indicatorColor="secondary"
          fullWidth="true"
          data-testid="Scope3MaterialityTabs"
        >
          {scope3Tabs
            && scope3Tabs.map((e, i) => (
              <Tab
                key={i}
                label={e}
                style={{ fontSize: 11 }}
                data-testid={`tab-${e}`}
              />
            ))}
        </Tabs>
      </Paper>
      <TabPanel value={tabValue} index={0}>
        <Scope3Heatmap />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <SectoralScope3Heatmap />
      </TabPanel>
    </>
  );
}
export default Scope3Materiality;
