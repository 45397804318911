/* eslint-disable import/prefer-default-export */
import cat1 from '../../../assets/report/ice-cat-1.jpg';
import cat2 from '../../../assets/report/ice-cat-2.jpg';
import cat3 from '../../../assets/report/ice-cat-3.png';
import cat4 from '../../../assets/report/ice-cat-4.png';
import cat5 from '../../../assets/report/ice-cat-5.png';

const disclosureCategoriesMap = new Map();
disclosureCategoriesMap.set(
  'Public, complete Scope 1 and 2 data, third-party assurance.',
  {
    no: 1,
    logo: cat1,
    title: 'Category 1: Complete with Accepted Assurance',
    description:
      'Scope 1 and 2 data covers at least 95% of the company’s global emissions within an appropriately chosen reporting boundary. Assurance is accepted if the independent verification statement verifies the company’s GHG emissions data to a recognised standard and level and the assurance covers 95% of the company’s global emissions',
  },
);
disclosureCategoriesMap.set(
  'Public, complete Scope 1 and 2 data, no third-party assurance.',
  {
    no: 2,
    logo: cat2,
    title: 'Category 2: Complete without Accepted Assurance',
    description:
      'Scope 1 and 2 data covers at least 95% of the company’s global emissions within an appropriately chosen reporting boundary without an assurance statement provided or accepted.',
  },
);
disclosureCategoriesMap.set(
  'Incomplete Scope 1 and 2 data, no third-party assurance.',
  {
    no: 3,
    logo: cat3,
    title: 'Category 3: Incomplete',
    description:
      'Scope 1 and 2 data covers less than 95% of a company’s global emissions. Data reported as an intensity metric, rather than an absolute figure is considered incomplete given this is not permitted under the GHG Protocol Corporate Standard.',
  },
);
disclosureCategoriesMap.set('No public data.', {
  no: 4,
  logo: cat4,
  title: 'Category 4: No Public Data',
  description:
    'When a company does not disclose any GHG emissions data publicly whatsoever for the reporting period.',
});

disclosureCategoriesMap.set('Not Directly Analyzed.', {
  no: 5,
  logo: cat5,
  title: 'Category 5: Not Directly Analyzed',
  description:
    'When a company may have publicly disclosed GHG emissions data for the reporting period but has not been included as part of ICE’s directly analyzed Emissions universe.',
});

export { disclosureCategoriesMap };
