import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import DataTable from '../../../components/Table/DataTable';
import { summaryCells } from '../../../util/TableHeadConfig';
import StackedBar from '../../../components/Charts/StackedBar';

const SummaryDisplay = ({ chartData, tableData }) => (
  <React.Fragment>
    <Grid container>
      <Grid item xs={12}>
        <StackedBar
          title="Funds' Weights"
          yAxisTitle="Weight (%)"
          yAxis={{ max: 100 }}
          data={chartData}
          categories={['']}
        />
      </Grid>

      <Grid item xs={12} style={{ marginTop: 10 }}>
        <DataTable
          data={tableData}
          columns={summaryCells}
          tableHeading="COMPANY_ANALYSIS"
        />
      </Grid>
    </Grid>
  </React.Fragment>
);

SummaryDisplay.propTypes = {
  chartData: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
};

export default SummaryDisplay;
