// import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import { NotificationManager } from 'react-notifications';
// // eslint-disable-next-line import/no-cycle
// import { CACHE_TIME, getQuery } from '../apiSlice';
// import UserSessionHandler from '../../handler';
// import { logoutUser } from '../../services/auth';

// const baseQuery = fetchBaseQuery({
//   baseUrl: process.env.REACT_APP_TEMP_API_URL,
//   prepareHeaders: (headers) => {
//     const token = UserSessionHandler.getAccessToken();
//     if (token) {
//       headers.set('Authorization', `Bearer ${token}`);
//     }
//     return headers;
//   },
// });

// const baseQueryWithReauth = async (args, api, extraOptions) => {
//   const result = await baseQuery(args, api, extraOptions);
//   if (result?.error?.status === 401 || result?.error?.status === 403) {
//     await api.dispatch(logoutUser());
//     NotificationManager.error(
//       'Missing Authentication Header. Pls re-login again',
//     );
//   }
//   return result;
// };

// const tempMetricApiSlice = createApi({
//   baseQuery: baseQueryWithReauth,
//   endpoints: (builder) => ({
//     getTempMetricArrayTempScores: builder.query({
//       query: getQuery.post('array_temperature_scores/'),
//       transformErrorResponse: ({ data }) => data?.message,
//       extraOptions: {
//         baseUrl: 'https://tm-qa.urgentem.net',
//       },
//     }),
//   }),
//   keepUnusedDataFor: CACHE_TIME,
// });

// export default tempMetricApiSlice;

// eslint-disable-next-line import/no-cycle
import apiSlice, { getQuery } from '../apiSlice';
import UserSessionHandler from '../../handler';

const embedCookie = UserSessionHandler.getCookie();
const BASE_API_URL = embedCookie && process.env.REACT_APP_SF_TEMP_API_URL
  ? process.env.REACT_APP_SF_TEMP_API_URL
  : process.env.REACT_APP_TEMP_API_URL;

const extraOptions = {
  baseUrl: BASE_API_URL,
};

export const tempMetricApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTempMetricArrayTempScores: builder.query({
      query: getQuery.post('array_temperature_scores/'),
      transformErrorResponse: ({ data }) => data?.message,
      extraOptions,
    }),
    getTempMetricPortfolioScore: builder.query({
      query: getQuery.post('portfolio_score/'),
      transformErrorResponse: ({ data }) => data?.message,
      extraOptions,
    }),
    getTempMetricSectoralTempScore: builder.query({
      query: getQuery.post('sectoral_temp_score'),
      transformErrorResponse: ({ data }) => data?.message,
      extraOptions,
    }),
    getTempMetricCompaniesScore: builder.query({
      query: getQuery.post('companies_score/'),
      transformErrorResponse: ({ data }) => data?.message,
      extraOptions,
    }),
    getTempMetricAttribution: builder.query({
      query: getQuery.post('attribution/'),
      transformErrorResponse: ({ data }) => data?.message,
      extraOptions,
    }),
    getTempMetricHeatmap: builder.query({
      query: getQuery.post('heatmap/'),
      transformErrorResponse: ({ data }) => data?.message,
      extraOptions,
    }),
    getTempMetricContribution: builder.query({
      query: getQuery.post('contribution/'),
      transformErrorResponse: ({ data }) => data?.message,
      extraOptions,
    }),
  }),
});

export const {
  useGetTempMetricArrayTempScoresQuery,
  useLazyGetTempMetricArrayTempScoresQuery,
  useGetTempMetricAttributionQuery,
  useGetTempMetricCompaniesScoreQuery,
  useGetTempMetricContributionQuery,
  useGetTempMetricHeatmapQuery,
  useGetTempMetricPortfolioScoreQuery,
  useGetTempMetricSectoralTempScoreQuery,
  useLazyGetTempMetricAttributionQuery,
  useLazyGetTempMetricCompaniesScoreQuery,
  useLazyGetTempMetricContributionQuery,
  useLazyGetTempMetricHeatmapQuery,
  useLazyGetTempMetricPortfolioScoreQuery,
  useLazyGetTempMetricSectoralTempScoreQuery,
} = tempMetricApiSlice;
