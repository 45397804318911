/* eslint-disable react/prop-types */

import React from 'react';
import ColumnChart from '../../../components/Charts/ColumnChart';

const CarbonAttributionDisplay = (props) => (
  <React.Fragment>
    <ColumnChart
      categories={props.categories}
      data={props.data}
      title="Carbon Attribution"
      yAxisTitle={props.yAxisTitle}
      xAxisTitle={'Sector'}
    />
    <div
      style={{
        fontSize: 14,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      }}
    >
      This module allows investors to interrogate the role of sector allocation
      and stock selection that lead to differences in the carbon intensity of
      the portfolio and chosen benchmark. In this graph, you can isolate the
      contributions to the differences in footprint between the portfolio and
      benchmark. Carbon attribution illustrates whether changes in the carbon
      intensity of the portfolio compared to the benchmark are changed by
      selecting lower or higher carbon stocks or sectors. This technique is
      employed to help identify the most effective combination of stock and
      sectoral allocations to reduce the carbon risk of the portfolio.
    </div>
  </React.Fragment>
);

export default CarbonAttributionDisplay;
