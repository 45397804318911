/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import ReportTable from '../../../../components/PdfReport/ReportTable';
import {
  getCarbonDisclosureQuality,
  getComparativeSc12Emisssions,
  getComparativeSc12EmisssionsTable,
  getSc12HistoricalEmissions,
  getSc12HistoricalEmissionsTable,
  getSc12Pcaf,
  getSc12PcafTable,
  getShareofSc12Emissions,
} from '../../../../helpers/report';
import ReportHorizontalBar from '../../../../components/PdfReport/Charts/ReportHorizontalBar';
import ReportDonutChart from '../../../../components/PdfReport/Charts/ReportDonutChart';
import ReportStackedBar from '../../../../components/PdfReport/Charts/ReportStackedBar';
import ReportLineChart from '../../../../components/PdfReport/Charts/ReportLineChart';

const comparativeSc12EmisssionsColumns = [
  {
    name: ' ',
    selector: 'name',
    right: false,
    bold: true,
  },
  { name: 'Scope 1+2', selector: 'sc12', right: true },
  { name: 'Scope 1+2+3', selector: 'sc123', right: true },
];

const pcafColumns = [
  {
    name: ' ',
    selector: 'name',
    right: false,
    width: '11%',
    bold: true,
  },
  { name: 'Portfolio Scope 1 + 2', selector: 'psc12', right: true },
  {
    name: 'Industry Benchmark Scope 1 + 2',
    selector: 'bsc12',
    right: true,
  },
];

const sc12HistoricalEmissionsColumns = [
  {
    name: 'Year',
    selector: 'year',
    right: false,
    bold: true,
  },
  {
    name: 'Corporate',
    selector: 'corporate',
    right: true,
  },
  {
    name: 'Industry Benchmark',
    selector: 'benchmark',
    right: true,
  },
];

function EmissionsFootprint({ data }) {
  const { singleIsin, benchmark } = data;

  let comparativeSc12Emisssions = { data: [], yAxisTitle: '', categories: [] };
  let comparativeSc12EmisssionsTable = [];
  let shareofSc12Emissions = { data: [] };
  let carbonDisclosureQuality = { data: [], yAxisTitle: '', categories: [] };
  let pcaf = { data: [], yAxisTitle: '', categories: [] };
  let pcafTable = [];
  let sc12HistoricalEmissions = { data: [], yAxisTitle: '', xAxisTitle: '' };
  let sc12HistoricalEmissionsTable = [];

  const corporate = singleIsin?.name || '';
  const industryBenchmark = singleIsin?.sub_industry_group || '';

  if (singleIsin && benchmark) {
    comparativeSc12Emisssions = getComparativeSc12Emisssions(
      singleIsin.portfolio_emisisons,
      benchmark.portfolio_emisisons,
    );

    comparativeSc12EmisssionsTable = getComparativeSc12EmisssionsTable(
      singleIsin.portfolio_emisisons,
      benchmark.portfolio_emisisons,
    );

    shareofSc12Emissions = getShareofSc12Emissions(
      singleIsin.emission_share,
      'ICE light',
    );

    carbonDisclosureQuality = getCarbonDisclosureQuality(
      singleIsin.disclosure,
      benchmark.disclosure,
    );

    pcaf = getSc12Pcaf(singleIsin.pcaf, benchmark.pcaf, false);

    pcafTable = getSc12PcafTable(pcaf.data);

    sc12HistoricalEmissions = getSc12HistoricalEmissions(
      singleIsin.portfolio_emisisons,
      benchmark.portfolio_emisisons,
    );

    sc12HistoricalEmissionsTable = getSc12HistoricalEmissionsTable(
      singleIsin.portfolio_emisisons,
      benchmark.portfolio_emisisons,
    );
  }
  return (
    <>
      <div
        className="pdf_page"
        data-footer="[3] For more details on PCAF Methodology, please refer to Partnership for Carbon Accounting Financials (PCAF) (2022): Financed Emissions. The Global GHG Accounting"
      >
        <h2>Emissions Footprint (Scope 1+2)</h2>
        <div className="span-2">
          <p>
            Emissions footprint analysis enables corporates to use the latest
            available reported and estimated data to quantify their greenhouse
            gas emissions (GHG), presenting them as tons of carbon dioxide
            equivalents (tCO2e) apportioned to their exposure. To better compare
            companies or portfolios to each other, ICE normalizes emissions by
            an indicator of size (such as annual revenues or enterprise value
            including cash) to give a measure of carbon intensity.
          </p>
          <p>
            The metrics below are intended to help understand the climate
            related risks and opportunities in a portfolio, and as such may be
            used for internal or external reporting, as well as for risk
            management and corporate engagement.
          </p>
        </div>
        <div>
          <h3>Comparative Scope 1+2 Emissions (Intensity Footprint)</h3>
          <ReportHorizontalBar
            categories={comparativeSc12Emisssions.categories}
            data={comparativeSc12Emisssions.data}
            yAxisTitle={comparativeSc12Emisssions.yAxisTitle}
            height={240}
          />
          <div className="report-text">
            <p>
              The Comparative Scope 1+2 Emissions (Intensity Footprint) chart
              displays the carbon intensity (or footprint) of {corporate},
              comparative to its {industryBenchmark} peers, in terms of Scope
              1+2.
            </p>
            <p>
              The selected footprint metric is Weighted Average Intensity (WACI)
              (Revenue)
            </p>
          </div>
          <ReportTable
            columns={comparativeSc12EmisssionsColumns}
            data={comparativeSc12EmisssionsTable}
          />
        </div>
        <div>
          <h3>Share of Scope 1+2 Emissions (within Scope 1+2+3)</h3>
          <ReportDonutChart
            data={shareofSc12Emissions.data}
            height={240}
            width={350}
            size={140}
          />
          <div>
            <p>
              The Share of Scope 1+2 Emissions (within Scope 1+2+3) chart shows
              that Scope 1+2 emissions account for{' '}
              {shareofSc12Emissions.data[0]?.y}% of {corporate}'s total
              emissions, and Scope 3 emissions account for{' '}
              {shareofSc12Emissions.data[1]?.y}% of {corporate}'s total
              emissions. This includes both reported and modelled figures.
            </p>
            {/* <p>The selected footprint metric is WACI (Revenue)</p> */}
          </div>
        </div>
        <div>
          <h3>Comparative Carbon Scope 1+2 Disclosure Quality</h3>
          <ReportStackedBar
            categories={carbonDisclosureQuality.categories}
            data={carbonDisclosureQuality.data}
            yAxisTitle={carbonDisclosureQuality.yAxisTitle}
            max={100}
          />
          <div>
            <p>
              The Comparative Carbon Scope 1+2 Disclosure Quality chart displays
              the number of Scope 1+2 carbon emission disclosures that are
              included within {corporate} comparative to its {industryBenchmark}{' '}
              peers.
            </p>
            <p>
              Disclosure is categorised by ICE, from Category 1 to 5, as defined
              on page 2
            </p>
          </div>
        </div>
        <div>
          <h3>Comparative Scope 1+2 PCAF Score</h3>
          <ReportStackedBar
            categories={pcaf.categories}
            data={pcaf.data}
            yAxisTitle={pcaf.yAxisTitle}
          />
          <div className="report-text">
            <p>
              The Comparative Scope 1+2 PCAF Score chart, displays the carbon
              intensity or footprint of {corporate} comparative to its{' '}
              {industryBenchmark} peers, categorised by the Portfolio Footprint
              for Carbon Accounting Financials (PCAF)<sup>3</sup> disclosure
              quality scores. The PCAF disclosure quality scores range from 1 to
              5, as defined above.
            </p>
          </div>
          <ReportTable columns={pcafColumns} data={pcafTable} />
        </div>
      </div>
      <div className="pdf_page">
        <h2>Emissions Footprint (Scope 1+2)</h2>
        <h3 className="span-2">Comparative Scope 1+2 Historical Emissions</h3>
        <ReportLineChart
          data={sc12HistoricalEmissions.data}
          yAxisTitle={sc12HistoricalEmissions.yAxisTitle}
          xAxisTitle={sc12HistoricalEmissions.xAxisTitle}
          xAxisType="datetime"
        />
        <div>
          <div>
            <p>
              The Comparative Scope 1+2 Historical Emissions chart displays the
              historical carbon intensity (or footprint) of {corporate},
              comparative to its {industryBenchmark} peers, in terms of Scope
              1+2.
            </p>
            <p>The selected footprint metric is WACI (Revenue).</p>
          </div>
          <ReportTable
            columns={sc12HistoricalEmissionsColumns}
            data={sc12HistoricalEmissionsTable}
          />
        </div>
      </div>
    </>
  );
}

export default EmissionsFootprint;
