import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useGetTheme from '../../../hooks/useGetTheme';
import { setMultiplePortfolio } from '../../../redux/reducers/authReducer';
import { clearAllPortBuilderFields } from '../../../redux/reducers/portfolioBuilderReducer';
import { getPortfolioBuilderData } from '../../../services/auth';
import MultiSelectDropdown from '../../CustomFormComponent/MultiSelectDropdown';

const MultiSelectPortfolio = () => {
  const dispatch = useDispatch();
  const {
    portfolioList: portfolios,
    selectedPortfolios,
    loading,
  } = useSelector((state) => state.auth);
  const { pathname } = window.location;
  const { currentTheme } = useGetTheme();

  const onMultiPortfolioChange = (portfolioList) => dispatch(setMultiplePortfolio(portfolioList));

  const onClearClick = () => {
    dispatch(setMultiplePortfolio([]));
    if (pathname === '/portfolio-screener') {
      dispatch(getPortfolioBuilderData());
    }
  };

  const onClose = () => {
    if (pathname === '/portfolio-screener') {
      dispatch(clearAllPortBuilderFields());
    }
  };

  return (
    <MultiSelectDropdown
      options={portfolios && portfolios.length > 0 ? portfolios : []}
      isEditable={Boolean(selectedPortfolios.length)}
      currentValue={selectedPortfolios}
      onClearClick={onClearClick}
      handleChange={onMultiPortfolioChange}
      labelText="Multi Select Portfolios"
      onClose={onClose}
      disabled={loading}
      currentTheme={currentTheme}
    />
  );
};

export default MultiSelectPortfolio;
