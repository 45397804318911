/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, makeStyles } from '@material-ui/core';
import config from './config';
import setDependancyFilters from './functions';
import {
  getModuleNameFromPath,
  getShowFiltersPaths,
} from '../../layouts/Route';
import FilterDatePicker from './components/FilterDatePicker';
import FilterOnLoadButton from './components/FilterOnLoadButton';
import FilterDropdown from './components/FilterDropdown';
import { getFootprintParameters } from '../../util/pcafHelpers';
import FilterPcafAccordion from './components/FilterPcafAccordion';
import SelectPortfolio from './components/SelectPortfolio';
import SelectBenchmark from './components/SelectBenchmark';
import SelectCountry from './components/SelectCountry';
import SelectFundsPortfolio from './components/SelectFundsPortfolio';
import BenchmarkToggle from './components/BenchmarkToggle';
import MultiSelectPortfolio from './components/MultiSelectPortfolio';
import MultiAddFilters from '../CustomFormComponent/MultiAddFilters';
import { setModule, setTabValue } from '../../redux/reducers/authReducer';
import { setFilterItemSuccess } from '../../redux/reducers/filterReducer';

/*
1.filter out allowed tabs filters
2. loop over allowed filters
3.
*/
// TODO: cover with test cases
// TODO: split into different components, eg renderDatePickerComp, pcaf accordions etc
// TODO: rethink structure of the config.js
// TODO: think of the way to generalize accordion, scenario logic etc.

const getFilterData = async (filterData, filterItem, tabValue) => {
  const allowedFilterData = filterData.filter((filters) => filters.tabs.find((c) => c.tab === tabValue));
  const newFilters = [];
  allowedFilterData.some((data) => {
    const isFilterValueAllowed = [];
    const allowedTabsFilters = data.options.filter((options) => (!data.dependency.length
      ? options.allowedTabs.includes(tabValue)
      : options));
    const tabData = data.tabs.find((t) => t.tab === tabValue); // Allow_multiple, default, disabled, tab
    allowedTabsFilters.some((list) => {
      if (data.dependency.length) {
        return data.dependency.some((dep) => {
          const dependentObj = allowedTabsFilters.find(
            (a) => a.dependantName === filterItem[dep],
          );
          if (dependentObj) {
            if (filterItem[dep] === list.dependantName) {
              setDependancyFilters(
                filterItem[data.key],
                data.key,
                newFilters,
                list,
                isFilterValueAllowed,
                tabData,
              );
            }
          } else {
            setDependancyFilters(
              filterItem,
              data.key,
              newFilters,
              list,
              isFilterValueAllowed,
              tabData,
            );
          }
        });
      }
      if (tabData && tabData.disabled(filterItem)) {
        const list = data.options
          .filter((d) => {
            const val = tabData?.default
              ? tabData.default.includes(d.name)
              : tabData?.disabled_default
                ? tabData.disabled_default.includes(d.name)
                : data.default.includes(d.name);
            return val;
          })
          .map((l) => l.value);
        if (list.length === 1 && list[0] !== filterItem[data.key]) {
          const index = newFilters.findIndex(({ key }) => key === data.key);
          const object = {
            key: data.key,
            value: list[0],
          };
          if (index === -1) {
            newFilters.push(object);
          } else {
            newFilters[index] = object;
          }
        } else if (list.length > 1) {
          const isValueSame = list.length === filterItem[data.key].length
            && list.every(
              (element, index) => element === filterItem[data.key][index],
            );
          if (!isValueSame) {
            const index = newFilters.findIndex(({ key }) => key === data.key);
            const object = {
              key: data.key,
              value: list,
            };
            if (index === -1) {
              newFilters.push(object);
            } else {
              newFilters[index] = object;
            }
          }
        } else if (list.length === 1 && tabData?.disabled_default) {
          const object = allowedTabsFilters.find(
            (item) => item.value === list[0],
          );
          if (!isFilterValueAllowed.find((item) => item.value === object.value)) {
            isFilterValueAllowed.push(object);
          }
        }
      } else if (
        Array.isArray(filterItem[data.key])
        && filterItem[data.key].includes(list.value)
      ) {
        isFilterValueAllowed.push(list);
      } else if (
        data.type !== 'datePicker'
        && data.type !== 'button'
        && data.type !== 'MultipleFilter'
      ) {
        if (tabData && tabData?.allow_multiple) {
          if (typeof filterItem[data.key] === 'string') {
            const defaultValue = tabData?.default || data.default;
            const filterValue = data.options.reduce((acc, cur) => {
              if (defaultValue.includes(cur.name)) acc.push(cur.value);
              return acc;
            }, []);

            const index = newFilters.findIndex(({ key }) => key === data.key);
            const object = {
              key: data.key,
              value: filterValue,
            };
            if (index === -1) {
              newFilters.push(object);
            } else {
              newFilters[index] = object;
            }
          }
        } else if (list.value === filterItem[data.key]) {
          isFilterValueAllowed.push(list);
        } else {
          const filteredData = allowedTabsFilters.find(
            (f) => f.value === filterItem[data.key],
          );
          if (typeof filteredData === 'undefined') {
            const list = data.options.find((d) => (tabData?.default
              ? tabData.default.includes(d.name)
              : data.default.includes(d.name)));

            const index = newFilters.findIndex(({ key }) => key === data.key);
            const object = {
              key: data.key,
              value: list.value,
            };
            if (index === -1) {
              newFilters.push(object);
            } else {
              newFilters[index] = object;
            }
          }
        }
      }
    });
    data.current = 'current' in data && isFilterValueAllowed.length
      ? isFilterValueAllowed.map((selectedFilter) => selectedFilter.name)
      : data?.tabs.findIndex((t) => t.tab === tabValue && t?.default) !== -1
        ? [
          ...data?.tabs[
            data?.tabs.findIndex((t) => t.tab === tabValue && t?.default)
          ].default,
        ]
        : data?.default;
  });
  const filteredArray = {
    newFilters,
    allowedFilterData,
  };
  return filteredArray;
};

function FilterGroup() {
  const [filterData, setFilterData] = useState([]);
  const { pathname } = window.location;
  const path = pathname.replace(/\/$/, '');
  const moduleConfig = config.filter((obj) => obj.path === path);
  const accordionData = moduleConfig.length && moduleConfig[0]?.accordionOptions
    ? [...config.filter((obj) => obj.path === path)?.[0]?.accordionOptions]
    : null;
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const filterItem = useSelector((state) => state.filters);

  const { moduleName, tabValue, userInfo } = auth;

  const { year } = userInfo;
  const emissionYear = year.emissions;

  const setEmissionTypeAndMethodology = (filters, accordionOptions) => {
    const footprintParams = getFootprintParameters(
      userInfo.footprint_parameters,
      accordionData,
    );
    if (accordionOptions && accordionOptions.length > 0) {
      const indexS = filters.findIndex((element) => element.key === 'strategy');
      let value = '';
      if (indexS !== -1) {
        value = filters[indexS].options.find(
          (d) => d.value === footprintParams[0].Methodology,
        ).value;
        const obj = filters[indexS].options.find(
          (d) => d.value === footprintParams[0].Methodology,
        );
        filters[indexS].default = [obj.name];
      }

      const indexF = filters.findIndex(
        (element) => element.key === 'footprintMetric',
      );
      if (indexF !== -1 && value !== 'undefined') {
        const indexE = filters.findIndex(
          (element) => element.key === 'emissionsType',
        );

        if (indexE !== -1 && value !== 'undefined') {
          for (const f of filters[indexE].options) {
            if (f.name === 'By PCAF Score') {
              value === 'default'
                ? (f.allowedTabs = [])
                : f.allowedTabs[(0, 1)];
            }
          }
          const obj = filters[indexE].options.find(
            (d) => d.value === footprintParams[0]['Emission Type'],
          );
          filters[indexE].default = value === 'pcaf'
            ? (filters[indexE].current = ['By PCAF Score'])
            : [obj?.name];
          filters[indexE].current = value === 'pcaf'
            ? (filters[indexE].current = ['By PCAF Score'])
            : [obj?.name];
        }
      }
    }
  };

  const setCurrentAlignmentYear = (filters, accordionOptions, path) => {
    let formattedEmissionYear = '';
    path === '/net-zero-analysis'
      ? (formattedEmissionYear = '2025')
      : (formattedEmissionYear = emissionYear.toString());
    const index = filters.findIndex((element) => element.key === 'alignmentYear');
    if (
      index !== -1
      && !filters[index].options.some(
        (option) => option.value === formattedEmissionYear,
      )
    ) {
      filters[index].options.unshift({
        name: formattedEmissionYear,
        value: formattedEmissionYear,
        allowedTabs: [0, 1, 2, 3, 4, 5],
      });
      filters[index].default = [formattedEmissionYear];
    }

    setEmissionTypeAndMethodology(filters, accordionOptions);
    return filters;
  };

  const updateFilter = (key, value) => dispatch(setFilterItemSuccess({ key, value }));

  useEffect(
    () => () => {
      setFilterData([]);
    },
    [pathname, tabValue],
  );

  const fetchData = async () => {
    const moduleNameFromUrl = getModuleNameFromPath(pathname);
    const filter = config.filter((obj) => obj.path === pathname);
    let currentModule = moduleName;
    let currentTab = tabValue;
    if (moduleNameFromUrl && moduleName !== moduleNameFromUrl) {
      currentModule = moduleNameFromUrl;
      currentTab = 0;
      dispatch(setTabValue(0));
      dispatch(setModule(moduleNameFromUrl));
    }
    if (filter.length) {
      const { filters } = filter[0];
      const f = setCurrentAlignmentYear(
        filters,
        filter[0].accordionOptions,
        pathname,
      );
      const data = await getFilterData(f, filterItem, currentTab);
      setFilterData(data.allowedFilterData);
      data.newFilters.length
        && data.newFilters.forEach(({ key, value }) => {
          updateFilter(key, value);
        });
    }
    updateFilter('updatedFilterModule', `${currentModule}-${currentTab}`);
  };

  useEffect(() => {
    fetchData();
  }, [filterItem, pathname, tabValue]);

  const renderComponent = (type, data, allowedTabs) => {
    switch (type) {
      case 'button':
        return <FilterOnLoadButton />;
      case 'datePicker':
        return <FilterDatePicker dateOptions={data.options} />;
      case 'MultipleFilter':
        return <MultiAddFilters />;
      default:
        return (
          <FilterDropdown
            data={data}
            allowedTabs={allowedTabs}
            filterData={filterData}
            setFilterData={setFilterData}
            getFootprintParameters={getFootprintParameters}
          />
        );
    }
  };
  return (
    <React.Fragment>
      {filterData.map((data) => {
        const allowedTabs = data.tabs[data.tabs.findIndex((t) => t.tab === tabValue)];
        return (
          typeof allowedTabs !== 'undefined' && (
            <React.Fragment key={data.key}>
              {renderComponent(data.type, data, allowedTabs)}
            </React.Fragment>
          )
        );
      })}
      <FilterPcafAccordion />
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  filterBackground: {
    backgroundColor: theme.palette.background.default,
  },
}));

const FilterSection = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = window.location;
  const { loading, moduleName, tabValue } = useSelector((state) => state.auth);
  const { moduleTabs } = config.find((obj) => obj.path === pathname) || {};

  const isHistoricalEmission = moduleName === 'Sovereign' && tabValue === 2;

  const showBenchmarkToggle = moduleTabs?.find(
    ({ tab }) => tab === tabValue,
  )?.show_toggle;

  const shouldShowFilters = getShowFiltersPaths(pathname);

  useEffect(() => {
    if (!shouldShowFilters) {
      dispatch(
        setFilterItemSuccess({
          key: 'updatedFilterModule',
          value: `${moduleName}-0`,
        }),
      );
    }
  }, [moduleName, shouldShowFilters]);

  if (!shouldShowFilters) return null;
  return (
    <div className={`${classes.filterBackground} filter-main`}>
      <Box>
        {pathname === '/fund-of-funds' ? (
          <SelectFundsPortfolio />
        ) : pathname === '/portfolio-screener' ? (
          <MultiSelectPortfolio />
        ) : (
          <>
            <SelectPortfolio />
            {(!isHistoricalEmission || !moduleName === 'Download')
              && moduleName !== 'Summary'
              && moduleName !== 'Indicator' && <SelectBenchmark />}
            {isHistoricalEmission && <SelectCountry />}
          </>
        )}
      </Box>
      {!loading && <FilterGroup />}
      {showBenchmarkToggle && <BenchmarkToggle />}
    </div>
  );
};

export default FilterSection;
