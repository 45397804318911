import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import { useLocation } from 'react-router-dom';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { CircularProgress } from '@material-ui/core';
import { useDownloadPDFFileMutation } from '../../redux/apiHooks';
import supplyChainIcon from '../../assets/dashboards/supply_chain.png';
import cvarIcon from '../../assets/dashboards/cvar.png';
import labelledBondIcon from '../../assets/dashboards/labelled_bond.png';

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    transition: 'none',
    transitionDuration: 0,
  },
  customFab: {
    backgroundColor: '#FFFFFF',
  },
}));

const documentsData = {
  // '/': {
  //   onClick: () => window.open(manual, '_blank'),
  // },
  '/portfolio-screener': {
    fileUrl:
      'factsheet/ICE_Sustainable Finance_CTA_Portfolio Screener Product Guide.pdf',
    filename:
      'ICE_Sustainable Finance_CTA_Portfolio Screener Product Guide.pdf',
  },
  '/temperature-metric': {
    fileUrl:
      'factsheet/ICE Sustainable Finance_CTA_Temperature Score Product Guide.pdf',
    filename: 'ICE Sustainable Finance_CTA_Temperature Score Product Guide.pdf',
  },
  '/net-zero-analysis': {
    fileUrl:
      'factsheet/ICE_Sustainable Finance_CTA_Net Zero Analysis Product Guide.pdf',
    filename: 'ICE_Sustainable Finance_CTA_Net Zero Analysis Product Guide.pdf',
  },
  '/': {
    fileUrl:
      'factsheet/ICE_Sustainable Finance_Climate Transition Analytics_User Manual.pdf',
    filename:
      'ICE_Sustainable Finance_Climate Transition Analytics_User Manual.pdf',
  },
  '/portfolio-footprint': {
    fileUrl:
      'factsheet/ICE_Sustainable Finance_CTA_Portfolio Emissions Product Guide.pdf',
    filename:
      'ICE_Sustainable Finance_CTA_Portfolio Emissions Product Guide.pdf',
  },
  '/portfolio-optimization': {
    fileUrl:
      'factsheet/ICE_Sustainable Finance_CTA_Portfolio Optimization Product Guide.pdf',
    filename:
      'ICE_Sustainable Finance_CTA_Portfolio Optimization Product Guide.pdf',
  },
  '/reports': {
    fileUrl: 'factsheet/ICE_Sustainable Finance_CTA_Report Product Guide.pdf',
    filename: 'ICE_Sustainable Finance_CTA_Report Product Guide.pdf',
  },
  '/portfolio-summary': {
    fileUrl:
      'factsheet/ICE_Sustainable Finance_CTA_Transition Indicator Product Guide.pdf',
    filename:
      'ICE_Sustainable Finance_CTA_Transition Indicator Product Guide.pdf',
  },
  '/dashboards': {
    actions: [
      {
        icon: <img src={labelledBondIcon} width={24} />,
        name: 'Labelled Bond',
        fileUrl:
          'factsheet/ICE_Sustainable Finance_CTA_Labelled Bonds Product Guide.pdf',
        filename:
          'ICE_Sustainable Finance_CTA_Labelled Bonds Product Guide.pdf',
      },
      {
        icon: <img src={cvarIcon} width={24} />,
        name: 'Climate Transition Value-at-Risk',
        fileUrl:
          'factsheet/ICE_Sustainable Finance_CTA_Climate Transition Value-at-Risk Product Guide.pdf',
        filename:
          'ICE_Sustainable Finance_CTA_Climate Transition Value-at-Risk Product Guide.pdf',
      },
      {
        icon: <img src={supplyChainIcon} width={24} />,
        name: 'Supply Chain Risk',
        fileUrl:
          'factsheet/ICE_Sustainable Finance_CTA_Supply Chain Risk Product Guide.pdf',
        filename:
          'ICE_Sustainable Finance_CTA_Supply Chain Risk Product Guide.pdf',
      },
    ],
  },
};

export const pathSetWithSpeedDial = new Set(Object.keys(documentsData));

export default function SpeedDials() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const clickData = documentsData[pathname];
  const [open, setOpen] = useState(false);
  const [downloadFile, { isLoading, originalArgs }] = useDownloadPDFFileMutation();

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  const hasMulipleOptions = clickData?.actions;

  const onClick = () => {
    if (isLoading || hasMulipleOptions) return;
    if (clickData.onClick instanceof Function) {
      clickData.onClick();
    }
    if (clickData.fileUrl) {
      downloadFile({ url: clickData.fileUrl, filename: clickData.filename });
    }
  };

  const onActionClick = (action) => {
    if (isLoading) return;
    if (action.onClick instanceof Function) {
      action.onClick();
    }
    if (action.fileUrl) {
      downloadFile({ url: action.fileUrl, filename: action.filename });
    }
    handleClose();
  };

  if (!clickData) return null;
  return (
    <SpeedDial
      ariaLabel="FactSheetDownload"
      className={classes.speedDial}
      hidden={false}
      open={hasMulipleOptions && !isLoading ? open : true}
      icon={
        <SpeedDialIcon
          openIcon={
            isLoading ? (
              <CircularProgress color="#FFFFFF" size="24px" />
            ) : (
              <MenuBookIcon />
            )
          }
          icon={<MenuBookIcon />}
        />
      }
      onClose={handleClose}
      onOpen={handleOpen}
      onClick={onClick}
    >
      {hasMulipleOptions
        && clickData.actions?.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={
              isLoading && originalArgs?.filename === action.filename ? (
                <CircularProgress size="14px" />
              ) : (
                action.icon
              )
            }
            tooltipTitle={action.name}
            onClick={() => onActionClick(action)}
            className={classes.customFab}
          />
        ))}
    </SpeedDial>
  );
}
