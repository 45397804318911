/* eslint-disable react/prop-types */

import React from 'react';
import {
  Box, Grid, Slider, Button,
} from '@material-ui/core';
import LineChart from '../../../components/Charts/LineChart';
import DataTable from '../../../components/Table/DataTable';

const PortfolioOptimizationDisplay = (props) => (
  <React.Fragment>
    <Box>
      <Grid container style={{ marginLeft: 20, marginTop: 10 }}>
        <Grid item xs={5}>
          <Box style={{ fontSize: 14 }}>Select reweight factor</Box>
          <Slider
            value={props.value}
            max={1}
            min={0}
            step={0.01}
            style={{ width: '80%' }}
            onChange={props.onChange}
            valueLabelDisplay="auto"
          />
        </Grid>
        <Grid item xs={4}>
          <Button type="primary" variant="contained" onClick={props.onClick}>
            Submit
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <LineChart
            data={props.lineChartData}
            title="Low Carbon Backtesting Module"
            yAxisTitle="Return (%)"
            height={500}
            xAxisType="datetime"
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <DataTable
            data={props.data}
            columns={props.columns}
            tableHeading={props.tableHeading}
            // isTrial={trial}
          />
        </Grid>
      </Grid>
    </Box>
  </React.Fragment>
);

export default PortfolioOptimizationDisplay;
