import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import DataTable from '../../../components/Table/DataTable';
import { tempScoreCells } from '../../../util/TableHeadConfig';
import StackedBar from '../../../components/Charts/StackedBar';
import SpiralChart from '../../../components/Charts/SpiralChart';

const PortTempScoreDisplay = ({
  tempScoreChartData,
  spiralXValue,
  companyChartData,
  targetData,
  tableData,
}) => (
  <React.Fragment>
    <Grid container>
      <Grid item xs={3} style={{ marginRight: 5 }}>
        <SpiralChart
          data={tempScoreChartData}
          yAxis={{ min: 0, max: 5, tickInterval: 1 }}
          spiralXValue={spiralXValue}
          width={300}
        />
      </Grid>
      <Grid item xs={5} style={{ marginTop: 70 }}>
        <StackedBar
          categories={['Number Of Companies']}
          data={companyChartData}
          height={250}
        />
      </Grid>
      <Grid item xs={3}>
        <SpiralChart
          data={targetData}
          yAxis={{ min: 0, max: 100, tickInterval: 25 }}
          spiralXValue={['Disclosed Targets ', 'SBTI validated']}
          unit="%"
        />

        {/* <Box className={classes.companyDiv}>
            <CircularProgressbarWithChildren
              value={targetData}
              styles={buildStyles({
                pathColor: currentTheme === 'dark' ? '#1bdecb' : '#1E2732',
              })}
            >
              <div
                className="default-font"
                style={{
                  fontSize: 12,
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                }}
              >
                Disclosed Target
              </div>
              <div
                style={{
                  fontSize: 12,
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                }}
              >{`${targetData}%`}</div>
            </CircularProgressbarWithChildren>
          </Box> */}
      </Grid>
    </Grid>
    <Grid conatiner>
      <DataTable
        data={tableData}
        columns={tempScoreCells}
        tableHeading="TEMP_SCORE"
      />
    </Grid>
  </React.Fragment>
);

PortTempScoreDisplay.propTypes = {
  tempScoreChartData: PropTypes.array.isRequired,
  companyChartData: PropTypes.array.isRequired,
  targetData: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  spiralXValue: PropTypes.string.isRequired,
};

export default PortTempScoreDisplay;
