/* eslint-disable react/prop-types */

import React from 'react';
import BubbleChart from '../../../components/Charts/BubbleChart';
import DataTable from '../../../components/Table/DataTable';

const CompanyDisplay = (props) => (
  <React.Fragment>
    <BubbleChart
      data={props.data}
      xAxisTitle={props.xAxisLabel}
      yAxisTitle={props.yAxisLabel}
      xAxisLabel="Carbon Offset"
      yAxisLabel="Emissions"
      zAxisLabel="Weights"
      maxSize={8}
    />
    <DataTable
      data={props.dataTableData}
      columns={props.columns}
      tableHeading={props.tableHeading}
      isTrial={props.isTrial}
    />
  </React.Fragment>
);

export default CompanyDisplay;
