export default class portfolioBuilderView {
  constructor(auth, filters) {
    const { selectedPortfolios } = auth;
    this.portfolio_ids = selectedPortfolios || [];
    this.display_ccy = 'USD';
    this.currency_date = {
      year: 2021,
      quarter: 'Q3',
    };
    this.classification = 'ICE';
    this.filters = filters;
  }

  toJsonObject() {
    return { ...this };
  }
}
