import React from 'react';

// eslint-disable-next-line react/prop-types
function RoundSignal({ size = 10, color }) {
  let computedColor = 'black';
  switch (color) {
    case 'red':
      computedColor = 'red';
      break;
    case 'green':
      computedColor = 'green';
      break;
    case 'orange':
      computedColor = 'orange';
      break;
    default:
      computedColor = color;
      break;
  }
  return (
    <div
      style={{
        borderRadius: '50%',
        width: size,
        height: size,
        background: computedColor,
      }}
    />
  );
}

export default RoundSignal;
