/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import UrgentemDownloadDisplay from './UrgentemDownloadDisplay';
import { LoadingHOC } from '../../util/LoadingHOC';
import { formatNumber } from '../../util/formatNumber';
import { columnsWithStringType } from './constants';
import { downloadExcel } from '../../helpers';
import {
  INCLUDE_KEY,
  INCLUDE_NONE,
  SBTI_TEMP_SCORE,
  TARGETS,
} from '../../components/FilterSection/constants';
import {
  useGetDownloadEmissionsQuery,
  useLazyGetTempMetricArrayTempScoresQuery,
} from '../../redux/apiHooks';
import DownloadModel from '../../models/download';
import TempMetricModel from '../../models/download/tempMetric';
import useGetPayload from '../../hooks/useGetPayload';
import { setFilterItemSuccess } from '../../redux/reducers/filterReducer';

const DownloadWithLoading = LoadingHOC(UrgentemDownloadDisplay);

const WIDTH = '10.5VW';

const TermsStrings = {
  short: 'short',
  mid: 'mid',
  long: 'long',
};
// Sorting as per excel sort
const convertSbtiArray = (data) => ({
  ISIN: data.ISIN,
  'Company Name': data['Company Name'],
  'Scope 12 Short Term Temperature Score':
    data['Scope 12 Short Term Temperature Score'],
  'Scope 12 Mid Term Temperature Score':
    data['Scope 12 Mid Term Temperature Score'],
  'Scope 12 Long Term Temperature Score':
    data['Scope 12 Long Term Temperature Score'],
  'Scope 3 Short Term Temperature Score':
    data['Scope 3 Short Term Temperature Score'],
  'Scope 3 Mid Term Temperature Score':
    data['Scope 3 Mid Term Temperature Score'],
  'Scope 3 Long Term Temperature Score':
    data['Scope 3 Long Term Temperature Score'],
  'Scope 123 Short Term Temperature Score':
    data['Scope 123 Short Term Temperature Score'],
  'Scope 123 Mid Term Temperature Score':
    data['Scope 123 Mid Term Temperature Score'],
  'Scope 123 Long Term Temperature Score':
    data['Scope 123 Long Term Temperature Score'],
});

const generateSbtiArray = (sbtiTempScoreData) => {
  const data = sbtiTempScoreData.reduce(
    (acc, {
      company_name, company_isin, scope, term, temperature_score,
    }) => {
      const newData = {};
      if (scope === 'Sc12') {
        if (term === TermsStrings.short) newData['Scope 12 Short Term Temperature Score'] = temperature_score;
        if (term === TermsStrings.mid) newData['Scope 12 Mid Term Temperature Score'] = temperature_score;
        if (term === TermsStrings.long) newData['Scope 12 Long Term Temperature Score'] = temperature_score;
      }
      if (scope === 'Sc3') {
        if (term === TermsStrings.short) newData['Scope 3 Short Term Temperature Score'] = temperature_score;
        if (term === TermsStrings.mid) newData['Scope 3 Mid Term Temperature Score'] = temperature_score;
        if (term === TermsStrings.long) newData['Scope 3 Long Term Temperature Score'] = temperature_score;
      }
      if (scope === 'Sc123') {
        if (term === TermsStrings.short) newData['Scope 123 Short Term Temperature Score'] = temperature_score;
        if (term === TermsStrings.mid) newData['Scope 123 Mid Term Temperature Score'] = temperature_score;
        if (term === TermsStrings.long) newData['Scope 123 Long Term Temperature Score'] = temperature_score;
      }
      if (acc[company_name]) {
        acc[company_name] = {
          ...acc[company_name],
          ...newData,
        };
      } else {
        acc[company_name] = {
          ISIN: company_isin,
          'Company Name': company_name,
          ...newData,
        };
      }
      return acc;
    },
    {},
  );
  return Object.keys(data)
    .sort()
    .map((key) => convertSbtiArray(data[key]));
};

function UrgentemDownload() {
  const [columns, setColumns] = useState([]);
  const {
    auth,
    filters,
    payload: downloadPayload,
  } = useGetPayload(DownloadModel);
  const {
    data: allDownloadData,
    isFetching: isDownloadFetching,
    error: downloadError,
  } = useGetDownloadEmissionsQuery(downloadPayload);

  const { payload: tempMetricPayload } = useGetPayload(TempMetricModel);
  const dispatch = useDispatch();
  const [
    getTempMetric,
    {
      data: sbtiTempScoreData,
      isFetching: isTempMetricFetching,
      error: tempMetricError,
    },
  ] = useLazyGetTempMetricArrayTempScoresQuery();

  const targetsData = allDownloadData?.targets || [];

  const { currentPortfolio, userInfo } = auth;
  const { include } = filters;

  const trial = get(userInfo, 'trial', false);

  useEffect(() => {
    if (include?.includes(SBTI_TEMP_SCORE)) {
      getTempMetric(tempMetricPayload, true);
    }
  }, [
    include,
    currentPortfolio?.value,
    currentPortfolio?.version,
    userInfo?.year?.emissions,
  ]);

  useEffect(() => {
    if (include.length > 1 && include.includes(INCLUDE_NONE)) {
      if (include.findIndex((item) => item === INCLUDE_NONE) === 0) {
        dispatch(
          setFilterItemSuccess({
            key: INCLUDE_KEY,
            value: include.filter((item) => item !== INCLUDE_NONE),
          }),
        );
      } else {
        dispatch(
          setFilterItemSuccess({
            key: INCLUDE_KEY,
            value: [INCLUDE_NONE],
          }),
        );
      }
    }
  }, [include]);

  const disclosureSort = (rowA, rowB) => {
    const a = rowA['Disclosure Category'].toString();
    const b = rowB['Disclosure Category'].toString();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const getTableColumns = () => {
    if (allDownloadData?.data?.[0]) {
      const res = Object.entries(allDownloadData.data[0]).map(
        ([column, value]) => ({
          name: column,
          selector: column,
          sortable: true,
          right: typeof value === 'number',
          wrap: true,
          minWidth: WIDTH,
          sortFunction: column === 'Disclosure Category' && disclosureSort,
          style: {
            height: 80,
            width: 500,
          },
          format: (row) => {
            if (row[column] === null) {
              return 'N/A';
            }
            if (columnsWithStringType.includes(column)) {
              return row[column];
            }
            return formatNumber(row[column]);
          },
        }),
      );
      setColumns(res);
    }
  };

  // Sorting as per excel sort
  const convertTargetData = (data) => ({
    // 'Unique Target Identifier': data['Unique Target Identifier'],
    ISIN: data.ISIN,
    'Primary Issuer ISIN': data['Primary Issuer ISIN'],
    'Company (Short) Name': data['Company (Short) Name'],
    Country: data.Country,
    Region: data.Region,
    Scope: data.Scope,
    'Scope Description': data['Scope Description'],
    'Target Type': data['Target Type'],
    'Base Year': data['Base Year'],
    'End Year': data['End Year'],
    'Start Year': data['Start Year'],
    'Reduction Ambition %': data['Reduction Ambition %'],
    'Base Year Emissions Coverage (%)':
      data['Base Year Emissions Coverage (%)'],
    'Base Year Emissions': data['Base Year Emissions'],
    Status: data.Status,
    'Status Year': data['Status Year'],
    // 'Data Source': data['Data Source'],
    'Unit Denominator': data['Unit Denominator'],
    'SBTi Approved': data['SBTi Approved'],
  });

  const downloadDataHandler = () => {
    const data = {
      'GHG Emissions': allDownloadData.data,
    };
    if (include?.includes(TARGETS)) {
      data.Targets = targetsData.map(convertTargetData);
    }
    if (include?.includes(SBTI_TEMP_SCORE)) {
      data['SBTi Temp Scores'] = generateSbtiArray(sbtiTempScoreData);
    }
    downloadExcel(data);
  };
  useEffect(() => {
    getTableColumns();
  }, [allDownloadData]);
  return (
    <React.Fragment>
      <DownloadWithLoading
        loading={isDownloadFetching || isTempMetricFetching}
        error={downloadError || tempMetricError}
        downloadDataHandler={downloadDataHandler}
        downloadData={allDownloadData}
        columns={columns}
        trial={trial}
      />
    </React.Fragment>
  );
}
export default UrgentemDownload;
