import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import handleDownloadPdf from '../../../helpers/downloadPdf';
import useGetPayload from '../../../hooks/useGetPayload';
import Report from '../../../models/report';
import {
  useLazyGetReportGeneralReportQuery,
  useLazyGetTempMetricPortfolioScoreQuery,
} from '../../../redux/apiHooks';
import { setFilterItemSuccess } from '../../../redux/reducers/filterReducer';
import PortfolioScore from '../../../models/tempScore/portfolioScore';
import GeneralReportDisplay from './GeneralReportDisplay';
import { LoadingHOC } from '../../../util/LoadingHOC';

import './index.css';

const GeneralReportDisplayWithLoading = LoadingHOC(GeneralReportDisplay);

const Display = () => {
  const dispatch = useDispatch();
  const options = [
    'Show all',
    'Portfolio Footprint',
    'Sectoral Analysis',
    'Avoided Emissions',
    'Sovereign Footprint',
    'Scope 3 Materiality',
    'Fossil Fuels Reserves Footprint',
    'Net-Zero Analysis',
    'Portfolio Temperature Score (SBTi)',
  ];
  const { loadData } = useSelector((state) => state.filters);

  const [displayedPages, setDisplayedPages] = useState(options);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const generalReport = useSelector((state) => state.report.generalReport);

  const { payload: reportPayload, auth, filters } = useGetPayload(Report);
  const { payload: tempScorePayload } = useGetPayload(PortfolioScore);

  const [tcfdTrigger, { data: tcfdData, isFetching: isReportFetching }] = useLazyGetReportGeneralReportQuery(reportPayload);
  const [
    tempScoreTrigger,
    { data: tempScoreData, isFetching: isTempScoreFetching, error },
  ] = useLazyGetTempMetricPortfolioScoreQuery();

  useEffect(() => {
    if (tcfdData || tempScoreData) {
      setButtonDisabled(false);
    }
  }, [tcfdData, tempScoreData]);

  useEffect(() => {
    if (loadData) {
      tcfdTrigger(reportPayload, true);
      tempScoreTrigger(tempScorePayload);
      dispatch(setFilterItemSuccess({ key: 'loadData', value: false }));
    }
  }, [loadData]);

  useEffect(() => {
    if (generalReport.portfolio || generalReport.portfolio) {
      setButtonDisabled(false);
    }
  }, [generalReport]);
  const onPageSelect = (event) => {
    let {
      target: { value },
    } = event;
    if (displayedPages.includes('Show all') && !value.includes('Show all')) {
      value = [];
    } else if (
      !displayedPages.includes('Show all')
      && value.includes('Show all')
    ) {
      value = options;
    }

    setDisplayedPages(value);
  };
  const showPage = (pageName) => displayedPages.findIndex((page) => page === 'Show all' || page === pageName)
    > -1;

  const onDownloadClick = async () => {
    setLoading(true);
    setButtonDisabled(true);
    handleDownloadPdf('Portfolio Analytics Report', () => {
      setLoading(false);
      setButtonDisabled(false);
    });
  };
  return (
    <GeneralReportDisplayWithLoading
      error={error}
      loading={isReportFetching || isTempScoreFetching}
      onDownloadClick={onDownloadClick}
      buttonDisabled={buttonDisabled}
      downloading={loading}
      displayedPages={displayedPages}
      onPageSelect={onPageSelect}
      options={options}
      showPage={showPage}
      data={{
        portfolio: tcfdData?.portfolio,
        benchmark: tcfdData?.benchmark,
        tempScore: tempScoreData,
        auth,
        filters,
      }}
    />
  );
};

export default Display;
