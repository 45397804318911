import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { LoadingHOC } from '../../../util/LoadingHOC';
import FootprintDisplay from './FootprintDisplay';
import FootprintModel from '../../../models/FOF/footprint';
import useGetPayload from '../../../hooks/useGetPayload';
import {
  useGetFoFPortfolioEmissionsQuery,
  useGetFoFSectorEmissionsQuery,
  useGetUserFundsPortfolioListQuery,
} from '../../../redux/apiHooks';

const FootprintDisplayWithLoading = LoadingHOC(FootprintDisplay);

const Footprint = () => {
  const [pieChartdata, setPieChartData] = useState([]);
  const [stackedColChartData, setStackedColChartData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [yAxisTitle, setYAxisTitle] = useState('');
  const [parentData, setParentData] = useState('');
  const [parentCategories, setParentCategories] = useState('');
  const [currentSector, setSector] = useState('');

  const { payload, auth, filters } = useGetPayload(FootprintModel);
  const {
    data: portData,
    error: portError,
    isFetching: isPortLoading,
  } = useGetFoFPortfolioEmissionsQuery(payload);
  const {
    data: sectorData,
    error: sectorError,
    isFetching: isSectorLoading,
  } = useGetFoFSectorEmissionsQuery(payload);

  const { data: fundsData } = useGetUserFundsPortfolioListQuery();
  const allPortfolios = fundsData?.allPortfolios || [];

  const { currentFundsPortfolio } = auth;
  const { inferenceType, emission } = filters;

  const handleSectorChange = (e) => {
    const sectorName = e.target.value;
    const sectorLevelData = sectorData.data;

    const barChartData = [];

    if (sectorLevelData && Object.keys(sectorLevelData).length > 0) {
      Object.keys(sectorLevelData).forEach((id) => {
        if (id !== currentFundsPortfolio.value) {
          const intensity = inferenceType === 'avg'
            ? sectorLevelData[id].avg.intensity
            : sectorLevelData[id].max.intensity;

          if (intensity[sectorName]) {
            barChartData.push({
              y: intensity[sectorName][emission.toLowerCase()]
                ? intensity[sectorName][emission.toLowerCase()]
                : 0,
            });
          } else {
            barChartData.push({
              y: 0,
            });
          }
        }
      });
    }

    setParentData({
      name: '',
      data: barChartData,
    });

    setSector(sectorName);
  };

  const getPortfolioName = (id) => {
    let portName = '';
    if (allPortfolios && allPortfolios.length > 0) {
      allPortfolios.forEach((portfolio) => {
        if (portfolio.portfolio_id === id) {
          portName = portfolio.name;
        }
      });
    }
    return portName;
  };
  const getChartData = () => {
    setYAxisTitle(portData.metadata.unit);

    const footprintData = portData.data;
    const sectorLevelData = sectorData.data;

    const pieChartData = [
      {
        name: 'Contribution',
        data: [],
      },
    ];
    const stChartData = [];
    const cat = [];
    const stackedCol = [];
    const pCategories = [];
    const barCHartData = [];

    if (!isEmpty(footprintData) && !isEmpty(sectorLevelData)) {
      const intensityByCat = {};
      Object.keys(footprintData).forEach((id) => {
        if (id !== currentFundsPortfolio.value) {
          const weight = inferenceType === 'avg'
            ? footprintData[id].avg.weight
            : footprintData[id].max.weight;

          const value = inferenceType === 'avg'
            ? footprintData[id].avg.contribution[emission.toLowerCase()]
            : footprintData[id].max.contribution[emission.toLowerCase()];

          pCategories.push(getPortfolioName(id));

          pieChartData[0].data.push({
            name: getPortfolioName(id),
            y: value,
          });

          stChartData.push({
            name: getPortfolioName(id),
            data: [weight],
          });
        }
      });
      Object.keys(sectorLevelData).forEach((id) => {
        if (id !== currentFundsPortfolio.value) {
          const { intensity } = sectorLevelData[id][inferenceType.toLowerCase()];
          const sortedIntensity = Object.keys(intensity).sort();
          const sector = sortedIntensity[0];
          if (sector) {
            barCHartData.push({
              y: intensity?.[sector][emission.toLowerCase()]
                ? intensity?.[sector][emission.toLowerCase()]
                : 0,
              // color: colors[index],
            });
          } else {
            barCHartData.push({
              y: 0,
              // color: colors[index],
            });
          }

          Object.keys(intensity)
            .sort()
            .forEach((el) => {
              if (!intensityByCat[el]) {
                intensityByCat[el] = {};
              }
              intensityByCat[el][getPortfolioName(id)] = intensity[el][emission.toLowerCase()];

              if (!cat.includes(el)) {
                cat.push(el);
              }
            });
        }
      });

      Object.keys(sectorLevelData).forEach((id) => {
        if (id !== currentFundsPortfolio.value) {
          const values = [];
          Object.keys(intensityByCat).forEach((el) => {
            if (intensityByCat[el][getPortfolioName(id)]) {
              values.push(intensityByCat[el][getPortfolioName(id)]);
            } else {
              values.push(0);
            }
          });
          stackedCol.push({
            name: getPortfolioName(id),
            data: values,
          });
        }
      });
    }
    setPieChartData(pieChartData);
    // setStackedChartData(stChartData);
    setStackedColChartData(stackedCol);
    setCategories(cat);
    setParentData([
      {
        name: '',
        data: barCHartData,
      },
    ]);
    setParentCategories(pCategories);
    setSector(cat[0]);
  };

  useEffect(() => {
    if (!isEmpty(portData) && !isEmpty(sectorData)) getChartData();
  }, [portData, sectorData, inferenceType, emission]);

  return (
    <React.Fragment>
      <Box>
        <FootprintDisplayWithLoading
          error={portError || sectorError}
          loading={isPortLoading || isSectorLoading}
          pieChartdata={pieChartdata}
          yAxisTitle={yAxisTitle}
          stackedColChartData={stackedColChartData}
          categories={categories}
          currentSector={currentSector}
          handleSectorChange={handleSectorChange}
          parentData={parentData}
          parentCategories={parentCategories}
        />
      </Box>
    </React.Fragment>
  );
};

export default Footprint;
