import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../../components/Table/DataTable';
import HorizontalBar from '../../../components/Charts/HorizontalBar';

const PortfolioEmissionsDisplay = ({
  categories,
  chartData,
  yAxisTitle,
  text,
  tableData,
  tableColumns,
  tableHeading,
}) => (
  <React.Fragment>
    <HorizontalBar
      categories={categories}
      data={chartData}
      title="Portfolio-level Sovereign Emissions Breakdown"
      yAxisTitle={yAxisTitle}
    />
    <div
      style={{
        fontSize: 14,
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        textAlign: 'center',
      }}
    >
      {text}
    </div>
    <DataTable
      data={tableData}
      columns={tableColumns}
      tableHeading={tableHeading}
    />
  </React.Fragment>
);

PortfolioEmissionsDisplay.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  chartData: PropTypes.arrayOf().isRequired,
  yAxisTitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  tableData: PropTypes.arrayOf().isRequired,
  tableColumns: PropTypes.arrayOf().isRequired,
  tableHeading: PropTypes.string.isRequired,
};

export default PortfolioEmissionsDisplay;
