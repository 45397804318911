/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */

import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import colorSchema from '../../Themes/config';
import useGetTheme from '../../hooks/useGetTheme';

export default function SelectwithSearch({
  heading,
  data,
  handleChange,
  currentValue,
  disabled,
}) {
  const { currentTheme } = useGetTheme();
  return (
    <div style={{ width: 250, marginRight: '20px' }}>
      <Autocomplete
        openOnFocus
        id={heading}
        options={data}
        disabled={disabled}
        value={currentValue}
        onChange={(e, newValue) => {
          if (e) {
            handleChange(newValue?.label);
          }
        }}
        getOptionLabel={(option) => option?.label || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            label={heading}
            variant="standard"
            data-testid="autocomplete-textfield"
          />
        )}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.label, inputValue);
          const parts = parse(option.label, matches);

          return (
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    color: part.highlight
                      ? colorSchema[currentTheme].primary
                      : null,
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
      />
    </div>
  );
}
