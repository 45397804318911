import BaseRequest from '../baseRequest';

export default class CoalPower extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem } = props;
    const { footprintMetric, assetClass, marketValue } = filterItem;

    this.asset_type = assetClass;
    this.footprint = footprintMetric;
    this.market_value = marketValue;
  }
}
