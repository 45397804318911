// eslint-disable-next-line import/no-cycle
import apiSlice, { getQuery } from '../apiSlice';

export const emissionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEmissionPortfolioEmission: builder.query({
      query: getQuery.post('/portfolio_footprint/portfolio_emissions'),
      transformErrorResponse: ({ data }) => (data?.type ? data?.description : data?.message),
    }),
    getEmissionSectorEmissions: builder.query({
      query: getQuery.post('/portfolio_footprint/sector_emissions'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getEmissionCarbonAttribution: builder.query({
      query: getQuery.post('/portfolio_footprint/carbon_attribution'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getEmissionDisclosure: builder.query({
      query: getQuery.post('/portfolio_footprint/disclosure'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getEmissionAvoidedEmissions: builder.query({
      query: getQuery.post('/portfolio_footprint/avoided_emissions'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getEmissionPcaf: builder.query({
      query: getQuery.post('/portfolio_footprint/pcaf'),
    }),
    getCompanyEmissions: builder.query({
      query: getQuery.post('/portfolio_footprint/company_emissions'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
  }),
});

export const {
  useGetEmissionPortfolioEmissionQuery,
  useLazyGetEmissionPortfolioEmissionQuery,
  useGetEmissionSectorEmissionsQuery,
  useLazyGetEmissionSectorEmissionsQuery,
  useGetEmissionCarbonAttributionQuery,
  useLazyGetEmissionCarbonAttributionQuery,
  useGetEmissionDisclosureQuery,
  useLazyGetEmissionDisclosureQuery,
  useGetEmissionAvoidedEmissionsQuery,
  useLazyGetEmissionAvoidedEmissionsQuery,
  useGetEmissionPcafQuery,
  useLazyGetEmissionPcafQuery,
  useGetCompanyEmissionsQuery,
  useLazyGetCompanyEmissionsQuery,
} = emissionsApiSlice;
