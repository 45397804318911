import React from 'react';
import PropTypes from 'prop-types';
import ReportTable from '../../../../components/PdfReport/ReportTable';
import {
  getCarbonAttributionAnalysis,
  getcarbonDisclosure,
  getCarbonIntensity,
  getCarbonIntensityTable,
  getPcaf,
  getPcafTable,
} from '../../../../helpers/report';
import ReportHorizontalBar from '../../../../components/PdfReport/Charts/ReportHorizontalBar';
import ReportStackedBar from '../../../../components/PdfReport/Charts/ReportStackedBar';

const CARBON_ATTRIBUTION_CHART_HEIGHT = 350;

const carbonIntensityColumns = [
  {
    name: ' ',
    selector: 'name',
    right: false,
    width: '40%',
    bold: true,
  },
  { name: 'Scope 1+2', selector: 'sc12', right: true },
  { name: 'Scope 3', selector: 'sc3', right: true },
  { name: 'Scope 1+2+3', selector: 'sc123', right: true },
];

const pcafColumns = [
  {
    name: ' ',
    selector: 'name',
    right: false,
    width: '11%',
    bold: true,
  },
  { name: 'Porfolio (S12)', selector: 'psc12', right: true },
  { name: 'Benchmark (S12)', selector: 'bsc12', right: true },
  {
    name: 'Porfolio (S123)',
    selector: 'psc123',
    right: true,
  },
  {
    name: 'Benchmark (S123)',
    selector: 'bsc123',
    right: true,
  },
];

const Emissions = ({ data }) => {
  const { portfolio, benchmark, auth } = data;

  let carbonIntensity = { data: [], yAxisTitle: '', categories: [] };
  let carbonIntensityTable = [];

  let pcaf = { data: [], yAxisTitle: '', categories: [] };
  let pcafTable = [];

  let carbonAttributionAnalysis = { data: [], yAxisTitle: '', categories: [] };
  let carbonDisclosure = {
    data: [],
    categories: [],
    yAxisTitle: '',
    xAxisTitle: '',
  };

  if (portfolio && benchmark) {
    carbonIntensity = getCarbonIntensity(
      portfolio.portfolio_emisisons,
      benchmark.portfolio_emisisons,
    );

    carbonIntensityTable = getCarbonIntensityTable(
      portfolio.portfolio_emisisons,
      benchmark.portfolio_emisisons,
    );

    pcaf = getPcaf(portfolio.pcaf, benchmark.pcaf);

    pcafTable = getPcafTable(pcaf.data);

    carbonAttributionAnalysis = getCarbonAttributionAnalysis(
      portfolio.carbon_atribution,
    );

    carbonDisclosure = getcarbonDisclosure(
      portfolio.disclosure,
      benchmark.disclosure,
    );
  }

  return (
    <div className="pdf_page">
      <h2>Emissions Footprint</h2>
      <div>
        <h3>Portfolio Footprint (Carbon Intensity)</h3>
        <ReportHorizontalBar
          categories={carbonIntensity.categories}
          data={carbonIntensity.data}
          yAxisTitle={carbonIntensity.yAxisTitle}
        />
        <div>
          <p>
            The Portfolio Footprint (Carbon Intensity) chart displays the carbon
            intensity (or footprint) of the portfolio and the benchmark,
            selected by {auth.currentUser.client}, in terms of Scope 1+2, Scope
            3, and Scope 1+2+3.
          </p>
        </div>
        <div style={{ marginTop: 25, marginBottom: 20 }}>
          <ReportTable
            columns={carbonIntensityColumns}
            data={carbonIntensityTable}
          />
        </div>
      </div>
      <div>
        <h3>Portfolio Footprint (PCAF)</h3>
        <ReportStackedBar
          categories={pcaf.categories}
          data={pcaf.data}
          yAxisTitle={pcaf.yAxisTitle}
        />
        <div>
          <p>
            The Portfolio Footprint Partnership for Carbon Accounting Financials
            (PCAF) chart displays the carbon intensity (or footprint) of the
            portfolio and benchmark, categorised by PCAF disclosure quality
            scores. PCAF disclosure quality range from 1 to 5 and are defined
            above.
          </p>
        </div>
        <div style={{ marginTop: 13, marginBottom: 20 }}>
          <ReportTable columns={pcafColumns} data={pcafTable} />
        </div>
      </div>
      <div>
        <h3>Carbon Attribution Analysis</h3>
        <ReportHorizontalBar
          categories={carbonAttributionAnalysis.categories}
          data={carbonAttributionAnalysis.data}
          yAxisTitle={carbonAttributionAnalysis.yAxisTitle}
          height={CARBON_ATTRIBUTION_CHART_HEIGHT}
          barWidth={2}
        />
        <div>
          <p>
            The Carbon Attribution Analysis chart identifies specific areas of
            the portfolio and benchmark’s carbon intensity performance. The
            stock selection effect measures the impact of individual security
            selection on the carbon intensity against the benchmark within a
            respective sector. The sector allocation effect highlights the
            impact of the portfolio’s sector weighting on the carbon intensity
            against the benchmark. Lastly, the interaction effect measures the
            combined impact of the selection and allocation effects within a
            sector.
          </p>
        </div>
      </div>
      <div>
        <h3>Carbon Disclosure</h3>
        <ReportStackedBar
          categories={carbonDisclosure.categories}
          data={carbonDisclosure.data}
          yAxisTitle={carbonDisclosure.yAxisTitle}
          xAxisTitle={carbonDisclosure.xAxisTitle}
          max={100}
          height={CARBON_ATTRIBUTION_CHART_HEIGHT}
        />
        <div>
          <p>
            The Carbon Disclosure chart highlights the number of Scope 1+2
            carbon emission disclosures that are made by companies within the
            portfolio and benchmark. Disclosure is categorised by ICE, from
            Category 1 to 5, as defined above.
          </p>
        </div>
      </div>
    </div>
  );
};

Emissions.propTypes = {
  data: PropTypes.shape({
    portfolio: PropTypes.object,
    benchmark: PropTypes.object,
    tempScore: PropTypes.array,
    auth: PropTypes.object,
    filters: PropTypes.object,
  }),
};

export default Emissions;
