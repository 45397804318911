import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { List, Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ListItemLink from '../ListItemLink';
import { RouteData } from '../../layouts/Route';
import { setModule, setTabValue } from '../../redux/reducers/authReducer';

const useStyles = makeStyles(() => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  list: {
    marginTop: '64px',
  },
}));

function SideNav() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.currentUser);

  const setDefaultTab = async (e) => {
    dispatch(setTabValue(0));
    dispatch(setModule(e.name));
  };

  const { currentPortfolio, currentBenchmark } = useSelector(
    (state) => state.auth,
  );
  const extraModules = currentUser?.extra_modules || [];

  return (
    <Drawer
      variant="permanent"
      className={classNames(classes.drawer)}
      open={true}
    >
      <List className={classes.list}>
        {RouteData.map((e, index) => {
          if (e.hideOnSidebar) return null;
          const isEnabled = e.isPublic
            || (extraModules.includes(e.extraModuleName)
              && (e.isPortfolioIndependent
                || (currentPortfolio.value && currentBenchmark.value)));
          return (
            <ListItemLink
              key={index}
              icon={e.icon}
              primary={e.name}
              to={e.url}
              fullName={e.fullName}
              handleClick={() => setDefaultTab(e)}
              disabled={!isEnabled}
            />
          );
        })}
      </List>
    </Drawer>
  );
}

export default SideNav;
