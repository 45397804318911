import React from 'react';
import PropTypes from 'prop-types';
import strings from '../constants';
import ReportTable from '../../../../components/PdfReport/ReportTable';
import {
  getAvoidedEmission,
  getEmissionBreakdown,
  getEmissionFootprint,
} from '../../../../helpers/report';
import ReportHorizontalBar from '../../../../components/PdfReport/Charts/ReportHorizontalBar';
import ReportStackedBar from '../../../../components/PdfReport/Charts/ReportStackedBar';

const emissionFootprintColumns = [
  { name: ' ', selector: 'type', right: false },
  { name: 'Scope 1+2', selector: 'sc12', right: true },
  { name: 'Scope 3', selector: 'sc3', right: true },
  { name: 'Scope 1+2+3', selector: 'sc123', right: true },
  {
    name: 'Avoided Emissions',
    selector: 'avoided_emissions',
    right: true,
  },
];

const emissionFootprintColumnsSc12 = [
  { name: ' ', selector: 'type', right: false },
  { name: 'Scope 1+2', selector: 'sc12', right: true },
  {
    name: 'Avoided Emissions',
    selector: 'avoided_emissions',
    right: true,
  },
];
const AvoidedEmission = ({ data }) => {
  const {
    portfolio,
    benchmark,
    filters: { emission },
  } = data;

  let emissionFootprint = { data: [], yAxisTitle: '', categories: [] };
  let emissionFootprintTable = [];
  let emissionBreakdown = { data: [], yAxisTitle: '', categories: [] };
  let avoidedEmission = { data: [], yAxisTitle: '', categories: [] };
  if (portfolio && benchmark) {
    emissionFootprint = getEmissionFootprint(
      portfolio.avoided_emissions,
      benchmark.avoided_emissions,
      emission,
    );

    emissionFootprintTable = [
      { type: 'Portfolio', ...portfolio?.avoided_emissions?.data },
      { type: 'Benchmark', ...benchmark?.avoided_emissions?.data },
    ];
    emissionBreakdown = getEmissionBreakdown(portfolio.avoided_emissions);

    avoidedEmission = getAvoidedEmission(portfolio.avoided_emissions);
  }

  return (
    <div
      className="pdf_page"
      data-footer="[2] Avoided emissions are emission reductions that occur outside of a product’s life cycle or value chain, but as a result of the use of that product (World Resources Institute)"
    >
      <h2>Avoided Emissions</h2>
      <div className="span-2">
        <p>{strings.avoidedEmission.text1}</p>
      </div>
      <div className="span-2">
        <h3>Avoided Emissions Footprint</h3>
        <ReportHorizontalBar
          chartKey="AVOIDED_EMISSIONS_TCFD"
          categories={emissionFootprint.categories}
          data={emissionFootprint.data}
          yAxisTitle={emissionFootprint.yAxisTitle}
          barWidth={6}
        />
        <div>
          <p>{strings.avoidedEmission.text3}</p>
          <p>{strings.avoidedEmission.text4}</p>
        </div>
        <div style={{ marginTop: 2, marginBottom: 20 }}>
          <ReportTable
            columns={
              emission === 'Sc12'
                ? emissionFootprintColumnsSc12
                : emissionFootprintColumns
            }
            data={emissionFootprintTable}
          />
        </div>
      </div>
      <div>
        <h3>Avoided Emissions Breakdown</h3>
        <ReportStackedBar
          categories={emissionBreakdown.categories}
          data={emissionBreakdown.data}
          yAxisTitle={emissionBreakdown.yAxisTitle}
          max={100}
        />
        <div>
          <p>{strings.avoidedEmission.text5}</p>
        </div>
      </div>
      <div>
        <h3>Reported Avoided Emissions</h3>
        <ReportStackedBar
          categories={avoidedEmission.categories}
          data={avoidedEmission.data}
          yAxisTitle={avoidedEmission.yAxisTitle}
          barWidth={15}
          max={100}
        />
        <div>
          <p>{strings.avoidedEmission.text6}</p>
        </div>
      </div>
    </div>
  );
};

AvoidedEmission.propTypes = {
  data: PropTypes.shape({
    portfolio: PropTypes.object,
    benchmark: PropTypes.object,
    auth: PropTypes.object,
    filters: PropTypes.object,
  }),
};
export default AvoidedEmission;
