import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const categoryContent = (
  <React.Fragment>
    <Grid container>
      <Grid item xs={3}>
        <Typography>Category 1 - </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>Purchased goods and services (CAPITAL GDS)</Typography>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={3}>
        <Typography>Category 3 - </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>
          Fuel and energy related activities (FUEL & ENERGY)
        </Typography>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={3}>
        <Typography>Category 4 - </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>
          Upstream transportation and distribution (UPSTREAM TRANS)
        </Typography>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={3}>
        <Typography>Category 5 - </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>Waste generated in operations (WASTE GEN)</Typography>
      </Grid>
    </Grid>

    <Grid container>
      <Grid item xs={3}>
        <Typography>Category 6 - </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>Business travel (BUSINESS TRAV)</Typography>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={3}>
        <Typography>Category 7 - </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>Employee commuting (EMPLOYEE COMMUTE)</Typography>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={3}>
        <Typography>Category 8 - </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>Upstream leased assets (UPSTREAM LEAA)</Typography>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={3}>
        <Typography>Category 9 - </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>
          Downstream transportation and distribution (DOWNSTREAM TRANS)
        </Typography>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={3}>
        <Typography>Category 10 - </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>Processing of sold products (PROCESSING SP)</Typography>
      </Grid>
    </Grid>

    <Grid container>
      <Grid item xs={3}>
        <Typography>Category 11 - </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>Use of sold products (USE OF SP)</Typography>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={3}>
        <Typography>Category 12 - </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>
          End of life treatment of sold products (END OF LIFE SP)
        </Typography>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={3}>
        <Typography>Category 13 - </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>Downstream leased assets (DOWNSTREAM LEAA)</Typography>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={3}>
        <Typography>Category 14 - </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>Franchises (FRA)</Typography>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={3}>
        <Typography>Category 15 - </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>Investments (INVST)</Typography>
      </Grid>
    </Grid>
  </React.Fragment>
);

export default categoryContent;
