import React from 'react';
import PropTypes from 'prop-types';
import BaseChart from './BaseChart';
import ChartProps from './ChartProps';

function SpiralChart({
  spiralXValue = ['Portfolio', 'Benchmark', 'Default Score'],
  unit = '',
  ...props
}) {
  const options = {
    chart: {
      inverted: true,
      polar: true,
    },
    xAxis: {
      tickInterval: 1,
      gridLineWidth: 1,
      labels: { enabled: false, allowOverlap: true },
    },
    yAxis: props.yAxis,
    tooltip: {
      formatter() {
        const xValue = spiralXValue[this.point.x] || 'Default Score';
        const yValue = this.point.options.y;
        return `<b>${xValue}:</b>${yValue} ${unit}`;
      },
    },
    series: [{ colorByPoint: true, data: props.data }],
    plotOptions: {
      series: {
        pointPadding: 0,
        groupPadding: 0,
        dataLabels: {
          enabled: true,
          format: `{point.options.y:.1f} ${unit}`,
          inside: true,
          allowOverlap: true,
          style: { textOutline: false },
        },
      },
    },
  };
  return (
    <BaseChart
      {...props}
      chartType="column"
      xAxisTitle={null}
      width={null}
      showLegend={false}
      options={options}
      xAxisLabelsEnabled={false}
    />
  );
}

SpiralChart.propTypes = {
  ...ChartProps,
  spiralXValue: PropTypes.array,
  unit: PropTypes.string,
};
export default SpiralChart;
