import React from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import TabPanel from '../../components/TabPanel';
import { sovereignAnalyticsTabs } from '../../util/tabs-config';
import PortfolioEmissions from './PortfolioEmissions/PortfolioEmissions';
import CountryEmissions from './CountryEmissions/CountryEmissions';
import HistoricalEmissions from './HistoricalEmissions/HistoricalEmissions';
import useTabValue from '../../hooks/useTabValueHook';

const SovereignAnalytics = () => {
  const [tabValue, onTabChange] = useTabValue();

  return (
    <>
      <Paper position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={onTabChange}
          indicatorColor="secondary"
          fullWidth="true"
          data-testid="SovereignTabs"
        >
          {sovereignAnalyticsTabs
            && sovereignAnalyticsTabs.map((e, i) => (
              <Tab
                key={i}
                label={e}
                style={{ fontSize: 11 }}
                data-testid={`tab-${e}`}
              />
            ))}
        </Tabs>
      </Paper>
      <TabPanel value={tabValue} index={0}>
        <PortfolioEmissions />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <CountryEmissions />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <HistoricalEmissions />
      </TabPanel>
    </>
  );
};

export default SovereignAnalytics;
