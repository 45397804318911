/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { Alert } from '@mui/material';
import DataTable from '../../../components/Table/DataTable';
import LineAndScatterChart from '../../../components/Charts/LineAndScatterChart';
import OnTrackWithTargets from '../../../components/OnTrackWithTargets';

const CompanyAlignmentDisplay = ({
  currentSector,
  chartData,
  tableData,
  yAxisTitle,
  handleSectorChange,
  trial,
  sectorList,
  currentCompany,
  handleCompanyChange,
  companyList,
  companyAlignmentCells,
  classes,
  emissionYear,
  message,
  impliedTemperature,
  includesTargets,
  onTrackColor,
  weight,
  includeHistorical,
}) => {
  let xAxisExtraData = {};
  if (includeHistorical) {
    // When company has historical information, then data will always start from 2015 (like Paris agreement year)
    const xIntervals = [Date.UTC('2015', '01', '01')];
    for (let year = 2016; year <= 2050; year += 2) {
      xIntervals.push(Date.UTC(year, '01', '01'));
    }
    xAxisExtraData = {
      softMin: Date.UTC('2015', '01', '01'),
      startOnTick: true,
      tickPositions: xIntervals,
    };
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item md={6} sm={12} xs={12}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            id="demo-simple-select-filled-label"
          >
            <InputLabel>Sector</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              label="Sector"
              value={currentSector}
              onChange={handleSectorChange}
              style={{ fontSize: 14 }}
            >
              {sectorList.length > 0
                && sectorList.map((sector) => (
                  <MenuItem key={sector} value={sector}>
                    {sector}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Company</InputLabel>
            <Select
              label="Company"
              value={currentCompany}
              onChange={handleCompanyChange}
              style={{ fontSize: 14 }}
            >
              {companyList.length > 0
                && companyList.map((company) => (
                  <MenuItem value={company.company_id} key={company.company_id}>
                    {company.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: '0 16px 16px 16px' }}
      >
        <Grid item container spacing={3} xs={8}>
          <Grid item>
            Implied Temperature Rise: <strong>{impliedTemperature}</strong>
          </Grid>
          <Grid item>
            Target: <strong>{includesTargets}</strong>
          </Grid>
          <Grid item>
            Weight: <strong>{weight}</strong>
          </Grid>
        </Grid>
        <OnTrackWithTargets backgroundColor={onTrackColor} source="company" />
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {message && <Alert severity="info">{message}</Alert>}
          <LineAndScatterChart
            data={chartData}
            title={`${
              companyList.find(
                ({ company_id }) => company_id === currentCompany,
              )?.name
            } Alignment`}
            yAxisTitle={yAxisTitle}
            emissionYear={emissionYear}
            customOptions={{
              xAxis: xAxisExtraData,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DataTable
            data={tableData}
            columns={companyAlignmentCells}
            tableHeading="COMPANY_PROFILE"
            isTrial={trial}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

CompanyAlignmentDisplay.propTypes = {
  chartData: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  yAxisTitle: PropTypes.string.isRequired,
  trial: PropTypes.bool.isRequired,
  currentSector: PropTypes.string.isRequired,
  handleSectorChange: PropTypes.func.isRequired,
  sectorList: PropTypes.array.isRequired,
  currentCompany: PropTypes.string.isRequired,
  handleCompanyChange: PropTypes.func.isRequired,
  companyList: PropTypes.array.isRequired,
  companyAlignmentCells: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  emissionYear: PropTypes.number,
  message: PropTypes.string,
  impliedTemperature: PropTypes.string,
  includesTargets: PropTypes.string,
  onTrackColor: PropTypes.string,
  weight: PropTypes.string,
  includeHistorical: PropTypes.bool,
};

export default CompanyAlignmentDisplay;
