import React from 'react';
import PropTypes from 'prop-types';
import ColumnChart from '../../../components/Charts/ColumnChart';

const AttributionDisplay = ({ chartData, categories }) => (
  <React.Fragment>
    <ColumnChart categories={categories} data={chartData} />
  </React.Fragment>
);

AttributionDisplay.propTypes = {
  chartData: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  chartKey: PropTypes.string.isRequired,
};

export default AttributionDisplay;
