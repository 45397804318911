import React from 'react';
import { Grid, makeStyles, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles(() => ({
  circle: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    backgroundColor: (props) => props.backgroundColor,
  },
  rightColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltipCircle: {
    display: 'inline-block',
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: (props) => props.backgroundColor,
    marginRight: 5,
  },
  tooltipFont: {
    fontSize: 12,
  },
  iconStyle: {
    color: 'grey',
    margin: '0 5px 10px 5px',
    fontSize: 20,
  },
}));

export const CircleBullet = ({ backgroundColor, isTooltip = false }) => {
  // On Track are only for colors: Orange, Green & Red
  const isCorrectBg = ['orange', 'green', 'red'].includes(backgroundColor);
  if (!isCorrectBg) return null;
  const classes = useStyles({
    backgroundColor,
  });
  if (isTooltip) return <span className={classes.tooltipCircle}></span>;
  return <div className={classes.circle}></div>;
};

CircleBullet.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  isTooltip: PropTypes.bool,
};

const OnTrackWithTargets = ({
  backgroundColor,
  source,
  addApostrophe = false,
}) => {
  const isCorrectBg = ['orange', 'green', 'red'].includes(backgroundColor);
  if (!isCorrectBg) return null;
  const classes = useStyles({
    backgroundColor,
  });
  const sourceWithApostrophe = addApostrophe ? `${source}'s` : source;
  const tooltipText = (
    <div className={classes.tooltipFont}>
      <p>
        {`This is based on a comparison of the ${sourceWithApostrophe} historic
        emissions trend with the ${sourceWithApostrophe} commitments until 2030
        (shown by the expected pathway). If the historic trend continues until
        2030 and this leads to`}
      </p>
      <p>
        <CircleBullet backgroundColor="green" isTooltip={true} />
        {`A reduction in emissions below the expected pathway, the ${source} is deemed "on track".`}
      </p>
      <p>
        <CircleBullet backgroundColor="orange" isTooltip={true} />
        {`Emissions that are within 20% of this, the ${source} is
        "partially on track".`}
      </p>
      <p>
        <CircleBullet backgroundColor="red" isTooltip={true} />
        {`Emissions that are more than 20% greater than this, the ${source} is "not on track" to achieve its stated targets.`}
      </p>
    </div>
  );
  return (
    <Grid item className={classes.rightColumn}>
      On Track With Targets
      <Tooltip title={tooltipText}>
        <HelpOutlineIcon className={classes.iconStyle} />
      </Tooltip>
      <CircleBullet backgroundColor={backgroundColor} />
    </Grid>
  );
};

OnTrackWithTargets.propTypes = {
  backgroundColor: PropTypes.string,
  source: PropTypes.string.isRequired,
  addApostrophe: PropTypes.bool,
};

export default OnTrackWithTargets;
