import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useGetPortCompanyTransitionScoreQuery } from '../../redux/apiHooks';
import SummaryDisplay from './SummaryDisplay';
import { formatNumber } from '../../util/formatNumber';
import {
  getCellValue,
  getTargetCoverageLabelOrColor,
  getTransitionScoreMetricColor,
} from '../../helpers';
import TitleTooltip from '../../components/Table/TitleTooltip';
import { tooltipTextData } from '../../constants';

const portfolioColumn = (showTrafficLights) => [
  {
    name: 'Portfolio Name',
    selector: 'name',
    right: false,
    wrap: true,
    cell: (row) => (
      <Tooltip title={`Portfolio Id : ${row.portfolio_id}`}>
        <div>{row.name}</div>
      </Tooltip>
    ),
  },
  {
    name: (
      <TitleTooltip
        title="Emissions Coverage (%)"
        tooltipText={tooltipTextData.emission_coverage}
      />
    ),
    selector: 'coverageWeightEmissions',
    right: true,
    wrap: true,
    cell: (row) => getCellValue({ value: row.coverageWeightEmissions, isNumber: true }),
  },
  {
    name: (
      <TitleTooltip
        title="Portfolio Value (1M)"
        tooltipText={tooltipTextData.portfolio_value}
      />
    ),
    selector: 'value',
    right: true,
    wrap: true,
    width: '140px',
    cell: (row) => getCellValue({ value: row.value / 1000000, isNumber: true }),
  },
  {
    name: (
      <TitleTooltip
        title="WACI by EVIC (Scope 1+2)"
        tooltipText={tooltipTextData.intensity_scope12}
      />
    ),
    selector: 'intensityWaciEvic',
    right: true,
    wrap: true,
    cell: (row) => getCellValue({ value: row.intensityWaciEvic, isNumber: true }),
  },
  {
    name: (
      <TitleTooltip
        title={`Disclosure Quality${showTrafficLights ? '' : ' (%)'}`}
        tooltipText={tooltipTextData.disclosure_quality}
      />
    ),
    selector: 'disclosureQualityScore',
    right: true,
    cell: (row) => getCellValue({
      value: row.disclosureQualityScore,
      showColor: showTrafficLights,
      color: row.disclosureQualityScoreColor,
    }),
  },
  {
    name: (
      <TitleTooltip
        title={`Historical Performance${showTrafficLights ? '' : ' (%)'}`}
        tooltipText={tooltipTextData.historical_performance}
      />
    ),
    selector: 'historicalQualityScore',
    right: true,
    cell: (row) => getCellValue({
      value: row.historicalQualityScore,
      showColor: showTrafficLights,
      color: row.historicalPerColor,
    }),
  },
  {
    name: (
      <TitleTooltip
        title={`Target Coverage${showTrafficLights ? '' : ' (%)'}`}
        tooltipText={tooltipTextData.target_coverage}
      />
    ),
    selector: 'targetsCovQualityScore',
    right: true,
    cell: (row) => getCellValue({
      value: row.targetsCovQualityScore,
      showColor: showTrafficLights,
      color: row.targetCoverageScoreColor,
    }),
  },
  {
    name: (
      <TitleTooltip title="ITR Score" tooltipText={tooltipTextData.Itr_score} />
    ),
    selector: 'itrScore',
    right: true,
    cell: (row) => getCellValue({
      value: row.itrScore,
      showColor: showTrafficLights,
      color: row.itrScoreColor,
    }),
  },
  {
    name: (
      <TitleTooltip
        title="On Track for Targets"
        tooltipText={tooltipTextData.on_track_for_targets}
      />
    ),
    selector: 'onTrackColor',
    right: true,
    cell: (row) => getCellValue({
      value: row.onTrackColor,
      showColor: true,
      color: row.onTrackColor,
    }),
  },
];

const companyColumns = (showTrafficLights) => [
  {
    name: 'Company',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Weight in Portfolio (%)',
    selector: 'weight',
    right: true,
    sortable: true,
    cell: (row) => getCellValue({ value: row.weight, isNumber: true }),
  },
  {
    name: (
      <TitleTooltip
        title="Absolute Emissions Scope 1+2"
        tooltipText={tooltipTextData.company_ab_s12}
      />
    ),
    selector: 'emissions_sc12',
    right: true,
    sortable: true,
    cell: (row) => getCellValue({ value: row.emissions_sc12, isNumber: true }),
  },
  {
    name: (
      <TitleTooltip
        title="Intensity Scope 1+2"
        tooltipText={tooltipTextData.company_is_s12}
      />
    ),
    selector: 'waci_evic_sc12',
    right: true,
    sortable: true,
    cell: (row) => getCellValue({
      value: row.waci_evic_sc12,
      isNumber: true,
    }),
  },
  {
    name: (
      <TitleTooltip
        title="Disclosure Quality"
        tooltipText={tooltipTextData.company_disclosure_category}
      />
    ),
    selector: 'disclosure_sc12',
    right: true,
    sortable: true,
    cell: (row) => getCellValue({
      value: row.disclosure_sc12,
      showColor: showTrafficLights,
      color: getTransitionScoreMetricColor(row.disclosure_sc12, 'disclosure'),
    }),
  },
  {
    name: (
      <TitleTooltip
        title={`Historical Performance${showTrafficLights ? '' : ' (%)'}`}
        tooltipText={tooltipTextData.company_historical_performance}
      />
    ),
    sortable: true,
    selector: 'historical_performance',
    right: true,
    cell: (row) => getCellValue({
      value: row.historical_performance,
      showColor: showTrafficLights,
      color: getTransitionScoreMetricColor(
        row.historical_performance,
        'historic_performace',
      ),
      isNumber: true,
    }),
  },
  {
    name: (
      <TitleTooltip
        title="Target Coverage"
        tooltipText={tooltipTextData.company_target_coverage}
      />
    ),
    selector: 'target_coverage',
    right: true,
    sortable: true,
    cell: (row) => getCellValue({
      value: getTargetCoverageLabelOrColor(
        row.target_coverage,
        row.has_sbti_approved_target,
      ),
      showColor: showTrafficLights,
      color: getTargetCoverageLabelOrColor(
        row.target_coverage,
        row.has_sbti_approved_target,
        true,
      ),
    }),
  },
  {
    name: (
      <TitleTooltip
        title="ITR Score"
        tooltipText={tooltipTextData.company_itr_score}
      />
    ),
    selector: 'itr_score',
    right: true,
    sortable: true,
    cell: (row) => getCellValue({
      value: row.itr_score,
      showColor: showTrafficLights,
      color: getTransitionScoreMetricColor(row.itr_score, 'itr_score'),
      isNumber: true,
    }),
  },
  {
    name: (
      <TitleTooltip
        title="On Track for Targets"
        tooltipText={tooltipTextData.company_on_track_for_targets}
      />
    ),
    selector: 'on_track_for_targets',
    right: true,
    sortable: true,
    cell: (row) => getCellValue({
      value: row.on_track_for_targets,
      showColor: true,
      color: row.on_track_for_targets,
    }),
  },
];

const Summary = () => {
  const [showTrafficLights, setShowTrafficLights] = useState(true);
  const { currentPortfolio, userInfo } = useSelector((state) => state.auth);
  const yearEmissions = userInfo.year && userInfo.year.emissions ? userInfo.year.emissions : '2019';
  const { data, isFetching, error } = useGetPortCompanyTransitionScoreQuery(
    currentPortfolio.value,
  );
  const onSwitchChange = () => setShowTrafficLights(!showTrafficLights);

  let coverageEmissions = 0;
  let weightEmissions = 0;
  let disclosureQualityScore = 0;
  let historicalQualityScore = 0;
  let targetsCovQualityScore = 0;
  let itrScore = 0;
  let onTrackColor = 'orange';
  let intensityWaciEvic;
  if (data?.portfolio?.coverage_emissions) {
    data?.portfolio?.coverage_emissions.forEach((emission) => {
      if (emission.year === yearEmissions) {
        coverageEmissions = emission.coverage;
        weightEmissions = emission.weight_coverage;
      }
    });
  }
  if (data?.portfolio?.transition_scores) {
    data.portfolio.transition_scores.forEach((transitions) => {
      if (transitions.year === yearEmissions) {
        disclosureQualityScore = transitions.disclosure_quality_score || 0;
        historicalQualityScore = transitions.historic_performance || 0;
        targetsCovQualityScore = transitions.target_coverage || 0;
        itrScore = transitions.itr_score || 0;
        onTrackColor = transitions.on_track_for_targets;
        intensityWaciEvic = transitions.intensity_waci_evic;
      }
    });
  }
  const portfolioData = data
    ? [
      {
        ...data.portfolio,
        version: data.portfolio.version,
        coverageEmissions,
        coverageWeightEmissions: weightEmissions,
        disclosureQualityScore: formatNumber(disclosureQualityScore),
        historicalQualityScore: formatNumber(historicalQualityScore),
        targetsCovQualityScore: formatNumber(targetsCovQualityScore),
        itrScore: formatNumber(itrScore),
        disclosureQualityScoreColor: getTransitionScoreMetricColor(
          disclosureQualityScore,
        ),
        historicalPerColor: getTransitionScoreMetricColor(
          historicalQualityScore,
          'historic_performace',
        ),
        targetCoverageScoreColor: getTransitionScoreMetricColor(
          targetsCovQualityScore,
        ),
        itrScoreColor: getTransitionScoreMetricColor(itrScore, 'itr_score'),
        onTrackColor,
        intensityWaciEvic,
      },
    ]
    : [];
  return (
    <SummaryDisplay
      error={error}
      loading={isFetching}
      data={data}
      onSwitchChange={onSwitchChange}
      showTrafficLights={showTrafficLights}
      portfolioData={portfolioData}
      portfolioColumns={portfolioColumn(showTrafficLights)}
      companiesData={data?.company_transition_scores || []}
      companiesColumns={companyColumns(showTrafficLights)}
    />
  );
};

export default Summary;
