import React from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import TabPanel from '../../components/TabPanel';
import { netZeroTabs } from '../../util/tabs-config';
import DecarbonisationMonitor from './DecarbonisationMonitor/DecarbonisationMonitor';
import TargetSetting from './TargetSetting/TargetSetting';
import CompanyAlignment from './CompanyAlignment/CompanyAlignment';
import PortAlignmentV2 from './PortAlignment/PortAlignmentV2';
import useTabValue from '../../hooks/useTabValueHook';
import SectorSubplot from './SectorSubplot/SectorSubplot';
import {
  DECARBONATION_MONITOR,
  NET_ZERO_ANALYSIS,
  NET_ZERO_COMPANY_ALIGNMENT,
  NET_ZERO_TARGET_SETTINGS,
  SECTOR_SUBPLOT,
} from '../../components/FilterSection/constants';

function NetZeroAnalysis() {
  const [tabValue, onTabChange] = useTabValue();

  return (
    <>
      <Paper position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={onTabChange}
          indicatorColor="secondary"
          data-testid="NetZeroAnalysisTabs"
        >
          {netZeroTabs?.map((e) => (
            <Tab
              key={e}
              label={e}
              style={{ fontSize: 11 }}
              data-testid={`tab-${e}`}
            />
          ))}
        </Tabs>
      </Paper>
      <TabPanel value={tabValue} index={DECARBONATION_MONITOR}>
        <DecarbonisationMonitor />
      </TabPanel>
      <TabPanel value={tabValue} index={NET_ZERO_ANALYSIS}>
        <PortAlignmentV2 />
      </TabPanel>
      <TabPanel value={tabValue} index={NET_ZERO_TARGET_SETTINGS}>
        <TargetSetting />
      </TabPanel>
      <TabPanel value={tabValue} index={NET_ZERO_COMPANY_ALIGNMENT}>
        <CompanyAlignment />
      </TabPanel>
      <TabPanel value={tabValue} index={SECTOR_SUBPLOT}>
        <SectorSubplot />
      </TabPanel>
    </>
  );
}
export default NetZeroAnalysis;
