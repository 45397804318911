import BaseRequest from '../baseRequest';

export default class Alignment extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem, currentFundsPortfolio } = props;
    const { portScenario, footprintMetric, emission } = filterItem;
    this.portfolio_id = currentFundsPortfolio.value;
    this.benchmark_id = undefined;
    this.version_benchmark = undefined;
    this.scenario = portScenario;
    this.asset_type = ['Eq', 'CB'];
    this.market_value = 'MarketCapDebt';
    this.footprint = footprintMetric === 'CarbIntensityMarketVal'
      || footprintMetric === 'CarbIntensityRev'
      || footprintMetric === 'TotalCarbEmis'
      ? 'WeightAvgRev'
      : footprintMetric;
    this.inference = 'Avg';
    this.scope = emission;
  }
}
