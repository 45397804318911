import apiSlice, { getQuery } from '../apiSlice';

export const riskContributorApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCarbonRiskContributors: builder.query({
      query: getQuery.post('portfolio_carbon_risk/portfolio_risk_contributors'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
  }),
});

export const {
  useGetCarbonRiskContributorsQuery,
  useLazyGetCarbonRiskContributorsQuery,
} = riskContributorApiSlice;
