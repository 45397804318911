import BaseRequest from '../baseRequest';

export default class Footprint extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem, currentFundsPortfolio } = props;
    const {
      footprintMetric, sector, marketValue, assetClass,
    } = filterItem;
    this.portfolio_id = currentFundsPortfolio.value;
    this.benchmark_id = undefined;
    this.version_benchmark = undefined;
    this.footprint = footprintMetric;
    this.sector = sector;
    this.market_value = marketValue;
    this.asset_type = assetClass === 'EqCB' ? ['Eq', 'CB'] : assetClass;
  }
}
