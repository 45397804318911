import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get, isEmpty } from 'lodash';
import { companyAlignmentCells } from '../../../util/TableHeadConfig';
import { LoadingHOC } from '../../../util/LoadingHOC';
import CompanyAlignmentDisplay from './CompanyAlignmentDisplay';
import { EMISSION_YEAR, getLegendName } from '../constants';
import { getChartDataFromObject } from '../../../helpers';
import { setCompany, setSector } from '../../../redux/reducers/authReducer';
import {
  CompanyAlignment as CompanyAlignmentModel,
  CompanyProfile as CompanyProfileModel,
  CompanyTableAlignment as CompanyTableAlignmentModel,
} from '../../../models/netZero/company/index';
import useGetPayload from '../../../hooks/useGetPayload';
import {
  useGetNetZeroSectorCompaniesQuery,
  useLazyGetNetZeroCompaniesDataQuery,
  useLazyGetNetZeroCompaniesTableDataQuery,
} from '../../../redux/apiHooks';

const CompanyAlignmentWithLoading = LoadingHOC(CompanyAlignmentDisplay);

const useStyles = makeStyles(() => ({
  formControl: {
    width: 300,
    margin: 15,
  },
}));

const defaultChartData = {
  type: 'line',
  marker: {
    enabled: false,
  },
  enableMouseTracking: true,
  // add: name
  // add: data
};
const USE_CACHE_VALUE = true;

const CompanyAlignment = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [chartData, setChartData] = useState([]);
  const [minEmissionYear, setMinEmissionYear] = useState(null);
  // Queries : Get company data
  const { payload: companiesPayload } = useGetPayload(CompanyProfileModel);
  const {
    data: allCompaniesData,
    isFetching: isCompaniesFetching,
    error: companiesError,
  } = useGetNetZeroSectorCompaniesQuery(companiesPayload);
  // Queries : Get Alignment Data
  const { payload: dataPayload } = useGetPayload(CompanyAlignmentModel);
  const [
    getAlignmentData,
    { data: alignmentData, isFetching: isDataFetching, error: dataError },
  ] = useLazyGetNetZeroCompaniesDataQuery();
  // Queries : Get Alignment Table Data
  const {
    auth,
    filters,
    payload: tablePayload,
  } = useGetPayload(CompanyTableAlignmentModel);
  const [
    getAlignmentTable,
    {
      data: alignmentTableData,
      isFetching: isTableFetching,
      error: tableError,
    },
  ] = useLazyGetNetZeroCompaniesTableDataQuery();
  // Destructuring
  const { userInfo, currentCompany, currentSector } = auth;
  const { includeHistorical, warmingScenario } = filters;

  const fetchCompanyData = ({ sector, company }) => {
    getAlignmentData(
      {
        ...dataPayload,
        isin: company,
        industry: sector,
      },
      USE_CACHE_VALUE,
    );
    getAlignmentTable(
      {
        ...tablePayload,
        industry: sector,
      },
      USE_CACHE_VALUE,
    );
  };

  useEffect(() => {
    if (allCompaniesData) {
      const { sectors, companiesData } = allCompaniesData;
      let industry = currentSector;
      if (!sectors.includes(industry)) {
        // eslint-disable-next-line prefer-destructuring
        industry = sectors[0];
        dispatch(setSector(industry));
      }
      let isin = currentCompany;
      if (!companiesData[industry].find((item) => item.company_id === isin)) {
        isin = companiesData[industry][0].company_id;
        dispatch(setCompany(isin));
      }
      fetchCompanyData({
        company: isin,
        sector: industry,
      });
    }
  }, [allCompaniesData, filters]);

  const getData = () => {
    const data = alignmentData.alignment?.data || {};
    // Populating Data
    const newChartData = [];
    // Adding Historical Data if available
    if (includeHistorical && !isEmpty(data.historical_emissions)) {
      let tempMinEmissionYear = null;
      Object.entries(data.historical_emissions).forEach(([year]) => {
        if (
          tempMinEmissionYear === null
          || tempMinEmissionYear > parseInt(year, 10)
        ) {
          tempMinEmissionYear = parseInt(year, 10);
        }
      });
      newChartData.push({
        type: 'scatter',
        name: 'Historical Emissions (Reported)',
        data: getChartDataFromObject(data.historical_emissions),
        marker: {
          radius: 4,
          symbol: 'circle',
        },
      });
      setMinEmissionYear(tempMinEmissionYear - 1);
    } else {
      setMinEmissionYear(null);
    }
    // Adding Selected Scenario Data
    warmingScenario.forEach((key) => {
      if (data[key]) {
        newChartData.push({
          ...defaultChartData,
          name: getLegendName(key),
          data: getChartDataFromObject(data[key]),
        });
      }
    });
    // Adding Expected Data
    newChartData.push({
      ...defaultChartData,
      name: 'Expected Trajectory',
      data: getChartDataFromObject(data.expected),
    });
    setChartData([...newChartData]);
  };

  useEffect(() => {
    if (!isEmpty(alignmentData) && Array.isArray(warmingScenario)) getData();
  }, [alignmentData, includeHistorical, warmingScenario]);

  const handleSectorChange = async (e) => {
    const sector = e.target.value;
    dispatch(setSector(sector));
    const companyId = allCompaniesData.companiesData[sector][0].company_id;
    dispatch(setCompany(companyId));
    fetchCompanyData({
      company: companyId,
      sector,
    });
  };

  const handleCompanyChange = async (event) => {
    const companyId = event.target.value;
    dispatch(setCompany(companyId));
    fetchCompanyData({
      company: companyId,
      sector: currentSector,
    });
  };

  const impliedTemperature = alignmentData?.implied_temperature;
  const onTrackColor = alignmentData?.on_track_indicator;
  const includesTargets = alignmentData?.includes_targets;
  const weights = alignmentTableData?.[0]?.weight;
  const yAxisTitle = alignmentData?.alignment?.metadata?.units || '';
  const trial = get(userInfo, 'trial', false);
  const emissionYear = userInfo?.year?.emissions
    ? userInfo.year.emissions - 1
    : EMISSION_YEAR;
  const tableData = alignmentTableData || [];
  const sectorList = allCompaniesData?.sectors || [];
  const companyList = allCompaniesData?.companiesData?.[currentSector] || [];
  const loading = isCompaniesFetching || isDataFetching || isTableFetching;
  const error = companiesError || dataError || tableError;
  return (
    <React.Fragment>
      <Box>
        <CompanyAlignmentWithLoading
          loading={loading}
          error={error}
          currentSector={currentSector}
          chartData={chartData}
          tableData={tableData}
          yAxisTitle={yAxisTitle}
          handleSectorChange={handleSectorChange}
          trial={trial}
          sectorList={sectorList}
          currentCompany={currentCompany}
          handleCompanyChange={handleCompanyChange}
          companyList={companyList}
          companyAlignmentCells={companyAlignmentCells}
          classes={classes}
          emissionYear={minEmissionYear || emissionYear || undefined}
          includeHistorical={includeHistorical}
          message={includeHistorical && alignmentData?.message}
          impliedTemperature={
            impliedTemperature ? `${impliedTemperature}°C` : 'N/A'
          }
          includesTargets={
            includesTargets === true
              ? 'Yes'
              : includesTargets === false
                ? 'No'
                : 'N/A'
          }
          onTrackColor={onTrackColor}
          weight={weights ? `${weights}%` : 'N/A'}
        />
      </Box>
    </React.Fragment>
  );
};

export default CompanyAlignment;
