import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import PerformanceAttributionDisplay from './PerformanceAttributionDisplay';
import { LoadingHOC } from '../../../util/LoadingHOC';
import useGetPayload from '../../../hooks/useGetPayload';
import PerformanceAttributionModel from '../../../models/optimization/performanceAttribution';
import { useGetOptimizationPerformanceAttributionQuery } from '../../../redux/apiHooks';

const PerformanceAttributionWithLoading = LoadingHOC(
  PerformanceAttributionDisplay,
);

const PerformanceAttribution = () => {
  const [chartData, setChartData] = useState([]);
  const [categories, setCategories] = useState([]);

  const { payload } = useGetPayload(PerformanceAttributionModel);
  const {
    data: perfAttribData,
    isFetching,
    error,
  } = useGetOptimizationPerformanceAttributionQuery(payload);

  const getData = () => {
    const attrChartData = [];
    let Attrcategories = [];
    const perfAttributions = perfAttribData.data;

    Object.keys(perfAttributions).forEach((perfAttribution) => {
      let values = [];
      Object.keys(perfAttributions[perfAttribution]).forEach(() => {
        values = Object.values(perfAttributions[perfAttribution]);
        Attrcategories = Object.keys(perfAttributions[perfAttribution]);
      });
      attrChartData.push({
        name: perfAttribution.replaceAll('_', ' '),
        data: values,
      });
    });
    setChartData(attrChartData);
    setCategories(Attrcategories);
  };

  useEffect(() => {
    if (!isEmpty(perfAttribData)) getData();
  }, [perfAttribData]);

  return (
    <PerformanceAttributionWithLoading
      loading={isFetching}
      error={error}
      categories={categories}
      data={chartData}
    />
  );
};
export default PerformanceAttribution;
