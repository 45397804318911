import React from 'react';
import PortfolioEmissionDisplay from './PortfolioEmissionDisplay';
import { LoadingHOC } from '../../../util/LoadingHOC';
import usePcafScreenData from '../../../hooks/usePcafScreenData';
import PortfolioEmissionPcaf from '../../../models/pcaf/portfolioEmissionPcaf';
import { useGetEmissionPcafQuery } from '../../../redux/apiHooks';
import useGetPayload from '../../../hooks/useGetPayload';

const PortfolioEmissionWithLoading = LoadingHOC(PortfolioEmissionDisplay);

export default function PortfolioEmission() {
  const { payload } = useGetPayload(PortfolioEmissionPcaf);
  const { data, isFetching, error } = useGetEmissionPcafQuery(payload);
  const {
    emission,
    // portfolioEmissionPcaf,
    portfolioFootprintReported,
    sectorCategories,
    sectorCategoriesData,
    reportedInferredData,
    categories,
    portfolioFootprint,
    pcafScoreDisclosureCategoryData,
    tempBaryAxisTitle,
    updatePortfolioFootprintReported,
  } = usePcafScreenData({ data });

  return (
    <PortfolioEmissionWithLoading
      error={error}
      loading={isFetching}
      selectValue={portfolioFootprint}
      categoriesStack={categories}
      dataStackedBar={pcafScoreDisclosureCategoryData}
      title={`Portfolio Footprint (${portfolioFootprint})`}
      yAxisTitle={tempBaryAxisTitle}
      onChange={(e) => {
        updatePortfolioFootprintReported(e.target.value);
      }}
      selectValue2={portfolioFootprintReported}
      dataStackedBar2={reportedInferredData}
      title2={`Portfolio Footprint (${portfolioFootprintReported})`}
      dynamicTitle2={`Portfolio Footprint (${portfolioFootprintReported})`}
      categories={sectorCategories}
      dataColumnChart={sectorCategoriesData}
      title3={`Sector Contribution  ${
        emission === 'Sc12' ? 'Scope 1+2' : 'Scope 1+2+3'
      }`}
      pcafData={data}
    />
  );
}
