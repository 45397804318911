import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/es/constants';
import sortArrayByKey from '../../util/sortArrayByKey';

const initialState = {
  reportData: {
    data: {},
    error: '',
  },
  tempScoreData: {
    data: [],
  },
  tcfdReport: {
    portfolio: null,
    benchmark: null,
    tempScore: null,
    error: '',
  },
  generalReport: {
    portfolio: null,
    benchmark: null,
    tempScore: null,
    error: '',
  },
  benchmarkReport: {
    singleIsin: null,
    benchmark: null,
    error: '',
    company: '',
    companyList: [],
    sector: '',
    sectorList: [],
    companyResponse: [],
  },
  loader: false,
};

export const reportSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    [PURGE]: () => initialState,
    tcfdReportSuccess: (state, action) => {
      if (action.payload.reportData.status === 'fulfilled') {
        state.tcfdReport.portfolio = action.payload.reportData.value.portfolio;
        state.tcfdReport.benchmark = action.payload.reportData.value.benchmark;
      } else {
        state.tcfdReport.error = action.payload.reportData.reason;
      }

      if (action.payload.tempScore.status === 'fulfilled') {
        state.tcfdReport.tempScore = action.payload.tempScore.value;
      } else {
        state.tcfdReport.error = action.payload.reportData.reason;
      }
    },
    generalReportSuccess: (state, action) => {
      if (action.payload.reportData.status === 'fulfilled') {
        state.generalReport.portfolio = action.payload.reportData.value.portfolio;
        state.generalReport.benchmark = action.payload.reportData.value.benchmark;
      } else {
        state.generalReport.error = action.payload.reportData.reason;
      }

      if (action.payload.tempScore.status === 'fulfilled') {
        state.generalReport.tempScore = action.payload.tempScore.value;
      } else {
        state.generalReport.error = action.payload.reportData.reason;
      }
    },
    benchmarkReportSuccess: (state, action) => {
      state.benchmarkReport.singleIsin = action.payload.reportData.single_isin;
      state.benchmarkReport.benchmark = action.payload.reportData.benchmark;
    },
    getReportDataFailed: (state, action) => {
      state.tcfdReport.portfolio = null;
      state.tcfdReport.benchmark = null;
      state.tcfdReport.tempScore = null;
      state.tcfdReport.error = action.payload;
    },
    onReportCompanySuccess: (state, action) => {
      const currentSectorList = Object.keys(action.payload).sort();
      let currentSector = state.benchmarkReport.sector;
      let currentCompany = state.benchmarkReport.company;
      if (
        !state.benchmarkReport.sector
        || !currentSectorList.includes(currentSector)
      ) {
        // eslint-disable-next-line prefer-destructuring
        currentSector = currentSectorList[0];
      }
      const currentCompaniesList = action.payload[currentSector].sort(
        sortArrayByKey('name'),
      );
      if (
        !state.benchmarkReport.company
        || currentCompaniesList.findIndex(
          (company) => company.company_id === currentCompany,
        ) === -1
      ) {
        currentCompany = currentCompaniesList[0].company_id;
      }

      state.benchmarkReport.companyResponse = action.payload;
      state.benchmarkReport.sectorList = currentSectorList;
      state.benchmarkReport.sector = currentSector;
      state.benchmarkReport.companyList = currentCompaniesList;
      state.benchmarkReport.company = currentCompany;
    },
    setBenchmarkCompany: (state, action) => {
      state.benchmarkReport.company = action.payload;
    },
    setBenchmarkSector: (state, action) => {
      state.benchmarkReport.sector = action.payload;

      const currentCompaniesList = state.benchmarkReport.companyResponse[
        action.payload
      ].sort(sortArrayByKey('name'));

      const currentCompany = currentCompaniesList[0].company_id;
      state.benchmarkReport.companyList = currentCompaniesList;
      state.benchmarkReport.company = currentCompany;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
  },
});

export const {
  tcfdReportSuccess,
  generalReportSuccess,
  benchmarkReportSuccess,
  getReportDataFailed,
  onReportCompanySuccess,
  setBenchmarkCompany,
  setBenchmarkSector,
  setLoader,
} = reportSlice.actions;
export default reportSlice.reducer;
