/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import RoundSignal from '../../../../components/PdfReport/RoundSignal';
import ReportTable from '../../../../components/PdfReport/ReportTable';
import ReportLineAndScatterChart from '../../../../components/PdfReport/Charts/ReportLineAndScatterChart';
import {
  getCompanyBenchmarkComparisonTable,
  getNetZeroExpectedAlignment,
  getNetZeroExpectedAlignmentTable,
} from '../../../../helpers/report';

const EMISSION_YEAR = 2019;
const netZeroExpectedAlignmentColumns = [
  { name: ' ', selector: 'name', right: false },
  {
    name: '2015',
    selector: 2015,
    right: true,
    noDataText: '-',
  },
  {
    name: '2020',
    selector: 2020,
    right: true,
    noDataText: '-',
  },
  {
    name: '2030',
    selector: 2030,
    right: true,
    noDataText: '-',
  },
  {
    name: '2050',
    selector: 2050,
    right: true,
    noDataText: '-',
  },
];

const companyBenchmarkComparisonColumns = [
  {
    name: ' ',
    selector: 'name',
    right: false,
    width: '30%',
  },
  {
    name: 'ITR(°C)',
    selector: 'implied_temp',
    right: true,
    width: '20%',
  },
  {
    name: 'Companies with Targets (%)',
    selector: 'percent_of_companies_with_targets',
    right: true,
    width: '20%',
  },
  {
    name: 'On Track with Target',
    selector: 'on_track_indicator',
    right: true,
    width: '20%',
    render: ({ data }) => (
      <div style={{ marginLeft: 75 }}>
        <RoundSignal size={12} color={data} />
      </div>
    ),
  },
];

const companyItrColumns = [
  {
    name: 'Company',
    selector: 'company_name',
    right: false,
    width: '30%',
  },
  {
    name: 'ITR(°C)',
    selector: 'implied_temperature_rise',
    right: true,
    width: '20%',
  },
  {
    name: 'Target',
    selector: 'targets',
    right: true,
    render: ({ data }) => (data ? 'Yes' : 'No'),
    width: '20%',
  },
  {
    name: 'On Track with Target',
    selector: 'on_track_indicator',
    right: true,
    width: '20%',
    render: ({ data }) => (
      <div style={{ marginLeft: 40 }}>
        <RoundSignal size={12} color={data} />
      </div>
    ),
  },
];

function ForwardLookingAlignment({ data }) {
  const { singleIsin, benchmark } = data;
  const corporate = singleIsin?.name;
  const industryBenchmark = singleIsin?.sub_industry_group;
  const { userInfo } = useSelector((state) => state.auth);
  const emissionYear = userInfo?.year?.emissions
    ? userInfo.year.emissions - 1
    : EMISSION_YEAR;
  let netZeroExpectedAlignment = { data: [], emissionYear, yAxisTitle: '' };
  let netZeroExpectedAlignmentTable = [];
  let companyBenchmarkComparisonTable = [];
  let isHistoricalDataMissing = true;

  if (singleIsin && benchmark) {
    netZeroExpectedAlignment = getNetZeroExpectedAlignment(
      singleIsin.portfolio_alignment,
      emissionYear,
    );
    isHistoricalDataMissing = isEmpty(
      singleIsin.portfolio_alignment?.alignment?.data?.historical_emissions,
    );
    netZeroExpectedAlignmentTable = getNetZeroExpectedAlignmentTable(
      singleIsin.portfolio_alignment,
    );
    companyBenchmarkComparisonTable = getCompanyBenchmarkComparisonTable(
      singleIsin,
      benchmark,
    );
  }
  return (
    <div className="pdf_page">
      <h2 className="span-2">Forward Looking Alignment</h2>
      <div className="span-2">
        <h3>{corporate} On Track Indicator</h3>
        <div
          style={{
            fontSize: 14,
            display: 'grid',
            gridTemplateColumns: 'repeat(3, auto) 60%',
            gap: 5,
          }}
        >
          <span>
            <b>ITR: </b>
            {singleIsin?.portfolio_alignment?.implied_temp} °C
          </span>
          <span>
            <b>Targets: </b>
            {singleIsin?.has_targets ? 'Yes' : 'No'}
          </span>
          <span>
            <b>Weight: </b>100%
          </span>
          {!isHistoricalDataMissing && (
            <span
              style={{
                justifySelf: 'right',
                display: 'flex',
                alignItems: 'center',
                gap: 10,
              }}
            >
              <b>On Track with Targets </b>
              <RoundSignal
                size={15}
                color={singleIsin?.portfolio_alignment?.on_track_indicator}
              />
            </span>
          )}
        </div>
      </div>
      <div className="span-2">
        <h3>{corporate} Estimated Trajectory (Net Zero Alignment) </h3>
        <ReportLineAndScatterChart
          data={netZeroExpectedAlignment.data}
          emissionYear={netZeroExpectedAlignment.emissionYear}
          yAxisTitle={netZeroExpectedAlignment.yAxisTitle}
          width={500}
        />
        <div>
          <p>
            {corporate} has an overall Implied Temperature Rise (ITR) score of{' '}
            {singleIsin?.portfolio_alignment?.implied_temp}°C. This score is
            based on the cumulative estimated emissions of the company. This is
            calculated using company-level emission reduction targets and
            historical emissions, i.e. it includes actions to date as well as
            commitments.{' '}
          </p>
          <p>
            The Estimated Trajectory (Net Zero Alignment) chart displays{' '}
            {corporate}'s historical emissions, expected trajectory and Net Zero
            2050 aligned pathway, comparative to its {industryBenchmark} peers'
            historical emissions, expected trajectory and Net Zero 2050 aligned
            pathway. The chart includes Scopes 1+2+3 emissions.
          </p>
          {isHistoricalDataMissing && (
            <p>
              Reported historical emissions data is not available for{' '}
              {singleIsin?.name}.
            </p>
          )}
        </div>

        <ReportTable
          columns={netZeroExpectedAlignmentColumns}
          data={netZeroExpectedAlignmentTable}
        />
      </div>
      <div className="span-2">
        <h3 className="table-heading">
          {corporate} & {industryBenchmark} Comparison
        </h3>
        <ReportTable
          columns={companyBenchmarkComparisonColumns}
          data={companyBenchmarkComparisonTable}
          width="fit-content"
        />
      </div>

      <div className="span-2">
        <h3 className="table-heading">
          Top 10 {industryBenchmark} Companies (ITR - Highest & Lowest)
        </h3>
        <ReportTable
          columns={companyItrColumns}
          data={benchmark?.company_itr || []}
          width="fit-content"
        />
      </div>
    </div>
  );
}

export default ForwardLookingAlignment;
