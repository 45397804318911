/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from '@material-ui/core';

import Introduction from './Pages/Introduction';
import EmissionsFootprint from './Pages/EmissionsFootprint';
import FossilAndAssets from './Pages/FossilAndAssets';
import Alignment from './Pages/Alignment';
import AvoidedEmission from './Pages/AvoidedEmissions';
import TopIntensities from './Pages/TopIntensities';
import Disclaimer from './Pages/Disclaimer';
import Pdf from '../../../components/PdfReport/Pdf';

const theme = 'ICE light';
function TCFDDisplay({
  onDownloadClick, buttonDisabled, downloading, data,
}) {
  return (
    <div style={{ width: 755 }}>
      <div style={{ margin: '5px 10px' }}>
        <Button
          type="button"
          onClick={onDownloadClick}
          disabled={buttonDisabled}
        >
          Download Report
        </Button>
      </div>
      <Pdf downloading={downloading}>
        <Introduction data={data} />
        <EmissionsFootprint data={data} />
        <FossilAndAssets theme={theme} data={data} />
        <Alignment theme={theme} data={data} />
        <AvoidedEmission data={data} />
        <TopIntensities data={data} />
        <Disclaimer />
      </Pdf>
    </div>
  );
}

export default TCFDDisplay;
