/* eslint-disable */

import React from 'react';
import HeatmapChart from '../../../components/Charts/HeatmapChart';
import DataTable from '../../../components/Table/DataTable';
import {
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Typography,
  MenuItem,
} from '@material-ui/core';

const SectoralScope3HeatmapDisplay = props => (
  <React.Fragment>
    <Grid container>
      <Grid item xs={4}>
        <FormControl variant="outlined" className={props.className}>
          <InputLabel>Select Sector</InputLabel>
          <Select
            label="Select Sector"
            value={props.selectValue}
            onChange={props.selectOnChange}
            style={{ fontSize: 14 }}
          >
            {props.yCategories.length > 0 &&
              props.yCategories.map((sector, id) => (
                <MenuItem key={id} value={sector}>
                  {sector}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
    <HeatmapChart
      data={props.data}
      yAxisCategories={props.yAxisCategories}
      xAxisCategories={props.xAxisCategories}
      title="Supply and Value Chain Scope 3 Materiality"
      height={250}
    />
    <DataTable
      data={props.dataTableData}
      columns={props.columns}
      tableHeading={props.tableHeading}
      isScroll={props.isScroll}
    />
    <Link onClick={props.onDialogHandler} style={{ marginTop: 20 }}>
      Explore the scope 3 categories classification
    </Link>
    <Typography>
      This module provides a granular breakdown of carbon risk exposure within
      sectoral supply and value chains. This can be reviewed from the sectoral
      and portfolio perspectives Sector Analysis: Each Sector is scaled by the
      maximum Scope 3 or Scope 1+2 category by carbon intensity. Portfolio
      Analysis: All Scope 3 and Scope 1+2 carbon intensity figures are scaled by
      the maximum category for the portfolio as a whole.
    </Typography>
    <Dialog open={props.dialogOpen} keepMounted fullWidth={true}>
      <DialogTitle>Category Classification</DialogTitle>
      <DialogContent>{props.dialogContent}</DialogContent>
      <DialogActions>
        <Button onClick={props.onDialogHandler}>Cancel</Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
);

export default SectoralScope3HeatmapDisplay;
