/* eslint-disable react/prop-types */

import React from 'react';
import HorizontalBar from '../../../components/Charts/HorizontalBar';

const CarbonEmissionDisplay = (props) => (
  <React.Fragment>
    <HorizontalBar
      categories={props.categories}
      data={props.horizontalBarData_1}
      title="Sector Intensity"
      yAxisTitle={props.yAxisTitle}
    />
    <HorizontalBar
      categories={props.categories}
      data={props.horizontalBarData_2}
      title="Sector Contribution"
      yAxisTitle={props.yAxisTitle}
    />
    <HorizontalBar
      categories={props.categories}
      data={props.horizontalBarData_3}
      title="Sector Weights"
      yAxisTitle="Sector Weight (%)"
      tooltipUnit="%"
    />
  </React.Fragment>
);

export default CarbonEmissionDisplay;
