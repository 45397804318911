import React, { useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import { Backdrop } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
// import { verifySsoCredentials } from '../../services/auth';
import { useLazyUseSsoSignInQuery } from '../../redux/apiHooks';
import Header from '../../components/Header';

const SsoLogin = () => {
  const isSsoLoginPath = window.location.pathname === '/wb';
  const credentials = isSsoLoginPath && window.location.search.split('?credentials=')[1];
  const [verifySSO, { isFetching }] = useLazyUseSsoSignInQuery();
  // const dispatch = useDispatch();
  const history = useHistory();
  // const loading = useSelector((state) => state.auth.loading);

  // const verifyLogin = () => {
  //   dispatch(verifySsoCredentials(credentials));
  // };

  useEffect(() => {
    if (isSsoLoginPath) {
      verifySSO({ credentials });
    }
  }, []);
  return (
    <React.Fragment>
      <Header history={history} />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isFetching}
      >
        <h1>Verifying login.....</h1>
      </Backdrop>
    </React.Fragment>
  );
};
export default SsoLogin;
