import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, makeStyles, Tooltip, Typography,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { LoadingHOC } from '../../../util/LoadingHOC';
import LineChart from '../../../components/Charts/LineChart';
import colorSchema from '../../../Themes/config';
import useGetTheme from '../../../hooks/useGetTheme';

const useStyles = makeStyles(() => ({
  container: {
    padding: 16,
  },
  title: {
    marginBottom: 16,
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  legendContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 5,
    flex: 1,
  },
  legend: {
    display: 'flex',
    alignItems: 'center',
  },
  legendBar: {
    width: 16,
    height: 2,
    marginRight: 5,
  },
  legendText: {
    width: 150,
    fontSize: '12px',
    textAlign: 'left',
  },
  iconStyle: {
    color: 'grey',
    margin: '0 5px 10px 5px',
    fontSize: 20,
  },
  topContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
}));

const tooltipText = 'The subplot charts show historical and aligned emissions of the portfolio on a sector or regional level, leveraging the same methodology as the Portfolio tab. Each subplot displays a different sector or region with identical axes labels and legends across the subplots. The sector-level ITR scores are displayed alongside the subplot headings. <br /> For NGFS phase 4 scenarios, the pathways consider regional as well as sectoral nuances, whereas for NGFS phase 3 and SSP scenarios, global-level sectoral pathways are used.';

const SectorSubplotDisplay = ({
  data,
  sectorKeys,
  emissionYear,
  metadata,
  impliedTemp,
}) => {
  const sectorsCount = sectorKeys.length;
  if (sectorsCount === 0) return null;
  const lg = sectorsCount === 1 ? 12 : sectorsCount === 2 ? 6 : 4;
  const classes = useStyles();
  const { currentTheme } = useGetTheme();

  const color = colorSchema[currentTheme].chartColors;
  return (
    <Grid
      justifyContent="flex-start"
      spacing={2}
      container
      className={classes.container}
    >
      <div className={classes.topContainer}>
        <div className={classes.legendContainer}>
          {data[sectorKeys[0]]?.map(({ name }, index) => (
            <div key={name} className={classes.legend}>
              <div
                className={classes.legendBar}
                style={{ backgroundColor: color[index] }}
              ></div>
              <Typography className={classes.legendText}>{name}</Typography>
            </div>
          ))}
        </div>
        <Tooltip title={tooltipText}>
          <InfoOutlinedIcon className={classes.iconStyle} />
        </Tooltip>
      </div>
      {sectorKeys.map((sectorKey, index) => (
        <Grid item xs={12} md={6} lg={lg} key={sectorKey}>
          <Typography className={classes.title}>
            {sectorKey}
            {impliedTemp[sectorKey] ? `: ${impliedTemp[sectorKey]}°C` : ''}
          </Typography>
          <LineChart
            data={data[sectorKey]}
            xAxisType="datetime"
            markerEnabled={false}
            chartKey="SECTOR_SUBPLOT"
            showLegend={false}
            xAxisTitle="Year"
            emissionYear={emissionYear}
            yAxisTitle={
              index === 0 && metadata?.units
                ? `${metadata?.units} (same unit for all charts)`
                : ''
            }
            height={250}
          />
        </Grid>
      ))}
    </Grid>
  );
};

SectorSubplotDisplay.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(
          PropTypes.arrayOf(
            PropTypes.oneOfType([
              PropTypes.number, // Timestamp
              PropTypes.number, // Value
            ]),
          ),
        ).isRequired,
      }),
    ),
  ).isRequired,
  sectorKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  emissionYear: PropTypes.number,
  metadata: PropTypes.shape({
    units: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  impliedTemp: PropTypes.object.isRequired,
};

export default LoadingHOC(SectorSubplotDisplay);
