/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import image from '../../../../assets/report/benchmark-main.jpg';

const Introduction = ({ data }) => {
  const { singleIsin } = data;
  const emissionYear = useSelector(
    (state) => state.auth.userInfo?.year?.emissions,
  );
  const day = new Date();
  const today = day.toLocaleString('default', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <div className="pdf_page">
      <h1 style={{ fontSize: 40 }}>
        <div>Climate Related Corporate Benchmarking Report</div>
        <div>{singleIsin?.name}</div>
      </h1>
      <img className="span-2" src={image} width="710px" height={400} />
      <div>
        <h3>Contents</h3>
        <table className="table-of-content">
          <tr>
            <td>Overview</td>
            <td>2</td>
          </tr>
          <tr>
            <td>Emissions Footprint (Scope 1+2)</td>
            <td>4</td>
          </tr>
          <tr>
            <td>Scope 3 Emissions</td>
            <td>5</td>
          </tr>
          <tr>
            <td>Forward Looking Alignment</td>
            <td>6</td>
          </tr>
          <tr>
            <td>Appendix</td>
            <td>7</td>
          </tr>
          <tr>
            <td>Glossary</td>
            <td>8</td>
          </tr>
          <tr>
            <td>Disclaimer</td>
            <td>9</td>
          </tr>
        </table>
      </div>
      <div>
        <h3>Benchmarking Summmary</h3>
        <table className="table-of-content">
          <tr>
            <td>Company Name</td>
            <td>{singleIsin?.name}</td>
          </tr>
          <tr>
            <td>Sub Industry Group Sector</td>
            <td>{singleIsin?.sub_industry_group}</td>
          </tr>
          <tr>
            <td>Sector Classification</td>
            <td>ICE</td>
          </tr>
          <tr>
            <td>Dataset Period</td>
            <td>{emissionYear}</td>
          </tr>
          <tr>
            <td>Report Date</td>
            <td>{today}</td>
          </tr>
        </table>
      </div>
    </div>
  );
};
export default Introduction;
