import React from 'react';
import PropTypes from 'prop-types';
import StackedBar from '../../Charts/StackedBar';

function ReportStackedBar(props) {
  return (
    <StackedBar
      dynamicTitle=""
      height={250}
      theme={'ICE light'}
      isExportable={false}
      plotLineWidth={1}
      showLegend={true}
      fontSize={7}
      barWidth={15}
      showLabels={true}
      yAxisGridlineShow={true}
      {...props}
    />
  );
}

ReportStackedBar.propTypes = {
  categories: PropTypes.array,
  data: PropTypes.array,
  yAxisTitle: PropTypes.string,
  xAxisTitle: PropTypes.string,
  max: PropTypes.number,
};

export default ReportStackedBar;
