import BaseRequest from '../baseRequest';

export default class PortfolioEmissions extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem, moduleName } = props;
    const {
      footprintMetric, sector, marketValue, assetClass,
    } = filterItem;
    this.footprint = moduleName === 'TCFD' ? 'WeightAvgMarketVal' : footprintMetric;
    this.sector = sector;
    this.market_value = moduleName === 'TCFD' ? 'EnterpriseValCash' : marketValue;
    this.asset_type = assetClass;
  }
}
