import React from 'react';
import PropTypes from 'prop-types';
import HorizontalBar from '../../../components/Charts/HorizontalBar';

const CountryEmissionsDisplay = (props) => (
  <React.Fragment>
    {props.chartData.length
      && props.chartData.map((chart) => (
        <HorizontalBar
          key={chart.chartKey}
          categories={chart.categories}
          data={chart.data}
          yAxisTitle={chart.yTitle}
          title={chart.chartTitle}
        />
      ))}
  </React.Fragment>
);

CountryEmissionsDisplay.propTypes = {
  chartData: PropTypes.array,
};
export default CountryEmissionsDisplay;
