import React, { useState, useEffect } from 'react';
import {
  some, findIndex, get, isEmpty,
} from 'lodash';
import { companyAnalysisCells } from '../../../util/TableHeadConfig';
import { LoadingHOC } from '../../../util/LoadingHOC';
import CompanyAnalysisDisplay from './CompanyAnalysisDisplay';
import useGetPayload from '../../../hooks/useGetPayload';
import Model from '../../../models/tempScore/companyAnalysis';
import { useGetTempMetricCompaniesScoreQuery } from '../../../redux/apiHooks';

const CompanyAnalysisDisplayWithLoading = LoadingHOC(CompanyAnalysisDisplay);

const emissionValueMap = {
  Sc12: 'S1+2',
  Sc123: 'S1+2+3',
  Sc3: 'S3',
};

const CompanyAnalysis = () => {
  const { auth, filters, payload } = useGetPayload(Model);
  const {
    data: companyData,
    isFetching,
    error,
  } = useGetTempMetricCompaniesScoreQuery(payload);
  const { userInfo } = auth;
  const { emission } = filters;

  const trial = get(userInfo, 'trial', false);

  const emissionLabel = emissionValueMap[emission];
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const getData = () => {
    let tData = [];
    let cData = [];
    if (!isEmpty(companyData)) {
      companyData.forEach((data) => {
        if (some(cData, { name: data.sector })) {
          const index = findIndex(cData, { name: data.sector });
          const xVal = data.temperature_score;
          const yVal = data.emission_intensity;
          const zVal = data.weight;
          cData[index].data.push({
            x: xVal,
            y: yVal,
            z: zVal,
            company: data.company_name,
          });
        } else {
          const xVal = data.temperature_score;
          const yVal = data.emission_intensity;
          const zVal = data.weight;

          cData = [
            ...cData,
            {
              name: data.sector,
              data: [
                {
                  x: xVal,
                  y: yVal,
                  z: zVal,
                  company: data.company_name,
                },
              ],
            },
          ];
        }
      });
      tData = [
        ...companyData.map((obj) => ({
          ...obj,
          sbti_validated: obj.sbti_validated ? 'Yes' : 'No',
        })),
      ];
    }
    setTableData(tData);
    setChartData(cData);
  };

  useEffect(() => {
    getData();
  }, [companyData, emission]);

  return (
    <React.Fragment>
      <CompanyAnalysisDisplayWithLoading
        loading={isFetching}
        error={error}
        chartKey="COMPANY_ANALYSIS"
        chartData={chartData}
        xAxisLabel="Temperature Score"
        yAxisLabel={`${emissionLabel} GHG Emissions (tCO2)`}
        zAxisLabel="Weight"
        yAxisTitle={`${emissionLabel} GHG Emissions (tCO2)`}
        isExportEnabled={!trial}
        tableData={tableData}
        columns={companyAnalysisCells}
        tableHeading="COMPANY_ANALYSIS"
      />
    </React.Fragment>
  );
};

export default CompanyAnalysis;
