import React from 'react';
import PropTypes from 'prop-types';
import useCurrentTheme from '../../hooks/useCurrentTheme';
import BaseChart from './BaseChart';
import ChartProps from './ChartProps';

function HorizontalBar({
  tooltipUnit,
  tooltipText,
  barWidth,
  xAxisType,
  ...props
}) {
  const { usedStyles } = useCurrentTheme(props.theme);
  let unit = tooltipUnit;
  if (!unit) {
    unit = props.yAxisTitle;
  }
  const options = {
    xAxis: {
      type: xAxisType,
    },
    tooltip: {
      formatter() {
        let text;
        if (tooltipText) {
          if (typeof tooltipText === 'string') {
            text = tooltipText;
          } else {
            text = tooltipText[this.colorIndex];
          }
        } else if (this.point.series.name) {
          text = this.point.series.name;
        } else {
          const opts = ['portfolio', 'benchmark'];
          text = opts[this.colorIndex];
        }
        return `<b>${this.x}</b>
          <br/>'
          <b>${text} : </b>${this.y} (${unit})`;
      },
    },
    plotOptions: {
      bar: {
        pointWidth: barWidth,
        borderWidth: 1,
        borderColor: usedStyles.themeType === 'dark' ? '#000000' : '#FFFFFF',
      },
    },
  };
  return <BaseChart {...props} chartType="bar" options={options} />;
}

HorizontalBar.propTypes = {
  ...ChartProps,
  tooltipUnit: PropTypes.string,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  barWidth: PropTypes.number,
  xAxisType: PropTypes.string,
};
export default HorizontalBar;
