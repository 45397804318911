import { api } from './api';
import UserSessionHandler from '../handler';
import {
  benchmarkReportSuccess,
  generalReportSuccess,
  getReportDataFailed,
  onReportCompanySuccess,
  setLoader,
  tcfdReportSuccess,
} from '../redux/reducers/reportReducer';

const embedCookie = UserSessionHandler.getCookie();
const BASE_API_URL = embedCookie && process.env.REACT_APP_SF_TEMP_API_URL
  ? process.env.REACT_APP_SF_TEMP_API_URL
  : process.env.REACT_APP_TEMP_API_URL;

const config = { baseURL: BASE_API_URL };

export const getPortfolioScoreConfig = () => config;

const getGenerateReportData = (reportParams, tempScoreParams) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const [apiResponse, tempScoreRes] = await Promise.allSettled([
      api.post('general_report', reportParams),
      api.post(
        'portfolio_score/',
        tempScoreParams,
        getPortfolioScoreConfig(),
      ),
    ]);

    dispatch(
      generalReportSuccess({
        reportData: apiResponse,
        tempScore: tempScoreRes,
      }),
    );
    dispatch(setLoader(false));
  } catch (error) {
    dispatch(getReportDataFailed(error?.message));
  }
};

const getTcfdData = (tcfdParams, tempScoreParams) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const [tcfdRes, tempScoreRes] = await Promise.allSettled([
      api.post('tcfd', tcfdParams),
      api.post('portfolio_score/', tempScoreParams, getPortfolioScoreConfig()),
    ]);
    dispatch(
      tcfdReportSuccess({ reportData: tcfdRes, tempScore: tempScoreRes }),
    );
    dispatch(setLoader(false));
  } catch (error) {
    dispatch(getReportDataFailed(error?.message));
  } finally {
    dispatch(setLoader(false));
  }
};

// eslint-disable-next-line no-unused-vars
const getBenchmarkReportData = (benchmarkReportParams) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const benchmarkRes = await api.post(
      'benchmark_report',
      benchmarkReportParams,
    );
    dispatch(benchmarkReportSuccess({ reportData: benchmarkRes }));
  } catch (error) {
    dispatch(getReportDataFailed(error?.message));
  } finally {
    dispatch(setLoader(false));
  }
};

// eslint-disable-next-line no-unused-vars
const getBenchmarkCompanies = (companyParams) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const response = await api.post(
      'forward_looking_metrics/sectors/companies',
      companyParams,
    );
    dispatch(onReportCompanySuccess(response));
    dispatch(setLoader(false));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export {
  getGenerateReportData,
  getTcfdData,
  getBenchmarkReportData,
  getBenchmarkCompanies,
};
