import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import Header from '../components/Header';
import FilterSection from '../components/FilterSection';
import { RouteData } from './Route';
import SideNav from '../components/SideBar';
import './base.css';
import { setCountrySuccess } from '../redux/reducers/filterReducer';
import {
  useGetUserDownloadPortfoliosQuery,
  useGetUserInfoQuery,
  useGetUserPortfolioListQuery,
  useLazyGetUserUploadPortfolioListQuery,
} from '../redux/apiHooks';
import SpeedDial, { pathSetWithSpeedDial } from '../components/SpeedDialComp';

const styles = () => ({
  root: {
    display: 'flex',
    width: '100%',
  },
});
const USE_CACHE = true;

const MiniDrawer = ({ classes }) => {
  const dispatch = useDispatch();
  const [getUserUploadPortfolioList] = useLazyGetUserUploadPortfolioListQuery();

  useGetUserInfoQuery();
  useGetUserPortfolioListQuery();
  useGetUserDownloadPortfoliosQuery();

  const auth = useSelector((state) => state.auth);
  const { moduleName, tabValue, userInfo } = auth;
  const { country: selectedCountry, updatedFilterModule } = useSelector(
    (state) => state.filters,
  );
  const { pathname } = window.location;

  useEffect(() => {
    if (userInfo.year) getUserUploadPortfolioList(undefined, USE_CACHE);
  }, [userInfo.year]);

  const currentUser = auth && auth.currentUser ? auth.currentUser : {};
  const fetchDetails = async () => {
    if (!selectedCountry) {
      const country = {
        label: 'United Kingdom',
        value: 'GB',
      };
      dispatch(setCountrySuccess(country));
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  let extraModulesSet = new Set([]);
  if (Array.isArray(currentUser?.extra_modules)) {
    extraModulesSet = new Set(currentUser.extra_modules);
  }
  const isPathPrivate = pathname !== '/'
    && RouteData.findIndex(
      ({ extraModuleName, url }) => (extraModuleName ? extraModulesSet.has(extraModuleName) : true)
        && pathname === url,
    ) === -1;
  if (isPathPrivate) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header />
      <SideNav />
      <main className="main-content">
        <Switch>
          {RouteData.map(
            ({
              name,
              Component,
              url,
              hideOnSidebar = false,
              exact = true,
              isPublic = false,
            }) => {
              // To make sure filters are already changed as per screens filter
              // Then mount the component once updatedFilterModule is set
              if (
                isPublic
                || hideOnSidebar
                || (Component
                  && updatedFilterModule === `${moduleName}-${tabValue}`)
              ) {
                return (
                  <Route path={url} exact={exact} key={name}>
                    <div className="content-container">
                      <FilterSection />
                      <div className="tabs-section">
                        <Component />
                      </div>
                    </div>
                    {pathSetWithSpeedDial.has(url) && <SpeedDial />}
                  </Route>
                );
              }
              return null;
            },
          )}
        </Switch>
      </main>
    </div>
  );
};

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    go: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    goForward: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired,
    action: PropTypes.string.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired,
      hash: PropTypes.string.isRequired,
      state: PropTypes.object,
    }).isRequired,
    listen: PropTypes.func.isRequired,
    createHref: PropTypes.func.isRequired,
  }).isRequired,
};
export default withStyles(styles, { withTheme: true })(MiniDrawer);
