// eslint-disable-next-line import/no-cycle
import apiSlice, { getQuery } from '../apiSlice';

const transformErrorResponse = ({ data }) => data?.message || 'Data not found for given Portfolio.';

export const fofApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFoFSummary: builder.query({
      query: getQuery.get('portfolio/fund_of_funds/?portfolio_ids='),
      transformErrorResponse,
    }),
    getFoFPortfolioAlignment: builder.query({
      query: getQuery.post(
        'forward_looking_metrics/portfolio_alignment/fund_of_funds',
      ),
      transformErrorResponse,
    }),
    getFoFPortfolioEmissions: builder.query({
      query: getQuery.post(
        'portfolio_footprint/portfolio_emissions/fund_of_funds',
      ),
      transformErrorResponse,
    }),
    getFoFSectorEmissions: builder.query({
      query: getQuery.post(
        'portfolio_footprint/sector_emissions/fund_of_funds',
      ),
      transformErrorResponse,
    }),
    getFoFTargetSetting: builder.query({
      query: getQuery.post(
        'forward_looking_metrics/target_setting/fund_of_funds',
      ),
      transformErrorResponse,
    }),
  }),
});

export const {
  useGetFoFPortfolioAlignmentQuery,
  useGetFoFPortfolioEmissionsQuery,
  useGetFoFSectorEmissionsQuery,
  useGetFoFSummaryQuery,
  useGetFoFTargetSettingQuery,
  useLazyGetFoFPortfolioAlignmentQuery,
  useLazyGetFoFPortfolioEmissionsQuery,
  useLazyGetFoFSectorEmissionsQuery,
  useLazyGetFoFSummaryQuery,
  useLazyGetFoFTargetSettingQuery,
} = fofApiSlice;
