import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ListItem, Box, Tooltip } from '@material-ui/core';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import TimelineIcon from '@material-ui/icons/Timeline';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import GetAppIcon from '@material-ui/icons/GetApp';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import BarChartIcon from '@material-ui/icons/BarChart';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import PublicIcon from '@material-ui/icons/Public';
import TableChartIcon from '@material-ui/icons/TableChart';
import { ReactComponent as FilterAltIcon } from '../../assets/FilterAltIcon.svg';
import colorSchema from '../../Themes/config';
import psychologyIcon from '../../assets/icon.png';
import useGetTheme from '../../hooks/useGetTheme';

function ListItemLink({
  icon, primary, to, handleClick, disabled, fullName,
}) {
  const { currentTheme } = useGetTheme();
  const getAvataricon = (iconName, color) => {
    switch (iconName) {
      case 'GraphicEqIcon':
        return <GraphicEqIcon style={{ color }} />;
      case 'VerticalSplitIcon':
        return <VerticalSplitIcon style={{ color }} />;
      case 'PsychologyIcon':
        return <img src={psychologyIcon} alt="" style={{ width: 30, color }} />;
      case 'InsertChartIcon':
        return <InsertChartIcon style={{ color }} />;
      case 'TimelineIcon':
        return <TimelineIcon style={{ color }} />;
      case 'BubbleChartIcon':
        return <BubbleChartIcon style={{ color }} />;
      case 'ViewWeekIcon':
        return <ViewWeekIcon style={{ color }} />;
      case 'AccountTreeIcon':
        return <AccountTreeIcon style={{ color }} />;
      case 'BarChartIcon':
        return <BarChartIcon style={{ color }} />;
      case 'GetAppIcon':
        return <GetAppIcon style={{ color }} />;
      case 'CodeIcon':
        return <CodeIcon style={{ color }} />;
      case 'AssignmentReturnedIcon':
        return <AssignmentReturnedIcon style={{ color }} />;
      case 'Public':
        return <PublicIcon style={{ color }} />;
      case 'DashboardIcon':
        return <DashboardIcon style={{ color }} />;
      case 'TableChartIcon':
        return <TableChartIcon style={{ color }} />;
      case 'FilterAltIcon':
        return <FilterAltIcon style={{ color }} />;
      default:
        return <AssignmentReturnedIcon style={{ color }} />;
    }
  };

  const CustomLink = React.useMemo(
    /* eslint-disable react/display-name */
    () => React.forwardRef((linkProps, ref) => (
        <NavLink
          ref={ref}
          to={to}
          {...linkProps}
          activeClassName="Mui-selected"
        />
    )),
    [to],
  );

  return (
    <li>
      <Tooltip title={fullName}>
        <ListItem
          button
          component={CustomLink}
          className="navigation"
          onClick={handleClick}
          disabled={disabled}
        >
          <Box>{getAvataricon(icon, colorSchema[currentTheme].iconColor)}</Box>
          <span
            style={{
              fontSize: 8,
              color: colorSchema[currentTheme].textSecondary,
            }}
          >
            {primary}
          </span>
        </ListItem>
      </Tooltip>
    </li>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.string.isRequired,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ListItemLink;
