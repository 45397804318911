import BaseRequest from '../baseRequest';

export default class HistoricalEmissions extends BaseRequest {
  constructor(props) {
    super(props);
    const { filterItem } = props;
    const { footprintMetric, country } = filterItem;
    this.country = country?.value;
    this.attribution = footprintMetric;
  }
}
