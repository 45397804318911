/* eslint-disable react/prop-types */
import React from 'react';

import {
  FormControl, Select, MenuItem, Button,
} from '@material-ui/core';
import Introduction from './Pages/Introduction';
import Emission from './Pages/Emissions';
import AvoidedEmissions from './Pages/AvoidedEmissions';
import Sectors from './Pages/Sectors';
import SovereignEmissions from './Pages/SovereignEmissions';
import Scope3OverviewPage1 from './Pages/Scope3OverviewPage1';
import Scope3OverviewPage2 from './Pages/Scope3OverviewPage2';
import FossilFuelsAndStrandedAssets from './Pages/FossilFuelsAndStrandedAssets';
import NetZero from './Pages/NetZero';
import TemperatureMetrics from './Pages/TemperatureMetrics';
import Disclaimer from './Pages/Disclaimer';
import Pdf from '../../../components/PdfReport/Pdf';

function getStyles(name, personName) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? 'normal' : 'bold',
  };
}

const theme = 'ICE light';
function GeneralReportDisplay({
  onDownloadClick,
  buttonDisabled,
  downloading,
  displayedPages,
  onPageSelect,
  options,
  showPage,
  data,
}) {
  return (
    <div style={{ width: 755 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ marginLeft: 10 }}>
          <Button
            type="button"
            onClick={onDownloadClick}
            disabled={buttonDisabled}
          >
            Download Report
          </Button>
        </div>
        <FormControl variant="standard" disabled={buttonDisabled}>
          <Select
            placeholder="PCAF SCORE"
            value={displayedPages}
            style={{ fontSize: 14, width: 290, margin: 10 }}
            multiple
            onChange={onPageSelect}
            renderValue={(selected) => (selected.includes('Show all') ? 'Show All' : selected.join(', '))
            }
          >
            {options.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, displayedPages)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Pdf downloading={downloading}>
        <Introduction data={data} />
        {showPage('Portfolio Footprint') && <Emission data={data} />}
        {showPage('Sectoral Analysis') && <Sectors data={data} />}
        {showPage('Avoided Emissions') && <AvoidedEmissions data={data} />}
        {showPage('Sovereign Footprint') && <SovereignEmissions data={data} />}
        {showPage('Scope 3 Materiality') && <Scope3OverviewPage1 data={data} />}
        {showPage('Scope 3 Materiality') && <Scope3OverviewPage2 data={data} />}
        {showPage('Fossil Fuels Reserves Footprint') && (
          <FossilFuelsAndStrandedAssets data={data} />
        )}
        {showPage('Net-Zero Analysis') && <NetZero theme={theme} data={data} />}
        {showPage('Portfolio Temperature Score (SBTi)') && (
          <TemperatureMetrics data={data} />
        )}
        <Disclaimer />
      </Pdf>
    </div>
  );
}

export default GeneralReportDisplay;
