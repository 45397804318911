import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
// import DeleteIcon from '@material-ui/icons/Delete';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import {
  InputLabel,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  MenuItem,
} from '@material-ui/core';
import colorSchema from '../../Themes/config';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectDropdown({
  options,
  handleChange,
  currentValue,
  // onDeleteClick,
  onClearClick,
  // isDeletable,
  // dropdownField,
  fields,
  labelText,
  isEditable,
  onClose = () => {},
  disabled = false,
  currentTheme,
}) {
  const handleClick = (event) => {
    const {
      target: { value },
    } = event;
    handleChange(value);
  };

  const renderValue = (selected) => options
    .reduce(
      (acc, p) => (selected.includes(p.value) ? [...acc, p.label] : acc),
      [],
    )
    .join(', ');

  return (
    <FormControl
      variant="outlined"
      sx={{ width: 250, marginTop: 1 }}
      size="small"
      style={{ width: 250, marginTop: 7 }}
      disabled={disabled}
    >
      <InputLabel id={labelText} style={{ textTransform: 'capitalize' }}>
        {labelText}
      </InputLabel>
      <Select
        labelId={labelText}
        id={labelText}
        multiple
        value={currentValue}
        onChange={handleClick}
        onClose={onClose}
        label={labelText}
        renderValue={(selected) => renderValue(selected)}
        MenuProps={MenuProps}
        disabled={disabled}
        endAdornment={
          <>
            {isEditable ? (
              <IconButton
                style={{ marginRight: 10 }}
                disabled={disabled}
                onClick={() => onClearClick(fields?.id)}
              >
                <HighlightOffOutlinedIcon
                  style={{ color: colorSchema[currentTheme].clearIconColor }}
                  fontSize="small"
                />
              </IconButton>
            ) : null}
          </>
        }
      >
        {options.map(({ label, value }) => (
          <MenuItem
            key={value}
            value={value}
            name={label}
            style={{ whiteSpace: 'normal' }}
          >
            <Checkbox checked={currentValue?.indexOf(value) > -1} />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

MultipleSelectDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  currentValue: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  onClearClick: PropTypes.func.isRequired,
  fields: PropTypes.object,
  labelText: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
  currentTheme: PropTypes.object,
};
