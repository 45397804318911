/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import {
  InputLabel, FormControl, Select, MenuItem,
} from '@material-ui/core';
import colorSchema from '../../Themes/config';
import useGetTheme from '../../hooks/useGetTheme';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectDropdownComp({
  filterData,
  handleChange,
  type,
  fields,
  onDeleteClick,
  dropdownFields,
  defaultValue,
  isEditable,
  isDisabled,
  isDeletable,
  onClearClick,
  labelText,
  onCloseHandler,
}) {
  const { currentTheme } = useGetTheme();

  const handleFilterChange = (event, filterType, isVisible) => {
    if (event.target.value !== 'undefuned') {
      const {
        target: { value },
      } = event;
      handleChange(value, filterType, fields?.id, isVisible);
    }
  };

  const setDisabled = (value) => {
    const singleSelectionArray = ['region', 'country', 'asset_type'];
    if (fields.length) {
      const filterValue = fields.find(
        (f) => (f[type] === value && isDisabled)
          || (f[type] === value && singleSelectionArray.includes(value)),
      );
      return !!filterValue;
    }
    return (
      (fields && fields[type] && isDisabled) === value
      || ((fields && fields[type]) === value
        && singleSelectionArray.includes(value))
    );
  };

  const handleDeleteClick = (id) => onDeleteClick(id);

  const handleClear = (id) => onClearClick(id);

  const getRenderValue = (selected) => {
    const label = filterData.find((f) => f.value === selected)?.label;
    return (
      (fields.filterType === 'intensity_sc12'
        ? fields.condition === 'range'
          ? fields.gt && fields.lt
            ? `${fields.gt}-${fields.lt}`
            : label
          : `${label} ${fields.value}`
        : label) || defaultValue
    );
  };

  const handleOnClose = () => onCloseHandler(fields.id);

  return (
    <FormControl
      sx={{ width: 250, marginTop: 1, backgroundColor: '#FFFFFF' }}
      size="small"
      variant="outlined"
      style={{ minWidth: 250, marginTop: 7 }}
    >
      <InputLabel>{labelText}</InputLabel>
      <Select
        value={type === 'filterType' ? 'Add Filter' : fields[type]}
        onChange={(event) => handleFilterChange(event, type, !!event)}
        onClose={(event) => handleOnClose(event)}
        label={labelText}
        endAdornment={
          <>
            {isEditable ? (
              <IconButton style={{ marginRight: 10 }}>
                <HighlightOffOutlinedIcon
                  style={{ color: colorSchema[currentTheme].clearIconColor }}
                  fontSize="small"
                  onClick={() => handleClear(fields?.id)}
                />
              </IconButton>
            ) : null}
            {isDeletable && dropdownFields?.length ? (
              <IconButton>
                <DeleteIcon
                  color="inherit"
                  fontSize="small"
                  onClick={() => handleDeleteClick(fields.id)}
                />
              </IconButton>
            ) : null}
          </>
        }
        renderValue={(selected) => getRenderValue(selected)}
        MenuProps={MenuProps}
      >
        {filterData.map(({ label, value }) => (
          <MenuItem key={value} value={value} disabled={setDisabled(value)}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectDropdownComp.propTypes = {
  filterData: PropTypes.array.isRequired,
  dropdownFields: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onClearClick: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  isEditable: PropTypes.string.isRequired,
  isDeletable: PropTypes.bool.isRequired,
  labelText: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
};
