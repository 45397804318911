import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { LoadingHOC } from '../../../util/LoadingHOC';
import DecarbonisationMonitorDisplay from './DecarbonisationMonitorDisplay';
import { getLineChartData } from '../../../helpers';
import { EMISSION_YEAR, TRAJECTORY } from '../constants';
import DecarbonisationModel from '../../../models/netZero/decarbonisation';
import useGetPayload from '../../../hooks/useGetPayload';
import { useGetNetZeroDecarbonisationQuery } from '../../../redux/apiHooks';

const DecarbonisationMonitorWithLoading = LoadingHOC(
  DecarbonisationMonitorDisplay,
);

const DecarbonisationMonitor = () => {
  const { auth, payload } = useGetPayload(DecarbonisationModel);
  const {
    data: decarbonisationData,
    isFetching,
    error,
  } = useGetNetZeroDecarbonisationQuery(payload);
  const { showBenchmark, userInfo } = auth;

  const [lineChartData, setChartData] = useState([]);
  const [yAxisTitle, setYAxisTitle] = useState('');
  const [chartUnit, setUnit] = useState('');

  const trajectory = {
    portfolio_trajectory: TRAJECTORY.PORTFOLIO_TRAJECTORY,
    benchmark_trajectory: TRAJECTORY.BENCHMARK_TRAJECTORY,
  };

  const getData = () => {
    const { unit, title, chartData } = getLineChartData(
      decarbonisationData,
      trajectory,
    );
    setChartData(
      showBenchmark
        ? chartData
        : chartData.filter(
          (item) => item.name !== TRAJECTORY.BENCHMARK_TRAJECTORY,
        ),
    );
    setYAxisTitle(title);
    setUnit(unit);
  };

  useEffect(() => {
    if (!isEmpty(decarbonisationData)) getData();
  }, [decarbonisationData]);

  const portfolioPercentage = decarbonisationData?.target_coverage?.portfolio;
  const onTrackColor = decarbonisationData?.on_track_indicator?.portfolio;
  const emissionYear = userInfo?.year?.emissions
    ? userInfo.year.emissions - 1
    : EMISSION_YEAR;

  return (
    <DecarbonisationMonitorWithLoading
      portfolioPercentage={
        portfolioPercentage ? `${portfolioPercentage}%` : 'N/A'
      }
      chartData={lineChartData}
      yAxisTitle={yAxisTitle}
      unit={chartUnit}
      emissionYear={emissionYear}
      onTrackColor={onTrackColor}
      loading={isFetching}
      error={error}
    />
  );
};
export default DecarbonisationMonitor;
