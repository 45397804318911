import React from 'react';
import PropTypes from 'prop-types';
import HorizontalBar from '../../Charts/HorizontalBar';

function ReportHorizontalBar(props) {
  return (
    <HorizontalBar
      height={250}
      theme={'ICE light'}
      isExportable={false}
      plotLineWidth={1}
      showLegend={true}
      fontSize={7}
      barWidth={props.categories.length >= 10 ? 4 : 6}
      yAxisGridlineShow={true}
      legendType="horizontal"
      {...props}
    />
  );
}

ReportHorizontalBar.propTypes = {
  categories: PropTypes.array,
  data: PropTypes.array,
  yAxisTitle: PropTypes.string,
  height: PropTypes.number,
};

export default ReportHorizontalBar;
