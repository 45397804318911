import React from 'react';
import BaseChart from './BaseChart';
import ChartProps from './ChartProps';

function BasicAreaChart(props) {
  return (
    <BaseChart
      {...props}
      chartType="area"
      legendType="horizontal"
      options={{
        xAxis: {
          tickInterval: 150,
          labels: {
            formatter() {
              return this.value;
            },
          },
        },
      }}
    />
  );
}

BasicAreaChart.propTypes = {
  ...ChartProps,
};
export default BasicAreaChart;
