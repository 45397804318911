import { get, isNumber } from 'lodash';

const formatNumber = (number, showNotation) => (isNumber(number)
  ? new Intl.NumberFormat('en-UK', {
    notation: showNotation ? 'compact' : 'standard',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number)
  : number);

const getNumberFormatter = (selector) => (row) => {
  const value = get(row, selector);
  if (typeof value === 'number') {
    return formatNumber(value);
  }
  return value;
};

export { formatNumber, getNumberFormatter };
