// eslint-disable-next-line import/no-cycle
import apiSlice, { getQuery } from '../apiSlice';
import { getCountriesByCode } from '../../helpers';

const extraOptions = {
  baseUrl: process.env.REACT_APP_SOV_API_URL,
};

export const sovereignApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSovereignPortfolioTotal: builder.query({
      query: getQuery.post(
        'portfolio_footprint/sovereign_view/portfolio_total',
      ),
      transformErrorResponse: ({ data }) => data?.message,
      extraOptions,
    }),
    getSovereignCountrySplit: builder.query({
      query: getQuery.post('portfolio_footprint/sovereign_view/country_split'),
      transformErrorResponse: ({ data }) => data?.message,
      extraOptions,
    }),
    getSovereignTimeseries: builder.query({
      query: getQuery.post('portfolio_footprint/sovereign_view/timeseries'),
      transformErrorResponse: ({ data }) => data?.message,
      extraOptions,
    }),
    getSovereignTimeseriesCountries: builder.query({
      query: getQuery.post(
        'portfolio_footprint/sovereign_view/timeseries/countries',
      ),
      transformErrorResponse: ({ data }) => data?.message,
      transformResponse: (response) => getCountriesByCode(response),
      extraOptions,
    }),
  }),
});

export const {
  useGetSovereignCountrySplitQuery,
  useGetSovereignPortfolioTotalQuery,
  useGetSovereignTimeseriesCountriesQuery,
  useGetSovereignTimeseriesQuery,
  useLazyGetSovereignCountrySplitQuery,
  useLazyGetSovereignPortfolioTotalQuery,
  useLazyGetSovereignTimeseriesCountriesQuery,
  useLazyGetSovereignTimeseriesQuery,
} = sovereignApiSlice;
