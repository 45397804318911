/* eslint-disable react/prop-types */

import React from 'react';
import {
  Grid, FormControl, Select, MenuItem,
} from '@material-ui/core';
import HorizontalBar from '../../../components/Charts/HorizontalBar';
import DataTable from '../../../components/Table/DataTable';
import TitleTooltip from '../../../components/Table/TitleTooltip';

const CarbonEmissionDisplay = (props) => (
  <React.Fragment>
    <Grid container>
      <Grid item xs={6}>
        <FormControl
          variant="outlined"
          style={{ width: '100px', marginTop: 20 }}
        >
          <Select
            placeholder="Top 10"
            value={props.selectedValue}
            onChange={(e) => props.onChange(e)}
            style={{
              fontSize: 14,
              width: 300,
              marginBottom: 20,
              height: 40,
            }}
          >
            <MenuItem value="top">Top 10</MenuItem>
            <MenuItem value="bottom">Bottom 10</MenuItem>
          </Select>
        </FormControl>
        <TitleTooltip
          style={{ marginTop: 10, fontSize: 16, fontWeight: 'bold' }}
          title={props.contributionTitle}
          tooltipText=" This displays the top or bottom 10 companies with the highest or lowest carbon metric contribution (scope 1+2 or scope 1+2+3) within the portfolio. This is calculated by using a company's selected footprint metric and applicable market value and weighting within the portfolio."
        />
        <HorizontalBar
          categories={props.contriCategories}
          data={props.horizontalBarData_1}
          yAxisTitle={props.contributionYaxisTitle}
        />
      </Grid>
      <Grid item xs={6} style={{ width: '100px', marginTop: 80 }}>
        <TitleTooltip
          title={props.intensitiesTitle}
          tooltipText="This displays the top or bottom 10 companies with the highest or lowest carbon metric (scope 1+2 or scope 1+2+3) within the portfolio. This is calculated by using a company's selected footprint metric and total emissions."
          style={{ marginTop: 10, fontSize: 16, fontWeight: 'bold' }}
        />
        <HorizontalBar
          categories={props.intensityCategories}
          data={props.horizontalBarData_2}
          yAxisTitle={props.intensitiesYaxisTitle}
        />
      </Grid>
    </Grid>
    <Grid conatiner>
      <DataTable
        data={props.tableData}
        columns={props.columns}
        tableHeading="TEMP_SCORE"
      />
    </Grid>
  </React.Fragment>
);

export default CarbonEmissionDisplay;
