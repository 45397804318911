import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {
  Box, Grid, CardActionArea, makeStyles,
} from '@material-ui/core';
import supplyChainIcon from '../../assets/dashboards/supply_chain.png';
import cvarIcon from '../../assets/dashboards/cvar.png';
import labelledBondIcon from '../../assets/dashboards/labelled_bond.png';

const dashboardCardsData = [
  {
    title: 'Supply Chain Risk',
    description:
      "This prototype comprises of a dashboard providing an overview of the transition risk of a selected portfolio and/or benchmark, based on their exposure to companies' supply chain operations. The analytics tool uses the latest company supplier data to calculate the carbon footprint of a company's supply chain by analysing the emission intensity of the supplier's industry and region.",
    image: supplyChainIcon,
    path: '/supply-chain-risk/',
  },
  {
    title: 'Climate Transition Value-at-Risk',
    description:
      'The Climate Transition Value-at-Risk (CTVaR) is a novel risk metric that assess the climate transition risks of an investment portfolio. The methodology utilises a climate-financial model to calculate the potential financial risk of a company that does not align its emissions to relevant climate-transition scenarios.',
    image: cvarIcon,
    path: '/cvar/',
  },
  {
    title: 'Labelled Bond',
    description:
      "This dashboard provides a status check on the environmental and social impacts funded by a selected portfolio, based off the portfolio's investment into Labelled Bonds. We break down a portfolio's investment into labelled bonds by bond classification, reporting standards, and the impacts of initiatives funded, and use this to calculate the positive impact derived from the portfolio's investment.",
    image: labelledBondIcon,
    path: '/impact_bonds/',
  },
];

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: 750,
    height: '100%',
    backgroundColor: '#FFFFFF !important',
  },
  logo: {
    width: '180px !important',
    objectFit: 'contain !important',
  },
  text: {
    color: 'inherit !important',
  },
  flex: {
    display: 'flex',
  },
  cardButton: {
    height: '100%',
  },
}));

const Dashboards = () => {
  const classes = useStyles();
  const onRedirect = (path) => {
    window.open(`${window.location.origin}${path}`, '_blank');
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      {dashboardCardsData.map(({
        title, description, path, image,
      }) => (
        <Grid item key={title}>
          <Card className={classes.card}>
            <CardActionArea
              onClick={() => onRedirect(path)}
              className={classes.cardButton}
            >
              <Box className={classes.flex}>
                <CardMedia
                  component="img"
                  image={image}
                  alt={title}
                  className={classes.logo}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className={classes.text}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className={classes.text}
                  >
                    {description}
                  </Typography>
                </CardContent>
              </Box>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Dashboards;
