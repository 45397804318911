import {
  INCLUDE_NONE,
  TARGETS,
} from '../../components/FilterSection/constants';

export default class Download {
  constructor(props) {
    const { currentPortfolio, filterItem } = props;
    const {
      yearEmissions,
      emission,
      inferenceType,
      emissionsType,
      include,
      marketValue,
    } = filterItem;
    this.portfolio_id = currentPortfolio.value;
    this.version_portfolio = currentPortfolio.version;
    this.market_value = marketValue;
    this.year = yearEmissions;
    this.field = 'dynamic';
    this.scope = Array.isArray(emission) ? emission : [emission];
    this.inference_type = Array.isArray(inferenceType)
      ? inferenceType
      : [inferenceType];
    this.emission_type = Array.isArray(emissionsType)
      ? emissionsType
      : [emissionsType];
    this.id_type = 'ID_ISIN';

    if (
      include.includes(INCLUDE_NONE)
      && include.findIndex((item) => item === INCLUDE_NONE) !== 0
    ) {
      this.include_targets = false;
    } else {
      this.include_targets = include?.includes(TARGETS);
    }
  }

  toJsonObject() {
    return { ...this };
  }
}
