import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';

function ProgressBarChart({
  height = 35, value, max, percent, text,
}) {
  const calculatedPercent = percent || (value / max) * 100;
  return (
    <div style={{ fontSize: 14 }}>
      <div
        style={{
          borderRadius: 50,
          overflow: 'hidden',
          height,
          background: 'gray',
        }}
      >
        <div
          style={{
            width: `${calculatedPercent}%`,
            background: '#71c5e8',
            height: '100%',
          }}
        />
      </div>
      <div style={{ marginLeft: 10 }}>
        <b>
          {value} / {max}
        </b>
      </div>
      <div
        style={{
          color: 'grey',
          fontSize: 11,
          marginLeft: 10,
          marginTop: 5,
        }}
      >
        {text}
      </div>
    </div>
  );
}

ProgressBarChart.propTypes = {
  height: PropTypes.number,
  value: PropTypes.number,
  max: PropTypes.number,
  percent: PropTypes.number,
  text: oneOfType([PropTypes.text, PropTypes.elementType]),
};

export default ProgressBarChart;
