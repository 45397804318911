import React, { useState, useEffect, useCallback } from 'react';
import { isEmpty } from 'lodash';
import { countryEmissisonCharts } from '../constants';
import CountryEmissionsDisplay from './CountryEmissionsDisplay';
import { LoadingHOC } from '../../../util/LoadingHOC';
import useGetPayload from '../../../hooks/useGetPayload';
import SovereignCountryEmissionModel from '../../../models/sovereign/countryEmissions';
import { useGetSovereignCountrySplitQuery } from '../../../redux/apiHooks';

const CountryEmissionsWithLoading = LoadingHOC(CountryEmissionsDisplay);

const CountryEmissions = () => {
  const { auth, filters, payload } = useGetPayload(
    SovereignCountryEmissionModel,
  );
  const { data, isFetching, error } = useGetSovereignCountrySplitQuery(payload);
  const { showBenchmark } = auth;
  const { emissionType } = filters;

  const [chartData, setChartData] = useState({});

  const getChartData = (portfolioData, benchmarkData, chartTitle) => {
    const portfolio = [];
    const benchmark = [];
    let result = [];
    const isIntensityChart = chartTitle === 'Intensity';
    if (portfolioData && Object.keys(portfolioData).length > 0) {
      Object.keys(portfolioData).forEach((country) => {
        const portValue = portfolioData[country];
        portfolio.push(portValue);
      });
    }

    if (benchmarkData && Object.keys(benchmarkData).length > 0) {
      Object.keys(benchmarkData).forEach((country) => {
        const portValue = benchmarkData[country];
        benchmark.push(portValue);
      });
    }
    result = [
      {
        name: 'Portfolio',
        data: [...portfolio],
      },
    ];

    if (!isIntensityChart && showBenchmark) {
      result.push({
        name: 'Benchmark',
        data: [...benchmark],
      });
    }
    return result;
  };

  const getEmissionType = (type) => type.toLowerCase();

  const getData = useCallback(() => {
    const contribution = getEmissionType(emissionType);
    const emissionChartData = Object.values(countryEmissisonCharts).map(
      ({ chartName, chartTitle, chartKey }) => {
        const yTitle = `${
          data?.metadata?.[chartTitle.toLowerCase()]?.label || ''
        } ${data?.metadata?.[chartTitle.toLowerCase()]?.units || ''}`;
        if (!isEmpty(data.data)) {
          const portfolioData = data?.data?.portfolio?.[contribution]?.[chartName];
          const benchmarkData = data?.data?.benchmark?.[contribution]?.[chartName];
          const result = getChartData(portfolioData, benchmarkData, chartTitle);
          const categories = Object.keys(portfolioData);
          return {
            data: result,
            categories,
            chartName,
            chartTitle,
            chartKey,
            yTitle,
          };
        }
        return null;
      },
    );
    setChartData(emissionChartData);
  }, [data, emissionType]);

  useEffect(() => {
    if (!isEmpty(data)) getData();
  }, [getData]);

  return (
    <CountryEmissionsWithLoading
      error={error}
      loading={isFetching}
      chartData={chartData}
    />
  );
};

export default CountryEmissions;
