// eslint-disable-next-line import/no-cycle
import apiSlice, { getQuery } from '../apiSlice';

export const reportApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReportGeneralReport: builder.query({
      query: getQuery.post('general_report'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getReportTcfd: builder.query({
      query: getQuery.post('tcfd'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getReportBenchMark: builder.query({
      query: getQuery.post('benchmark_report'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
  }),
});

export const {
  useGetReportGeneralReportQuery,
  useGetReportTcfdQuery,
  useGetReportBenchMarkQuery,
  useLazyGetReportGeneralReportQuery,
  useLazyGetReportTcfdQuery,
  useLazyGetReportBenchMarkQuery,
} = reportApiSlice;
