import PropTypes from 'prop-types';

export default {
  theme: PropTypes.string,
  isExportable: PropTypes.bool,

  width: PropTypes.number,
  height: PropTypes.number,
  fontSize: PropTypes.number,

  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  categories: PropTypes.array,

  // Declare shape of and subtitle
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string,
      align: PropTypes.string,
    }),
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string,
      align: PropTypes.string,
    }),
  ]),

  xAxisTitle: PropTypes.string,
  xAxisCategories: PropTypes.array,
  xAxisGridlineShow: PropTypes.bool,
  xAxisLabelsEnabled: PropTypes.bool,

  yAxisTitle: PropTypes.string,
  yAxisCategories: PropTypes.array,
  yAxisGridlineShow: PropTypes.bool,
  yAxisLabelsEnabled: PropTypes.bool,

  showLegend: PropTypes.bool,
  legendType: PropTypes.string,

  customOptions: PropTypes.object,
};
