/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import {
  Typography, List, Divider, Box, Grid,
} from '@material-ui/core';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';

class UrgentemApi extends Component {
  render() {
    return (
      <Grid container>
        <Grid item xs={3}></Grid>
        <Grid item xs={8}>
          <Box style={{ width: '60%' }} mt={4}>
            <Typography variant="h3">Client API</Typography>
            <br />
            <Divider />
            <br />
            <Box p={1}>
              <Typography variant="h4">API Call</Typography>
            </Box>
            <Box p={1}>
              <Typography>
                URL : https://clientapi.urgentem.net/download/
                <br />
                To have access to the API, you need to provide x-api-key in the
                request headers.
                <br />
                There are two options to provide data to the API:
              </Typography>
              <List>
                <Typography>
                  1. Json (Content-Type: application/json)
                </Typography>
                <Typography>
                  2. File csv or xlsx/xls (Content-Type: multipart/form-data).
                  To provide a file you need to excel_file parameter with your
                  file
                </Typography>
              </List>
            </Box>
            <br />
            <Divider />
            <br />
            <div>
              <Typography variant="h6">Json Request body:</Typography>
              <Typography> </Typography>
              <SyntaxHighlighter language="javascript" style={tomorrowNight}>
                {`[
  {
    "identifier": "US29134WAB37",  // required field
    "id_type": "ID_ISIN",          // required field
    "year": 2020,                  // optional field
    "quarter": "Q1",               // optional field
    "field": "summary;avg_in_cols" // required field
  },...etc.. 
]`}
              </SyntaxHighlighter>
            </div>
            <br />
            <div>
              <Typography>
                Possible values for id_type field: ID_ISIN, LEI, Ticker. <br />
                This field should be the same for all identifiers in the
                request. <br /> Note: How to use ticker for requesting data -
                corp & equitiesThe TICKER code to be used as the identifier must
                be a combination of the TICKER symbol and the exchange code,
                separated by an empty character. &apos;TICKER&apos; + &apos;
                &apos; + EXCHANGE_CODE.
                <br />
                For example as below:
              </Typography>
              <br />
              <Typography variant="h6">AAPL US</Typography>

              <Typography>
                You can have multiple fields each separated by a semi-colon (;).{' '}
                <br />
                Example: `IntensityAvgInferenceScope123Total;
                IntensityAvgInferenceScope12Total`
                <br />
                The field can be one field or a group. The possible groups are
                listed below. <br />
                <br />
                Field Groups Description: <br />
                all All columns <br />
                summary Summary Data (Security IDs etc.)
                <br />
                avg_int_cols Average Intensity (tCO2e/$USDm Revenue)
                <br />
                max_int_cols Maximum Intensity (tCO2e/$USDm Revenue)
                <br />
                rep_int_cols Reported Intensity (tCO2e/$USDm Revenue)
                <br />
                rep_emis_cols Reported Emissions (tCO2e)
                <br />
                absolute_avg Average Absolute Emissions (tCO2e)
                <br /> <br />
                Response Example:
                <br />
                <br />
              </Typography>

              <SyntaxHighlighter language="javascript" style={tomorrowNight}>
                {`
{
    "status": "Success",
    "data": [
      {
        "identifier": "US29134WAB37",
        "id_type": "ID_ISIN",
        "version": "11",
        "ISIN": "FR0013467479",
        "Company (Short) Name": "CONSTELLIUM SE",
        "Primary Data Source": "Urgentem",
        "Disclosure Category": 1.0,
        "Number of Scope 3 Categories Disclosed": 0.0,
        "Region": "Europe",
        "Intensity Average Inference Scope 1 & 2 Total (tCO2e/$m Revenue) - Source": "Sum of Location and Scope One",
        "Intensity Average Inference Scope 1 & 2 Total (tCO2e/$m Revenue)": 178.97,
        "Intensity Average Inference Scope 3 Total (tCO2e/$m Revenue) - Source": "Sum of Average Category Intensities",
        "Intensity Average Inference Scope 3 Total (tCO2e/$m Revenue)": 4339.52,
        "Intensity Average Inference Scope 1, 2 & 3 Total (tCO2e/$m Revenue)": 4518.49,
        "Emissions Year": "2019",
        "Country": "France"
      }
    ]
                    `}
              </SyntaxHighlighter>
            </div>
            <br />
            <Divider />
            <br />
            <div>
              <Typography variant="h5">
                Python Code Example (send a file)
              </Typography>
              <Typography variant="h6"> </Typography>
              <Typography> </Typography>
              <SyntaxHighlighter language="python" style={tomorrowNight}>
                {`
import os
from requests import post
from argparse import ArgumentParser


def run_download(args):
  if args.filename ===  "":
      print("Please enter a filename with full path")
      return

  _, file_extension = os.path.splitext(args.filename)

  allowed_mimetypes = {
      ".csv": "text/csv",
      ".xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ".xls": "application/vnd.ms-excel",
  }

  if file_extension not in allowed_mimetypes:
      print("Invalid file provided. Allowed: csv, xls, xlsx")
      return

  headers = {
      "x-api-key": args.x_api_key,
      "Access-Control-Allow-Origin": "*"
  }

  response = post(
      url=args.url,
      files={"excel_file": (
          args.filename, open(args.filename, "rb"), allowed_mimetypes[file_extension]
      )},
      headers=headers
  )

  print(response.status_code)
  print(response.text)


if __name__ ===  "__main__":
  parser = ArgumentParser()

  parser.add_argument(
      "-fn", "--filename", type=str,
      help="enter the full path of the file: example: /Users/xyz/abc.xlsx",
      default=""
  )
  parser.add_argument(
      "-key", "--x_api_key", type=str,
      help="pls contact your Urgentem contact to get your API key",
      default=""
  )
  parser.add_argument(
      "-url", "--url", type=str,
      help="url should include the full end point, example: http://XXXXXX/download/",
      default="http://localhost:8080/download/"
  )
  args = parser.parse_args()

  run_download(args)`}
              </SyntaxHighlighter>
            </div>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default UrgentemApi;
