import moment from 'moment';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import DatePickers from '../../Datepickers';
import { END_DATE, START_DATE } from '../constants';
import { setFilterItemSuccess } from '../../../redux/reducers/filterReducer';

const FilterDatePicker = ({ dateOptions }) => {
  const dispatch = useDispatch();
  const filterItem = useSelector((state) => state.filters);
  const { backtest_end_date: backtestEndData } = useSelector(
    (state) => state.auth.userInfo,
  );
  // Setting date in state from redux store
  // Only updating redux state when dates are valid
  const [dateStates, setDateStates] = useState(
    dateOptions.reduce((acc, { name }) => {
      if (filterItem?.dateRange?.[name]) {
        acc[name] = filterItem.dateRange[name];
      }
      return acc;
    }, {}),
  );

  return (
    <>
      {dateOptions.map(({ label, name }) => {
        const { dateRange } = filterItem;
        let minDateStr = '2017-01-01';
        let maxDateStr = backtestEndData;
        let minDate = moment(minDateStr, 'YYYY-MM-DD');
        let maxDate = moment(maxDateStr, 'YYYY-MM-DD');
        const currentStartDate = moment(dateRange.startDate, 'YYYY-MM-DD');
        const currentEndDate = moment(dateRange.endDate, 'YYYY-MM-DD');
        if (
          label === END_DATE
          && currentStartDate.isAfter(minDate)
          && currentStartDate.isBefore(maxDate)
        ) {
          minDateStr = dateRange.startDate;
          minDate = moment(minDateStr, 'YYYY-MM-DD');
        }
        if (
          label === START_DATE
          && currentEndDate.isAfter(minDate)
          && currentEndDate.isBefore(maxDate)
        ) {
          maxDateStr = dateRange.endDate;
          maxDate = moment(maxDateStr, 'YYYY-MM-DD');
        }
        return (
          <div key={label}>
            <DatePickers
              label={label}
              value={dateStates[name]} // using state for handling date values
              minDate={minDateStr}
              maxDate={maxDateStr}
              onChange={(e) => {
                const newDateStr = moment(e).format('yyyy-MM-DD');
                const newDateRange = {
                  ...dateStates,
                  [name]: newDateStr,
                };
                setDateStates(newDateRange);
                if (
                  // Checking if date is valid
                  moment(newDateStr, 'YYYY-MM-DD').isValid()
                  && Object.values(newDateRange).filter((date) => moment(date, 'YYYY-MM-DD').isBetween(
                    minDate,
                    maxDate,
                    null,
                    [],
                  )).length === 2
                ) {
                  // Only updating redux state if date is valid
                  dispatch(
                    setFilterItemSuccess({
                      key: 'dateRange',
                      value: newDateRange,
                    }),
                  );
                }
              }}
            />
          </div>
        );
      })}
    </>
  );
};

FilterDatePicker.propTypes = {
  dateOptions: PropTypes.array.isRequired,
};

export default FilterDatePicker;
