import React from 'react';
import { formatNumber } from './formatNumber';
import { CircleBullet } from '../components/OnTrackWithTargets';
import TitleTooltip from '../components/Table/TitleTooltip';

const avoidedEmissionCells = [
  {
    name: '',
    selector: 'name',
    sortable: true,
    right: false,
    wrap: true,
  },
  {
    name: 'Scope 1+2',
    selector: 'sc12',
    sortable: true,
    right: true,
  },
  {
    name: 'Scope 3',
    selector: 'sc3',
    sortable: true,
    right: true,
  },
  {
    name: 'Scope 1+2+3',
    selector: 'sc123',
    sortable: true,
    right: true,
  },
  {
    name: 'Avoided Emissions',
    selector: 'avoided_emissions',
    sortable: true,
    right: true,
  },
];

const sectoralScope3Cells = [
  {
    name: 'Company Name',
    selector: 'security',
    sortable: true,
    right: false,
    wrap: true,
  },
  {
    name: 'Purchased Goods and Services',
    selector: 'purchased',
    sortable: true,
    right: true,
    wrap: true,
    format: (row) => (row.purchased === null ? 'N/A' : formatNumber(row.purchased)),
  },
  {
    name: 'Capital Goods',
    selector: 'capital',
    sortable: true,
    right: true,
    wrap: true,
    format: (row) => (row.capital === null ? 'N/A' : formatNumber(row.capital)),
  },
  {
    name: 'Fuel and Energy Related Activities',
    selector: 'fuel',
    sortable: true,
    right: true,
    wrap: true,
    format: (row) => (row.fuel === null ? 'N/A' : formatNumber(row.fuel)),
  },
  {
    name: 'Upstream Transport and Distribution',
    selector: 'upstreamTransport',
    sortable: true,
    right: true,
    wrap: true,
    format: (row) => (row.upstreamTransport === null
      ? 'N/A'
      : formatNumber(row.upstreamTransport)),
  },
  {
    name: 'Waste Generated',
    selector: 'waste',
    sortable: true,
    right: true,
    wrap: true,
    format: (row) => (row.waste === null ? 'N/A' : formatNumber(row.waste)),
  },
  {
    name: 'Business Travel',
    selector: 'business',
    sortable: true,
    right: true,
    wrap: true,
    format: (row) => (row.business === null ? 'N/A' : formatNumber(row.business)),
  },
  {
    name: 'Employee Commuting',
    selector: 'employee',
    sortable: true,
    right: true,
    wrap: true,
    format: (row) => (row.employee === null ? 'N/A' : formatNumber(row.employee)),
  },
  {
    name: 'Upstream Leased Assets',
    selector: 'upstreamLeased',
    sortable: true,
    right: true,
    wrap: true,
    format: (row) => (row.upstreamLeased === null ? 'N/A' : formatNumber(row.upstreamLeased)),
  },
  {
    name: 'Downstream Transport Distribution',
    selector: 'downstreamTransport',
    sortable: true,
    right: true,
    wrap: true,
    format: (row) => (row.downstreamTransport === null
      ? 'N/A'
      : formatNumber(row.downstreamTransport)),
  },
  {
    name: 'Processing of Sold Products',
    selector: 'processing',
    sortable: true,
    right: true,
    wrap: true,
    format: (row) => (row.processing === null ? 'N/A' : formatNumber(row.processing)),
  },
  {
    name: 'Use of Sold Products',
    selector: 'useSolid',
    sortable: true,
    right: true,
    wrap: true,
    format: (row) => (row.useSolid === null ? 'N/A' : formatNumber(row.useSolid)),
  },
  {
    name: 'End of Life Treatment of Sold Products',
    selector: 'endOfLife',
    sortable: true,
    right: true,
    wrap: true,
    format: (row) => (row.endOfLife === null ? 'N/A' : formatNumber(row.endOfLife)),
  },
  {
    name: 'Downstream Leased Assets',
    selector: 'downstreamLease',
    sortable: true,
    right: true,
    wrap: true,
    format: (row) => (row.downstreamLease === null ? 'N/A' : formatNumber(row.downstreamLease)),
  },
  {
    name: 'Franchises',
    selector: 'franchise',
    sortable: true,
    right: true,
    wrap: true,
    format: (row) => (row.franchise === null ? 'N/A' : formatNumber(row.franchise)),
  },
  {
    name: 'Investments',
    selector: 'investments',
    sortable: true,
    right: true,
    wrap: true,
    format: (row) => (row.investments === null ? 'N/A' : formatNumber(row.investments)),
  },
];
const portOptimizationCells = [
  {
    name: '',
    selector: 'name',
    sortable: true,
    right: false,
    wrap: true,
  },
  {
    name: 'Portfolio',
    selector: 'portfolio',
    sortable: true,
    right: true,
  },
  {
    name: 'Tilted',
    selector: 'tilted',
    sortable: true,
    right: true,
  },
];
const portfolioRisk = [
  {
    name: '',
    selector: 'name',
    sortable: true,
    right: false,
    wrap: true,
  },
  {
    name: 'Portfolio',
    selector: 'portfolio',
    sortable: true,
    right: true,
  },
  {
    name: 'Tilted(%)',
    selector: 'tilted',
    sortable: true,
    right: true,
  },
];
const riskContribCells = [
  {
    name: 'Company Name',
    selector: 'company',
    sortable: true,
    right: false,
    wrap: true,
    style: {
      height: 80,
      minWidth: 120,
    },
  },
  {
    name: 'ISIN',
    selector: 'isin',
    sortable: true,
    right: false,
  },
  {
    name: 'Sector',
    selector: 'sasb_sector',
    sortable: true,
    wrap: true,
    right: false,
  },
  {
    name: 'Weight (%)',
    selector: 'weight',
    sortable: true,
    right: true,
  },
  {
    name: 'Contribution to Annualized Return',
    selector: 'annualized_return',
    sortable: true,
    right: true,
    cell: (row) => (
      <div>
        {row.annualized_return === -999999
          ? 'NA'
          : formatNumber(row.annualized_return)}
      </div>
    ),
  },
  {
    name: 'Contribution to Annualized Risk',
    selector: 'annualized_risk',
    sortable: true,
    right: true,
    cell: (row) => (
      <div>
        {row.annualized_risk === -999999
          ? 'NA'
          : formatNumber(row.annualized_risk)}
      </div>
    ),
  },
  {
    name: 'Contribution to Emissions Footprint',
    selector: 'intensity',
    sortable: true,
    right: true,
  },
];
const fossilFuelCells = [
  {
    name: 'Fossil Fuel Type',
    selector: 'type',
    sortable: true,
    right: false,
  },
  {
    name: 'Portfolio',
    selector: 'portfolio',
    sortable: true,
    right: true,
  },
];
const countryFossilCells = [
  {
    name: 'Country',
    selector: 'country',
    sortable: true,
    right: false,
  },
  {
    name: 'Contribution to total footprint (%)',
    selector: 'contribution',
    sortable: true,
    right: true,
    wrap: false,
  },
];
const coalPowerCells = [
  {
    name: 'Company',
    selector: 'company',
    sortable: true,
    right: false,
    wrap: true,
  },
  {
    name: 'Capacity (MW)',
    selector: 'capacity',
    sortable: true,
    right: true,
    wrap: true,
  },
  {
    name: 'Ownership (% Market Cap)',
    selector: 'ownership',
    sortable: true,
    right: true,
    wrap: true,
  },
  {
    name: 'Capacity Owned (MW)',
    selector: 'owned_capacity',
    sortable: true,
    right: true,
    wrap: true,
  },
];
const tempScoreCells = [
  {
    name: (
      <div>
        <div>Portfolio</div>
        <div style={{ visibility: 'hidden' }}>portfolio</div>
      </div>
    ),
    selector: 'name',
    sortable: true,
    right: false,
    wrap: true,
    style: {
      height: 50,
      fontSize: 13,
    },
  },
  {
    name: (
      <div>
        <div>Short Term Score °C</div>
        <div>(2021-2025)</div>
      </div>
    ),
    selector: 'shortTerm.score',
    sortable: true,
    right: true,
  },
  {
    name: (
      <div>
        <div>Mid Term Score °C</div>
        <div>(2026-2036)</div>
      </div>
    ),
    selector: 'midTerm.score',
    sortable: true,
    right: true,
  },
  {
    // name: <div><div>Long Term Score °C</div><div>`({'>'}2036)`</div></div>,
    name: 'Long Term Score °C (>2036)',
    selector: 'longTerm.score',
    sortable: true,
    right: true,
  },
  {
    name: (
      <div>
        <div>Coverage (%)</div>
        <div style={{ visibility: 'hidden' }}>coverage</div>
      </div>
    ),
    selector: 'coverage.total',
    sortable: true,
    right: true,
  },
];
const companyAnalysisCells = [
  {
    name: (
      <div>
        <div>Company Name</div>
        <div style={{ visibility: 'hidden' }}>portfolio</div>
      </div>
    ),
    selector: 'company_name',
    sortable: true,
    right: false,
    wrap: true,
    style: {
      height: 50,
      fontSize: 13,
    },
  },
  {
    name: (
      <div>
        <div>Sector</div>
        <div style={{ visibility: 'hidden' }}>portfolio</div>
      </div>
    ),
    selector: 'sector',
    sortable: true,
    right: false,
    wrap: true,
  },
  {
    name: (
      <div>
        <div>Contribution</div>
        <div>(°C)</div>
      </div>
    ),
    selector: 'contribution',
    sortable: true,
    right: true,
  },
  {
    name: (
      <div>
        <div>Temperature Score</div>
        <div>(°C)</div>
      </div>
    ),
    selector: 'temperature_score',
    sortable: true,
    right: true,
  },
  {
    name: <div>Ownership Percentage</div>,
    selector: 'ownership_percentage',
    sortable: true,
    right: true,
  },
  {
    name: <div>Portfolio Percentage</div>,
    selector: 'portfolio_percentage',
    sortable: true,
    right: true,
  },
  {
    name: <div>SBTI validated</div>,
    selector: 'sbti_validated',
    sortable: true,
    right: true,
  },
];

const netZeroTargetSettingCells = (emissionYear, alignmentYear, units) => [
  {
    name: 'Sector',
    selector: 'sector',
    sortable: true,
    right: false,
  },
  {
    name: `Emissions ${emissionYear} (${units})`,
    selector: 'current_emissions',
    sortable: true,
    right: true,
  },
  {
    name: `Estimated Emissions ${alignmentYear} (${units})`,
    selector: 'estimated_emissions',
    sortable: true,
    right: true,
  },
  {
    name: `Aligned Emissions (${units})`,
    selector: 'aligned_emissions',
    sortable: true,
    right: true,
  },
  {
    name: 'Average annual emissions reduction % - ESTIMATED',
    selector: 'emissions_reduction_estimated',
    sortable: true,
    right: true,
  },
  {
    name: 'Average annual emissions reduction % -REQUIRED',
    selector: 'emissions_reduction_required',
    sortable: true,
    right: true,
  },
];

const targetSettingCells = [
  {
    name: 'Sector',
    selector: 'sector',
    sortable: true,
    right: false,
  },
  {
    name: 'Portfolio',
    selector: 'port',
    sortable: true,
    right: true,
  },
  {
    name: 'Allowance',
    selector: 'allowance',
    sortable: true,
    right: true,
  },
  {
    name: 'Annual Reduction %',
    selector: 'annualRed',
    sortable: true,
    right: true,
  },
];
const companyProfileCells = [
  {
    name: 'Summary',
    selector: 'name',
    sortable: true,
    right: false,
    wrap: true,
  },
  {
    name: '',
    selector: 'summary',
    sortable: true,
    right: true,
  },
];

const companyAlignmentCells = [
  {
    name: 'Company',
    selector: 'company_name',
    sortable: true,
    right: false,
    wrap: true,
  },
  {
    name: 'Portfolio weight (%)',
    selector: 'weight',
    sortable: true,
    right: true,
  },
  {
    name: 'Implied Temperature Rise',
    selector: 'implied_temperature_rise',
    sortable: true,
    right: true,
  },
  {
    name: 'Target(s)',
    selector: 'includes_targets',
    sortable: true,
    right: true,
    cell: (row) => (row.includes_targets ? 'Yes' : 'No'),
    maxWidth: '100px',
  },
  {
    name: 'On Track With Target(s)',
    selector: 'on_track_indicator',
    sortable: false,
    right: true,
    cell: (row) => <CircleBullet backgroundColor={row.on_track_indicator} />,
  },
];

const missingCoverageCells = [
  {
    name: 'ISIN',
    selector: 'ISIN',
    sortable: true,
    right: false,
  },
  {
    name: 'Weight',
    selector: 'Weight',
    sortable: true,
    right: true,
  },
  {
    name: 'Fundamentals Covered',
    selector: 'Fundamentals_Covered',
    sortable: true,
    right: true,
  },
  {
    name: 'Emissions Covered',
    selector: 'Emissions_Covered',
    sortable: true,
    right: true,
  },
  {
    name: 'Price Covered',
    selector: 'Price_Covered',
    sortable: true,
    right: true,
  },
];
const summaryCells = [
  {
    name: 'Fund Name',
    selector: 'name',
    sortable: true,
    right: false,
  },
  {
    name: 'Weight (%)',
    selector: 'weight',
    sortable: true,
    right: true,
  },
  {
    name: 'Coverage (%)',
    selector: 'emission',
    sortable: true,
    right: true,
  },
  {
    name: 'Weight Coverage (%)',
    selector: 'fundamentals',
    sortable: true,
    right: true,
  },
];
const targetFundCells = [
  {
    name: 'Fund Name',
    selector: 'name',
    sortable: true,
    right: false,
  },
  {
    name: 'Contribution',
    selector: 'contribution',
    sortable: true,
    right: true,
  },
  {
    name: 'Allowance',
    selector: 'allowance',
    sortable: true,
    right: true,
  },
  {
    name: 'Annual Reduction (%)',
    selector: 'annualRed',
    sortable: true,
    right: true,
  },
];

const portEmissionCells = (tableLabel, CATEGORIES_MAP) => [
  {
    name: `Portfolios (${tableLabel})`,
    selector: 'name',
    sortable: true,
    right: false,
    wrap: true,
  },
  {
    name: CATEGORIES_MAP.sc12,
    selector: 'sc12',
    sortable: true,
    right: true,
    cell: (row) => <div>{new Intl.NumberFormat().format(row.sc12)}</div>,
  },
  {
    name: CATEGORIES_MAP.sc3,
    selector: 'sc3',
    sortable: true,
    right: true,
    cell: (row) => <div>{new Intl.NumberFormat().format(row.sc3)}</div>,
  },
  {
    name: CATEGORIES_MAP.sc123,
    selector: 'sc123',
    sortable: true,
    right: true,
    cell: (row) => <div>{new Intl.NumberFormat().format(row.sc123)}</div>,
  },
];

const carbonAdjustedReturnCells = (companyData) => [
  {
    name: '',
    selector: 'name',
    sortable: true,
    right: false,
    wrap: true,
    style: {
      height: 50,
      fontSize: 13,
    },
  },
  {
    name: companyData?.name,
    selector: 'company',
    sortable: true,
    right: true,
  },
  {
    name: 'Sector',
    selector: 'sector',
    sortable: true,
    right: true,
  },
  {
    name: 'Portfolio',
    selector: 'portfolio',
    sortable: true,
    right: true,
  },
];

const emissionsCells = (yAxisTitle) => [
  {
    name: `Portfolios (${yAxisTitle})`,
    selector: 'name',
    sortable: true,
    right: false,
    wrap: true,
  },
  {
    name: 'Production',
    selector: 'production',
    sortable: true,
    right: true,
    cell: (row) => <div>{new Intl.NumberFormat().format(row.production)}</div>,
  },
  // {
  //   name: 'Export',
  //   selector: 'exported',
  //   sortable: true,
  //   right: true,
  //   cell: (row) => <div>{new Intl.NumberFormat().format(row.exported)}</div>,
  // },
  // {
  //   name: 'Import',
  //   selector: 'imported',
  //   sortable: true,
  //   right: true,
  //   cell: (row) => <div>{new Intl.NumberFormat().format(row.imported)}</div>,
  // },
  {
    name: 'Consumption',
    selector: 'consumption',
    sortable: true,
    right: true,
    cell: (row) => <div>{new Intl.NumberFormat().format(row.consumption)}</div>,
  },
];
const portfolioBuilderTooltipTexts = {
  isin: 'An International Securities Identification Number (ISIN) is a 12-digit alphanumeric code that uniquely identifies a specific security.',
  companyName:
    'Name of the issuing entity. Where the securities of a subsidiary are mapped to a parent company, this would be the name of the parent entity.',
  region: `ICE Regional geography is defined based on an entities country.
  Country = Country is classified by an entity's Country of Risk, if Country of Risk is not available then Country of Domicile is used.`,
  country:
    "Country is classified by an entity's Country of Risk, if Country of Risk is not available then Country of Domicile is used.",
  sector:
    "Sector is classified by ICE's Uniform Entity Sector Classification schema.",
  avgIntensityS12:
    'Carbon emissions of a company expressed in terms of tons CO2e per million dollars of revenue.',
  marketCap:
    'Mega-cap is the market value of $200 billion or more, Large-cap is the market value between $10 billion and $200 billion, Mid-cap is the market value between $2 billion and $10 billion, and Small-cap is the market value of $2 billion or less. All values being in USD.',
  disclosureCategory:
    "This is based on ICE Emission Data Disclosure Category Classification. Category 1: Complete with Accepted Assurance (covers at least 95% of a company's global emissions and is third party verified), Category 2: Complete without Accepted Assurance (covers at least 95% of a company's global emissions and is not third party verified), Category 3: Incomplete not third party verified, Category 4: No public data, and Category 5: Not directly analysed.",
  companiesWithTarget:
    'Yes/no - Defines if an entity has GHG emissions reduction target. SBTI target - Defines if an entity has at least one SBTi-validated GHG emissions reduction target.',
};

const portfolioBuilderCells = [
  {
    name: (
      <TitleTooltip
        title="ISIN"
        tooltipText={portfolioBuilderTooltipTexts.isin}
      />
    ),
    selector: 'isin',
    sortable: true,
    right: false,
    wrap: true,
  },
  {
    name: (
      <TitleTooltip
        title="Company Name"
        tooltipText={portfolioBuilderTooltipTexts.companyName}
      />
    ),
    selector: 'company_name',
    sortable: true,
    wrap: true,
  },
  {
    name: (
      <TitleTooltip
        title="Region"
        tooltipText={portfolioBuilderTooltipTexts.region}
      />
    ),
    selector: 'region',
    sortable: true,
    wrap: true,
  },
  {
    name: (
      <TitleTooltip
        title="Country"
        tooltipText={portfolioBuilderTooltipTexts.country}
      />
    ),
    selector: 'country',
    sortable: true,
    wrap: true,
  },
  {
    name: (
      <TitleTooltip
        title="Sector"
        tooltipText={portfolioBuilderTooltipTexts.sector}
      />
    ),
    selector: 'ice_sector',
    sortable: true,
    wrap: true,
  },
  {
    name: (
      <TitleTooltip
        title="Avg Intensity - Scope (1+2)"
        tooltipText={portfolioBuilderTooltipTexts.avgIntensityS12}
      />
    ),
    selector: 'intensity_sc12',
    sortable: true,
    right: true,
  },
  {
    name: (
      <TitleTooltip
        title="MarketCap"
        tooltipText={portfolioBuilderTooltipTexts.marketCap}
      />
    ),
    selector: 'market_cap',
    sortable: true,
    cell: (row) => <div>{row.market_cap && row.market_cap.toUpperCase()}</div>,
  },
  {
    name: (
      <TitleTooltip
        title="Disclosure Category"
        tooltipText={portfolioBuilderTooltipTexts.disclosureCategory}
      />
    ),
    selector: 'disclosure_sc12',
    sortable: true,
    right: true,
    cell: (row) => <div>{row.disclosure_sc12}</div>, // To remove decimal
  },
  {
    name: (
      <TitleTooltip
        title="Companies with Targets"
        tooltipText={portfolioBuilderTooltipTexts.companiesWithTarget}
      />
    ),
    selector: 'targets',
    sortable: true,
  },
  {
    name: <TitleTooltip title="Asset Type" />,
    selector: 'security_type',
    sortable: true,
    wrap: true,
    right: false,
  },
];

const carbonOffsetCompanyToolTipText = {
  isin: 'An International Securities Identification Number (ISIN) is a 12-digit alphanumeric code that uniqely identifies a specific security.',
  companyName:
    'Name of the issuing entity. Where the securities of a subsidiary are mapped to a parent company, this would be the name of the parent entity.',
  country:
    "Country is classified by an entity's Country of Risk, if Country of Risk is not available then Country of Domicile is used.",
  sector:
    "Depending on the selected Sector Classification filter, sector is classified either by ICE's Uniform Entity Sector Classification schema or the Sustainability Accounting Standards Board (SASB) Sustainable Industry Classification System (SICS).",
  carbonOffset:
    'The number of retired carbon offsets of a company. One carbon offset represents one tonne of CO2 reduction.',
  emissions:
    'Carbon emissions (or absolute emissions) of a company expressed in terms of tons of carbon dioxide equivalent (tCO2e).',
};

const carbonMarketCell = [
  {
    name: (
      <TitleTooltip
        title="Company (Short) Name"
        tooltipText={carbonOffsetCompanyToolTipText.companyName}
      />
    ),
    selector: 'company',
    sortable: true,
    right: false,
    wrap: true,
    style: {
      height: 80,
      minWidth: 120,
    },
  },
  {
    name: (
      <TitleTooltip
        title="Sector"
        tooltipText={carbonOffsetCompanyToolTipText.sector}
      />
    ),
    selector: 'sasb_sector',
    sortable: true,
    wrap: true,
    right: false,
  },
  {
    name: 'Weight (%)',
    selector: 'weight',
    sortable: true,
    right: true,
  },
  {
    name: (
      <TitleTooltip
        title="Carbon Offset"
        tooltipText={carbonOffsetCompanyToolTipText.carbonOffset}
      />
    ),
    selector: 'carbon_offset',
    sortable: true,
    right: true,
  },
  {
    name: (
      <TitleTooltip
        title="Emissions"
        tooltipText={carbonOffsetCompanyToolTipText.emissions}
      />
    ),
    selector: 'emissions',
    sortable: true,
    right: true,
  },
];

const companyEmissionaTooltip = {
  isin: 'An International Securities Identification Number (ISIN) is a 12-digit alphanumeric code that uniqely identifies a specific security.',
  companyName:
    'Name of the issuing entity. Where the securities of a subsidiary are mapped to a parent company, this would be the name of the parent entity.',
  sector:
    "Depending on the selected Sector Classification filter, sector is classified either by ICE's Uniform Entity Sector Classification schema or the Sustainability Accounting Standards Board (SASB) Sustainable Industry Classification System (SICS).",
  country:
    "Country is classified by an entity's Country of Risk, if Country of Risk is not available then Country of Domicile is used.",
  disclosure:
    "This is based on ICE Disclosure Category Classification. Category 1: Complete with Accepted Assurance (covers at least 95% of a company's global emissions and is third party verified), Category 2: Complete without Accepted Assurance (covers at least 95% of a company's global emissions and is not third party verified), Category 3: Incomplete not third party verified, Category 4: No public data, and Category 5: Not directly analysed.",
  weight: 'This is based on the securities weighting within the portfolio.',
  emission:
    'The carbon emissions of the selected emission scopes (scope 1+2 or scope 1+2+3) expressed in GHG Emissions tCO2e.',
  contribution:
    'This is based on the selected emission scopes (scope 1+2 or scope 1+2+3) and footprinting metric, taking into consideration weighting within the portfolio.',
  intensity:
    'The carbon intensity of the selected emission scopes (scope 1+2 or scope 1+2+3) expressed in Intensity tCO2e / 1M of the selected footprinting metric.',
};

const companyEmissionCells = (columns, emission, sector) => [
  {
    name: (
      <TitleTooltip
        title={columns.company_name}
        tooltipText={companyEmissionaTooltip.companyName}
      />
    ),
    selector: 'company_name',
    sortable: true,
    right: false,
    wrap: true,
  },
  {
    name: (
      <TitleTooltip
        title="Sector"
        tooltipText={companyEmissionaTooltip.sector}
      />
    ),
    selector: sector === 'ICE' ? 'ice_sector' : 'sasb_sector',
    sortable: true,
    wrap: true,
    right: false,
  },
  {
    name: (
      <TitleTooltip
        title={columns.country}
        tooltipText={companyEmissionaTooltip.country}
      />
    ),
    selector: 'country',
    sortable: true,
    wrap: true,
    right: false,
  },
  {
    name: (
      <TitleTooltip
        title={columns.disclosure}
        tooltipText={companyEmissionaTooltip.disclosure}
      />
    ),
    selector: 'disclosure',
    sortable: true,
    right: true,
    cell: (row) => <div>{row.disclosure}</div>,
  },
  {
    name: (
      <TitleTooltip
        title={columns.weight}
        tooltipText={companyEmissionaTooltip.weight}
      />
    ),
    selector: 'weight',
    sortable: true,
    right: true,
  },
  {
    name: (
      <TitleTooltip
        title={columns[`emissions_${emission}`]}
        tooltipText={companyEmissionaTooltip.emission}
      />
    ),
    selector: 'emission',
    sortable: true,
    right: true,
  },
  {
    name: (
      <TitleTooltip
        title={columns[`intensity_${emission}`]}
        tooltipText={companyEmissionaTooltip.intensity}
      />
    ),
    selector: 'intensity',
    sortable: true,
    right: true,
  },
  {
    name: (
      <TitleTooltip
        title={columns[`contribution_${emission}`]}
        tooltipText={companyEmissionaTooltip.contribution}
      />
    ),
    selector: 'contribution',
    sortable: true,
    right: true,
  },
];

export {
  avoidedEmissionCells,
  sectoralScope3Cells,
  portOptimizationCells,
  portfolioRisk,
  riskContribCells,
  fossilFuelCells,
  countryFossilCells,
  coalPowerCells,
  tempScoreCells,
  companyAnalysisCells,
  targetSettingCells,
  netZeroTargetSettingCells,
  companyProfileCells,
  companyAlignmentCells,
  missingCoverageCells,
  summaryCells,
  targetFundCells,
  portEmissionCells,
  carbonAdjustedReturnCells,
  emissionsCells,
  portfolioBuilderCells,
  carbonMarketCell,
  companyEmissionCells,
};
