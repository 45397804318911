import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { sectoralScope3Cells } from '../../../util/TableHeadConfig';
import categoryContent from '../CategoryContent';
import { LoadingHOC } from '../../../util/LoadingHOC';
import SectoralScope3HeatmapDisplay from './SectoralScope3HeatmapDisplay';
import { getCategoryKeyForScope3 } from '../../../helpers';
import { SCOPE_12_TOTAL } from '../../../components/FilterSection/constants';
import SectoralScope3HeatmapModel from '../../../models/scope3/sectoralScope3Heatmap';
import useGetPayload from '../../../hooks/useGetPayload';
import { useGetScope3SectorHeatmapQuery } from '../../../redux/apiHooks';

const SectoralScope3HeatmapWithLoading = LoadingHOC(
  SectoralScope3HeatmapDisplay,
);

const useStyles = makeStyles(() => ({
  formControl: {
    width: 350,
    margin: 15,
  },
}));

const SectoralScope3Heatmap = () => {
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [yCategories, setYCategories] = useState([]);
  const [xCategories, setXCategories] = useState([]);
  const [currentSector, setCurrentSector] = useState('');
  const [dialog, setDialog] = useState(false);
  const classes = useStyles();

  const { filters, payload } = useGetPayload(SectoralScope3HeatmapModel);
  const {
    data: heatmapData,
    isFetching,
    error,
  } = useGetScope3SectorHeatmapQuery(payload);
  const { emission } = filters;

  const getTableData = (currentSectorName) => {
    const heatmapTableData = [];
    const tableResponse = heatmapData.data.table;
    const unit = heatmapData.metadata.units;

    if (tableResponse && tableResponse.length > 0) {
      tableResponse.forEach((table) => {
        const sector = Object.keys(table)[0];

        if (sector === currentSectorName) {
          sectoralScope3Cells[0].name = '';
          sectoralScope3Cells[0].name = `Company Name ${sectoralScope3Cells[0].name}  ( ${unit} ) `;

          const dataToParse = Object.values(table)[0];
          dataToParse.forEach((catTable) => {
            heatmapTableData.push({
              security: catTable.name,
              business: catTable.categories.Business_Travel,
              capital: catTable.categories.Capital_Goods,
              downstreamLease: catTable.categories.Downstream_Leased_Assets,
              downstreamTransport:
                catTable.categories.Downstream_Transport_Distribution,
              employee: catTable.categories.Employee_Commuting,
              endOfLife:
                catTable.categories.End_of_Life_Treatment_of_Sold_Products,
              franchise: catTable.categories.Franchises,
              fuel: catTable.categories.Fuel_and_Energy_Related_Activities,
              investments: catTable.categories.Investments,
              processing: catTable.categories.Processing_of_Sold_Products,
              purchased: catTable.categories.Purchased_Goods_and_Services,
              upstreamLeased: catTable.categories.Upstream_Leased_Assets,
              upstreamTransport:
                catTable.categories.Upstream_Transport_and_Distribution,
              useSolid: catTable.categories.Use_of_Sold_Products,
              waste: catTable.categories.Waste_Generated,
            });
          });
        }
      });
    }

    setTableData(heatmapTableData);
  };

  const getChartData = (currentSectorName) => {
    let emissionChartData = [];
    const categories = [];

    const sectorList = heatmapData.sectors;

    const res = heatmapData.data.chart;
    for (let i = 0; i < 15; i += 1) {
      categories.push(`cat ${i + 1}`);
    }

    if (res.length > 0) {
      emissionChartData = res.map((data) => {
        const sectorName = data.sector;
        if (sectorName === currentSectorName) {
          if (
            !categories.includes('total 1 + 2')
            && data.category === SCOPE_12_TOTAL
          ) {
            categories.unshift('total 1 + 2');
          }
          const xValue = getCategoryKeyForScope3(data.category, emission);
          if (xValue === 'N/A') return false;
          const yValue = 0;
          const value = [
            xValue,
            yValue,
            parseFloat(data.contribution.toFixed(2)),
          ];
          return value;
        }
        return false;
      });
    }
    setChartData(emissionChartData.filter((data) => data !== false));
    setYCategories(sectorList);
    setXCategories(categories);
    setCurrentSector(currentSectorName);
  };

  const onDialogHandler = () => {
    setDialog(!dialog);
  };

  useEffect(() => {
    if (!isEmpty(heatmapData)) {
      const sectorName = heatmapData.sectors[0];
      getChartData(sectorName);
      getTableData(sectorName);
    }
  }, [heatmapData]);

  const handleSectorChange = (e) => {
    const sectorName = e.target.value;
    getTableData(sectorName);
    getChartData(sectorName);
  };

  return (
    <SectoralScope3HeatmapWithLoading
      error={error}
      loading={isFetching}
      className={classes.formControl}
      selectValue={currentSector}
      selectOnChange={handleSectorChange}
      yCategories={yCategories}
      heatmapChartKey="SECTORAL_SCOPE3_HEATMAP"
      yAxisCategories={['']}
      data={chartData}
      xAxisCategories={xCategories}
      isSectoral={true}
      dataTableData={tableData}
      columns={sectoralScope3Cells}
      tableHeading="SECTORAL_SCOPE3_HEATMAP"
      isScroll={true}
      onDialogHandler={onDialogHandler}
      dialogOpen={dialog}
      dialogContent={categoryContent}
    />
  );
};

export default SectoralScope3Heatmap;
