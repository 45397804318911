import React from 'react';
import PropTypes from 'prop-types';
import strings from '../constants';

function Disclaimer() {
  return (
    <div className="pdf_page pdf_page1">
      <h2>Disclaimer</h2>
      <div className="span-2">
        <p>{strings.disclaimer.limitations}</p>
        <p>{strings.disclaimer.text1}</p>
        <p>{strings.disclaimer.text2}</p>
        <p>{strings.disclaimer.text3}</p>
        <p>{strings.disclaimer.text4}</p>
        <p>{strings.disclaimer.text5}</p>
        <p>{strings.disclaimer.text6}</p>
        <p>{strings.disclaimer.text7}</p>
        <p>{strings.disclaimer.text8}</p>
      </div>
    </div>
  );
}

Disclaimer.propTypes = {
  currentTheme: PropTypes.string.isRequired,
};

export default Disclaimer;
