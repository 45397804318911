import React from 'react';
import PropTypes from 'prop-types';
import BaseChart from './BaseChart';
import ChartProps from './ChartProps';

function LineAndScatterChart({ emissionYear, xAxisType, ...props }) {
  const options = {
    xAxis: {
      // #TO-DO: Move this to BaseChart
      type: xAxisType || 'datetime',
      min: Date.UTC(emissionYear, '01', '01'),
      max: Date.UTC('2050', '01', '01'),
      plotLines: null,
    },
    tooltip: {
      formatter() {
        return `${new Date(this.x).getFullYear()}<br/>
          <b>${this.point.series.name}:</b> ${this.y}`;
      },
    },
  };
  return (
    <BaseChart
      {...props}
      xAxisTitle={props.xAxisTitle || 'Year'}
      yAxisGridlineShow={true}
      legendType="horizontal"
      height={props.height || 500}
      options={options}
    />
  );
}

LineAndScatterChart.propTypes = {
  ...ChartProps,
  emissionYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xAxisType: PropTypes.string,
};
export default LineAndScatterChart;
