import { useSelector } from 'react-redux';

const useInferenceValue = () => {
  const filterItem = useSelector((state) => state.filters);
  const { inferenceType } = filterItem;
  const currentInference = Array.isArray(inferenceType)
    ? inferenceType[0]
    : inferenceType.toLowerCase();
  return currentInference;
};

export default useInferenceValue;
