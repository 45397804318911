import React from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import TabPanel from '../../components/TabPanel';
import { fundOfFundsTabs } from '../../util/tabs-config';
import Summary from './Summary/Summary';
import Alignment from './Alignment/Alignment';
import Footprint from './Footprint/Footprint';
import TargetSetting from './TargetSettings/TargetSetting';
import useTabValue from '../../hooks/useTabValueHook';

function FundOfFunds() {
  const [tabValue, onTabChange] = useTabValue();

  return (
    <>
      <Paper position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={onTabChange}
          indicatorColor="secondary"
          fullWidth="true"
          data-testid="FundsOfFundsTabs"
        >
          {fundOfFundsTabs.map((e) => (
            <Tab
              key={e}
              label={e}
              style={{ fontSize: 11 }}
              data-testid={`tab-${e}`}
            />
          ))}
        </Tabs>
      </Paper>
      <TabPanel value={tabValue} index={0}>
        <Summary />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Footprint />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Alignment />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <TargetSetting />
      </TabPanel>
    </>
  );
}
export default FundOfFunds;
