/* eslint-disable import/no-cycle */
import apiSlice, { getQuery } from '../apiSlice';

export const scope3ApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getScope3PortfolioHeatmap: builder.query({
      query: getQuery.post('scope3/portfolio_heatmap'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
    getScope3SectorHeatmap: builder.query({
      query: getQuery.post('scope3/sector_heatmap'),
      transformErrorResponse: ({ data }) => data?.message,
    }),
  }),
});

export const {
  useGetScope3PortfolioHeatmapQuery,
  useGetScope3SectorHeatmapQuery,
  useLazyGetScope3PortfolioHeatmapQuery,
  useLazyGetScope3SectorHeatmapQuery,
} = scope3ApiSlice;
